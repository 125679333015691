/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CheckSisenseSeatsInput = {|
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type CheckSisenseSeatsMutationVariables = {|
  input: CheckSisenseSeatsInput
|};
export type CheckSisenseSeatsMutationResponse = {|
  +checkSisenseSeats: ?{|
    +designerSeats: ?number,
    +viewerSeats: ?number,
  |}
|};
export type CheckSisenseSeatsMutation = {|
  variables: CheckSisenseSeatsMutationVariables,
  response: CheckSisenseSeatsMutationResponse,
|};
*/


/*
mutation CheckSisenseSeatsMutation(
  $input: CheckSisenseSeatsInput!
) {
  checkSisenseSeats(input: $input) {
    designerSeats
    viewerSeats
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CheckSisenseSeatsInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CheckSisenseSeatsPayload",
    "kind": "LinkedField",
    "name": "checkSisenseSeats",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "designerSeats",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "viewerSeats",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckSisenseSeatsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckSisenseSeatsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CheckSisenseSeatsMutation",
    "operationKind": "mutation",
    "text": "mutation CheckSisenseSeatsMutation(\n  $input: CheckSisenseSeatsInput!\n) {\n  checkSisenseSeats(input: $input) {\n    designerSeats\n    viewerSeats\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd0d9089fcb410601c2c885c175054ca7';

module.exports = node;
