import {graphql} from 'react-relay';
import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

// Does not support create Client currently!!

const mutation = graphql`
	mutation createPersonMutationModernMutation($input: CreatePersonInput!) {
		createPerson(input: $input) {
			person {
				node {
					id
					harvestUser
					economicUser
					initials
					seat
					gitlabUser {
						integrationUserId
					}
					gitlabServerUser {
						integrationUserId
					}
					githubUser {
						integrationUserId
					}
					jiraServerUser
					jiraCloudUser
					asanaUser
					adoUserId
					firstName
					lastName
					fullName
					email
					phone
					profilePictureId
					profilePictureDefaultId
					role {
						id
						name
					}
					skills {
						edges {
							node {
								id
							}
						}
					}
					skillPersons {
						skill {
							id
						}
						level {
							id
						}
					}
					profiles(first: 10000) {
						edges {
							node {
								id
								name
								seat
							}
						}
					}
					permissions
					active
					isViewer
					costPeriods(first: 10000) @connection(key: "Person_costPeriods", filters: []) {
						edges {
							node {
								id
								startDay
								startMonth
								startYear
								cost
							}
						}
					}
					invited
				}
			}
			company {
				userSeats
				virtualSeats
			}
			errors
		}
	}
`;

function getConfigs(input) {
	const configs = [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_allPersons',
					rangeBehavior: 'append',
				},
				{
					key: 'Company_modal_allPersons',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'person',
		},
	];

	if (input.clientId) {
		configs.push({
			type: 'RANGE_ADD',
			parentID: input.clientId,
			connectionInfo: [
				{
					key: 'Client_clientUsers',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'person',
		});
	}

	return configs;
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Person Created');
	const variables = {...input};
	const cleanedVariables = omit(variables, ['companyId']);
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
