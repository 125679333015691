/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkPlaceholderAllocationsInput = {|
  placeholderAllocations?: ?$ReadOnlyArray<?PlaceholderAllocationInputType>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type PlaceholderAllocationInputType = {|
  id?: ?string,
  startDate?: ?string,
  endDate?: ?string,
  placeholderId?: ?string,
  monday?: ?number,
  tuesday?: ?number,
  wednesday?: ?number,
  thursday?: ?number,
  friday?: ?number,
  saturday?: ?number,
  sunday?: ?number,
  description?: ?string,
|};
export type bulkPlaceholderAllocationsMutationVariables = {|
  input: BulkPlaceholderAllocationsInput
|};
export type bulkPlaceholderAllocationsMutationResponse = {|
  +bulkPlaceholderAllocations: ?{|
    +placeholderAllocations: ?$ReadOnlyArray<?{|
      +id: string,
      +placeholder: ?{|
        +id: string
      |},
      +description: ?string,
      +startDate: ?string,
      +endDate: ?string,
      +monday: ?number,
      +tuesday: ?number,
      +wednesday: ?number,
      +thursday: ?number,
      +friday: ?number,
      +saturday: ?number,
      +sunday: ?number,
    |}>
  |}
|};
export type bulkPlaceholderAllocationsMutation = {|
  variables: bulkPlaceholderAllocationsMutationVariables,
  response: bulkPlaceholderAllocationsMutationResponse,
|};
*/


/*
mutation bulkPlaceholderAllocationsMutation(
  $input: BulkPlaceholderAllocationsInput!
) {
  bulkPlaceholderAllocations(input: $input) {
    placeholderAllocations {
      id
      placeholder {
        id
      }
      description
      startDate
      endDate
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "BulkPlaceholderAllocationsInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BulkPlaceholderAllocationsPayload",
    "kind": "LinkedField",
    "name": "bulkPlaceholderAllocations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PlaceholderAllocation",
        "kind": "LinkedField",
        "name": "placeholderAllocations",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Placeholder",
            "kind": "LinkedField",
            "name": "placeholder",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "monday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tuesday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "wednesday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thursday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "friday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "saturday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sunday",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bulkPlaceholderAllocationsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bulkPlaceholderAllocationsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "bulkPlaceholderAllocationsMutation",
    "operationKind": "mutation",
    "text": "mutation bulkPlaceholderAllocationsMutation(\n  $input: BulkPlaceholderAllocationsInput!\n) {\n  bulkPlaceholderAllocations(input: $input) {\n    placeholderAllocations {\n      id\n      placeholder {\n        id\n      }\n      description\n      startDate\n      endDate\n      monday\n      tuesday\n      wednesday\n      thursday\n      friday\n      saturday\n      sunday\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1315f250df26ddb96ad772b70c5bc519';

module.exports = node;
