import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation unmarkProgramAsFavoriteMutation($input: UnmarkProgramAsFavoriteInput!) {
		unmarkProgramAsFavorite(input: $input) {
			unmarkedProgramId
			program {
				node {
					id
					userRelationTypes
				}
			}
		}
	}
`;

function getConfigs(input) {
	const configs = [];

	configs.push({
		type: 'RANGE_DELETE',
		parentID: input.companyId,
		connectionKeys: [
			{
				key: 'favorite_programs',
			},
		],
		pathToConnection: ['company', 'programs'],
		deletedIDFieldName: 'unmarkedProgramId',
	});

	return configs;
}

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('Unmark program as favorite');

	const variables = {
		input: {
			csrfToken: Util.getCsrfValue(),
			socketClientId: Util.getClientId(),
			programId: input.programId,
		},
	};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
