/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateProjectPersonInput = {|
  projectId?: ?string,
  personId?: ?string,
  roleId?: ?string,
  projectGroupId?: ?string,
  personIds?: ?$ReadOnlyArray<?string>,
  contactIds?: ?$ReadOnlyArray<?string>,
  projectIds?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createProjectPersonClientMutationVariables = {|
  input: CreateProjectPersonInput
|};
export type createProjectPersonClientMutationResponse = {|
  +createProjectPerson: ?{|
    +projectPersonsConnection: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +person: ?{|
            +id: string,
            +profiles: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +name: ?string,
                |}
              |}>
            |},
            +permissions: ?$ReadOnlyArray<?string>,
          |},
          +project: ?{|
            +id: string
          |},
        |}
      |}>
    |}
  |}
|};
export type createProjectPersonClientMutation = {|
  variables: createProjectPersonClientMutationVariables,
  response: createProjectPersonClientMutationResponse,
|};
*/


/*
mutation createProjectPersonClientMutation(
  $input: CreateProjectPersonInput!
) {
  createProjectPerson(input: $input) {
    projectPersonsConnection {
      edges {
        node {
          id
          person {
            id
            profiles(first: 10000) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            permissions
          }
          project {
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProjectPersonInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateProjectPersonPayload",
    "kind": "LinkedField",
    "name": "createProjectPerson",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectPersonTypeConnection",
        "kind": "LinkedField",
        "name": "projectPersonsConnection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectPersonTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectPerson",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 10000
                          }
                        ],
                        "concreteType": "ProfileTypeConnection",
                        "kind": "LinkedField",
                        "name": "profiles",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfileTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Profile",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "profiles(first:10000)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "permissions",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createProjectPersonClientMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createProjectPersonClientMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createProjectPersonClientMutation",
    "operationKind": "mutation",
    "text": "mutation createProjectPersonClientMutation(\n  $input: CreateProjectPersonInput!\n) {\n  createProjectPerson(input: $input) {\n    projectPersonsConnection {\n      edges {\n        node {\n          id\n          person {\n            id\n            profiles(first: 10000) {\n              edges {\n                node {\n                  id\n                  name\n                }\n              }\n            }\n            permissions\n          }\n          project {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '117ea1b7244afcbea43fb5c4b8777a3c';

module.exports = node;
