import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteDependencyMutation($input: DeleteDependencyInput!) {
		deleteDependency(input: $input) {
			deletedDependencyId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.thisDependsOnTaskId,
			connectionKeys: [
				{
					key: 'Task_dependsOnThisTask',
				},
			],
			pathToConnection: ['task', 'dependsOnThisTask'],
			deletedIDFieldName: 'deletedDependencyId',
		},
		{
			type: 'RANGE_DELETE',
			parentID: input.taskIdDependsOnThis,
			connectionKeys: [
				{
					key: 'Task_thisTaskDependsOn',
				},
			],
			pathToConnection: ['task', 'thisTaskDependsOn'],
			deletedIDFieldName: 'deletedDependencyId',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		deleteDependency: {
			deletedDependencyId: input.id,
		},
	};
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Dependency Deleted');

	const cleanedVariables = omit(input, ['thisDependsOnTaskId', 'taskIdDependsOnThis']);
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				...cleanedVariables,
			},
		},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted,
		onError,
	});
}

export default {commit};
