/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSageIntacctInvoiceInput = {|
  invoiceId: string,
  sageIntacctCustomerId?: ?string,
  forecastClientId?: ?string,
  entryMap?: ?$ReadOnlyArray<?SageIntacctLink>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type SageIntacctLink = {|
  entryId?: ?string,
  locationId?: ?string,
  accountId?: ?string,
  projectId?: ?string,
  departmentId?: ?string,
  employeeId?: ?string,
  taskId?: ?string,
  total?: ?number,
|};
export type createSageIntacctInvoiceMutationVariables = {|
  input: CreateSageIntacctInvoiceInput
|};
export type createSageIntacctInvoiceMutationResponse = {|
  +createSageIntacctInvoice: ?{|
    +invoice: ?{|
      +id: string,
      +invoiceReference: ?string,
      +companyInvoiceId: ?number,
      +name: ?string,
      +invoiceType: ?string,
      +status: ?string,
      +externalStatus: ?string,
      +dueDay: ?number,
      +dueMonth: ?number,
      +dueYear: ?number,
      +createdDay: ?number,
      +createdMonth: ?number,
      +createdYear: ?number,
      +notes: ?string,
      +quickbooksId: ?number,
      +xeroId: ?string,
      +sageIntacctId: ?string,
      +entries: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +quantity: ?number,
            +unitPrice: ?number,
            +discount: ?number,
            +tax: ?number,
            +description: ?string,
          |}
        |}>
      |},
      +payments: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +notes: ?string,
            +amount: ?number,
            +day: ?number,
            +month: ?number,
            +year: ?number,
            +createdAt: ?string,
            +createdBy: ?{|
              +fullName: ?string
            |},
          |}
        |}>
      |},
    |},
    +success: ?boolean,
    +errorMessage: ?string,
  |}
|};
export type createSageIntacctInvoiceMutation = {|
  variables: createSageIntacctInvoiceMutationVariables,
  response: createSageIntacctInvoiceMutationResponse,
|};
*/


/*
mutation createSageIntacctInvoiceMutation(
  $input: CreateSageIntacctInvoiceInput!
) {
  createSageIntacctInvoice(input: $input) {
    invoice {
      id
      invoiceReference
      companyInvoiceId
      name
      invoiceType
      status
      externalStatus
      dueDay
      dueMonth
      dueYear
      createdDay
      createdMonth
      createdYear
      notes
      quickbooksId
      xeroId
      sageIntacctId
      entries(first: 100000000) {
        edges {
          node {
            id
            name
            quantity
            unitPrice
            discount
            tax
            description
          }
        }
      }
      payments(first: 100000000) {
        edges {
          node {
            id
            notes
            amount
            day
            month
            year
            createdAt
            createdBy {
              fullName
              id
            }
          }
        }
      }
    }
    success
    errorMessage
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSageIntacctInvoiceInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceReference",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyInvoiceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalStatus",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDay",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueMonth",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueYear",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDay",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdMonth",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdYear",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quickbooksId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xeroId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sageIntacctId",
  "storageKey": null
},
v19 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000000
  }
],
v20 = {
  "alias": null,
  "args": (v19/*: any*/),
  "concreteType": "InvoiceEntryTypeConnection",
  "kind": "LinkedField",
  "name": "entries",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceEntryTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvoiceEntryType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unitPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "entries(first:100000000)"
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errorMessage",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createSageIntacctInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSageIntacctInvoicePayload",
        "kind": "LinkedField",
        "name": "createSageIntacctInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceType",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": (v19/*: any*/),
                "concreteType": "InvoicePaymentTypeConnection",
                "kind": "LinkedField",
                "name": "payments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoicePaymentTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoicePaymentType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v15/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Person",
                            "kind": "LinkedField",
                            "name": "createdBy",
                            "plural": false,
                            "selections": [
                              (v26/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "payments(first:100000000)"
              }
            ],
            "storageKey": null
          },
          (v27/*: any*/),
          (v28/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createSageIntacctInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSageIntacctInvoicePayload",
        "kind": "LinkedField",
        "name": "createSageIntacctInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceType",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": (v19/*: any*/),
                "concreteType": "InvoicePaymentTypeConnection",
                "kind": "LinkedField",
                "name": "payments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoicePaymentTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoicePaymentType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v15/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v25/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Person",
                            "kind": "LinkedField",
                            "name": "createdBy",
                            "plural": false,
                            "selections": [
                              (v26/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "payments(first:100000000)"
              }
            ],
            "storageKey": null
          },
          (v27/*: any*/),
          (v28/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createSageIntacctInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation createSageIntacctInvoiceMutation(\n  $input: CreateSageIntacctInvoiceInput!\n) {\n  createSageIntacctInvoice(input: $input) {\n    invoice {\n      id\n      invoiceReference\n      companyInvoiceId\n      name\n      invoiceType\n      status\n      externalStatus\n      dueDay\n      dueMonth\n      dueYear\n      createdDay\n      createdMonth\n      createdYear\n      notes\n      quickbooksId\n      xeroId\n      sageIntacctId\n      entries(first: 100000000) {\n        edges {\n          node {\n            id\n            name\n            quantity\n            unitPrice\n            discount\n            tax\n            description\n          }\n        }\n      }\n      payments(first: 100000000) {\n        edges {\n          node {\n            id\n            notes\n            amount\n            day\n            month\n            year\n            createdAt\n            createdBy {\n              fullName\n              id\n            }\n          }\n        }\n      }\n    }\n    success\n    errorMessage\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4678345a04deee8311e448d54c6eae05';

module.exports = node;
