import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deletePlaceholderMutation($input: DeletePlaceholderInput!) {
		deletePlaceholder(input: $input) {
			deletedPlaceholderId
		}
	}
`;

function getOptimisticResponse(input) {
	return {
		deletePlaceholder: {
			deletedPlaceholderId: input.id,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const trackingOptions = {
		isFromContextMenu: !!input.trackingOptions?.isFromContextMenu,
	};
	tracking.trackEvent('Placeholder Deleted', trackingOptions);

	const cleanedInput = omit(input, ['companyId', 'trackingOptions']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		optimisticResponse: getOptimisticResponse(cleanedInput),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
