import {graphql} from 'react-relay';
import {omit} from 'lodash';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createFilterMutation($input: CreateFilterInput!) {
		createFilter(input: $input) {
			filter {
				node {
					id
					name
					section
					value
					updatedAt
				}
			}
			viewer {
				id
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.viewerId,
			connectionInfo: [
				{
					key: 'Viewer_filters',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'filter',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Saved Filter Created');
	const cleanedVariables = omit(input, ['viewerId']);
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
