/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VerifyFirstTimeKeyInput = {|
  key: string,
  clientMutationId?: ?string,
|};
export type verifyFirstTimeKeyMutationVariables = {|
  input: VerifyFirstTimeKeyInput
|};
export type verifyFirstTimeKeyMutationResponse = {|
  +verifyFirstTimeKey: ?{|
    +isKeyValid: ?boolean,
    +isFirstPersonInCompany: ?boolean,
    +person: ?{|
      +email: ?string,
      +firstName: ?string,
      +lastName: ?string,
      +jobTitle: ?string,
      +phone: ?string,
      +scimProvisioned: ?boolean,
      +invited: ?boolean,
      +joined: ?boolean,
      +company: ?{|
        +companySize: ?string
      |},
    |},
    +isAutojoinAvailable: ?boolean,
    +roles: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
    |}>,
  |}
|};
export type verifyFirstTimeKeyMutation = {|
  variables: verifyFirstTimeKeyMutationVariables,
  response: verifyFirstTimeKeyMutationResponse,
|};
*/


/*
mutation verifyFirstTimeKeyMutation(
  $input: VerifyFirstTimeKeyInput!
) {
  verifyFirstTimeKey(input: $input) {
    isKeyValid
    isFirstPersonInCompany
    person {
      email
      firstName
      lastName
      jobTitle
      phone
      scimProvisioned
      invited
      joined
      company {
        companySize
        id
      }
      id
    }
    isAutojoinAvailable
    roles {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "VerifyFirstTimeKeyInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isKeyValid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFirstPersonInCompany",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scimProvisioned",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invited",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "joined",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companySize",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAutojoinAvailable",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "roles",
  "plural": true,
  "selections": [
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "verifyFirstTimeKeyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyFirstTimeKeyPayload",
        "kind": "LinkedField",
        "name": "verifyFirstTimeKey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "verifyFirstTimeKeyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyFirstTimeKeyPayload",
        "kind": "LinkedField",
        "name": "verifyFirstTimeKey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "verifyFirstTimeKeyMutation",
    "operationKind": "mutation",
    "text": "mutation verifyFirstTimeKeyMutation(\n  $input: VerifyFirstTimeKeyInput!\n) {\n  verifyFirstTimeKey(input: $input) {\n    isKeyValid\n    isFirstPersonInCompany\n    person {\n      email\n      firstName\n      lastName\n      jobTitle\n      phone\n      scimProvisioned\n      invited\n      joined\n      company {\n        companySize\n        id\n      }\n      id\n    }\n    isAutojoinAvailable\n    roles {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd467520e989dacb2ca339c555e2bae98';

module.exports = node;
