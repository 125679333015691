import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation markProjectAsFavoriteMutation($input: MarkProjectAsFavoriteInput!) {
		markProjectAsFavorite(input: $input) {
			project {
				node {
					id
					userRelationTypes
				}
			}
		}
	}
`;

function getConfigs(input) {
	const configs = [];

	configs.push({
		type: 'RANGE_ADD',
		parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
		connectionInfo: [
			{
				key: 'favorite_psProjects',
				rangeBehavior: 'prepend',
			},
		],
		edgeName: 'project',
	});

	return configs;
}

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('Mark project as favorite');

	const variables = {
		input: {
			csrfToken: Util.getCsrfValue(),
			socketClientId: Util.getClientId(),
			projectId: input.projectId,
		},
	};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
