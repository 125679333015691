import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateRepeatingTaskMutation($input: UpdateRepeatingTaskInput!) {
		updateRepeatingTask(input: $input) {
			task {
				repeatingTask {
					id
					repeatType
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					monthlyDay
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const repeatingTask = {
		id: input.id,
	};
	if (input.repeatType) {
		repeatingTask.repeatType = input.repeatType;
	}
	if (input.monday) {
		repeatingTask.monday = input.monday;
	}
	if (input.tuesday) {
		repeatingTask.tuesday = input.tuesday;
	}
	if (input.wednesday) {
		repeatingTask.wednesday = input.wednesday;
	}
	if (input.thursday) {
		repeatingTask.thursday = input.thursday;
	}
	if (input.friday) {
		repeatingTask.friday = input.friday;
	}
	if (input.saturday) {
		repeatingTask.saturday = input.saturday;
	}
	if (input.sunday) {
		repeatingTask.sunday = input.sunday;
	}
	if (input.monthlyDay) {
		repeatingTask.monthlyDay = input.monthlyDay;
	}
	const task = {
		id: input.taskId,
		repeatingTask: repeatingTask,
	};
	return {
		updateRepeatingTask: {
			task: task,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Repeating Card Updated', {_Type: input.repeatType});
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
