import Util from './forecast-app/shared/util/util';
import * as amplitude from '@amplitude/analytics-browser';

/**
 * @deprecated
 * This implementation of tracking is deprecated. It should only be used for backwards compatibility with existing events.
 * All new events should use the tracking functions from TrackingV2.js
 *
 * This tracking implementation will eventually be deleted once the new events have collected enough historical data.
 */
const tracking = {
	/**
	 * @deprecated
	 */
	trackPage(page_name, insightComponents = null, columns = null, additionalProperties = null) {
		try {
			let eventProperties = {
				'page name': page_name,
			};
			if (insightComponents) {
				eventProperties.insightComponents = insightComponents;
			}
			if (columns) {
				eventProperties.columns = columns;
			}
			if (additionalProperties && typeof additionalProperties === 'object') {
				eventProperties = Object.assign(additionalProperties, eventProperties);
			}
			Util.sessionStorageSetItem('amplitude_page', page_name);
			if (process.env.CIRCLE_BRANCH !== 'production') {
				// eslint-disable-next-line no-console
				console.log('TRACKING: Page Viewed', eventProperties);
			}

			if (window.userpilot) {
				window.userpilot.track('Page Viewed', eventProperties);
			}

			amplitude.track('Page Viewed', eventProperties);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	},
	/**
	 * @deprecated
	 */
	trackEvent(event_name, options = null) {
		try {
			const page_name = sessionStorage.getItem('amplitude_page');
			const task_open = sessionStorage.getItem('amplitude_task_open') === 'true';

			if (options !== null) {
				options.page = page_name;
				options.task_modal_open = task_open;
			} else {
				options = {page: page_name, task_modal_open: task_open};
			}

			if (process.env.CIRCLE_BRANCH !== 'production') {
				// eslint-disable-next-line no-console
				console.log('TRACKING: ' + event_name, options);
			}

			amplitude.track(event_name, options);

			if (window.userpilot) {
				window.userpilot.track(event_name, options);
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	},
	/**
	 * @deprecated
	 */
	registerSuperProperty(key, value) {
		if (key === '_TaskModalOpen') {
			Util.sessionStorageSetItem('amplitude_task_open', value);
		}
	},
	/**
	 * @deprecated
	 */
	logout() {
		// Hubspot remove cookie
		document.cookie = 'hubspotutk=; Max-Age=-99999999;domain=.forecast.it;';
		document.cookie = '__hstc=; Max-Age=-99999999;domain=.forecast.it;';
	},
};

tracking.trackElementClicked = (clicked, options = null) => {
	if (clicked) {
		tracking.trackEvent(
			'Element clicked',
			Object.assign(
				{
					clicked,
				},
				options || {}
			)
		);
	}
};

tracking.trackElementHovered = (hovered, options = null) => {
	if (hovered) {
		tracking.trackEvent(
			'Element hovered',
			Object.assign(
				{
					hovered,
				},
				options || {}
			)
		);
	}
};

tracking.trackPageAction = (action, options = null) => {
	if (action) {
		tracking.trackEvent(
			'Page action',
			Object.assign(
				{
					action,
				},
				options || {}
			)
		);
	}
};

tracking.trackInfoTooltipShown = (helpIcon, placement = null) => {
	if (helpIcon) {
		const options = {
			'Help icon': helpIcon,
		};
		if (placement) {
			options.Placement = placement;
		}
		tracking.trackEvent('Info Tooltip shown', options);
	}
};

tracking.trackSectionAdded = sectionOptions => {
	if (sectionOptions) {
		tracking.trackEvent('Section added', sectionOptions);
	}
};

tracking.trackFilter = (filterValues, isLoad) => {
	if (filterValues) {
		let spreadFilterValues = [];
		let nbFilter = 0;
		// Spread the values of the filter object into a single array of strings.
		Object.keys(filterValues).forEach(group => {
			const groupFilterValues = Object.keys(filterValues[group])
				.map(key => {
					nbFilter += filterValues[group][key].length;
					return filterValues[group][key].length > 0 ? group + 'Filter-' + key : null;
				})
				.filter(val => val !== null);
			spreadFilterValues = spreadFilterValues.concat(groupFilterValues);
		});

		const track = {filters: spreadFilterValues, nbFilterApplied: nbFilter};
		const eventName = isLoad ? 'Filters loaded' : 'Filters applied';
		if (spreadFilterValues.length > 0) {
			tracking.trackEvent(eventName, track);
		}
	}
};

tracking.trackAIEvent = (event, model, selected = null, additionalOptions) => {
	if (event && model) {
		const options = {
			event,
			model,
			selected,
			additionalOptions,
		};
		tracking.trackEvent('AI Event', options);
	}
};

/**
 * @deprecated
 */
module.exports = tracking;
