import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation CreateUnit4ResourcesMutation($input: CreateUnit4ResourcesInput!) {
		createUnit4Resources(input: $input) {
			errors
			company {
				allPersons(first: 10000, excludeClientUsers: true) @connection(key: "Company_allPersons", filters: []) {
					edges {
						node {
							id
							firstName
							lastName
							initials
							fullName
							active
							email
							unit4User
							unit4UserObject
							permissions
							costPeriods(first: 10000) @connection(key: "Person_costPeriods", filters: []) {
								edges {
									node {
										id
										startDay
										startMonth
										startYear
										cost
									}
								}
							}
							profiles(first: 10000) {
								edges {
									node {
										id
										name
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
