import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createCustomFieldDefinitionMutation($input: CreateCustomFieldDefinitionInput!) {
		createCustomFieldDefinition(input: $input) {
			customFieldDefinition {
				node {
					id
					key
					displayName
					readOnly
					entityType
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_customFieldDefinitions',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'customFieldDefinition',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		createCustomFieldDefinition: {
			customFieldDefinition: {
				node: {
					id: undefined,
					key: input.key,
					displayName: input.displayName,
					readOnly: input.readOnly,
					entityType: input.entityType,
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('CustomFieldDefinition - Created');

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(cleanedInput),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
