import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation stopSageIntacctRecipesMutation($input: StopSageIntacctRecipesInput!) {
		stopSageIntacctRecipes(input: $input) {
			outcome
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId()}},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
