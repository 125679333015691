/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SubTaskInput = {|
  id?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteSubTaskMutationVariables = {|
  input: SubTaskInput
|};
export type deleteSubTaskMutationResponse = {|
  +deleteSubTask: ?{|
    +deletedSubTaskId: ?string,
    +task: ?{|
      +timeLeft: ?number,
      +latestUiUpdateAt: ?string,
      +latestUiUpdateBy: ?{|
        +firstName: ?string,
        +lastName: ?string,
      |},
    |},
  |}
|};
export type deleteSubTaskMutation = {|
  variables: deleteSubTaskMutationVariables,
  response: deleteSubTaskMutationResponse,
|};
*/


/*
mutation deleteSubTaskMutation(
  $input: SubTaskInput!
) {
  deleteSubTask(input: $input) {
    deletedSubTaskId
    task {
      timeLeft
      latestUiUpdateAt
      latestUiUpdateBy {
        firstName
        lastName
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SubTaskInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedSubTaskId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLeft",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestUiUpdateAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteSubTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubTaskPayload",
        "kind": "LinkedField",
        "name": "deleteSubTask",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "latestUiUpdateBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteSubTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubTaskPayload",
        "kind": "LinkedField",
        "name": "deleteSubTask",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "latestUiUpdateBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteSubTaskMutation",
    "operationKind": "mutation",
    "text": "mutation deleteSubTaskMutation(\n  $input: SubTaskInput!\n) {\n  deleteSubTask(input: $input) {\n    deletedSubTaskId\n    task {\n      timeLeft\n      latestUiUpdateAt\n      latestUiUpdateBy {\n        firstName\n        lastName\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '00c375ceb68f948a30fc16b7c0a6f11b';

module.exports = node;
