import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateExpenseCategoryMutation($input: UpdateExpenseCategoryInput!) {
		updateExpenseCategory(input: $input) {
			expenseCategory {
				id
				name
				disabled
				canDelete
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const expenseCategory = {id: input.id};
	if (input.name) {
		expenseCategory.name = input.name;
	}
	if (input.disabled) {
		expenseCategory.disabled = input.disabled;
	}
	return {
		updateExpenseCategory: {
			expenseCategory: expenseCategory,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('ExpenseCategory Updated', {_Changed: changes});

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
