/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateIdleTimeInput = {|
  id: string,
  name?: ?string,
  isInternalTime?: ?boolean,
  favoured?: ?boolean,
  disabled?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type favoriteIdleTimeMutationVariables = {|
  input: UpdateIdleTimeInput
|};
export type favoriteIdleTimeMutationResponse = {|
  +updateIdleTime: ?{|
    +idleTime: ?{|
      +id: string,
      +favoured: ?boolean,
    |}
  |}
|};
export type favoriteIdleTimeMutation = {|
  variables: favoriteIdleTimeMutationVariables,
  response: favoriteIdleTimeMutationResponse,
|};
*/


/*
mutation favoriteIdleTimeMutation(
  $input: UpdateIdleTimeInput!
) {
  updateIdleTime(input: $input) {
    idleTime {
      id
      favoured
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateIdleTimeInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateIdleTimePayload",
    "kind": "LinkedField",
    "name": "updateIdleTime",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "IdleTime",
        "kind": "LinkedField",
        "name": "idleTime",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "favoured",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "favoriteIdleTimeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "favoriteIdleTimeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "favoriteIdleTimeMutation",
    "operationKind": "mutation",
    "text": "mutation favoriteIdleTimeMutation(\n  $input: UpdateIdleTimeInput!\n) {\n  updateIdleTime(input: $input) {\n    idleTime {\n      id\n      favoured\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a75cc752b17ac22ab4d0e5a517d7f1b3';

module.exports = node;
