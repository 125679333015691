import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
import {addEdgeToConnection, getStoreNodeById} from '../relay-helpers/RelayCacheHelpers';

const mutation = graphql`
	mutation createProjectPersonClientMutation($input: CreateProjectPersonInput!) {
		createProjectPerson(input: $input) {
			projectPersonsConnection {
				edges {
					node {
						id
						person {
							id
							profiles(first: 10000) {
								edges {
									node {
										id
										name
									}
								}
							}
							permissions
						}
						project {
							id
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	const configs = [];

	if (input.projectId) {
		configs.push({
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_projectPersons',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'projectPerson',
		});
	}

	return configs;
}

const updater = (store, input, payload) => {
	// If client project person was added to multiple projects, bulk add them to the appropriate connections
	const projectIds = input.projectIds ? input.projectIds : input.projectId ? [input.projectId] : undefined;
	if (projectIds) {
		const createdProjectPersonEdges = payload.getLinkedRecord('projectPersonsConnection')?.getLinkedRecords('edges');

		if (!createdProjectPersonEdges) {
			return;
		}

		// The projectPersons created by the mutation have to be individually assigned to the project they were created for
		const addProjectPersonToAppropriateProject = projectNode => {
			const projectId = projectNode.getValue('id');
			const createdProjectPersonEdge = createdProjectPersonEdges.find(edge => {
				const projectPersonProjectId = edge.getLinkedRecord('node')?.getLinkedRecord('project')?.getValue('id');
				return projectId === projectPersonProjectId;
			});
			if (createdProjectPersonEdge) {
				addEdgeToConnection({
					parentNode: projectNode,
					connectionName: 'Project_projectPersons',
					newEdge: createdProjectPersonEdge,
				});
			}
		};

		projectIds.forEach(projectId => {
			const projectNode = getStoreNodeById({store, id: projectId});
			if (projectNode) {
				addProjectPersonToAppropriateProject(projectNode);
			}
		});
	}
};

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Project Person Added');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		updater: store => {
			const payload = store.getRootField('createProjectPerson');
			updater(store, input, payload);
		},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
