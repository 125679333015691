import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateClientMutation($input: UpdateClientInput!) {
		updateClient(input: $input) {
			client {
				id
				name
				street
				zip
				city
				vat
				country
				notes
				logoId
				logoDefaultId
				quickbooksCustomerId
				economicCustomerId
				xeroContactId
				salesforceAccountId
				hubspotCompanyId
				pipedriveOrganizationId
				sageIntacctCustomerId
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Client Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables,
			uploadables,
			configs: getConfigs(input),
			onCompleted: onSuccess,
			onError: onError,
		},
		true
	);
}

export default {commit};
