import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteProgramMutation($input: DeleteProgramInput!) {
		deleteProgram(input: $input) {
			deletedProgramId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_programs',
				},
				{
					key: 'favorite_programs',
				},
			],
			pathToConnection: ['company', 'programs'],
			deletedIDFieldName: 'deletedProgramId',
		},
	];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('Program Deleted');
	const {companyId, ...cleanedInput} = input;

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs({companyId, ...cleanedInput}),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
