/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetProjectSlackChannelInput = {|
  projectId?: ?string,
  channelId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type SetProjectSlackChannelMutationVariables = {|
  input: SetProjectSlackChannelInput
|};
export type SetProjectSlackChannelMutationResponse = {|
  +setProjectSlackChannel: ?{|
    +project: ?{|
      +slackChannelId: ?string
    |}
  |}
|};
export type SetProjectSlackChannelMutation = {|
  variables: SetProjectSlackChannelMutationVariables,
  response: SetProjectSlackChannelMutationResponse,
|};
*/


/*
mutation SetProjectSlackChannelMutation(
  $input: SetProjectSlackChannelInput!
) {
  setProjectSlackChannel(input: $input) {
    project {
      slackChannelId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetProjectSlackChannelInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slackChannelId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetProjectSlackChannelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetProjectSlackChannelPayload",
        "kind": "LinkedField",
        "name": "setProjectSlackChannel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetProjectSlackChannelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetProjectSlackChannelPayload",
        "kind": "LinkedField",
        "name": "setProjectSlackChannel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SetProjectSlackChannelMutation",
    "operationKind": "mutation",
    "text": "mutation SetProjectSlackChannelMutation(\n  $input: SetProjectSlackChannelInput!\n) {\n  setProjectSlackChannel(input: $input) {\n    project {\n      slackChannelId\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '83561d5db6fce9fd62943be191593e33';

module.exports = node;
