/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation OptIntoFeatureFlagMutation(
  $input: OptIntoFeatureFlagInput!
) {
  optIntoFeatureFlag(input: $input) {
    viewer {
      availableFeatureFlags {
        id
        key
      }
      id
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "OptIntoFeatureFlagInput!"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "FeatureFlag",
        "kind": "LinkedField",
        "name": "availableFeatureFlags",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "OptIntoFeatureFlagMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "OptIntoFeatureFlagPayload",
                    "kind": "LinkedField",
                    "name": "optIntoFeatureFlag",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Viewer",
                            "kind": "LinkedField",
                            "name": "viewer",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "OptIntoFeatureFlagMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "OptIntoFeatureFlagPayload",
                    "kind": "LinkedField",
                    "name": "optIntoFeatureFlag",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Viewer",
                            "kind": "LinkedField",
                            "name": "viewer",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "OptIntoFeatureFlagMutation",
            "operationKind": "mutation",
            "text": "mutation OptIntoFeatureFlagMutation(\n  $input: OptIntoFeatureFlagInput!\n) {\n  optIntoFeatureFlag(input: $input) {\n    viewer {\n      availableFeatureFlags {\n        id\n        key\n      }\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '45584a5f01b028af543288938df26b60';
export default node;
