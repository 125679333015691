/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFixedPriceLockInput = {|
  id: string,
  amount?: ?string,
  locked?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateFixedPriceLockMutationVariables = {|
  input: UpdateFixedPriceLockInput
|};
export type updateFixedPriceLockMutationResponse = {|
  +updateFixedPriceLock: ?{|
    +fixedPriceLock: ?{|
      +id: string,
      +locked: ?boolean,
      +actualRevenueProjectCurrency: ?number,
      +progress: ?number,
    |}
  |}
|};
export type updateFixedPriceLockMutation = {|
  variables: updateFixedPriceLockMutationVariables,
  response: updateFixedPriceLockMutationResponse,
|};
*/


/*
mutation updateFixedPriceLockMutation(
  $input: UpdateFixedPriceLockInput!
) {
  updateFixedPriceLock(input: $input) {
    fixedPriceLock {
      id
      locked
      actualRevenueProjectCurrency
      progress
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFixedPriceLockInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateFixedPriceLockPayload",
    "kind": "LinkedField",
    "name": "updateFixedPriceLock",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectFixedPriceLockType",
        "kind": "LinkedField",
        "name": "fixedPriceLock",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locked",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actualRevenueProjectCurrency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "progress",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateFixedPriceLockMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateFixedPriceLockMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateFixedPriceLockMutation",
    "operationKind": "mutation",
    "text": "mutation updateFixedPriceLockMutation(\n  $input: UpdateFixedPriceLockInput!\n) {\n  updateFixedPriceLock(input: $input) {\n    fixedPriceLock {\n      id\n      locked\n      actualRevenueProjectCurrency\n      progress\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9896fdbe7c13396e7068baca1d658944';

module.exports = node;
