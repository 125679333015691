import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation ImportBambooHRUsersMutation($input: ImportBambooHRUsersInput!) {
		importBambooHRUsers(input: $input) {
			company {
				allPersons(first: 10000, excludeClientUsers: true) @connection(key: "Company_allPersons", filters: []) {
					edges {
						node {
							id
							firstName
							lastName
							initials
							fullName
							profiles(first: 10000) {
								edges {
									node {
										id
										name
									}
								}
							}
							active
							email
							bambooHRId
							permissions
							costPeriods(first: 10000) @connection(key: "Person_costPeriods", filters: []) {
								edges {
									node {
										id
										startDay
										startMonth
										startYear
										cost
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
