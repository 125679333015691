/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DisableQuickbooksIntegrationInput = {|
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type disableQuickbooksIntegrationMutationVariables = {|
  input: DisableQuickbooksIntegrationInput
|};
export type disableQuickbooksIntegrationMutationResponse = {|
  +disableQuickbooksIntegration: ?{|
    +company: ?{|
      +quickbooksEnabled: ?boolean
    |}
  |}
|};
export type disableQuickbooksIntegrationMutation = {|
  variables: disableQuickbooksIntegrationMutationVariables,
  response: disableQuickbooksIntegrationMutationResponse,
|};
*/


/*
mutation disableQuickbooksIntegrationMutation(
  $input: DisableQuickbooksIntegrationInput!
) {
  disableQuickbooksIntegration(input: $input) {
    company {
      quickbooksEnabled
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DisableQuickbooksIntegrationInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quickbooksEnabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "disableQuickbooksIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DisableQuickbooksIntegrationPayload",
        "kind": "LinkedField",
        "name": "disableQuickbooksIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "disableQuickbooksIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DisableQuickbooksIntegrationPayload",
        "kind": "LinkedField",
        "name": "disableQuickbooksIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "disableQuickbooksIntegrationMutation",
    "operationKind": "mutation",
    "text": "mutation disableQuickbooksIntegrationMutation(\n  $input: DisableQuickbooksIntegrationInput!\n) {\n  disableQuickbooksIntegration(input: $input) {\n    company {\n      quickbooksEnabled\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3faaedb8f68a6ad118573ad9a7f3c743';

module.exports = node;
