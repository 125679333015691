import React from 'react';
import PropTypes from 'prop-types';
import {EVENT_ID, subscribe, unsubscribe} from '../containers/event_manager';
import {withRouter} from 'react-router-dom';
import * as Sentry from '@sentry/browser';

export function withSimpleSocketHandling(WrappedComponent) {
	// ...and returns another component...
	class WithSimpleSocketHandling extends React.Component {
		constructor(props) {
			super(props);
			this.handleSocketNotify = this.handleSocketNotify.bind(this);

			this.setSocketConfig = this.setSocketConfig.bind(this);
			this.setSocketVariables = this.setSocketVariables.bind(this);

			this.updatePending = false;

			document.addEventListener('visibilitychange', event => {
				if (!document.hidden) {
					// Is visible
					if (this.updatePending) {
						//Have pending update. Update now!
						this.updateComponent();
					}
					this.updatePending = false;
				}
			});
		}

		componentDidMount() {
			// ... that takes care of the subscription...
			subscribe(EVENT_ID.SOCKET_NOTIFY, this.handleSocketNotify);
		}

		componentWillUnmount() {
			unsubscribe(EVENT_ID.SOCKET_NOTIFY, this.handleSocketNotify);
		}

		setSocketConfig(config, refetchFunc) {
			this.config = config.map(c => {
				if (c.entityIds && !Array.isArray(c.entityIds)) {
					c.entityIds = [c.entityIds];
				}
				return c;
			});

			if (refetchFunc) {
				this.refetchFunc = refetchFunc;
			}
		}

		setSocketVariables(variables) {
			this.refetchVariables = variables;
		}

		handleSocketNotify(dataList) {
			if (!this.config) return;
			let shouldUpdate = false;

			// Go through list of events to see if this component should update
			dataList.forEach(data => {
				this.config.forEach(event => {
					// Check if component is listening for this particular event
					if (event.type === data.eventType && event.action === data.eventAction) {
						if (event.entityIds) {
							shouldUpdate =
								event.entityIds &&
								data.entityIds &&
								event.entityIds.some(p => data.entityIds.some(dataId => dataId === p));
						} else {
							shouldUpdate = true;
						}
						console.log('socket', event, data, shouldUpdate);
					}
				});
			});

			if (shouldUpdate) {
				if (document.hidden) {
					// Page not visible. Set update pending flag
					this.updatePending = true;
				} else {
					// Page visible. Updating as normal
					this.updateComponent();
				}
			}
		}

		updateComponent() {
			if (this.refetchFunc && this.refetchVariables) {
				this.refetchFunc(this.refetchVariables);
			} else if (this.props.retry) {
				this.props.retry();
			} else {
				Sentry.captureMessage(
					'Missing relay.forceFetch on query: ' +
						this.props.viewer.component +
						' - ' +
						getDisplayName(WrappedComponent)
				);
			}
		}

		render() {
			// ... and renders the wrapped component with the fresh data!
			// Notice that we pass through any additional props
			return (
				<WrappedComponent
					{...this.props}
					setSocketConfig={this.setSocketConfig}
					setSocketVariables={this.setSocketVariables}
				/>
			);
		}
	}

	WithSimpleSocketHandling.propTypes = {
		retry: PropTypes.func,
	};

	WithSimpleSocketHandling.displayName = `WithSimpleSocketHandling(${getDisplayName(WrappedComponent)})`;
	return withRouter(WithSimpleSocketHandling);
}

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
