import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation verifyFirstTimeKeyMutation($input: VerifyFirstTimeKeyInput!) {
		verifyFirstTimeKey(input: $input) {
			isKeyValid
			isFirstPersonInCompany
			person {
				email
				firstName
				lastName
				jobTitle
				phone
				scimProvisioned
				invited
				joined
				company {
					companySize
				}
			}
			isAutojoinAvailable
			roles {
				id
				name
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input};
	return commitMutation(environment, {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
