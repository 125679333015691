/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PERIODICITY = "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type UpdateRetainerPeriodBulkInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  periodHoursAmount?: ?number,
  periodPriceAmount?: ?number,
  periodLocked?: ?boolean,
  periodLockedTime?: ?string,
  rolloverIdsToRevert?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateRetainerPeriodBulkMutationVariables = {|
  input: UpdateRetainerPeriodBulkInput
|};
export type updateRetainerPeriodBulkMutationResponse = {|
  +updateRetainerPeriodBulk: ?{|
    +errors: ?$ReadOnlyArray<?string>,
    +project: ?{|
      +retainerPeriods: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +startYear: ?number,
            +startMonth: ?number,
            +startDay: ?number,
            +endYear: ?number,
            +endMonth: ?number,
            +endDay: ?number,
            +available: ?number,
            +periodLength: ?number,
            +periodPeriodicity: ?PERIODICITY,
            +periodPriceAmount: ?number,
            +periodHoursAmount: ?number,
            +periodBudgetType: ?PERIOD_BUDGET_TYPE,
            +periodSettingIgnoreForBilling: ?boolean,
            +periodSettingSubtractValue: ?boolean,
            +periodSettingRollValue: ?boolean,
            +periodSettingAddExpenses: ?boolean,
            +periodDifferencePriceAmount: ?number,
            +periodDifferenceHoursAmount: ?number,
            +sharedPeriodDifferenceHoursAmount: ?number,
            +sharedPeriodDifferencePriceAmount: ?number,
            +ignoredRolloverHours: ?number,
            +ignoredRolloverPrice: ?number,
            +periodLocked: ?boolean,
            +periodLockedTime: ?string,
            +periodDifferenceSplit: ?boolean,
          |}
        |}>
      |}
    |},
  |}
|};
export type updateRetainerPeriodBulkMutation = {|
  variables: updateRetainerPeriodBulkMutationVariables,
  response: updateRetainerPeriodBulkMutationResponse,
|};
*/


/*
mutation updateRetainerPeriodBulkMutation(
  $input: UpdateRetainerPeriodBulkInput!
) {
  updateRetainerPeriodBulk(input: $input) {
    errors
    project {
      retainerPeriods(first: 100000) {
        edges {
          node {
            id
            name
            startYear
            startMonth
            startDay
            endYear
            endMonth
            endDay
            available
            periodLength
            periodPeriodicity
            periodPriceAmount
            periodHoursAmount
            periodBudgetType
            periodSettingIgnoreForBilling
            periodSettingSubtractValue
            periodSettingRollValue
            periodSettingAddExpenses
            periodDifferencePriceAmount
            periodDifferenceHoursAmount
            sharedPeriodDifferenceHoursAmount
            sharedPeriodDifferencePriceAmount
            ignoredRolloverHours
            ignoredRolloverPrice
            periodLocked
            periodLockedTime
            periodDifferenceSplit
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateRetainerPeriodBulkInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100000
    }
  ],
  "concreteType": "RetainerPeriodTypeConnection",
  "kind": "LinkedField",
  "name": "retainerPeriods",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RetainerPeriodTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RetainerPeriodType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "available",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodLength",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodPeriodicity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodPriceAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodHoursAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodBudgetType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodSettingIgnoreForBilling",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodSettingSubtractValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodSettingRollValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodSettingAddExpenses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodDifferencePriceAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodDifferenceHoursAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sharedPeriodDifferenceHoursAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sharedPeriodDifferencePriceAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ignoredRolloverHours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ignoredRolloverPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodLocked",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodLockedTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "periodDifferenceSplit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "retainerPeriods(first:100000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateRetainerPeriodBulkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRetainerPeriodBulkPayload",
        "kind": "LinkedField",
        "name": "updateRetainerPeriodBulk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateRetainerPeriodBulkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRetainerPeriodBulkPayload",
        "kind": "LinkedField",
        "name": "updateRetainerPeriodBulk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateRetainerPeriodBulkMutation",
    "operationKind": "mutation",
    "text": "mutation updateRetainerPeriodBulkMutation(\n  $input: UpdateRetainerPeriodBulkInput!\n) {\n  updateRetainerPeriodBulk(input: $input) {\n    errors\n    project {\n      retainerPeriods(first: 100000) {\n        edges {\n          node {\n            id\n            name\n            startYear\n            startMonth\n            startDay\n            endYear\n            endMonth\n            endDay\n            available\n            periodLength\n            periodPeriodicity\n            periodPriceAmount\n            periodHoursAmount\n            periodBudgetType\n            periodSettingIgnoreForBilling\n            periodSettingSubtractValue\n            periodSettingRollValue\n            periodSettingAddExpenses\n            periodDifferencePriceAmount\n            periodDifferenceHoursAmount\n            sharedPeriodDifferenceHoursAmount\n            sharedPeriodDifferencePriceAmount\n            ignoredRolloverHours\n            ignoredRolloverPrice\n            periodLocked\n            periodLockedTime\n            periodDifferenceSplit\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8b8da6d0b52268e2ebdb14a8a2a28712';

module.exports = node;
