import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

//TODO: Not sure if fetching warnings should be moved to a separate mutation since it is only used for budget?
const mutation = graphql`
	mutation updateProjectMutationModernMutation($input: UpdateProjectInput!) {
		updateProject(input: $input) {
			errors
			project {
				...ProjectSettingsPage_project
				synchBaselineAndScopingDates
				useFixedPriceForBaselineRevenue
				useDeliverables
				flatRate
				flatCost
				customProjectId
				company {
					roles(first: 10000) {
						edges {
							node {
								id
								name
							}
						}
					}
					modules {
						moduleType
					}
					expenseCategories(first: 10000) {
						edges {
							node {
								id
								name
							}
						}
					}
				}
				xeroInvoices {
					id
					amountTotal
					amountNoTax
					amountPaid
				}
				invoices(first: 100000000) {
					edges {
						node {
							id
							name
							invoiceType
							status
							entries(first: 100000000) {
								edges {
									node {
										id
										name
										quantity
										unitPrice
										discount
										tax
										description
									}
								}
							}
							payments(first: 100000000) {
								edges {
									node {
										id
										amount
									}
								}
							}
						}
					}
				}
				baselineWinChance
				projectLabels(first: 10000) {
					edges {
						node {
							id
							label {
								id
								name
								color
							}
						}
					}
				}
				client {
					id
					name
					street
					vat
					zip
					city
					country
					notes
					logoId
					clientUsers(first: 1000) {
						edges {
							node {
								id
								firstName
								lastName
								email
								phone
							}
						}
					}
				}
				rateCard {
					currency
					name
					defaultRate
					currency
					parentRateCardId
					disabledRoles {
						id
					}
					rates(first: 10000) {
						edges {
							node {
								id
								rate
								defaultRate
								startDate
								role {
									id
									name
								}
							}
						}
					}
				}
				phases(first: 10000) {
					edges {
						node {
							id
							startYear
							startMonth
							startDay
							deadlineDay
							deadlineMonth
							deadlineYear
							baselineStartYear
							baselineStartMonth
							baselineStartDay
							baselineDeadlineDay
							baselineDeadlineMonth
							baselineDeadlineYear
						}
					}
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const project = {id: input.project ? input.project.id : input.id};
	if (input.name) {
		project.name = input.name;
	}
	if (input.status) {
		project.status = input.status;
	}
	if (input.description) {
		project.description = input.description;
	}
	if (input.estimationUnit) {
		project.estimationUnit = input.estimationUnit;
	}
	if (input.sprintTimeBox !== undefined) {
		project.sprintTimeBox = input.sprintTimeBox;
	}
	if (input.sprintLengthInDays) {
		project.sprintLengthInDays = input.sprintLengthInDays;
	}
	if (input.taskLevels) {
		project.taskLevels = input.taskLevels;
	}
	if (input.statusColumns) {
		project.statusColumns = input.statusColumns;
	}
	if (input.clientId) {
		project.client = {id: input.clientId};
	}
	if (input.rateCardId) {
		project.rateCard = {id: input.rateCardId};
	}
	if (input.minutesPerEstimationPoint) {
		project.minutesPerEstimationPoint = input.minutesPerEstimationPoint;
	}
	if (input.budget) {
		project.budget = input.budget;
	}

	if (input.harvestProjectId !== undefined) {
		if (input.harvestProjectId !== '') {
			project.harvestProject = {id: input.harvestProjectId};
		} else {
			project.harvestProject = null;
		}
	}
	if (input.newHarvestProjectId !== undefined) {
		if (input.newHarvestProjectId !== '') {
			project.harvestProject = {id: input.newHarvestProjectId};
		} else {
			project.harvestProject = null;
		}
	}
	if (input.slackChannelId !== undefined) {
		if (input.slackChannelId !== '') {
			project.slackChannel = {id: btoa(`SlackChannel:${input.slackChannelId}`)};
		} else {
			project.slackChannel = null;
		}
	}
	if (input.projectColor === null) {
		project.projectColor = null;
	} else if (input.projectColor !== undefined) {
		project.projectColor = input.projectColor;
	}
	if (input.projectStartYear !== undefined) {
		project.projectStartYear = input.projectStartYear;
		project.projectStartMonth = input.projectStartMonth;
		project.projectStartDay = input.projectStartDay;

		const projectFirstDate = Util.CreateNonUtcMomentDate(
			input.project.projectFirstDateYear,
			input.project.projectFirstDateMonth,
			input.project.projectFirstDateDay
		);
		const projectStartDate = Util.CreateNonUtcMomentDate(
			input.projectStartYear,
			input.projectStartMonth,
			input.projectStartDay
		);
		if (!projectFirstDate || (projectStartDate !== null && projectFirstDate.isAfter(projectStartDate))) {
			project.projectFirstDateYear = input.projectStartYear;
			project.projectFirstDateMonth = input.projectStartMonth;
			project.projectFirstDateDay = input.projectStartDay;
		}
	}
	if (input.projectEndYear !== undefined) {
		project.projectEndYear = input.projectEndYear;
		project.projectEndMonth = input.projectEndMonth;
		project.projectEndDay = input.projectEndDay;

		const projectLastDate = Util.CreateNonUtcMomentDate(
			input.project.projectLastDateYear,
			input.project.projectLastDateMonth,
			input.project.projectLastDateDay
		);
		const projectEndDate = Util.CreateNonUtcMomentDate(input.projectEndYear, input.projectEndMonth, input.projectEndDay);
		if (!projectLastDate || (projectEndDate !== null && projectLastDate.isBefore(projectEndDate))) {
			project.projectLastDateYear = input.projectEndYear;
			project.projectLastDateMonth = input.projectEndMonth;
			project.projectLastDateDay = input.projectEndDay;
		}
	}
	if (input.billable !== undefined) {
		project.billable = input.billable;
	}
	if (input.excludedHarvestTasks !== undefined) {
		project.excludedHarvestTasks = input.excludedHarvestTasks.map(value => {
			return {id: value};
		});
	}
	if (input.salesforceOpportunity !== undefined) {
		project.salesforceOpportunity = input.salesforceOpportunity;
	}
	if (input.unit4Project !== undefined) {
		project.unit4Project = {id: input.unit4Project};
	}
	if (input.vstsProject !== undefined) {
		project.vstsProject = input.vstsProject;
	}
	if (input.vstsArea !== undefined) {
		project.vstsArea = input.vstsArea;
	}
	if (input.vstsSyncBugs !== undefined) {
		project.vstsSyncBugs = input.vstsSyncBugs;
	}
	if (input.vstsUseEpics !== undefined) {
		project.vstsUseEpics = input.vstsUseEpics;
	}
	if (input.githubRepositoryIds !== undefined) {
		project.githubRepositoryIds = input.githubRepositoryIds;
	}
	if (input.remainingAutoCalculated !== undefined) {
		project.remainingAutoCalculated = input.remainingAutoCalculated;
	}
	if (input.useManualAllocations !== undefined) {
		project.useManualAllocations = input.useManualAllocations;
	}
	if (input.useTaskHierarchy !== undefined) {
		project.useTaskHierarchy = input.useTaskHierarchy;
	}
	if (input.useTaskOwner !== undefined) {
		project.useTaskOwner = input.useTaskOwner;
	}
	if (input.useTaskFollowers !== undefined) {
		project.useTaskFollowers = input.useTaskFollowers;
	}
	if (input.currencyExchangeRate !== undefined) {
		project.currencyExchangeRate = input.currencyExchangeRate;
	}
	if (input.taskCostCalculationType !== undefined) {
		project.taskCostCalculationType = input.taskCostCalculationType;
	}
	if (input.unassignedTaskHourlyCost !== undefined) {
		project.unassignedTaskHourlyCost = input.unassignedTaskHourlyCost;
	}
	if (input.defaultPeriodPeriodicity !== undefined) {
		project.defaultPeriodPeriodicity = input.defaultPeriodPeriodicity;
	}
	if (input.defaultPeriodLength !== undefined) {
		project.defaultPeriodLength = input.defaultPeriodLength;
	}
	if (input.retainerBudgetType !== undefined) {
		project.retainerBudgetType = input.retainerBudgetType;
	}
	if (input.defaultPeriodBudgetType !== undefined) {
		project.defaultPeriodBudgetType = input.defaultPeriodBudgetType;
	}
	if (input.defaultPeriodHoursAmount !== undefined) {
		project.defaultPeriodHoursAmount = input.defaultPeriodHoursAmount;
	}
	if (input.defaultPeriodPriceAmount !== undefined) {
		project.defaultPeriodPriceAmount = input.defaultPeriodPriceAmount;
	}
	if (input.defaultPeriodSettingIgnoreForBilling !== undefined) {
		project.defaultPeriodSettingIgnoreForBilling = input.defaultPeriodSettingIgnoreForBilling;
	}
	if (input.defaultPeriodSettingSubtractValue !== undefined) {
		project.defaultPeriodSettingSubtractValue = input.defaultPeriodSettingSubtractValue;
	}
	if (input.defaultPeriodSettingRollValue !== undefined) {
		project.defaultPeriodSettingRollValue = input.defaultPeriodSettingRollValue;
	}
	if (input.defaultPeriodSettingAddExpenses !== undefined) {
		project.defaultPeriodSettingAddExpenses = input.defaultPeriodSettingAddExpenses;
	}
	if (input.publicCreateTaskPageStatusColumnId !== undefined) {
		project.publicCreateTaskPageStatusColumnId = input.publicCreateTaskPageStatusColumnId;
	}
	if (input.usePublicCreateTaskPassword !== undefined) {
		project.usePublicCreateTaskPassword = input.usePublicCreateTaskPassword;
	}
	if (input.publicCreateTaskPassword !== undefined) {
		project.publicCreateTaskPassword = input.publicCreateTaskPassword;
	}
	if (input.baselineTargetHours !== undefined) {
		project.baselineTargetHours = input.baselineTargetHours;
	}
	if (input.baselineTargetPrice !== undefined) {
		project.baselineTargetPrice = input.baselineTargetPrice;
	}
	if (input.labels !== undefined) {
		project.projectLabels = {};
		project.projectLabels.edges = input.labels.map(label => {
			return {node: {label: {id: label}}};
		});
	}
	if (input.priorityLevelId !== undefined) {
		project.priorityLevel = {id: input.priorityLevelId};
	}
	if (input.manualProgressOnProjectEnabled !== undefined) {
		project.manualProgressOnProjectEnabled = input.manualProgressOnProjectEnabled;
		project.progressDetails = {progress: undefined};
	}
	if (input.manualProgressOnPhasesEnabled !== undefined) {
		project.manualProgressOnPhasesEnabled = input.manualProgressOnPhasesEnabled;
	}
	if (input.manualProgressOnTasksEnabled !== undefined) {
		project.manualProgressOnTasksEnabled = input.manualProgressOnTasksEnabled;
	}
	if (input.flatRate !== undefined) {
		project.flatRate = input.flatRate;
	}
	if (input.flatCost !== undefined) {
		project.flatCost = input.flatCost;
	}
	if (input.financialSourceSettings !== undefined) {
		project.financialSourceSettings = input.financialSourceSettings;
	}
	if (input.jiraCutoffDate !== undefined) {
		project.jiraCloudProjectSettings = {isSyncDone: false};
		project.jiraCutoffDate = input.jiraCutoffDate;
	}
	if (input.sageProjectId !== undefined) {
		project.sageProject = {sageProjectId: input.sageProjectId};
	}
	if (input.useDeliverables !== undefined) {
		project.useDeliverables = input.useDeliverables;
	}
	return {
		updateProject: {
			project: project,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	if (
		input.project &&
		input.project.statusColumns &&
		input.statusColumns &&
		input.statusColumns.length !== input.project.statusColumns.length
	) {
		if (input.statusColumns.length > input.project.statusColumns.length) {
			tracking.trackEvent('Workflow Column Created');
		} else {
			tracking.trackEvent('Workflow Column Deleted');
		}
	} else {
		const changes = [];

		let status;

		for (const key of Object.keys(input)) {
			if (['id', 'project', 'disabledRolesChanged'].includes(key) || input[key] === undefined) {
				continue;
			}

			if (key === 'status') {
				status = input.status;
			}

			changes.push(key);
		}

		const options = {_Changed: changes};
		if (status) {
			options.Status = status;
		}
		tracking.trackEvent('Project Updated', options);
	}

	const cleanedVariables = omit(input, ['project', 'disabledRolesChanged']);
	if (input.project) {
		cleanedVariables.id = input.project.id;
	}

	const additionalTracking = {disabledRolesChanged: input.disabledRolesChanged};

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}};
	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables,
			optimisticResponse: getOptimisticResponse(input),
			onCompleted: onSuccess,
			onError: onError,
			updater: store => {
				if (input.hasOwnProperty('customProjectId')) {
					/* We need to invalidate the cache for the project when changing the custom project id.
					 * Otherwise this project would be returned from the cache using the old custom project id
					 * Causing problems when reusing the old custom project id for a new project.
					 * */
					const project = store.get(input.project?.id);
					if (project != null) {
						project.invalidateRecord();
					}
				}
			},
		},
		true,
		undefined,
		additionalTracking
	);
}

export default {commit};
