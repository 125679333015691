/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsHolidayCalendars_holidayCalendarEntry$ref = any;
export type CreateHolidayCalendarEntryInput = {|
  name?: ?string,
  year?: ?number,
  month?: ?number,
  day?: ?number,
  holidayCalendarId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createHolidayCalendarEntryMutationVariables = {|
  input: CreateHolidayCalendarEntryInput
|};
export type createHolidayCalendarEntryMutationResponse = {|
  +createHolidayCalendarEntry: ?{|
    +holidayCalendarEntry: ?{|
      +node: ?{|
        +$fragmentRefs: settingsHolidayCalendars_holidayCalendarEntry$ref
      |}
    |}
  |}
|};
export type createHolidayCalendarEntryMutation = {|
  variables: createHolidayCalendarEntryMutationVariables,
  response: createHolidayCalendarEntryMutationResponse,
|};
*/


/*
mutation createHolidayCalendarEntryMutation(
  $input: CreateHolidayCalendarEntryInput!
) {
  createHolidayCalendarEntry(input: $input) {
    holidayCalendarEntry {
      node {
        ...settingsHolidayCalendars_holidayCalendarEntry
        id
      }
    }
  }
}

fragment settingsHolidayCalendars_holidayCalendarEntry on HolidayCalendarEntry {
  id
  name
  year
  month
  day
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateHolidayCalendarEntryInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createHolidayCalendarEntryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateHolidayCalendarEntryPayload",
        "kind": "LinkedField",
        "name": "createHolidayCalendarEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HolidayCalendarEntryTypeEdge",
            "kind": "LinkedField",
            "name": "holidayCalendarEntry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HolidayCalendarEntry",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "settingsHolidayCalendars_holidayCalendarEntry"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createHolidayCalendarEntryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateHolidayCalendarEntryPayload",
        "kind": "LinkedField",
        "name": "createHolidayCalendarEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HolidayCalendarEntryTypeEdge",
            "kind": "LinkedField",
            "name": "holidayCalendarEntry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HolidayCalendarEntry",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "year",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "month",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "day",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createHolidayCalendarEntryMutation",
    "operationKind": "mutation",
    "text": "mutation createHolidayCalendarEntryMutation(\n  $input: CreateHolidayCalendarEntryInput!\n) {\n  createHolidayCalendarEntry(input: $input) {\n    holidayCalendarEntry {\n      node {\n        ...settingsHolidayCalendars_holidayCalendarEntry\n        id\n      }\n    }\n  }\n}\n\nfragment settingsHolidayCalendars_holidayCalendarEntry on HolidayCalendarEntry {\n  id\n  name\n  year\n  month\n  day\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e7aac779fda1dde9f80d2881c2fcdbec';

module.exports = node;
