import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation UpdateProjectRevenueMarginMutation($input: UpdateProjectInput!) {
		updateProject(input: $input) {
			project {
				revenueMarginLow
				revenueMarginHigh
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const project = {id: input.id};
	if (input.revenueMarginLow !== undefined) {
		project.revenueMarginLow = input.revenueMarginLow;
	}
	if (input.revenueMarginHigh !== undefined) {
		project.revenueMarginHigh = input.revenueMarginHigh;
	}
	return {
		updateProject: {
			project: project,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id', 'project'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	const options = {_Changed: changes};
	tracking.trackEvent('Project Updated', options);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
