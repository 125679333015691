import styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../../css_variables';

const pageSpacing = '18px 85px 18px 66px';

const smallPageSpacing = '16px 24px 24px 24px';

export const PageTabsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, max-content);
	grid-gap: 22px;
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	text-transform: capitalize;
	margin: ${props => (props.noMargins ? null : props.smallMargin ? smallPageSpacing : pageSpacing)};
	margin-bottom: 0;
	&.edit-invoice-modal-tabs {
		flex-grow: 1;
	}
`;

export const TabStyled = styled.div`
	padding-top: 5px;
	cursor: pointer;
	color: ${({isActive}) => (isActive ? CSS_CONSTANTS.v2_branding_purple : 'inherit')};
	border-bottom: ${({isActive}) => (isActive ? `3px solid ${CSS_CONSTANTS.v2_branding_purple}` : 'none')};
	padding-bottom: ${({isActive}) => (isActive ? '5px' : 'initial')};
	font-weight: ${({isActive}) => (isActive ? '500' : 'initial')};

	&:hover {
		color: ${CSS_CONSTANTS.v2_branding_purple};
	}
`;

export const PageWrapper = styled.div`
	margin: ${pageSpacing};
	padding-bottom: 66px;
`;

export const StatsContainer = styled.div`
	display: grid;
	grid-template-columns: ${props => (props.columns ? 'repeat(' + props.columns + ', 1fr)' : 'repeat(3, 1fr)')};
	grid-gap: 20px;
	margin-bottom: 46px;
	flex-grow: ${({growable}) => growable && 1};
`;

export const StatBox = styled.div`
	border: 1px solid ${CSS_CONSTANTS.app_border_color};
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: space-around;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

export const StatTitle = styled.div`
	align-self: center;
	margin: 10px;
	font-size: 14px;
`;

export const StatValue = styled.div`
	align-self: center;
	margin: 10px;
	font-size: 24px;
	white-space: nowrap;
	max-width: 330px;
	overflow: auto;
	@media screen and (max-width: 1300px) {
		& {
			max-width: 300px;
		}
	}

	@media screen and (max-width: 1200px) {
		& {
			font-size: 16px;
		}
	}
`;

export const TableWrapper = styled.div`
	margin-bottom: 40px;
`;

export const InvoiceLinesTableContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 350px;
	flex-grow: 1;
`;

export const InvoiceLinesDetailsSection = styled.div`
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
`;

export const AddLineButton = styled.div`
	.add-button-component {
		width: 32px;
		height: 32px;
	}
`;

export const ProjectDropdownCell = styled.div`
    ${props => (props.customHeight ? `height: ${props.customHeight}px; box-sizing: content-box;` : '')}
	flex-grow: 1;
	display: flex;
	align-items: center;
	min-width: 150px;
	padding: 9px 0;
	${props => (props.maxWidth ? `max-width: ${props.maxWidth}px;` : '')}

	.drop-down-v2 {
		flex-grow: 1;
	}

	svg {
		margin-right: 5px;
	}
`;

export const InvoiceDetails = styled.div`
	display: flex;
	margin-left: auto;
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

export const DetailLabelColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-right: 24px;
`;

export const DetailValueColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const DetailLabel = styled.div`
	${props => (props.isTotal ? 'font-size: 14px' : '')};
	${props => (props.isTotal ? 'font-weight: 700;' : '')};
	${props => (props.isTotal ? 'margin-top: 13px;' : '')};
`;

export const DetailValue = styled.div`
	${props => (props.isTotal ? 'font-size: 14px' : '')};
	${props => (props.isTotal ? 'font-weight: 700;' : '')};
	${props => (props.isTotal ? 'margin-top: 13px;' : '')};
	max-width: 500px;
`;

export const InputWithErrorWrapper = styled.div`
	display: flex;
	align-items: center;

	svg {
		margin-right: 5px;
	}
`;

export const ErrorMessage = styled.div`
	display: flex;
	align-items: right;
	font-size: 12px;
	color: ${CSS_CONSTANTS.negative_value};
	align-items: flex-end;
	height: 50px;
	margin-left: 8px;
`;

export const TextArea = styled.textarea`
	position: absolute;
	top: 9px;
	overflow: hidden;
	color: #535353;
	line-height: 18px;
	background-color: ${CSS_CONSTANTS.color_white};
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	resize: none;
	border-radius: 3px;
	border: solid 1px ${props => (props.error ? `${CSS_CONSTANTS.negative_value}` : `${CSS_CONSTANTS.v2_border_gray}`)};
	outline: none;
	padding: 6px 8px;
	width: 243px;
	height: 16px;
	&::placeholder {
		font-size: 13px;
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		opacity: 1;
	}

	&:disabled {
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		background-color: #f3f3f3;
		&:hover {
			border-color: ${CSS_CONSTANTS.v2_border_gray};
		}
	}
	&:hover {
		border-color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
	}
	&:focus {
		border-color: #6e0fea;
		outline: none;
		width: 500px;
		height: 200px;
		z-index: 99;
		overflow: auto;
		transition: all 0.2s ease-in-out;
	}
`;

export const LargeTextArea = styled.textarea`
	position: absolute;
	top: 9px;
	overflow: hidden;
	background-color: ${CSS_CONSTANTS.color_white};
	resize: none;
	padding: 6px 0px;
	width: ${props => (`${props.customWidth}px`)};
	height: 35px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	white-space: break-spaces;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsi;
	justify-content: center;
	line-height: 1.7;
	font-size: 13px;
	border: 0px none;
`;

export const UneditableMessageBox = styled.div`
	border: 1px solid #e6e6e6;
	height: 60x;
	width: 550px;
	padding: 10px 15px 10px 15px;
	margin-top: 20px;
	font-size: 13px;
	color: #4f4e4e;
`;

export const MessageContainer = styled.div`
	float: right;
	width: 470px;
	margin-top: 6px;
`;

export const PadLockContainer = styled.div`
	float: left;
`;