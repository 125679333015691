import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation moveTasksToCurrentSprintMutation($input: MoveTasksToCurrentSprintInput!) {
		moveTasksToCurrentSprint(input: $input) {
			projectGroup {
				projects(first: 10000) {
					edges {
						node {
							tasks(first: 10000) {
								edges {
									node {
										id
										sprint {
											id
										}
									}
								}
							}
						}
					}
				}
			}
			project {
				tasks(first: 10000) {
					edges {
						node {
							id
							sprint {
								id
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Card Moved TO Current Sprint');

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
