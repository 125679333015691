import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation CheckSisenseSeatsMutation($input: CheckSisenseSeatsInput!) {
		checkSisenseSeats(input: $input) {
			designerSeats
			viewerSeats
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				...input,
			},
		},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
