/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DuplicateSavedReportInput = {|
  id: string,
  reportService?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type duplicateSavedReportMutationVariables = {|
  input: DuplicateSavedReportInput
|};
export type duplicateSavedReportMutationResponse = {|
  +duplicateSavedReport: ?{|
    +savedReport: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +type: ?string,
        +person: ?{|
          +id: string,
          +fullName: ?string,
          +email: ?string,
          +profilePictureId: ?string,
        |},
        +reportService: ?boolean,
        +shares: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +user: ?{|
                +id: string,
                +email: ?string,
              |},
            |}
          |}>
        |},
        +updatedAt: ?string,
        +privateAccess: ?boolean,
        +favorite: ?boolean,
        +state: ?string,
      |}
    |}
  |}
|};
export type duplicateSavedReportMutation = {|
  variables: duplicateSavedReportMutationVariables,
  response: duplicateSavedReportMutationResponse,
|};
*/


/*
mutation duplicateSavedReportMutation(
  $input: DuplicateSavedReportInput!
) {
  duplicateSavedReport(input: $input) {
    savedReport {
      node {
        id
        name
        type
        person {
          id
          fullName
          email
          profilePictureId
        }
        reportService
        shares(first: 100) {
          edges {
            node {
              id
              user {
                id
                email
              }
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        updatedAt
        privateAccess
        favorite
        state
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DuplicateSavedReportInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reportService",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ReportShareTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReportShare",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportShareUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privateAccess",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "favorite",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "duplicateSavedReportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateSavedReportPayload",
        "kind": "LinkedField",
        "name": "duplicateSavedReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedReportTypeEdge",
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SavedReportType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": "shares",
                    "args": null,
                    "concreteType": "ReportShareTypeConnection",
                    "kind": "LinkedField",
                    "name": "__Report_shares_connection",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "duplicateSavedReportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateSavedReportPayload",
        "kind": "LinkedField",
        "name": "duplicateSavedReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedReportTypeEdge",
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SavedReportType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": (v13/*: any*/),
                    "concreteType": "ReportShareTypeConnection",
                    "kind": "LinkedField",
                    "name": "shares",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": "shares(first:100)"
                  },
                  {
                    "alias": null,
                    "args": (v13/*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "Report_shares",
                    "kind": "LinkedHandle",
                    "name": "shares"
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "duplicateSavedReport",
            "savedReport",
            "node",
            "shares"
          ]
        }
      ]
    },
    "name": "duplicateSavedReportMutation",
    "operationKind": "mutation",
    "text": "mutation duplicateSavedReportMutation(\n  $input: DuplicateSavedReportInput!\n) {\n  duplicateSavedReport(input: $input) {\n    savedReport {\n      node {\n        id\n        name\n        type\n        person {\n          id\n          fullName\n          email\n          profilePictureId\n        }\n        reportService\n        shares(first: 100) {\n          edges {\n            node {\n              id\n              user {\n                id\n                email\n              }\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        updatedAt\n        privateAccess\n        favorite\n        state\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '46c88a64f5309d4ec5356fa5996740f3';

module.exports = node;
