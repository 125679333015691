import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createExchangeRateMutation($input: CreateExchangeRateInput!) {
		createExchangeRate(input: $input) {
			exchangeRate {
				node {
					id
					currency
					rate
					isUsed
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_exchangeRates',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'exchangeRate',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		createExchangeRate: {
			exchangeRate: {
				node: {
					currency: input.currency,
					rate: input.rate,
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Exchange rate Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				currency: input.currency,
				rate: input.rate,
			},
		},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
