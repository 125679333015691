/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation CreateAdoUsersMutation(
  $input: CreateAdoUsersInput!
) {
  createAdoUsers(input: $input) {
    company {
      allPersons(first: 10000, excludeClientUsers: true) {
        edges {
          node {
            id
            firstName
            lastName
            initials
            fullName
            active
            email
            adoUserId
            permissions
            cost
            costPeriods(first: 10000) {
              edges {
                node {
                  id
                  startDay
                  startMonth
                  startYear
                  cost
                  __typename
                }
                cursor
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
            profiles(first: 10000) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
    errors
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "CreateAdoUsersInput!"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "initials",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "adoUserId",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissions",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cost",
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v15 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "CostPeriodTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CostPeriod",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDay",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startMonth",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startYear",
                            "storageKey": null
                        },
                        (v11 /*: any*/),
                        (v12 /*: any*/)
                    ],
                    "storageKey": null
                },
                (v13 /*: any*/)
            ],
            "storageKey": null
        },
        (v14 /*: any*/)
    ], v16 = {
        "kind": "Literal",
        "name": "first",
        "value": 10000
    }, v17 = [
        (v16 /*: any*/)
    ], v18 = {
        "alias": null,
        "args": (v17 /*: any*/),
        "concreteType": "ProfileTypeConnection",
        "kind": "LinkedField",
        "name": "profiles",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "ProfileTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Profile",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            (v2 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": "profiles(first:10000)"
    }, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
    }, v20 = [
        {
            "kind": "Literal",
            "name": "excludeClientUsers",
            "value": true
        },
        (v16 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateAdoUsersMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateAdoUsersPayload",
                    "kind": "LinkedField",
                    "name": "createAdoUsers",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": "allPersons",
                                    "args": null,
                                    "concreteType": "PersonTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "__Company_allPersons_connection",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PersonTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Person",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v7 /*: any*/),
                                                        (v8 /*: any*/),
                                                        (v9 /*: any*/),
                                                        (v10 /*: any*/),
                                                        (v11 /*: any*/),
                                                        {
                                                            "alias": "costPeriods",
                                                            "args": null,
                                                            "concreteType": "CostPeriodTypeConnection",
                                                            "kind": "LinkedField",
                                                            "name": "__Person_costPeriods_connection",
                                                            "plural": false,
                                                            "selections": (v15 /*: any*/),
                                                            "storageKey": null
                                                        },
                                                        (v18 /*: any*/),
                                                        (v12 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v13 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v14 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v19 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateAdoUsersMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateAdoUsersPayload",
                    "kind": "LinkedField",
                    "name": "createAdoUsers",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v20 /*: any*/),
                                    "concreteType": "PersonTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "allPersons",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PersonTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Person",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v7 /*: any*/),
                                                        (v8 /*: any*/),
                                                        (v9 /*: any*/),
                                                        (v10 /*: any*/),
                                                        (v11 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": (v17 /*: any*/),
                                                            "concreteType": "CostPeriodTypeConnection",
                                                            "kind": "LinkedField",
                                                            "name": "costPeriods",
                                                            "plural": false,
                                                            "selections": (v15 /*: any*/),
                                                            "storageKey": "costPeriods(first:10000)"
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": (v17 /*: any*/),
                                                            "filters": [],
                                                            "handle": "connection",
                                                            "key": "Person_costPeriods",
                                                            "kind": "LinkedHandle",
                                                            "name": "costPeriods"
                                                        },
                                                        (v18 /*: any*/),
                                                        (v12 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v13 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v14 /*: any*/)
                                    ],
                                    "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
                                },
                                {
                                    "alias": null,
                                    "args": (v20 /*: any*/),
                                    "filters": [],
                                    "handle": "connection",
                                    "key": "Company_allPersons",
                                    "kind": "LinkedHandle",
                                    "name": "allPersons"
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v19 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {
                "connection": [
                    {
                        "count": null,
                        "cursor": null,
                        "direction": "forward",
                        "path": null
                    },
                    {
                        "count": null,
                        "cursor": null,
                        "direction": "forward",
                        "path": [
                            "createAdoUsers",
                            "company",
                            "allPersons"
                        ]
                    }
                ]
            },
            "name": "CreateAdoUsersMutation",
            "operationKind": "mutation",
            "text": "mutation CreateAdoUsersMutation(\n  $input: CreateAdoUsersInput!\n) {\n  createAdoUsers(input: $input) {\n    company {\n      allPersons(first: 10000, excludeClientUsers: true) {\n        edges {\n          node {\n            id\n            firstName\n            lastName\n            initials\n            fullName\n            active\n            email\n            adoUserId\n            permissions\n            cost\n            costPeriods(first: 10000) {\n              edges {\n                node {\n                  id\n                  startDay\n                  startMonth\n                  startYear\n                  cost\n                  __typename\n                }\n                cursor\n              }\n              pageInfo {\n                endCursor\n                hasNextPage\n              }\n            }\n            profiles(first: 10000) {\n              edges {\n                node {\n                  id\n                  name\n                }\n              }\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n    errors\n  }\n}\n"
        }
    };
})();
node.hash = 'a01fc2394c92d7d6760a064d6f7baf7b';
export default node;
