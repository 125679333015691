/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MovePhaseOnTimelineInput = {|
  id?: ?string,
  projectId?: ?string,
  previousProjectId?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  deadlineYear?: ?number,
  deadlineMonth?: ?number,
  deadlineDay?: ?number,
  isJiraPhase?: ?boolean,
  isWeekendHidden?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type movePhaseOnTimelineMutationModernMutationVariables = {|
  input: MovePhaseOnTimelineInput
|};
export type movePhaseOnTimelineMutationModernMutationResponse = {|
  +movePhaseOnTimeline: ?{|
    +phase: ?{|
      +id: string,
      +name: ?string,
      +startDay: ?number,
      +startMonth: ?number,
      +startYear: ?number,
      +startFrom: ?string,
      +deadlineDay: ?number,
      +deadlineMonth: ?number,
      +deadlineYear: ?number,
      +deadlineFrom: ?string,
    |},
    +previousProject: ?{|
      +phases: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
          |}
        |}>
      |}
    |},
    +project: ?{|
      +tasks: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +project: ?{|
              +id: string
            |},
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
            +startFrom: ?string,
            +deadlineDay: ?number,
            +deadlineMonth: ?number,
            +deadlineYear: ?number,
            +deadlineFrom: ?string,
          |}
        |}>
      |},
      +phases: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
          |}
        |}>
      |},
    |},
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type movePhaseOnTimelineMutationModernMutation = {|
  variables: movePhaseOnTimelineMutationModernMutationVariables,
  response: movePhaseOnTimelineMutationModernMutationResponse,
|};
*/


/*
mutation movePhaseOnTimelineMutationModernMutation(
  $input: MovePhaseOnTimelineInput!
) {
  movePhaseOnTimeline(input: $input) {
    phase {
      id
      name
      startDay
      startMonth
      startYear
      startFrom
      deadlineDay
      deadlineMonth
      deadlineYear
      deadlineFrom
    }
    previousProject {
      phases(first: 10000) {
        edges {
          node {
            id
            name
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
    project {
      tasks(first: 10000) {
        edges {
          node {
            project {
              id
            }
            startDay
            startMonth
            startYear
            startFrom
            deadlineDay
            deadlineMonth
            deadlineYear
            deadlineFrom
            id
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      phases(first: 10000) {
        edges {
          node {
            id
            name
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "MovePhaseOnTimelineInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startFrom",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineFrom",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "PhaseType",
  "kind": "LinkedField",
  "name": "phase",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PhaseTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PhaseType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      },
      (v14/*: any*/)
    ],
    "storageKey": null
  },
  (v15/*: any*/)
],
v17 = {
  "alias": "phases",
  "args": null,
  "concreteType": "PhaseTypeConnection",
  "kind": "LinkedField",
  "name": "__Project_phases_connection",
  "plural": false,
  "selections": (v16/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v20 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v21 = {
  "alias": null,
  "args": (v20/*: any*/),
  "concreteType": "PhaseTypeConnection",
  "kind": "LinkedField",
  "name": "phases",
  "plural": false,
  "selections": (v16/*: any*/),
  "storageKey": "phases(first:10000)"
},
v22 = {
  "alias": null,
  "args": (v20/*: any*/),
  "filters": null,
  "handle": "connection",
  "key": "Project_phases",
  "kind": "LinkedHandle",
  "name": "phases"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "movePhaseOnTimelineMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MovePhaseOnTimelinePayload",
        "kind": "LinkedField",
        "name": "movePhaseOnTimeline",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "previousProject",
            "plural": false,
            "selections": [
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": "tasks",
                "args": null,
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "__Project_tasks_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "movePhaseOnTimelineMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MovePhaseOnTimelinePayload",
        "kind": "LinkedField",
        "name": "movePhaseOnTimeline",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "previousProject",
            "plural": false,
            "selections": [
              (v21/*: any*/),
              (v22/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v20/*: any*/),
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "tasks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v2/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": "tasks(first:10000)"
              },
              {
                "alias": null,
                "args": (v20/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Project_tasks",
                "kind": "LinkedHandle",
                "name": "tasks"
              },
              (v21/*: any*/),
              (v22/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "movePhaseOnTimeline",
            "previousProject",
            "phases"
          ]
        },
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "movePhaseOnTimeline",
            "project",
            "tasks"
          ]
        },
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "movePhaseOnTimeline",
            "project",
            "phases"
          ]
        }
      ]
    },
    "name": "movePhaseOnTimelineMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation movePhaseOnTimelineMutationModernMutation(\n  $input: MovePhaseOnTimelineInput!\n) {\n  movePhaseOnTimeline(input: $input) {\n    phase {\n      id\n      name\n      startDay\n      startMonth\n      startYear\n      startFrom\n      deadlineDay\n      deadlineMonth\n      deadlineYear\n      deadlineFrom\n    }\n    previousProject {\n      phases(first: 10000) {\n        edges {\n          node {\n            id\n            name\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n    project {\n      tasks(first: 10000) {\n        edges {\n          node {\n            project {\n              id\n            }\n            startDay\n            startMonth\n            startYear\n            startFrom\n            deadlineDay\n            deadlineMonth\n            deadlineYear\n            deadlineFrom\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      phases(first: 10000) {\n        edges {\n          node {\n            id\n            name\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1656637631c59d1ea01413f0ed4dbc28';

module.exports = node;
