import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation sharedReportLoginMutation($input: SharedReportLoginInput!) {
		sharedReportLogin(input: $input) {
			viewer {
				id
			}
			success
			errors
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Shared Report Login');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {...input}},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
