/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DismissSimilarTaskInput = {|
  taskId?: ?string,
  dismissedTaskId?: ?string,
  similarTaskId1?: ?string,
  similarTaskId2?: ?string,
  similarTaskId3?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type DismissSimilarTaskMutationVariables = {|
  input: DismissSimilarTaskInput
|};
export type DismissSimilarTaskMutationResponse = {|
  +dismissSimilarTask: ?{|
    +task: ?{|
      +id: string,
      +similarTasks: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +companyTaskId: ?number,
            +name: ?string,
            +estimateForecast: ?number,
            +fullAccessToProject: ?boolean,
            +project: ?{|
              +id: string,
              +name: ?string,
              +projectColor: ?string,
              +companyProjectId: ?number,
            |},
            +timeRegistrations: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +minutesRegistered: ?number
                |}
              |}>
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type DismissSimilarTaskMutation = {|
  variables: DismissSimilarTaskMutationVariables,
  response: DismissSimilarTaskMutationResponse,
|};
*/


/*
mutation DismissSimilarTaskMutation(
  $input: DismissSimilarTaskInput!
) {
  dismissSimilarTask(input: $input) {
    task {
      id
      similarTasks(overrunOnly: true) {
        edges {
          node {
            id
            companyTaskId
            name
            estimateForecast
            fullAccessToProject
            project {
              id
              name
              projectColor
              companyProjectId
            }
            timeRegistrations(first: 1000000) {
              edges {
                node {
                  minutesRegistered
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DismissSimilarTaskInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "overrunOnly",
    "value": true
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyTaskId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimateForecast",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullAccessToProject",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyProjectId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutesRegistered",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DismissSimilarTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DismissSimilarTaskPayload",
        "kind": "LinkedField",
        "name": "dismissSimilarTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "similarTasks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": (v9/*: any*/),
                            "concreteType": "TimeRegTypeConnection",
                            "kind": "LinkedField",
                            "name": "timeRegistrations",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeRegTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TimeRegType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "timeRegistrations(first:1000000)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "similarTasks(overrunOnly:true)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DismissSimilarTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DismissSimilarTaskPayload",
        "kind": "LinkedField",
        "name": "dismissSimilarTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "similarTasks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": (v9/*: any*/),
                            "concreteType": "TimeRegTypeConnection",
                            "kind": "LinkedField",
                            "name": "timeRegistrations",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeRegTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TimeRegType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "timeRegistrations(first:1000000)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "similarTasks(overrunOnly:true)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "DismissSimilarTaskMutation",
    "operationKind": "mutation",
    "text": "mutation DismissSimilarTaskMutation(\n  $input: DismissSimilarTaskInput!\n) {\n  dismissSimilarTask(input: $input) {\n    task {\n      id\n      similarTasks(overrunOnly: true) {\n        edges {\n          node {\n            id\n            companyTaskId\n            name\n            estimateForecast\n            fullAccessToProject\n            project {\n              id\n              name\n              projectColor\n              companyProjectId\n            }\n            timeRegistrations(first: 1000000) {\n              edges {\n                node {\n                  minutesRegistered\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aca1f54ea597a819956973da714195fd';

module.exports = node;
