import * as Sentry from '@sentry/browser';
import { sendTrackingMessage } from '../TrackingMessage';
import { TRACKING_EVENT_TYPE } from './TRACKING_EVENT_TYPE';
const constructTaskModalViewedBody = ({ companyId, personId, taskId }) => {
    return {
        eventType: TRACKING_EVENT_TYPE.TASK_MODAL_VIEWED,
        companyId,
        personId,
        taskId,
    };
};
export const trackTaskModalViewed = (body) => {
    try {
        const s3Body = constructTaskModalViewedBody(body);
        sendTrackingMessage(s3Body);
    }
    catch (err) {
        Sentry.captureException(err);
        console.log('Tracking failed, reported to Sentry', err);
    }
};
