/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkUpdateExpenseItemsInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  approved?: ?boolean,
  expenseCategoryId?: ?string,
  projectId?: ?string,
  phaseId?: ?string,
  billable?: ?boolean,
  partOfFixedPrice?: ?boolean,
  markup?: ?number,
  personId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type bulkUpdateExpenseItemsMutationVariables = {|
  input: BulkUpdateExpenseItemsInput
|};
export type bulkUpdateExpenseItemsMutationResponse = {|
  +bulkUpdateExpenseItems: ?{|
    +expenseItems: ?$ReadOnlyArray<?{|
      +name: ?string,
      +approved: ?boolean,
      +expenseYear: ?number,
      +expenseMonth: ?number,
      +expenseDay: ?number,
      +billable: ?boolean,
      +partOfFixedPrice: ?boolean,
      +price: ?number,
      +priceBaseCurrency: ?number,
      +cost: ?number,
      +costBaseCurrency: ?number,
      +quantity: ?number,
      +invoiced: ?boolean,
      +planned: ?boolean,
      +createdAt: ?string,
      +person: ?{|
        +id: string,
        +fullName: ?string,
        +profilePictureId: ?string,
        +profilePictureDefaultId: ?number,
      |},
      +category: ?{|
        +id: string
      |},
      +phase: ?{|
        +id: string
      |},
      +project: ?{|
        +id: string,
        +client: ?{|
          +id: string,
          +name: ?string,
        |},
      |},
    |}>
  |}
|};
export type bulkUpdateExpenseItemsMutation = {|
  variables: bulkUpdateExpenseItemsMutationVariables,
  response: bulkUpdateExpenseItemsMutationResponse,
|};
*/


/*
mutation bulkUpdateExpenseItemsMutation(
  $input: BulkUpdateExpenseItemsInput!
) {
  bulkUpdateExpenseItems(input: $input) {
    expenseItems {
      name
      approved
      expenseYear
      expenseMonth
      expenseDay
      billable
      partOfFixedPrice
      price
      priceBaseCurrency
      cost
      costBaseCurrency
      quantity
      invoiced
      planned
      createdAt
      person {
        id
        fullName
        profilePictureId
        profilePictureDefaultId
      }
      category {
        id
      }
      phase {
        id
      }
      project {
        id
        client {
          id
          name
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "BulkUpdateExpenseItemsInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approved",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expenseYear",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expenseMonth",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expenseDay",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partOfFixedPrice",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceBaseCurrency",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costBaseCurrency",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiced",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planned",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureDefaultId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = [
  (v17/*: any*/)
],
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ExpenseCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "PhaseType",
  "kind": "LinkedField",
  "name": "phase",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v17/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bulkUpdateExpenseItemsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdateExpenseItemsPayload",
        "kind": "LinkedField",
        "name": "bulkUpdateExpenseItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExpenseItem",
            "kind": "LinkedField",
            "name": "expenseItems",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bulkUpdateExpenseItemsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdateExpenseItemsPayload",
        "kind": "LinkedField",
        "name": "bulkUpdateExpenseItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExpenseItem",
            "kind": "LinkedField",
            "name": "expenseItems",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "bulkUpdateExpenseItemsMutation",
    "operationKind": "mutation",
    "text": "mutation bulkUpdateExpenseItemsMutation(\n  $input: BulkUpdateExpenseItemsInput!\n) {\n  bulkUpdateExpenseItems(input: $input) {\n    expenseItems {\n      name\n      approved\n      expenseYear\n      expenseMonth\n      expenseDay\n      billable\n      partOfFixedPrice\n      price\n      priceBaseCurrency\n      cost\n      costBaseCurrency\n      quantity\n      invoiced\n      planned\n      createdAt\n      person {\n        id\n        fullName\n        profilePictureId\n        profilePictureDefaultId\n      }\n      category {\n        id\n      }\n      phase {\n        id\n      }\n      project {\n        id\n        client {\n          id\n          name\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '291da76b029b29203010c501173a6c8c';

module.exports = node;
