/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateExpenseItemInput = {|
  id?: ?string,
  projectId?: ?string,
  companyProjectId?: ?number,
  name?: ?string,
  billable?: ?boolean,
  partOfFixedPrice?: ?boolean,
  cost?: ?number,
  price?: ?number,
  quantity?: ?number,
  approved?: ?boolean,
  expenseYear?: ?number,
  expenseMonth?: ?number,
  expenseDay?: ?number,
  notes?: ?string,
  planned?: ?boolean,
  personId?: ?string,
  expenseCategoryId?: ?string,
  phaseId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateExpenseItemMutationVariables = {|
  input: UpdateExpenseItemInput
|};
export type updateExpenseItemMutationResponse = {|
  +updateExpenseItem: ?{|
    +expenseItem: ?{|
      +node: ?{|
        +name: ?string,
        +approved: ?boolean,
        +expenseYear: ?number,
        +expenseMonth: ?number,
        +expenseDay: ?number,
        +billable: ?boolean,
        +partOfFixedPrice: ?boolean,
        +price: ?number,
        +costBaseCurrency: ?number,
        +priceBaseCurrency: ?number,
        +cost: ?number,
        +quantity: ?number,
        +planned: ?boolean,
        +createdAt: ?string,
        +invoiced: ?boolean,
        +person: ?{|
          +id: string,
          +fullName: ?string,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
        |},
        +category: ?{|
          +id: string
        |},
        +phase: ?{|
          +id: string
        |},
        +project: ?{|
          +id: string,
          +client: ?{|
            +id: string,
            +name: ?string,
          |},
        |},
      |}
    |}
  |}
|};
export type updateExpenseItemMutation = {|
  variables: updateExpenseItemMutationVariables,
  response: updateExpenseItemMutationResponse,
|};
*/


/*
mutation updateExpenseItemMutation(
  $input: UpdateExpenseItemInput!
) {
  updateExpenseItem(input: $input) {
    expenseItem {
      node {
        name
        approved
        expenseYear
        expenseMonth
        expenseDay
        billable
        partOfFixedPrice
        price
        costBaseCurrency
        priceBaseCurrency
        cost
        quantity
        planned
        createdAt
        invoiced
        person {
          id
          fullName
          profilePictureId
          profilePictureDefaultId
        }
        category {
          id
        }
        phase {
          id
        }
        project {
          id
          client {
            id
            name
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateExpenseItemInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approved",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expenseYear",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expenseMonth",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expenseDay",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partOfFixedPrice",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costBaseCurrency",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceBaseCurrency",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planned",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiced",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureDefaultId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = [
  (v17/*: any*/)
],
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ExpenseCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "PhaseType",
  "kind": "LinkedField",
  "name": "phase",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v17/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateExpenseItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExpenseItemPayload",
        "kind": "LinkedField",
        "name": "updateExpenseItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExpenseItemTypeEdge",
            "kind": "LinkedField",
            "name": "expenseItem",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExpenseItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v18/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateExpenseItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExpenseItemPayload",
        "kind": "LinkedField",
        "name": "updateExpenseItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExpenseItemTypeEdge",
            "kind": "LinkedField",
            "name": "expenseItem",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExpenseItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v18/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateExpenseItemMutation",
    "operationKind": "mutation",
    "text": "mutation updateExpenseItemMutation(\n  $input: UpdateExpenseItemInput!\n) {\n  updateExpenseItem(input: $input) {\n    expenseItem {\n      node {\n        name\n        approved\n        expenseYear\n        expenseMonth\n        expenseDay\n        billable\n        partOfFixedPrice\n        price\n        costBaseCurrency\n        priceBaseCurrency\n        cost\n        quantity\n        planned\n        createdAt\n        invoiced\n        person {\n          id\n          fullName\n          profilePictureId\n          profilePictureDefaultId\n        }\n        category {\n          id\n        }\n        phase {\n          id\n        }\n        project {\n          id\n          client {\n            id\n            name\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b82e54aea9f60b2f4effc7e7acf76e5b';

module.exports = node;
