/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetSlackNotificationSettingInput = {|
  setting?: ?string,
  personId?: ?string,
  value?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type SetSlackNotificationSettingMutationVariables = {|
  input: SetSlackNotificationSettingInput
|};
export type SetSlackNotificationSettingMutationResponse = {|
  +setSlackNotificationSetting: ?{|
    +slackNotificationSettings: ?{|
      +disableNotifications: ?boolean,
      +notifyOnProjectStatusChange: ?boolean,
      +notifyOnAssignedProject: ?boolean,
      +notifyOnPersonJoin: ?boolean,
      +notifyOnProjectDeadline: ?boolean,
      +notifyOnAssignedTask: ?boolean,
      +notifyOnAssignedTaskUpdate: ?boolean,
      +notifyOnTaskDeadline: ?boolean,
      +notifyOnMention: ?boolean,
      +notifyOnTaskDescriptionChange: ?boolean,
      +notifyOnTaskTitleChange: ?boolean,
      +notifyOnTaskEstimateChange: ?boolean,
      +notifyOnTaskStartDateChange: ?boolean,
      +notifyOnTaskEndDateChange: ?boolean,
      +notifyOnTaskSprintChange: ?boolean,
      +notifyOnTaskPhaseChange: ?boolean,
      +notifyOnTaskStatusColumnChange: ?boolean,
      +notifyOnTaskProjectChange: ?boolean,
      +notifyOnTaskSubtaskChange: ?boolean,
      +notifyOnTaskSubtaskEstimateChange: ?boolean,
      +notifyOnTaskCommentChange: ?boolean,
      +notifyOnTaskFileChange: ?boolean,
      +notifyOnTaskBugChange: ?boolean,
      +notifyOnTaskBlockedChange: ?boolean,
      +notifyOnTaskRepeatingChange: ?boolean,
      +notifyOnInvoiceDueDate: ?boolean,
      +notifyOnInvoiceOverdue: ?boolean,
      +notifyOnInvoiceDaysOverdue: ?boolean,
      +notifyOnInvoiceCreatedOrDeleted: ?boolean,
      +notifyOnInvoiceStatusChange: ?boolean,
      +notifyOnInvoiceDateReached: ?boolean,
      +notifyOnInvoicePayment: ?boolean,
      +notifyOnInvoiceDateChanged: ?boolean,
      +notifyOnInvoiceDueDateChanged: ?boolean,
      +notifyOnTimeOffManager: ?boolean,
      +notifyOnTimeOffOwner: ?boolean,
    |}
  |}
|};
export type SetSlackNotificationSettingMutation = {|
  variables: SetSlackNotificationSettingMutationVariables,
  response: SetSlackNotificationSettingMutationResponse,
|};
*/


/*
mutation SetSlackNotificationSettingMutation(
  $input: SetSlackNotificationSettingInput!
) {
  setSlackNotificationSetting(input: $input) {
    slackNotificationSettings {
      disableNotifications
      notifyOnProjectStatusChange
      notifyOnAssignedProject
      notifyOnPersonJoin
      notifyOnProjectDeadline
      notifyOnAssignedTask
      notifyOnAssignedTaskUpdate
      notifyOnTaskDeadline
      notifyOnMention
      notifyOnTaskDescriptionChange
      notifyOnTaskTitleChange
      notifyOnTaskEstimateChange
      notifyOnTaskStartDateChange
      notifyOnTaskEndDateChange
      notifyOnTaskSprintChange
      notifyOnTaskPhaseChange
      notifyOnTaskStatusColumnChange
      notifyOnTaskProjectChange
      notifyOnTaskSubtaskChange
      notifyOnTaskSubtaskEstimateChange
      notifyOnTaskCommentChange
      notifyOnTaskFileChange
      notifyOnTaskBugChange
      notifyOnTaskBlockedChange
      notifyOnTaskRepeatingChange
      notifyOnInvoiceDueDate
      notifyOnInvoiceOverdue
      notifyOnInvoiceDaysOverdue
      notifyOnInvoiceCreatedOrDeleted
      notifyOnInvoiceStatusChange
      notifyOnInvoiceDateReached
      notifyOnInvoicePayment
      notifyOnInvoiceDateChanged
      notifyOnInvoiceDueDateChanged
      notifyOnTimeOffManager
      notifyOnTimeOffOwner
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetSlackNotificationSettingInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetSlackNotificationSettingPayload",
    "kind": "LinkedField",
    "name": "setSlackNotificationSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationSettingsType",
        "kind": "LinkedField",
        "name": "slackNotificationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disableNotifications",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnProjectStatusChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnAssignedProject",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnPersonJoin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnProjectDeadline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnAssignedTask",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnAssignedTaskUpdate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskDeadline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnMention",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskDescriptionChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskTitleChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskEstimateChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskStartDateChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskEndDateChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskSprintChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskPhaseChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskStatusColumnChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskProjectChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskSubtaskChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskSubtaskEstimateChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskCommentChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskFileChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskBugChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskBlockedChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTaskRepeatingChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnInvoiceDueDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnInvoiceOverdue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnInvoiceDaysOverdue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnInvoiceCreatedOrDeleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnInvoiceStatusChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnInvoiceDateReached",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnInvoicePayment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnInvoiceDateChanged",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnInvoiceDueDateChanged",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTimeOffManager",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notifyOnTimeOffOwner",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetSlackNotificationSettingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetSlackNotificationSettingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SetSlackNotificationSettingMutation",
    "operationKind": "mutation",
    "text": "mutation SetSlackNotificationSettingMutation(\n  $input: SetSlackNotificationSettingInput!\n) {\n  setSlackNotificationSetting(input: $input) {\n    slackNotificationSettings {\n      disableNotifications\n      notifyOnProjectStatusChange\n      notifyOnAssignedProject\n      notifyOnPersonJoin\n      notifyOnProjectDeadline\n      notifyOnAssignedTask\n      notifyOnAssignedTaskUpdate\n      notifyOnTaskDeadline\n      notifyOnMention\n      notifyOnTaskDescriptionChange\n      notifyOnTaskTitleChange\n      notifyOnTaskEstimateChange\n      notifyOnTaskStartDateChange\n      notifyOnTaskEndDateChange\n      notifyOnTaskSprintChange\n      notifyOnTaskPhaseChange\n      notifyOnTaskStatusColumnChange\n      notifyOnTaskProjectChange\n      notifyOnTaskSubtaskChange\n      notifyOnTaskSubtaskEstimateChange\n      notifyOnTaskCommentChange\n      notifyOnTaskFileChange\n      notifyOnTaskBugChange\n      notifyOnTaskBlockedChange\n      notifyOnTaskRepeatingChange\n      notifyOnInvoiceDueDate\n      notifyOnInvoiceOverdue\n      notifyOnInvoiceDaysOverdue\n      notifyOnInvoiceCreatedOrDeleted\n      notifyOnInvoiceStatusChange\n      notifyOnInvoiceDateReached\n      notifyOnInvoicePayment\n      notifyOnInvoiceDateChanged\n      notifyOnInvoiceDueDateChanged\n      notifyOnTimeOffManager\n      notifyOnTimeOffOwner\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5581c8b8bc9d09870f39799bb33a07a1';

module.exports = node;
