/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteInvoiceInput = {|
  id?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteInvoiceMutationVariables = {|
  input: DeleteInvoiceInput
|};
export type deleteInvoiceMutationResponse = {|
  +deleteInvoice: ?{|
    +deletedInvoiceId: ?string
  |}
|};
export type deleteInvoiceMutation = {|
  variables: deleteInvoiceMutationVariables,
  response: deleteInvoiceMutationResponse,
|};
*/


/*
mutation deleteInvoiceMutation(
  $input: DeleteInvoiceInput!
) {
  deleteInvoice(input: $input) {
    deletedInvoiceId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteInvoiceInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteInvoicePayload",
    "kind": "LinkedField",
    "name": "deleteInvoice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedInvoiceId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteInvoiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteInvoiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation deleteInvoiceMutation(\n  $input: DeleteInvoiceInput!\n) {\n  deleteInvoice(input: $input) {\n    deletedInvoiceId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'da22f466466342677980c35efa598bda';

module.exports = node;
