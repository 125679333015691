/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteSkillCategoryInput = {|
  id?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteSkillCategoryMutationVariables = {|
  input: DeleteSkillCategoryInput
|};
export type deleteSkillCategoryMutationResponse = {|
  +deleteSkillCategory: ?{|
    +deletedSkillCategoryId: ?string
  |}
|};
export type deleteSkillCategoryMutation = {|
  variables: deleteSkillCategoryMutationVariables,
  response: deleteSkillCategoryMutationResponse,
|};
*/


/*
mutation deleteSkillCategoryMutation(
  $input: DeleteSkillCategoryInput!
) {
  deleteSkillCategory(input: $input) {
    deletedSkillCategoryId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteSkillCategoryInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteSkillCategoryPayload",
    "kind": "LinkedField",
    "name": "deleteSkillCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedSkillCategoryId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteSkillCategoryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteSkillCategoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteSkillCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation deleteSkillCategoryMutation(\n  $input: DeleteSkillCategoryInput!\n) {\n  deleteSkillCategory(input: $input) {\n    deletedSkillCategoryId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '661bfd5e8ef03cff76d2e99e67139224';

module.exports = node;
