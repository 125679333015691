/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateExpenseItemInput = {|
  projectId?: ?string,
  companyProjectId?: ?number,
  name?: ?string,
  billable?: ?boolean,
  partOfFixedPrice?: ?boolean,
  cost?: ?number,
  price?: ?number,
  quantity?: ?number,
  approved?: ?boolean,
  expenseYear?: ?number,
  expenseMonth?: ?number,
  expenseDay?: ?number,
  planned?: ?boolean,
  notes?: ?string,
  personId?: ?string,
  expenseCategoryId?: ?string,
  phaseId?: ?string,
  xeroPurchaseOrderId?: ?string,
  sageIntacctPurchaseOrderId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createExpenseItemMutationVariables = {|
  input: CreateExpenseItemInput
|};
export type createExpenseItemMutationResponse = {|
  +createExpenseItem: ?{|
    +expenseItem: ?{|
      +node: ?{|
        +id: string,
        +category: ?{|
          +id: string,
          +name: ?string,
        |},
        +name: ?string,
        +approved: ?boolean,
        +expenseYear: ?number,
        +expenseMonth: ?number,
        +expenseDay: ?number,
        +billable: ?boolean,
        +partOfFixedPrice: ?boolean,
        +price: ?number,
        +cost: ?number,
        +quantity: ?number,
        +planned: ?boolean,
        +createdAt: ?string,
        +person: ?{|
          +id: string,
          +fullName: ?string,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
        |},
        +project: ?{|
          +id: string,
          +companyProjectId: ?number,
          +customProjectId: ?string,
          +name: ?string,
        |},
        +phase: ?{|
          +id: string
        |},
      |}
    |}
  |}
|};
export type createExpenseItemMutation = {|
  variables: createExpenseItemMutationVariables,
  response: createExpenseItemMutationResponse,
|};
*/


/*
mutation createExpenseItemMutation(
  $input: CreateExpenseItemInput!
) {
  createExpenseItem(input: $input) {
    expenseItem {
      node {
        id
        category {
          id
          name
        }
        name
        approved
        expenseYear
        expenseMonth
        expenseDay
        billable
        partOfFixedPrice
        price
        cost
        quantity
        planned
        createdAt
        person {
          id
          fullName
          profilePictureId
          profilePictureDefaultId
        }
        project {
          id
          companyProjectId
          customProjectId
          name
        }
        phase {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateExpenseItemInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateExpenseItemPayload",
    "kind": "LinkedField",
    "name": "createExpenseItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExpenseItemTypeEdge",
        "kind": "LinkedField",
        "name": "expenseItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExpenseItem",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ExpenseCategory",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approved",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expenseYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expenseMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expenseDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "billable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "partOfFixedPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planned",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilePictureId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilePictureDefaultId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyProjectId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customProjectId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PhaseType",
                "kind": "LinkedField",
                "name": "phase",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createExpenseItemMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createExpenseItemMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createExpenseItemMutation",
    "operationKind": "mutation",
    "text": "mutation createExpenseItemMutation(\n  $input: CreateExpenseItemInput!\n) {\n  createExpenseItem(input: $input) {\n    expenseItem {\n      node {\n        id\n        category {\n          id\n          name\n        }\n        name\n        approved\n        expenseYear\n        expenseMonth\n        expenseDay\n        billable\n        partOfFixedPrice\n        price\n        cost\n        quantity\n        planned\n        createdAt\n        person {\n          id\n          fullName\n          profilePictureId\n          profilePictureDefaultId\n        }\n        project {\n          id\n          companyProjectId\n          customProjectId\n          name\n        }\n        phase {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '804d7628c674fd4b68c69ab3548e3294';

module.exports = node;
