/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTimeRegInput = {|
  id?: ?string,
  ids?: ?$ReadOnlyArray<?string>,
  updateTimeRegLocation?: ?boolean,
  taskId?: ?string,
  idleTimeId?: ?string,
  projectId?: ?string,
  roleId?: ?string,
  year?: ?number,
  month?: ?number,
  day?: ?number,
  minutesRegistered?: ?number,
  billableMinutesRegistered?: ?number,
  retainerConflictHandled?: ?boolean,
  removeOthersForTheDay?: ?boolean,
  notes?: ?string,
  harvestTaskId?: ?string,
  retryHarvest?: ?boolean,
  unit4ActivityId?: ?string,
  unit4ActivityName?: ?string,
  skipApprovalValidation?: ?boolean,
  overrideInvoiced?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateTimeRegistrationRetainerConflictMutationVariables = {|
  input: UpdateTimeRegInput
|};
export type updateTimeRegistrationRetainerConflictMutationResponse = {|
  +updateTimeReg: ?{|
    +timeRegs: ?$ReadOnlyArray<?{|
      +id: string,
      +retainerConflictHandled: ?boolean,
    |}>
  |}
|};
export type updateTimeRegistrationRetainerConflictMutation = {|
  variables: updateTimeRegistrationRetainerConflictMutationVariables,
  response: updateTimeRegistrationRetainerConflictMutationResponse,
|};
*/


/*
mutation updateTimeRegistrationRetainerConflictMutation(
  $input: UpdateTimeRegInput!
) {
  updateTimeReg(input: $input) {
    timeRegs {
      id
      retainerConflictHandled
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTimeRegInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTimeRegPayload",
    "kind": "LinkedField",
    "name": "updateTimeReg",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TimeRegType",
        "kind": "LinkedField",
        "name": "timeRegs",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "retainerConflictHandled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateTimeRegistrationRetainerConflictMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateTimeRegistrationRetainerConflictMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateTimeRegistrationRetainerConflictMutation",
    "operationKind": "mutation",
    "text": "mutation updateTimeRegistrationRetainerConflictMutation(\n  $input: UpdateTimeRegInput!\n) {\n  updateTimeReg(input: $input) {\n    timeRegs {\n      id\n      retainerConflictHandled\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ccadd9d9180e46461fe1892098dd341a';

module.exports = node;
