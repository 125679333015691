import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';
import {createRangeBulkAddUpdater} from '../relay-helpers/RelayCacheHelpers';

const mutation = graphql`
	mutation ImportSageIntacctTeamMembersMutation($input: ImportSageIntacctTeamMembersInput!) {
		importSageIntacctTeamMembers(input: $input) {
			persons {
				node {
					id
					createdAt
					createdBy
					harvestUser
					economicUser
					initials
					gitlabUser {
						integrationUserId
					}
					gitlabServerUser {
						integrationUserId
					}
					githubUser {
						integrationUserId
					}
					jiraServerUser
					jiraCloudUser
					asanaUser
					adoUserId
					unit4User
					msTeamsId
					bambooHRId
					sageIntacctId
					slackId
					firstName
					lastName
					fullName
					email
					startDate
					endDate
					profilePictureId
					profilePictureDefaultId
					role {
						id
						name
					}
					skillPersons {
						skill {
							id
						}
						level {
							id
						}
					}
					profiles(first: 10000) {
						edges {
							node {
								id
								name
							}
						}
					}
					permissions
					active
					invited
					isViewer
					costPeriods(first: 10000) @connection(key: "Person_costPeriods", filters: []) {
						edges {
							node {
								id
								startDay
								startMonth
								startYear
								cost
							}
						}
					}
					department {
						id
						name
					}
					hasLoggedIn
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return commitMutation(environment, {
		mutation,
		variables,
		updater: createRangeBulkAddUpdater(input.companyId, 'importSageIntacctTeamMembers', 'persons', 'Company_allPersons'),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
