import * as Sentry from '@sentry/browser';
import { sendTrackingMessage } from '../TrackingMessage';
import { TRACKING_EVENT_TYPE } from './TRACKING_EVENT_TYPE';
import { toEntityId } from '../../forecast-app/shared/components/modals/time-registration/time-registration-modal/util/TimeRegistrationModalUtil';
function formatTimeRegistrationSuggestions(timeRegistrationSuggestions) {
    if (!timeRegistrationSuggestions)
        return null;
    return timeRegistrationSuggestions.reduce((acc, suggestion) => {
        var _a, _b, _c;
        if (suggestion) {
            const entityId = toEntityId({
                taskId: ((_a = suggestion.task) === null || _a === void 0 ? void 0 : _a.id) || null,
                projectId: ((_b = suggestion.project) === null || _b === void 0 ? void 0 : _b.id) || null,
                idleTimeId: ((_c = suggestion.idleTime) === null || _c === void 0 ? void 0 : _c.id) || null,
            });
            if (entityId && suggestion.rank && suggestion.total) {
                acc.push({
                    id: entityId.id,
                    entity: entityId.entity,
                    rank: suggestion.rank,
                    score: suggestion.total,
                });
            }
        }
        return acc;
    }, []);
}
function formatOldTimeRegistrationSuggestions(timeRegistrationSuggestions) {
    if (!timeRegistrationSuggestions)
        return null;
    return timeRegistrationSuggestions.reduce((acc, suggestion) => {
        var _a, _b;
        if (suggestion) {
            const entityId = toEntityId({
                taskId: ((_a = suggestion.task) === null || _a === void 0 ? void 0 : _a.id) || null,
                projectId: null,
                idleTimeId: ((_b = suggestion.idleTime) === null || _b === void 0 ? void 0 : _b.id) || null,
            });
            if (entityId && suggestion.total) {
                acc.push({
                    id: entityId.id,
                    entity: entityId.entity,
                    score: suggestion.total,
                    rank: -1,
                });
            }
        }
        return acc;
    }, []);
}
function constructFeedbackBody({ companyId, viewerId, personId, uiSource, modelVersion, timeRegistrationTargetTaskId = null, timeRegistrationTargetProjectId = null, timeRegistrationTargetIdleTimeId = null, timeRegistrationDate, timeRegistrationTargetSuggestions, oldTimeRegistrationTargetSuggestions, }) {
    const timeRegistrationEntityId = toEntityId({
        taskId: timeRegistrationTargetTaskId,
        projectId: timeRegistrationTargetProjectId,
        idleTimeId: timeRegistrationTargetIdleTimeId,
    });
    if (!timeRegistrationEntityId)
        return null;
    return {
        eventType: TRACKING_EVENT_TYPE.TIME_REGISTRATION_TARGET_SUGGESTION_FEEDBACK,
        companyId,
        viewerId,
        personId,
        uiSource,
        modelVersion,
        timeRegistrationTargetEntity: timeRegistrationEntityId.entity,
        timeRegistrationTargetId: timeRegistrationEntityId.id,
        timeRegistrationDate,
        timeRegistrationTargetSuggestions: timeRegistrationTargetSuggestions
            ? formatTimeRegistrationSuggestions(timeRegistrationTargetSuggestions)
            : null,
        oldTimeRegistrationTargetSuggestions: oldTimeRegistrationTargetSuggestions
            ? formatOldTimeRegistrationSuggestions(oldTimeRegistrationTargetSuggestions)
            : null,
    };
}
export function trackTimeRegistrationTargetSuggestionFeedback(body) {
    try {
        const s3body = constructFeedbackBody(body);
        if (s3body) {
            sendTrackingMessage(s3body);
        }
    }
    catch (err) {
        Sentry.captureException(err);
        console.log('Tracking failed, reported to Sentry', err);
    }
}
