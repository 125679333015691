import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createSalesforceVerificationKeyMutation($input: CreateSalesforceVerificationKeyInput!) {
		createSalesforceVerificationKey(input: $input) {
			key
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), useSandbox: input.useSandbox}},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
