/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeactivateSlackIntegrationInput = {|
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type DeactivateSlackIntegrationMutationVariables = {|
  input: DeactivateSlackIntegrationInput
|};
export type DeactivateSlackIntegrationMutationResponse = {|
  +deactivateSlackIntegration: ?{|
    +company: ?{|
      +newSlackEnabled: ?boolean,
      +slackEnabled: ?boolean,
    |}
  |}
|};
export type DeactivateSlackIntegrationMutation = {|
  variables: DeactivateSlackIntegrationMutationVariables,
  response: DeactivateSlackIntegrationMutationResponse,
|};
*/


/*
mutation DeactivateSlackIntegrationMutation(
  $input: DeactivateSlackIntegrationInput!
) {
  deactivateSlackIntegration(input: $input) {
    company {
      newSlackEnabled
      slackEnabled
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeactivateSlackIntegrationInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newSlackEnabled",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slackEnabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeactivateSlackIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeactivateSlackIntegrationPayload",
        "kind": "LinkedField",
        "name": "deactivateSlackIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeactivateSlackIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeactivateSlackIntegrationPayload",
        "kind": "LinkedField",
        "name": "deactivateSlackIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "DeactivateSlackIntegrationMutation",
    "operationKind": "mutation",
    "text": "mutation DeactivateSlackIntegrationMutation(\n  $input: DeactivateSlackIntegrationInput!\n) {\n  deactivateSlackIntegration(input: $input) {\n    company {\n      newSlackEnabled\n      slackEnabled\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd159d9e8faec4d8a125981c9ca169604';

module.exports = node;
