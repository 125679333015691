/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsRoles_role$ref = any;
export type CreateRoleInput = {|
  name?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createRoleMutationVariables = {|
  input: CreateRoleInput
|};
export type createRoleMutationResponse = {|
  +createRole: ?{|
    +role: ?{|
      +node: ?{|
        +$fragmentRefs: settingsRoles_role$ref
      |}
    |}
  |}
|};
export type createRoleMutation = {|
  variables: createRoleMutationVariables,
  response: createRoleMutationResponse,
|};
*/


/*
mutation createRoleMutation(
  $input: CreateRoleInput!
) {
  createRole(input: $input) {
    role {
      node {
        ...settingsRoles_role
        id
      }
    }
  }
}

fragment settingsRoles_role on Role {
  id
  name
  notifyProjectOverview
  timeRegCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateRoleInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateRolePayload",
        "kind": "LinkedField",
        "name": "createRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoleTypeEdge",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "settingsRoles_role"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateRolePayload",
        "kind": "LinkedField",
        "name": "createRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoleTypeEdge",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notifyProjectOverview",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timeRegCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createRoleMutation",
    "operationKind": "mutation",
    "text": "mutation createRoleMutation(\n  $input: CreateRoleInput!\n) {\n  createRole(input: $input) {\n    role {\n      node {\n        ...settingsRoles_role\n        id\n      }\n    }\n  }\n}\n\nfragment settingsRoles_role on Role {\n  id\n  name\n  notifyProjectOverview\n  timeRegCount\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b501c0789ec19d9eb0a8fb4298f1d1aa';

module.exports = node;
