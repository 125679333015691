import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateProjectBudgetMutation($input: UpdateProjectBudgetInput!) {
		updateProjectBudget(input: $input) {
			clientMutationId
		}
	}
`;

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('Updating project budget');

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), projects: input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
