/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
export type SendTasksToAdoInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  workitemType?: ?string,
  projectId?: ?string,
  adoState?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type sendTasksToAdoMutationVariables = {|
  input: SendTasksToAdoInput
|};
export type sendTasksToAdoMutationResponse = {|
  +sendTasksToAdo: ?{|
    +tasks: ?$ReadOnlyArray<?{|
      +id: string,
      +vstsId: ?string,
      +name: ?string,
      +statusColumnV2: ?{|
        +id: string,
        +name: ?string,
        +category: ?STATUS_CATEGORY,
      |},
    |}>,
    +updatedTasksIds: ?$ReadOnlyArray<?string>,
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type sendTasksToAdoMutation = {|
  variables: sendTasksToAdoMutationVariables,
  response: sendTasksToAdoMutationResponse,
|};
*/


/*
mutation sendTasksToAdoMutation(
  $input: SendTasksToAdoInput!
) {
  sendTasksToAdo(input: $input) {
    tasks {
      id
      vstsId
      name
      statusColumnV2 {
        id
        name
        category
      }
    }
    updatedTasksIds
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SendTasksToAdoInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendTasksToAdoPayload",
    "kind": "LinkedField",
    "name": "sendTasksToAdo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vstsId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StatusColumnV2",
            "kind": "LinkedField",
            "name": "statusColumnV2",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedTasksIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sendTasksToAdoMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sendTasksToAdoMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "sendTasksToAdoMutation",
    "operationKind": "mutation",
    "text": "mutation sendTasksToAdoMutation(\n  $input: SendTasksToAdoInput!\n) {\n  sendTasksToAdo(input: $input) {\n    tasks {\n      id\n      vstsId\n      name\n      statusColumnV2 {\n        id\n        name\n        category\n      }\n    }\n    updatedTasksIds\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3923e5dcdb3116a330fc933ab3a41b6e';

module.exports = node;
