/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFeatureFlagInput = {|
  featureFlag?: ?string,
  companyId?: ?string,
  companyIds?: ?$ReadOnlyArray<?string>,
  allUsers?: ?boolean,
  removeAccess?: ?boolean,
  persons?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type featureFlagMutationVariables = {|
  input: UpdateFeatureFlagInput
|};
export type featureFlagMutationResponse = {|
  +UpdateFeatureFlag: ?{|
    +availableFeatureFlags: ?$ReadOnlyArray<?{|
      +id: string,
      +key: ?string,
    |}>,
    +missionControlData: ?{|
      +availableFeatures: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +key: ?string,
        +color: ?string,
        +companies: ?$ReadOnlyArray<?{|
          +id: string,
          +companyId: string,
          +name: ?string,
          +timestamp: ?string,
        |}>,
        +persons: ?$ReadOnlyArray<?{|
          +id: string,
          +personId: string,
          +companyId: string,
          +companyName: ?string,
          +name: ?string,
          +email: ?string,
          +timestamp: ?string,
        |}>,
        +personsOptedOut: ?$ReadOnlyArray<?{|
          +id: string,
          +companyId: string,
          +companyName: ?string,
          +name: ?string,
          +email: ?string,
        |}>,
      |}>
    |},
  |}
|};
export type featureFlagMutation = {|
  variables: featureFlagMutationVariables,
  response: featureFlagMutationResponse,
|};
*/


/*
mutation featureFlagMutation(
  $input: UpdateFeatureFlagInput!
) {
  UpdateFeatureFlag(input: $input) {
    availableFeatureFlags {
      id
      key
    }
    missionControlData {
      availableFeatures {
        id
        name
        key
        color
        companies {
          id
          companyId
          name
          timestamp
        }
        persons {
          id
          personId
          companyId
          companyName
          name
          email
          timestamp
        }
        personsOptedOut {
          id
          companyId
          companyName
          name
          email
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFeatureFlagInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "FeatureFlag",
  "kind": "LinkedField",
  "name": "availableFeatureFlags",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timestamp",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "AvailableFeaturesType",
  "kind": "LinkedField",
  "name": "availableFeatures",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyFeatureType",
      "kind": "LinkedField",
      "name": "companies",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v6/*: any*/),
        (v5/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonFeatureType",
      "kind": "LinkedField",
      "name": "persons",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "personId",
          "storageKey": null
        },
        (v6/*: any*/),
        (v8/*: any*/),
        (v5/*: any*/),
        (v9/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonFeatureType",
      "kind": "LinkedField",
      "name": "personsOptedOut",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v6/*: any*/),
        (v8/*: any*/),
        (v5/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "featureFlagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFeatureFlagPayload",
        "kind": "LinkedField",
        "name": "UpdateFeatureFlag",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "featureFlagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFeatureFlagPayload",
        "kind": "LinkedField",
        "name": "UpdateFeatureFlag",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "featureFlagMutation",
    "operationKind": "mutation",
    "text": "mutation featureFlagMutation(\n  $input: UpdateFeatureFlagInput!\n) {\n  UpdateFeatureFlag(input: $input) {\n    availableFeatureFlags {\n      id\n      key\n    }\n    missionControlData {\n      availableFeatures {\n        id\n        name\n        key\n        color\n        companies {\n          id\n          companyId\n          name\n          timestamp\n        }\n        persons {\n          id\n          personId\n          companyId\n          companyName\n          name\n          email\n          timestamp\n        }\n        personsOptedOut {\n          id\n          companyId\n          companyName\n          name\n          email\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a1598fdf2e3a9085afc1e4a359a3a3f7';

module.exports = node;
