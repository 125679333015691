/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetGitHubSyncTargetInput = {|
  projectId?: ?string,
  target?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type setGithubSyncTargetMutationVariables = {|
  input: SetGitHubSyncTargetInput
|};
export type setGithubSyncTargetMutationResponse = {|
  +setGitHubSyncTarget: ?{|
    +project: ?{|
      +gitHubSyncTarget: ?number
    |}
  |}
|};
export type setGithubSyncTargetMutation = {|
  variables: setGithubSyncTargetMutationVariables,
  response: setGithubSyncTargetMutationResponse,
|};
*/


/*
mutation setGithubSyncTargetMutation(
  $input: SetGitHubSyncTargetInput!
) {
  setGitHubSyncTarget(input: $input) {
    project {
      gitHubSyncTarget
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetGitHubSyncTargetInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gitHubSyncTarget",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "setGithubSyncTargetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetGitHubSyncTargetPayload",
        "kind": "LinkedField",
        "name": "setGitHubSyncTarget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "setGithubSyncTargetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetGitHubSyncTargetPayload",
        "kind": "LinkedField",
        "name": "setGitHubSyncTarget",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "setGithubSyncTargetMutation",
    "operationKind": "mutation",
    "text": "mutation setGithubSyncTargetMutation(\n  $input: SetGitHubSyncTargetInput!\n) {\n  setGitHubSyncTarget(input: $input) {\n    project {\n      gitHubSyncTarget\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dd304be7bbb632e4b1919d44ff13bc27';

module.exports = node;
