/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation DeleteClientBillTosMutation(
  $input: DeleteClientBillTosInput!
) {
  deleteClientBillTos(input: $input) {
    deletedClientBillToIds
    company {
      id
      clients(first: 100000) {
        edges {
          node {
            id
            name
            clientBillTos(first: 10000) {
              edges {
                node {
                  id
                  billFrom {
                    id
                  }
                  billTo {
                    id
                  }
                  clientId
                  __typename
                }
                cursor
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
          }
        }
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "DeleteClientBillTosInput!"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedClientBillToIds",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100000
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v6 = [
        (v3 /*: any*/)
    ], v7 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "ClientBillToTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientBillTo",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "BillFrom",
                            "kind": "LinkedField",
                            "name": "billFrom",
                            "plural": false,
                            "selections": (v6 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "BillTo",
                            "kind": "LinkedField",
                            "name": "billTo",
                            "plural": false,
                            "selections": (v6 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "clientId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ], v8 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteClientBillTosMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteClientBillTosPayload",
                    "kind": "LinkedField",
                    "name": "deleteClientBillTos",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "ClientTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "clients",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ClientTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Client",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v5 /*: any*/),
                                                        {
                                                            "alias": "clientBillTos",
                                                            "args": null,
                                                            "concreteType": "ClientBillToTypeConnection",
                                                            "kind": "LinkedField",
                                                            "name": "__Client_clientBillTos_connection",
                                                            "plural": false,
                                                            "selections": (v7 /*: any*/),
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "clients(first:100000)"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteClientBillTosMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteClientBillTosPayload",
                    "kind": "LinkedField",
                    "name": "deleteClientBillTos",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "ClientTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "clients",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ClientTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Client",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v5 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": (v8 /*: any*/),
                                                            "concreteType": "ClientBillToTypeConnection",
                                                            "kind": "LinkedField",
                                                            "name": "clientBillTos",
                                                            "plural": false,
                                                            "selections": (v7 /*: any*/),
                                                            "storageKey": "clientBillTos(first:10000)"
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": (v8 /*: any*/),
                                                            "filters": [],
                                                            "handle": "connection",
                                                            "key": "Client_clientBillTos",
                                                            "kind": "LinkedHandle",
                                                            "name": "clientBillTos"
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "clients(first:100000)"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {
                "connection": [
                    {
                        "count": null,
                        "cursor": null,
                        "direction": "forward",
                        "path": null
                    }
                ]
            },
            "name": "DeleteClientBillTosMutation",
            "operationKind": "mutation",
            "text": "mutation DeleteClientBillTosMutation(\n  $input: DeleteClientBillTosInput!\n) {\n  deleteClientBillTos(input: $input) {\n    deletedClientBillToIds\n    company {\n      id\n      clients(first: 100000) {\n        edges {\n          node {\n            id\n            name\n            clientBillTos(first: 10000) {\n              edges {\n                node {\n                  id\n                  billFrom {\n                    id\n                  }\n                  billTo {\n                    id\n                  }\n                  clientId\n                  __typename\n                }\n                cursor\n              }\n              pageInfo {\n                endCursor\n                hasNextPage\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '87d1807fa634f9f4e797808373d7d56b';
export default node;
