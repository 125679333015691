import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteRepeatingTaskMutation($input: DeleteRepeatingTaskInput!) {
		deleteRepeatingTask(input: $input) {
			task {
				repeatingTask {
					id
					repeatType
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					monthlyDay
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const task = {
		id: input.taskId,
		repeatingTask: null,
	};
	return {
		updateRepeatingTask: {
			task: task,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Repeating Card Deleted');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
