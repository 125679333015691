import {graphql} from 'react-relay';
import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateSkillLevelsMutation($input: UpdateSkillLevelsInput!) {
		updateSkillLevels(input: $input) {
			company {
				skillLevels {
					id
					description
					level
				}
				allPersons(first: 10000, excludeClientUsers: true) @connection(key: "Company_allPersons", filters: []) {
					edges {
						node {
							skillPersons {
								skill {
									id
								}
								level {
									id
									level
								}
							}
						}
					}
				}
				placeholders {
					edges {
						node {
							skillPlaceholders {
								skill {
									id
								}
								level {
									id
									level
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Skill levels updated');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
