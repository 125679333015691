/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTasksBulkInput = {|
  tasks?: ?$ReadOnlyArray<?TaskBulk>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type TaskBulk = {|
  taskId?: ?string,
  roleId?: ?string,
  estimateLow?: ?number,
  estimateHigh?: ?number,
  estimateForecast?: ?number,
  personIds?: ?$ReadOnlyArray<?string>,
  labelIds?: ?$ReadOnlyArray<?string>,
|};
export type updateTasksBulkMutationModernMutationVariables = {|
  input: UpdateTasksBulkInput
|};
export type updateTasksBulkMutationModernMutationResponse = {|
  +updateTasksBulk: ?{|
    +tasks: ?$ReadOnlyArray<?{|
      +id: string,
      +estimateForecast: ?number,
      +estimateForecastMinutes: ?number,
      +estimateForecastPrice: ?number,
      +timeLeft: ?number,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
      +assignedPersons: ?$ReadOnlyArray<?{|
        +id: string,
        +active: ?boolean,
        +firstName: ?string,
        +lastName: ?string,
        +profilePictureId: ?string,
        +profilePictureDefaultId: ?number,
        +permissions: ?$ReadOnlyArray<?string>,
        +client: ?{|
          +id: string
        |},
      |}>,
      +taskLabels: ?$ReadOnlyArray<?{|
        +id: string,
        +label: ?{|
          +id: string,
          +name: ?string,
          +color: ?string,
        |},
      |}>,
    |}>
  |}
|};
export type updateTasksBulkMutationModernMutation = {|
  variables: updateTasksBulkMutationModernMutationVariables,
  response: updateTasksBulkMutationModernMutationResponse,
|};
*/


/*
mutation updateTasksBulkMutationModernMutation(
  $input: UpdateTasksBulkInput!
) {
  updateTasksBulk(input: $input) {
    tasks {
      id
      estimateForecast
      estimateForecastMinutes
      estimateForecastPrice
      timeLeft
      role {
        id
        name
      }
      assignedPersons {
        id
        active
        firstName
        lastName
        profilePictureId
        profilePictureDefaultId
        permissions
        client {
          id
        }
      }
      taskLabels {
        id
        label {
          id
          name
          color
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTasksBulkInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTasksBulkPayload",
    "kind": "LinkedField",
    "name": "updateTasksBulk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "estimateForecast",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "estimateForecastMinutes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "estimateForecastPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeLeft",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "assignedPersons",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePictureId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePictureDefaultId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "permissions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskLabel",
            "kind": "LinkedField",
            "name": "taskLabels",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "label",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "color",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateTasksBulkMutationModernMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateTasksBulkMutationModernMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateTasksBulkMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation updateTasksBulkMutationModernMutation(\n  $input: UpdateTasksBulkInput!\n) {\n  updateTasksBulk(input: $input) {\n    tasks {\n      id\n      estimateForecast\n      estimateForecastMinutes\n      estimateForecastPrice\n      timeLeft\n      role {\n        id\n        name\n      }\n      assignedPersons {\n        id\n        active\n        firstName\n        lastName\n        profilePictureId\n        profilePictureDefaultId\n        permissions\n        client {\n          id\n        }\n      }\n      taskLabels {\n        id\n        label {\n          id\n          name\n          color\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d9040af6c4db36dce16cc7ee40f25a7';

module.exports = node;
