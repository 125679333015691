import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation UpdateProfileMutation($input: UpdateProfileInput!) {
		updateProfile(input: $input) {
			profile {
				id
				name
				canBeEdited
				personsCount
				permissions
				disabled
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const profile = {
		id: input.id,
		name: undefined,
		canBeEdited: undefined,
		personsCount: undefined,
		permissions: undefined,
		disabled: undefined,
	};
	if (input.name) {
		profile.name = input.name;
	}
	if (input.disabled) {
		profile.disabled = input.disabled;
	}
	return {
		updateProfile: {
			profile: profile,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Permission Profile Updated', {profilePermissions: input.profilePermissions});

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
