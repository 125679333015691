import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation sharedReportResetPasswordMutation($input: SharedReportResetPasswordInput!) {
		sharedReportResetPassword(input: $input) {
			success
			viewer {
				id
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Shared Report Reset password');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {...input}},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
