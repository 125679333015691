/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSubTaskInput = {|
  taskId: string,
  name?: ?string,
  description?: ?string,
  estimate?: ?number,
  done?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createSubTaskMutationVariables = {|
  input: CreateSubTaskInput
|};
export type createSubTaskMutationResponse = {|
  +createSubTask: ?{|
    +task: ?{|
      +timeLeft: ?number,
      +latestUiUpdateAt: ?string,
      +latestUiUpdateBy: ?{|
        +firstName: ?string,
        +lastName: ?string,
      |},
    |},
    +subTask: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +estimate: ?number,
        +sortOrder: ?number,
      |}
    |},
  |}
|};
export type createSubTaskMutation = {|
  variables: createSubTaskMutationVariables,
  response: createSubTaskMutationResponse,
|};
*/


/*
mutation createSubTaskMutation(
  $input: CreateSubTaskInput!
) {
  createSubTask(input: $input) {
    task {
      timeLeft
      latestUiUpdateAt
      latestUiUpdateBy {
        firstName
        lastName
        id
      }
      id
    }
    subTask {
      node {
        id
        name
        estimate
        sortOrder
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSubTaskInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLeft",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestUiUpdateAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SubTaskTypeEdge",
  "kind": "LinkedField",
  "name": "subTask",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SubTask",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sortOrder",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createSubTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSubTaskPayload",
        "kind": "LinkedField",
        "name": "createSubTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "latestUiUpdateBy",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createSubTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSubTaskPayload",
        "kind": "LinkedField",
        "name": "createSubTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "latestUiUpdateBy",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createSubTaskMutation",
    "operationKind": "mutation",
    "text": "mutation createSubTaskMutation(\n  $input: CreateSubTaskInput!\n) {\n  createSubTask(input: $input) {\n    task {\n      timeLeft\n      latestUiUpdateAt\n      latestUiUpdateBy {\n        firstName\n        lastName\n        id\n      }\n      id\n    }\n    subTask {\n      node {\n        id\n        name\n        estimate\n        sortOrder\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bce7e065d0d6f6512d15d6d618adc4ca';

module.exports = node;
