/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JoinCompanyInput = {|
  email: string,
  join?: ?boolean,
  clientMutationId?: ?string,
|};
export type joinCompanyMutationVariables = {|
  input: JoinCompanyInput
|};
export type joinCompanyMutationResponse = {|
  +joinCompany: ?{|
    +errors: ?$ReadOnlyArray<?string>,
    +person: ?{|
      +node: ?{|
        +id: string
      |}
    |},
  |}
|};
export type joinCompanyMutation = {|
  variables: joinCompanyMutationVariables,
  response: joinCompanyMutationResponse,
|};
*/


/*
mutation joinCompanyMutation(
  $input: JoinCompanyInput!
) {
  joinCompany(input: $input) {
    errors
    person {
      node {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "JoinCompanyInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "JoinCompanyPayload",
    "kind": "LinkedField",
    "name": "joinCompany",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PersonTypeEdge",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "joinCompanyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "joinCompanyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "joinCompanyMutation",
    "operationKind": "mutation",
    "text": "mutation joinCompanyMutation(\n  $input: JoinCompanyInput!\n) {\n  joinCompany(input: $input) {\n    errors\n    person {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9447723403369ab0e169fd661aa42b63';

module.exports = node;
