import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {createRangeBulkAddUpdater} from '../relay-helpers/RelayCacheHelpers';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createBulkFixedPriceLockMutation($input: CreateBulkFixedPriceLockInput!) {
		createBulkFixedPriceLock(input: $input) {
			fixedPriceLocks {
				node {
					id
					startDate
					endDate
					locked
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Fixed Price Lock Created');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		updater: createRangeBulkAddUpdater(
			input.projectId,
			'createBulkFixedPriceLock',
			'fixedPriceLocks',
			'Project_fixedPriceLocks'
		),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
