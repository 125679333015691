/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIODICITY = "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type UpdateProjectInput = {|
  id?: ?string,
  name?: ?string,
  description?: ?string,
  status?: ?string,
  active?: ?boolean,
  projectColor?: ?string,
  customProjectId?: ?string,
  clientId?: ?string,
  projectOwner?: ?string,
  projectManagers?: ?$ReadOnlyArray<?string>,
  statusColumns?: ?$ReadOnlyArray<?StatusColumnInput>,
  estimationUnit?: ?string,
  sprintTimeBox?: ?boolean,
  sprintLengthInDays?: ?number,
  projectStartYear?: ?number,
  projectStartMonth?: ?number,
  projectStartDay?: ?number,
  projectEndYear?: ?number,
  projectEndMonth?: ?number,
  projectEndDay?: ?number,
  taskLevels?: ?number,
  useTaskHierarchy?: ?boolean,
  rateCardId?: ?string,
  labels?: ?$ReadOnlyArray<?string>,
  minutesPerEstimationPoint?: ?number,
  budget?: ?number,
  budgetType?: ?BUDGET_TYPE,
  defaultPeriodPriceAmount?: ?number,
  defaultPeriodHoursAmount?: ?number,
  defaultPeriodBudgetType?: ?PERIOD_BUDGET_TYPE,
  defaultPeriodLength?: ?number,
  defaultPeriodPeriodicity?: ?PERIODICITY,
  defaultPeriodSettingIgnoreForBilling?: ?boolean,
  defaultPeriodSettingSubtractValue?: ?boolean,
  defaultPeriodSettingRollValue?: ?boolean,
  defaultPeriodSettingAddExpenses?: ?boolean,
  revenueMarginLow?: ?number,
  revenueMarginHigh?: ?number,
  economicProjectId?: ?string,
  harvestProjectId?: ?string,
  newHarvestProjectId?: ?string,
  jiraProjectId?: ?string,
  jiraCutoffDate?: ?string,
  excludedHarvestTasks?: ?$ReadOnlyArray<?string>,
  newExcludedHarvestTasks?: ?$ReadOnlyArray<?number>,
  timelogProjectId?: ?string,
  newTimelogProjectId?: ?string,
  initTaskImport?: ?boolean,
  slackChannelId?: ?string,
  approveNoPhaseTasks?: ?boolean,
  billable?: ?boolean,
  projectGroupId?: ?string,
  previousProjectGroupId?: ?string,
  salesforceOpportunity?: ?string,
  unit4Project?: ?string,
  githubRepositories?: ?$ReadOnlyArray<?string>,
  githubRepositoryIds?: ?$ReadOnlyArray<?number>,
  remainingAutoCalculated?: ?boolean,
  useManualAllocations?: ?boolean,
  taskCostCalculationType?: ?string,
  unassignedTaskHourlyCost?: ?number,
  useTaskOwner?: ?boolean,
  useTaskFollowers?: ?boolean,
  currencyExchangeRate?: ?number,
  fixedPriceRateCardSetting?: ?number,
  usePublicCreateTaskPage?: ?boolean,
  usePublicCreateTaskPassword?: ?boolean,
  publicCreateTaskPassword?: ?string,
  publicCreateTaskPageStatusColumnId?: ?string,
  unsynchedJiraProject?: ?boolean,
  useBaseline?: ?boolean,
  synchBaselineAndScopingDates?: ?boolean,
  useFixedPriceForBaselineRevenue?: ?boolean,
  useDeliverables?: ?boolean,
  synchDatesSetting?: ?string,
  baselineWinChance?: ?number,
  baselineTarget?: ?number,
  baselineMinutesPerDay?: ?number,
  priorityLevelId?: ?string,
  manualProgressOnProjectEnabled?: ?boolean,
  manualProgressOnPhasesEnabled?: ?boolean,
  manualProgressOnTasksEnabled?: ?boolean,
  flatRate?: ?number,
  flatCost?: ?number,
  financialSourceSettings?: ?FinancialSourceSettingsInput,
  sageProjectId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type StatusColumnInput = {|
  id?: ?string,
  name?: ?string,
  order?: ?number,
  category?: ?string,
  projectId?: ?string,
  projectGroupStatusColumnId?: ?number,
  autoShowOnTimePage?: ?boolean,
  encourageTimeRegistration?: ?boolean,
|};
export type FinancialSourceSettingsInput = {|
  plannedHours?: ?string,
  plannedRevenue?: ?string,
  plannedCost?: ?string,
  forecastHours?: ?string,
  forecastRevenue?: ?string,
  forecastCost?: ?string,
  actualHours?: ?string,
  actualRevenue?: ?string,
  actualCost?: ?string,
|};
export type UpdateProjectRevenueMarginMutationVariables = {|
  input: UpdateProjectInput
|};
export type UpdateProjectRevenueMarginMutationResponse = {|
  +updateProject: ?{|
    +project: ?{|
      +revenueMarginLow: ?number,
      +revenueMarginHigh: ?number,
    |}
  |}
|};
export type UpdateProjectRevenueMarginMutation = {|
  variables: UpdateProjectRevenueMarginMutationVariables,
  response: UpdateProjectRevenueMarginMutationResponse,
|};
*/


/*
mutation UpdateProjectRevenueMarginMutation(
  $input: UpdateProjectInput!
) {
  updateProject(input: $input) {
    project {
      revenueMarginLow
      revenueMarginHigh
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProjectInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueMarginLow",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueMarginHigh",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProjectRevenueMarginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProjectPayload",
        "kind": "LinkedField",
        "name": "updateProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProjectRevenueMarginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProjectPayload",
        "kind": "LinkedField",
        "name": "updateProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateProjectRevenueMarginMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProjectRevenueMarginMutation(\n  $input: UpdateProjectInput!\n) {\n  updateProject(input: $input) {\n    project {\n      revenueMarginLow\n      revenueMarginHigh\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b42b0ff806b17c5d8054494361d1755c';

module.exports = node;
