import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createExpenseItemMutation($input: CreateExpenseItemInput!) {
		createExpenseItem(input: $input) {
			expenseItem {
				node {
					id
					category {
						id
						name
					}
					name
					approved
					expenseYear
					expenseMonth
					expenseDay
					billable
					partOfFixedPrice
					price
					cost
					quantity
					name
					planned
					createdAt
					person {
						id
						fullName
						profilePictureId
						profilePictureDefaultId
					}
					project {
						id
						companyProjectId
						customProjectId
						name
					}
					category {
						id
					}
					phase {
						id
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	const config = [];
	config.push({
		type: 'RANGE_ADD',
		parentID: input.projectId,
		connectionInfo: [
			{
				key: 'Project_expenseItems',
				rangeBehavior: 'append',
			},
		],
		edgeName: 'expenseItem',
	});

	if (input.actualPersonId === input.personId || input.viewerId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.viewerId,
			connectionInfo: [
				{
					key: 'Viewer_expenseItems',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'expenseItem',
		});
	}
	return config;
}

function commit(environment, input, onSuccess, onError) {
	Util.localStorageDeleteFincancialMap();
	tracking.trackEvent('Expense Item Created', {
		_Billable: input.billable,
		_Approved: input.approved,
		_Quantity: input.quantity,
		_DateSet: !!input.expenseYear,
		_PersonSet: !!input.personId,
	});

	const cleanedVariables = omit(input, ['viewerId', 'actualPersonId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
