import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteRetainerPeriodBulkMutation($input: DeleteRetainerPeriodBulkInput!) {
		deleteRetainerPeriodBulk(input: $input) {
			project {
				retainerPeriods(first: 100000) {
					edges {
						node {
							id
							name
							startYear
							startMonth
							startDay
							endYear
							endMonth
							endDay
							available
							periodLength
							periodPeriodicity
							periodPriceAmount
							periodHoursAmount
							periodBudgetType
							periodSettingIgnoreForBilling
							periodSettingSubtractValue
							periodSettingRollValue
							periodSettingAddExpenses
							periodDifferencePriceAmount
							periodDifferenceHoursAmount
							sharedPeriodDifferenceHoursAmount
							sharedPeriodDifferencePriceAmount
							ignoredRolloverHours
							ignoredRolloverPrice
							periodLocked
							periodLockedTime
							periodDifferenceSplit
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Retainer period bulk deleted');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
