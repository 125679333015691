export var Team;
(function (Team) {
    Team["Team1"] = "team-1";
    Team["Team2"] = "team-2";
    Team["NoTeam"] = "no-team";
})(Team || (Team = {}));
// Team 1 pathnames
const team1GlobalPathnames = [
    'access-blocked',
    'global-search',
    'my-list',
    'projects',
    'timesheets',
    'timesheet-approval',
    'timesheet-entries',
    'my-profile',
    'add-task-form',
    'forgot-password',
    'reset',
    'login',
    'trial-end',
    'signup',
    'sharedinsight',
    'first',
    'savedReport',
];
const team1ProjectPathnames = [
    'nova-insights',
    'settings',
    'overview',
    'workflow',
    'scoping',
    'sprints',
    'connected-projects',
    'group-settings',
    'initial-plan',
    'baseline',
    'advanced-analytics',
];
const team1AdminPathnames = ['details', 'notifications', 'subscription', 'catalog', 'api-keys'];
const team1InsightPathnames = ['insights']; // Default fallback for any type of insight bug
const team1ProfilePathnames = ['my-profile']; // Default fallback for any type of profile bug
const team1IntegrationPathnames = []; // Any integration bug not specifically caught falls back to team 1
const Team1Paths = {
    team: Team.Team1,
    global: team1GlobalPathnames,
    project: team1ProjectPathnames,
    admin: team1AdminPathnames,
    insight: team1InsightPathnames,
    profile: team1ProfilePathnames,
    integration: team1IntegrationPathnames,
};
// Team 2 pathnames
const team2GlobalPathnames = [
    'saved-report',
    'scheduling',
    'okta',
    'one-login',
    'azureAD',
    'microsoft-entra-id-login',
    'oauth',
    'expenses',
    'invoicing',
    'economic',
    'xero',
    'salesforce',
    'finance',
    'invoice',
];
const team2ProjectPathnames = [
    'schedule',
    'timeline',
    'team',
    'client',
    'files',
    'periods',
    'budget',
    'financials',
    'rates',
    'invoicing',
    'retainer',
];
const team2AdminPathnames = [
    'roles',
    'labels',
    'time-management',
    'people',
    'teams',
    'team',
    'clients',
    'holidays',
    'finance',
    'rate-cards',
    'currencies',
];
const team2InsightPathnames = ['utilization-report', 'financial-portfolio-report'];
const team2ProfilePathnames = ['expenses'];
const team2IntegrationPathnames = ['sage-intacct'];
const Team2Paths = {
    team: Team.Team2,
    global: team2GlobalPathnames,
    project: team2ProjectPathnames,
    admin: team2AdminPathnames,
    insight: team2InsightPathnames,
    profile: team2ProfilePathnames,
    integration: team2IntegrationPathnames,
};
// No team pathnames
const noTeamGlobalPathnames = ['not-found', 'not-authorized', 'maintenance', 'control'];
const NoTeamPaths = {
    team: Team.NoTeam,
    global: noTeamGlobalPathnames,
    project: [],
    admin: [],
    insight: [],
    profile: [],
    integration: [],
};
const pathnameInTeamPathNames = (pathname, teamPathNames) => {
    return teamPathNames.some(teamPath => pathname.includes(teamPath));
};
const getTeamFromTeamPaths = (pathname, teamPaths) => {
    if (pathnameInTeamPathNames(pathname, teamPaths.global))
        return teamPaths.team;
    // Project paths
    if (pathname.includes('/project') || pathname.includes('connected')) {
        if (pathnameInTeamPathNames(pathname, teamPaths.project))
            return teamPaths.team;
    }
    // Admin paths
    if (pathname.includes('/settings')) {
        if (pathnameInTeamPathNames(pathname, teamPaths.admin))
            return teamPaths.team;
    }
    // Insight paths
    if (pathname.includes('/insights')) {
        if (pathnameInTeamPathNames(pathname, teamPaths.insight))
            return teamPaths.team;
    }
    // My profile paths
    if (pathname.includes('/my-profile')) {
        if (pathnameInTeamPathNames(pathname, teamPaths.profile))
            return teamPaths.team;
    }
    if (pathname.includes('/integrations')) {
        if (pathnameInTeamPathNames(pathname, teamPaths.integration))
            return teamPaths.team;
    }
    return null;
};
export const getTeamFromPathname = (pathname) => {
    var _a, _b;
    if (!pathname)
        return Team.NoTeam;
    // Task modal match
    const taskModalPathRegex = '.*T[0-9]+.*';
    if (pathname.match(taskModalPathRegex))
        return Team.Team1;
    // My work
    if (pathname === '/')
        return Team.Team1;
    const teamFromPathname = (_b = (_a = getTeamFromTeamPaths(pathname, NoTeamPaths)) !== null && _a !== void 0 ? _a : getTeamFromTeamPaths(pathname, Team2Paths)) !== null && _b !== void 0 ? _b : getTeamFromTeamPaths(pathname, Team1Paths); // Team 1 has a few cases where it acts as the general fallback if nothing else matches - as such, team 1 should always be checked last.
    return teamFromPathname !== null && teamFromPathname !== void 0 ? teamFromPathname : Team.Team1; // All errors that fall outside of category are put into team 1 for visibility.
};
