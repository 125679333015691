/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SendRecommenderFeedbackInput = {|
  recommendationInstanceId?: ?number,
  contextId?: ?number,
  recommendationId?: ?number,
  feedbackValue?: ?number,
  generation?: ?number,
  timeStep?: ?number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type sendRecommenderFeedbackMutationVariables = {|
  input: SendRecommenderFeedbackInput
|};
export type sendRecommenderFeedbackMutationResponse = {|
  +sendRecommenderFeedback: ?{|
    +clientMutationId: ?string
  |}
|};
export type sendRecommenderFeedbackMutation = {|
  variables: sendRecommenderFeedbackMutationVariables,
  response: sendRecommenderFeedbackMutationResponse,
|};
*/


/*
mutation sendRecommenderFeedbackMutation(
  $input: SendRecommenderFeedbackInput!
) {
  sendRecommenderFeedback(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SendRecommenderFeedbackInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendRecommenderFeedbackPayload",
    "kind": "LinkedField",
    "name": "sendRecommenderFeedback",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sendRecommenderFeedbackMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sendRecommenderFeedbackMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "sendRecommenderFeedbackMutation",
    "operationKind": "mutation",
    "text": "mutation sendRecommenderFeedbackMutation(\n  $input: SendRecommenderFeedbackInput!\n) {\n  sendRecommenderFeedback(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '94eb2c2bb2eac2a3fbf92b73d5a2f250';

module.exports = node;
