import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateProjectBudgetPageMutationModernMutation($input: UpdateProjectInput!) {
		updateProject(input: $input) {
			errors
			project {
				id
				estimationUnit # warnings
				useBaseline
				projectStartYear # warnings
				projectStartMonth # warnings
				projectStartDay # warnings
				projectEndYear
				projectEndMonth
				projectEndDay
				companyProjectId
				warnings {
					id
					year
					month
					day
					tasks(first: 10000) {
						edges {
							node {
								id
								name
								startDay
								startMonth
								startYear
								deadlineDay
								deadlineMonth
								deadlineYear
								estimateForecast
							}
						}
					}
					personIds
					expenseItems(first: 10000) {
						edges {
							node {
								id
								name
								expenseYear
								expenseMonth
								expenseDay
							}
						}
					}
					timeRegistrations(first: 10000) {
						edges {
							node {
								id
								year
								month
								day
								minutesRegistered
								person {
									id
									fullName
									profilePictureId
									profilePictureDefaultId
								}
								task {
									name
								}
							}
						}
					}
				}
				...BudgetPage_project
				...expensesPage_project
				...ratesAndInternalCostPage_project
			}
			program {
				id
				name
				prefix
				budgetType
				projects(first: 10000) @connection(key: "Program_projects", filters: []) {
					edges {
						node {
							id
							serviceCompanyId
							companyProjectId
							name
							prefix
							budgetType
							budget
							defaultPeriodBudgetType
							financialNumbers {
								allActualTimeAndExpenses
								recognitionProfitPercentage
								allTotalTimeAndExpensesAtCompletion
								forecastCostToComplete
							}
							financialNumbersToDate {
								recognitionActualProfitPercentage
								allActualTimeAndExpenses
							}
							totalRevenueRecognition
							totalRevenueRecognitionToDate
							totalTimeRevenueRecognitionToDate
							totalBillableExpensesAtCompletion
						}
					}
				}
				projectsFinancialsTotals {
					recognitionForecastRevenue # Revenue - Recognition at completion
					allActualTimeAndExpenses # Spend - Actual to date
					allTotalTimeAndExpensesAtCompletion # Spend - Total at completion
					recognitionProfitPercentage # Profit margin - total at completion
					recognitionActualProfitPercentage # Profit margin - total at completion
				}
				projectsFinancialsToDate {
					totalActualRevenueRecognition # Revenue - recognised to date
					recognitionAmount # Revenue - recognised to date
					recognitionActualProfitPercentage # Profit margin - actual to date
					allActualTimeAndExpenses # Spend - actual to date
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError, uploadables) {
	Util.localStorageDeleteFincancialMap();
	const changes = [];

	let status;

	for (const key of Object.keys(input)) {
		if (['id', 'project'].includes(key) || input[key] === undefined) {
			continue;
		}

		if (key === 'status') {
			status = input.status;
		}

		changes.push(key);
	}

	const options = {_Changed: changes};
	if (status) {
		options.Status = status;
	}
	tracking.trackEvent('Project Updated', options);

	const cleanedVariables = omit(input, ['project']);
	cleanedVariables.id = input.project.id;

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
