/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
export type UpdateProgramFinancialMetadataInput = {|
  id?: ?string,
  budgetType?: ?PROGRAM_BUDGET_TYPE,
  budgetValue?: ?number,
  revenueSetting?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateProgramFinancialMetadataMutationVariables = {|
  input: UpdateProgramFinancialMetadataInput
|};
export type updateProgramFinancialMetadataMutationResponse = {|
  +updateProgramFinancialMetadata: ?{|
    +program: ?{|
      +node: ?{|
        +id: string,
        +budgetType: ?PROGRAM_BUDGET_TYPE,
        +budgetValue: ?number,
        +revenueSetting: ?string,
      |}
    |}
  |}
|};
export type updateProgramFinancialMetadataMutation = {|
  variables: updateProgramFinancialMetadataMutationVariables,
  response: updateProgramFinancialMetadataMutationResponse,
|};
*/


/*
mutation updateProgramFinancialMetadataMutation(
  $input: UpdateProgramFinancialMetadataInput!
) {
  updateProgramFinancialMetadata(input: $input) {
    program {
      node {
        id
        budgetType
        budgetValue
        revenueSetting
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProgramFinancialMetadataInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateProgramFinancialMetadataPayload",
    "kind": "LinkedField",
    "name": "updateProgramFinancialMetadata",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectServiceProgramTypeEdge",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budgetType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budgetValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revenueSetting",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateProgramFinancialMetadataMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateProgramFinancialMetadataMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateProgramFinancialMetadataMutation",
    "operationKind": "mutation",
    "text": "mutation updateProgramFinancialMetadataMutation(\n  $input: UpdateProgramFinancialMetadataInput!\n) {\n  updateProgramFinancialMetadata(input: $input) {\n    program {\n      node {\n        id\n        budgetType\n        budgetValue\n        revenueSetting\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '470d27574cbc8a016e51a33d9607b725';

module.exports = node;
