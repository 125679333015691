/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SyncGitlabDataInput = {|
  projectId: string,
  gitlabProjectId?: ?string,
  gitlabProjectName?: ?string,
  server?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type syncGitlabDataMutationVariables = {|
  input: SyncGitlabDataInput
|};
export type syncGitlabDataMutationResponse = {|
  +syncGitlabData: ?{|
    +project: ?{|
      +gitlabProjectId: ?string,
      +gitlabProjectName: ?string,
      +gitlabServerProjectId: ?string,
    |}
  |}
|};
export type syncGitlabDataMutation = {|
  variables: syncGitlabDataMutationVariables,
  response: syncGitlabDataMutationResponse,
|};
*/


/*
mutation syncGitlabDataMutation(
  $input: SyncGitlabDataInput!
) {
  syncGitlabData(input: $input) {
    project {
      gitlabProjectId
      gitlabProjectName
      gitlabServerProjectId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SyncGitlabDataInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gitlabProjectId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gitlabProjectName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gitlabServerProjectId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "syncGitlabDataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncGitlabDataPayload",
        "kind": "LinkedField",
        "name": "syncGitlabData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "syncGitlabDataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncGitlabDataPayload",
        "kind": "LinkedField",
        "name": "syncGitlabData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "syncGitlabDataMutation",
    "operationKind": "mutation",
    "text": "mutation syncGitlabDataMutation(\n  $input: SyncGitlabDataInput!\n) {\n  syncGitlabData(input: $input) {\n    project {\n      gitlabProjectId\n      gitlabProjectName\n      gitlabServerProjectId\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24b334c15e33a6ee4cdbe969ef0a5339';

module.exports = node;
