import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation suppressTimeRegOnProjectWarningMutation($input: SuppressTimeRegOnProjectWarningInput!) {
		suppressTimeRegOnProjectWarning(input: $input) {
			clientMutationId
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('TimeReg on Project Warning suppressed');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
