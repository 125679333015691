/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation CreateSageCompanyMutation(
  $input: CreateSageCompanyInput!
) {
  CreateSageCompany(input: $input) {
    resultCode
    companyId
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "CreateSageCompanyInput!"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "CreateSageCompanyPayload",
            "kind": "LinkedField",
            "name": "CreateSageCompany",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "resultCode",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateSageCompanyMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateSageCompanyMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "CreateSageCompanyMutation",
            "operationKind": "mutation",
            "text": "mutation CreateSageCompanyMutation(\n  $input: CreateSageCompanyInput!\n) {\n  CreateSageCompany(input: $input) {\n    resultCode\n    companyId\n  }\n}\n"
        }
    };
})();
node.hash = '008e721077f05207fe6e8a72119ff940';
export default node;
