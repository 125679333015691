import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createInsightMutation($input: CreateInsightInput!) {
		createInsight(input: $input) {
			insight {
				node {
					id
					name
					category
					components {
						id
						componentName
						config
						w
						h
						x
						y
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_insights',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'insight',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Insight Created', {
		_Category: input.category,
		_Components: input.components.map(c => c.componentName),
	});
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				name: input.name,
				category: input.category,
				components: input.components,
			},
		},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
