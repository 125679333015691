/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImportSageIntacctProjectInput = {|
  sageIntacctProjectId?: ?string,
  forecastProjectId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type ImportSageIntacctProjectMutationVariables = {|
  input: ImportSageIntacctProjectInput
|};
export type ImportSageIntacctProjectMutationResponse = {|
  +importSageIntacctProject: ?{|
    +project: ?{|
      +id: string,
      +sageProject: ?{|
        +sageProjectId: ?string
      |},
    |}
  |}
|};
export type ImportSageIntacctProjectMutation = {|
  variables: ImportSageIntacctProjectMutationVariables,
  response: ImportSageIntacctProjectMutationResponse,
|};
*/


/*
mutation ImportSageIntacctProjectMutation(
  $input: ImportSageIntacctProjectInput!
) {
  importSageIntacctProject(input: $input) {
    project {
      id
      sageProject {
        sageProjectId
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImportSageIntacctProjectInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sageProjectId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportSageIntacctProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ImportSageIntacctProjectPayload",
        "kind": "LinkedField",
        "name": "importSageIntacctProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SageProjectType",
                "kind": "LinkedField",
                "name": "sageProject",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportSageIntacctProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ImportSageIntacctProjectPayload",
        "kind": "LinkedField",
        "name": "importSageIntacctProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SageProjectType",
                "kind": "LinkedField",
                "name": "sageProject",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ImportSageIntacctProjectMutation",
    "operationKind": "mutation",
    "text": "mutation ImportSageIntacctProjectMutation(\n  $input: ImportSageIntacctProjectInput!\n) {\n  importSageIntacctProject(input: $input) {\n    project {\n      id\n      sageProject {\n        sageProjectId\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f67e72cf0b23deb0b83e17006458270';

module.exports = node;
