/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AcceptTermsInput = {|
  personId?: ?string,
  termsVersionAccepted?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type AcceptTermsMutationVariables = {|
  input: AcceptTermsInput
|};
export type AcceptTermsMutationResponse = {|
  +acceptTerms: ?{|
    +viewer: ?{|
      +actualPersonId: ?string,
      +hasAcceptedTerms: ?boolean,
    |}
  |}
|};
export type AcceptTermsMutation = {|
  variables: AcceptTermsMutationVariables,
  response: AcceptTermsMutationResponse,
|};
*/


/*
mutation AcceptTermsMutation(
  $input: AcceptTermsInput!
) {
  acceptTerms(input: $input) {
    viewer {
      actualPersonId
      hasAcceptedTerms
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AcceptTermsInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAcceptedTerms",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptTermsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptTermsPayload",
        "kind": "LinkedField",
        "name": "acceptTerms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AcceptTermsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AcceptTermsPayload",
        "kind": "LinkedField",
        "name": "acceptTerms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AcceptTermsMutation",
    "operationKind": "mutation",
    "text": "mutation AcceptTermsMutation(\n  $input: AcceptTermsInput!\n) {\n  acceptTerms(input: $input) {\n    viewer {\n      actualPersonId\n      hasAcceptedTerms\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3b115099a1698b4831aed2bc874c1816';

module.exports = node;
