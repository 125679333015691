/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RetryUnit4LogItemInput = {|
  viewerId?: ?string,
  type?: ?string,
  method?: ?string,
  forecastId?: ?number,
  unit4Identifier?: ?string,
  date?: ?string,
  taskId?: ?number,
  personId?: ?number,
  projectId?: ?number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type RetryUnit4LogItemMutationVariables = {|
  input: RetryUnit4LogItemInput
|};
export type RetryUnit4LogItemMutationResponse = {|
  +retryUnit4LogItem: ?{|
    +viewer: ?{|
      +id: string,
      +unit4LogItems: ?$ReadOnlyArray<?{|
        +id: ?string,
        +type: ?string,
        +person: ?string,
        +project: ?string,
        +workorder: ?string,
        +task: ?string,
        +date: ?string,
        +minutesRegistered: ?string,
        +notes: ?string,
        +errorMessage: ?string,
      |}>,
    |},
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type RetryUnit4LogItemMutation = {|
  variables: RetryUnit4LogItemMutationVariables,
  response: RetryUnit4LogItemMutationResponse,
|};
*/


/*
mutation RetryUnit4LogItemMutation(
  $input: RetryUnit4LogItemInput!
) {
  retryUnit4LogItem(input: $input) {
    viewer {
      id
      unit4LogItems {
        id
        type
        person
        project
        workorder
        task
        date
        minutesRegistered
        notes
        errorMessage
      }
    }
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "RetryUnit4LogItemInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RetryUnit4LogItemPayload",
    "kind": "LinkedField",
    "name": "retryUnit4LogItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Unit4LogItemType",
            "kind": "LinkedField",
            "name": "unit4LogItems",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "person",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "project",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workorder",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "task",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minutesRegistered",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "errorMessage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RetryUnit4LogItemMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RetryUnit4LogItemMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "RetryUnit4LogItemMutation",
    "operationKind": "mutation",
    "text": "mutation RetryUnit4LogItemMutation(\n  $input: RetryUnit4LogItemInput!\n) {\n  retryUnit4LogItem(input: $input) {\n    viewer {\n      id\n      unit4LogItems {\n        id\n        type\n        person\n        project\n        workorder\n        task\n        date\n        minutesRegistered\n        notes\n        errorMessage\n      }\n    }\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac592bccad377ac3a3bd744a3c41a037';

module.exports = node;
