import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
import {ConnectionHandler} from 'relay-runtime';

const mutation = graphql`
	mutation updateTaskMutationModernMutation($input: UpdateTaskInput!) {
		updateTask(input: $input) {
			errors
			updatedTasksIds
			childrenTasks {
				edges {
					node {
						id
						sprint {
							id
						}
						phase {
							id
						}
						deadlineDay
						deadlineMonth
						deadlineYear
						deadlineFrom
						startDay
						startMonth
						startYear
						startFrom
					}
				}
			}
			taskEdge {
				node {
					id
					...DraggableCard_task
				}
			}
			taskEdges {
				node {
					id
					...DraggableCard_task
				}
			}
			parentTask {
				id
				childrenTasks(first: 1000000) @connection(key: "ParentTask_childrenTasks", filters: []) {
					edges {
						node {
							id
						}
					}
				}
			}
			previousParentTask {
				id
				childrenTasks(first: 1000000) @connection(key: "ParentTask_childrenTasks", filters: []) {
					edges {
						node {
							id
						}
					}
				}
			}
			tasks {
				id
				...DraggableCard_task
				assignedPersons {
					id
				}
				parentTaskId
				parentTask {
					id
				}
				billable
				blocked
				bug
				companyTaskId
				deadlineDay
				deadlineMonth
				deadlineYear
				deadlineFrom
				startFrom
				done
				highPriority
				id
				role {
					id
				}
				phase {
					id
					progress
					progressDetails {
						progress
					}
				}
				sprint {
					id
				}
				statusColumnV2 {
					id
				}
				progress
				startDay
				startMonth
				startYear
				timeLeft
				timeLeftMinutesWithoutFutureTimeRegs
				hasChildren
				name
				progressDetails {
					progress
				}
				estimateForecast
				estimateForecastPrice
				estimateForecastMinutes
				approved
				currentPrice
				allTotalTimeAndExpensesAtCompletion
				plannedCost
				actualCost
				taskLabels {
					label {
						id
						name
						color
					}
				}
				project {
					id
					completion
					remaining
					forecast
					progress
					progressDetails {
						progress
					}
				}
				owner {
					id
				}
				followers {
					id
				}
				timeRegistrations {
					edges {
						node {
							billableMinutesRegistered
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	const configs = [];
	if (input.deleteNode) {
		configs.push({
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_tasks',
				},
			],
			pathToConnection: ['project', 'tasks'],
			deletedIDFieldName: 'updatedTasksIds',
		});
	}
	if (input.companyId) {
		if (input.statusColumnId && input.prevStatusColumnId) {
			configs.push({
				type: 'RANGE_DELETE',
				parentID: input.companyId,
				connectionKeys: [
					{
						key: 'Company_allTasks',
						filters: {filterColumnId: input.prevStatusColumnId},
					},
				],
				pathToConnection: ['company', 'allTasks'],
				deletedIDFieldName: 'updatedTasksIds',
			});
			configs.push({
				type: 'RANGE_ADD',
				parentID: input.companyId,
				connectionInfo: [
					{
						key: 'Company_allTasks',
						filters: {filterColumnId: input.statusColumnId},
						rangeBehavior: 'prepend',
					},
				],
				edgeName: 'taskEdge',
			});
		}
		if (input.projectGroupStatusColumnId && input.prevProjectGroupStatusColumnId) {
			configs.push({
				type: 'RANGE_DELETE',
				parentID: input.companyId,
				connectionKeys: [
					{
						key: 'Company_allTasks',
						filters: {filterColumnId: input.prevProjectGroupStatusColumnId.toString()},
					},
				],
				pathToConnection: ['company', 'allTasks'],
				deletedIDFieldName: 'updatedTasksIds',
			});
			configs.push({
				type: 'RANGE_ADD',
				parentID: input.companyId,
				connectionInfo: [
					{
						key: 'Company_allTasks',
						filters: {filterColumnId: input.projectGroupStatusColumnId.toString()},
						rangeBehavior: 'prepend',
					},
				],
				edgeName: 'taskEdge',
			});
		}
	}
	return configs;
}

function updater(store, input, id, updatedEdge) {
	// This is to handle the support of bulk sprint action on the new Sprint page V4.
	// It uses two different connections for the sprint task and the backlog.
	if (input.pageContext === 'sprintv4') {
		const task = updatedEdge.getLinkedRecord('node');
		const projectId = task.getLinkedRecord('project').getValue('id');

		const projectProxy = store.get(projectId);
		const backLogFilter = {backlog: true};

		const prevTaskState = input.prevStates.find(task => task.node.id === id).node;
		const prevSprintId = prevTaskState?.sprint?.id;
		const prevProjectGroupSprintId = prevTaskState?.sprint?.projectGroupSprintId;

		if (input.sprintId !== prevSprintId && (input.sprintId === null || !prevSprintId)) {
			const prevConn = ConnectionHandler.getConnection(
				projectProxy,
				'Project_tasks',
				prevSprintId ? null : backLogFilter
			);

			const newConn = ConnectionHandler.getConnection(
				projectProxy,
				'Project_tasks',
				input.sprintId ? null : backLogFilter
			);

			if (prevConn) {
				ConnectionHandler.deleteNode(prevConn, id);
			}
			if (newConn) {
				ConnectionHandler.insertEdgeBefore(newConn, updatedEdge);
			}
		} else if (
			input.projectGroupSprintId !== prevProjectGroupSprintId &&
			(input.projectGroupSprintId === null || !prevProjectGroupSprintId)
		) {
			const prevConn = ConnectionHandler.getConnection(
				projectProxy,
				'Project_tasks',
				prevProjectGroupSprintId ? null : backLogFilter
			);

			const newConn = ConnectionHandler.getConnection(
				projectProxy,
				'Project_tasks',
				input.projectGroupSprintId ? null : backLogFilter
			);

			if (prevConn) {
				ConnectionHandler.deleteNode(prevConn, id);
			}

			if (newConn) {
				ConnectionHandler.insertEdgeBefore(newConn, updatedEdge);
			}
		}
	}
}

function getOptimisticResponse(input) {
	const tasks = [];
	input.ids.forEach(id => {
		const task = {id: id};
		if (input.startDay) {
			task.startDay = input.startDay;
		}
		if (input.startMonth) {
			task.startMonth = input.startMonth;
		}
		if (input.startYear) {
			task.startYear = input.startYear;
		}
		if (input.deadlineDay) {
			task.deadlineDay = input.deadlineDay;
		}
		if (input.deadlineMonth) {
			task.deadlineMonth = input.deadlineMonth;
		}
		if (input.deadlineYear) {
			task.deadlineYear = input.deadlineYear;
		}
		if (input.description) {
			task.description = input.description;
		}
		if (input.name) {
			task.name = input.name;
		}
		if (input.forecast !== null && input.forecast !== undefined) {
			task.estimateForecast = input.forecast;
		}
		if (input.timeLeftOptimistic) {
			task.timeLeft = input.timeLeftOptimistic;
		}

		if (input.sprintId === null) {
			task.sprint = {id: null};
			task.startFrom = null;
			task.deadlineFrom = null;
		}
		if (input.sprintId) {
			task.sprint = {id: input.sprintId};
			task.startFrom = null;
			task.deadlineFrom = null;
		}
		if (input.roleId !== undefined) {
			task.role = {id: input.roleId};
		}
		if (input.phaseId !== undefined) {
			task.phase = input.phaseId ? {id: input.phaseId} : null;
			task.startFrom = null;
			task.deadlineFrom = null;
		}
		if (input.coverFileId) {
			task.coverFile = {id: input.coverFileId};
		}
		if (input.aboveTaskOrder) {
			task.sortOrder = input.aboveTaskOrder + 1;
		}
		if (input.optimisticTaskOrder) {
			task.sortOrder = input.optimisticTaskOrder;
		}
		if (input.aboveFavouredTaskOrder) {
			//task.favouredSortOrder = input.aboveFavouredTaskOrder + BigInt(1); // 1n doesn't compile :(
			/*
			 *	Just to add 1 to bigInt made of a string.
			 *	This code should be removed (and replace by the line bellow)
			 *	as soon as Safari, IE and Edge accept BigInt
			 */
			let aboveFavouredTaskOrderFormatted = input.aboveFavouredTaskOrder;
			let i = aboveFavouredTaskOrderFormatted.length - 1;
			while (aboveFavouredTaskOrderFormatted[i] === '9' && i >= 0) {
				aboveFavouredTaskOrderFormatted = Util.replaceAt(aboveFavouredTaskOrderFormatted, i, '0');
				i--;
			}
			if (i < 0) {
				aboveFavouredTaskOrderFormatted = '1' + aboveFavouredTaskOrderFormatted;
			} else {
				aboveFavouredTaskOrderFormatted = Util.replaceAt(
					aboveFavouredTaskOrderFormatted,
					i,
					parseInt(aboveFavouredTaskOrderFormatted[i]) + 1
				);
			}
			task.favouredSortOrder = aboveFavouredTaskOrderFormatted;
		}
		if (input.favouredTaskOrder) {
			task.favouredSortOrder = input.favouredTaskOrder;
		}
		if (input.blocked !== undefined) {
			task.blocked = input.blocked;
		}
		if (input.approved !== undefined) {
			task.approved = input.approved;
		}
		if (input.bug !== undefined) {
			task.bug = input.bug;
		}
		if (input.assignedPersons) {
			task.assignedPersons = input.assignedPersons.map(p => ({id: p}));
		}
		if (input.repeatingTaskId) {
			task.repeatingTaskId = {id: input.repeatingTaskId};
		}
		if (input.statusColumnId && !input.statusColumnV2Id) {
			task.statusColumnV2 = {id: input.statusColumnId};
		}
		if (input.statusColumnV2Id) {
			task.statusColumnV2 = {id: input.statusColumnV2Id};
		}
		// if (input.actualStatusColumnId) {
		// 	task.statusColumn = {id: input.actualStatusColumnId};
		// }
		if (input.projectGroupStatusIdToStatusIdMap && input.taskIdToProjectIdMap && input.projectGroupStatusColumnId) {
			const projectId = input.taskIdToProjectIdMap.get(id);
			const groupStatusIdToStatusIdMap = input.projectGroupStatusIdToStatusIdMap.get(projectId);
			task.statusColumn = {id: groupStatusIdToStatusIdMap.get(input.projectGroupStatusColumnId)};
		}
		if (input.projectGroupStatusColumnId && input.optimisticColumnId) {
			task.statusColumnV2 = {id: input.optimisticColumnId};
		}
		if (input.previousProjectId && input.projectId) {
			task.projectId = input.projectId;
		}
		if (input.remaining) {
			task.timeLeft = input.remaining;
		}
		if (input.highPriority !== undefined) {
			task.highPriority = input.highPriority;
		}
		if (input.ownerId === null) {
			task.owner = null;
		}
		if (input.ownerId) {
			task.owner = {id: input.ownerId};
		}
		if (input.followers) {
			task.followers = input.followers.map(f => ({id: f}));
		}
		if (input.favoured !== undefined) {
			task.favoured = input.favoured;
		}
		if (input.parentTaskId !== undefined) {
			task.parentTaskId = input.parentTaskId;
		}
		if (input.previousParentTaskId) {
			task.previousParentTaskId = input.previousParentTaskId;
		}
		if (input.labels && Array.isArray(input.labels)) {
			task.taskLabels = input.labels.map(label => ({id: undefined, label: {id: label}}));
		}
		tasks.push(task);
	});

	return {
		updateTask: {
			tasks,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	Util.localStorageDeleteFincancialMap();
	const changes = [];

	for (const key of Object.keys(input)) {
		if (
			[
				'ids',
				'id',
				'aboveTaskOrder',
				'viewerId',
				'viewerActualId',
				'optimisticTaskOrder',
				'previousAssignedPersons',
			].includes(key) ||
			input[key] === undefined
		) {
			continue;
		}

		if (key === 'aboveTaskId') {
			changes.push('sortOrder');
		} else {
			changes.push(key);
		}
	}

	tracking.trackEvent('Card Updated', {_Changed: changes, bulkUpdate: input.ids && input.ids.length > 1});

	const cleanedInput = omit(input, [
		'companyId',
		'timeLeftOptimistic',
		'optimisticTaskOrder',
		'optimisticColumnId',
		'deleteNode',
		'statusColumnCategory',
		'prevStatusColumnId',
		'prevProjectGroupStatusColumnId',
		'pageContext',
		'prevStates',
	]);

	const additionalTracking = {mutationAffectedCount: input.ids ? input.ids.length : 1};
	if (input.statusColumnCategory) {
		additionalTracking.statusColumnCategory = input.statusColumnCategory;
	}
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};
	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables,
			updater: store => {
				const payload = store.getRootField('updateTask');
				const updatedEdges = payload.getLinkedRecords('taskEdges');
				input.ids.forEach(id => {
					const updatedEdge = updatedEdges?.find(edge => edge.getLinkedRecord('node').getValue('id') === id);
					updater(store, input, id, updatedEdge);
				});
			},
			configs: getConfigs(input),
			optimisticResponse: getOptimisticResponse(input),
			onCompleted: onSuccess,
			onError: onError,
		},
		true,
		undefined,
		additionalTracking
	);
}

export default {commit};
