import styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../css_variables';
import up_arrow from '../../images/up-arrow.svg';
import expanded from '../../images/header/expanded.svg';

const InvoiceDataFormInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	:not(:last-child) {
		margin-right: 10px;
	}
`;

export const ContentWrapper = styled.div`
	/* make the modal 1000px wide */
	min-width: 884px;
	min-height: ${props => props.minHeight || '500px'};
	display: flex;
	flex-direction: column;
	width: 1100px;
`;

export const SageIntacctExportModalContentWrapper = styled.div`
	/* make the modal 1000px wide */
	min-width: 884px;
	min-height: ${props => props.minHeight || '500px'};
	display: flex;
	flex-direction: column;
	width: 1300px;
`;

export const GeneralSettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const InvoiceSetting = styled.div`
	/*width: 100px;*/
	margin-bottom: 27px;
`;

export const InvoiceSettingTitle = styled.div`
	font-size: 9px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 1px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	text-transform: uppercase;
	margin-bottom: 8px;
`;

export const BlankInvoiceSettingOptions = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-top: 15px;
	font-size: 13px;
	line-height: 22px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	.radio-button-wrapper {
		&:not(:last-child) {
			margin-right: 17px;
		}
	}
`;

export const ClientTimespanContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const ClientDropdownContainer = styled.div`
	flex: 0 0 220px;
`;

export const InvoiceDataForm = styled.div`
	.input-value-container.locked,
	.date-picker-button-container-v3 .date-picker-button.locked {
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		background-color: ${CSS_CONSTANTS.v2_input_disabled_grey};
		opacity: 1;
		&:hover {
			background-color: ${CSS_CONSTANTS.v2_input_disabled_grey};
		}
	}
`;

export const InvoiceDataLine = styled.div`
	display: flex;
`;

export const InvoiceDataLineOne = styled.div`
	margin-top: 25px;
	display: flex;

	.date-range-picker-wrapper-new .date-picker-label,
	.fixed-range-picker-wrapper-new .date-picker-label {
		margin-bottom: 5px;
	}
`;

export const InvoiceIdInputContainer = styled(InvoiceDataFormInputContainer)``;

export const InvoiceNameInputContainer = styled(InvoiceDataFormInputContainer)`
	flex-grow: 1;

	.error-message {
		color: #d0021b;
		font-size: 13px;
		padding-left: 8px;
		padding-top: 4px;
	}
`;

export const InvoiceDatesInputContainer = styled(InvoiceDataFormInputContainer)``;

export const InvoiceDataLineTwo = styled.div`
	margin-top: 25px;
	display: flex;
`;

export const InvoiceProjects = styled.div`
	flex-grow: 1;
	margin-right: 10px;
	max-width: 420px;
`;
export const InvoiceProjectsLabel = styled.div`
	font-size: 9px;
	font-weight: 500;
	line-height: 1.22;
	letter-spacing: 1px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	text-transform: uppercase;
	margin-bottom: 5px;
`;

export const InvoiceProjectsList = styled.div`
	flex: 1;
	display: flex;
	height: 40px;
	padding: 10px 0;
`;

export const InvoiceFinancialInputContainer = styled(InvoiceDataFormInputContainer)`
	width: 105px;
`;

export const InvoiceDropdownContainer = styled(InvoiceDataFormInputContainer)`
	width: 290px;
	margin-bottom: 30px;
`;

export const InvoiceDataLineThree = styled.div`
	margin-top: 25px;
	.rich-text-field-editor-wrapper {
		min-height: 37px;
	}
	.rich-text-field-label {
		font-size: 9px;
	}
`;

export const ProjectTablesContainer = styled.div`
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const ProjectTablesWrapper = styled.div``;

export const GroupingBox = styled.div`
	background-color: ${CSS_CONSTANTS.color_medium_gray};
	margin-top: 30px;
	display: flex;
	padding: 35px 40px;
	.drop-down-v2 {
		width: 180px;
	}
`;

export const GroupingBoxSection = styled.div`
	&:first-child {
		border-right: solid 1px #d2cece;
		flex-grow: 0;
		padding-right: 40px;
	}
	&:not(:first-child) {
		margin-left: 40px;
	}
`;

export const GroupingBoxSectionContent = styled.div`
	display: flex;
`;

export const GroupingBoxHeadline = styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-weight: 500;
	margin-bottom: 17px;
`;

export const RadioButtonSection = styled.div`
	margin-right: 34px;
`;

export const RadioButtonHeadline = styled.div`
	font-size: 9px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	font-weight: 500;
	margin-bottom: 15px;
	text-transform: uppercase;
	line-height: 1.22;
	letter-spacing: 1px;
`;

export const RadioButtonWrapper = styled.div`
	display: flex;
	.text {
		font-size: 13px;
		margin-left: 8px;
		margin-right: 17px;
		line-height: 22px;
	}
`;

export const EditInvoiceModalTabContent = styled.div`
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const ProjectIndicatorWrapper = styled.div`
	max-width: 46px;
	&:not(:last-child) {
		margin-right: 4px;
	}
`;

export const SwitchTitle = styled.div`
	font-weight: 500;
	margin-bottom: 9px;
	font-size: 9px;
	letter-spacing: 1px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	line-height: 1;
	text-transform: uppercase;
`;

export const SwitchWrapper = styled.div`
	width: 290px;
	margin-bottom: 30px;
	margin-right: 10px;
`;

export const HeaderContainer = styled.div`
	display: flex;
`;

export const InvoiceTable = styled.div`
	margin-bottom: 46px;
`;

export const InvoiceTableHeader = styled.div`
	display: flex;
	.expand-section {
		display: flex;
	}
	.headline {
		font-size: 16px;
		font-weight: 600;
		color: ${CSS_CONSTANTS.v2_text_gray};
		margin-right: 10px;
	}
	.date-selector {
		margin-left: auto;
		.date-picker-button {
			height: 17px;
		}
	}
	.controls {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		.expand-control {
			width: 11px;
			height: 21px;
			background-size: 21px;
			background-repeat: no-repeat;
			background-position: center;
			cursor: pointer;
			&.collapse {
				background-image: url(${expanded});
			}

			&.expand {
				background-image: url(${up_arrow});
			}
		}
	}
`;
