// @ts-check

import {useEffect, useRef} from 'react';
import {useSingleton} from '../../../forecast-app/shared/hooks/useSingleton';
import {registerSuperProperty, SUPER_PROPERTY, trackEvent, unregisterSuperProperty} from '../TrackingV2';

/**
 * Hook that handles tracking along with super property registration and unregistration for Modal Viewed events.
 * This function uses the useSingleton pattern to allow executing the tracking function before the component and all its children are rendered, but still only executing it once.
 * This allows us to register super properties and have them included in tracking calls made by a component's children on mount.
 * @param {string | null} modalName name of the modal being tracking
 * @param {Object} [modalProperties={}] passed properties will be attached to the tracking event, but will not be included in the AMPLITUDE_MODAL_INFO super property
 * @param {Object} [modalSuperProperties={}] passed properties will be set as part of the AMPLITUDE_MODAL_INFO super property and will be attached to all future events until modal is unmounted
 * @param {Array} [deps=[]] array of items that when changed will cause the callback to trigger again, works similar to the deps array of native React hooks.
 * Generally, you should not include something in the deps that you are not including in the Super Property.
 */
export const useTrackModal = (modalName, modalProperties = {}, modalSuperProperties = {}, deps = []) => {
	const checksumRef = useRef('');

	useSingleton(() => {
		if (modalName) {
			const superProperties = {modalName: modalName, ...modalSuperProperties};
			const properties = {...modalProperties, ...superProperties};

			checksumRef.current = registerSuperProperty(SUPER_PROPERTY.AMPLITUDE_MODAL_INFO, superProperties);

			trackEvent(modalName, 'Viewed', properties);
		}
	}, deps);

	useEffect(() => {
		return () => {
			if (modalName) {
				unregisterSuperProperty(SUPER_PROPERTY.AMPLITUDE_MODAL_INFO, checksumRef.current);
			}
		};
	}, deps);
};
