import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
import {trackEvent} from '../tracking/amplitude/TrackingV2';

const mutation = graphql`
	mutation renameSavedReportMutation($input: RenameSavedReportInput!) {
		renameSavedReport(input: $input) {
			savedReport {
				id
				name
				...GenericSavedReportConductor_savedReport
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Saved Report renamed', {_Changed: changes});
	trackEvent('Standard Report', 'Renamed');

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
