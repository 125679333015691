import {omit} from 'lodash';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteTimeRegistrationMutationModernMutation($input: DeleteTimeRegInput!) {
		deleteTimeReg(input: $input) {
			deletedTimeRegId
			task {
				timeLeft
				timeLeftMinutesWithoutFutureTimeRegs
				totalMinutesRegistered
				progress
				project {
					completion
					remaining
					forecast
				}
			}
			errors
		}
	}
`;

function getConfigs(input) {
	const config = [
		{
			type: 'RANGE_DELETE',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionKeys: [
				{
					key: 'Viewer_timeRegistrations',
				},
			],
			pathToConnection: ['viewer', 'timeRegistrations'],
			deletedIDFieldName: 'deletedTimeRegId',
		},
	];

	if (input.companyId) {
		config.push({
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_timeRegistrations',
				},
			],
			pathToConnection: ['task', 'timeRegistrations'],
			deletedIDFieldName: 'deletedTimeRegId',
		});
	}

	if (input.taskId) {
		config.push({
			type: 'RANGE_DELETE',
			parentID: input.taskId,
			connectionKeys: [
				{
					key: 'Task_timeRegistrations',
				},
			],
			pathToConnection: ['task', 'timeRegistrations'],
			deletedIDFieldName: 'deletedTimeRegId',
		});
	}
	if (input.projectId) {
		config.push({
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_timeRegistrations',
				},
			],
			pathToConnection: ['project', 'timeRegistrations'],
			deletedIDFieldName: 'deletedTimeRegId',
		});
	}
	return config;
}

function getOptimisticResponse(input) {
	return {
		deleteTimeReg: {
			deletedTimeRegId: input.id,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Time Registration Deleted');

	const cleanedVariables = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
