/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCompanyInput = {|
  name?: ?string,
  street?: ?string,
  zip?: ?string,
  city?: ?string,
  country?: ?string,
  currency?: ?string,
  timezone?: ?string,
  vatId?: ?string,
  logoId?: ?string,
  defaultRateCardId?: ?string,
  defaultHolidayCalendarId?: ?string,
  defaultExpenseCategoryId?: ?string,
  defaultRevenueMarginLow?: ?number,
  defaultRevenueMarginHigh?: ?number,
  disableNotifications?: ?boolean,
  timeRegNotificationSchedule?: ?string,
  projectOverviewNotificationSchedule?: ?string,
  contactPersonId?: ?string,
  contactName?: ?string,
  contactPhone?: ?string,
  contactEmail?: ?string,
  companyTarget?: ?number,
  monday?: ?number,
  tuesday?: ?number,
  wednesday?: ?number,
  thursday?: ?number,
  friday?: ?number,
  saturday?: ?number,
  sunday?: ?number,
  useTimeApproval?: ?boolean,
  useInternalTimeApproval?: ?boolean,
  useTimeOffApproval?: ?boolean,
  allUsersModifyTimeOff?: ?boolean,
  harvestDeactivate?: ?boolean,
  timelogDeactivate?: ?boolean,
  economicDeactivate?: ?boolean,
  slackDeactivate?: ?boolean,
  gitlabDeactivate?: ?boolean,
  gitlabServerDeactivate?: ?boolean,
  gitlabDeleteIssues?: ?boolean,
  calendarEnabled?: ?boolean,
  calendarApiEnabled?: ?boolean,
  outlookCalendarApiEnabled?: ?boolean,
  jiraServerUrl?: ?string,
  jiraPrefixType?: ?string,
  jiraServerPrefixType?: ?string,
  tempoOauthClientId?: ?string,
  tempoOauthClientSecret?: ?string,
  salesforceDeactivate?: ?boolean,
  salesforceAutoCreate?: ?boolean,
  xeroUninstall?: ?boolean,
  githubUnauthorizedInstallation?: ?number,
  githubDeactivate?: ?boolean,
  vstsDeactivate?: ?boolean,
  vstsEstimateField?: ?string,
  oktaClientId?: ?string,
  oktaClientSecret?: ?string,
  oktaUrl?: ?string,
  oneloginClientId?: ?string,
  oneloginClientSecret?: ?string,
  oneloginUrl?: ?string,
  unit4Enabled?: ?boolean,
  unit4TwoWay?: ?boolean,
  unit4SyncDateLimit?: ?string,
  AADClientId?: ?string,
  AADClientSecret?: ?string,
  AADTenant?: ?string,
  isUsingSchedulingPlanMode?: ?boolean,
  isUsingProjectAllocation?: ?boolean,
  isUsingMixedAllocation?: ?boolean,
  usingProjectAllocationCreateAllocations?: ?boolean,
  userSeats?: ?number,
  collaboratorSeats?: ?number,
  virtualSeats?: ?number,
  timesheetReminderDay?: ?string,
  timesheetReminderHour?: ?number,
  timesheetReminderEnabled?: ?boolean,
  timesheetReminderIncludeToday?: ?boolean,
  projectDigestNotificationDay?: ?string,
  projectDigestNotificationHour?: ?number,
  projectDigestNotificationEnabled?: ?boolean,
  userCanUnlinkInvoice?: ?boolean,
  skillLevelsEnabled?: ?boolean,
  revenueEnabled?: ?boolean,
  timesheetsEnabled?: ?boolean,
  clientsEnabled?: ?boolean,
  betaOptIn?: ?boolean,
  weekendDisplayPerUser?: ?boolean,
  weekendDisplayShowAlways?: ?boolean,
  invoiceQuantityDecimals?: ?number,
  addFeatureFlagKeysForCypress?: ?$ReadOnlyArray<?string>,
  isSSOMandatory?: ?boolean,
  sageIntacctTimeRegsFromForecast?: ?boolean,
  sageIntacctSyncOnlyApproved?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateCompanySubscriptionDataMutationVariables = {|
  input: UpdateCompanyInput
|};
export type updateCompanySubscriptionDataMutationResponse = {|
  +updateCompany: ?{|
    +company: ?{|
      +id: string,
      +userSeats: ?number,
      +collaboratorSeats: ?number,
      +virtualSeats: ?number,
      +subscription: ?{|
        +seats: ?number,
        +collaboratorSeats: ?number,
        +virtualSeats: ?number,
      |},
    |}
  |}
|};
export type updateCompanySubscriptionDataMutation = {|
  variables: updateCompanySubscriptionDataMutationVariables,
  response: updateCompanySubscriptionDataMutationResponse,
|};
*/


/*
mutation updateCompanySubscriptionDataMutation(
  $input: UpdateCompanyInput!
) {
  updateCompany(input: $input) {
    company {
      id
      userSeats
      collaboratorSeats
      virtualSeats
      subscription {
        seats
        collaboratorSeats
        virtualSeats
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCompanyInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSeats",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collaboratorSeats",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "virtualSeats",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seats",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateCompanySubscriptionDataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCompanyPayload",
        "kind": "LinkedField",
        "name": "updateCompany",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateCompanySubscriptionDataMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCompanyPayload",
        "kind": "LinkedField",
        "name": "updateCompany",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateCompanySubscriptionDataMutation",
    "operationKind": "mutation",
    "text": "mutation updateCompanySubscriptionDataMutation(\n  $input: UpdateCompanyInput!\n) {\n  updateCompany(input: $input) {\n    company {\n      id\n      userSeats\n      collaboratorSeats\n      virtualSeats\n      subscription {\n        seats\n        collaboratorSeats\n        virtualSeats\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fa53966c71db7d71d87b93280e1beea6';

module.exports = node;
