import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation bulkUpdateExpenseItemsMutation($input: BulkUpdateExpenseItemsInput!) {
		bulkUpdateExpenseItems(input: $input) {
			expenseItems {
				name
				approved
				expenseYear
				expenseMonth
				expenseDay
				billable
				partOfFixedPrice
				price
				priceBaseCurrency
				cost
				costBaseCurrency
				quantity
				name
				invoiced
				planned
				createdAt
				person {
					id
					fullName
					profilePictureId
					profilePictureDefaultId
				}
				category {
					id
				}
				phase {
					id
				}
				project {
					id
					client {
						id
						name
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	Util.localStorageDeleteFincancialMap();
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables,
			onCompleted: onSuccess,
			onError: onError,
		},
		undefined,
		undefined,
		{
			totalExpenses: input.ids.length,
		}
	);
}

export default {commit};
