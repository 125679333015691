/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAutojoinDomainInput = {|
  name?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createAutojoinDomainMutationVariables = {|
  input: CreateAutojoinDomainInput
|};
export type createAutojoinDomainMutationResponse = {|
  +createAutojoinDomain: ?{|
    +autojoinDomain: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}
  |}
|};
export type createAutojoinDomainMutation = {|
  variables: createAutojoinDomainMutationVariables,
  response: createAutojoinDomainMutationResponse,
|};
*/


/*
mutation createAutojoinDomainMutation(
  $input: CreateAutojoinDomainInput!
) {
  createAutojoinDomain(input: $input) {
    autojoinDomain {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateAutojoinDomainInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAutojoinDomainPayload",
    "kind": "LinkedField",
    "name": "createAutojoinDomain",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AutojoinDomainTypeEdge",
        "kind": "LinkedField",
        "name": "autojoinDomain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AutojoinDomain",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createAutojoinDomainMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createAutojoinDomainMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createAutojoinDomainMutation",
    "operationKind": "mutation",
    "text": "mutation createAutojoinDomainMutation(\n  $input: CreateAutojoinDomainInput!\n) {\n  createAutojoinDomain(input: $input) {\n    autojoinDomain {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12e41e6e665c5327eb792c0762bd1d34';

module.exports = node;
