import { commitMutation, graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
import { omit } from 'lodash';
const mutation = graphql `
	mutation DeleteBillToMutation($input: DeleteBillToInput!) {
		deleteBillTo(input: $input) {
			deletedBillToId
		}
	}
`;
function getConfigs(input) {
    return [
        {
            type: 'RANGE_DELETE',
            parentID: input.billFromId,
            connectionKeys: [
                {
                    key: 'BillFrom_billTos',
                },
            ],
            pathToConnection: ['billFroms', 'billTos'],
            deletedIDFieldName: 'deletedBillToId',
        },
    ];
}
function getOptimisticResponse(input) {
    return {
        deleteBillTo: {
            deletedBillToId: input.billToId,
        },
    };
}
function commit(environment, input, onSuccess, onError) {
    trackEvent('Bill To', 'Deleted', input);
    const cleanedInput = omit(input, ['billFromId']);
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, cleanedInput) };
    return commitMutation(environment, {
        mutation,
        variables,
        configs: getConfigs(input),
        optimisticResponse: getOptimisticResponse(input),
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
