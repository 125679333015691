import * as Sentry from '@sentry/browser';
import {TRACKING_EVENT_TYPE} from './TRACKING_EVENT_TYPE';
import {sendTrackingMessage} from '../TrackingMessage';
import * as tracking from '../../tracking';

const constructPlaceholder = (
	staffingPlaceholder,
	placeholderAllocationDeletes,
	placeholderAllocationsByPlaceholder,
	placeholderSkillsByPlaceholder,
	totalMinutesMap
) => {
	const {id, name, projectId, roleId} = staffingPlaceholder;
	const placeholderAllocations = placeholderAllocationsByPlaceholder[id].concat(placeholderAllocationDeletes);
	const placeholderAllocationEntries = placeholderAllocations.map(allocation => {
		return {
			id: allocation.id,
			startDate: allocation.startDate,
			endDate: allocation.endDate,
			allocatedMinutes: totalMinutesMap.get(allocation.id) || 0,
		};
	});
	const placeholderSkills = placeholderSkillsByPlaceholder[id];
	const placeholderSkillIds = placeholderSkills ? placeholderSkills.map(placeholderSkill => placeholderSkill.skillId) : [];
	return {
		id: id,
		name: name,
		projectId: projectId,
		roleId: roleId,
		skillIds: placeholderSkillIds,
		placeholderAllocations: placeholderAllocationEntries,
	};
};

const constructSuggestedPersons = staffingSuggestedPersons => {
	return staffingSuggestedPersons.map(suggestedPerson => {
		const {personId} = suggestedPerson;
		const {availability, buckets, suitability} = suggestedPerson.ranks;
		return {
			id: personId,
			availabilityRank: availability,
			suitabilityRank: suitability,
			bucketRank: buckets,
		};
	});
};

const constructAssignedAllocationsList = projectAllocationCreatesAndUpdates => {
	return projectAllocationCreatesAndUpdates
		.filter(allocation => !!allocation.trackingInfo)
		.map(allocation => {
			const {id, personId, startDate, endDate, trackingInfo} = allocation;
			const {
				sortingType,
				filteredAvailabilityRank,
				filteredBucketRank,
				filteredSuitabilityRank,
				filteredPersonIds,
				filters,
				staffingOperation,
			} = trackingInfo;

			return {
				placeholderAllocationId: id,
				personId: personId,
				startDate: startDate,
				endDate: endDate,
				sortType: sortingType,
				filteredAvailabilityRank: filteredAvailabilityRank,
				filteredSuitabilityRank: filteredSuitabilityRank,
				filteredBucketRank: filteredBucketRank,
				filteredPersonIds: filteredPersonIds,
				filters: filters,
				staffingOperation,
			};
		});
};

const constructPlaceholderStaffedBody = state => {
	const {data, staffingPlaceholder, placeholderAllocationDeletes, projectAllocationCreatesAndUpdates, totalMinutesMap} =
		state;
	const {
		company,
		suggestedPersons,
		placeholderAllocationsByPlaceholder,
		placeholderSkillsByPlaceholder,
		projects,
		projectGroups,
	} = data;
	const staffingSuggestedPersons = suggestedPersons;

	const companyId = company.id;

	const placeholder = constructPlaceholder(
		staffingPlaceholder,
		placeholderAllocationDeletes,
		placeholderAllocationsByPlaceholder,
		placeholderSkillsByPlaceholder,
		totalMinutesMap
	);

	const suggestedPersonsEntry = constructSuggestedPersons(staffingSuggestedPersons);

	const assignedAllocations = constructAssignedAllocationsList(projectAllocationCreatesAndUpdates);

	const project = staffingPlaceholder.projectId
		? projects.find(project => project.id === staffingPlaceholder.projectId)
		: projectGroups.find(projectGroup => projectGroup.id === staffingPlaceholder.projectGroupId);

	return {
		eventType: TRACKING_EVENT_TYPE.PLACEHOLDER_STAFFED,
		companyId: companyId,
		placeholder: placeholder,
		suggestedPersons: suggestedPersonsEntry,
		assignedAllocations: assignedAllocations,
		projectStage: project.status,
	};
};

const trackPlaceholderStaffedAmplitude = body => {
	const {assignedAllocations, projectStage} = body;
	// Amplitude does not support charting for lists of objects.
	// Instead, assignedAllocations is decomposed into multiple arrays. Order of staffing will be preserved.
	// Pattern from: https://community.amplitude.com/building-and-sharing-your-analysis-58/is-array-of-objects-possible-in-userproperties-does-amplitude-charts-allow-insights-on-the-key-inside-array-objects-529
	const personIds = [];
	const sortTypes = [];
	const availabilityRanks = [];
	const suitabilityRanks = [];
	const bucketRanks = [];
	const staffingOperations = [];

	assignedAllocations.forEach(assignedAllocation => {
		personIds.push(assignedAllocation.personId);
		sortTypes.push(assignedAllocation.sortType);
		availabilityRanks.push(assignedAllocation.filteredAvailabilityRank);
		suitabilityRanks.push(assignedAllocation.filteredSuitabilityRank);
		bucketRanks.push(assignedAllocation.filteredBucketRank);

		const {staffingOperation} = assignedAllocation;
		if (staffingOperation) {
			staffingOperations.push(staffingOperation);
		}
	});

	tracking.trackEvent('Placeholder Staffed', {
		personIds: personIds,
		sortTypes: sortTypes,
		availabilityRanks: availabilityRanks,
		suitabilityRanks: suitabilityRanks,
		bucketRanks: bucketRanks,
		staffingOperations: staffingOperations,
		projectStage: projectStage,
	});
};

export const trackPlaceholderStaffed = state => {
	try {
		const body = constructPlaceholderStaffedBody(state);
		trackPlaceholderStaffedAmplitude(body);
		sendTrackingMessage(body);
	} catch (err) {
		Sentry.captureException(err);
		console.log('Tracking failed, reported to Sentry', err);
	}
};
