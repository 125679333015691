/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteSisenseUserInput = {|
  personId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type DeleteSisenseUserMutationVariables = {|
  input: DeleteSisenseUserInput
|};
export type DeleteSisenseUserMutationResponse = {|
  +deleteSisenseUser: ?{|
    +person: ?{|
      +id: string,
      +sisenseUserId: ?string,
    |},
    +viewer: ?{|
      +id: string,
      +sisenseUserId: ?string,
    |},
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type DeleteSisenseUserMutation = {|
  variables: DeleteSisenseUserMutationVariables,
  response: DeleteSisenseUserMutationResponse,
|};
*/


/*
mutation DeleteSisenseUserMutation(
  $input: DeleteSisenseUserInput!
) {
  deleteSisenseUser(input: $input) {
    person {
      id
      sisenseUserId
    }
    viewer {
      id
      sisenseUserId
    }
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteSisenseUserInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sisenseUserId",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteSisenseUserPayload",
    "kind": "LinkedField",
    "name": "deleteSisenseUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteSisenseUserMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteSisenseUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "DeleteSisenseUserMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteSisenseUserMutation(\n  $input: DeleteSisenseUserInput!\n) {\n  deleteSisenseUser(input: $input) {\n    person {\n      id\n      sisenseUserId\n    }\n    viewer {\n      id\n      sisenseUserId\n    }\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e9ea6aacaad67f4a0e8f395efa011db';

module.exports = node;
