import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createIdleTimeMutation($input: CreateIdleTimeInput!) {
		createIdleTime(input: $input) {
			idleTime {
				node {
					...settingsIdleTimes_idleTime
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_idleTimes',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'idleTime',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Non project Time Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				name: input.name,
				isInternalTime: input.isInternalTime,
			},
		},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
