/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreatePhaseInput = {|
  projectId?: ?string,
  name?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  deadlineYear?: ?number,
  deadlineMonth?: ?number,
  deadlineDay?: ?number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createPhaseMutationVariables = {|
  input: CreatePhaseInput
|};
export type createPhaseMutationResponse = {|
  +createPhase: ?{|
    +phase: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +startYear: ?number,
        +startMonth: ?number,
        +startDay: ?number,
        +deadlineDay: ?number,
        +deadlineMonth: ?number,
        +deadlineYear: ?number,
        +phasePersons: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +availableMinutes: ?number,
              +scheduledMinutes: ?number,
              +person: ?{|
                +id: string
              |},
            |}
          |}>
        |},
        +baselineTargetPrice: ?number,
        +baselineTargetMinutes: ?number,
        +phaseBaselineRoles: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +role: ?{|
                +id: string
              |},
              +baselineMinutes: ?number,
              +baselinePrice: ?number,
            |}
          |}>
        |},
      |}
    |},
    +project: ?{|
      +progress: ?number,
      +progressDetails: ?{|
        +progress: ?number
      |},
    |},
  |}
|};
export type createPhaseMutation = {|
  variables: createPhaseMutationVariables,
  response: createPhaseMutationResponse,
|};
*/


/*
mutation createPhaseMutation(
  $input: CreatePhaseInput!
) {
  createPhase(input: $input) {
    phase {
      node {
        id
        name
        startYear
        startMonth
        startDay
        deadlineDay
        deadlineMonth
        deadlineYear
        phasePersons(first: 10000) {
          edges {
            node {
              id
              availableMinutes
              scheduledMinutes
              person {
                id
              }
            }
          }
        }
        baselineTargetPrice
        baselineTargetMinutes
        phaseBaselineRoles {
          edges {
            node {
              role {
                id
              }
              baselineMinutes
              baselinePrice
              id
            }
          }
        }
      }
    }
    project {
      progress
      progressDetails {
        progress
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreatePhaseInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v10 = [
  (v2/*: any*/)
],
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000
    }
  ],
  "concreteType": "PhasePersonTypeConnection",
  "kind": "LinkedField",
  "name": "phasePersons",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PhasePersonTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PhasePerson",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "availableMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "scheduledMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": (v10/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "phasePersons(first:10000)"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baselineTargetPrice",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baselineTargetMinutes",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baselineMinutes",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baselinePrice",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPhaseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePhasePayload",
        "kind": "LinkedField",
        "name": "createPhase",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PhaseTypeEdge",
            "kind": "LinkedField",
            "name": "phase",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PhaseType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PhaseBaselineRoleTypeConnection",
                    "kind": "LinkedField",
                    "name": "phaseBaselineRoles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhaseBaselineRoleTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PhaseBaselineRoleType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectProgress",
                "kind": "LinkedField",
                "name": "progressDetails",
                "plural": false,
                "selections": [
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPhaseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePhasePayload",
        "kind": "LinkedField",
        "name": "createPhase",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PhaseTypeEdge",
            "kind": "LinkedField",
            "name": "phase",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PhaseType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PhaseBaselineRoleTypeConnection",
                    "kind": "LinkedField",
                    "name": "phaseBaselineRoles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhaseBaselineRoleTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PhaseBaselineRoleType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectProgress",
                "kind": "LinkedField",
                "name": "progressDetails",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createPhaseMutation",
    "operationKind": "mutation",
    "text": "mutation createPhaseMutation(\n  $input: CreatePhaseInput!\n) {\n  createPhase(input: $input) {\n    phase {\n      node {\n        id\n        name\n        startYear\n        startMonth\n        startDay\n        deadlineDay\n        deadlineMonth\n        deadlineYear\n        phasePersons(first: 10000) {\n          edges {\n            node {\n              id\n              availableMinutes\n              scheduledMinutes\n              person {\n                id\n              }\n            }\n          }\n        }\n        baselineTargetPrice\n        baselineTargetMinutes\n        phaseBaselineRoles {\n          edges {\n            node {\n              role {\n                id\n              }\n              baselineMinutes\n              baselinePrice\n              id\n            }\n          }\n        }\n      }\n    }\n    project {\n      progress\n      progressDetails {\n        progress\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c3610bd34280dfe6c55f3f6a9e7b46b';

module.exports = node;
