/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateXeroInvoiceInput = {|
  invoiceId?: ?string,
  forecastClientId?: ?string,
  xeroClientId?: ?string,
  accountMap?: ?$ReadOnlyArray<?XeroAccountLink>,
  tenantId?: ?string,
  projectInDescription?: ?boolean,
  billToId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type XeroAccountLink = {|
  entryId?: ?string,
  accountId?: ?string,
|};
export type CreateXeroInvoiceMutationVariables = {|
  input: CreateXeroInvoiceInput
|};
export type CreateXeroInvoiceMutationResponse = {|
  +createXeroInvoice: ?{|
    +invoice: ?{|
      +id: string,
      +invoiceReference: ?string,
      +companyInvoiceId: ?number,
      +name: ?string,
      +invoiceType: ?string,
      +status: ?string,
      +externalStatus: ?string,
      +dueDay: ?number,
      +dueMonth: ?number,
      +dueYear: ?number,
      +createdDay: ?number,
      +createdMonth: ?number,
      +createdYear: ?number,
      +notes: ?string,
      +quickbooksId: ?number,
      +xeroId: ?string,
      +xeroTenantId: ?string,
      +economicId: ?number,
      +billTo: ?{|
        +id: string,
        +name: string,
        +billFrom: ?{|
          +id: string,
          +name: string,
          +integration: string,
        |},
      |},
      +entries: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +quantity: ?number,
            +unitPrice: ?number,
            +discount: ?number,
            +tax: ?number,
            +description: ?string,
          |}
        |}>
      |},
    |},
    +success: ?boolean,
    +errorMessage: ?string,
  |}
|};
export type CreateXeroInvoiceMutation = {|
  variables: CreateXeroInvoiceMutationVariables,
  response: CreateXeroInvoiceMutationResponse,
|};
*/


/*
mutation CreateXeroInvoiceMutation(
  $input: CreateXeroInvoiceInput!
) {
  createXeroInvoice(input: $input) {
    invoice {
      id
      invoiceReference
      companyInvoiceId
      name
      invoiceType
      status
      externalStatus
      dueDay
      dueMonth
      dueYear
      createdDay
      createdMonth
      createdYear
      notes
      quickbooksId
      xeroId
      xeroTenantId
      economicId
      billTo {
        id
        name
        billFrom {
          id
          name
          integration
        }
      }
      entries(first: 100000000) {
        edges {
          node {
            id
            name
            quantity
            unitPrice
            discount
            tax
            description
          }
        }
      }
    }
    success
    errorMessage
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateXeroInvoiceInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateXeroInvoicePayload",
    "kind": "LinkedField",
    "name": "createXeroInvoice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceType",
        "kind": "LinkedField",
        "name": "invoice",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceReference",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyInvoiceId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dueDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dueMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dueYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quickbooksId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "xeroId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "xeroTenantId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "economicId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BillTo",
            "kind": "LinkedField",
            "name": "billTo",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BillFrom",
                "kind": "LinkedField",
                "name": "billFrom",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "integration",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 100000000
              }
            ],
            "concreteType": "InvoiceEntryTypeConnection",
            "kind": "LinkedField",
            "name": "entries",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InvoiceEntryTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceEntryType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quantity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unitPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "discount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tax",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "entries(first:100000000)"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errorMessage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateXeroInvoiceMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateXeroInvoiceMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateXeroInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation CreateXeroInvoiceMutation(\n  $input: CreateXeroInvoiceInput!\n) {\n  createXeroInvoice(input: $input) {\n    invoice {\n      id\n      invoiceReference\n      companyInvoiceId\n      name\n      invoiceType\n      status\n      externalStatus\n      dueDay\n      dueMonth\n      dueYear\n      createdDay\n      createdMonth\n      createdYear\n      notes\n      quickbooksId\n      xeroId\n      xeroTenantId\n      economicId\n      billTo {\n        id\n        name\n        billFrom {\n          id\n          name\n          integration\n        }\n      }\n      entries(first: 100000000) {\n        edges {\n          node {\n            id\n            name\n            quantity\n            unitPrice\n            discount\n            tax\n            description\n          }\n        }\n      }\n    }\n    success\n    errorMessage\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '60009f208c19852be62f4ff73ba392f6';

module.exports = node;
