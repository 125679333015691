/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
export type UpdateCompanyInternalDataInput = {|
  companyId: number,
  datacenter?: ?string,
  trialEndYear?: ?number,
  trialEndMonth?: ?number,
  trialEndDay?: ?number,
  tier?: ?TierType,
  accessBlocked?: ?boolean,
  name?: ?string,
  street?: ?string,
  zip?: ?string,
  city?: ?string,
  country?: ?string,
  vatId?: ?string,
  contactName?: ?string,
  contactPhone?: ?string,
  contactEmail?: ?string,
  seats?: ?number,
  virtualSeats?: ?number,
  sisenseViewerSeats?: ?number,
  sisenseDesignerSeats?: ?number,
  syncWithSF?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateCompanyInternalDataMutationVariables = {|
  input: UpdateCompanyInternalDataInput
|};
export type updateCompanyInternalDataMutationResponse = {|
  +UpdateCompanyInternalData: ?{|
    +MissionControlCompanyStats: ?{|
      +id: string,
      +companyId: ?number,
      +companyName: ?string,
      +tier: ?TierType,
      +trialEndDate: ?string,
      +numOfProjects: ?number,
      +numOfCards: ?number,
      +numActivePersons: ?number,
      +numTimeRegs: ?number,
      +accessBlocked: ?boolean,
      +userSeats: ?number,
      +virtualSeats: ?number,
      +numOfClientUsers: ?number,
      +numVirtualUsers: ?number,
      +sisenseViewerSeats: ?number,
      +sisenseDesignerSeats: ?number,
    |}
  |}
|};
export type updateCompanyInternalDataMutation = {|
  variables: updateCompanyInternalDataMutationVariables,
  response: updateCompanyInternalDataMutationResponse,
|};
*/


/*
mutation updateCompanyInternalDataMutation(
  $input: UpdateCompanyInternalDataInput!
) {
  UpdateCompanyInternalData(input: $input) {
    MissionControlCompanyStats {
      id
      companyId
      companyName
      tier
      trialEndDate
      numOfProjects
      numOfCards
      numActivePersons
      numTimeRegs
      accessBlocked
      userSeats
      virtualSeats
      numOfClientUsers
      numVirtualUsers
      sisenseViewerSeats
      sisenseDesignerSeats
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCompanyInternalDataInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCompanyInternalDataPayload",
    "kind": "LinkedField",
    "name": "UpdateCompanyInternalData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MissionControlCompanyStatsType",
        "kind": "LinkedField",
        "name": "MissionControlCompanyStats",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "trialEndDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numOfProjects",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numOfCards",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numActivePersons",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numTimeRegs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accessBlocked",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userSeats",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "virtualSeats",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numOfClientUsers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numVirtualUsers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sisenseViewerSeats",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sisenseDesignerSeats",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateCompanyInternalDataMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateCompanyInternalDataMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateCompanyInternalDataMutation",
    "operationKind": "mutation",
    "text": "mutation updateCompanyInternalDataMutation(\n  $input: UpdateCompanyInternalDataInput!\n) {\n  UpdateCompanyInternalData(input: $input) {\n    MissionControlCompanyStats {\n      id\n      companyId\n      companyName\n      tier\n      trialEndDate\n      numOfProjects\n      numOfCards\n      numActivePersons\n      numTimeRegs\n      accessBlocked\n      userSeats\n      virtualSeats\n      numOfClientUsers\n      numVirtualUsers\n      sisenseViewerSeats\n      sisenseDesignerSeats\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f5fa99b86fb30c2c686911606f116e7c';

module.exports = node;
