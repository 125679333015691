import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../css_variables';

const getArrowPositionLeft = (arrowPosition) => {
	return (arrowPosition.left ? '0' : arrowPosition.right ? '100%' : '50%')
}

const getArrowPositionTop = (arrowPosition) => {
	return arrowPosition.topPixels ?
		arrowPosition.topPixels + 'px' :
		arrowPosition.bottom ? '100%' :
			arrowPosition.left || arrowPosition.right ? '50%' : 0;
}

// Hacky override because the old button does not have the styling required from design, and it is not worth the effort to extend the old button.
export const TertiaryButtonStyleOverride = styled.div`
	height: 28px;
	display: flex;
	flex-direction: row;
	padding: ${props => (props.isTimepage ? '0 24px' : '0')};
	width: 100%;
	button.custom-button-container {
		.input-container {
			.uppercase-text {		
				font-size: 12px;		
				font-weight: 400;
				text-transform: none;
			}
		}
	}
`;

export default styled.div`
	bottom: ${props => props.popupPosition.bottom}px;
	left: ${props => props.popupPosition.left}px;

	background: white;
	position: absolute;
	width: 450px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
	border: solid 1px ${CSS_CONSTANTS.v2_branding_purple};
	border-radius: 4px;
	z-index: 100;

	::before {
		position: absolute;
		content: '';
		visibility:  ${({arrowPosition}) => arrowPosition.hidden ? 'hidden' : 'inherit'};
		border-style: solid;
		border-width: 10px 15px 0 15px;
		border-color: ${CSS_CONSTANTS.v2_branding_purple} transparent transparent transparent;
		left: ${({arrowPosition}) => getArrowPositionLeft(arrowPosition)};
		top: ${({arrowPosition}) => getArrowPositionTop(arrowPosition)};
		transform: ${({arrowPosition}) =>
			arrowPosition.bottom
				? arrowPosition.right
					? 'rotate(-90deg) translateX(20px) translateY(-10px)'
					: arrowPosition.left
					? 'rotate(90deg) translateX(-20px) translateY(20px)'
					: 'translateY(1px)  translateX(-50%)'
				: arrowPosition.left
				? 'rotate(90deg) translateY(21px) translateX(calc(-50% + 8px))'
				: arrowPosition.right
				? 'rotate(-90deg) translateY(-9px) translateX(calc(50% - 8px))'
				: 'rotate(180deg) translateY(11px) translateX(50%)'};
	}

	::after {
		position: absolute;
		content: '';
		border-style: solid;
		border-width: 10px 15px 0 15px;
		border-color: #fff transparent transparent transparent;
		visibility:  ${({arrowPosition}) => arrowPosition.hidden ? 'hidden' : 'inherit'};
		left: ${({arrowPosition}) => getArrowPositionLeft(arrowPosition)};
		top: ${({arrowPosition}) => getArrowPositionTop(arrowPosition)};
		transform: ${({arrowPosition}) =>
			arrowPosition.bottom
				? arrowPosition.right
					? 'rotate(-90deg) translateX(20px) translateY(-11px)'
					: arrowPosition.left
					? 'rotate(90deg) translateX(-20px) translateY(19px)'
					: 'translateX(-50%)'
				: arrowPosition.left
				? 'rotate(90deg) translateY(20px) translateX(calc(-50% + 8px))'
				: arrowPosition.right
				? 'rotate(-90deg) translateY(-10px) translateX(calc(50% - 8px))'
				: 'rotate(180deg) translateY(10px) translateX(50%)'};
	}

	.popup-wrapper {
		padding: 16px;

		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.title {
			font-size: 13px;
			color: ${CSS_CONSTANTS.v2_text_gray};
			font-weight: 500;
		}

		.input-title {
			color: ${CSS_CONSTANTS.v2_text_light_gray};
			font-size: 9px;
			text-transform: uppercase;
			margin-bottom: 8px;
			letter-spacing: 1px;
			font-weight: 500;
		}
		.entry-wrapper {
			margin-bottom: 16px;

			.date-picker-button {
				height: 30px;
			}

            .roles {
            	width: 240px;
            }
		}

		.notes {
			.rich-text-field-container .rich-text-field-input-container {
				height: 60px;
			}
			textarea {
				width: 100%;
				resize: none;
				border: none;
				outline: 0;
				border: 1px solid ${CSS_CONSTANTS.app_border_color};
				border-radius: 4px;
				font-size: 12px;
				color: ${CSS_CONSTANTS.v2_text_gray};
				padding: 10px 16px 0 16px;
				height: 70px;
				&:hover {
					border-color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
				}
				&:focus {
					border-color: ${CSS_CONSTANTS.v2_branding_purple};
				}
			}
			::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				font-size: 12px;
			}
			::-moz-placeholder {
				/* Firefox 19+ */
				font-size: 12px;
			}
			:-ms-input-placeholder {
				/* IE 10+ */
				font-size: 12px;
			}
			:-moz-placeholder {
				/* Firefox 18- */
				font-size: 12px;
			}
		}

		.button-wrapper {
			display: flex;
			justify-content: space-between;
		}

		.buttons {
			display: flex;
			justify-content: flex-end;
			height: 30px;
			flex-grow: 1;

			.custom-button-container .input-container .text {
				padding: 0 8.5px 0 8.5px;
			}
		}
	}
`;
