/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssignMultipleLabelsToCategoryInput = {|
  categoryId?: ?string,
  labelIds?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type assignMultipleLabelsToCategoryMutationVariables = {|
  input: AssignMultipleLabelsToCategoryInput
|};
export type assignMultipleLabelsToCategoryMutationResponse = {|
  +assignMultipleLabelsToCategory: ?{|
    +labels: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +taskCount: ?number,
      +projectCount: ?number,
      +peopleCount: ?number,
      +category: ?{|
        +id: string,
        +name: ?string,
      |},
    |}>
  |}
|};
export type assignMultipleLabelsToCategoryMutation = {|
  variables: assignMultipleLabelsToCategoryMutationVariables,
  response: assignMultipleLabelsToCategoryMutationResponse,
|};
*/


/*
mutation assignMultipleLabelsToCategoryMutation(
  $input: AssignMultipleLabelsToCategoryInput!
) {
  assignMultipleLabelsToCategory(input: $input) {
    labels {
      id
      name
      taskCount
      projectCount
      peopleCount
      category {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AssignMultipleLabelsToCategoryInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AssignMultipleLabelsToCategoryPayload",
    "kind": "LinkedField",
    "name": "assignMultipleLabelsToCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Label",
        "kind": "LinkedField",
        "name": "labels",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "peopleCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LabelCategory",
            "kind": "LinkedField",
            "name": "category",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "assignMultipleLabelsToCategoryMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "assignMultipleLabelsToCategoryMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "assignMultipleLabelsToCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation assignMultipleLabelsToCategoryMutation(\n  $input: AssignMultipleLabelsToCategoryInput!\n) {\n  assignMultipleLabelsToCategory(input: $input) {\n    labels {\n      id\n      name\n      taskCount\n      projectCount\n      peopleCount\n      category {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cb5d245dd19947f3065f83cae488a780';

module.exports = node;
