/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateJiraServerSyncSettingsInput = {|
  isJiraToForecastOneWaySync?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateJiraServerSyncSettingsMutationVariables = {|
  input: UpdateJiraServerSyncSettingsInput
|};
export type updateJiraServerSyncSettingsMutationResponse = {|
  +updateJiraServerSyncSettings: ?{|
    +company: ?{|
      +integrations: ?{|
        +jiraServer: ?{|
          +syncSettings: ?{|
            +isJiraToForecastOneWaySync: ?boolean
          |}
        |}
      |}
    |}
  |}
|};
export type updateJiraServerSyncSettingsMutation = {|
  variables: updateJiraServerSyncSettingsMutationVariables,
  response: updateJiraServerSyncSettingsMutationResponse,
|};
*/


/*
mutation updateJiraServerSyncSettingsMutation(
  $input: UpdateJiraServerSyncSettingsInput!
) {
  updateJiraServerSyncSettings(input: $input) {
    company {
      integrations {
        jiraServer {
          syncSettings {
            isJiraToForecastOneWaySync
          }
          id
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateJiraServerSyncSettingsInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "JiraSyncSettingsType",
  "kind": "LinkedField",
  "name": "syncSettings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isJiraToForecastOneWaySync",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateJiraServerSyncSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateJiraServerSyncSettingsPayload",
        "kind": "LinkedField",
        "name": "updateJiraServerSyncSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyIntegrationsType",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyJiraSettingsType",
                    "kind": "LinkedField",
                    "name": "jiraServer",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateJiraServerSyncSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateJiraServerSyncSettingsPayload",
        "kind": "LinkedField",
        "name": "updateJiraServerSyncSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyIntegrationsType",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyJiraSettingsType",
                    "kind": "LinkedField",
                    "name": "jiraServer",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateJiraServerSyncSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation updateJiraServerSyncSettingsMutation(\n  $input: UpdateJiraServerSyncSettingsInput!\n) {\n  updateJiraServerSyncSettings(input: $input) {\n    company {\n      integrations {\n        jiraServer {\n          syncSettings {\n            isJiraToForecastOneWaySync\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'adbe5d99a1db5e853cf2d7fc7ea0f55d';

module.exports = node;
