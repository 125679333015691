/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateReportShareInput = {|
  reportId: string,
  emails?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createReportShareMutationVariables = {|
  input: CreateReportShareInput
|};
export type createReportShareMutationResponse = {|
  +createReportShare: ?{|
    +shareEdges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +user: ?{|
          +id: string,
          +email: ?string,
        |},
      |}
    |}>,
    +warnings: ?$ReadOnlyArray<?string>,
  |}
|};
export type createReportShareMutation = {|
  variables: createReportShareMutationVariables,
  response: createReportShareMutationResponse,
|};
*/


/*
mutation createReportShareMutation(
  $input: CreateReportShareInput!
) {
  createReportShare(input: $input) {
    shareEdges {
      node {
        id
        user {
          id
          email
        }
      }
    }
    warnings
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateReportShareInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateReportSharePayload",
    "kind": "LinkedField",
    "name": "createReportShare",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReportShareTypeEdge",
        "kind": "LinkedField",
        "name": "shareEdges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportShare",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ReportShareUser",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warnings",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createReportShareMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createReportShareMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createReportShareMutation",
    "operationKind": "mutation",
    "text": "mutation createReportShareMutation(\n  $input: CreateReportShareInput!\n) {\n  createReportShare(input: $input) {\n    shareEdges {\n      node {\n        id\n        user {\n          id\n          email\n        }\n      }\n    }\n    warnings\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c8f858e7fb623ff34e4c1d3d8f06c9f1';

module.exports = node;
