/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSageIntacctTimesheetsInput = {|
  People?: ?$ReadOnlyArray<?SageTimesheetInput>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type SageTimesheetInput = {|
  SageEmployeeId?: ?string,
  StartDate?: ?string,
  Name?: ?string,
  TimeRegs?: ?$ReadOnlyArray<?SageTimesheetEntryInput>,
|};
export type SageTimesheetEntryInput = {|
  TimeRegId?: ?string,
  Date?: ?string,
  Hours?: ?number,
  Billable?: ?boolean,
  Notes?: ?string,
  SageProjectId?: ?string,
  SageTaskId?: ?string,
  SageLocationId?: ?string,
  SageDepartmentId?: ?string,
|};
export type CreateSageIntacctTimesheetsMutationVariables = {|
  input: CreateSageIntacctTimesheetsInput
|};
export type CreateSageIntacctTimesheetsMutationResponse = {|
  +createSageIntacctTimesheets: ?{|
    +viewer: ?{|
      +id: string
    |}
  |}
|};
export type CreateSageIntacctTimesheetsMutation = {|
  variables: CreateSageIntacctTimesheetsMutationVariables,
  response: CreateSageIntacctTimesheetsMutationResponse,
|};
*/


/*
mutation CreateSageIntacctTimesheetsMutation(
  $input: CreateSageIntacctTimesheetsInput!
) {
  createSageIntacctTimesheets(input: $input) {
    viewer {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSageIntacctTimesheetsInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateSageIntacctTimesheetsPayload",
    "kind": "LinkedField",
    "name": "createSageIntacctTimesheets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSageIntacctTimesheetsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSageIntacctTimesheetsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateSageIntacctTimesheetsMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSageIntacctTimesheetsMutation(\n  $input: CreateSageIntacctTimesheetsInput!\n) {\n  createSageIntacctTimesheets(input: $input) {\n    viewer {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb49b2d39ddecd8e3500e65268b6fdae';

module.exports = node;
