/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSubTaskInput = {|
  id?: ?string,
  taskId?: ?string,
  name?: ?string,
  description?: ?string,
  estimate?: ?number,
  done?: ?boolean,
  sortOrder?: ?number,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  endYear?: ?number,
  endMonth?: ?number,
  endDay?: ?number,
  roleId?: ?string,
  personId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateSubTaskMutationVariables = {|
  input: UpdateSubTaskInput
|};
export type updateSubTaskMutationResponse = {|
  +updateSubTask: ?{|
    +task: ?{|
      +timeLeft: ?number,
      +latestUiUpdateAt: ?string,
      +latestUiUpdateBy: ?{|
        +firstName: ?string,
        +lastName: ?string,
      |},
      +subTasks: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +sortOrder: ?number,
          |}
        |}>
      |},
    |},
    +subTask: ?{|
      +id: string,
      +estimate: ?number,
      +done: ?boolean,
      +sortOrder: ?number,
      +name: ?string,
      +description: ?string,
      +startDay: ?number,
      +startMonth: ?number,
      +startYear: ?number,
      +endDay: ?number,
      +endMonth: ?number,
      +endYear: ?number,
      +startFrom: ?string,
      +endFrom: ?string,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
      +person: ?{|
        +id: string,
        +firstName: ?string,
        +lastName: ?string,
        +active: ?boolean,
        +profilePictureId: ?string,
        +profilePictureDefaultId: ?number,
      |},
    |},
  |}
|};
export type updateSubTaskMutation = {|
  variables: updateSubTaskMutationVariables,
  response: updateSubTaskMutationResponse,
|};
*/


/*
mutation updateSubTaskMutation(
  $input: UpdateSubTaskInput!
) {
  updateSubTask(input: $input) {
    task {
      timeLeft
      latestUiUpdateAt
      latestUiUpdateBy {
        firstName
        lastName
        id
      }
      subTasks(first: 1000) {
        edges {
          node {
            id
            sortOrder
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
    subTask {
      id
      estimate
      done
      sortOrder
      name
      description
      startDay
      startMonth
      startYear
      endDay
      endMonth
      endYear
      startFrom
      endFrom
      role {
        id
        name
      }
      person {
        id
        firstName
        lastName
        active
        profilePictureId
        profilePictureDefaultId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSubTaskInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLeft",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestUiUpdateAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortOrder",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SubTaskTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SubTask",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SubTask",
  "kind": "LinkedField",
  "name": "subTask",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "done",
      "storageKey": null
    },
    (v7/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startFrom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endFrom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "active",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePictureId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePictureDefaultId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateSubTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSubTaskPayload",
        "kind": "LinkedField",
        "name": "updateSubTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "latestUiUpdateBy",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "subTasks",
                "args": null,
                "concreteType": "SubTaskTypeConnection",
                "kind": "LinkedField",
                "name": "__Task_subTasks_connection",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateSubTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSubTaskPayload",
        "kind": "LinkedField",
        "name": "updateSubTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "latestUiUpdateBy",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "SubTaskTypeConnection",
                "kind": "LinkedField",
                "name": "subTasks",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": "subTasks(first:1000)"
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Task_subTasks",
                "kind": "LinkedHandle",
                "name": "subTasks"
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "updateSubTask",
            "task",
            "subTasks"
          ]
        }
      ]
    },
    "name": "updateSubTaskMutation",
    "operationKind": "mutation",
    "text": "mutation updateSubTaskMutation(\n  $input: UpdateSubTaskInput!\n) {\n  updateSubTask(input: $input) {\n    task {\n      timeLeft\n      latestUiUpdateAt\n      latestUiUpdateBy {\n        firstName\n        lastName\n        id\n      }\n      subTasks(first: 1000) {\n        edges {\n          node {\n            id\n            sortOrder\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n    subTask {\n      id\n      estimate\n      done\n      sortOrder\n      name\n      description\n      startDay\n      startMonth\n      startYear\n      endDay\n      endMonth\n      endYear\n      startFrom\n      endFrom\n      role {\n        id\n        name\n      }\n      person {\n        id\n        firstName\n        lastName\n        active\n        profilePictureId\n        profilePictureDefaultId\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e897ae9a3e4e97b4f4c73455f2ba2fb';

module.exports = node;
