/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreatePlaceholderInput = {|
  name?: ?string,
  projectId?: ?string,
  projectGroupId?: ?string,
  roleId?: ?string,
  departmentId?: ?string,
  teamIds?: ?$ReadOnlyArray<?string>,
  skillPlaceholders?: ?$ReadOnlyArray<?SkillPlaceholderInputType>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type SkillPlaceholderInputType = {|
  placeholderId?: ?string,
  skillId?: ?string,
  skillLevelId?: ?string,
|};
export type createPlaceholderMutationVariables = {|
  input: CreatePlaceholderInput
|};
export type createPlaceholderMutationResponse = {|
  +createPlaceholder: ?{|
    +placeholder: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +role: ?{|
          +id: string,
          +name: ?string,
        |},
        +project: ?{|
          +id: string
        |},
        +projectGroupId: ?string,
        +skills: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string
            |}
          |}>
        |},
        +skillPlaceholders: ?$ReadOnlyArray<?{|
          +skill: ?{|
            +id: string
          |},
          +level: ?{|
            +id: string
          |},
        |}>,
        +departmentId: ?string,
        +teamPlaceholders: ?$ReadOnlyArray<?{|
          +teamId: ?string
        |}>,
      |}
    |}
  |}
|};
export type createPlaceholderMutation = {|
  variables: createPlaceholderMutationVariables,
  response: createPlaceholderMutationResponse,
|};
*/


/*
mutation createPlaceholderMutation(
  $input: CreatePlaceholderInput!
) {
  createPlaceholder(input: $input) {
    placeholder {
      node {
        id
        name
        role {
          id
          name
        }
        project {
          id
        }
        projectGroupId
        skills {
          edges {
            node {
              id
            }
          }
        }
        skillPlaceholders {
          skill {
            id
          }
          level {
            id
          }
          id
        }
        departmentId
        teamPlaceholders {
          teamId
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreatePlaceholderInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectGroupId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillTypeConnection",
  "kind": "LinkedField",
  "name": "skills",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skill",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillLevel",
  "kind": "LinkedField",
  "name": "level",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "departmentId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPlaceholderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePlaceholderPayload",
        "kind": "LinkedField",
        "name": "createPlaceholder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaceholderTypeEdge",
            "kind": "LinkedField",
            "name": "placeholder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Placeholder",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillPlaceholder",
                    "kind": "LinkedField",
                    "name": "skillPlaceholders",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamPlaceholder",
                    "kind": "LinkedField",
                    "name": "teamPlaceholders",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPlaceholderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePlaceholderPayload",
        "kind": "LinkedField",
        "name": "createPlaceholder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaceholderTypeEdge",
            "kind": "LinkedField",
            "name": "placeholder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Placeholder",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillPlaceholder",
                    "kind": "LinkedField",
                    "name": "skillPlaceholders",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamPlaceholder",
                    "kind": "LinkedField",
                    "name": "teamPlaceholders",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createPlaceholderMutation",
    "operationKind": "mutation",
    "text": "mutation createPlaceholderMutation(\n  $input: CreatePlaceholderInput!\n) {\n  createPlaceholder(input: $input) {\n    placeholder {\n      node {\n        id\n        name\n        role {\n          id\n          name\n        }\n        project {\n          id\n        }\n        projectGroupId\n        skills {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n        skillPlaceholders {\n          skill {\n            id\n          }\n          level {\n            id\n          }\n          id\n        }\n        departmentId\n        teamPlaceholders {\n          teamId\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd24361b8852bdc0e83d3ba6c1f1b1a84';

module.exports = node;
