/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplyAutoScheduleChangesInput = {|
  projectId?: ?string,
  projectStartYear?: ?number,
  projectStartMonth?: ?number,
  projectStartDay?: ?number,
  projectEndYear?: ?number,
  projectEndMonth?: ?number,
  projectEndDay?: ?number,
  tasks?: ?$ReadOnlyArray<?AutoScheduleTaskInput>,
  phases?: ?$ReadOnlyArray<?AutoSchedulePhaseInput>,
  allocations?: ?$ReadOnlyArray<?AutoScheduleAllocationInput>,
  projectPersons?: ?$ReadOnlyArray<?AutoScheduleProjectPersonInputType>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type AutoScheduleTaskInput = {|
  id?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  deadlineYear?: ?number,
  deadlineMonth?: ?number,
  deadlineDay?: ?number,
  assignedPersons?: ?$ReadOnlyArray<?string>,
|};
export type AutoSchedulePhaseInput = {|
  id?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  deadlineYear?: ?number,
  deadlineMonth?: ?number,
  deadlineDay?: ?number,
|};
export type AutoScheduleAllocationInput = {|
  id?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  endYear?: ?number,
  endMonth?: ?number,
  endDay?: ?number,
|};
export type AutoScheduleProjectPersonInputType = {|
  personId?: ?string,
  roleId?: ?string,
|};
export type applyAutoScheduleChangesMutationVariables = {|
  input: ApplyAutoScheduleChangesInput
|};
export type applyAutoScheduleChangesMutationResponse = {|
  +applyAutoScheduleChanges: ?{|
    +project: ?{|
      +projectPersons: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +isContactPerson: ?boolean,
            +person: ?{|
              +id: string,
              +firstName: ?string,
              +lastName: ?string,
              +fullName: ?string,
            |},
            +role: ?{|
              +id: string,
              +name: ?string,
            |},
          |}
        |}>
      |}
    |}
  |}
|};
export type applyAutoScheduleChangesMutation = {|
  variables: applyAutoScheduleChangesMutationVariables,
  response: applyAutoScheduleChangesMutationResponse,
|};
*/


/*
mutation applyAutoScheduleChangesMutation(
  $input: ApplyAutoScheduleChangesInput!
) {
  applyAutoScheduleChanges(input: $input) {
    project {
      projectPersons(first: 10000) {
        edges {
          node {
            id
            isContactPerson
            person {
              id
              firstName
              lastName
              fullName
            }
            role {
              id
              name
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ApplyAutoScheduleChangesInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000
    }
  ],
  "concreteType": "ProjectPersonTypeConnection",
  "kind": "LinkedField",
  "name": "projectPersons",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectPersonTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectPerson",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isContactPerson",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "role",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "projectPersons(first:10000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "applyAutoScheduleChangesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApplyAutoScheduleChangesPayload",
        "kind": "LinkedField",
        "name": "applyAutoScheduleChanges",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "applyAutoScheduleChangesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApplyAutoScheduleChangesPayload",
        "kind": "LinkedField",
        "name": "applyAutoScheduleChanges",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "applyAutoScheduleChangesMutation",
    "operationKind": "mutation",
    "text": "mutation applyAutoScheduleChangesMutation(\n  $input: ApplyAutoScheduleChangesInput!\n) {\n  applyAutoScheduleChanges(input: $input) {\n    project {\n      projectPersons(first: 10000) {\n        edges {\n          node {\n            id\n            isContactPerson\n            person {\n              id\n              firstName\n              lastName\n              fullName\n            }\n            role {\n              id\n              name\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '30dd35f587024b653f52003636058448';

module.exports = node;
