import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation syncJiraIntegrationMutation($input: SyncJiraDataInput!) {
		syncJiraData(input: $input) {
			company {
				unSynchedJiraProjects {
					id
					name
					style
				}
				unSynchedJiraServerProjects {
					id
					name
				}
			}
			project {
				id
				isJiraProject
				jiraCloudProject {
					id
					name
				}
				jiraCloudEpicIds
				jiraServerProject {
					id
					name
				}
				remainingAutoCalculated
				jiraSyncRemaining
				jiraCutoffDate
			}
			projectEdge {
				node {
					id
					companyProjectId
					customProjectId
					status
					name
					isJiraProject
					projectColor
					isInProjectGroup
					billable
					budgetType
					isInProjectGroup
					projectGroupId
					sprintTimeBox
					harvestProject {
						id
					}
					jiraCloudProject {
						id
						name
					}
					jiraCloudEpicIds
					jiraServerProject {
						id
						name
					}
					jiraCutoffDate
					statusColumnsV2(first: 1000) {
						edges {
							node {
								id
								projectGroupStatusColumnId
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [
		{
			type: 'RANGE_ADD',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionInfo: [
				{
					key: 'Viewer_projects',
					rangeBehavior: 'append',
				},
				{
					key: 'Header_Viewer_projects',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'projectEdge',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
