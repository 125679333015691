import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createLabelMutation($input: CreateLabelInput!) {
		createLabel(input: $input) {
			label {
				node {
					id
					name
					taskCount
					projectCount
					peopleCount
					category {
						id
						allowOnTasks
						allowOnProjects
						allowOnPeople
					}
					...labelCategoryRow_labels
				}
			}
		}
	}
`;

function getConfigs(companyId) {
	return [
		{
			type: 'RANGE_ADD',
			parentName: 'company',
			parentID: companyId,
			connectionInfo: [
				{
					key: 'Company_labels',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'label',
		},
	];
}

function getOptimisticResponse(input) {
	const {name, color, categoryId} = input;
	return {
		createLabel: {
			label: {
				node: {
					id: undefined,
					name,
					color,
					occurrenceCount: 0,
					taskCount: 0,
					projectCount: 0,
					peopleCount: 0,
					category: {
						id: categoryId,
						allowOnTasks: undefined,
						allowOnProjects: undefined,
						allowOnPeople: undefined,
					},
				},
			},
		},
	};
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Label Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				name: input.name,
				color: input.color,
				categoryId: input.categoryId,
			},
		},
		configs: getConfigs(input.companyId),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted,
		onError,
	});
}

export default {commit};
