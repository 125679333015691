/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
export type DeleteStatusColumnInput = {|
  id?: ?string,
  projectId?: ?string,
  projectGroupId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteStatusColumnMutationVariables = {|
  input: DeleteStatusColumnInput
|};
export type deleteStatusColumnMutationResponse = {|
  +deleteStatusColumn: ?{|
    +deletedStatusColumnId: ?string,
    +errors: ?$ReadOnlyArray<?string>,
    +project: ?{|
      +id: string,
      +statusColumnsV2: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +order: ?number,
            +category: ?STATUS_CATEGORY,
            +encourageTimeRegistration: ?boolean,
            +autoShowOnTimePage: ?boolean,
            +projectGroupStatusColumnId: ?number,
          |}
        |}>
      |},
    |},
    +projectGroup: ?{|
      +id: string,
      +projects: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +statusColumnsV2: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +name: ?string,
                  +order: ?number,
                  +category: ?STATUS_CATEGORY,
                  +encourageTimeRegistration: ?boolean,
                  +autoShowOnTimePage: ?boolean,
                  +projectGroupStatusColumnId: ?number,
                |}
              |}>
            |}
          |}
        |}>
      |},
    |},
  |}
|};
export type deleteStatusColumnMutation = {|
  variables: deleteStatusColumnMutationVariables,
  response: deleteStatusColumnMutationResponse,
|};
*/


/*
mutation deleteStatusColumnMutation(
  $input: DeleteStatusColumnInput!
) {
  deleteStatusColumn(input: $input) {
    deletedStatusColumnId
    errors
    project {
      id
      statusColumnsV2(first: 1000000) {
        edges {
          node {
            id
            name
            order
            category
            encourageTimeRegistration
            autoShowOnTimePage
            projectGroupStatusColumnId
          }
        }
      }
    }
    projectGroup {
      id
      projects(first: 1000000) {
        edges {
          node {
            statusColumnsV2(first: 1000000) {
              edges {
                node {
                  id
                  name
                  order
                  category
                  encourageTimeRegistration
                  autoShowOnTimePage
                  projectGroupStatusColumnId
                }
              }
            }
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteStatusColumnInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedStatusColumnId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
],
v6 = {
  "alias": null,
  "args": (v5/*: any*/),
  "concreteType": "StatusColumnV2TypeConnection",
  "kind": "LinkedField",
  "name": "statusColumnsV2",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StatusColumnV2TypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StatusColumnV2",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "order",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "category",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "encourageTimeRegistration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "autoShowOnTimePage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "projectGroupStatusColumnId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "statusColumnsV2(first:1000000)"
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteStatusColumnMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteStatusColumnPayload",
        "kind": "LinkedField",
        "name": "deleteStatusColumn",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectGroupType",
            "kind": "LinkedField",
            "name": "projectGroup",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ProjectTypeConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "projects(first:1000000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteStatusColumnMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteStatusColumnPayload",
        "kind": "LinkedField",
        "name": "deleteStatusColumn",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectGroupType",
            "kind": "LinkedField",
            "name": "projectGroup",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ProjectTypeConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "projects(first:1000000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteStatusColumnMutation",
    "operationKind": "mutation",
    "text": "mutation deleteStatusColumnMutation(\n  $input: DeleteStatusColumnInput!\n) {\n  deleteStatusColumn(input: $input) {\n    deletedStatusColumnId\n    errors\n    project {\n      id\n      statusColumnsV2(first: 1000000) {\n        edges {\n          node {\n            id\n            name\n            order\n            category\n            encourageTimeRegistration\n            autoShowOnTimePage\n            projectGroupStatusColumnId\n          }\n        }\n      }\n    }\n    projectGroup {\n      id\n      projects(first: 1000000) {\n        edges {\n          node {\n            statusColumnsV2(first: 1000000) {\n              edges {\n                node {\n                  id\n                  name\n                  order\n                  category\n                  encourageTimeRegistration\n                  autoShowOnTimePage\n                  projectGroupStatusColumnId\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b3a9e613773e52cc746a4f67e7bbc943';

module.exports = node;
