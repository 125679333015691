import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation convertSubTaskToTaskMutation($input: ConvertSubTaskToTaskInput!) {
		convertSubTaskToTask(input: $input) {
			task {
				node {
					id
					...DraggableCard_task
					fullAccessToProject
					userCanDeleteTask
					userCantDeleteTaskReason
					hasInvoicedTime
					hasLockedTime
					hasTimeRegistrations
					approved
					name
					description
					sortOrder
					done
					billable
					blocked
					progress
					bug
					latestUiUpdateAt
					startFrom
					deadlineFrom
					startYear
					startMonth
					startDay
					deadlineDay
					deadlineMonth
					deadlineYear
					estimateForecast
					estimateForecastPrice
					currentPrice
					companyTaskId
					timeLeft
					deadlineFrom
					canStart
					canBeSetToDone
					approved
					highPriority
					hasDependency
					jiraId
					subTaskCount
					favoured
					parentTaskId
					phase {
						id
						name
						startYear
						startMonth
						startDay
						deadlineDay
						deadlineMonth
						deadlineYear
					}
					role {
						id
						name
					}
					sprint {
						id
					}
					statusColumnV2 {
						id
						name
						category
						projectGroupStatusColumnId
					}
					project {
						id
						billable
						estimationUnit
						minutesPerEstimationPoint
						projectEndYear
						projectEndMonth
						projectEndDay
						name
						completion
						remaining
						forecast
						isJiraProject
						harvestProject {
							id
						}
					}
					taskLabels {
						id
					}
					timeRegistrations(first: 10000) @connection(key: "Task_timeRegistrations", filters: []) {
						edges {
							node {
								id
							}
						}
					}
					assignedPersons {
						id
						firstName
						lastName
						email
						profilePictureId
						profilePictureDefaultId
						permissions
					}
				}
			}
			deletedSubTaskId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.parentTaskId,
			connectionKeys: [
				{
					key: 'Task_subTasks',
				},
			],
			pathToConnection: ['task', 'subTasks'],
			deletedIDFieldName: 'deletedSubTaskId',
		},
		{
			type: 'RANGE_ADD',
			parentID: input.projectId,
			edgeName: 'task',
			connectionInfo: [
				{
					key: 'Project_tasks',
					rangeBehavior: 'append',
				},
			],
		},
	];
}

function getOptimisticResponse(input) {
	return {
		convertSubTaskToTask: {
			deletedSubTaskId: input.id,
		},
	};
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Sub Task Converted To Card');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				id: input.id,
				parentTaskId: input.parentTaskId,
			},
		},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted,
		onError,
	});
}

export default {commit};
