import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation detachProjectFromProgramMutation($input: DetachProjectFromProgramInput!) {
		detachProjectFromProgram(input: $input) {
			detachedProjectIds
			program {
				id
				name
				description
				prefix
				color
				startDate
				endDate
				projects {
					edges {
						node {
							companyProjectId
							name
						}
					}
				}
				projectsFinancialsTotals {
					recognitionForecastRevenue # Revenue - Recognition at completion
					allActualTimeAndExpenses # Spend - Actual to date
					allTotalTimeAndExpensesAtCompletion # Spend - Total at completion
					recognitionProfitPercentage # Profit margin - total at completion
					recognitionActualProfitPercentage # Profit margin - total at completion
				}
				projectsFinancialsToDate {
					totalActualRevenueRecognition # Revenue - recognised to date
					recognitionAmount # Revenue - recognised to date
					recognitionActualProfitPercentage # Profit margin - actual to date
					allActualTimeAndExpenses # Spend - actual to date
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.programId,
			connectionKeys: [
				{
					key: 'Program_projects',
				},
			],
			pathToConnection: ['program', 'projects'],
			deletedIDFieldName: 'detachedProjectIds',
		},
	];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('Detached project from program');
	const {companyId, ...cleanedInput} = input;

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs({companyId, ...cleanedInput}),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
