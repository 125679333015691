/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSisenseUserInput = {|
  email?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  type?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type CreateSisenseUserMutationVariables = {|
  input: CreateSisenseUserInput
|};
export type CreateSisenseUserMutationResponse = {|
  +createSisenseUser: ?{|
    +viewer: ?{|
      +id: string,
      +sisenseUserId: ?string,
    |},
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type CreateSisenseUserMutation = {|
  variables: CreateSisenseUserMutationVariables,
  response: CreateSisenseUserMutationResponse,
|};
*/


/*
mutation CreateSisenseUserMutation(
  $input: CreateSisenseUserInput!
) {
  createSisenseUser(input: $input) {
    viewer {
      id
      sisenseUserId
    }
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSisenseUserInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateSisenseUserPayload",
    "kind": "LinkedField",
    "name": "createSisenseUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sisenseUserId",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSisenseUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSisenseUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateSisenseUserMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSisenseUserMutation(\n  $input: CreateSisenseUserInput!\n) {\n  createSisenseUser(input: $input) {\n    viewer {\n      id\n      sisenseUserId\n    }\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b33d571581ad1769add7e0a364aa06ed';

module.exports = node;
