import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation joinCompanyMutation($input: JoinCompanyInput!) {
		joinCompany(input: $input) {
			errors
			person {
				node {
					id
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input};
	return commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
