/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsDepartments_department$ref = any;
export type UpdateDepartmentInput = {|
  id: string,
  name?: ?string,
  persons?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateDepartmentMutationVariables = {|
  input: UpdateDepartmentInput
|};
export type updateDepartmentMutationResponse = {|
  +updateDepartment: ?{|
    +department: ?{|
      +$fragmentRefs: settingsDepartments_department$ref
    |}
  |}
|};
export type updateDepartmentMutation = {|
  variables: updateDepartmentMutationVariables,
  response: updateDepartmentMutationResponse,
|};
*/


/*
mutation updateDepartmentMutation(
  $input: UpdateDepartmentInput!
) {
  updateDepartment(input: $input) {
    department {
      ...settingsDepartments_department
      id
    }
  }
}

fragment settingsDepartments_department on DepartmentType {
  id
  name
  sageIntacctId
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateDepartmentInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateDepartmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDepartmentPayload",
        "kind": "LinkedField",
        "name": "updateDepartment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DepartmentType",
            "kind": "LinkedField",
            "name": "department",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "settingsDepartments_department"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateDepartmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDepartmentPayload",
        "kind": "LinkedField",
        "name": "updateDepartment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DepartmentType",
            "kind": "LinkedField",
            "name": "department",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sageIntacctId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateDepartmentMutation",
    "operationKind": "mutation",
    "text": "mutation updateDepartmentMutation(\n  $input: UpdateDepartmentInput!\n) {\n  updateDepartment(input: $input) {\n    department {\n      ...settingsDepartments_department\n      id\n    }\n  }\n}\n\nfragment settingsDepartments_department on DepartmentType {\n  id\n  name\n  sageIntacctId\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a8b13e685e92a231a63c757cc3e7aca8';

module.exports = node;
