import { commitMutation, graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
const mutation = graphql `
	mutation CreateDeliverableMutation($input: CreateDeliverableInput!) {
		createDeliverable(input: $input) {
			deliverable {
				node {
					id
					name
					description
				}
			}
		}
	}
`;
function getConfigs(input) {
    return [
        {
            type: 'RANGE_ADD',
            parentID: input.projectId,
            connectionInfo: [
                {
                    key: 'Project_deliverables',
                    rangeBehavior: 'append',
                },
            ],
            edgeName: 'deliverable',
        },
    ];
}
function commit(environment, input, onSuccess, onError) {
    trackEvent('Deliverable', 'Created', input);
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return commitMutation(environment, {
        mutation,
        variables,
        configs: getConfigs(input),
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
