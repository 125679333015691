/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
export type UpdateInsightInput = {|
  id: string,
  name?: ?string,
  components?: ?$ReadOnlyArray<?InsightComponentInputType>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type InsightComponentInputType = {|
  id?: ?string,
  componentName?: ?string,
  x?: ?number,
  y?: ?number,
  w?: ?number,
  h?: ?number,
  config?: ?string,
|};
export type updateInsightMutationVariables = {|
  input: UpdateInsightInput
|};
export type updateInsightMutationResponse = {|
  +updateInsight: ?{|
    +insight: ?{|
      +id: string,
      +name: ?string,
      +components: ?$ReadOnlyArray<?{|
        +id: string,
        +componentName: ?string,
        +x: ?number,
        +y: ?number,
        +w: ?number,
        +h: ?number,
        +config: ?string,
      |}>,
      +labels: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
      |}>,
      +statusColumns: ?$ReadOnlyArray<?{|
        +id: string,
        +category: ?STATUS_CATEGORY,
        +name: ?string,
      |}>,
      +clients: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
      |}>,
    |}
  |}
|};
export type updateInsightMutation = {|
  variables: updateInsightMutationVariables,
  response: updateInsightMutationResponse,
|};
*/


/*
mutation updateInsightMutation(
  $input: UpdateInsightInput!
) {
  updateInsight(input: $input) {
    insight {
      id
      name
      components {
        id
        componentName
        x
        y
        w
        h
        config
      }
      labels {
        id
        name
      }
      statusColumns {
        id
        category
        name
      }
      clients {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateInsightInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateInsightPayload",
    "kind": "LinkedField",
    "name": "updateInsight",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Insight",
        "kind": "LinkedField",
        "name": "insight",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "InsightComponent",
            "kind": "LinkedField",
            "name": "components",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "componentName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "x",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "y",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "w",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "h",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "config",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "labels",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StatusColumn",
            "kind": "LinkedField",
            "name": "statusColumns",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Client",
            "kind": "LinkedField",
            "name": "clients",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateInsightMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateInsightMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateInsightMutation",
    "operationKind": "mutation",
    "text": "mutation updateInsightMutation(\n  $input: UpdateInsightInput!\n) {\n  updateInsight(input: $input) {\n    insight {\n      id\n      name\n      components {\n        id\n        componentName\n        x\n        y\n        w\n        h\n        config\n      }\n      labels {\n        id\n        name\n      }\n      statusColumns {\n        id\n        category\n        name\n      }\n      clients {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4dc5acf1c14a471b2c97f29f3d5dd59b';

module.exports = node;
