import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation sendInsightShareEmailMutation($input: SendInsightShareEmailInput!) {
		sendInsightShareEmail(input: $input) {
			viewer {
				id
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Insight Shared Via Email');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
