/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateViewerNotificationSettingsInput = {|
  projectStatusEmailEnabled?: ?boolean,
  projectStatusEmailDay?: ?string,
  projectStatusEmailHour?: ?number,
  projectDeadlineOnlyAssignedProjects?: ?boolean,
  stateChangedOnlyAssignedProjects?: ?boolean,
  invoiceDueDateOnlyOwned?: ?boolean,
  invoiceOverdueOnlyOwned?: ?boolean,
  invoiceDaysOverdueOnlyOwned?: ?boolean,
  invoiceCreatedOrDeletedOnlyOwned?: ?boolean,
  invoiceStatusChangeOnlyOwned?: ?boolean,
  invoiceDateReachedOnlyOwned?: ?boolean,
  invoicePaymentOnlyOwned?: ?boolean,
  invoiceDateChangedOnlyOwned?: ?boolean,
  invoiceDueDateChangedOnlyOwned?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateViewerNotificationSettingsMutationVariables = {|
  input: UpdateViewerNotificationSettingsInput
|};
export type UpdateViewerNotificationSettingsMutationResponse = {|
  +updateViewerNotificationSettings: ?{|
    +personNotificationSettings: ?{|
      +id: string,
      +projectStatusEmailEnabled: ?boolean,
      +projectStatusEmailDay: ?string,
      +projectStatusEmailHour: ?number,
      +projectDeadlineOnlyAssignedProjects: ?boolean,
      +stateChangedOnlyAssignedProjects: ?boolean,
      +invoiceDueDateOnlyOwned: ?boolean,
      +invoiceOverdueOnlyOwned: ?boolean,
      +invoiceDaysOverdueOnlyOwned: ?boolean,
      +invoiceCreatedOrDeletedOnlyOwned: ?boolean,
      +invoiceStatusChangeOnlyOwned: ?boolean,
      +invoiceDateReachedOnlyOwned: ?boolean,
      +invoicePaymentOnlyOwned: ?boolean,
      +invoiceDateChangedOnlyOwned: ?boolean,
      +invoiceDueDateChangedOnlyOwned: ?boolean,
    |}
  |}
|};
export type UpdateViewerNotificationSettingsMutation = {|
  variables: UpdateViewerNotificationSettingsMutationVariables,
  response: UpdateViewerNotificationSettingsMutationResponse,
|};
*/


/*
mutation UpdateViewerNotificationSettingsMutation(
  $input: UpdateViewerNotificationSettingsInput!
) {
  updateViewerNotificationSettings(input: $input) {
    personNotificationSettings {
      id
      projectStatusEmailEnabled
      projectStatusEmailDay
      projectStatusEmailHour
      projectDeadlineOnlyAssignedProjects
      stateChangedOnlyAssignedProjects
      invoiceDueDateOnlyOwned
      invoiceOverdueOnlyOwned
      invoiceDaysOverdueOnlyOwned
      invoiceCreatedOrDeletedOnlyOwned
      invoiceStatusChangeOnlyOwned
      invoiceDateReachedOnlyOwned
      invoicePaymentOnlyOwned
      invoiceDateChangedOnlyOwned
      invoiceDueDateChangedOnlyOwned
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateViewerNotificationSettingsInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateViewerNotificationSettingsPayload",
    "kind": "LinkedField",
    "name": "updateViewerNotificationSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PersonNotificationSettingsType",
        "kind": "LinkedField",
        "name": "personNotificationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectStatusEmailEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectStatusEmailDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectStatusEmailHour",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectDeadlineOnlyAssignedProjects",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stateChangedOnlyAssignedProjects",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceDueDateOnlyOwned",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceOverdueOnlyOwned",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceDaysOverdueOnlyOwned",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceCreatedOrDeletedOnlyOwned",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceStatusChangeOnlyOwned",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceDateReachedOnlyOwned",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoicePaymentOnlyOwned",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceDateChangedOnlyOwned",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoiceDueDateChangedOnlyOwned",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateViewerNotificationSettingsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateViewerNotificationSettingsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateViewerNotificationSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateViewerNotificationSettingsMutation(\n  $input: UpdateViewerNotificationSettingsInput!\n) {\n  updateViewerNotificationSettings(input: $input) {\n    personNotificationSettings {\n      id\n      projectStatusEmailEnabled\n      projectStatusEmailDay\n      projectStatusEmailHour\n      projectDeadlineOnlyAssignedProjects\n      stateChangedOnlyAssignedProjects\n      invoiceDueDateOnlyOwned\n      invoiceOverdueOnlyOwned\n      invoiceDaysOverdueOnlyOwned\n      invoiceCreatedOrDeletedOnlyOwned\n      invoiceStatusChangeOnlyOwned\n      invoiceDateReachedOnlyOwned\n      invoicePaymentOnlyOwned\n      invoiceDateChangedOnlyOwned\n      invoiceDueDateChangedOnlyOwned\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a9f1f5ff0f7f7a1ad1e7e512d3216ab';

module.exports = node;
