/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveMemberFromProgramInput = {|
  programId: string,
  memberIds?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type removeMemberFromProgramMutationVariables = {|
  input: RemoveMemberFromProgramInput
|};
export type removeMemberFromProgramMutationResponse = {|
  +removeMemberFromProgram: ?{|
    +removedMemberIds: ?$ReadOnlyArray<?string>,
    +program: ?{|
      +id: string,
      +name: ?string,
      +description: ?string,
      +prefix: ?string,
      +color: ?string,
      +startDate: ?string,
      +endDate: ?string,
      +projects: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +companyProjectId: ?number,
            +name: ?string,
          |}
        |}>
      |},
    |},
  |}
|};
export type removeMemberFromProgramMutation = {|
  variables: removeMemberFromProgramMutationVariables,
  response: removeMemberFromProgramMutationResponse,
|};
*/


/*
mutation removeMemberFromProgramMutation(
  $input: RemoveMemberFromProgramInput!
) {
  removeMemberFromProgram(input: $input) {
    removedMemberIds
    program {
      id
      name
      description
      prefix
      color
      startDate
      endDate
      projects {
        edges {
          node {
            companyProjectId
            name
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveMemberFromProgramInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "removedMemberIds",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefix",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeMemberFromProgramMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveMemberFromProgramPayload",
        "kind": "LinkedField",
        "name": "removeMemberFromProgram",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProjectTypeConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectServiceProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectServiceProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeMemberFromProgramMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveMemberFromProgramPayload",
        "kind": "LinkedField",
        "name": "removeMemberFromProgram",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProjectTypeConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectServiceProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectServiceProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "removeMemberFromProgramMutation",
    "operationKind": "mutation",
    "text": "mutation removeMemberFromProgramMutation(\n  $input: RemoveMemberFromProgramInput!\n) {\n  removeMemberFromProgram(input: $input) {\n    removedMemberIds\n    program {\n      id\n      name\n      description\n      prefix\n      color\n      startDate\n      endDate\n      projects {\n        edges {\n          node {\n            companyProjectId\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf6b7ec71961a95dd2eb8083ae08993f';

module.exports = node;
