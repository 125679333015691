/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImportSageIntacctTeamMembersInput = {|
  sageIntacctEmployeesIds?: ?$ReadOnlyArray<?string>,
  permissionProfile?: ?string,
  assignDepartment?: ?boolean,
  companyId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type ImportSageIntacctTeamMembersMutationVariables = {|
  input: ImportSageIntacctTeamMembersInput
|};
export type ImportSageIntacctTeamMembersMutationResponse = {|
  +importSageIntacctTeamMembers: ?{|
    +persons: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +createdAt: ?string,
        +createdBy: ?string,
        +harvestUser: ?boolean,
        +economicUser: ?boolean,
        +initials: ?string,
        +gitlabUser: ?{|
          +integrationUserId: ?number
        |},
        +gitlabServerUser: ?{|
          +integrationUserId: ?number
        |},
        +githubUser: ?{|
          +integrationUserId: ?number
        |},
        +jiraServerUser: ?boolean,
        +jiraCloudUser: ?boolean,
        +asanaUser: ?boolean,
        +adoUserId: ?string,
        +unit4User: ?boolean,
        +msTeamsId: ?string,
        +bambooHRId: ?string,
        +sageIntacctId: ?string,
        +slackId: ?string,
        +firstName: ?string,
        +lastName: ?string,
        +fullName: ?string,
        +email: ?string,
        +startDate: ?string,
        +endDate: ?string,
        +profilePictureId: ?string,
        +profilePictureDefaultId: ?number,
        +role: ?{|
          +id: string,
          +name: ?string,
        |},
        +skillPersons: ?$ReadOnlyArray<?{|
          +skill: ?{|
            +id: string
          |},
          +level: ?{|
            +id: string
          |},
        |}>,
        +profiles: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
        +permissions: ?$ReadOnlyArray<?string>,
        +active: ?boolean,
        +invited: ?boolean,
        +isViewer: ?boolean,
        +costPeriods: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +startDay: ?number,
              +startMonth: ?number,
              +startYear: ?number,
              +cost: ?number,
            |}
          |}>
        |},
        +department: ?{|
          +id: string,
          +name: ?string,
        |},
        +hasLoggedIn: ?boolean,
      |}
    |}>
  |}
|};
export type ImportSageIntacctTeamMembersMutation = {|
  variables: ImportSageIntacctTeamMembersMutationVariables,
  response: ImportSageIntacctTeamMembersMutationResponse,
|};
*/


/*
mutation ImportSageIntacctTeamMembersMutation(
  $input: ImportSageIntacctTeamMembersInput!
) {
  importSageIntacctTeamMembers(input: $input) {
    persons {
      node {
        id
        createdAt
        createdBy
        harvestUser
        economicUser
        initials
        gitlabUser {
          integrationUserId
        }
        gitlabServerUser {
          integrationUserId
        }
        githubUser {
          integrationUserId
        }
        jiraServerUser
        jiraCloudUser
        asanaUser
        adoUserId
        unit4User
        msTeamsId
        bambooHRId
        sageIntacctId
        slackId
        firstName
        lastName
        fullName
        email
        startDate
        endDate
        profilePictureId
        profilePictureDefaultId
        role {
          id
          name
        }
        skillPersons {
          skill {
            id
          }
          level {
            id
          }
          id
        }
        profiles(first: 10000) {
          edges {
            node {
              id
              name
            }
          }
        }
        permissions
        active
        invited
        isViewer
        costPeriods(first: 10000) {
          edges {
            node {
              id
              startDay
              startMonth
              startYear
              cost
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        department {
          id
          name
        }
        hasLoggedIn
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImportSageIntacctTeamMembersInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdBy",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "harvestUser",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "economicUser",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initials",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integrationUserId",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "gitlabUser",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "gitlabServerUser",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "githubUser",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraServerUser",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraCloudUser",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "asanaUser",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adoUserId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit4User",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "msTeamsId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bambooHRId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sageIntacctId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slackId",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v29 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v29/*: any*/),
  "storageKey": null
},
v31 = [
  (v2/*: any*/)
],
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skill",
  "plural": false,
  "selections": (v31/*: any*/),
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillLevel",
  "kind": "LinkedField",
  "name": "level",
  "plural": false,
  "selections": (v31/*: any*/),
  "storageKey": null
},
v34 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v35 = {
  "alias": null,
  "args": (v34/*: any*/),
  "concreteType": "ProfileTypeConnection",
  "kind": "LinkedField",
  "name": "profiles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Profile",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v29/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "profiles(first:10000)"
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invited",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isViewer",
  "storageKey": null
},
v40 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CostPeriodTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CostPeriod",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v41 = {
  "alias": null,
  "args": null,
  "concreteType": "DepartmentType",
  "kind": "LinkedField",
  "name": "department",
  "plural": false,
  "selections": (v29/*: any*/),
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasLoggedIn",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportSageIntacctTeamMembersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ImportSageIntacctTeamMembersPayload",
        "kind": "LinkedField",
        "name": "importSageIntacctTeamMembers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonTypeEdge",
            "kind": "LinkedField",
            "name": "persons",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v30/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillPerson",
                    "kind": "LinkedField",
                    "name": "skillPersons",
                    "plural": true,
                    "selections": [
                      (v32/*: any*/),
                      (v33/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v35/*: any*/),
                  (v36/*: any*/),
                  (v37/*: any*/),
                  (v38/*: any*/),
                  (v39/*: any*/),
                  {
                    "alias": "costPeriods",
                    "args": null,
                    "concreteType": "CostPeriodTypeConnection",
                    "kind": "LinkedField",
                    "name": "__Person_costPeriods_connection",
                    "plural": false,
                    "selections": (v40/*: any*/),
                    "storageKey": null
                  },
                  (v41/*: any*/),
                  (v42/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportSageIntacctTeamMembersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ImportSageIntacctTeamMembersPayload",
        "kind": "LinkedField",
        "name": "importSageIntacctTeamMembers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonTypeEdge",
            "kind": "LinkedField",
            "name": "persons",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v30/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillPerson",
                    "kind": "LinkedField",
                    "name": "skillPersons",
                    "plural": true,
                    "selections": [
                      (v32/*: any*/),
                      (v33/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v35/*: any*/),
                  (v36/*: any*/),
                  (v37/*: any*/),
                  (v38/*: any*/),
                  (v39/*: any*/),
                  {
                    "alias": null,
                    "args": (v34/*: any*/),
                    "concreteType": "CostPeriodTypeConnection",
                    "kind": "LinkedField",
                    "name": "costPeriods",
                    "plural": false,
                    "selections": (v40/*: any*/),
                    "storageKey": "costPeriods(first:10000)"
                  },
                  {
                    "alias": null,
                    "args": (v34/*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "Person_costPeriods",
                    "kind": "LinkedHandle",
                    "name": "costPeriods"
                  },
                  (v41/*: any*/),
                  (v42/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": null
        }
      ]
    },
    "name": "ImportSageIntacctTeamMembersMutation",
    "operationKind": "mutation",
    "text": "mutation ImportSageIntacctTeamMembersMutation(\n  $input: ImportSageIntacctTeamMembersInput!\n) {\n  importSageIntacctTeamMembers(input: $input) {\n    persons {\n      node {\n        id\n        createdAt\n        createdBy\n        harvestUser\n        economicUser\n        initials\n        gitlabUser {\n          integrationUserId\n        }\n        gitlabServerUser {\n          integrationUserId\n        }\n        githubUser {\n          integrationUserId\n        }\n        jiraServerUser\n        jiraCloudUser\n        asanaUser\n        adoUserId\n        unit4User\n        msTeamsId\n        bambooHRId\n        sageIntacctId\n        slackId\n        firstName\n        lastName\n        fullName\n        email\n        startDate\n        endDate\n        profilePictureId\n        profilePictureDefaultId\n        role {\n          id\n          name\n        }\n        skillPersons {\n          skill {\n            id\n          }\n          level {\n            id\n          }\n          id\n        }\n        profiles(first: 10000) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        permissions\n        active\n        invited\n        isViewer\n        costPeriods(first: 10000) {\n          edges {\n            node {\n              id\n              startDay\n              startMonth\n              startYear\n              cost\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        department {\n          id\n          name\n        }\n        hasLoggedIn\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e5f71931351168de42f372a2bb2438a3';

module.exports = node;
