/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateGoogleDriveFileAttachmentInput = {|
  taskId: string,
  personId: string,
  googleDriveId?: ?string,
  name?: ?string,
  link?: ?string,
  thumb?: ?string,
  folderId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createGoogleDriveFileAttachmentMutationVariables = {|
  input: CreateGoogleDriveFileAttachmentInput
|};
export type createGoogleDriveFileAttachmentMutationResponse = {|
  +createGoogleDriveFileAttachment: ?{|
    +task: ?{|
      +latestUiUpdateAt: ?string,
      +latestUiUpdateBy: ?{|
        +firstName: ?string,
        +lastName: ?string,
      |},
    |},
    +googleDriveFile: ?{|
      +node: ?{|
        +id: ?string,
        +name: ?string,
        +link: ?string,
        +thumb: ?string,
      |}
    |},
  |}
|};
export type createGoogleDriveFileAttachmentMutation = {|
  variables: createGoogleDriveFileAttachmentMutationVariables,
  response: createGoogleDriveFileAttachmentMutationResponse,
|};
*/


/*
mutation createGoogleDriveFileAttachmentMutation(
  $input: CreateGoogleDriveFileAttachmentInput!
) {
  createGoogleDriveFileAttachment(input: $input) {
    task {
      latestUiUpdateAt
      latestUiUpdateBy {
        firstName
        lastName
        id
      }
      id
    }
    googleDriveFile {
      node {
        id
        name
        link
        thumb
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateGoogleDriveFileAttachmentInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestUiUpdateAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "GoogleDriveFileTypeEdge",
  "kind": "LinkedField",
  "name": "googleDriveFile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GoogleDriveFile",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "link",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumb",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createGoogleDriveFileAttachmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateGoogleDriveFileAttachmentPayload",
        "kind": "LinkedField",
        "name": "createGoogleDriveFileAttachment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "latestUiUpdateBy",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createGoogleDriveFileAttachmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateGoogleDriveFileAttachmentPayload",
        "kind": "LinkedField",
        "name": "createGoogleDriveFileAttachment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "latestUiUpdateBy",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createGoogleDriveFileAttachmentMutation",
    "operationKind": "mutation",
    "text": "mutation createGoogleDriveFileAttachmentMutation(\n  $input: CreateGoogleDriveFileAttachmentInput!\n) {\n  createGoogleDriveFileAttachment(input: $input) {\n    task {\n      latestUiUpdateAt\n      latestUiUpdateBy {\n        firstName\n        lastName\n        id\n      }\n      id\n    }\n    googleDriveFile {\n      node {\n        id\n        name\n        link\n        thumb\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '603c2969e21f6e2b93252ff65e1d8ad3';

module.exports = node;
