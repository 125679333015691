/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteDepartmentInput = {|
  id: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteDepartmentMutationVariables = {|
  input: DeleteDepartmentInput
|};
export type deleteDepartmentMutationResponse = {|
  +deleteDepartment: ?{|
    +deletedDepartmentId: ?string,
    +company: ?{|
      +allPersons: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +department: ?{|
              +id: string
            |},
          |}
        |}>
      |}
    |},
  |}
|};
export type deleteDepartmentMutation = {|
  variables: deleteDepartmentMutationVariables,
  response: deleteDepartmentMutationResponse,
|};
*/


/*
mutation deleteDepartmentMutation(
  $input: DeleteDepartmentInput!
) {
  deleteDepartment(input: $input) {
    deletedDepartmentId
    company {
      allPersons(first: 10000, excludeClientUsers: true) {
        edges {
          node {
            id
            department {
              id
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteDepartmentInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedDepartmentId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "excludeClientUsers",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000
    }
  ],
  "concreteType": "PersonTypeConnection",
  "kind": "LinkedField",
  "name": "allPersons",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "DepartmentType",
              "kind": "LinkedField",
              "name": "department",
              "plural": false,
              "selections": [
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteDepartmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteDepartmentPayload",
        "kind": "LinkedField",
        "name": "deleteDepartment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteDepartmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteDepartmentPayload",
        "kind": "LinkedField",
        "name": "deleteDepartment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteDepartmentMutation",
    "operationKind": "mutation",
    "text": "mutation deleteDepartmentMutation(\n  $input: DeleteDepartmentInput!\n) {\n  deleteDepartment(input: $input) {\n    deletedDepartmentId\n    company {\n      allPersons(first: 10000, excludeClientUsers: true) {\n        edges {\n          node {\n            id\n            department {\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0751c62c7858c41e0b48d6710e9a817d';

module.exports = node;
