import { commitMutation, graphql } from 'react-relay';
import Util from '../../../../forecast-app/shared/util/util';
const mutation = graphql `
	mutation UpdateGoogleCalendarMutation($input: UpdateGoogleCalendarInput!) {
		updateGoogleCalendar(input: $input) {
			googleCalendar {
				calendarViewPrivateEvents
				enabledCalendars
			}
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    const optimisticResponse = {
        updateGoogleCalendar: {
            googleCalendar: {
                id: input.id,
                calendarViewPrivateEvents: input.viewPrivateEvents,
                enabledCalendars: input.enabledCalendarIds,
            },
        },
    };
    return commitMutation(environment, {
        mutation,
        variables,
        optimisticResponse,
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
