/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenerateUniquePrefixInput = {|
  name?: ?string,
  domain?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type generateUniquePrefixMutationVariables = {|
  input: GenerateUniquePrefixInput
|};
export type generateUniquePrefixMutationResponse = {|
  +generateUniquePrefix: ?{|
    +prefix: ?string
  |}
|};
export type generateUniquePrefixMutation = {|
  variables: generateUniquePrefixMutationVariables,
  response: generateUniquePrefixMutationResponse,
|};
*/


/*
mutation generateUniquePrefixMutation(
  $input: GenerateUniquePrefixInput!
) {
  generateUniquePrefix(input: $input) {
    prefix
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "GenerateUniquePrefixInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateUniquePrefixPayload",
    "kind": "LinkedField",
    "name": "generateUniquePrefix",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "prefix",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "generateUniquePrefixMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "generateUniquePrefixMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "generateUniquePrefixMutation",
    "operationKind": "mutation",
    "text": "mutation generateUniquePrefixMutation(\n  $input: GenerateUniquePrefixInput!\n) {\n  generateUniquePrefix(input: $input) {\n    prefix\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a76f71f64bea2de03b50316f27bec514';

module.exports = node;
