import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation createOneloginOauthKeyMutation($input: CreateOneloginOauthKeyInput!) {
		createOneloginOauthKey(input: $input) {
			key
			oneloginClientId
			oneloginUrl
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input};
	return commitMutation(environment, {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
