import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation UpdatePhaseBaselineMutation($input: UpdatePhaseBaselineInput!) {
		updatePhaseBaseline(input: $input) {
			project {
				id
				baselineTargetMinutes
				baselineTargetPrice
				baselineCost
				baselineProfit
				phases(first: 100000) @connection(key: "Project_phases", filters: []) {
					edges {
						...PhasesSection_phases
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

/*function getOptimisticResponse(input) {
	const obj = {id: input.id};
	if (input.name) {
		obj.name = input.name;
	}
	if (input.baselineDeadlineYear) {
		obj.baselineDeadlineYear = input.baselineDeadlineYear;
		obj.baselineDeadlineMonth = input.baselineDeadlineMonth;
		obj.baselineDeadlineDay = input.baselineDeadlineDay;
	}
	if (input.baselineStartYear) {
		obj.baselineStartYear = input.baselineStartYear;
		obj.baselineStartMonth = input.baselineStartMonth;
		obj.baselineStartDay = input.baselineStartDay;
	}
	if (input.baselineTargetPrice) {
		obj.baselineTargetPrice = input.baselineTargetPrice;
	}
	if (input.baselineTargetHours) {
		obj.baselineTargetHours = input.baselineTargetHours;
	}
	return {
		updatePhase: {
			phase: obj,
		},
	};
}*/

function commit(environment, input, onSuccess, onError) {
	const changes = [];
	for (const key of Object.keys(input)) {
		//ADD projectId, projectGroupId also here if used by the mutation
		if (['id', 'projectId'].includes(key) || input[key] === undefined) {
			continue;
		}
		changes.push(key);
	}

	tracking.trackEvent('Phase Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		//optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
