import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation EditJiraEpicsMutation($input: EditJiraEpicsInput!) {
		editJiraEpics(input: $input) {
			project {
				id
				isJiraProject
				jiraCloudEpicIds
				jiraCloudProjectSettings {
					isSyncDone
				}
			}
			errors
		}
	}
`;

function getOptimisticResponse(input) {
	return {
		editJiraEpics: {project: {jiraCloudEpicIds: input.jiraCloudEpicIds, jiraCloudProjectSettings: {isSyncDone: false}}},
	};
}

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
