/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation DeleteOutlookCalendarTokensMutation(
  $input: DeleteOutlookCalendarTokensInput!
) {
  deleteOutlookCalendarTokens(input: $input) {
    outlookCalendar {
      id
      calendarUser {
        name
        email
        picture
      }
      calendarTokenError
      enabledCalendars
      calendarFetchEnabled
      calendars {
        edges {
          node {
            id
            name
            description
            backgroundColor
            foregroundColor
          }
        }
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "DeleteOutlookCalendarTokensInput!"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v3 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "DeleteOutlookCalendarTokensPayload",
            "kind": "LinkedField",
            "name": "deleteOutlookCalendarTokens",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CalendarIntegration",
                    "kind": "LinkedField",
                    "name": "outlookCalendar",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CalendarUser",
                            "kind": "LinkedField",
                            "name": "calendarUser",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "email",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "picture",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "calendarTokenError",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enabledCalendars",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "calendarFetchEnabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CalendarTypeConnection",
                            "kind": "LinkedField",
                            "name": "calendars",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CalendarTypeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Calendar",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v1 /*: any*/),
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "description",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "backgroundColor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "foregroundColor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteOutlookCalendarTokensMutation",
            "selections": (v3 /*: any*/),
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteOutlookCalendarTokensMutation",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "DeleteOutlookCalendarTokensMutation",
            "operationKind": "mutation",
            "text": "mutation DeleteOutlookCalendarTokensMutation(\n  $input: DeleteOutlookCalendarTokensInput!\n) {\n  deleteOutlookCalendarTokens(input: $input) {\n    outlookCalendar {\n      id\n      calendarUser {\n        name\n        email\n        picture\n      }\n      calendarTokenError\n      enabledCalendars\n      calendarFetchEnabled\n      calendars {\n        edges {\n          node {\n            id\n            name\n            description\n            backgroundColor\n            foregroundColor\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '4bfd3cb9e37db6dec1991e83ac0b5819';
export default node;
