/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateProgramStatusInput = {|
  programId: string,
  description?: ?string,
  ragStatus: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type CreateProgramStatusMutationVariables = {|
  input: CreateProgramStatusInput
|};
export type CreateProgramStatusMutationResponse = {|
  +createProgramStatus: ?{|
    +programStatus: ?{|
      +node: ?{|
        +id: string,
        +createdAt: ?string,
        +createdBy: ?{|
          +person: ?{|
            +fullName: ?string
          |}
        |},
        +description: ?string,
        +ragStatus: ?string,
      |}
    |},
    +program: ?{|
      +currentProgramStatus: ?{|
        +id: string,
        +createdAt: ?string,
        +createdBy: ?{|
          +person: ?{|
            +fullName: ?string
          |}
        |},
        +description: ?string,
        +ragStatus: ?string,
      |}
    |},
  |}
|};
export type CreateProgramStatusMutation = {|
  variables: CreateProgramStatusMutationVariables,
  response: CreateProgramStatusMutationResponse,
|};
*/


/*
mutation CreateProgramStatusMutation(
  $input: CreateProgramStatusInput!
) {
  createProgramStatus(input: $input) {
    programStatus {
      node {
        id
        createdAt
        createdBy {
          person {
            fullName
            id
          }
          id
        }
        description
        ragStatus
      }
    }
    program {
      currentProgramStatus {
        id
        createdAt
        createdBy {
          person {
            fullName
            id
          }
          id
        }
        description
        ragStatus
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProgramStatusInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ragStatus",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "UserType",
    "kind": "LinkedField",
    "name": "createdBy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "UserType",
    "kind": "LinkedField",
    "name": "createdBy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateProgramStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateProgramStatusPayload",
        "kind": "LinkedField",
        "name": "createProgramStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectServiceProgramStatusTypeEdge",
            "kind": "LinkedField",
            "name": "programStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProgramStatusType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProgramStatusType",
                "kind": "LinkedField",
                "name": "currentProgramStatus",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateProgramStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateProgramStatusPayload",
        "kind": "LinkedField",
        "name": "createProgramStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectServiceProgramStatusTypeEdge",
            "kind": "LinkedField",
            "name": "programStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProgramStatusType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProgramStatusType",
                "kind": "LinkedField",
                "name": "currentProgramStatus",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateProgramStatusMutation",
    "operationKind": "mutation",
    "text": "mutation CreateProgramStatusMutation(\n  $input: CreateProgramStatusInput!\n) {\n  createProgramStatus(input: $input) {\n    programStatus {\n      node {\n        id\n        createdAt\n        createdBy {\n          person {\n            fullName\n            id\n          }\n          id\n        }\n        description\n        ragStatus\n      }\n    }\n    program {\n      currentProgramStatus {\n        id\n        createdAt\n        createdBy {\n          person {\n            fullName\n            id\n          }\n          id\n        }\n        description\n        ragStatus\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2fe4117fd5f58885b6b5beb2f8ee73a7';

module.exports = node;
