import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation UpdatePhaseBaselineRoleMutation($input: UpdatePhaseBaselineRoleInput!) {
		updatePhaseBaselineRole(input: $input) {
			project {
				id
				baselineTargetMinutes
				baselineTargetPrice
				baselineCost
				baselineProfit
				phases(first: 100000) @connection(key: "Project_phases", filters: []) {
					edges {
						...PhasesSection_phases
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function getOptimisticResponse(input) {
	const obj = {id: input.id};

	if (input.roleId) {
		obj.roleId = input.roleId;
	}

	return {
		updatePhaseBaselineRole: {
			phaseBaselineRole: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];
	for (const key of Object.keys(input)) {
		if (['id', 'projectId'].includes(key) || input[key] === undefined) {
			continue;
		}
		changes.push(key);
	}

	tracking.trackEvent('Baseline Role Updated', {_Changed: changes});

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
