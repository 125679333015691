import { commitMutation, graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
const mutation = graphql `
	mutation DeleteClientBillTosMutation($input: DeleteClientBillTosInput!) {
		deleteClientBillTos(input: $input) {
			deletedClientBillToIds
			company {
				id
				clients(first: 100000) {
					edges {
						node {
							id
							name
							clientBillTos(first: 10000) @connection(key: "Client_clientBillTos", filters: []) {
								edges {
									node {
										id
										billFrom {
											id
										}
										billTo {
											id
										}
										clientId
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    trackEvent('Client Bill Tos', 'Deleted', input);
    const variables = {
        input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input),
    };
    return commitMutation(environment, {
        mutation,
        variables,
        configs: [],
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
