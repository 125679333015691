/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsHolidayCalendars_HolidayCalendar$ref = any;
export type CreateHolidayCalendarInput = {|
  name?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createHolidayCalendarMutationVariables = {|
  input: CreateHolidayCalendarInput
|};
export type createHolidayCalendarMutationResponse = {|
  +createHolidayCalendar: ?{|
    +holidayCalendar: ?{|
      +node: ?{|
        +$fragmentRefs: settingsHolidayCalendars_HolidayCalendar$ref
      |}
    |}
  |}
|};
export type createHolidayCalendarMutation = {|
  variables: createHolidayCalendarMutationVariables,
  response: createHolidayCalendarMutationResponse,
|};
*/


/*
mutation createHolidayCalendarMutation(
  $input: CreateHolidayCalendarInput!
) {
  createHolidayCalendar(input: $input) {
    holidayCalendar {
      node {
        ...settingsHolidayCalendars_HolidayCalendar
        id
      }
    }
  }
}

fragment settingsHolidayCalendars_HolidayCalendar on HolidayCalendar {
  id
  name
  holidayCalendarEntries(first: 100000) {
    edges {
      node {
        id
        name
        year
        month
        day
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateHolidayCalendarInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createHolidayCalendarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateHolidayCalendarPayload",
        "kind": "LinkedField",
        "name": "createHolidayCalendar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HolidayCalendarTypeEdge",
            "kind": "LinkedField",
            "name": "holidayCalendar",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HolidayCalendar",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "settingsHolidayCalendars_HolidayCalendar"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createHolidayCalendarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateHolidayCalendarPayload",
        "kind": "LinkedField",
        "name": "createHolidayCalendar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HolidayCalendarTypeEdge",
            "kind": "LinkedField",
            "name": "holidayCalendar",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HolidayCalendar",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "HolidayCalendarEntryTypeConnection",
                    "kind": "LinkedField",
                    "name": "holidayCalendarEntries",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HolidayCalendarEntryTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HolidayCalendarEntry",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "year",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "month",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "day",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "holidayCalendarEntries(first:100000)"
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "HolidayCalendar_holidayCalendarEntries",
                    "kind": "LinkedHandle",
                    "name": "holidayCalendarEntries"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createHolidayCalendarMutation",
    "operationKind": "mutation",
    "text": "mutation createHolidayCalendarMutation(\n  $input: CreateHolidayCalendarInput!\n) {\n  createHolidayCalendar(input: $input) {\n    holidayCalendar {\n      node {\n        ...settingsHolidayCalendars_HolidayCalendar\n        id\n      }\n    }\n  }\n}\n\nfragment settingsHolidayCalendars_HolidayCalendar on HolidayCalendar {\n  id\n  name\n  holidayCalendarEntries(first: 100000) {\n    edges {\n      node {\n        id\n        name\n        year\n        month\n        day\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd291e05284d932000a511106b6c73b6e';

module.exports = node;
