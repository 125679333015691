import DirectApi from '../directApi';

export const sendTrackingMessage = body => {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if (process.env.CIRCLE_BRANCH !== 'production') {
		// eslint-disable-next-line no-console
		console.log('S3_TRACKING: ', body);
	}
	return DirectApi.Fetch_WithErrorHandling('tracking_message', {
		method: 'POST',
		credentials: 'include',
		headers,
		body: JSON.stringify(body),
	});
};
