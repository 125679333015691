import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation markProgramAsFavoriteMutation($input: MarkProgramAsFavoriteInput!) {
		markProgramAsFavorite(input: $input) {
			program {
				node {
					id
					userRelationTypes
				}
			}
		}
	}
`;

function getConfigs(input) {
	const configs = [];

	configs.push({
		type: 'RANGE_ADD',
		parentID: input.companyId,
		connectionInfo: [
			{
				key: 'favorite_programs',
				rangeBehavior: 'prepend',
			},
		],
		edgeName: 'program',
	});

	return configs;
}

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('Mark program as favorite');

	const variables = {
		input: {
			csrfToken: Util.getCsrfValue(),
			socketClientId: Util.getClientId(),
			programId: input.programId,
		},
	};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
