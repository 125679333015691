import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updatePersonNotificationMutation($input: UpdatePersonNotificationInput!) {
		updatePersonNotification(input: $input) {
			viewer {
				recentNotifications {
					id
					countId
					publisherPersonId
					subscriberPersonId
					publisherAction
					entityType
					entityId
					params
					createdAt
					read
				}
				unseenNotifications
			}
			PersonNotifications {
				id
				read
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const notifications = [];
	if (input.ids && input.ids.length > 0) {
		input.ids.forEach(id => {
			const obj = {id: id};
			if (input.read != null) {
				obj.read = input.read;
			}
			notifications.push({
				personNotification: obj,
			});
		});
	} else if (input.updateAll) {
		// do some cool stuff
	}
	return {
		updatePersonNotification: {
			personNotification: notifications,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		changes.push(key);
	}

	tracking.trackEvent('PersonNotification Updated', {_Changed: changes});

	const cleanedVariables = omit(input, ['updateAll']);
	if (input.updateAll) {
		cleanedVariables.all = input.updateAll;
	}

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
