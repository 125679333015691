import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
import {omit} from 'lodash';

const mutation = graphql`
	mutation UpdateCompanyContactMutation($input: SetCompanyContactInput!) {
		setCompanyContact(input: $input) {
			companyContact {
				node {
					id
					contactType
					person {
						fullName
						email
						phone
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_contactPersons',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'companyContact',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Company Contact Updated');
	const cleanedInput = omit(input, ['companyId']);
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				...cleanedInput,
			},
		},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
