// To learn more about how to effectively add tracking, see the guide to Implementing Tracking with Amplitude: https://docs.google.com/document/d/1lCvaaS6EhM6a99BDDElOUgEueVwniYa6Bk6SnWi-y0g/edit#
import * as Sentry from '@sentry/browser';
import * as amplitude from '@amplitude/analytics-browser';
import { isEqual } from 'lodash';
import uuid from 'uuid';
import Util from '../../forecast-app/shared/util/util';
/**
 * Super property keys must be present in this constant before they are allowed to be set
 */
export const SUPER_PROPERTY = {
    AMPLITUDE_PAGE_INFO: 'amplitudePageInfo',
    AMPLITUDE_TASK_MODAL_INFO: 'amplitudeTaskModalInfo',
    AMPLITUDE_MODAL_INFO: 'amplitudeModalInfo',
};
export const logTrackingError = (err) => {
    try {
        if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
            Sentry.captureException(err);
        }
        else {
            console.error(err);
        }
    }
    catch (e) {
        console.error('Sentry Error');
    }
};
const validateProperties = (properties = {}) => {
    if (!Util.isObjectLiteral(properties)) {
        throw new Error('TrackingError: eventProperties must be a single object');
    }
};
/**
 * Needed to ensure that non-TS callers still adhere to the Object-action pattern.
 */
const validateEventProperties = (eventProperties) => {
    if (!eventProperties.object)
        throw new Error('TrackingError: trackEvent must be called with an object');
    if (!eventProperties.action)
        throw new Error('TrackingError: trackEvent must be called with an action');
};
/**
 * @param superProperty sessionStorage address of item to load
 * @returns parsed JSON of item at given sessionStorage address
 */
const loadSuperProperty = (superProperty) => {
    const properties = sessionStorage.getItem(superProperty);
    if (properties === null)
        return {};
    return JSON.parse(properties);
};
// Loads sessionStorage values for all strings defined in the SUPER_PROPERTY object and combines them into a single object
const loadSuperProperties = () => {
    const propertyList = Object.values(SUPER_PROPERTY).map(superProperty => {
        return loadSuperProperty(superProperty);
    });
    return propertyList.reduce((acc, properties) => {
        delete properties.checksum;
        return Object.assign(acc, properties);
    }, {});
};
/**
 * Base-level tracking function which handles Proper Case regex, loading super properties, and contacting Amplitude. All calls to Amplitude should be routed through this function.
 * @throws if eventName does not match regex, if eventProperties is not an object, or if eventProperties does not contain params "object" and "action".
 * @returns object containing properties that were tracked or Error if function threw during execution. Return value used to facilitate testing.
 */
const track = (eventName, eventProperties) => {
    try {
        // Validates properties existing and containing object and action
        validateProperties(eventProperties);
        // Regex for testing if input string consisting entirely of either numbers words written in Proper Case, or symbols "-", ":", and "/"
        const properCaseRegex = new RegExp('^(?:[-:/A-Z0-9][^\\s]*\\s?)+$');
        if (!properCaseRegex.test(eventName)) {
            throw new Error(`TrackingError: eventName must be Proper Case. Was: ${eventName}`);
        }
        const pathName = window.location.pathname.replace(/\d/g, '');
        const superProperties = loadSuperProperties();
        const properties = Object.assign(Object.assign({ pathName }, eventProperties), superProperties);
        if (!Object.keys(properties).includes('pageName')) {
            const noPageErrorString = `No page name in tracking at URL: ${window.location.href}`;
            if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
                console.error(noPageErrorString);
            }
        }
        if (process.env.CIRCLE_BRANCH !== 'production') {
            // eslint-disable-next-line no-console
            console.log('TRACKING: ' + eventName, properties);
        }
        amplitude.track(eventName, properties);
        if (window['userpilot']) {
            window['userpilot'].track(eventName, properties);
        }
        return { eventName, properties };
    }
    catch (e) {
        logTrackingError(e);
        return e;
    }
};
// Global standard functions
/**
 * @param object non-empty string signifying object being tracked (in accordance with object-action framework).
 * @param action non-empty string signifying action being tracked (in accordance with object-action framework).
 * @param properties optional object containing properties to be passed along with the tracking event.
 * @throws if object or action is not defined
 * @returns an object containing properties that were tracked to facilitate testing, or error if function threw during execution.
 */
export const trackEvent = (object, action, properties = {}) => {
    try {
        validateProperties(properties);
        const eventName = `${object} ${action}`;
        const eventProperties = Object.assign({ object, action }, properties);
        validateEventProperties(eventProperties);
        return track(eventName, eventProperties);
    }
    catch (e) {
        logTrackingError(e);
        return e;
    }
};
/**
 * trackComplexEvent will embed the content from additionalInfo into the main string body of the tracking event, effectively creating a new tracking event instance consisting of the full text.
 * Unique or semi-unique properties should never be sent as part of the main string body, but should instead be embedded in properties.
 *
 * @param object non-empty string signifying object being tracked (in accordance with object-action framework).
 * @param action non-empty string signifying action being tracked (in accordance with object-action framework).
 * @param additionalInfo non-empty object containing properties that will both be embedded in the tracking event name and passed along as properties. Values must all be strings.
 * @param properties optional object containing properties to be passed along with the tracking event.
 * @throws if object, action, or additionalInfo are not defined
 * @returns an object containing properties that were tracked to facilitate testing, or error if function threw during execution.
 */
export const trackComplexEvent = (object, action, additionalInfo, properties = {}) => {
    try {
        if (!additionalInfo || Object.keys(additionalInfo).length === 0) {
            throw new Error('TrackingError: trackComplexEvent must always be called with a non-empty additionalInfo object. If this param is not needed, use trackEvent instead');
        }
        validateProperties(properties);
        const additionalInfoString = Object.values(additionalInfo)
            .reduce((acc, info) => acc + info + ' ', '')
            .trim();
        const eventName = `${object} ${action} ${additionalInfoString}`;
        const eventProperties = Object.assign(Object.assign({ object: object, action: action }, additionalInfo), properties);
        validateEventProperties(eventProperties);
        return track(eventName, eventProperties);
    }
    catch (e) {
        logTrackingError(e);
        return e;
    }
};
export const trackMutation = (mutationString, mutationProperties) => {
    return track(mutationString, mutationProperties);
};
// Custom resuable functions
export const trackCSVExport = (csvObject, properties = {}) => {
    return trackEvent(csvObject, 'CSV Downloaded', properties);
};
export const trackPDFExport = (pdfObject, properties = {}) => {
    return trackEvent(pdfObject, 'PDF Downloaded', properties);
};
export const trackTooltip = (tooltipObject, properties = {}) => {
    return trackEvent(tooltipObject, 'Tooltip Shown', properties);
};
// Super Property Functions
/**
 * Registering a super property will cause the property to be saved in sessionStorage and included in all future tracking events until either the user's session is ended, or the property is manually unregistered with unregisterSuperProperty
 * @param superPropertyValue the key used for sessionStorage address
 * @param properties values stored at sessionStorage address
 * @throws if superPropertyKey is not in SUPER_PROPERTY or if properties is not an object with entries
 * @returns checksum used for validation when unregistering
 */
export const registerSuperProperty = (superPropertyValue, properties) => {
    try {
        if (Object.values(SUPER_PROPERTY).indexOf(superPropertyValue) === -1) {
            throw new Error('TrackingError: Super property must be in SUPER_PROPERTY object');
        }
        if (!properties || Object.keys(properties).length === 0) {
            throw new Error('TrackingError: Properties must be an object containing entries');
        }
        const checksum = uuid.v4();
        const superProperties = Object.assign({ checksum }, properties);
        sessionStorage.setItem(superPropertyValue, JSON.stringify(superProperties));
        return checksum;
    }
    catch (e) {
        logTrackingError(e);
        return e;
    }
};
/**
 * @param superPropertyValue The key to be unregistered
 * @param checksum Checksum created from registerSuperProperty. If passed checksum value does not match current checksum value, item is not removed. This is used to prevent asynchronous invocation from deleting an already overwritten property.
 */
export const unregisterSuperProperty = (superPropertyValue, checksum) => {
    var _a;
    try {
        const currentChecksum = (_a = loadSuperProperty(superPropertyValue)) === null || _a === void 0 ? void 0 : _a.checksum;
        if (!isEqual(currentChecksum, checksum))
            return;
        sessionStorage.removeItem(superPropertyValue);
    }
    catch (e) {
        logTrackingError(e);
        return e;
    }
};
/**
 * @param superPropertyValue The key to update
 * @param checksum Checksum created from registerSuperProperty. If passed checksum value does not match current checksum value, item is not updated. This is used to prevent asynchronous invocation from deleting an already overwritten property.
 * @param superPropertyUpdates values to update
 */
export const updateSuperProperty = (superPropertyValue, checksum, superPropertyUpdates) => {
    try {
        const existingSuperProperties = loadSuperProperty(superPropertyValue) || {};
        const currentChecksum = existingSuperProperties.checksum;
        if (!isEqual(currentChecksum, checksum))
            return;
        const superProperties = Object.assign(Object.assign({}, existingSuperProperties), superPropertyUpdates);
        sessionStorage.setItem(superPropertyValue, JSON.stringify(superProperties));
    }
    catch (e) {
        logTrackingError(e);
        return e;
    }
};
// Class component support
/**
 * ATTENTION: This function should ONLY be used to track old Class-based components since these are not compatible with hooks. For all new functional components, use the useTrackPage hook instead.
 * When using this trackPage function with Class-based components, remember to call this function in the constructur, and to call unregisterPageInfo in ComponentWillUnmount.
 * @param pageName name of the page being tracking
 * @param pageProperties passed properties will be attached to the tracking event, but will not be included in the AMPLITUDE_PAGE_INFO super property
 * @param pageSuperProperties passed properties will be set as part of the AMPLITUDE_PAGE_INFO super property and will be attached to all future events until page is unmounted
 * @returns the checksum from super property registration. Used to preserve expected value for later unregistration
 */
export const trackPage = (pageName, pageProperties = {}, pageSuperProperties = {}) => {
    try {
        const superProperties = Object.assign({ pageName: pageName }, pageSuperProperties);
        const properties = Object.assign(Object.assign({}, pageProperties), pageSuperProperties);
        const superPropertyChecksum = registerSuperProperty(SUPER_PROPERTY.AMPLITUDE_PAGE_INFO, superProperties);
        trackEvent(pageName, 'Page Viewed', properties);
        return superPropertyChecksum;
    }
    catch (e) {
        logTrackingError(e);
        return e;
    }
};
/**
 * @param checksum
 */
export const unregisterPageInfo = (checksum) => {
    unregisterSuperProperty(SUPER_PROPERTY.AMPLITUDE_PAGE_INFO, checksum);
};
/**
 * @param {string} str
 */
export const toProperCase = (str) => {
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
};
