/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DEPENDENCY_TYPE = "CANNOT_BE_COMPLETED" | "CANNOT_START" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
export type UpdateDependencyInput = {|
  id: string,
  thisDependsOnTaskId?: ?string,
  taskIdDependsOnThis?: ?string,
  type?: ?DEPENDENCY_TYPE,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateDependencyMutationVariables = {|
  input: UpdateDependencyInput
|};
export type updateDependencyMutationResponse = {|
  +updateDependency: ?{|
    +errors: ?$ReadOnlyArray<?string>,
    +dependency: ?{|
      +id: string,
      +type: ?DEPENDENCY_TYPE,
      +completed: ?boolean,
      +taskDependsOnThis: ?{|
        +id: string,
        +name: ?string,
        +companyTaskId: ?number,
        +startYear: ?number,
        +startMonth: ?number,
        +startDay: ?number,
        +deadlineDay: ?number,
        +deadlineMonth: ?number,
        +deadlineYear: ?number,
        +statusColumnV2: ?{|
          +id: string,
          +category: ?STATUS_CATEGORY,
        |},
      |},
      +thisDependsOnTask: ?{|
        +id: string,
        +name: ?string,
        +companyTaskId: ?number,
        +startYear: ?number,
        +startMonth: ?number,
        +startDay: ?number,
        +deadlineDay: ?number,
        +deadlineMonth: ?number,
        +deadlineYear: ?number,
        +statusColumnV2: ?{|
          +id: string,
          +category: ?STATUS_CATEGORY,
        |},
      |},
    |},
  |}
|};
export type updateDependencyMutation = {|
  variables: updateDependencyMutationVariables,
  response: updateDependencyMutationResponse,
|};
*/


/*
mutation updateDependencyMutation(
  $input: UpdateDependencyInput!
) {
  updateDependency(input: $input) {
    errors
    dependency {
      id
      type
      completed
      taskDependsOnThis {
        id
        name
        companyTaskId
        startYear
        startMonth
        startDay
        deadlineDay
        deadlineMonth
        deadlineYear
        statusColumnV2 {
          id
          category
        }
      }
      thisDependsOnTask {
        id
        name
        companyTaskId
        startYear
        startMonth
        startDay
        deadlineDay
        deadlineMonth
        deadlineYear
        statusColumnV2 {
          id
          category
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateDependencyInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "companyTaskId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startYear",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startMonth",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startDay",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deadlineDay",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deadlineMonth",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deadlineYear",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "StatusColumnV2",
    "kind": "LinkedField",
    "name": "statusColumnV2",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "category",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDependencyPayload",
    "kind": "LinkedField",
    "name": "updateDependency",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DependencyType",
        "kind": "LinkedField",
        "name": "dependency",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "taskDependsOnThis",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "thisDependsOnTask",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateDependencyMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateDependencyMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateDependencyMutation",
    "operationKind": "mutation",
    "text": "mutation updateDependencyMutation(\n  $input: UpdateDependencyInput!\n) {\n  updateDependency(input: $input) {\n    errors\n    dependency {\n      id\n      type\n      completed\n      taskDependsOnThis {\n        id\n        name\n        companyTaskId\n        startYear\n        startMonth\n        startDay\n        deadlineDay\n        deadlineMonth\n        deadlineYear\n        statusColumnV2 {\n          id\n          category\n        }\n      }\n      thisDependsOnTask {\n        id\n        name\n        companyTaskId\n        startYear\n        startMonth\n        startDay\n        deadlineDay\n        deadlineMonth\n        deadlineYear\n        statusColumnV2 {\n          id\n          category\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '234ba6ad5daafece95b845312cf6f5a0';

module.exports = node;
