import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deletePriorityLevelMutationModernMutation($input: DeletePriorityLevelInput!) {
		deletePriorityLevel(input: $input) {
			company {
				priorityLevels(first: 1000) @connection(key: "Company_priorityLevels", filters: []) {
					edges {
						node {
							id
							name
							weight
							useCount
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	const variables = {...input};
	const cleanedVariables = omit(variables, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
