import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createGithubAttachedObjectMutation($input: CreateGithubAttachedObjectInput!) {
		createGithubAttachedObject(input: $input) {
			githubAttachedObject {
				node {
					id
					number
					title
					repo
					url
					createdBy
					type
					status
					state
					base
					head
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.taskId,
			connectionInfo: [
				{
					key: 'Task_githubAttachedObjects',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'githubAttachedObject',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Github Object Attached', {_Type: input.githubType});
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
