import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteDepartmentMutation($input: DeleteDepartmentInput!) {
		deleteDepartment(input: $input) {
			deletedDepartmentId
			company {
				allPersons(first: 10000, excludeClientUsers: true) {
					edges {
						node {
							id
							department {
								id
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(companyId) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: companyId,
			connectionKeys: [
				{
					key: 'Company_departments',
				},
			],
			pathToConnection: ['company', 'departments'],
			deletedIDFieldName: 'deletedDepartmentId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Department Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), id: input.id}},
		configs: getConfigs(input.companyId),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
