import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteTeamMutation($input: DeleteTeamInput!) {
		deleteTeam(input: $input) {
			deletedTeamId
		}
	}
`;

function getConfigs(companyId) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: companyId,
			connectionKeys: [
				{
					key: 'Company_teams',
				},
			],
			pathToConnection: ['company', 'teams'],
			deletedIDFieldName: 'deletedTeamId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Team Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), id: input.id}},
		configs: getConfigs(input.companyId),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
