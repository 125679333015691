import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation MergePhaseMutation($input: MergePhaseInput!) {
		mergePhase(input: $input) {
			phase {
				id
				unit4Id
				jiraId
			}
			project {
				tasks(first: 10000) @connection(key: "Project_tasks") {
					edges {
						node {
							id
							startDay
							startMonth
							startYear
							startFrom
							deadlineDay
							deadlineMonth
							deadlineYear
							deadlineFrom
							phase {
								id
								name
							}
						}
					}
				}
			}
			deletedPhaseId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_phases',
				},
			],
			pathToConnection: ['project', 'phases'],
			deletedIDFieldName: 'deletedPhaseId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Phase Merged');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
