import styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../css_variables';

export const TextArea = styled.textarea`
	position: relative;
	width: 100%;
	color: #535353;
	line-height: 16px;
	font-size: 13px;
	background-color: $color-white;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	resize: none;
	border-radius: 3px;
	border: solid 1px #e6e6e6;
	padding: 8px;
	outline: none;
	&::placeholder {
		font-size: 13px;
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		opacity: 1;
	}

	&:disabled {
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		background-color: #f3f3f3;
		&:hover {
			border-color: ${CSS_CONSTANTS.v2_border_gray};
		}
	}
	&:hover {
		border-color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
	}
	&:focus {
		border-color: #6e0fea;
		outline: none;
	}
`;

export const ModalWrapper = styled.div`
	width: ${props => (props.isTimepage ? '450px' : '402px')};
	@media screen and (max-width: 1400px) {
		width: ${props => (props.isTimepage ? '420px' : '402px')};
	}
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	.textarea-foldout {
		width: 100%;
		font-family: ${CSS_CONSTANTS.text_font_family};
		font-size: 13px;
		font-weight: 500;
		color: ${CSS_CONSTANTS.v2_text_gray};
		border: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
		border-radius: 4px;
		padding: 7px 16px 0 16px;
		min-height: 30px;
		outline: none;
		&:hover {
			border-color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
		}
		&:focus {
			border-color: ${CSS_CONSTANTS.v2_branding_purple};
		}
		&.locked {
			background-color: ${CSS_CONSTANTS.v2_input_disabled_grey};
			&:focus {
				border-color: ${CSS_CONSTANTS.v2_button_very_light_grey};
			}
		}
	}

	.nested-dropdown-v2 {
		height: 30px;
		.input-arrow-wrapper {
			height: 30px;
			border: 1px solid ${CSS_CONSTANTS.app_border_color};
			border-radius: 4px;
			&.locked {
				cursor: default;
				> input {
					cursor: default;
				}
			}
			&:hover {
				border: 1px solid ${CSS_CONSTANTS.v2_date_picker_button_border_color};
			}
			&.locked {
				background-color: ${CSS_CONSTANTS.v2_input_disabled_grey};
				color: ${CSS_CONSTANTS.v2_text_light_gray};
				&:hover {
					border: 1px solid ${CSS_CONSTANTS.app_border_color};
				}
			}
			.nested-dropdown-input {
				height: 28px;
				padding: 7px 0 7px 16px;
				font-family: ${CSS_CONSTANTS.v2_site_header_font_family};
				font-size: 13px;
				font-weight: 500;
				color: ${CSS_CONSTANTS.v2_text_gray};
				background-color: white;
				overflow: hidden;
				text-overflow: ellipsis;
				&.expanded {
					padding-bottom: 7px;
				}
				&.locked {
					background-color: ${CSS_CONSTANTS.v2_input_disabled_grey};
				}
			}
			.arrow {
				margin-right: 17px;
			}
		}
		.nested-dropdown-options-wrapper {
			border-radius: 4px;
		}
	}
`;

export const DropdownWrapper = styled.div`
	display: flex;
	margin: ${props => (props.isTimepage ? '0 24px 16px 24px' : '0 0 16px 0')};
`;

export const ElemWrapper = styled.div`
	margin: ${props => (props.isTimepage ? '0 24px 16px 24px' : '0 0 16px 0')};
	&.message-wrapper {
		margin: ${props => (props.isTimepage ? '0 24px 8px 24px' : '0 0 15px 0')};
		border: solid 1px #e6e6e6;
		height:120px;
		padding: 11px;
		border-radius: 5px;
	}
	&.notes-wrapper {
		margin: ${props => (props.isTimepage ? '0 24px 8px 24px' : '0 0 10px 0')};
	}
  	&.time-wrapper {
      	margin: ${props => (props.isTimepage ? '0 0 16px 24px' : '0 24px 16px 0')};
    }
	.date-picker-button-container-v3 {
		.date-picker-button {
			&.date-picker-button-standard {
				height: 30px;
			}
		}
	}
	.icon-title-wrapper {
		display: flex;
		flex-direction: row;
		min-height: 16px;
		font-weight: 500;

		.information-icon {
			height: 11px;
			width: 11px;
			margin-left: 4px;
			margin-bottom: 4px;
			cursor: help;
		}
	}
	.input-title {
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		font-size: 9px;
		text-transform: uppercase;
		margin-bottom: 5px;
		letter-spacing: 1px;
	}
`;

export const TimeEntryWrapper = styled.div`
  	display: flex;
	gap: 24px;
	align-items: flex-end;
`;

export const DropdownLabelWrapper = styled.div`
	flex-direction: column;
	flex-grow: 1;
	max-width: 100%;
`;

export const DropdownLabel = styled.div`
	font-family: ${CSS_CONSTANTS.v2_site_header_font_family};
	font-size: 9px;
	font-weight: 500;
	line-height: 1.22;
	letter-spacing: 1px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	text-transform: uppercase;
	margin-bottom: 5px;
`;

export const TabSelectorWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin: ${props => (props.isTimepage ? '0 24px 16px 24px' : '0 0 16px 0')};
	height: 30px;
	.custom-button-container {
	  	height: 30px;
	  	min-width: 130px;
	  	flex: 1;
	}
`;

// Hacky override because the old button does not have the styling required from design, and it is not worth the effort to extend the old button.
export const TertiaryButtonStyleOverride = styled.div`
	height: 28px;
	display: flex;
	flex-direction: row;
	padding: ${props => (props.isTimepage ? '0 24px' : '0')};
	width: 100%;
	button.custom-button-container {
		.input-container {
			.uppercase-text {		
				font-size: 12px;		
				font-weight: 400;
				text-transform: none;
			}
		}
	}
`;

export const ButtonWrapper = styled.div`
	height: 28px;
	display: flex;
	flex-direction: row;
	padding: ${props => (props.isTimepage ? '0 24px' : '0')};
	width: 100%;
	justify-content: flex-end;
	button {
		height: 28px;
		padding: 0 16px 0 16px;
	}
`;
