import { graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackAndCommitMutation } from '../../tracking/amplitude/TrackMutation';
const mutation = graphql `
	mutation OptOutOfFeatureFlagMutation($input: OptOutOfFeatureFlagInput!) {
		optOutOfFeatureFlag(input: $input) {
			viewer {
				availableFeatureFlags {
					id
					key
				}
			}
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return trackAndCommitMutation(environment, {
        mutation,
        variables,
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
