import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateProjectPersonMutation($input: UpdateProjectPersonInput!) {
		updateProjectPerson(input: $input) {
			projectPerson {
				isContactPerson
				role {
					id
					name
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const obj = {id: input.id};
	if (input.isContactPerson !== undefined) {
		obj.isContactPerson = input.isContactPerson;
	}
	if (input.roleId) {
		obj.role = {id: input.roleId};
	}
	return {
		updateProjectPerson: {
			projectPerson: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Project Person Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
