import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createPlaceholderAllocationMutation($input: CreatePlaceholderAllocationInput!) {
		createPlaceholderAllocation(input: $input) {
			placeholderAllocation {
				id
				startDate
				endDate
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				description
				placeholder {
					id
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	return {
		createPlaceholderAllocation: {
			placeholderAllocation: {
				id: 'new',
				startDate: input.startDate,
				endDate: input.endDate,
				monday: input.monday,
				tuesday: input.tuesday,
				wednesday: input.wednesday,
				thursday: input.thursday,
				friday: input.friday,
				saturday: input.saturday,
				sunday: input.sunday,
				description: input.description,
				placeholder: {
					id: input.placeholderId,
				},
			},
		},
	};
}

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.placeholderId,
			connectionInfo: [
				{
					key: 'Project_placeholderAllocations',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'allocation',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	const trackingOptions = {};

	if (input.trackingOptions) {
		if (trackingOptions.percentageAllocated) {
			trackingOptions.percentageAllocated = input.trackingOptions.percentageAllocated;
		}

		trackingOptions.isFromCreatePlaceholder = !!input.trackingOptions.isFromCreatePlaceholder;
		trackingOptions.isFullDuration = !!input.trackingOptions.isFullDuration;
	}

	tracking.trackEvent('Placeholder Allocation Created', trackingOptions);

	const cleanedInput = omit(input, ['companyId', 'trackingOptions']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(cleanedInput),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit, getOptimisticResponse};
