import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {MODAL_TYPE, showModal} from '../forecast-app/shared/components/modals/generic_modal_conductor';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteTaskMutation($input: DeleteTaskInput!) {
		deleteTask(input: $input) {
			deletedTasksIds
			deletedTimeRegsIds
			project {
				id
				completion
				remaining
				forecast
				progress
				progressDetails {
					progress
				}
				phases(first: 10000) {
					edges {
						node {
							id
							progress
							progressDetails {
								progress
							}
						}
					}
				}
			}
			parentTask {
				rollupEstimate
				rollupTimeEntries
				rollupRemaining
			}
			errors
		}
	}
`;

function getConfigs(input) {
	const config = [];
	config.push({
		type: 'RANGE_DELETE',
		parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
		connectionKeys: [
			{
				key: 'Viewer_tasks',
			},
		],
		pathToConnection: ['viewer', 'tasks'],
		deletedIDFieldName: 'deletedTasksIds',
	});
	config.push({
		type: 'RANGE_DELETE',
		parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
		connectionKeys: [
			{
				key: 'Viewer_favouredTasks',
			},
		],
		pathToConnection: ['viewer', 'tasks'],
		deletedIDFieldName: 'deletedTasksIds',
	});
	config.push({
		type: 'RANGE_DELETE',
		parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
		connectionKeys: [
			{
				key: 'Viewer_timeRegistrations',
			},
		],
		pathToConnection: ['viewer', 'timeRegistrations'],
		deletedIDFieldName: 'deletedTimeRegsIds',
	});
	if (input.projectIds && input.projectIds.length > 0) {
		input.projectIds.forEach(pid => {
			config.push({
				type: 'RANGE_DELETE',
				parentID: pid,
				connectionKeys: [
					{
						key: 'Project_tasks',
					},
					// This is to handle the new Sprint page V4.
					// It uses two different connections for the sprint task and the backlog.
					{
						key: 'Project_tasks',
						filters: {backlog: true},
					},
				],
				pathToConnection: ['project', 'tasks'],
				deletedIDFieldName: 'deletedTasksIds',
			});
		});
	} else {
		config.push({
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_tasks',
				},
				// This is to handle the new Sprint page V4.
				// It uses two different connections for the sprint task and the backlog.
				{
					key: 'Project_tasks',
					filters: {backlog: true},
				},
			],
			pathToConnection: ['project', 'tasks'],
			deletedIDFieldName: 'deletedTasksIds',
		});
		config.push({
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_timeRegistrations',
				},
			],
			pathToConnection: ['project', 'timeRegistrations'],
			deletedIDFieldName: 'deletedTimeRegsIds',
		});
	}
	// Needed for My List and Timesheets
	if (input.viewer && input.viewer.company && input.viewer.company.id) {
		config.push({
			type: 'RANGE_DELETE',
			parentID: input.viewer.company.id,
			connectionKeys: [
				{
					key: 'Company_allTasks',
				},
				{
					key: 'Company_taskTypeQueryTasks',
				},
				{
					key: 'Company_modalTasks',
				},
				{
					key: 'Company_overdueTasks',
				},
			],
			pathToConnection: ['company', 'allTasks'],
			deletedIDFieldName: 'deletedTasksIds',
		});
	}
	if (input.filters) {
		let filterConfig = {
			type: 'RANGE_DELETE',
			parentID: input.viewer.company.id,
			connectionKeys: [],
			pathToConnection: ['company', 'allTasks'],
			deletedIDFieldName: 'deletedTasksIds',
		};
		input.filters.forEach(filter => {
			filterConfig.connectionKeys.push({
				key: 'Company_allTasks',
				filters: {filterColumnId: filter.toString()},
			});
		});
		config.push(filterConfig);
	}
	if (input.parentTaskId) {
		config.push({
			type: 'RANGE_DELETE',
			parentID: input.parentTaskId,
			connectionKeys: [
				{
					key: 'ParentTask_childrenTasks',
				},
			],
			pathToConnection: ['parentTask', 'childrenTasks'],
			deletedIDFieldName: 'deletedTasksIds',
		});
	}
	return config;
}

function getOptimisticResponse(input) {
	return {
		deleteTask: {
			deletedTasksIds: input.ids,
		},
	};
}

const onSuccessWrapper = (result, onSuccess) => {
	if (result.deleteTask && result.deleteTask.errors && result.deleteTask.errors.length) {
		if (result.deleteTask.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD') {
			showModal({
				type: MODAL_TYPE.WARNING_DATE_LOCKED,
				nonTimeRegWarning: true,
			});
		} else if (
			result.deleteTask.errors[0].indexOf('SAGE ERROR') !== -1 ||
			result.deleteTask.errors[0].indexOf('Sage Intacct ERROR') !== -1
		) {
			const warningMessageId = 'common.invalid_action_modal_title';
			if (result.deleteTask.errors[0].indexOf('The record is used in one of the following: Timesheet entry') !== -1) {
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId,
					warningInformationIds: ['sage.cannot_delete_task_with_time_regs'],
				});
			} else if (result.deleteTask.errors[0].indexOf('The record is used in one of the following: Task') !== -1) {
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId,
					warningInformationIds: ['sage.cannot_delete_task_with_dependent_tasks'],
				});
			} else if (result.deleteTask.errors[0].indexOf('Unable to locate Task') !== -1) {
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId,
					warningInformationIds: ['sage.cannot_delete_task_not_found'],
				});
			} else {
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId,
					warningInformation: result.deleteTask.errors.slice(0, 1),
				});
			}
		} else {
			showModal({
				type: MODAL_TYPE.MUTATION_ERROR,
			});
		}
	} else {
		onSuccess(result);
	}
};

function commit(environment, input, onSuccess, onError) {
	Util.localStorageDeleteFincancialMap();
	tracking.trackEvent('Card Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				ids: input.ids,
				projectId: input.projectId,
				parentTaskId: input.parentTaskId,
			},
		},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: result => onSuccessWrapper(result, onSuccess),
		onError: onError,
	});
}

export default {commit};
