import {config} from './config';

class VersionManager {
	constructor() {
		this._serverVersionMajor = null;
		this._serverVersionTimestamp = null;
		this._versionConfig = null;
	}

	getClientMajorVersion() {
		return typeof config.clientVersionMajor === 'number' ? config.clientVersionMajor : 0;
	}

	getClientMinorVersion() {
		return typeof config.clientVersionMinor === 'number' ? config.clientVersionMinor : 0;
	}

	getClientVersionTime() {
		return typeof config.clientVersionTimestamp === 'number' ? config.clientVersionTimestamp : 0;
	}

	getServerMajorVersion() {
		return typeof config._serverVersionMajor === 'number' ? config._serverVersionMajor : 0;
	}

	getServerMinorVersion() {
		return typeof config._serverVersionMinor === 'number' ? config._serverVersionMinor : 0;
	}

	updateWithWebFrontVersionResponse(webFrontVersionResponse) {
		this._serverVersionMajor = webFrontVersionResponse.majorVersion;
		this._serverVersionMinor = webFrontVersionResponse.minorVersion;
		this._serverVersionTimestamp = webFrontVersionResponse.timestamp;
		this._serverVersionString = webFrontVersionResponse.versionString;
		this._versionConfig = webFrontVersionResponse.config;

		// Check if using a very old version and force reload page
		if ((config.environment === 'master' || config.environment === 'production') && this._serverVersionMajor !== null) {
			const timestampDiffInMilliseconds = this._serverVersionTimestamp - config.clientVersionTimestamp;
			const timestampDiffInSeconds = timestampDiffInMilliseconds / 1000;
			const timestampDiffInMinutes = timestampDiffInSeconds / 60;
			const timestampDiffInHours = timestampDiffInMinutes / 60;
			const timestampDiffInDays = timestampDiffInHours / 24;

			const versionMinorNumberDiff = this._serverVersionMinor - config.clientVersionMinor;

			// If version is older than 7 days and 5 versions, force refresh page
			if (versionMinorNumberDiff > 5 && timestampDiffInDays > 7) {
				window.location.reload();
			}
		}
	}

	requiresReload() {
		if ((config.environment === 'master' || config.environment === 'production') && this._serverVersionMajor !== null) {
			// If major version changes
			if (this._serverVersionMajor > config.clientVersionMajor) {
				return true;
			} else {
				const timestampDiffInMilliseconds = this._serverVersionTimestamp - config.clientVersionTimestamp;
				const timestampDiffInSeconds = timestampDiffInMilliseconds / 1000;
				const timestampDiffInMinutes = timestampDiffInSeconds / 60;

				// If client version is older than the configured minutes between force reload
				if (timestampDiffInMinutes > this._versionConfig.minutesBetweenForceReload) {
					return true;
				}
			}
		}

		return false;
	}

	getClientVersionString() {
		return config.clientVersionString;
	}

	getServerVersionString() {
		return this._serverVersionString;
	}
}

export const versionManager = new VersionManager();
