import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateFilterMutation($input: UpdateFilterInput!) {
		updateFilter(input: $input) {
			filter {
				id
				name
				section
				value
				updatedAt
			}
			viewer {
				filters {
					edges {
						node {
							id
							name
							section
							value
							updatedAt
						}
					}
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const filter = {id: input.id};
	if (input.name) {
		filter.name = input.name;
	}
	if (input.value) {
		filter.value = input.value;
	}

	return {
		updateFilter: {
			filter: filter,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Saved Filter Updated');

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
