/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateFileInput = {|
  taskId?: ?string,
  projectId?: ?string,
  expenseItemId?: ?string,
  personId?: ?string,
  name?: ?string,
  mimeType?: ?string,
  folderId?: ?string,
  size?: ?number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createFileMutationVariables = {|
  input: CreateFileInput
|};
export type createFileMutationResponse = {|
  +createFile: ?{|
    +task: ?{|
      +fileCount: ?number
    |},
    +file: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +mimeType: ?string,
        +key: ?string,
        +size: ?number,
        +yearCreated: ?number,
        +monthCreated: ?number,
        +dayCreated: ?number,
        +hourCreated: ?number,
        +minuteCreated: ?number,
        +secondCreated: ?number,
        +person: ?{|
          +id: string,
          +fullName: ?string,
        |},
        +folder: ?{|
          +id: string,
          +name: ?string,
        |},
      |}
    |},
    +error: ?string,
    +filename: ?string,
  |}
|};
export type createFileMutation = {|
  variables: createFileMutationVariables,
  response: createFileMutationResponse,
|};
*/


/*
mutation createFileMutation(
  $input: CreateFileInput!
) {
  createFile(input: $input) {
    task {
      fileCount
      id
    }
    file {
      node {
        id
        name
        mimeType
        key
        size
        yearCreated
        monthCreated
        dayCreated
        hourCreated
        minuteCreated
        secondCreated
        person {
          id
          fullName
        }
        folder {
          id
          name
        }
      }
    }
    error
    filename
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateFileInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "FileTypeEdge",
  "kind": "LinkedField",
  "name": "file",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mimeType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "size",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "yearCreated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthCreated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dayCreated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hourCreated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minuteCreated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondCreated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Folder",
          "kind": "LinkedField",
          "name": "folder",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createFileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFilePayload",
        "kind": "LinkedField",
        "name": "createFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createFileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFilePayload",
        "kind": "LinkedField",
        "name": "createFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createFileMutation",
    "operationKind": "mutation",
    "text": "mutation createFileMutation(\n  $input: CreateFileInput!\n) {\n  createFile(input: $input) {\n    task {\n      fileCount\n      id\n    }\n    file {\n      node {\n        id\n        name\n        mimeType\n        key\n        size\n        yearCreated\n        monthCreated\n        dayCreated\n        hourCreated\n        minuteCreated\n        secondCreated\n        person {\n          id\n          fullName\n        }\n        folder {\n          id\n          name\n        }\n      }\n    }\n    error\n    filename\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9e933edda6768f7abf7ea711a3d3aa0d';

module.exports = node;
