/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSkillLevelsInput = {|
  enableSkillLevels?: ?boolean,
  skillLevels?: ?$ReadOnlyArray<?SkillLevelInput>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type SkillLevelInput = {|
  id?: ?string,
  description?: ?string,
  level?: ?number,
  replacesSkillLevelIds?: ?$ReadOnlyArray<?string>,
|};
export type updateSkillLevelsMutationVariables = {|
  input: UpdateSkillLevelsInput
|};
export type updateSkillLevelsMutationResponse = {|
  +updateSkillLevels: ?{|
    +company: ?{|
      +skillLevels: ?$ReadOnlyArray<?{|
        +id: string,
        +description: ?string,
        +level: ?number,
      |}>,
      +allPersons: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +skillPersons: ?$ReadOnlyArray<?{|
              +skill: ?{|
                +id: string
              |},
              +level: ?{|
                +id: string,
                +level: ?number,
              |},
            |}>
          |}
        |}>
      |},
      +placeholders: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +skillPlaceholders: ?$ReadOnlyArray<?{|
              +skill: ?{|
                +id: string
              |},
              +level: ?{|
                +id: string,
                +level: ?number,
              |},
            |}>
          |}
        |}>
      |},
    |}
  |}
|};
export type updateSkillLevelsMutation = {|
  variables: updateSkillLevelsMutationVariables,
  response: updateSkillLevelsMutationResponse,
|};
*/


/*
mutation updateSkillLevelsMutation(
  $input: UpdateSkillLevelsInput!
) {
  updateSkillLevels(input: $input) {
    company {
      skillLevels {
        id
        description
        level
      }
      allPersons(first: 10000, excludeClientUsers: true) {
        edges {
          node {
            skillPersons {
              skill {
                id
              }
              level {
                id
                level
              }
              id
            }
            id
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      placeholders {
        edges {
          node {
            skillPlaceholders {
              skill {
                id
              }
              level {
                id
                level
              }
              id
            }
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSkillLevelsInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillLevel",
  "kind": "LinkedField",
  "name": "skillLevels",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skill",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillLevel",
  "kind": "LinkedField",
  "name": "level",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "excludeClientUsers",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v12 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateSkillLevelsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSkillLevelsPayload",
        "kind": "LinkedField",
        "name": "updateSkillLevels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": "allPersons",
                "args": null,
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "__Company_allPersons_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SkillPerson",
                            "kind": "LinkedField",
                            "name": "skillPersons",
                            "plural": true,
                            "selections": (v7/*: any*/),
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PlaceholderTypeConnection",
                "kind": "LinkedField",
                "name": "placeholders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlaceholderTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Placeholder",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SkillPlaceholder",
                            "kind": "LinkedField",
                            "name": "skillPlaceholders",
                            "plural": true,
                            "selections": (v7/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateSkillLevelsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSkillLevelsPayload",
        "kind": "LinkedField",
        "name": "updateSkillLevels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "allPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SkillPerson",
                            "kind": "LinkedField",
                            "name": "skillPersons",
                            "plural": true,
                            "selections": (v12/*: any*/),
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Company_allPersons",
                "kind": "LinkedHandle",
                "name": "allPersons"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PlaceholderTypeConnection",
                "kind": "LinkedField",
                "name": "placeholders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlaceholderTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Placeholder",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SkillPlaceholder",
                            "kind": "LinkedField",
                            "name": "skillPlaceholders",
                            "plural": true,
                            "selections": (v12/*: any*/),
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "updateSkillLevels",
            "company",
            "allPersons"
          ]
        }
      ]
    },
    "name": "updateSkillLevelsMutation",
    "operationKind": "mutation",
    "text": "mutation updateSkillLevelsMutation(\n  $input: UpdateSkillLevelsInput!\n) {\n  updateSkillLevels(input: $input) {\n    company {\n      skillLevels {\n        id\n        description\n        level\n      }\n      allPersons(first: 10000, excludeClientUsers: true) {\n        edges {\n          node {\n            skillPersons {\n              skill {\n                id\n              }\n              level {\n                id\n                level\n              }\n              id\n            }\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      placeholders {\n        edges {\n          node {\n            skillPlaceholders {\n              skill {\n                id\n              }\n              level {\n                id\n                level\n              }\n              id\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '46ea2be1c6b5fb1142295d01e8ac03f5';

module.exports = node;
