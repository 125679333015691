import {commitMutation, graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';

const mutation = graphql`
	mutation CreateQuickbooksInvoiceMutation($input: CreateQuickbooksInvoiceInput!) {
		createQuickbooksInvoice(input: $input) {
			invoice {
				id
				invoiceReference
				companyInvoiceId
				name
				invoiceType
				status
				externalStatus
				dueDay
				dueMonth
				dueYear
				createdDay
				createdMonth
				createdYear
				notes
				quickbooksId
				xeroId
				economicId
				billTo {
					id
					name
					billFrom {
						id
						name
						integration
					}
				}
				entries(first: 100000000) {
					edges {
						node {
							id
							name
							quantity
							unitPrice
							discount
							tax
							description
						}
					}
				}
			}
			success
			errorMessage
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
