/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnlinkInvoiceInput = {|
  id?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type UnlinkInvoiceMutationVariables = {|
  input: UnlinkInvoiceInput
|};
export type UnlinkInvoiceMutationResponse = {|
  +unlinkInvoice: ?{|
    +invoice: ?{|
      +xeroId: ?string,
      +quickbooksId: ?number,
      +economicId: ?number,
      +sageIntacctId: ?string,
    |}
  |}
|};
export type UnlinkInvoiceMutation = {|
  variables: UnlinkInvoiceMutationVariables,
  response: UnlinkInvoiceMutationResponse,
|};
*/


/*
mutation UnlinkInvoiceMutation(
  $input: UnlinkInvoiceInput!
) {
  unlinkInvoice(input: $input) {
    invoice {
      xeroId
      quickbooksId
      economicId
      sageIntacctId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UnlinkInvoiceInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xeroId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quickbooksId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "economicId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sageIntacctId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnlinkInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnlinkInvoicePayload",
        "kind": "LinkedField",
        "name": "unlinkInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceType",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnlinkInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnlinkInvoicePayload",
        "kind": "LinkedField",
        "name": "unlinkInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceType",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UnlinkInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation UnlinkInvoiceMutation(\n  $input: UnlinkInvoiceInput!\n) {\n  unlinkInvoice(input: $input) {\n    invoice {\n      xeroId\n      quickbooksId\n      economicId\n      sageIntacctId\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6c1af8e122635eafedb9017263aeb5ae';

module.exports = node;
