import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createInvoiceMutation($input: CreateInvoiceInput!) {
		createInvoice(input: $input) {
			invoice {
				node {
					id
					invoiceReference
					companyInvoiceId
					name
					currency
					invoiceType
					status
					dueDay
					dueMonth
					dueYear
					createdDay
					createdMonth
					createdYear
					notes
					quickbooksId
					xeroId
					economicId
					project {
						retainerPeriods {
							edges {
								node {
									invoiced
								}
							}
						}
					}
					entries(first: 100000000) @connection(key: "Invoice_entries") {
						edges {
							node {
								id
								name
								quantity
								unitPrice
								discount
								tax
								description
							}
						}
					}
					payments(first: 100000000) @connection(key: "Invoice_payments") {
						edges {
							node {
								id
								notes
								amount
								day
								month
								year
								createdAt
								createdBy {
									fullName
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_invoices',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'invoice',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Invoice Created');

	for (let i = 0; i < input.entries.length; i++) {
		const entry = input.entries[i];
		if ('quickbooksItemOrService' in entry) {
			// Might be null, still need to remove it
			if (entry.quickbooksItemOrService) {
				// Save if not null
				const extRef = {key: 'quickbooks_online_item_or_service', value: entry.quickbooksItemOrService};
				entry.externalRefs = [extRef];
			}
			delete entry.quickbooksItemOrService;
		}
	}

	if (input.invoiceReference === null) {
		input = omit(input, ['invoiceReference']);
	}

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
