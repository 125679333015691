import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateSubTaskMutation($input: UpdateSubTaskInput!) {
		updateSubTask(input: $input) {
			task {
				timeLeft
				latestUiUpdateAt
				latestUiUpdateBy {
					firstName
					lastName
				}
				subTasks(first: 1000) @connection(key: "Task_subTasks") {
					edges {
						node {
							id
							sortOrder
						}
					}
				}
			}
			subTask {
				id
				estimate
				done
				sortOrder
				name
				description
				startDay
				startMonth
				startYear
				endDay
				endMonth
				endYear
				startFrom
				endFrom
				role {
					id
					name
				}
				person {
					id
					firstName
					lastName
					active
					profilePictureId
					profilePictureDefaultId
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const task = {id: input.id};
	if (input.name) {
		task.name = input.name;
	}
	if (input.estimate) {
		task.estimate = input.estimate;
	}
	if (input.done !== undefined) {
		task.done = input.done;
	}
	if (input.description) {
		task.description = input.description;
	}
	if (input.personId !== undefined) {
		task.personId = input.personId;
	}
	if (input.roleId !== undefined) {
		task.roleId = input.roleId;
	}

	if (input.optimisticSortOrder !== undefined) {
		task.sortOrder = input.optimisticSortOrder;
	}

	if (input.startYear !== undefined) {
		task.startYear = input.startYear;
		task.startMonth = input.startMonth;
		task.startDay = input.startDay;
	}
	if (input.endYear !== undefined) {
		task.endYear = input.endYear;
		task.endMonth = input.endMonth;
		task.endDay = input.endDay;
	}

	return {
		updateSubTask: {
			subTask: task,
		},
	};
}

function commit(environment, input, onCompleted, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id', 'taskId'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Sub Task Updated', {_Changed: changes});

	const cleanedVariables = omit(input, ['optimisticSortOrder']);

	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables: {
				input: {
					csrfToken: Util.getCsrfValue(),
					socketClientId: Util.getClientId(),
					...cleanedVariables,
				},
			},
			optimisticResponse: getOptimisticResponse(input),
			onCompleted,
			onError,
		},
		true
	);
}

export default {commit};
