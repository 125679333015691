import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createRepeatingTaskMutation($input: CreateRepeatingTaskInput!) {
		createRepeatingTask(input: $input) {
			task {
				repeatingTask {
					id
					repeatType
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					monthlyDay
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Repeating Card Created', {_Type: input.repeatType});
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
