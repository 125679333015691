import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteMultipleLabelsMutation($input: DeleteMultipleLabelsInput!) {
		deleteMultipleLabels(input: $input) {
			deletedLabelIds
		}
	}
`;

const getConfigs = input => {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_labels',
				},
			],
			pathToConnection: ['company', 'labels'],
			deletedIDFieldName: 'deletedLabelIds',
		},
	];
};

const getOptimisticResponse = input => {
	return {
		deleteMultipleLabels: {
			deletedLabelIds: input.ids,
		},
	};
};

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Labels Deleted');
	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
