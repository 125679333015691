/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteGoogleDriveFileLinkInput = {|
  id?: ?string,
  taskId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteGoogleDriveFileLinkMutationVariables = {|
  input: DeleteGoogleDriveFileLinkInput
|};
export type deleteGoogleDriveFileLinkMutationResponse = {|
  +deleteGoogleDriveFileLink: ?{|
    +deletedGoogleDriveFileId: ?string
  |}
|};
export type deleteGoogleDriveFileLinkMutation = {|
  variables: deleteGoogleDriveFileLinkMutationVariables,
  response: deleteGoogleDriveFileLinkMutationResponse,
|};
*/


/*
mutation deleteGoogleDriveFileLinkMutation(
  $input: DeleteGoogleDriveFileLinkInput!
) {
  deleteGoogleDriveFileLink(input: $input) {
    deletedGoogleDriveFileId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteGoogleDriveFileLinkInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteGoogleDriveFileLinkPayload",
    "kind": "LinkedField",
    "name": "deleteGoogleDriveFileLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedGoogleDriveFileId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteGoogleDriveFileLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteGoogleDriveFileLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteGoogleDriveFileLinkMutation",
    "operationKind": "mutation",
    "text": "mutation deleteGoogleDriveFileLinkMutation(\n  $input: DeleteGoogleDriveFileLinkInput!\n) {\n  deleteGoogleDriveFileLink(input: $input) {\n    deletedGoogleDriveFileId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2d63f3d26699b2be553ce3ff1b502746';

module.exports = node;
