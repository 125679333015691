/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteFolderInput = {|
  id?: ?string,
  projectId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteFolderMutationVariables = {|
  input: DeleteFolderInput
|};
export type deleteFolderMutationResponse = {|
  +deleteFolder: ?{|
    +deletedFolderId: ?string,
    +project: ?{|
      +id: string,
      +folders: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +folder: ?{|
              +id: string
            |},
          |}
        |}>
      |},
      +tasks: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +files: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +folder: ?{|
                    +id: string
                  |},
                |}
              |}>
            |}
          |}
        |}>
      |},
      +files: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +folder: ?{|
              +id: string
            |},
          |}
        |}>
      |},
      +googleDriveFiles: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: ?string,
            +folder: ?{|
              +id: string
            |},
          |}
        |}>
      |},
    |},
  |}
|};
export type deleteFolderMutation = {|
  variables: deleteFolderMutationVariables,
  response: deleteFolderMutationResponse,
|};
*/


/*
mutation deleteFolderMutation(
  $input: DeleteFolderInput!
) {
  deleteFolder(input: $input) {
    deletedFolderId
    project {
      id
      folders(first: 1000) {
        edges {
          node {
            id
            folder {
              id
            }
          }
        }
      }
      tasks(first: 1000) {
        edges {
          node {
            files(first: 100) {
              edges {
                node {
                  id
                  folder {
                    id
                  }
                }
              }
            }
            id
          }
        }
      }
      files(first: 10000) {
        edges {
          node {
            id
            folder {
              id
            }
          }
        }
      }
      googleDriveFiles(first: 10000) {
        edges {
          node {
            id
            folder {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteFolderInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedFolderId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v5 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Folder",
    "kind": "LinkedField",
    "name": "folder",
    "plural": false,
    "selections": [
      (v3/*: any*/)
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": (v4/*: any*/),
  "concreteType": "FolderTypeConnection",
  "kind": "LinkedField",
  "name": "folders",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FolderTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Folder",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "folders(first:1000)"
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FileTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100
    }
  ],
  "concreteType": "FileTypeConnection",
  "kind": "LinkedField",
  "name": "files",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": "files(first:100)"
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v10 = {
  "alias": null,
  "args": (v9/*: any*/),
  "concreteType": "FileTypeConnection",
  "kind": "LinkedField",
  "name": "files",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": "files(first:10000)"
},
v11 = {
  "alias": null,
  "args": (v9/*: any*/),
  "concreteType": "GoogleDriveFileTypeConnection",
  "kind": "LinkedField",
  "name": "googleDriveFiles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GoogleDriveFileTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GoogleDriveFile",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "googleDriveFiles(first:10000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFolderPayload",
        "kind": "LinkedField",
        "name": "deleteFolder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "tasks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "tasks(first:1000)"
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteFolderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFolderPayload",
        "kind": "LinkedField",
        "name": "deleteFolder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "TaskTypeConnection",
                "kind": "LinkedField",
                "name": "tasks",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "tasks(first:1000)"
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteFolderMutation",
    "operationKind": "mutation",
    "text": "mutation deleteFolderMutation(\n  $input: DeleteFolderInput!\n) {\n  deleteFolder(input: $input) {\n    deletedFolderId\n    project {\n      id\n      folders(first: 1000) {\n        edges {\n          node {\n            id\n            folder {\n              id\n            }\n          }\n        }\n      }\n      tasks(first: 1000) {\n        edges {\n          node {\n            files(first: 100) {\n              edges {\n                node {\n                  id\n                  folder {\n                    id\n                  }\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n      files(first: 10000) {\n        edges {\n          node {\n            id\n            folder {\n              id\n            }\n          }\n        }\n      }\n      googleDriveFiles(first: 10000) {\n        edges {\n          node {\n            id\n            folder {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f096f63c8a31600092bcc90937d27b4b';

module.exports = node;
