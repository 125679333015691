/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnmarkProgramAsFavoriteInput = {|
  programId: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type unmarkProgramAsFavoriteMutationVariables = {|
  input: UnmarkProgramAsFavoriteInput
|};
export type unmarkProgramAsFavoriteMutationResponse = {|
  +unmarkProgramAsFavorite: ?{|
    +unmarkedProgramId: ?string,
    +program: ?{|
      +node: ?{|
        +id: string,
        +userRelationTypes: ?$ReadOnlyArray<?string>,
      |}
    |},
  |}
|};
export type unmarkProgramAsFavoriteMutation = {|
  variables: unmarkProgramAsFavoriteMutationVariables,
  response: unmarkProgramAsFavoriteMutationResponse,
|};
*/


/*
mutation unmarkProgramAsFavoriteMutation(
  $input: UnmarkProgramAsFavoriteInput!
) {
  unmarkProgramAsFavorite(input: $input) {
    unmarkedProgramId
    program {
      node {
        id
        userRelationTypes
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UnmarkProgramAsFavoriteInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnmarkProgramAsFavoritePayload",
    "kind": "LinkedField",
    "name": "unmarkProgramAsFavorite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unmarkedProgramId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectServiceProgramTypeEdge",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userRelationTypes",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "unmarkProgramAsFavoriteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "unmarkProgramAsFavoriteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "unmarkProgramAsFavoriteMutation",
    "operationKind": "mutation",
    "text": "mutation unmarkProgramAsFavoriteMutation(\n  $input: UnmarkProgramAsFavoriteInput!\n) {\n  unmarkProgramAsFavorite(input: $input) {\n    unmarkedProgramId\n    program {\n      node {\n        id\n        userRelationTypes\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'de9a4aaec116b8a7b066479f46792c72';

module.exports = node;
