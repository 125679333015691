import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation movePhaseOnTimelineMutationModernMutation($input: MovePhaseOnTimelineInput!) {
		movePhaseOnTimeline(input: $input) {
			phase {
				id
				name
				startDay
				startMonth
				startYear
				startFrom
				deadlineDay
				deadlineMonth
				deadlineYear
				deadlineFrom
			}
			previousProject {
				phases(first: 10000) @connection(key: "Project_phases") {
					edges {
						node {
							id
							name
						}
					}
				}
			}
			project {
				tasks(first: 10000) @connection(key: "Project_tasks", filters: []) {
					edges {
						node {
							project {
								id
							}
							startDay
							startMonth
							startYear
							startFrom
							deadlineDay
							deadlineMonth
							deadlineYear
							deadlineFrom
						}
					}
				}
				phases(first: 10000) @connection(key: "Project_phases") {
					edges {
						node {
							id
							name
						}
					}
				}
			}
			errors
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const changes = [];
	for (const key of Object.keys(input)) {
		//ADD projectId, projectGroupId also here if used by the mutation
		if (['id', 'projectId'].includes(key) || input[key] === undefined) {
			continue;
		}
		changes.push(key);
	}

	tracking.trackEvent('Phase Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
