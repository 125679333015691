/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenerateLowHighSuggestionsInput = {|
  projectId?: ?string,
  tasks?: ?$ReadOnlyArray<?LowHighSuggestionInput>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type LowHighSuggestionInput = {|
  taskId?: ?string,
  roleId?: ?string,
|};
export type generateLowHighSuggestionsMutationVariables = {|
  input: GenerateLowHighSuggestionsInput
|};
export type generateLowHighSuggestionsMutationResponse = {|
  +generateLowHighSuggestions: ?{|
    +suggestions: ?$ReadOnlyArray<?{|
      +id: string,
      +taskId: string,
      +low: ?number,
      +high: ?number,
      +estimate: ?number,
    |}>
  |}
|};
export type generateLowHighSuggestionsMutation = {|
  variables: generateLowHighSuggestionsMutationVariables,
  response: generateLowHighSuggestionsMutationResponse,
|};
*/


/*
mutation generateLowHighSuggestionsMutation(
  $input: GenerateLowHighSuggestionsInput!
) {
  generateLowHighSuggestions(input: $input) {
    suggestions {
      id
      taskId
      low
      high
      estimate
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "GenerateLowHighSuggestionsInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateLowHighSuggestionsPayload",
    "kind": "LinkedField",
    "name": "generateLowHighSuggestions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SuggestedLowHigh",
        "kind": "LinkedField",
        "name": "suggestions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taskId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "low",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "high",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "estimate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "generateLowHighSuggestionsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "generateLowHighSuggestionsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "generateLowHighSuggestionsMutation",
    "operationKind": "mutation",
    "text": "mutation generateLowHighSuggestionsMutation(\n  $input: GenerateLowHighSuggestionsInput!\n) {\n  generateLowHighSuggestions(input: $input) {\n    suggestions {\n      id\n      taskId\n      low\n      high\n      estimate\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '88e51dc28fdbd94527f9b7916dc86fd4';

module.exports = node;
