import * as Sentry from '@sentry/browser';
import { getTeamFromPathname } from './GetTeamFromPathname';
export function registerMutationError(endpoint, correlationId, isNetworkError, isNonCritical = false) {
    const errorText = `${endpoint ? `${endpoint} - ` : ``}${isNetworkError ? 'Network error' : isNonCritical ? 'Non-critical error' : 'Error'} modal shown${correlationId ? `: ${correlationId}` : ``}`;
    // eslint-disable-next-line no-console
    console.log('error', errorText);
    let errorId;
    Sentry.withScope(scope => {
        if (correlationId) {
            const parsedCorrelationId = correlationId.substring(0, Math.min(correlationId.length, 36));
            if (endpoint) {
                scope.setFingerprint(['{{ default}}', endpoint]);
            }
            else {
                scope.setFingerprint(['{{ default}}', parsedCorrelationId]);
            }
            scope.addEventProcessor(event => {
                event.extra = Object.assign(Object.assign({}, event.extra), { ddLogs: `https://app.datadoghq.eu/logs?cols=service%2C%40level&event&from_ts=${new Date().getTime() - 604800000}&index=&live=true&messageDisplay=inline&query=%40correlationId%3A${parsedCorrelationId}&stream_sort=desc&to_ts=${new Date().getTime()}`, ddTrace: `https://app.datadoghq.eu/apm/traces?query=env%3Aproduction%20service%3Agraphql%20%40correlation_id%3A${parsedCorrelationId}&cols=service%2C%40duration%2Cresource_name%2C%40http.status_code%2Cenv%2C%40email%2C%40company_id%2C%40company_name%2C%40company_tier%2C%40_duration.by_service&start=${new Date().getTime() - 604800000}end=${new Date().getTime()}&paused=true&historicalData=true` });
                return event;
            });
        }
        errorId = Sentry.captureException(new Error(errorText), {
            tags: { bugduty: !isNonCritical, team: getTeamFromPathname(window.location.pathname) },
        });
    });
    return errorId;
}
