import styled from 'styled-components';

export const ProjectNameTooltipContainer = styled.div`
	padding: 7.5px 5px;
	
	> *:not(:last-child) {
		margin-bottom: 10px;
	}	
`;

export const ProjectNameTooltipItem = styled.div`
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;
	
	span {
		margin-left: 10px;
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		color: #292936;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		max-height: 20px;
	}
`;