/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIODICITY = "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type SyncJiraDataInput = {|
  jiraProjectId?: ?string,
  jiraEpicIds?: ?$ReadOnlyArray<?string>,
  forecastProjectId?: ?string,
  boardId?: ?string,
  subtaskType?: ?string,
  epicType?: ?string,
  type?: ?string,
  estimationField?: ?string,
  name?: ?string,
  projectColor?: ?string,
  labels?: ?$ReadOnlyArray<?string>,
  sprintTimeBox?: ?boolean,
  sprintLengthInDays?: ?number,
  projectStartDay?: ?number,
  projectStartMonth?: ?number,
  projectStartYear?: ?number,
  projectEndDay?: ?number,
  projectEndMonth?: ?number,
  projectEndYear?: ?number,
  rateCardId?: ?string,
  statusColumns?: ?$ReadOnlyArray<?StatusColumnInput>,
  useManualAllocations?: ?boolean,
  budgetType?: ?BUDGET_TYPE,
  clientId?: ?string,
  budget?: ?number,
  defaultPeriodPriceAmount?: ?number,
  defaultPeriodHoursAmount?: ?number,
  defaultPeriodBudgetType?: ?PERIOD_BUDGET_TYPE,
  defaultPeriodLength?: ?number,
  defaultPeriodPeriodicity?: ?PERIODICITY,
  defaultPeriodSettingIgnoreForBilling?: ?boolean,
  defaultPeriodSettingSubtractValue?: ?boolean,
  defaultPeriodSettingRollValue?: ?boolean,
  defaultPeriodSettingAddExpenses?: ?boolean,
  remainingField?: ?string,
  jiraTypeToPhase?: ?string,
  cutoffDate?: ?string,
  useBaseline?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type StatusColumnInput = {|
  id?: ?string,
  name?: ?string,
  order?: ?number,
  category?: ?string,
  projectId?: ?string,
  projectGroupStatusColumnId?: ?number,
  autoShowOnTimePage?: ?boolean,
  encourageTimeRegistration?: ?boolean,
|};
export type syncJiraIntegrationMutationVariables = {|
  input: SyncJiraDataInput
|};
export type syncJiraIntegrationMutationResponse = {|
  +syncJiraData: ?{|
    +company: ?{|
      +unSynchedJiraProjects: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +style: ?string,
      |}>,
      +unSynchedJiraServerProjects: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
      |}>,
    |},
    +project: ?{|
      +id: string,
      +isJiraProject: ?boolean,
      +jiraCloudProject: ?{|
        +id: string,
        +name: ?string,
      |},
      +jiraCloudEpicIds: ?$ReadOnlyArray<?string>,
      +jiraServerProject: ?{|
        +id: string,
        +name: ?string,
      |},
      +remainingAutoCalculated: ?boolean,
      +jiraSyncRemaining: ?boolean,
      +jiraCutoffDate: ?string,
    |},
    +projectEdge: ?{|
      +node: ?{|
        +id: string,
        +companyProjectId: ?number,
        +customProjectId: ?string,
        +status: ?PROJECT_STATUS,
        +name: ?string,
        +isJiraProject: ?boolean,
        +projectColor: ?string,
        +isInProjectGroup: ?boolean,
        +billable: ?boolean,
        +budgetType: ?BUDGET_TYPE,
        +projectGroupId: ?string,
        +sprintTimeBox: ?boolean,
        +harvestProject: ?{|
          +id: string
        |},
        +jiraCloudProject: ?{|
          +id: string,
          +name: ?string,
        |},
        +jiraCloudEpicIds: ?$ReadOnlyArray<?string>,
        +jiraServerProject: ?{|
          +id: string,
          +name: ?string,
        |},
        +jiraCutoffDate: ?string,
        +statusColumnsV2: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +projectGroupStatusColumnId: ?number,
            |}
          |}>
        |},
      |}
    |},
  |}
|};
export type syncJiraIntegrationMutation = {|
  variables: syncJiraIntegrationMutationVariables,
  response: syncJiraIntegrationMutationResponse,
|};
*/


/*
mutation syncJiraIntegrationMutation(
  $input: SyncJiraDataInput!
) {
  syncJiraData(input: $input) {
    company {
      unSynchedJiraProjects {
        id
        name
        style
      }
      unSynchedJiraServerProjects {
        id
        name
      }
      id
    }
    project {
      id
      isJiraProject
      jiraCloudProject {
        id
        name
      }
      jiraCloudEpicIds
      jiraServerProject {
        id
        name
      }
      remainingAutoCalculated
      jiraSyncRemaining
      jiraCutoffDate
    }
    projectEdge {
      node {
        id
        companyProjectId
        customProjectId
        status
        name
        isJiraProject
        projectColor
        isInProjectGroup
        billable
        budgetType
        projectGroupId
        sprintTimeBox
        harvestProject {
          id
        }
        jiraCloudProject {
          id
          name
        }
        jiraCloudEpicIds
        jiraServerProject {
          id
          name
        }
        jiraCutoffDate
        statusColumnsV2(first: 1000) {
          edges {
            node {
              id
              projectGroupStatusColumnId
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SyncJiraDataInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "JiraCloudProjectType",
  "kind": "LinkedField",
  "name": "unSynchedJiraProjects",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "style",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "JiraServerProjectType",
  "kind": "LinkedField",
  "name": "unSynchedJiraServerProjects",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isJiraProject",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "JiraCloudProjectType",
  "kind": "LinkedField",
  "name": "jiraCloudProject",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraCloudEpicIds",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "JiraServerProjectType",
  "kind": "LinkedField",
  "name": "jiraServerProject",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraCutoffDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingAutoCalculated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jiraSyncRemaining",
      "storageKey": null
    },
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectTypeEdge",
  "kind": "LinkedField",
  "name": "projectEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        (v3/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isInProjectGroup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectGroupId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sprintTimeBox",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HarvestProjectType",
          "kind": "LinkedField",
          "name": "harvestProject",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000
            }
          ],
          "concreteType": "StatusColumnV2TypeConnection",
          "kind": "LinkedField",
          "name": "statusColumnsV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StatusColumnV2TypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumnV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectGroupStatusColumnId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "statusColumnsV2(first:1000)"
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "syncJiraIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncJiraDataPayload",
        "kind": "LinkedField",
        "name": "syncJiraData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "syncJiraIntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncJiraDataPayload",
        "kind": "LinkedField",
        "name": "syncJiraData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "syncJiraIntegrationMutation",
    "operationKind": "mutation",
    "text": "mutation syncJiraIntegrationMutation(\n  $input: SyncJiraDataInput!\n) {\n  syncJiraData(input: $input) {\n    company {\n      unSynchedJiraProjects {\n        id\n        name\n        style\n      }\n      unSynchedJiraServerProjects {\n        id\n        name\n      }\n      id\n    }\n    project {\n      id\n      isJiraProject\n      jiraCloudProject {\n        id\n        name\n      }\n      jiraCloudEpicIds\n      jiraServerProject {\n        id\n        name\n      }\n      remainingAutoCalculated\n      jiraSyncRemaining\n      jiraCutoffDate\n    }\n    projectEdge {\n      node {\n        id\n        companyProjectId\n        customProjectId\n        status\n        name\n        isJiraProject\n        projectColor\n        isInProjectGroup\n        billable\n        budgetType\n        projectGroupId\n        sprintTimeBox\n        harvestProject {\n          id\n        }\n        jiraCloudProject {\n          id\n          name\n        }\n        jiraCloudEpicIds\n        jiraServerProject {\n          id\n          name\n        }\n        jiraCutoffDate\n        statusColumnsV2(first: 1000) {\n          edges {\n            node {\n              id\n              projectGroupStatusColumnId\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '628b076a57eac61593592064a2149424';

module.exports = node;
