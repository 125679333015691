import * as Sentry from '@sentry/browser';
import { sendTrackingMessage } from '../TrackingMessage';
import { TRACKING_EVENT_TYPE } from './TRACKING_EVENT_TYPE';
const constructTimeRegistrationSuggestionsShownBody = ({ companyId, personId, modelVersion, }) => {
    return {
        eventType: TRACKING_EVENT_TYPE.TIME_REGISTRATION_SUGGESTIONS_SHOWN,
        companyId,
        personId,
        modelVersion,
    };
};
export const trackTimeRegistrationSuggestionsShown = (body) => {
    try {
        const s3Body = constructTimeRegistrationSuggestionsShownBody(body);
        sendTrackingMessage(s3Body);
    }
    catch (err) {
        Sentry.captureException(err);
        console.log('Tracking failed, reported to Sentry', err);
    }
};
