// @ts-check

import {sendTrackingMessage} from '../TrackingMessage';
import * as Sentry from '@sentry/browser';
import {TRACKING_EVENT_TYPE} from './TRACKING_EVENT_TYPE';
import {trackEvent} from '../amplitude/TrackingV2';
import {TRACKING_OBJECTS} from '../amplitude/constants/TrackingObjects';
import {SEARCH_RESULT_VARIANT} from '../../forecast-app/navigation/header/global-search/SearchResultRow';

const getTrackingEventTypeFromTrackingObject = trackingObject => {
	switch (trackingObject) {
		case TRACKING_OBJECTS.TIME_REGISTRATION_MODAL_SEARCH:
			return TRACKING_EVENT_TYPE.TIME_REGISTRATION_MODAL_SEARCH_RESULT_SELECTED;
		default:
			return TRACKING_EVENT_TYPE.GLOBAL_SEARCH_RESULT_SELECTED;
	}
};

/**
 * @typedef {object} SelectedItem
 * @property {string} id
 * @property {string} variant
 */

/**
 * @typedef {object} SearchResult
 * @property {string} id
 * @property {string} variant
 * @property {string} name
 * @property {number} score
 * @property {string} projectId
 * @property {string} companyTaskId
 * @property {{highlightField: string, highlightValue: string}} highlight
 */

/**
 * @typedef {object} TrackGlobalSearchArgs
 * @property {string} trackingObject
 * @property {string | null} companyId
 * @property {number | null} personId
 * @property {string} searchString
 * @property {string[]} searchStringHistory
 * @property {SelectedItem} selectedItem
 * @property {SearchResult[]} searchResults
 * @property {number[]} accessibleProjectIds
 * @property {boolean} firstInSession
 */

/**
 * @param {TrackGlobalSearchArgs} args
 */
const constructGlobalSearchSessionEndedBody = ({
	trackingObject,
	companyId,
	personId,
	searchString,
	searchStringHistory,
	selectedItem,
	searchResults,
	accessibleProjectIds,
	firstInSession,
}) => {
	return {
		eventType: getTrackingEventTypeFromTrackingObject(trackingObject),
		companyId: companyId,
		searchPersonId: personId,
		searchString,
		searchStringHistory,
		selectedItem,
		searchResults,
		accessibleProjectIds,
		firstInSession,
	};
};

const getSelectionVariantString = selectedItem => {
	if (selectedItem.isSuggestion) return 'Suggestion';
	switch (selectedItem.variant) {
		case SEARCH_RESULT_VARIANT.PROJECT:
		case SEARCH_RESULT_VARIANT.PROJECT_GROUP:
			return 'Project';
		case SEARCH_RESULT_VARIANT.TASK:
			return 'Task';
		default:
			return 'Result';
	}
};

/**
 *
 * @param {TrackGlobalSearchArgs} args
 */
export const trackGlobalSearch = args => {
	try {
		const trackingAction = args.selectedItem
			? `${getSelectionVariantString(args.selectedItem)} Selected`
			: 'Closed Without Selection';
		trackEvent(args.trackingObject, trackingAction, {
			searchString: args.searchString,
			selectedItem: args.selectedItem,
			firstInSession: args.firstInSession,
		});

		if (args.searchString || args.selectedItem) {
			const s3Body = constructGlobalSearchSessionEndedBody(args);
			sendTrackingMessage(s3Body);
		}
	} catch (err) {
		Sentry.captureException(err);
		console.log('Tracking failed, reported to Sentry', err);
	}
};
