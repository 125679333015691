import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteAllTimeRegistrationsForDayMutation($input: DeleteAllTimesRegForDayInput!) {
		deleteAllTimeRegsForDay(input: $input) {
			deletedTimeRegIds
			viewer {
				id
			}
			errors
		}
	}
`;

function getConfigs() {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionKeys: [
				{
					key: 'Viewer_timeRegistrations',
				},
			],
			pathToConnection: ['viewer', 'timeRegistrations'],
			deletedIDFieldName: 'deletedTimeRegIds',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Time For Day Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
