import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteProjectMutation($input: DeleteProjectInput!) {
		deleteProject(input: $input) {
			deletedProjectId
			errors
		}
	}
`;

function getConfigs(input) {
	const config = [];
	config.push({
		type: 'RANGE_DELETE',
		parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
		connectionKeys: [
			{
				key: 'Viewer_projects',
			},
			{
				key: 'Header_Viewer_projects',
			},
		],
		pathToConnection: ['viewer', 'projects'],
		deletedIDFieldName: 'deletedProjectId',
	});
	if (input.projectGroupId) {
		config.push({
			type: 'RANGE_DELETE',
			parentID: input.projectGroupId,
			connectionKeys: [
				{
					key: 'ProjectGroup_projects',
				},
			],
			pathToConnection: ['projectGroup', 'projects'],
			deletedIDFieldName: 'deletedProjectId',
		});
	}
	return config;
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Project Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), id: input.id}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
