/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatePlaceholderAllocationInput = {|
  id?: ?string,
  startDate?: ?string,
  endDate?: ?string,
  placeholderId?: ?string,
  monday?: ?number,
  tuesday?: ?number,
  wednesday?: ?number,
  thursday?: ?number,
  friday?: ?number,
  saturday?: ?number,
  sunday?: ?number,
  description?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updatePlaceholderAllocationMutationVariables = {|
  input: UpdatePlaceholderAllocationInput
|};
export type updatePlaceholderAllocationMutationResponse = {|
  +updatePlaceholderAllocation: ?{|
    +placeholderAllocation: ?{|
      +node: ?{|
        +id: string,
        +startDate: ?string,
        +endDate: ?string,
        +monday: ?number,
        +tuesday: ?number,
        +wednesday: ?number,
        +thursday: ?number,
        +friday: ?number,
        +saturday: ?number,
        +sunday: ?number,
        +description: ?string,
        +placeholder: ?{|
          +id: string
        |},
      |}
    |}
  |}
|};
export type updatePlaceholderAllocationMutation = {|
  variables: updatePlaceholderAllocationMutationVariables,
  response: updatePlaceholderAllocationMutationResponse,
|};
*/


/*
mutation updatePlaceholderAllocationMutation(
  $input: UpdatePlaceholderAllocationInput!
) {
  updatePlaceholderAllocation(input: $input) {
    placeholderAllocation {
      node {
        id
        startDate
        endDate
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        description
        placeholder {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdatePlaceholderAllocationInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePlaceholderAllocationPayload",
    "kind": "LinkedField",
    "name": "updatePlaceholderAllocation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PlaceholderAllocationTypeEdge",
        "kind": "LinkedField",
        "name": "placeholderAllocation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaceholderAllocation",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "monday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tuesday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wednesday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thursday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "friday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "saturday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sunday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Placeholder",
                "kind": "LinkedField",
                "name": "placeholder",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePlaceholderAllocationMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePlaceholderAllocationMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updatePlaceholderAllocationMutation",
    "operationKind": "mutation",
    "text": "mutation updatePlaceholderAllocationMutation(\n  $input: UpdatePlaceholderAllocationInput!\n) {\n  updatePlaceholderAllocation(input: $input) {\n    placeholderAllocation {\n      node {\n        id\n        startDate\n        endDate\n        monday\n        tuesday\n        wednesday\n        thursday\n        friday\n        saturday\n        sunday\n        description\n        placeholder {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '750a908fef1c33d675a0233ee83facce';

module.exports = node;
