/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProjectGroupInput = {|
  id: string,
  name?: ?string,
  color?: ?string,
  statusColumns?: ?$ReadOnlyArray<?StatusColumnInput>,
  sprintTimeBox?: ?boolean,
  sprintLengthInDays?: ?number,
  estimationUnit?: ?string,
  minutesPerEstimationPoint?: ?number,
  useManualAllocations?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type StatusColumnInput = {|
  id?: ?string,
  name?: ?string,
  order?: ?number,
  category?: ?string,
  projectId?: ?string,
  projectGroupStatusColumnId?: ?number,
  autoShowOnTimePage?: ?boolean,
  encourageTimeRegistration?: ?boolean,
|};
export type updateProjectGroupMutationVariables = {|
  input: UpdateProjectGroupInput
|};
export type updateProjectGroupMutationResponse = {|
  +updateProjectGroup: ?{|
    +errors: ?$ReadOnlyArray<?string>,
    +projectGroup: ?{|
      +id: string,
      +name: ?string,
      +color: ?string,
      +projects: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +estimationUnit: ?string,
            +sprintTimeBox: ?boolean,
            +sprintLengthInDays: ?number,
            +minutesPerEstimationPoint: ?number,
            +useManualAllocations: ?boolean,
          |}
        |}>
      |},
    |},
  |}
|};
export type updateProjectGroupMutation = {|
  variables: updateProjectGroupMutationVariables,
  response: updateProjectGroupMutationResponse,
|};
*/


/*
mutation updateProjectGroupMutation(
  $input: UpdateProjectGroupInput!
) {
  updateProjectGroup(input: $input) {
    errors
    projectGroup {
      id
      name
      color
      projects(first: 100000) {
        edges {
          node {
            id
            estimationUnit
            sprintTimeBox
            sprintLengthInDays
            minutesPerEstimationPoint
            useManualAllocations
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProjectGroupInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateProjectGroupPayload",
    "kind": "LinkedField",
    "name": "updateProjectGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectGroupType",
        "kind": "LinkedField",
        "name": "projectGroup",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 100000
              }
            ],
            "concreteType": "ProjectTypeConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "estimationUnit",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sprintTimeBox",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sprintLengthInDays",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "minutesPerEstimationPoint",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useManualAllocations",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "projects(first:100000)"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateProjectGroupMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateProjectGroupMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateProjectGroupMutation",
    "operationKind": "mutation",
    "text": "mutation updateProjectGroupMutation(\n  $input: UpdateProjectGroupInput!\n) {\n  updateProjectGroup(input: $input) {\n    errors\n    projectGroup {\n      id\n      name\n      color\n      projects(first: 100000) {\n        edges {\n          node {\n            id\n            estimationUnit\n            sprintTimeBox\n            sprintLengthInDays\n            minutesPerEstimationPoint\n            useManualAllocations\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '02c716b1a27975f942760cac6b1e7951';

module.exports = node;
