/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateProjectStatusInput = {|
  projectId: string,
  description?: ?string,
  color: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createProjectStatusMutationVariables = {|
  input: CreateProjectStatusInput
|};
export type createProjectStatusMutationResponse = {|
  +createProjectStatus: ?{|
    +projectStatus: ?{|
      +node: ?{|
        +id: string,
        +person: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
        |},
        +description: ?string,
        +color: ?string,
        +day: ?number,
        +month: ?number,
        +year: ?number,
        +hour: ?number,
        +minute: ?number,
        +second: ?number,
      |}
    |},
    +project: ?{|
      +currentProjectStatus: ?{|
        +id: string,
        +color: ?string,
        +description: ?string,
        +person: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
        |},
      |}
    |},
    +psProject: ?{|
      +currentProjectStatus: ?{|
        +id: string,
        +color: ?string,
        +description: ?string,
        +person: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
        |},
      |}
    |},
  |}
|};
export type createProjectStatusMutation = {|
  variables: createProjectStatusMutationVariables,
  response: createProjectStatusMutationResponse,
|};
*/


/*
mutation createProjectStatusMutation(
  $input: CreateProjectStatusInput!
) {
  createProjectStatus(input: $input) {
    projectStatus {
      node {
        id
        person {
          id
          firstName
          lastName
          profilePictureId
          profilePictureDefaultId
        }
        description
        color
        day
        month
        year
        hour
        minute
        second
      }
    }
    project {
      currentProjectStatus {
        id
        color
        description
        person {
          id
          firstName
          lastName
          profilePictureId
        }
      }
      id
    }
    psProject {
      currentProjectStatus {
        id
        color
        description
        person {
          id
          firstName
          lastName
          profilePictureId
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProjectStatusInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectStatusTypeEdge",
  "kind": "LinkedField",
  "name": "projectStatus",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectStatus",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePictureDefaultId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "day",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "month",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "year",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hour",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minute",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "second",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectStatus",
  "kind": "LinkedField",
  "name": "currentProjectStatus",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v7/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
],
v11 = [
  (v9/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createProjectStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateProjectStatusPayload",
        "kind": "LinkedField",
        "name": "createProjectStatus",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectServiceProjectType",
            "kind": "LinkedField",
            "name": "psProject",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createProjectStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateProjectStatusPayload",
        "kind": "LinkedField",
        "name": "createProjectStatus",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectServiceProjectType",
            "kind": "LinkedField",
            "name": "psProject",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createProjectStatusMutation",
    "operationKind": "mutation",
    "text": "mutation createProjectStatusMutation(\n  $input: CreateProjectStatusInput!\n) {\n  createProjectStatus(input: $input) {\n    projectStatus {\n      node {\n        id\n        person {\n          id\n          firstName\n          lastName\n          profilePictureId\n          profilePictureDefaultId\n        }\n        description\n        color\n        day\n        month\n        year\n        hour\n        minute\n        second\n      }\n    }\n    project {\n      currentProjectStatus {\n        id\n        color\n        description\n        person {\n          id\n          firstName\n          lastName\n          profilePictureId\n        }\n      }\n      id\n    }\n    psProject {\n      currentProjectStatus {\n        id\n        color\n        description\n        person {\n          id\n          firstName\n          lastName\n          profilePictureId\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '117faa5f2cdc74fe474271c3817dddfc';

module.exports = node;
