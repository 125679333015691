import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateRateCardMutation($input: UpdateRateCardInput!) {
		updateRateCard(input: $input) {
			rateCard {
				id
				name
				defaultRate
				currency
				disabled
				disabledRoles {
					id
				}
				rates(first: 10000) {
					edges {
						node {
							id
							rate
							defaultRate
							startDate
							role {
								id
							}
						}
					}
				}
			}
			company {
				exchangeRates(first: 100000) {
					edges {
						node {
							id
							isUsed
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const {id, name, defaultRate, disabled, disabledRoles} = input;

	const obj = {
		id: id,
	};
	if (name) {
		obj.name = name;
	}
	if (defaultRate) {
		obj.defaultRate = defaultRate;
	}
	if (disabled) {
		obj.disabled = disabled;
	}
	if (disabledRoles) {
		obj.disabledRoles = disabledRoles;
	}
	return {
		updateRateCard: {
			...obj,
		},
	};
}

function commit(environment, input, onCompleted, onError) {
	Util.localStorageDeleteFincancialMap();
	const changes = [];

	for (const key of Object.keys(input)) {
		if (!(['id'].includes(key) || input[key] === undefined)) {
			changes.push(key);
		}
	}
	tracking.trackEvent('Rate Card Updated', {_Changed: changes});

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				id: input.id,
				name: input.name,
				defaultRate: input.defaultRate,
				currency: input.currency,
				disabled: input.disabled,
				rates: input.rates,
				disabledRoles: input.disabledRoles,
			},
		},
		configs: getConfigs(),
		getOptimisticResponse: getOptimisticResponse(input),
		onCompleted,
		onError,
	});
}

export default {commit};
