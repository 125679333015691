/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DuplicatePlaceholderInput = {|
  id?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type duplicatePlaceholderMutationVariables = {|
  input: DuplicatePlaceholderInput
|};
export type duplicatePlaceholderMutationResponse = {|
  +duplicatePlaceholder: ?{|
    +placeholder: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +role: ?{|
          +id: string,
          +name: ?string,
        |},
        +project: ?{|
          +id: string
        |},
        +projectGroupId: ?string,
        +skills: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string
            |}
          |}>
        |},
        +skillPlaceholders: ?$ReadOnlyArray<?{|
          +skill: ?{|
            +id: string
          |},
          +level: ?{|
            +id: string
          |},
        |}>,
        +placeholderAllocations: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +startDate: ?string,
              +endDate: ?string,
              +monday: ?number,
              +tuesday: ?number,
              +wednesday: ?number,
              +thursday: ?number,
              +friday: ?number,
              +saturday: ?number,
              +sunday: ?number,
              +description: ?string,
              +placeholder: ?{|
                +id: string
              |},
            |}
          |}>
        |},
      |}
    |}
  |}
|};
export type duplicatePlaceholderMutation = {|
  variables: duplicatePlaceholderMutationVariables,
  response: duplicatePlaceholderMutationResponse,
|};
*/


/*
mutation duplicatePlaceholderMutation(
  $input: DuplicatePlaceholderInput!
) {
  duplicatePlaceholder(input: $input) {
    placeholder {
      node {
        id
        name
        role {
          id
          name
        }
        project {
          id
        }
        projectGroupId
        skills {
          edges {
            node {
              id
            }
          }
        }
        skillPlaceholders {
          skill {
            id
          }
          level {
            id
          }
          id
        }
        placeholderAllocations(first: 10000) {
          edges {
            node {
              id
              startDate
              endDate
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
              description
              placeholder {
                id
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DuplicatePlaceholderInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectGroupId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillTypeConnection",
  "kind": "LinkedField",
  "name": "skills",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skill",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillLevel",
  "kind": "LinkedField",
  "name": "level",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000
    }
  ],
  "concreteType": "PlaceholderAllocationTypeConnection",
  "kind": "LinkedField",
  "name": "placeholderAllocations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PlaceholderAllocationTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PlaceholderAllocation",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tuesday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wednesday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thursday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "friday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "saturday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sunday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Placeholder",
              "kind": "LinkedField",
              "name": "placeholder",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "placeholderAllocations(first:10000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "duplicatePlaceholderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicatePlaceholderPayload",
        "kind": "LinkedField",
        "name": "duplicatePlaceholder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaceholderTypeEdge",
            "kind": "LinkedField",
            "name": "placeholder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Placeholder",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillPlaceholder",
                    "kind": "LinkedField",
                    "name": "skillPlaceholders",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "duplicatePlaceholderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicatePlaceholderPayload",
        "kind": "LinkedField",
        "name": "duplicatePlaceholder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaceholderTypeEdge",
            "kind": "LinkedField",
            "name": "placeholder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Placeholder",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillPlaceholder",
                    "kind": "LinkedField",
                    "name": "skillPlaceholders",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "duplicatePlaceholderMutation",
    "operationKind": "mutation",
    "text": "mutation duplicatePlaceholderMutation(\n  $input: DuplicatePlaceholderInput!\n) {\n  duplicatePlaceholder(input: $input) {\n    placeholder {\n      node {\n        id\n        name\n        role {\n          id\n          name\n        }\n        project {\n          id\n        }\n        projectGroupId\n        skills {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n        skillPlaceholders {\n          skill {\n            id\n          }\n          level {\n            id\n          }\n          id\n        }\n        placeholderAllocations(first: 10000) {\n          edges {\n            node {\n              id\n              startDate\n              endDate\n              monday\n              tuesday\n              wednesday\n              thursday\n              friday\n              saturday\n              sunday\n              description\n              placeholder {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '11a153c094b36d2614c9520d759f9fad';

module.exports = node;
