import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation createEconomicVerificationKeyMutation($input: CreateEconomicVerificationKeyInput!) {
		createEconomicVerificationKey(input: $input) {
			key
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue()}},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
