import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteExchangeRateMutation($input: DeleteExchangeRateInput!) {
		deleteExchangeRate(input: $input) {
			deletedExchangeRateId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_exchangeRates',
				},
			],
			pathToConnection: ['company', 'exchangeRates'],
			deletedIDFieldName: 'deletedExchangeRateId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Exchange Rate Deleted');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), id: input.id}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
