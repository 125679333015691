import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
import {omit} from 'lodash';

const mutation = graphql`
	mutation bulkDeleteExpenseItemsMutation($input: BulkDeleteExpenseItemsInput!) {
		bulkDeleteExpenseItems(input: $input) {
			deletedExpenseItemIds
		}
	}
`;

function getConfigs(input) {
	const config = [
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_expenseItems',
				},
			],
			pathToConnection: ['company', 'expenseItems'],
			deletedIDFieldName: 'deletedExpenseItemIds',
		},
	];
	return config;
}

function getOptimisticResponse(input) {
	return {
		bulkDeleteExpenseItems: {
			deletedExpenseItemIds: input.ids,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	Util.localStorageDeleteFincancialMap();
	const cleanedInput = omit(input, ['companyId']);
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
