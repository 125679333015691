/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsHolidayCalendars_HolidayCalendar$ref = any;
export type ImportHolidayCalendarEntriesInput = {|
  id: string,
  holidayCalendarEntries?: ?$ReadOnlyArray<?HolidayCalendarEntryInputType>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type HolidayCalendarEntryInputType = {|
  name?: ?string,
  year?: ?number,
  month?: ?number,
  day?: ?number,
|};
export type importHolidayCalendarEntriesMutationVariables = {|
  input: ImportHolidayCalendarEntriesInput
|};
export type importHolidayCalendarEntriesMutationResponse = {|
  +importHolidayCalendarEntries: ?{|
    +holidayCalendar: ?{|
      +$fragmentRefs: settingsHolidayCalendars_HolidayCalendar$ref
    |}
  |}
|};
export type importHolidayCalendarEntriesMutation = {|
  variables: importHolidayCalendarEntriesMutationVariables,
  response: importHolidayCalendarEntriesMutationResponse,
|};
*/


/*
mutation importHolidayCalendarEntriesMutation(
  $input: ImportHolidayCalendarEntriesInput!
) {
  importHolidayCalendarEntries(input: $input) {
    holidayCalendar {
      ...settingsHolidayCalendars_HolidayCalendar
      id
    }
  }
}

fragment settingsHolidayCalendars_HolidayCalendar on HolidayCalendar {
  id
  name
  holidayCalendarEntries(first: 100000) {
    edges {
      node {
        id
        name
        year
        month
        day
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImportHolidayCalendarEntriesInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "importHolidayCalendarEntriesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ImportHolidayCalendarEntriesPayload",
        "kind": "LinkedField",
        "name": "importHolidayCalendarEntries",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HolidayCalendar",
            "kind": "LinkedField",
            "name": "holidayCalendar",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "settingsHolidayCalendars_HolidayCalendar"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "importHolidayCalendarEntriesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ImportHolidayCalendarEntriesPayload",
        "kind": "LinkedField",
        "name": "importHolidayCalendarEntries",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HolidayCalendar",
            "kind": "LinkedField",
            "name": "holidayCalendar",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "HolidayCalendarEntryTypeConnection",
                "kind": "LinkedField",
                "name": "holidayCalendarEntries",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HolidayCalendarEntryTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HolidayCalendarEntry",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "year",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "month",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "day",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "holidayCalendarEntries(first:100000)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "HolidayCalendar_holidayCalendarEntries",
                "kind": "LinkedHandle",
                "name": "holidayCalendarEntries"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "importHolidayCalendarEntriesMutation",
    "operationKind": "mutation",
    "text": "mutation importHolidayCalendarEntriesMutation(\n  $input: ImportHolidayCalendarEntriesInput!\n) {\n  importHolidayCalendarEntries(input: $input) {\n    holidayCalendar {\n      ...settingsHolidayCalendars_HolidayCalendar\n      id\n    }\n  }\n}\n\nfragment settingsHolidayCalendars_HolidayCalendar on HolidayCalendar {\n  id\n  name\n  holidayCalendarEntries(first: 100000) {\n    edges {\n      node {\n        id\n        name\n        year\n        month\n        day\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d723022f120786b7b10c6811dd8df19';

module.exports = node;
