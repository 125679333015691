/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QUANTITY_LOCKED_REASON = "FIXED_PRICE" | "FIXED_PRICE_PERIOD" | "%future added value";
export type UpdateInvoiceInput = {|
  id?: ?string,
  name?: ?string,
  currency?: ?string,
  invoiceReference?: ?string,
  invoiceType?: ?string,
  status?: ?string,
  dueDay?: ?number,
  dueMonth?: ?number,
  dueYear?: ?number,
  createdDay?: ?number,
  createdMonth?: ?number,
  createdYear?: ?number,
  notes?: ?string,
  entries?: ?$ReadOnlyArray<?InvoiceEntryInput>,
  payments?: ?$ReadOnlyArray<?InvoicePaymentInputType>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type InvoiceEntryInput = {|
  id?: ?string,
  name?: ?string,
  projectId?: ?string,
  quantity?: ?number,
  quantityText?: ?string,
  unitPrice?: ?number,
  discount?: ?number,
  tax?: ?number,
  description?: ?string,
  timeRegIds?: ?$ReadOnlyArray<?string>,
  expenseItemIds?: ?$ReadOnlyArray<?string>,
  periodIds?: ?$ReadOnlyArray<?string>,
  externalRefs?: ?$ReadOnlyArray<?ExternalRef>,
  quantityLockedReason?: ?QUANTITY_LOCKED_REASON,
|};
export type ExternalRef = {|
  key?: ?string,
  value?: ?string,
|};
export type InvoicePaymentInputType = {|
  id?: ?string,
  notes?: ?string,
  amount?: ?number,
  day?: ?number,
  month?: ?number,
  year?: ?number,
|};
export type updateInvoiceMutationVariables = {|
  input: UpdateInvoiceInput
|};
export type updateInvoiceMutationResponse = {|
  +updateInvoice: ?{|
    +invoice: ?{|
      +invoiceReference: ?string,
      +name: ?string,
      +currency: ?string,
      +invoiceType: ?string,
      +status: ?string,
      +dueDay: ?number,
      +dueMonth: ?number,
      +dueYear: ?number,
      +createdDay: ?number,
      +createdMonth: ?number,
      +createdYear: ?number,
      +notes: ?string,
      +paymentStatus: ?string,
      +entries: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +quantity: ?number,
            +quantityText: ?string,
            +unitPrice: ?number,
            +discount: ?number,
            +tax: ?number,
            +description: ?string,
            +quickbooksItemOrService: ?string,
          |}
        |}>
      |},
      +payments: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +notes: ?string,
            +amount: ?number,
            +day: ?number,
            +month: ?number,
            +year: ?number,
            +createdAt: ?string,
            +createdBy: ?{|
              +fullName: ?string
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type updateInvoiceMutation = {|
  variables: updateInvoiceMutationVariables,
  response: updateInvoiceMutationResponse,
|};
*/


/*
mutation updateInvoiceMutation(
  $input: UpdateInvoiceInput!
) {
  updateInvoice(input: $input) {
    invoice {
      invoiceReference
      name
      currency
      invoiceType
      status
      dueDay
      dueMonth
      dueYear
      createdDay
      createdMonth
      createdYear
      notes
      paymentStatus
      entries(first: 100000000) {
        edges {
          node {
            id
            name
            quantity
            quantityText
            unitPrice
            discount
            tax
            description
            quickbooksItemOrService
          }
        }
      }
      payments(first: 100000000) {
        edges {
          node {
            id
            notes
            amount
            day
            month
            year
            createdAt
            createdBy {
              fullName
              id
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateInvoiceInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceReference",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDay",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueMonth",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueYear",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDay",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdMonth",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdYear",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentStatus",
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000000
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": (v15/*: any*/),
  "concreteType": "InvoiceEntryTypeConnection",
  "kind": "LinkedField",
  "name": "entries",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceEntryTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvoiceEntryType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v16/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantityText",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unitPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quickbooksItemOrService",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "entries(first:100000000)"
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateInvoicePayload",
        "kind": "LinkedField",
        "name": "updateInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceType",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "InvoicePaymentTypeConnection",
                "kind": "LinkedField",
                "name": "payments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoicePaymentTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoicePaymentType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v13/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Person",
                            "kind": "LinkedField",
                            "name": "createdBy",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "payments(first:100000000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateInvoicePayload",
        "kind": "LinkedField",
        "name": "updateInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceType",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "InvoicePaymentTypeConnection",
                "kind": "LinkedField",
                "name": "payments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoicePaymentTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoicePaymentType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v13/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Person",
                            "kind": "LinkedField",
                            "name": "createdBy",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/),
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "payments(first:100000000)"
              },
              (v16/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation updateInvoiceMutation(\n  $input: UpdateInvoiceInput!\n) {\n  updateInvoice(input: $input) {\n    invoice {\n      invoiceReference\n      name\n      currency\n      invoiceType\n      status\n      dueDay\n      dueMonth\n      dueYear\n      createdDay\n      createdMonth\n      createdYear\n      notes\n      paymentStatus\n      entries(first: 100000000) {\n        edges {\n          node {\n            id\n            name\n            quantity\n            quantityText\n            unitPrice\n            discount\n            tax\n            description\n            quickbooksItemOrService\n          }\n        }\n      }\n      payments(first: 100000000) {\n        edges {\n          node {\n            id\n            notes\n            amount\n            day\n            month\n            year\n            createdAt\n            createdBy {\n              fullName\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '72cd498206bd0776a1c2449df37ef121';

module.exports = node;
