import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation AcceptTermsMutation($input: AcceptTermsInput!) {
		acceptTerms(input: $input) {
			viewer {
				actualPersonId
				hasAcceptedTerms
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {...input, csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId()}},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
