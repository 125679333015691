import { graphql } from 'react-relay';
import Util from '../../../forecast-app/shared/util/util';
import { trackAndCommitMutation } from '../../../tracking/amplitude/TrackMutation';
import { trackEvent } from '../../../tracking/amplitude/TrackingV2';
const mutation = graphql `
	mutation SaveReportAsMutation($input: SaveReportAsInput!) {
		saveReportAs(input: $input) {
			savedReport {
				id
				updatedAt
				updatedBy {
					id
					fullName
				}
				type
				name
				person {
					id
					active
					email
					fullName
					profilePictureId
				}
				groupingOne
				groupingTwo
				groupingThree
				groupingFour
				eyeApplied
				filterApplied
				filters {
					field
					value
					operator
				}
				startDate
				endDate
				periodType
				periodDate
				reportService
				privateAccess
				state
			}
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    trackEvent('Standard Report', 'Saved As');
    return trackAndCommitMutation(environment, {
        mutation,
        variables,
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
