import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation replacePlaceholderMutation($input: ReplacePlaceholderInput!) {
		replacePlaceholder(input: $input) {
			placeholderId
			deletePlaceholder
			deletedPlaceholderAllocationIds
			allocations {
				node {
					id
					startYear
					startMonth
					startDay
					endYear
					endMonth
					endDay
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					description
					isProjectGroupAllocation
					projectGroupId
					projectGroupName
					projectGroupCompanyProjectGroupId
					projectGroupColor
					person {
						id
					}
					project {
						id
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Placeholder Replaced');

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
