import { commitMutation, graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
import { omit } from 'lodash';
const mutation = graphql `
	mutation DeleteProjectBillToMutation($input: DeleteProjectBillToInput!) {
		deleteProjectBillTo(input: $input) {
			deletedProjectBillToId
		}
	}
`;
function getConfigs(input) {
    return [
        {
            type: 'RANGE_DELETE',
            parentID: input.projectId,
            connectionKeys: [
                {
                    key: 'Project_projectBillTos',
                },
            ],
            pathToConnection: ['project', 'projectBillTos'],
            deletedIDFieldName: 'deletedProjectBillToId',
        },
    ];
}
function getOptimisticResponse(input) {
    return {
        deleteProjectBillTo: {
            deletedProjectBillToId: input.projectBillToId,
        },
    };
}
function commit(environment, input, onSuccess, onError) {
    trackEvent('Project Bill To Entity', 'Deleted', input);
    const cleanedInput = omit(input, ['projectId']);
    const variables = {
        input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, cleanedInput),
    };
    return commitMutation(environment, {
        mutation,
        variables,
        configs: getConfigs(input),
        optimisticResponse: getOptimisticResponse(input),
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
