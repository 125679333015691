/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateCostPeriodInput = {|
  startDay?: ?number,
  startMonth?: ?number,
  startYear?: ?number,
  cost?: ?number,
  personId: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createCostPeriodMutationModernMutationVariables = {|
  input: CreateCostPeriodInput
|};
export type createCostPeriodMutationModernMutationResponse = {|
  +createCostPeriod: ?{|
    +costPeriod: ?{|
      +node: ?{|
        +id: string,
        +startDay: ?number,
        +startMonth: ?number,
        +startYear: ?number,
        +cost: ?number,
      |}
    |}
  |}
|};
export type createCostPeriodMutationModernMutation = {|
  variables: createCostPeriodMutationModernMutationVariables,
  response: createCostPeriodMutationModernMutationResponse,
|};
*/


/*
mutation createCostPeriodMutationModernMutation(
  $input: CreateCostPeriodInput!
) {
  createCostPeriod(input: $input) {
    costPeriod {
      node {
        id
        startDay
        startMonth
        startYear
        cost
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateCostPeriodInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCostPeriodPayload",
    "kind": "LinkedField",
    "name": "createCostPeriod",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CostPeriodTypeEdge",
        "kind": "LinkedField",
        "name": "costPeriod",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CostPeriod",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cost",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createCostPeriodMutationModernMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createCostPeriodMutationModernMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createCostPeriodMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation createCostPeriodMutationModernMutation(\n  $input: CreateCostPeriodInput!\n) {\n  createCostPeriod(input: $input) {\n    costPeriod {\n      node {\n        id\n        startDay\n        startMonth\n        startYear\n        cost\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '056cb97e0617cd5647fa84c7c80beace';

module.exports = node;
