import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../css_variables';

export default styled.div`
	width: 580px;

	.tabs {
		display: flex;
		button {
			height: 30px;
			flex: 1;
		}
	}

	.parent-dropdown-container {
		.drop-down-v2 {
			width: 100%;
			margin-top: 16px;
		}
	}

	.person-date-row,
	.total-percentage-row,
	.task-name-row,
	.task-data-row {
		display: flex;
		flex-direction: row;
		margin-top: 24px;
	}

	.person-date-row {
		.drop-down-v2 {
			flex: 1 0 auto;
		}
		.date-range-picker-wrapper-new,
		.fixed-range-picker-wrapper-new {
			margin-left: 8px;
			flex: 0 0 auto;
		}
	}
	.task-name-row {
		.input-container-v2 {
			flex: 1;
		}
	}
	.task-data-row {
		display: flex;
		flex-direction: row;
		margin-top: 24px;
		height: 66px;
		.input-container-v2 {
			margin-left: 8px;
			flex-shrink: 0;
			width: 120px;
		}
		.person-dropdown-container {
			flex-grow: 1;
		}
		> div:not(:first-child) {
			margin-left: 8px;
		}
	}

	.distribution-button-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 16px;
		flex-shrink: 0;
		font-size: 12px;
		font-weight: 600;
		cursor: pointer;
		height: 38px;
		align-self: flex-end;

		.expand-icon {
			margin-left: 5px;
		}
	}

	.total-percentage-row {
		.warning {
			font-size: 12px;
			padding: 10px 0;
			color: red;
			max-width: 254px;
			margin-left: 10px;
		}

		.input-allocation-container,
		.input-total-container,
		.percentage-input-container {
			width: 150px;
		}

		.input-total-container,
		.percentage-input-container {
			margin-left: 10px;
		}
	}
	.allocation-type-selector-container {
		margin-top: 24px;
	}
	.distribution-row {
		margin-top: 12px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.input-container-v2 {
			width: 78px;
		}
		> div:not(:first-child) {
			margin-left: 8px;
		}
	}
	.time-registration-row {
		margin-top: 24px;

		.time-registration-label {
			height: 11px;
			font-size: 9px;
			font-weight: 500;
			line-height: 1.22;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin-bottom: 5px;
			white-space: nowrap;
			font-stretch: normal;
			font-style: normal;
			color: ${CSS_CONSTANTS.v2_text_light_gray};
		}

		.time-registration-description {
			font-size: 13px;
			margin-bottom: 16px;
		}

		.time-registration-choice-yes,
		.time-registration-choice-no {
			font-size: 13px;
			margin-bottom: 10px;

			.radio-button-wrapper {
				align-items: center;
			}
		}
		.info {
			font-size: 13px;
			font-weight: 500;
			font-style: italic;

			&.warning {
				color: ${CSS_CONSTANTS.v2_workflow_task_warning_red};
			}
		}
	}
	.notes-container {
		margin-top: 24px;
	}

	.checkbox-row-allocate-modal {
		margin-top: 20px;
	}
`;
