import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateExpenseItemMutation($input: UpdateExpenseItemInput!) {
		updateExpenseItem(input: $input) {
			expenseItem {
				node {
					name
					approved
					expenseYear
					expenseMonth
					expenseDay
					billable
					partOfFixedPrice
					price
					costBaseCurrency
					priceBaseCurrency
					cost
					quantity
					name
					planned
					createdAt
					invoiced
					person {
						id
						fullName
						profilePictureId
						profilePictureDefaultId
					}
					category {
						id
					}
					phase {
						id
					}
					project {
						id
						client {
							id
							name
						}
					}
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const obj = {
		id: input.id,
	};
	if (input.approved !== undefined) {
		obj.approved = input.approved;
	}
	if (input.planned !== undefined) {
		obj.planned = input.planned;
	}
	if (input.billable !== undefined) {
		obj.billable = input.billable;
	}
	if (input.partOfFixedPrice !== undefined) {
		obj.partOfFixedPrice = input.partOfFixedPrice;
	}
	return {
		updateExpenseItem: {
			expenseItem: {node: obj},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	Util.localStorageDeleteFincancialMap();
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id', 'projectId', 'companyProjectId'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Expense Item Updated', {_Changed: changes});

	const cleanedVariables = omit(input, ['viewerId', 'actualPersonId']);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
