/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateRateCardInput = {|
  name?: ?string,
  currency?: ?string,
  rates?: ?$ReadOnlyArray<?RateInput>,
  disabledRoles?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type RateInput = {|
  id?: ?number,
  rate?: ?number,
  startDate?: ?string,
  defaultRate?: ?boolean,
  roleId?: ?number,
  rateCardId?: ?number,
|};
export type createRateCardMutationVariables = {|
  input: CreateRateCardInput
|};
export type createRateCardMutationResponse = {|
  +createRateCard: ?{|
    +rateCard: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +defaultRate: ?number,
        +parentRateCardId: ?string,
        +currency: ?string,
        +disabledRoles: ?$ReadOnlyArray<?{|
          +id: string
        |}>,
        +rates: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +rate: ?number,
              +defaultRate: ?boolean,
              +startDate: ?string,
              +role: ?{|
                +id: string
              |},
            |}
          |}>
        |},
      |}
    |},
    +company: ?{|
      +exchangeRates: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +isUsed: ?boolean,
          |}
        |}>
      |}
    |},
  |}
|};
export type createRateCardMutation = {|
  variables: createRateCardMutationVariables,
  response: createRateCardMutationResponse,
|};
*/


/*
mutation createRateCardMutation(
  $input: CreateRateCardInput!
) {
  createRateCard(input: $input) {
    rateCard {
      node {
        id
        name
        defaultRate
        parentRateCardId
        currency
        disabledRoles {
          id
        }
        rates(first: 10000) {
          edges {
            node {
              id
              rate
              defaultRate
              startDate
              role {
                id
              }
            }
          }
        }
      }
    }
    company {
      exchangeRates(first: 100000) {
        edges {
          node {
            id
            isUsed
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateRateCardInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultRate",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "RateCardTypeEdge",
  "kind": "LinkedField",
  "name": "rateCard",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RateCard",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentRateCardId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "disabledRoles",
          "plural": true,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10000
            }
          ],
          "concreteType": "RateTypeConnection",
          "kind": "LinkedField",
          "name": "rates",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RateTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Rate",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rate",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Role",
                      "kind": "LinkedField",
                      "name": "role",
                      "plural": false,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "rates(first:10000)"
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100000
    }
  ],
  "concreteType": "ExchangeRateTypeConnection",
  "kind": "LinkedField",
  "name": "exchangeRates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExchangeRateTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExchangeRate",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isUsed",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "exchangeRates(first:100000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createRateCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateRateCardPayload",
        "kind": "LinkedField",
        "name": "createRateCard",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createRateCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateRateCardPayload",
        "kind": "LinkedField",
        "name": "createRateCard",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createRateCardMutation",
    "operationKind": "mutation",
    "text": "mutation createRateCardMutation(\n  $input: CreateRateCardInput!\n) {\n  createRateCard(input: $input) {\n    rateCard {\n      node {\n        id\n        name\n        defaultRate\n        parentRateCardId\n        currency\n        disabledRoles {\n          id\n        }\n        rates(first: 10000) {\n          edges {\n            node {\n              id\n              rate\n              defaultRate\n              startDate\n              role {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    company {\n      exchangeRates(first: 100000) {\n        edges {\n          node {\n            id\n            isUsed\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd1c45d98aaefde611cfc1ca2050e91e8';

module.exports = node;
