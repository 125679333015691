/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFileInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  name?: ?string,
  taskId?: ?string,
  projectId?: ?string,
  folderId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateFileMutationModernMutationVariables = {|
  input: UpdateFileInput
|};
export type updateFileMutationModernMutationResponse = {|
  +updateFile: ?{|
    +files: ?$ReadOnlyArray<?{|
      +name: ?string,
      +folder: ?{|
        +id: string,
        +name: ?string,
      |},
      +task: ?{|
        +id: string,
        +name: ?string,
        +companyTaskId: ?number,
      |},
    |}>
  |}
|};
export type updateFileMutationModernMutation = {|
  variables: updateFileMutationModernMutationVariables,
  response: updateFileMutationModernMutationResponse,
|};
*/


/*
mutation updateFileMutationModernMutation(
  $input: UpdateFileInput!
) {
  updateFile(input: $input) {
    files {
      name
      folder {
        id
        name
      }
      task {
        id
        name
        companyTaskId
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFileInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Folder",
  "kind": "LinkedField",
  "name": "folder",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Task",
  "kind": "LinkedField",
  "name": "task",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyTaskId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateFileMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFilePayload",
        "kind": "LinkedField",
        "name": "updateFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "files",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateFileMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFilePayload",
        "kind": "LinkedField",
        "name": "updateFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "files",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateFileMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation updateFileMutationModernMutation(\n  $input: UpdateFileInput!\n) {\n  updateFile(input: $input) {\n    files {\n      name\n      folder {\n        id\n        name\n      }\n      task {\n        id\n        name\n        companyTaskId\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6b34ae75bdc3c8c03a0106f3b927f7fa';

module.exports = node;
