import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation transferPlaceholderMutation($input: TransferPlaceholderInput!) {
		transferPlaceholder(input: $input) {
			placeholderAllocation {
				id
				startDate
				endDate
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				description
				placeholder {
					id
				}
			}
			personAllocation {
				id
				startDay
				startMonth
				startYear
				endDay
				endMonth
				endYear
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				description
				project {
					id
				}
				projectGroupId
				projectGroupColor
				person {
					id
				}
			}
			deletedPlaceholderAllocationId
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Placeholder Allocation Transfer');

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
