import React from 'react';
import PropTypes from 'prop-types';
import {ProjectNameTooltipContainer, ProjectNameTooltipItem} from "./tooltip_styled";
import DeprecatedProjectIndicatorJS from '../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

export const ProjectNameTooltip = ({projects}) => {
    return (
        <ProjectNameTooltipContainer>
            {projects.map(project => {
                return <ProjectNameTooltipItem>
                    <DeprecatedProjectIndicatorJS project={project} disableLink={true} showProjectName={true}/>
                </ProjectNameTooltipItem>
            })}
        </ProjectNameTooltipContainer>
    );
};

ProjectNameTooltip.propTypes = {
    projects: PropTypes.arrayOf(PropTypes.shape({
        projectColor: PropTypes.string.isRequired,
        companyProjectId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }))
};