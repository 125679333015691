/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteAutojoinDomainInput = {|
  id: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteAutojoinDomainMutationVariables = {|
  input: DeleteAutojoinDomainInput
|};
export type deleteAutojoinDomainMutationResponse = {|
  +deleteAutojoinDomain: ?{|
    +deletedAutojoinDomainId: ?string
  |}
|};
export type deleteAutojoinDomainMutation = {|
  variables: deleteAutojoinDomainMutationVariables,
  response: deleteAutojoinDomainMutationResponse,
|};
*/


/*
mutation deleteAutojoinDomainMutation(
  $input: DeleteAutojoinDomainInput!
) {
  deleteAutojoinDomain(input: $input) {
    deletedAutojoinDomainId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteAutojoinDomainInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteAutojoinDomainPayload",
    "kind": "LinkedField",
    "name": "deleteAutojoinDomain",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedAutojoinDomainId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteAutojoinDomainMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteAutojoinDomainMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteAutojoinDomainMutation",
    "operationKind": "mutation",
    "text": "mutation deleteAutojoinDomainMutation(\n  $input: DeleteAutojoinDomainInput!\n) {\n  deleteAutojoinDomain(input: $input) {\n    deletedAutojoinDomainId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '23f60e8e9adb100a4aaea231227b0c96';

module.exports = node;
