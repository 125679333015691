import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation ImportHubspotCompaniesMutation($input: ImportHubspotCompaniesInput!) {
		importHubspotCompanies(input: $input) {
			clients {
				cursor
				node {
					id
					name
					street
					zip
					city
					vat
					country
					notes
					logoId
					logoDefaultId
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_clients',
					rangeBehavior: 'append',
					filters: [],
				},
			],
			edgeName: 'client',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Bulk Clients Created');
	const cleanedInput = omit(input, ['companyId']);

	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
