import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateRetainerPeriodMutation($input: UpdateRetainerPeriodInput!) {
		updateRetainerPeriod(input: $input) {
			errors
			retainerPeriod {
				id
				name
				startYear
				startMonth
				startDay
				endYear
				endMonth
				endDay
				available
				periodLength
				periodPeriodicity
				periodPriceAmount
				periodHoursAmount
				periodBudgetType
				periodSettingIgnoreForBilling
				periodSettingSubtractValue
				periodSettingRollValue
				periodSettingAddExpenses
				periodDifferencePriceAmount
				periodDifferenceHoursAmount
				sharedPeriodDifferenceHoursAmount
				sharedPeriodDifferencePriceAmount
				ignoredRolloverHours
				ignoredRolloverPrice
				periodLocked
				periodLockedTime
				periodDifferenceSplit
			}
			project {
				retainerPeriods(first: 100000) {
					edges {
						node {
							id
							name
							startYear
							startMonth
							startDay
							endYear
							endMonth
							endDay
							available
							periodLength
							periodPeriodicity
							periodPriceAmount
							periodHoursAmount
							periodBudgetType
							periodSettingIgnoreForBilling
							periodSettingSubtractValue
							periodSettingRollValue
							periodSettingAddExpenses
							periodDifferencePriceAmount
							periodDifferenceHoursAmount
							sharedPeriodDifferenceHoursAmount
							sharedPeriodDifferencePriceAmount
							ignoredRolloverHours
							ignoredRolloverPrice
							periodLocked
							periodLockedTime
							periodDifferenceSplit
							retainerPeriodRollovers(first: 100000) {
								edges {
									node {
										id
										periodFrom {
											id
										}
										periodTo {
											id
											name
										}
										amount
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const obj = {id: input.id};
	if (input.name) {
		obj.name = input.name;
	}
	if (input.endYear) {
		obj.endYear = input.endYear;
		obj.endMonth = input.endMonth;
		obj.endDay = input.endDay;
	}
	if (input.startYear) {
		obj.startYear = input.startYear;
		obj.startMonth = input.startMonth;
		obj.startDay = input.startDay;
	}
	return {
		retainerPeriod: obj,
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		const val = input[key];
		if (['id', 'projectId'].includes(key) || val === undefined) {
			continue;
		}
		changes.push({key, val});
	}
	tracking.trackEvent('Retainer period updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
