import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
import {trackEvent} from '../tracking/amplitude/TrackingV2';

const mutation = graphql`
	mutation bulkDeleteSavedReportMutation($input: BulkDeleteSavedReportInput!) {
		bulkDeleteSavedReport(input: $input) {
			deletedReportIds
			company {
				id
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_allSavedReports',
				},
			],
			pathToConnection: ['company', 'allSavedReports'],
			deletedIDFieldName: 'deletedReportIds',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	trackEvent('Standard Report', 'Bulk Delete', {deletedCount: input?.reportServiceIds?.length});

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				reportServiceIds: input.reportServiceIds,
				savedReportIds: input.savedReportIds,
				companyId: input.companyId,
			},
		},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
