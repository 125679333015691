import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation mergeLabelsMutation($input: MergeLabelsInput!) {
		mergeLabels(input: $input) {
			label {
				node {
					id
					name
					taskCount
					projectCount
					peopleCount
					category {
						id
						allowOnTasks
						allowOnProjects
						allowOnPeople
					}
					...labelCategoryRow_labels
				}
			}
			deletedLabelIds
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentName: 'company',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_labels',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'label',
		},
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_labels',
				},
			],
			pathToConnection: ['company', 'labels'],
			deletedIDFieldName: 'deletedLabelIds',
		},
	];
}

function getOptimisticResponse(input) {
	const {name, color, categoryId} = input;

	return {
		mergeLabels: {
			label: {
				node: {
					id: undefined,
					name,
					color,
					occurrenceCount: 0,
					taskCount: 0,
					projectCount: 0,
					peopleCount: 0,
					category: categoryId
						? {
								id: categoryId,
								allowOnTasks: undefined,
								allowOnProjects: undefined,
								allowOnPeople: undefined,
						  }
						: undefined,
				},
			},
			deletedLabelIds: input.labelIds,
		},
	};
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Labels Merged');
	const cleanedInput = omit(input, ['companyId']);
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted,
		onError,
	});
}

export default {commit};
