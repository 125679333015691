import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation activateJiraServerMutation($input: ActivateJiraServerInput!) {
		activateJiraServer(input: $input) {
			company {
				jiraServerConsumerKey
				jiraServerPublicRsaKey
				jiraServerState
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Jira Activated');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId()}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
