import { commitMutation, graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
const mutation = graphql `
	mutation UpdateTimeRegistrationGranularityMutation($input: UpdateTimeRegistrationGranularityInput!) {
		updateTimeRegistrationGranularity(input: $input) {
			timeRegistrationSettings {
				granularity
				allowDoneTasks
				allowProjects
				allowInternalTime
				allowBillableSplit
				allowRoles
				requireTaskAssignment
				requireNote
			}
		}
	}
`;
function getOptimisticResponse(input) {
    return {
        updateTimeRegistrationGranularity: {
            timeRegistrationSettings: {
                id: input.id,
                granularity: input.granularity,
                allowDoneTasks: undefined,
                allowProjects: undefined,
                allowInternalTime: undefined,
                allowBillableSplit: undefined,
                allowRoles: undefined,
                requireTaskAssignment: undefined,
                requireNote: undefined,
            },
        },
    };
}
function commit(environment, input, onSuccess, onError) {
    trackEvent('Time Registration Settings', 'Increment Updated', input);
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return commitMutation(environment, {
        mutation,
        variables,
        optimisticResponse: getOptimisticResponse(input),
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
