import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {omit} from 'lodash';
import {ConnectionHandler} from 'relay-runtime';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation duplicateTaskMutation($input: DuplicateTaskInput!) {
		duplicateTask(input: $input) {
			errors
			taskEdges {
				node {
					id
					readOnly {
						isReadOnly
					}
					fullAccessToProject
					userCanDeleteTask
					userCantDeleteTaskReason
					hasInvoicedTime
					hasLockedTime
					hasTimeRegistrations
					approved
					name
					description
					sortOrder
					done
					billable
					blocked
					progress
					bug
					latestUiUpdateAt
					startFrom
					deadlineFrom
					startYear
					startMonth
					startDay
					deadlineDay
					deadlineMonth
					deadlineYear
					estimateForecast
					estimateForecastPrice
					currentPrice
					allTotalTimeAndExpensesAtCompletion
					companyTaskId
					timeLeft
					deadlineFrom
					canStart
					canBeSetToDone
					approved
					highPriority
					hasDependency
					jiraId
					subTaskCount
					favoured
					parentTaskId
					phase {
						id
						name
						startYear
						startMonth
						startDay
						deadlineDay
						deadlineMonth
						deadlineYear
					}
					role {
						id
						name
					}
					sprint {
						id
					}
					statusColumnV2 {
						id
						name
						category
					}
					parentTask {
						id
						name
					}
					project {
						id
						billable
						estimationUnit
						minutesPerEstimationPoint
						projectEndYear
						projectEndMonth
						projectEndDay
						name
						completion
						remaining
						forecast
						isJiraProject
						harvestProject {
							id
						}
					}
					taskLabels {
						id
						label {
							id
							name
							color
						}
					}
					timeRegistrations(first: 10000) @connection(key: "Task_timeRegistrations") {
						edges {
							node {
								id
							}
						}
					}
					assignedPersons {
						id
						firstName
						lastName
						email
						profilePictureId
						profilePictureDefaultId
						permissions
					}
					thisTaskDependsOn(first: 1000) @connection(key: "Task_thisTaskDependsOn") {
						edges {
							node {
								id
							}
						}
					}
					dependsOnThisTask(first: 1000) @connection(key: "Task_dependsOnThisTask") {
						edges {
							node {
								id
								taskDependsOnThis {
									id
									statusColumnV2 {
										category
									}
								}
							}
						}
					}
				}
			}
			project {
				tasks(first: 100000) {
					edges {
						node {
							id
							sortOrder
							timeRegistrations(first: 10000) {
								edges {
									node {
										id
									}
								}
							}
						}
					}
				}
			}
			viewer {
				tasks(first: 100000) {
					edges {
						node {
							id
							sortOrder
						}
					}
				}
			}
		}
	}
`;

function updater(store, input, updatedEdge) {
	if (input.projectId) {
		const proxy = store.get(input.projectId);
		const connection = ConnectionHandler.getConnection(proxy, 'Project_tasks');
		if (connection) {
			ConnectionHandler.insertEdgeAfter(connection, updatedEdge);
		}

		if (!input.sprintId) {
			const connection = ConnectionHandler.getConnection(proxy, 'Project_tasks', {backlog: true});
			if (connection) {
				ConnectionHandler.insertEdgeAfter(connection, updatedEdge);
			}
		}
	}
	if (input.viewerId) {
		const proxy = store.get(input.viewerId);
		const connection = ConnectionHandler.getConnection(proxy, 'Viewer_tasks');
		if (connection) {
			ConnectionHandler.insertEdgeAfter(connection, updatedEdge);
		}
	}
	if (input.filter) {
		const proxy = store.get(input.companyId);
		const connection = ConnectionHandler.getConnection(proxy, 'Company_allTasks', {
			filterColumnId: input.filter.toString(),
		});
		if (connection) {
			ConnectionHandler.insertEdgeAfter(connection, updatedEdge);
		}
	}
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Card Duplicated');
	const cleanedInput = omit(input, ['projectId', 'viewerId', 'viewer', 'filter', 'companyId']);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		updater: store => {
			const payload = store.getRootField('duplicateTask');
			const updatedEdges = payload.getLinkedRecords('taskEdges');
			updatedEdges?.forEach(edge => updater(store, input, edge));
		},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
