/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ExportInvoiceQuickbooksInput = {|
  invoiceId?: ?string,
  customerId?: ?string,
  taxCodeId?: ?string,
  blankReference?: ?boolean,
  exportNames?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type exportInvoiceQuickbooksMutationVariables = {|
  input: ExportInvoiceQuickbooksInput
|};
export type exportInvoiceQuickbooksMutationResponse = {|
  +exportInvoiceQuickbooks: ?{|
    +invoice: ?{|
      +quickbooksId: ?number
    |}
  |}
|};
export type exportInvoiceQuickbooksMutation = {|
  variables: exportInvoiceQuickbooksMutationVariables,
  response: exportInvoiceQuickbooksMutationResponse,
|};
*/


/*
mutation exportInvoiceQuickbooksMutation(
  $input: ExportInvoiceQuickbooksInput!
) {
  exportInvoiceQuickbooks(input: $input) {
    invoice {
      quickbooksId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ExportInvoiceQuickbooksInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quickbooksId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "exportInvoiceQuickbooksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExportInvoiceQuickbooksPayload",
        "kind": "LinkedField",
        "name": "exportInvoiceQuickbooks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceType",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "exportInvoiceQuickbooksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExportInvoiceQuickbooksPayload",
        "kind": "LinkedField",
        "name": "exportInvoiceQuickbooks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceType",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "exportInvoiceQuickbooksMutation",
    "operationKind": "mutation",
    "text": "mutation exportInvoiceQuickbooksMutation(\n  $input: ExportInvoiceQuickbooksInput!\n) {\n  exportInvoiceQuickbooks(input: $input) {\n    invoice {\n      quickbooksId\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b78709a00ab67efb92c01a72f52fb991';

module.exports = node;
