import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation revokeGoogleCalendarTokenMutation($input: RevokeGoogleCalendarTokenInput!) {
		revokeGoogleCalendarToken(input: $input) {
			viewer {
				calendarIntegrations {
					googleCalendar {
						calendarUser {
							name
							email
							picture
						}
						calendarViewPrivateEvents
						calendarTokenError
						enabledCalendars
						calendarFetchEnabled
						calendars {
							edges {
								node {
									id
									name
									description
									backgroundColor
									foregroundColor
								}
							}
						}
					}
				}
			}
			errors
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
