/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProgramStageInput = {|
  id?: ?string,
  stageId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateProgramStageMutationVariables = {|
  input: UpdateProgramStageInput
|};
export type updateProgramStageMutationResponse = {|
  +updateProgramStage: ?{|
    +program: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +description: ?string,
        +prefix: ?string,
        +color: ?string,
        +startDate: ?string,
        +endDate: ?string,
        +stage: ?{|
          +id: string,
          +name: ?string,
          +type: ?number,
        |},
      |}
    |}
  |}
|};
export type updateProgramStageMutation = {|
  variables: updateProgramStageMutationVariables,
  response: updateProgramStageMutationResponse,
|};
*/


/*
mutation updateProgramStageMutation(
  $input: UpdateProgramStageInput!
) {
  updateProgramStage(input: $input) {
    program {
      node {
        id
        name
        description
        prefix
        color
        startDate
        endDate
        stage {
          id
          name
          type
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProgramStageInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateProgramStagePayload",
    "kind": "LinkedField",
    "name": "updateProgramStage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectServiceProgramTypeEdge",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProgramStageType",
                "kind": "LinkedField",
                "name": "stage",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateProgramStageMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateProgramStageMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateProgramStageMutation",
    "operationKind": "mutation",
    "text": "mutation updateProgramStageMutation(\n  $input: UpdateProgramStageInput!\n) {\n  updateProgramStage(input: $input) {\n    program {\n      node {\n        id\n        name\n        description\n        prefix\n        color\n        startDate\n        endDate\n        stage {\n          id\n          name\n          type\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b92d34efd38274c67ab762571f80ee4d';

module.exports = node;
