/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteRateCardInput = {|
  id: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteRateCardMutationModernMutationVariables = {|
  input: DeleteRateCardInput
|};
export type deleteRateCardMutationModernMutationResponse = {|
  +deleteRateCard: ?{|
    +deletedRateCardId: ?string,
    +company: ?{|
      +exchangeRates: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +isUsed: ?boolean,
          |}
        |}>
      |}
    |},
  |}
|};
export type deleteRateCardMutationModernMutation = {|
  variables: deleteRateCardMutationModernMutationVariables,
  response: deleteRateCardMutationModernMutationResponse,
|};
*/


/*
mutation deleteRateCardMutationModernMutation(
  $input: DeleteRateCardInput!
) {
  deleteRateCard(input: $input) {
    deletedRateCardId
    company {
      exchangeRates(first: 100000) {
        edges {
          node {
            id
            isUsed
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteRateCardInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedRateCardId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 100000
    }
  ],
  "concreteType": "ExchangeRateTypeConnection",
  "kind": "LinkedField",
  "name": "exchangeRates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExchangeRateTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExchangeRate",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isUsed",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "exchangeRates(first:100000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteRateCardMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteRateCardPayload",
        "kind": "LinkedField",
        "name": "deleteRateCard",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteRateCardMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteRateCardPayload",
        "kind": "LinkedField",
        "name": "deleteRateCard",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteRateCardMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation deleteRateCardMutationModernMutation(\n  $input: DeleteRateCardInput!\n) {\n  deleteRateCard(input: $input) {\n    deletedRateCardId\n    company {\n      exchangeRates(first: 100000) {\n        edges {\n          node {\n            id\n            isUsed\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a7e53db61c5d340d56e010421c8bb492';

module.exports = node;
