/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTasksBulkInput = {|
  tasks?: ?$ReadOnlyArray<?TaskBulk>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type TaskBulk = {|
  taskId?: ?string,
  roleId?: ?string,
  estimateLow?: ?number,
  estimateHigh?: ?number,
  estimateForecast?: ?number,
  personIds?: ?$ReadOnlyArray<?string>,
  labelIds?: ?$ReadOnlyArray<?string>,
|};
export type updateTasksBulkSchedulingMutationVariables = {|
  input: UpdateTasksBulkInput
|};
export type updateTasksBulkSchedulingMutationResponse = {|
  +updateTasksBulk: ?{|
    +tasks: ?$ReadOnlyArray<?{|
      +assignedPersons: ?$ReadOnlyArray<?{|
        +id: string,
        +firstName: ?string,
        +lastName: ?string,
        +profilePictureId: ?string,
      |}>,
      +billable: ?boolean,
      +blocked: ?boolean,
      +bug: ?boolean,
      +companyTaskId: ?number,
      +deadlineDay: ?number,
      +deadlineMonth: ?number,
      +deadlineYear: ?number,
      +deadlineFrom: ?string,
      +startFrom: ?string,
      +done: ?boolean,
      +estimateForecast: ?number,
      +estimateForecastMinutes: ?number,
      +highPriority: ?boolean,
      +estimateForecastPrice: ?number,
      +id: string,
      +role: ?{|
        +id: string
      |},
      +phase: ?{|
        +id: string
      |},
      +statusColumnV2: ?{|
        +id: string
      |},
      +progress: ?number,
      +startDay: ?number,
      +startMonth: ?number,
      +startYear: ?number,
      +timeLeft: ?number,
      +name: ?string,
      +project: ?{|
        +id: string,
        +completion: ?number,
        +remaining: ?number,
        +forecast: ?number,
      |},
    |}>
  |}
|};
export type updateTasksBulkSchedulingMutation = {|
  variables: updateTasksBulkSchedulingMutationVariables,
  response: updateTasksBulkSchedulingMutationResponse,
|};
*/


/*
mutation updateTasksBulkSchedulingMutation(
  $input: UpdateTasksBulkInput!
) {
  updateTasksBulk(input: $input) {
    tasks {
      assignedPersons {
        id
        firstName
        lastName
        profilePictureId
      }
      billable
      blocked
      bug
      companyTaskId
      deadlineDay
      deadlineMonth
      deadlineYear
      deadlineFrom
      startFrom
      done
      estimateForecast
      estimateForecastMinutes
      highPriority
      estimateForecastPrice
      id
      role {
        id
      }
      phase {
        id
      }
      statusColumnV2 {
        id
      }
      progress
      startDay
      startMonth
      startYear
      timeLeft
      name
      project {
        id
        completion
        remaining
        forecast
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTasksBulkInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTasksBulkPayload",
    "kind": "LinkedField",
    "name": "updateTasksBulk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "assignedPersons",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePictureId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blocked",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyTaskId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deadlineDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deadlineMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deadlineYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deadlineFrom",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startFrom",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "done",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "estimateForecast",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "estimateForecastMinutes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "highPriority",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "estimateForecastPrice",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhaseType",
            "kind": "LinkedField",
            "name": "phase",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StatusColumnV2",
            "kind": "LinkedField",
            "name": "statusColumnV2",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "progress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeLeft",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remaining",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "forecast",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateTasksBulkSchedulingMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateTasksBulkSchedulingMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateTasksBulkSchedulingMutation",
    "operationKind": "mutation",
    "text": "mutation updateTasksBulkSchedulingMutation(\n  $input: UpdateTasksBulkInput!\n) {\n  updateTasksBulk(input: $input) {\n    tasks {\n      assignedPersons {\n        id\n        firstName\n        lastName\n        profilePictureId\n      }\n      billable\n      blocked\n      bug\n      companyTaskId\n      deadlineDay\n      deadlineMonth\n      deadlineYear\n      deadlineFrom\n      startFrom\n      done\n      estimateForecast\n      estimateForecastMinutes\n      highPriority\n      estimateForecastPrice\n      id\n      role {\n        id\n      }\n      phase {\n        id\n      }\n      statusColumnV2 {\n        id\n      }\n      progress\n      startDay\n      startMonth\n      startYear\n      timeLeft\n      name\n      project {\n        id\n        completion\n        remaining\n        forecast\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ec46e5cde4f71921d03670a2ccdf9b9';

module.exports = node;
