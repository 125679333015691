import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation logoutMutation($input: LogoutInput!) {
		logout(input: $input) {
			errors
			viewer {
				actualPersonId
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Logout');
	const variables = {input: {}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
