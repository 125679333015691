import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteStatusColumnMutation($input: DeleteStatusColumnInput!) {
		deleteStatusColumn(input: $input) {
			deletedStatusColumnId
			errors
			project {
				id
				statusColumnsV2(first: 1000000) {
					edges {
						node {
							id
							name
							order
							category
							encourageTimeRegistration
							autoShowOnTimePage
							projectGroupStatusColumnId
						}
					}
				}
			}
			projectGroup {
				id
				projects(first: 1000000) {
					edges {
						node {
							statusColumnsV2(first: 1000000) {
								edges {
									node {
										id
										name
										order
										category
										encourageTimeRegistration
										autoShowOnTimePage
										projectGroupStatusColumnId
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(projectId) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: projectId,
			connectionKeys: [
				{
					key: 'Project_statusColumnsV2',
				},
			],
			pathToConnection: ['project', 'statusColumnsV2'],
			deletedIDFieldName: 'deletedStatusColumnId',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		deleteStatusColumn: {
			deletedStatusColumnId: input.id,
		},
	};
}
function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('StatusColumn Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input.projectId),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
