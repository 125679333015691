import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation UpdateInitialPlanProjectMutation($input: UpdateInitialPlanProjectInput!) {
		updateInitialPlanProject(input: $input) {
			project {
				id
				baselineTargetMinutes
				baselineTargetPrice
				baselineCost
				baselineProfit
				baselineWinChance
				projectStartYear
				projectStartMonth
				projectStartDay
				projectEndYear
				projectEndMonth
				projectEndDay
				baselineTarget
				baselineEstimationMinutesPerDay
				baselineAdjustPrice
				baselineAdjustPercentage
				budget
				rateCard {
					id
					currency
				}
				unassignedTaskHourlyCost
				taskCostCalculationType
				phases(first: 100000) @connection(key: "Project_phases", filters: []) {
					edges {
						...PhasesSection_phases
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function getOptimisticResponse(input) {
	const project = {id: input.id};
	if (input.baselineTarget !== undefined) {
		project.baselineTarget = input.baselineTarget;
	}
	if (input.baselineMinutesPerDay !== undefined) {
		project.baselineMinutesPerDay = input.baselineMinutesPerDay;
	}
	return {
		updateProject: {
			project: project,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id', 'project'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Project Updated', {_Changed: changes});

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
