/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeletePersonEmailInput = {|
  personId: number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deletePersonEmailMutationVariables = {|
  input: DeletePersonEmailInput
|};
export type deletePersonEmailMutationResponse = {|
  +DeletePersonEmail: ?{|
    +MissionControlPersonList: ?{|
      +id: string,
      +personId: ?number,
      +companyId: ?number,
      +companyName: ?string,
      +accountPlan: ?string,
      +firstName: ?string,
      +lastName: ?string,
      +email: ?string,
      +active: ?boolean,
    |}
  |}
|};
export type deletePersonEmailMutation = {|
  variables: deletePersonEmailMutationVariables,
  response: deletePersonEmailMutationResponse,
|};
*/


/*
mutation deletePersonEmailMutation(
  $input: DeletePersonEmailInput!
) {
  DeletePersonEmail(input: $input) {
    MissionControlPersonList {
      id
      personId
      companyId
      companyName
      accountPlan
      firstName
      lastName
      email
      active
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeletePersonEmailInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeletePersonEmailPayload",
    "kind": "LinkedField",
    "name": "DeletePersonEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MissionControlPersonListType",
        "kind": "LinkedField",
        "name": "MissionControlPersonList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "personId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountPlan",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deletePersonEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deletePersonEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deletePersonEmailMutation",
    "operationKind": "mutation",
    "text": "mutation deletePersonEmailMutation(\n  $input: DeletePersonEmailInput!\n) {\n  DeletePersonEmail(input: $input) {\n    MissionControlPersonList {\n      id\n      personId\n      companyId\n      companyName\n      accountPlan\n      firstName\n      lastName\n      email\n      active\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f931512391b09e6704985747c7fdb0b1';

module.exports = node;
