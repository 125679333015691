import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteRateCardMutationModernMutation($input: DeleteRateCardInput!) {
		deleteRateCard(input: $input) {
			deletedRateCardId
			company {
				exchangeRates(first: 100000) {
					edges {
						node {
							id
							isUsed
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_rateCards',
				},
			],
			pathToConnection: ['company', 'rateCards'],
			deletedIDFieldName: 'deletedRateCardId',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		deleteRateCard: {
			deletedRateCardId: input.id,
		},
	};
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Rate Card Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				id: input.id,
			},
		},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted,
		onError,
	});
}

export default {commit};
