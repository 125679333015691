/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeletePersonInput = {|
  id: string,
  clientId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deletePersonMutationVariables = {|
  input: DeletePersonInput
|};
export type deletePersonMutationResponse = {|
  +deletePerson: ?{|
    +deletedPersonId: ?string,
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type deletePersonMutation = {|
  variables: deletePersonMutationVariables,
  response: deletePersonMutationResponse,
|};
*/


/*
mutation deletePersonMutation(
  $input: DeletePersonInput!
) {
  deletePerson(input: $input) {
    deletedPersonId
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeletePersonInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeletePersonPayload",
    "kind": "LinkedField",
    "name": "deletePerson",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedPersonId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deletePersonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deletePersonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deletePersonMutation",
    "operationKind": "mutation",
    "text": "mutation deletePersonMutation(\n  $input: DeletePersonInput!\n) {\n  deletePerson(input: $input) {\n    deletedPersonId\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ab5f9c0f989c5a55384cef0bf24002f';

module.exports = node;
