import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation setFirstTimePasswordMutation($input: SetFirstTimePasswordInput!) {
		setFirstTimePassword(input: $input) {
			personId
			companyId
			companyTier
			csrfToken
			profiles {
				id
				name
			}
			success
			errors
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input};
	return commitMutation(environment, {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
