/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteTimeRegInput = {|
  id?: ?string,
  taskId?: ?string,
  projectId?: ?string,
  idleTimeId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteTimeRegistrationMutationModernMutationVariables = {|
  input: DeleteTimeRegInput
|};
export type deleteTimeRegistrationMutationModernMutationResponse = {|
  +deleteTimeReg: ?{|
    +deletedTimeRegId: ?string,
    +task: ?{|
      +timeLeft: ?number,
      +timeLeftMinutesWithoutFutureTimeRegs: ?number,
      +totalMinutesRegistered: ?number,
      +progress: ?number,
      +project: ?{|
        +completion: ?number,
        +remaining: ?number,
        +forecast: ?number,
      |},
    |},
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type deleteTimeRegistrationMutationModernMutation = {|
  variables: deleteTimeRegistrationMutationModernMutationVariables,
  response: deleteTimeRegistrationMutationModernMutationResponse,
|};
*/


/*
mutation deleteTimeRegistrationMutationModernMutation(
  $input: DeleteTimeRegInput!
) {
  deleteTimeReg(input: $input) {
    deletedTimeRegId
    task {
      timeLeft
      timeLeftMinutesWithoutFutureTimeRegs
      totalMinutesRegistered
      progress
      project {
        completion
        remaining
        forecast
        id
      }
      id
    }
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteTimeRegInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedTimeRegId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLeft",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLeftMinutesWithoutFutureTimeRegs",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalMinutesRegistered",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completion",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remaining",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "forecast",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteTimeRegistrationMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteTimeRegPayload",
        "kind": "LinkedField",
        "name": "deleteTimeReg",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteTimeRegistrationMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteTimeRegPayload",
        "kind": "LinkedField",
        "name": "deleteTimeReg",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteTimeRegistrationMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation deleteTimeRegistrationMutationModernMutation(\n  $input: DeleteTimeRegInput!\n) {\n  deleteTimeReg(input: $input) {\n    deletedTimeRegId\n    task {\n      timeLeft\n      timeLeftMinutesWithoutFutureTimeRegs\n      totalMinutesRegistered\n      progress\n      project {\n        completion\n        remaining\n        forecast\n        id\n      }\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a3e6fe72371eb3aee5ed041a23755467';

module.exports = node;
