import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation DeletePhaseBaselineMutation($input: DeletePhaseInput!) {
		deletePhase(input: $input) {
			project {
				id
				baselineTargetMinutes
				baselineTargetPrice
				baselineCost
				baselineProfit
				phases(first: 100000) @connection(key: "Project_phases", filters: []) {
					edges {
						...PhasesSection_phases
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Phase Deleted');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
