import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation createDemoMutation($input: CreateDemoInput!) {
		createDemo(input: $input) {
			demo {
				node {
					id
					name
					trialDemo
					sharedInCompany
					createdByEmail
					numberOfProjects
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_demos',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'demo',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				name: input.name,
				sharedInCompany: input.sharedInCompany,
				viewDay: input.viewDay,
				viewMonth: input.viewMonth,
				viewYear: input.viewYear,
				anonymize: input.anonymize,
			},
		},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
