import {commitMutation, graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import {omit} from 'lodash';

const mutation = graphql`
	mutation generateUniquePrefixMutation($input: GenerateUniquePrefixInput!) {
		generateUniquePrefix(input: $input) {
			prefix
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const cleanedInput = omit(input, ['companyId']);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return commitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
