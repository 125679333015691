/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TransferPlaceholderInput = {|
  placeholderAllocation?: ?PlaceholderAllocationInputType,
  personAllocation?: ?CreateAllocationInputType,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type PlaceholderAllocationInputType = {|
  id?: ?string,
  startDate?: ?string,
  endDate?: ?string,
  placeholderId?: ?string,
  monday?: ?number,
  tuesday?: ?number,
  wednesday?: ?number,
  thursday?: ?number,
  friday?: ?number,
  saturday?: ?number,
  sunday?: ?number,
  description?: ?string,
|};
export type CreateAllocationInputType = {|
  personId?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  endYear?: ?number,
  endMonth?: ?number,
  endDay?: ?number,
  monday?: ?number,
  tuesday?: ?number,
  wednesday?: ?number,
  thursday?: ?number,
  friday?: ?number,
  saturday?: ?number,
  sunday?: ?number,
  description?: ?string,
  idleTimeId?: ?string,
  projectId?: ?string,
  projectGroupId?: ?string,
  isSoft?: ?boolean,
|};
export type transferPlaceholderMutationVariables = {|
  input: TransferPlaceholderInput
|};
export type transferPlaceholderMutationResponse = {|
  +transferPlaceholder: ?{|
    +placeholderAllocation: ?{|
      +id: string,
      +startDate: ?string,
      +endDate: ?string,
      +monday: ?number,
      +tuesday: ?number,
      +wednesday: ?number,
      +thursday: ?number,
      +friday: ?number,
      +saturday: ?number,
      +sunday: ?number,
      +description: ?string,
      +placeholder: ?{|
        +id: string
      |},
    |},
    +personAllocation: ?{|
      +id: string,
      +startDay: ?number,
      +startMonth: ?number,
      +startYear: ?number,
      +endDay: ?number,
      +endMonth: ?number,
      +endYear: ?number,
      +monday: ?number,
      +tuesday: ?number,
      +wednesday: ?number,
      +thursday: ?number,
      +friday: ?number,
      +saturday: ?number,
      +sunday: ?number,
      +description: ?string,
      +project: ?{|
        +id: string
      |},
      +projectGroupId: ?string,
      +projectGroupColor: ?string,
      +person: ?{|
        +id: string
      |},
    |},
    +deletedPlaceholderAllocationId: ?string,
  |}
|};
export type transferPlaceholderMutation = {|
  variables: transferPlaceholderMutationVariables,
  response: transferPlaceholderMutationResponse,
|};
*/


/*
mutation transferPlaceholderMutation(
  $input: TransferPlaceholderInput!
) {
  transferPlaceholder(input: $input) {
    placeholderAllocation {
      id
      startDate
      endDate
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      description
      placeholder {
        id
      }
    }
    personAllocation {
      id
      startDay
      startMonth
      startYear
      endDay
      endMonth
      endYear
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      description
      project {
        id
      }
      projectGroupId
      projectGroupColor
      person {
        id
      }
    }
    deletedPlaceholderAllocationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "TransferPlaceholderInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monday",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesday",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesday",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursday",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friday",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturday",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sunday",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = [
  (v1/*: any*/)
],
v11 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TransferPlaceholderPayload",
    "kind": "LinkedField",
    "name": "transferPlaceholder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PlaceholderAllocation",
        "kind": "LinkedField",
        "name": "placeholderAllocation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Placeholder",
            "kind": "LinkedField",
            "name": "placeholder",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Allocation",
        "kind": "LinkedField",
        "name": "personAllocation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endYear",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectGroupId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectGroupColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedPlaceholderAllocationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "transferPlaceholderMutation",
    "selections": (v11/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "transferPlaceholderMutation",
    "selections": (v11/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "transferPlaceholderMutation",
    "operationKind": "mutation",
    "text": "mutation transferPlaceholderMutation(\n  $input: TransferPlaceholderInput!\n) {\n  transferPlaceholder(input: $input) {\n    placeholderAllocation {\n      id\n      startDate\n      endDate\n      monday\n      tuesday\n      wednesday\n      thursday\n      friday\n      saturday\n      sunday\n      description\n      placeholder {\n        id\n      }\n    }\n    personAllocation {\n      id\n      startDay\n      startMonth\n      startYear\n      endDay\n      endMonth\n      endYear\n      monday\n      tuesday\n      wednesday\n      thursday\n      friday\n      saturday\n      sunday\n      description\n      project {\n        id\n      }\n      projectGroupId\n      projectGroupColor\n      person {\n        id\n      }\n    }\n    deletedPlaceholderAllocationId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dc6bda656af762e6a492f42d23229aa0';

module.exports = node;
