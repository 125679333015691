/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MigrateXeroClientsInput = {|
  xeroClientIds?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type MigrateXeroClientsMutationVariables = {|
  input: MigrateXeroClientsInput
|};
export type MigrateXeroClientsMutationResponse = {|
  +migrateXeroClients: ?{|
    +company: ?{|
      +clients: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +notes: ?string,
            +logoId: ?string,
            +logoDefaultId: ?number,
            +projectCount: ?number,
            +xeroContactId: ?string,
          |}
        |}>
      |}
    |}
  |}
|};
export type MigrateXeroClientsMutation = {|
  variables: MigrateXeroClientsMutationVariables,
  response: MigrateXeroClientsMutationResponse,
|};
*/


/*
mutation MigrateXeroClientsMutation(
  $input: MigrateXeroClientsInput!
) {
  migrateXeroClients(input: $input) {
    company {
      clients(first: 10000) {
        edges {
          node {
            id
            name
            notes
            logoId
            logoDefaultId
            projectCount
            xeroContactId
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "MigrateXeroClientsInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000
    }
  ],
  "concreteType": "ClientTypeConnection",
  "kind": "LinkedField",
  "name": "clients",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Client",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "notes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logoId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "logoDefaultId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "projectCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "xeroContactId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "clients(first:10000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MigrateXeroClientsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MigrateXeroClientsPayload",
        "kind": "LinkedField",
        "name": "migrateXeroClients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MigrateXeroClientsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MigrateXeroClientsPayload",
        "kind": "LinkedField",
        "name": "migrateXeroClients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "MigrateXeroClientsMutation",
    "operationKind": "mutation",
    "text": "mutation MigrateXeroClientsMutation(\n  $input: MigrateXeroClientsInput!\n) {\n  migrateXeroClients(input: $input) {\n    company {\n      clients(first: 10000) {\n        edges {\n          node {\n            id\n            name\n            notes\n            logoId\n            logoDefaultId\n            projectCount\n            xeroContactId\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ab99009f95491d9f40611c15914ddc4';

module.exports = node;
