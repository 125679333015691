import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation sendMilestoneToJiraMutation($input: SendMilestoneToJiraInput!) {
		sendMilestoneToJira(input: $input) {
			phase {
				id
				name
				jiraId
			}
		}
	}
`;

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Send milestone to Jira');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				...input,
			},
		},
		onCompleted,
		onError,
	});
}

export default {commit};
