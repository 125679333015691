/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation AssignPersonToTaskMutation(
  $input: AssignPersonToTaskInput!
) {
  assignPersonToTask(input: $input) {
    task {
      id
      role {
        id
        name
      }
      assignedPersons {
        id
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "AssignPersonToTaskInput!"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "AssignPersonToTaskPayload",
            "kind": "LinkedField",
            "name": "assignPersonToTask",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "task",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "role",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Person",
                            "kind": "LinkedField",
                            "name": "assignedPersons",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignPersonToTaskMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssignPersonToTaskMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "AssignPersonToTaskMutation",
            "operationKind": "mutation",
            "text": "mutation AssignPersonToTaskMutation(\n  $input: AssignPersonToTaskInput!\n) {\n  assignPersonToTask(input: $input) {\n    task {\n      id\n      role {\n        id\n        name\n      }\n      assignedPersons {\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '0565b0391f2e9799a431727d2b002e46';
export default node;
