/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation DeleteBillToMutation(
  $input: DeleteBillToInput!
) {
  deleteBillTo(input: $input) {
    deletedBillToId
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "DeleteBillToInput!"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "DeleteBillToPayload",
            "kind": "LinkedField",
            "name": "deleteBillTo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deletedBillToId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteBillToMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteBillToMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "DeleteBillToMutation",
            "operationKind": "mutation",
            "text": "mutation DeleteBillToMutation(\n  $input: DeleteBillToInput!\n) {\n  deleteBillTo(input: $input) {\n    deletedBillToId\n  }\n}\n"
        }
    };
})();
node.hash = 'f9f3ba63fd34455ee1e3d90d533d33e6';
export default node;
