import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation reorderTaskMutationModernMutation($input: UpdateTaskInput!) {
		updateTask(input: $input) {
			errors
			childrenTasks {
				edges {
					node {
						id
						sprint {
							id
						}
						phase {
							id
						}
						deadlineDay
						deadlineMonth
						deadlineYear
						deadlineFrom
						startDay
						startMonth
						startYear
						startFrom
					}
				}
			}
			tasks {
				id
				progress
				sortOrder
				favouredSortOrder
				phase {
					id
					progress
					progressDetails {
						progress
					}
				}
				parentTaskId
				sprint {
					id
				}
				statusColumnV2 {
					id
				}
				deadlineDay
				deadlineMonth
				deadlineYear
				startDay
				startMonth
				startYear
				done
				role {
					id
					name
				}
				assignedPersons {
					id
					firstName
					lastName
					active
					email
					profilePictureId
					profilePictureDefaultId
					permissions
				}
				dependsOnThisTask(first: 1000) @connection(key: "Task_dependsOnThisTask") {
					edges {
						node {
							id
							taskDependsOnThis {
								id
								canStart
								canBeSetToDone
							}
						}
					}
				}
			}
			sortOrderTasks {
				id
				sortOrder
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const tasks = [];
	input.ids.forEach(id => {
		const task = {id: id};
		let assignedPersons = null;
		if (input.assignedPersonToAdd != null && input.existingAssignedPersons != null) {
			if (assignedPersons == null) {
				assignedPersons = [];
			}
			const assignees = [...input.existingAssignedPersons.map(id => ({id: id}))];
			// add to list of assigned persons if not already there
			if (!assignees.find(elem => elem.id === input.assignedPersonToAdd)) {
				assignees.push({id: input.assignedPersonToAdd});
			}
			assignedPersons = assignedPersons.concat(assignees);
		}

		if (input.unassignPersonFromTask != null && input.existingAssignedPersons != null) {
			if (assignedPersons == null) {
				assignedPersons = [];
				input.existingAssignedPersons.forEach(pId => {
					if (!input.unassignPersonFromTask.find(obj => obj.personIds.includes(pId))) {
						assignedPersons.push({id: pId});
					}
				});
			} else {
				const shouldBeUnassigned = personId =>
					!!input.unassignPersonFromTask.find(el => el.personIds.includes(personId));
				assignedPersons = assignedPersons.filter(elem => !shouldBeUnassigned(elem.id));
			}
		}
		if (assignedPersons != null) {
			task.assignedPersons = assignedPersons;
		}
		if (input.startDay) {
			task.startDay = input.startDay;
		}
		if (input.startMonth) {
			task.startMonth = input.startMonth;
		}
		if (input.startYear) {
			task.startYear = input.startYear;
		}
		if (input.deadlineDay) {
			task.deadlineDay = input.deadlineDay;
		}
		if (input.deadlineMonth) {
			task.deadlineMonth = input.deadlineMonth;
		}
		if (input.deadlineYear) {
			task.deadlineYear = input.deadlineYear;
		}
		if (input.description) {
			task.description = input.description;
		}
		if (input.name) {
			task.name = input.name;
		}
		if (input.estimateForecast) {
			task.estimateForecast = input.estimateForecast;
		}
		if (input.timeLeftOptimistic) {
			task.timeLeft = input.timeLeftOptimistic;
		}

		if (input.sprintId === null) {
			task.sprint = null;
			task.startFrom = null;
			task.deadlineFrom = null;
		}
		if (input.sprintId) {
			task.sprint = {id: input.sprintId};
			task.startFrom = null;
			task.deadlineFrom = null;
		}
		if (!input.sprintId && input.optimisticSprintId) {
			task.sprint = {id: input.optimisticSprintId};
			task.startFrom = null;
			task.deadlineFrom = null;
		}
		if (input.roleId !== undefined) {
			task.role = input.roleId === null ? null : {id: input.roleId};
		}
		if (input.phaseId !== undefined) {
			task.phase = input.phaseId ? {id: input.phaseId} : null;
			task.startFrom = null;
			task.deadlineFrom = null;
		}
		if (input.coverFileId) {
			task.coverFile = {id: input.coverFileId};
		}
		if (input.aboveTaskOrder) {
			task.sortOrder = input.aboveTaskOrder + 1;
		}

		if (input.aboveFavouredTaskOrder) {
			//task.favouredSortOrder = input.aboveFavouredTaskOrder + BigInt(1); // 1n doesn't compile :(
			/*
			 *	Just to add 1 to bigInt made of a string.
			 *	This code should be removed (and replace by the line bellow)
			 *	as soon as Safari, IE and Edge accept BigInt
			 */
			let aboveFavouredTaskOrderFormatted = input.aboveFavouredTaskOrder;
			let i = aboveFavouredTaskOrderFormatted.length - 1;
			while (aboveFavouredTaskOrderFormatted[i] === '9' && i >= 0) {
				aboveFavouredTaskOrderFormatted = Util.replaceAt(aboveFavouredTaskOrderFormatted, i, '0');
				i--;
			}
			if (i < 0) {
				aboveFavouredTaskOrderFormatted = '1' + aboveFavouredTaskOrderFormatted;
			} else {
				aboveFavouredTaskOrderFormatted = Util.replaceAt(
					aboveFavouredTaskOrderFormatted,
					i,
					parseInt(aboveFavouredTaskOrderFormatted[i]) + 1
				);
			}
			task.favouredSortOrder = aboveFavouredTaskOrderFormatted;
		}
		if (input.favouredTaskOrder) {
			task.favouredSortOrder = input.favouredTaskOrder;
		}
		if (input.optimisticTaskOrder) {
			task.sortOrder = input.optimisticTaskOrder;
		}
		if (input.blocked) {
			task.blocked = input.blocked;
		}
		if (input.approved !== undefined) {
			task.approved = input.approved;
		}
		if (input.bug) {
			task.bug = input.bug;
		}
		if (input.assignedPersons) {
			task.assignedPersons = input.assignedPersons.map(p => ({id: p}));
		}
		if (input.repeatingTaskId) {
			task.repeatingTaskId = {id: input.repeatingTaskId};
		}
		if (input.statusColumnId) {
			task.statusColumnV2 = {id: input.statusColumnId};
		}
		// if (input.actualStatusColumnId) {
		// 	task.statusColumnV2 = {id: input.actualStatusColumnId};
		// }
		if (input.projectGroupStatusIdToStatusIdMap && input.taskIdToProjectIdMap && input.projectGroupStatusColumnId) {
			const projectId = input.taskIdToProjectIdMap.get(id);
			const groupStatusIdToStatusIdMap = input.projectGroupStatusIdToStatusIdMap.get(projectId);
			task.statusColumnV2 = {id: groupStatusIdToStatusIdMap.get(input.projectGroupStatusColumnId)};
		}
		if (input.projectGroupStatusColumnId && input.optimisticColumnId) {
			task.statusColumnV2 = {id: input.optimisticColumnId};
		}
		if (input.previousProjectId && input.projectId) {
			task.projectId = input.projectId;
		}
		if (input.remaining) {
			task.timeLeft = input.remaining;
		}
		if (input.highPriority !== undefined) {
			task.highPriority = input.highPriority;
		}
		if (input.ownerId === null) {
			task.owner = null;
		}
		if (input.ownerId) {
			task.owner = {id: input.ownerId};
		}
		if (input.parentTaskId !== undefined) {
			task.parentTaskId = input.parentTaskId;
		}
		tasks.push(task);
	});
	return {
		updateTask: {
			tasks: tasks,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Card Updated', {_Changed: 'sortOrder'});

	const cleanedInput = omit(input, [
		'timeLeftOptimistic',
		'optimisticTaskOrder',
		'optimisticColumnId',
		'aboveFavouredTaskOrder',
		'viewerId',
		'existingAssignedPersons',
		'optimisticSprintId',
	]);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: [],
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
