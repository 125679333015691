import {graphql} from 'react-relay';
import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createTeamMutation($input: CreateTeamInput!) {
		createTeam(input: $input) {
			team {
				node {
					...settingsTeams_team
				}
			}
		}
	}
`;

function getConfigs(companyId) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: companyId,
			connectionInfo: [
				{
					key: 'Company_teams',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'team',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Team Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				name: input.name,
				persons: input.persons,
			},
		},
		configs: getConfigs(input.companyId),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
