/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssignPlaceholderAllocationsToPersonInput = {|
  placeholderId?: ?string,
  personId?: ?string,
  isSoft?: ?boolean,
  placeholderAllocationIds?: ?$ReadOnlyArray<?string>,
  deletePlaceholderIfEmpty?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type AssignPlaceholderAllocationsToPersonMutationVariables = {|
  input: AssignPlaceholderAllocationsToPersonInput
|};
export type AssignPlaceholderAllocationsToPersonMutationResponse = {|
  +assignPlaceholderAllocationsToPerson: ?{|
    +placeholderId: ?string,
    +deletedPlaceholderAllocationIds: ?$ReadOnlyArray<?string>,
    +placeholderEmpty: ?boolean,
    +placeholderWasDeleted: ?boolean,
    +allocations: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +startYear: ?number,
        +startMonth: ?number,
        +startDay: ?number,
        +endYear: ?number,
        +endMonth: ?number,
        +endDay: ?number,
        +monday: ?number,
        +tuesday: ?number,
        +wednesday: ?number,
        +thursday: ?number,
        +friday: ?number,
        +saturday: ?number,
        +sunday: ?number,
        +description: ?string,
        +isProjectGroupAllocation: ?boolean,
        +projectGroupId: ?string,
        +projectGroupName: ?string,
        +projectGroupCompanyProjectGroupId: ?number,
        +projectGroupColor: ?string,
        +isSoft: ?boolean,
        +person: ?{|
          +id: string
        |},
        +project: ?{|
          +id: string
        |},
      |}
    |}>,
  |}
|};
export type AssignPlaceholderAllocationsToPersonMutation = {|
  variables: AssignPlaceholderAllocationsToPersonMutationVariables,
  response: AssignPlaceholderAllocationsToPersonMutationResponse,
|};
*/


/*
mutation AssignPlaceholderAllocationsToPersonMutation(
  $input: AssignPlaceholderAllocationsToPersonInput!
) {
  assignPlaceholderAllocationsToPerson(input: $input) {
    placeholderId
    deletedPlaceholderAllocationIds
    placeholderEmpty
    placeholderWasDeleted
    allocations {
      node {
        id
        startYear
        startMonth
        startDay
        endYear
        endMonth
        endDay
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        description
        isProjectGroupAllocation
        projectGroupId
        projectGroupName
        projectGroupCompanyProjectGroupId
        projectGroupColor
        isSoft
        person {
          id
        }
        project {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AssignPlaceholderAllocationsToPersonInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AssignPlaceholderAllocationsToPersonPayload",
    "kind": "LinkedField",
    "name": "assignPlaceholderAllocationsToPerson",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "placeholderId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedPlaceholderAllocationIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "placeholderEmpty",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "placeholderWasDeleted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AllocationTypeEdge",
        "kind": "LinkedField",
        "name": "allocations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Allocation",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "monday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tuesday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wednesday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thursday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "friday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "saturday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sunday",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isProjectGroupAllocation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectGroupId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectGroupName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectGroupCompanyProjectGroupId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectGroupColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isSoft",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignPlaceholderAllocationsToPersonMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssignPlaceholderAllocationsToPersonMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AssignPlaceholderAllocationsToPersonMutation",
    "operationKind": "mutation",
    "text": "mutation AssignPlaceholderAllocationsToPersonMutation(\n  $input: AssignPlaceholderAllocationsToPersonInput!\n) {\n  assignPlaceholderAllocationsToPerson(input: $input) {\n    placeholderId\n    deletedPlaceholderAllocationIds\n    placeholderEmpty\n    placeholderWasDeleted\n    allocations {\n      node {\n        id\n        startYear\n        startMonth\n        startDay\n        endYear\n        endMonth\n        endDay\n        monday\n        tuesday\n        wednesday\n        thursday\n        friday\n        saturday\n        sunday\n        description\n        isProjectGroupAllocation\n        projectGroupId\n        projectGroupName\n        projectGroupCompanyProjectGroupId\n        projectGroupColor\n        isSoft\n        person {\n          id\n        }\n        project {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5567ccd3d2384e282d9da810e2739396';

module.exports = node;
