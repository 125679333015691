import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateExchangeRateMutation($input: UpdateExchangeRateInput!) {
		updateExchangeRate(input: $input) {
			exchangeRate {
				rate
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const obj = {
		id: input.id,
		rate: input.rate,
	};
	return {
		updateExchangeRate: {
			exchangeRate: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];
	tracking.trackEvent('Exchange Rate Updated', {_Changed: changes});

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
