import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createApiKeyMutation($input: CreateApiKeyInput!) {
		createApiKey(input: $input) {
			apiKey {
				node {
					...settingsApiKeys_apiKey
				}
			}
		}
	}
`;

function getConfigs(companyId) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: companyId,
			connectionInfo: [
				{
					key: 'Company_apiKeys',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'apiKey',
		},
	];
}

function commit(environment, companyId, onSuccess, onError) {
	tracking.trackEvent('Api Key Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId()}},
		configs: getConfigs(companyId),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
