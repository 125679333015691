import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateSprintMutation($input: UpdateSprintInput!) {
		updateSprint(input: $input) {
			sprint {
				name
				startYear
				startMonth
				startDay
				endDay
				endMonth
				endYear
				timeLeft
				completion
				forecast
				available
				description
				savedPerformance
			}
			projectGroupSprint {
				name
				timeLeft
				completion
				forecast
				available
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const obj = {id: input.id};
	if (input.name) {
		obj.name = input.name;
	}
	if (input.endYear) {
		obj.endYear = input.endYear;
		obj.endMonth = input.endMonth;
		obj.endDay = input.endDay;
	}
	if (input.startYear) {
		obj.startYear = input.startYear;
		obj.startMonth = input.startMonth;
		obj.startDay = input.startDay;
	}
	if (input.savedPerformance) {
		obj.savedPerformance = input.savedPerformance;
	}
	return {
		updateSprint: {
			sprint: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];
	for (const key of Object.keys(input)) {
		//ADD projectId, projectGroupId also here if used by the mutation
		if (['id', 'projectId', 'projectGroupId'].includes(key) || input[key] === undefined) {
			continue;
		}
		changes.push(key);
	}

	tracking.trackEvent('Sprint Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
