import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createRetainerPeriodMutation($input: CreateRetainerPeriodInput!) {
		createRetainerPeriod(input: $input) {
			retainerPeriod {
				node {
					id
					name
					startYear
					startMonth
					startDay
					endYear
					endMonth
					endDay
					available
					periodLength
					periodPeriodicity
					periodPriceAmount
					periodHoursAmount
					periodBudgetType
					periodSettingIgnoreForBilling
					periodSettingSubtractValue
					periodSettingRollValue
					periodSettingAddExpenses
					periodDifferencePriceAmount
					periodDifferenceHoursAmount
					sharedPeriodDifferenceHoursAmount
					sharedPeriodDifferencePriceAmount
					ignoredRolloverHours
					ignoredRolloverPrice
					periodLocked
					periodLockedTime
					periodDifferenceSplit
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_retainerPeriods',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'retainerPeriod',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Retainer period created');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
