/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkUpdatePhasesInput = {|
  phases?: ?$ReadOnlyArray<?BulkPhasesInputType>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type BulkPhasesInputType = {|
  id?: ?string,
  projectId?: ?string,
  name?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  deadlineYear?: ?number,
  deadlineMonth?: ?number,
  deadlineDay?: ?number,
  sortOrder?: ?number,
|};
export type bulkUpdatePhasesMutationVariables = {|
  input: BulkUpdatePhasesInput
|};
export type bulkUpdatePhasesMutationResponse = {|
  +bulkUpdatePhases: ?{|
    +project: ?{|
      +id: string,
      +phases: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +projectId: ?string,
            +name: ?string,
            +startYear: ?number,
            +startMonth: ?number,
            +startDay: ?number,
            +deadlineYear: ?number,
            +deadlineMonth: ?number,
            +deadlineDay: ?number,
            +sortOrder: ?number,
          |}
        |}>
      |},
    |}
  |}
|};
export type bulkUpdatePhasesMutation = {|
  variables: bulkUpdatePhasesMutationVariables,
  response: bulkUpdatePhasesMutationResponse,
|};
*/


/*
mutation bulkUpdatePhasesMutation(
  $input: BulkUpdatePhasesInput!
) {
  bulkUpdatePhases(input: $input) {
    project {
      id
      phases(first: 1000000) {
        edges {
          node {
            id
            projectId
            name
            startYear
            startMonth
            startDay
            deadlineYear
            deadlineMonth
            deadlineDay
            sortOrder
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "BulkUpdatePhasesInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PhaseTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PhaseType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deadlineYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deadlineMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deadlineDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sortOrder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bulkUpdatePhasesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdatePhasesPayload",
        "kind": "LinkedField",
        "name": "bulkUpdatePhases",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "phases",
                "args": null,
                "concreteType": "PhaseTypeConnection",
                "kind": "LinkedField",
                "name": "__Project_phases_connection",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bulkUpdatePhasesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdatePhasesPayload",
        "kind": "LinkedField",
        "name": "bulkUpdatePhases",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "PhaseTypeConnection",
                "kind": "LinkedField",
                "name": "phases",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": "phases(first:1000000)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Project_phases",
                "kind": "LinkedHandle",
                "name": "phases"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "bulkUpdatePhases",
            "project",
            "phases"
          ]
        }
      ]
    },
    "name": "bulkUpdatePhasesMutation",
    "operationKind": "mutation",
    "text": "mutation bulkUpdatePhasesMutation(\n  $input: BulkUpdatePhasesInput!\n) {\n  bulkUpdatePhases(input: $input) {\n    project {\n      id\n      phases(first: 1000000) {\n        edges {\n          node {\n            id\n            projectId\n            name\n            startYear\n            startMonth\n            startDay\n            deadlineYear\n            deadlineMonth\n            deadlineDay\n            sortOrder\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c89ac929c2c913c7d3914f701c40e6b4';

module.exports = node;
