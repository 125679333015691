/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LANGUAGE = "DANISH" | "ENGLISH_EU" | "ENGLISH_UK" | "ENGLISH_US" | "FRENCH" | "POLISH" | "SPANISH" | "%future added value";
export type BulkUpdatePersonInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  active?: ?boolean,
  invited?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type bulkUpdatePersonMutationVariables = {|
  input: BulkUpdatePersonInput
|};
export type bulkUpdatePersonMutationResponse = {|
  +bulkUpdatePerson: ?{|
    +errors: ?$ReadOnlyArray<?string>,
    +persons: ?$ReadOnlyArray<?{|
      +id: string,
      +email: ?string,
      +jobTitle: ?string,
      +department: ?{|
        +id: string
      |},
      +firstName: ?string,
      +lastName: ?string,
      +initials: ?string,
      +profiles: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
          |}
        |}>
      |},
      +permissions: ?$ReadOnlyArray<?string>,
      +cost: ?number,
      +phone: ?string,
      +language: ?LANGUAGE,
      +active: ?boolean,
      +invited: ?boolean,
      +profilePictureId: ?string,
      +excludeFromCompanyLockedPeriod: ?boolean,
      +startDate: ?string,
      +endDate: ?string,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
      +holidayCalendar: ?{|
        +id: string
      |},
      +personLabels: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +label: ?{|
              +id: string
            |}
          |}
        |}>
      |},
      +gitlabUser: ?{|
        +integrationUserId: ?number
      |},
      +gitlabServerUser: ?{|
        +integrationUserId: ?number
      |},
      +githubUser: ?{|
        +integrationUserId: ?number
      |},
      +unit4UserObject: ?string,
      +jiraCloudId: ?string,
      +jiraServerId: ?string,
    |}>,
  |}
|};
export type bulkUpdatePersonMutation = {|
  variables: bulkUpdatePersonMutationVariables,
  response: bulkUpdatePersonMutationResponse,
|};
*/


/*
mutation bulkUpdatePersonMutation(
  $input: BulkUpdatePersonInput!
) {
  bulkUpdatePerson(input: $input) {
    errors
    persons {
      id
      email
      jobTitle
      department {
        id
      }
      firstName
      lastName
      initials
      profiles(first: 10000) {
        edges {
          node {
            id
            name
          }
        }
      }
      permissions
      cost
      phone
      language
      active
      invited
      profilePictureId
      excludeFromCompanyLockedPeriod
      startDate
      endDate
      role {
        id
        name
      }
      holidayCalendar {
        id
      }
      personLabels(first: 10000) {
        edges {
          node {
            label {
              id
            }
            id
          }
        }
      }
      gitlabUser {
        integrationUserId
      }
      gitlabServerUser {
        integrationUserId
      }
      githubUser {
        integrationUserId
      }
      unit4UserObject
      jiraCloudId
      jiraServerId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "BulkUpdatePersonInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v6 = [
  (v3/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "DepartmentType",
  "kind": "LinkedField",
  "name": "department",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initials",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v12 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": (v11/*: any*/),
  "concreteType": "ProfileTypeConnection",
  "kind": "LinkedField",
  "name": "profiles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Profile",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "profiles(first:10000)"
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invited",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "excludeFromCompanyLockedPeriod",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "HolidayCalendar",
  "kind": "LinkedField",
  "name": "holidayCalendar",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "Label",
  "kind": "LinkedField",
  "name": "label",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v27 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integrationUserId",
    "storageKey": null
  }
],
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "gitlabUser",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "gitlabServerUser",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "githubUser",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit4UserObject",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraCloudId",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraServerId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bulkUpdatePersonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdatePersonPayload",
        "kind": "LinkedField",
        "name": "bulkUpdatePerson",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "persons",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "PersonLabelTypeConnection",
                "kind": "LinkedField",
                "name": "personLabels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonLabelTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonLabel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v26/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "personLabels(first:10000)"
              },
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bulkUpdatePersonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BulkUpdatePersonPayload",
        "kind": "LinkedField",
        "name": "bulkUpdatePerson",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "persons",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "PersonLabelTypeConnection",
                "kind": "LinkedField",
                "name": "personLabels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonLabelTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonLabel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v26/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "personLabels(first:10000)"
              },
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "bulkUpdatePersonMutation",
    "operationKind": "mutation",
    "text": "mutation bulkUpdatePersonMutation(\n  $input: BulkUpdatePersonInput!\n) {\n  bulkUpdatePerson(input: $input) {\n    errors\n    persons {\n      id\n      email\n      jobTitle\n      department {\n        id\n      }\n      firstName\n      lastName\n      initials\n      profiles(first: 10000) {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n      permissions\n      cost\n      phone\n      language\n      active\n      invited\n      profilePictureId\n      excludeFromCompanyLockedPeriod\n      startDate\n      endDate\n      role {\n        id\n        name\n      }\n      holidayCalendar {\n        id\n      }\n      personLabels(first: 10000) {\n        edges {\n          node {\n            label {\n              id\n            }\n            id\n          }\n        }\n      }\n      gitlabUser {\n        integrationUserId\n      }\n      gitlabServerUser {\n        integrationUserId\n      }\n      githubUser {\n        integrationUserId\n      }\n      unit4UserObject\n      jiraCloudId\n      jiraServerId\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8527949a84db6d36ce632ca20eb1b959';

module.exports = node;
