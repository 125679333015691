/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeactivateJiraServerInput = {|
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deactivateJiraServerMutationVariables = {|
  input: DeactivateJiraServerInput
|};
export type deactivateJiraServerMutationResponse = {|
  +deactivateJiraServer: ?{|
    +viewer: ?{|
      +company: ?{|
        +jiraServerPublicRsaKey: ?string,
        +jiraServerConsumerKey: ?string,
        +jiraServerUrl: ?string,
        +jiraServerAccessToken: ?string,
        +jiraServerEnabled: ?boolean,
      |}
    |}
  |}
|};
export type deactivateJiraServerMutation = {|
  variables: deactivateJiraServerMutationVariables,
  response: deactivateJiraServerMutationResponse,
|};
*/


/*
mutation deactivateJiraServerMutation(
  $input: DeactivateJiraServerInput!
) {
  deactivateJiraServer(input: $input) {
    viewer {
      company {
        jiraServerPublicRsaKey
        jiraServerConsumerKey
        jiraServerUrl
        jiraServerAccessToken
        jiraServerEnabled
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeactivateJiraServerInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraServerPublicRsaKey",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraServerConsumerKey",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraServerUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraServerAccessToken",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraServerEnabled",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deactivateJiraServerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeactivateJiraServerPayload",
        "kind": "LinkedField",
        "name": "deactivateJiraServer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deactivateJiraServerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeactivateJiraServerPayload",
        "kind": "LinkedField",
        "name": "deactivateJiraServer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deactivateJiraServerMutation",
    "operationKind": "mutation",
    "text": "mutation deactivateJiraServerMutation(\n  $input: DeactivateJiraServerInput!\n) {\n  deactivateJiraServer(input: $input) {\n    viewer {\n      company {\n        jiraServerPublicRsaKey\n        jiraServerConsumerKey\n        jiraServerUrl\n        jiraServerAccessToken\n        jiraServerEnabled\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2ea7d8b129f089d2351c1aadf289f86c';

module.exports = node;
