/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VerifyHarvestIntegrationInput = {|
  harvestAccountName: string,
  harvestUserName: string,
  harvestPassword: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type verifyHarvestIntegrationMutationVariables = {|
  input: VerifyHarvestIntegrationInput
|};
export type verifyHarvestIntegrationMutationResponse = {|
  +verifyHarvestIntegration: ?{|
    +success: ?boolean,
    +message: ?string,
    +company: ?{|
      +id: string,
      +harvestEnabled: ?boolean,
      +harvestAccountName: ?string,
      +harvestUserName: ?string,
      +harvestPassword: ?string,
    |},
  |}
|};
export type verifyHarvestIntegrationMutation = {|
  variables: verifyHarvestIntegrationMutationVariables,
  response: verifyHarvestIntegrationMutationResponse,
|};
*/


/*
mutation verifyHarvestIntegrationMutation(
  $input: VerifyHarvestIntegrationInput!
) {
  verifyHarvestIntegration(input: $input) {
    success
    message
    company {
      id
      harvestEnabled
      harvestAccountName
      harvestUserName
      harvestPassword
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "VerifyHarvestIntegrationInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "VerifyHarvestIntegrationPayload",
    "kind": "LinkedField",
    "name": "verifyHarvestIntegration",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "harvestEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "harvestAccountName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "harvestUserName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "harvestPassword",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "verifyHarvestIntegrationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "verifyHarvestIntegrationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "verifyHarvestIntegrationMutation",
    "operationKind": "mutation",
    "text": "mutation verifyHarvestIntegrationMutation(\n  $input: VerifyHarvestIntegrationInput!\n) {\n  verifyHarvestIntegration(input: $input) {\n    success\n    message\n    company {\n      id\n      harvestEnabled\n      harvestAccountName\n      harvestUserName\n      harvestPassword\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ed3c6b192ec368ec2d356a55fb4dabfc';

module.exports = node;
