/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ValidatePrefixInput = {|
  prefix?: ?string,
  domain?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type validatePrefixMutationVariables = {|
  input: ValidatePrefixInput
|};
export type validatePrefixMutationResponse = {|
  +validatePrefix: ?{|
    +valid: ?string
  |}
|};
export type validatePrefixMutation = {|
  variables: validatePrefixMutationVariables,
  response: validatePrefixMutationResponse,
|};
*/


/*
mutation validatePrefixMutation(
  $input: ValidatePrefixInput!
) {
  validatePrefix(input: $input) {
    valid
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ValidatePrefixInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ValidatePrefixPayload",
    "kind": "LinkedField",
    "name": "validatePrefix",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "valid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "validatePrefixMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "validatePrefixMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "validatePrefixMutation",
    "operationKind": "mutation",
    "text": "mutation validatePrefixMutation(\n  $input: ValidatePrefixInput!\n) {\n  validatePrefix(input: $input) {\n    valid\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2fc09ea16b4bf69a688b71247c909217';

module.exports = node;
