import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateTasksBulkSchedulingMutation($input: UpdateTasksBulkInput!) {
		updateTasksBulk(input: $input) {
			tasks {
				assignedPersons {
					id
					firstName
					lastName
					profilePictureId
				}
				billable
				blocked
				bug
				companyTaskId
				deadlineDay
				deadlineMonth
				deadlineYear
				deadlineFrom
				startFrom
				done
				estimateForecast
				estimateForecastMinutes
				highPriority
				estimateForecastPrice
				id
				role {
					id
				}
				phase {
					id
				}
				statusColumnV2 {
					id
				}
				progress
				startDay
				startMonth
				startYear
				timeLeft
				name
				timeLeft
				progress
				project {
					id
					completion
					remaining
					forecast
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	Util.localStorageDeleteFincancialMap();
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['tasks'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Tasks Bulk Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
