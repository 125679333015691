import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';
import {createRangeBulkAddUpdater} from '../relay-helpers/RelayCacheHelpers';

const mutation = graphql`
	mutation migrateAsanaProjectsMutation($input: MigrateAsanaProjectsInput!) {
		migrateAsanaProjects(input: $input) {
			success
			projects {
				node {
					id
					companyProjectId
					status
					name
					projectColor
					isInProjectGroup
					useBaseline
					billable
					budgetType
					projectGroupId
					sprintTimeBox
					estimationUnit
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		updater: createRangeBulkAddUpdater(
			'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			'migrateAsanaProjects',
			'projects',
			'Header_Viewer_projects'
		),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
