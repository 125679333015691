/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateProgramInput = {|
  name?: ?string,
  description?: ?string,
  prefix?: ?string,
  color?: ?string,
  startDate?: ?string,
  endDate?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createProgramMutationVariables = {|
  input: CreateProgramInput
|};
export type createProgramMutationResponse = {|
  +createProgram: ?{|
    +program: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +description: ?string,
        +prefix: ?string,
        +color: ?string,
        +startDate: ?string,
        +endDate: ?string,
      |}
    |}
  |}
|};
export type createProgramMutation = {|
  variables: createProgramMutationVariables,
  response: createProgramMutationResponse,
|};
*/


/*
mutation createProgramMutation(
  $input: CreateProgramInput!
) {
  createProgram(input: $input) {
    program {
      node {
        id
        name
        description
        prefix
        color
        startDate
        endDate
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProgramInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateProgramPayload",
    "kind": "LinkedField",
    "name": "createProgram",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectServiceProgramTypeEdge",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createProgramMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createProgramMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createProgramMutation",
    "operationKind": "mutation",
    "text": "mutation createProgramMutation(\n  $input: CreateProgramInput!\n) {\n  createProgram(input: $input) {\n    program {\n      node {\n        id\n        name\n        description\n        prefix\n        color\n        startDate\n        endDate\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8bf183f1254eaaa9bdc7c94d4f86ccad';

module.exports = node;
