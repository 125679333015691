import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation removeMemberFromProgramMutation($input: RemoveMemberFromProgramInput!) {
		removeMemberFromProgram(input: $input) {
			removedMemberIds
			program {
				id
				name
				description
				prefix
				color
				startDate
				endDate
				projects {
					edges {
						node {
							companyProjectId
							name
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.programId,
			connectionKeys: [
				{
					key: 'Program_members',
				},
			],
			pathToConnection: ['program', 'members'],
			deletedIDFieldName: 'removedMemberIds',
		},
	];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('Detached project from program');
	const {companyId, ...cleanedInput} = input;

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs({companyId, ...cleanedInput}),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
