import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateClientPersonMutation($input: UpdateClientPersonInput!) {
		updateClientPerson(input: $input) {
			errors
			person {
				email
				jobTitle
				department {
					id
				}
				firstName
				lastName
				initials
				profiles(first: 10000) {
					edges {
						node {
							id
							name
						}
					}
				}
				permissions
				cost
				phone
				language
				active
				invited
				profilePictureId
				excludeFromCompanyLockedPeriod
				startDate
				endDate
				role {
					id
					name
				}
				holidayCalendar {
					id
				}
				personLabels(first: 10000) {
					edges {
						node {
							label {
								id
							}
						}
					}
				}
				gitlabUser {
					integrationUserId
				}
				gitlabServerUser {
					integrationUserId
				}
				githubUser {
					integrationUserId
				}
				unit4UserObject
				jiraCloudId
				jiraServerId
			}
			viewer {
				permissions
				profiles(first: 10000) {
					edges {
						node {
							id
							name
							permissions
						}
					}
				}
				company {
					userSeats
					virtualSeats
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const {id, projectId, clientId, invited} = input;

	return {
		updateClientPerson: {
			id,
			projectId,
			clientId,
			invited,
		},
	};
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Client Person Updated', {_Changed: changes});

	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
			uploadables,
			configs: getConfigs(),
			optimisticResponse: getOptimisticResponse(input),
			onCompleted: onSuccess,
			onError: onError,
		},
		true
	);
}

export default {commit};
