import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation bulkPlaceholderAllocationsMutation($input: BulkPlaceholderAllocationsInput!) {
		bulkPlaceholderAllocations(input: $input) {
			placeholderAllocations {
				id
				placeholder {
					id
				}
				description
				startDate
				endDate
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
			}
		}
	}
`;

function getOptimisticResponse(input) {
	return {
		bulkPlaceholderAllocations: {
			placeholderAllocations: input.map(placeholderAllocation => {
				return {
					id: placeholderAllocation.id,
					startDate: placeholderAllocation.startDate,
					endDate: placeholderAllocation.endDate,
					monday: placeholderAllocation.monday,
					tuesday: placeholderAllocation.tuesday,
					wednesday: placeholderAllocation.wednesday,
					thursday: placeholderAllocation.thursday,
					friday: placeholderAllocation.friday,
					saturday: placeholderAllocation.saturday,
					sunday: placeholderAllocation.sunday,
					description: placeholderAllocation.description,
					placeholder: {
						id: placeholderAllocation.placeholderId,
					},
				};
			}),
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit, getOptimisticResponse};
