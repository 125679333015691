import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../css_variables';

export const UtilizationModalStyled = styled.div`
	min-width: 584px; /* this gives the modal a width of 700 if we add the padding */
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

export const TopSection = styled.div`
	display: flex;
	justify-content: space-between;

	.person-name-container {
		display: flex;
		align-items: center;
		font-size: 20px;

		.person-name {
			margin-left: 10px;
			font-weight: 500;
			font-size: 20px;
			font-weight: 700;

			.utilization {
				font-weight: 100;
			}
		}
	}

	button {
		padding: 10px 16px;
	}
`;

export const DateRangeSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-top: 20px;
`;

export const UtilizationRange = styled.span`
	font-weight: 600;
	font-size: 13px;
	margin-top: 6px;
`;

export const StatsSection = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 20px;
	padding: 15px;
	border-top: 3px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	border-bottom: 3px solid ${CSS_CONSTANTS.v2_button_very_light_grey};

	.stats-component {
		display: flex;
		flex-direction: column;
		align-items: center;

		.component-value {
			font-size: 18px;
			font-weight: 500;
			color: ${CSS_CONSTANTS.v2_text_gray};
			margin-top: 5px;

			.available {
				color: ${CSS_CONSTANTS.v2_text_light_gray};
			}
		}
	}

	.separator {
		height: 33px;
		width: 1px;
		background-color: ${CSS_CONSTANTS.v2_button_very_light_grey};
	}
`;

export const UtilizationStatsSection = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: flex-start;
	margin-top: 20px;
	padding: 15px;
	border-top: 2px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	border-bottom: 2px solid ${CSS_CONSTANTS.v2_button_very_light_grey};

	.stats-component {
		display: flex;
		flex-direction: column;
		align-items: center;

		.component-value {
			font-size: 18px;
			font-weight: 500;
			color: ${CSS_CONSTANTS.v2_text_gray};
			margin-top: 5px;

			.available {
				color: ${CSS_CONSTANTS.v2_text_light_gray};
			}
		}
	}

	.separator {
		height: 33px;
		width: 1px;
		background-color: ${CSS_CONSTANTS.v2_button_very_light_grey};
	}
`;

export const ComponentValueDetails = styled.span`
	font-weight: 400;
	font-size: 8px;
	color: ${CSS_CONSTANTS.schedule_text_grey};
	text-transform: uppercase;
	margin-top: 3px;
`;

export const GraphSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 40px;
`;

export const Graph = styled.div`
	margin-top: 28px;
	margin-bottom: 22px;
	padding-left: 24px;
	position: relative;
`;

export const ZeroAvailabilityMessageContainer = styled.div`
	margin-top: 28px;
	margin-bottom: 22px;
	padding-left: 24px;
	position: relative;
	width: 300px;
`;

export const GraphLegendsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-left: 20px;

	> * {
		&:not(:first-child) {
			margin-top: 15px;
		}
	}
`;

export const GraphAxis = styled.div`
	position: relative;
	width: 150px;
	height: 200px;
	border-left: solid 1px ${CSS_CONSTANTS.v2_button_very_light_grey};
	border-bottom: solid 1px ${CSS_CONSTANTS.v2_button_very_light_grey};
`;

export const GraphWorkingHoursLine = styled.div`
	position: absolute;
	width: 150px;
	border-top: 1px dashed ${CSS_CONSTANTS.v2_button_very_light_grey};
	z-index: 100;
`;

export const GraphStep = styled.div`
	position: absolute;
	font-size: 10px;
	font-weight: 100;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	text-align: right;
	width: 100px;
	left: -86px;
`;

export const GraphStepLineContainer = styled.div`
	position: relative;
`;

export const GraphStepLine = styled.div`
	position: absolute;
	height: 1px;
	width: 9px;
	bottom: 8px;
	right: -15px;
	background-color: ${CSS_CONSTANTS.v2_button_very_light_grey};
`;

export const GraphStepInfinitySignContainer = styled.div`
	position: relative;
`;

export const GraphStepInfinitySign = styled.div`
	position: absolute;
	background-image: url('images/components/scheduling/infinity-icon.svg');
	right: -2px;
	top: -12px;
	width: 16px;
	height: 8px;
	background-size: 16px 8px;
	background-position: right top;
	background-repeat: no-repeat;
`;

export const GraphBlock = styled.div`
	position: ${props => props.blockPosition || 'static'};
	bottom: ${props => props.blockBottomOffset || 0}px;
	left: ${props => props.blockLeftOffset || 0}px;
	background-color: ${props => props.blockColor || ''};
	background: ${props => props.background || ''};
	width: ${props => props.blockWidth || 50}px;
	height: ${props => props.blockHeight || 15}px;
	border-radius: ${props => props.blockBorderRadius || 0}px;
`;

export const GraphDifferenceText = styled.div`
	position: absolute;
	font-size: 13px;
	width: 150px;
	text-align: center;
	margin-bottom: 5px;

	span {
		font-size: 11px;
	}
`;

export const GraphFooter = styled.div`
	position: absolute;
	width: 150px;
	text-align: center;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
	bottom: -24px;
	white-space: nowrap;
`;

export const GraphLegend = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

export const GraphLegendTitle = styled.span`
	font-size: 9px;
	flex: 1;
	padding-left: 10px;
`;

export const UtilizationDataSection = styled.div`
	margin-top: 10px;
	width: 350px;
`;

export const UtilizationDataSectionHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-bottom: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	padding: 10px 0;
`;

export const UtilizationDataSectionTitle = styled.h4`
	font-size: 13px;
	font-weight: 500;
	margin-top: 0;
	margin-bottom: 8px;
`;

export const UtilizationDataSectionRowContainer = styled.div`
	padding: 14px 0 4px 0;
`;

export const UtilizationDataRowLink = styled.a`
	text-decoration: none;
`;

export const UtilizationDataRow = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	align-items: center;
`;

export const UtilizationDataRowName = styled.span`
	font-size: 13px;
	font-weight: 600;
	flex: 1;
	min-width: 140px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const UtilizationDataRowMinutesAllocated = styled.span`
	flex: 0 0 100px;
	font-size: 13px;
	font-weight: 600;
	text-align: right;
`;

export const UtilizationAllocationTypeRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-left: 85px;
	margin-bottom: 16px;
	font-size: 11px;
	font-weight: 400;
`;

export const Subtitle = styled.div`
	font-size: 9px;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
`;

export const ScrollContent = styled.div`
	padding-right: 35px;
`;

export const DistributionSection = styled.div`
	margin-top: 25px;

	.data-graph-container {
		display: flex;
		justify-content: space-between;

		.flex-container {
			display: flex;
			flex-direction: column;

			.utilization-data-section {
				margin-top: 5px;
				width: 350px;

				.utilization-data-section-header {
					border-bottom: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
					padding: 10px 0;
					font-size: 13px;
					font-weight: 500;
				}

				.utilization-data-section-row-container {
					padding: 14px 0;
					padding-bottom: 4px;

					a {
						text-decoration: none;
					}

					.utilization-data-section-row {
						display: flex;
						flex-direction: row;
						margin-bottom: 10px;
						align-items: center;

						.name {
							flex: 1;
							font-size: 12px;
							min-width: 120px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.minutes-allocated {
							flex: 0 0 100px;
							font-size: 12px;
							text-align: right;
						}
					}

					&.idle,
					&.time-off {
						.name {
							flex: 1 0 186px;
							margin-left: 0;
							width: 186px;
						}
					}
				}
			}
		}

		.graph-section {
			margin-left: 25px;

			.graph-header {
				display: flex;
				align-items: center;

				.graph-header-date {
					font-size: 13px;
					font-weight: 500;
					flex: 1;
					padding-right: 10px;
				}

				.graph-striped-grey-block {
					left: 74px;
					width: 15px;
					height: 15px;
					background: repeating-linear-gradient(135deg, #f7f7fe, #f7f7fe 3px, #d3d3df 3px, #d3d3df 6px);
				}

				.graph-header-soft-desc {
					font-size: 9px;
					flex: 1;
					padding-left: 10px;
				}
			}

			.graph {
				margin-top: 28px;
				margin-bottom: 22px;
				padding-left: 24px;
				position: relative;

				.graph-axis {
					width: 150px;
					height: 200px;
					border-left: solid 1px ${CSS_CONSTANTS.v2_button_very_light_grey};
					border-bottom: solid 1px ${CSS_CONSTANTS.v2_button_very_light_grey};
				}

				.graph-step {
					position: absolute;
					font-size: 10px;
					font-weight: 100;
					color: ${CSS_CONSTANTS.v2_text_light_gray};
					text-align: right;
					width: 100px;
					left: -86px;

					.infinity-sign-container {
						position: relative;

						.infinity-sign {
							position: absolute;
							background-image: url('images/components/scheduling/infinity-icon.svg');
							right: -2px;
							top: -12px;
							width: 16px;
							height: 8px;
							background-size: 16px 8px;
							background-position: right top;
							background-repeat: no-repeat;
						}
					}

					.line-container {
						position: relative;

						.line {
							position: absolute;
							height: 1px;
							width: 9px;
							bottom: 8px;
							right: -15px;
							background-color: ${CSS_CONSTANTS.v2_button_very_light_grey};
						}
					}
				}

				.working-hours-line {
					position: absolute;
					width: 150px;
					border-top: 1px dashed ${CSS_CONSTANTS.v2_button_very_light_grey};
					z-index: 100;
				}

				.graph-grey-block {
					position: absolute;
					bottom: 0;
					left: 74px;
					background-color: ${CSS_CONSTANTS.v2_button_very_light_grey};
					width: 50px;
				}

				.graph-green-block {
					position: absolute;
					bottom: 0;
					left: 74px;
					background-color: ${CSS_CONSTANTS.schedule_v2_green};
					width: 50px;
				}

				.graph-striped-green-block {
					position: absolute;
					bottom: 0;
					left: 74px;
					width: 50px;
					background: repeating-linear-gradient(135deg, #9be5a3, #9be5a3 5px, #b5ecba 5px, #b5ecba 10px);
				}

				.graph-striped-red-block {
					position: absolute;
					bottom: 0;
					left: 74px;
					width: 50px;
					z-index: 100;
					background: repeating-linear-gradient(135deg, #ffcaca, #ffcaca 5px, #feb6b3 5px, #feb6b3 10px);
				}

				.graph-red-block {
					position: absolute;
					bottom: 0;
					left: 74px;
					background-color: ${CSS_CONSTANTS.schedule_v2_red};
					width: 50px;
				}

				.difference-text {
					position: absolute;
					font-size: 13px;
					width: 150px;
					text-align: center;
					margin-bottom: 5px;

					span {
						font-size: 11px;
					}
				}

				.graph-footer {
					position: absolute;
					width: 150px;
					text-align: center;
					font-size: 11px;
					font-weight: 500;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: ${CSS_CONSTANTS.v2_text_light_gray};
					bottom: -24px;
					white-space: nowrap;
				}
			}
		}
	}
`;

export const EmptyState = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 13px;

	.message {
		margin-top: 15px;
		font-weight: 500;
	}
`;
