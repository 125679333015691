/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSkillInput = {|
  name?: ?string,
  categoryId?: ?string,
  skillPersons?: ?$ReadOnlyArray<?SkillPersonInputType>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type SkillPersonInputType = {|
  personId?: ?string,
  skillId?: ?string,
  skillLevelId?: ?string,
|};
export type createSkillMutationVariables = {|
  input: CreateSkillInput
|};
export type createSkillMutationResponse = {|
  +createSkill: ?{|
    +skill: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +category: ?{|
          +id: string
        |},
        +skillPersons: ?$ReadOnlyArray<?{|
          +person: ?{|
            +id: string,
            +role: ?{|
              +id: string
            |},
            +department: ?{|
              +id: string
            |},
            +active: ?boolean,
            +permissions: ?$ReadOnlyArray<?string>,
          |},
          +level: ?{|
            +id: string
          |},
        |}>,
      |}
    |}
  |}
|};
export type createSkillMutation = {|
  variables: createSkillMutationVariables,
  response: createSkillMutationResponse,
|};
*/


/*
mutation createSkillMutation(
  $input: CreateSkillInput!
) {
  createSkill(input: $input) {
    skill {
      node {
        id
        name
        category {
          id
        }
        skillPersons {
          person {
            id
            role {
              id
            }
            department {
              id
            }
            active
            permissions
          }
          level {
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSkillInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DepartmentType",
      "kind": "LinkedField",
      "name": "department",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillLevel",
  "kind": "LinkedField",
  "name": "level",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSkillPayload",
        "kind": "LinkedField",
        "name": "createSkill",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillTypeEdge",
            "kind": "LinkedField",
            "name": "skill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Skill",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillPerson",
                    "kind": "LinkedField",
                    "name": "skillPersons",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createSkillMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSkillPayload",
        "kind": "LinkedField",
        "name": "createSkill",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillTypeEdge",
            "kind": "LinkedField",
            "name": "skill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Skill",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillPerson",
                    "kind": "LinkedField",
                    "name": "skillPersons",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createSkillMutation",
    "operationKind": "mutation",
    "text": "mutation createSkillMutation(\n  $input: CreateSkillInput!\n) {\n  createSkill(input: $input) {\n    skill {\n      node {\n        id\n        name\n        category {\n          id\n        }\n        skillPersons {\n          person {\n            id\n            role {\n              id\n            }\n            department {\n              id\n            }\n            active\n            permissions\n          }\n          level {\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49ca062de637e13682b991f25b163818';

module.exports = node;
