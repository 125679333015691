/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateGoogleCalendarTokenInput = {|
  personId?: ?string,
  authorizationCode?: ?string,
  clientMutationId?: ?string,
|};
export type updateGoogleCalendarTokenMutationVariables = {|
  input: UpdateGoogleCalendarTokenInput
|};
export type updateGoogleCalendarTokenMutationResponse = {|
  +updateGoogleCalendarToken: ?{|
    +viewer: ?{|
      +calendarIntegrations: ?{|
        +googleCalendar: ?{|
          +calendarUser: ?{|
            +name: ?string,
            +email: ?string,
            +picture: ?string,
          |},
          +calendarTokenError: ?string,
          +calendarFetchEnabled: ?boolean,
          +calendarViewPrivateEvents: ?boolean,
          +enabledCalendars: ?$ReadOnlyArray<?string>,
          +calendars: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +description: ?string,
                +backgroundColor: ?string,
                +foregroundColor: ?string,
              |}
            |}>
          |},
        |}
      |}
    |},
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type updateGoogleCalendarTokenMutation = {|
  variables: updateGoogleCalendarTokenMutationVariables,
  response: updateGoogleCalendarTokenMutationResponse,
|};
*/


/*
mutation updateGoogleCalendarTokenMutation(
  $input: UpdateGoogleCalendarTokenInput!
) {
  updateGoogleCalendarToken(input: $input) {
    viewer {
      calendarIntegrations {
        googleCalendar {
          calendarUser {
            name
            email
            picture
          }
          calendarTokenError
          calendarFetchEnabled
          calendarViewPrivateEvents
          enabledCalendars
          calendars {
            edges {
              node {
                id
                name
                description
                backgroundColor
                foregroundColor
              }
            }
          }
          id
        }
        id
      }
      id
    }
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateGoogleCalendarTokenInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CalendarUser",
  "kind": "LinkedField",
  "name": "calendarUser",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "picture",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calendarTokenError",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calendarFetchEnabled",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calendarViewPrivateEvents",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabledCalendars",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "CalendarTypeConnection",
  "kind": "LinkedField",
  "name": "calendars",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CalendarTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Calendar",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v8/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "backgroundColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "foregroundColor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateGoogleCalendarTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGoogleCalendarTokenPayload",
        "kind": "LinkedField",
        "name": "updateGoogleCalendarToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CalendarIntegrations",
                "kind": "LinkedField",
                "name": "calendarIntegrations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CalendarIntegration",
                    "kind": "LinkedField",
                    "name": "googleCalendar",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateGoogleCalendarTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGoogleCalendarTokenPayload",
        "kind": "LinkedField",
        "name": "updateGoogleCalendarToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CalendarIntegrations",
                "kind": "LinkedField",
                "name": "calendarIntegrations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CalendarIntegration",
                    "kind": "LinkedField",
                    "name": "googleCalendar",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateGoogleCalendarTokenMutation",
    "operationKind": "mutation",
    "text": "mutation updateGoogleCalendarTokenMutation(\n  $input: UpdateGoogleCalendarTokenInput!\n) {\n  updateGoogleCalendarToken(input: $input) {\n    viewer {\n      calendarIntegrations {\n        googleCalendar {\n          calendarUser {\n            name\n            email\n            picture\n          }\n          calendarTokenError\n          calendarFetchEnabled\n          calendarViewPrivateEvents\n          enabledCalendars\n          calendars {\n            edges {\n              node {\n                id\n                name\n                description\n                backgroundColor\n                foregroundColor\n              }\n            }\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fce3d7ca3599a7076df3a55147e0551b';

module.exports = node;
