import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updatePlaceholderMutation($input: UpdatePlaceholderInput!) {
		updatePlaceholder(input: $input) {
			placeholder {
				node {
					id
					name
					role {
						id
						name
					}
					project {
						id
					}
					projectGroupId
					skills {
						edges {
							node {
								id
							}
						}
					}
					skillPlaceholders {
						skill {
							id
						}
						level {
							id
						}
					}
					departmentId
					teamPlaceholders {
						teamId
					}
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	return {
		updatePlaceholder: {
			placeholder: {
				node: {
					...input,
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Placeholder Updated');

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		optimisticResponse: getOptimisticResponse(cleanedInput),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
