/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type DuplicateProjectInput = {|
  projectId?: ?string,
  projectColor?: ?string,
  customProjectId?: ?string,
  duplicateSettings?: ?boolean,
  duplicateTasks?: ?boolean,
  duplicatePhases?: ?boolean,
  duplicateProjectPersons?: ?boolean,
  duplicateExpenses?: ?boolean,
  duplicateColor?: ?boolean,
  duplicatePlaceholders?: ?boolean,
  duplicatePlaceholderAllocations?: ?boolean,
  duplicateAllocations?: ?boolean,
  duplicateRetainerPeriods?: ?boolean,
  name?: ?string,
  projectStartDay?: ?number,
  projectStartMonth?: ?number,
  projectStartYear?: ?number,
  projectEndDay?: ?number,
  projectEndMonth?: ?number,
  projectEndYear?: ?number,
  useManualAllocations?: ?boolean,
  budgetType?: ?BUDGET_TYPE,
  budget?: ?number,
  clientId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type duplicateProjectMutationVariables = {|
  input: DuplicateProjectInput
|};
export type duplicateProjectMutationResponse = {|
  +duplicateProject: ?{|
    +errors: ?$ReadOnlyArray<?string>,
    +project: ?{|
      +node: ?{|
        +id: string,
        +companyProjectId: ?number,
        +customProjectId: ?string,
        +status: ?PROJECT_STATUS,
        +name: ?string,
        +isJiraProject: ?boolean,
        +projectColor: ?string,
        +isInProjectGroup: ?boolean,
        +billable: ?boolean,
        +budgetType: ?BUDGET_TYPE,
        +projectGroupId: ?string,
        +sprintTimeBox: ?boolean,
        +harvestProject: ?{|
          +id: string
        |},
        +statusColumnsV2: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +projectGroupStatusColumnId: ?number,
            |}
          |}>
        |},
        +projectPersons: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +isContactPerson: ?boolean,
              +person: ?{|
                +id: string,
                +firstName: ?string,
                +lastName: ?string,
                +profilePictureId: ?string,
              |},
            |}
          |}>
        |},
      |}
    |},
  |}
|};
export type duplicateProjectMutation = {|
  variables: duplicateProjectMutationVariables,
  response: duplicateProjectMutationResponse,
|};
*/


/*
mutation duplicateProjectMutation(
  $input: DuplicateProjectInput!
) {
  duplicateProject(input: $input) {
    errors
    project {
      node {
        id
        companyProjectId
        customProjectId
        status
        name
        isJiraProject
        projectColor
        isInProjectGroup
        billable
        budgetType
        projectGroupId
        sprintTimeBox
        harvestProject {
          id
        }
        statusColumnsV2(first: 1000000) {
          edges {
            node {
              id
              projectGroupStatusColumnId
            }
          }
        }
        projectPersons(first: 1000000, contactsOnly: true) {
          edges {
            node {
              id
              isContactPerson
              person {
                id
                firstName
                lastName
                profilePictureId
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DuplicateProjectInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000000
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DuplicateProjectPayload",
    "kind": "LinkedField",
    "name": "duplicateProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectTypeEdge",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyProjectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customProjectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isJiraProject",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInProjectGroup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "billable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budgetType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectGroupId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sprintTimeBox",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HarvestProjectType",
                "kind": "LinkedField",
                "name": "harvestProject",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v2/*: any*/)
                ],
                "concreteType": "StatusColumnV2TypeConnection",
                "kind": "LinkedField",
                "name": "statusColumnsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StatusColumnV2TypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StatusColumnV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "projectGroupStatusColumnId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "statusColumnsV2(first:1000000)"
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "contactsOnly",
                    "value": true
                  },
                  (v2/*: any*/)
                ],
                "concreteType": "ProjectPersonTypeConnection",
                "kind": "LinkedField",
                "name": "projectPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectPersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectPerson",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isContactPerson",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Person",
                            "kind": "LinkedField",
                            "name": "person",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "profilePictureId",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "projectPersons(contactsOnly:true,first:1000000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "duplicateProjectMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "duplicateProjectMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "duplicateProjectMutation",
    "operationKind": "mutation",
    "text": "mutation duplicateProjectMutation(\n  $input: DuplicateProjectInput!\n) {\n  duplicateProject(input: $input) {\n    errors\n    project {\n      node {\n        id\n        companyProjectId\n        customProjectId\n        status\n        name\n        isJiraProject\n        projectColor\n        isInProjectGroup\n        billable\n        budgetType\n        projectGroupId\n        sprintTimeBox\n        harvestProject {\n          id\n        }\n        statusColumnsV2(first: 1000000) {\n          edges {\n            node {\n              id\n              projectGroupStatusColumnId\n            }\n          }\n        }\n        projectPersons(first: 1000000, contactsOnly: true) {\n          edges {\n            node {\n              id\n              isContactPerson\n              person {\n                id\n                firstName\n                lastName\n                profilePictureId\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '943a20161d13c5de925dc445f3ec3d31';

module.exports = node;
