import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateTasksBulkMutationModernMutation($input: UpdateTasksBulkInput!) {
		updateTasksBulk(input: $input) {
			tasks {
				id
				estimateForecast
				estimateForecastMinutes
				estimateForecastPrice
				timeLeft
				role {
					id
					name
				}
				assignedPersons {
					id
					active
					firstName
					lastName
					profilePictureId
					profilePictureDefaultId
					permissions
					client {
						id
					}
				}
				taskLabels {
					id
					label {
						id
						name
						color
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	Util.localStorageDeleteFincancialMap();
	const changes = [];
	for (const key of Object.keys(input)) {
		if (['tasks'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}
	tracking.trackEvent('Tasks Bulk Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables,
			configs: getConfigs(input),
			onCompleted: onSuccess,
			onError: onError,
		},
		true
	);
}

export default {commit};
