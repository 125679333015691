import { ConnectionHandler } from 'relay-runtime';
export const getStoreNodeById = ({ store, id }) => {
    return store.get(id);
};
/**
 * Relay doesn't support updating multiple edges in the store with RANGE_ADD.
 * This is a problem when bulk creating objects, as you can't easily update
 * the local cache with the newly created objects.
 *
 * It is therefore required to update the store manually using the updater
 * functions. This function creates an updater function just for that.
 *
 * Most of the arguments are known from RANGE_ADD, except for the mutation name,
 * which is just the name of the mutation itself.
 *
 * @param parentId      The global ID of the parent, where the newly created objects
 *                      should be attached to. The same as parentID in RANGE_ADD.
 * @param mutationName  The name of the mutation itself (f.ex "createBulkTasks")
 * @param edgesName     The name of the edges field on the response.
 *                      Corresponds to edgeName on RANGE_ADD, but for multiple edges.
 * @param connectionKey The connection key to identify where in the cache to add
 *                      the newly created objects. Corresponds to connectionInfo.key
 *                      in RANGE_ADD.
 * @returns {(function(store): void)} The updater function
 */
export const createRangeBulkAddUpdater = (parentId, mutationName, edgesName, connectionKey) => (store) => {
    if (!parentId)
        return;
    const parentProxy = store.get(parentId);
    if (!parentProxy)
        return;
    const payload = store.getRootField(mutationName);
    if (!payload)
        return;
    const edgesProxy = payload.getLinkedRecords(edgesName);
    if (!edgesProxy)
        return;
    const connection = ConnectionHandler.getConnection(parentProxy, connectionKey);
    if (!connection)
        return;
    edgesProxy.forEach(edgeProxy => {
        ConnectionHandler.insertEdgeAfter(connection, edgeProxy);
    });
};
/**
 * Behaves like RANGE_ADD
 */
export const addEdgeToConnection = ({ parentNode, connectionName, newEdge }) => {
    const connection = ConnectionHandler.getConnection(parentNode, connectionName);
    if (connection) {
        ConnectionHandler.insertEdgeAfter(connection, newEdge);
    }
};
export const replaceEdgesOnConnection = ({ store, edges, typeName, parentNode, connectionName, }) => {
    const newEdges = [];
    edges.forEach(edge => {
        const newEdge = store.create(`client:newEdge:${edge.getDataID()}`, typeName);
        newEdge.copyFieldsFrom(edge);
        newEdges.push(newEdge);
    });
    const connection = ConnectionHandler.getConnection(parentNode, connectionName);
    if (connection) {
        connection.setLinkedRecords(newEdges, 'edges');
    }
};
/**
 * Behaves like RANGE_DELETE
 */
export const removeEdgesFromConnectionByDeletedIds = ({ parentNode, connectionName, deletedIds, }) => {
    const connection = ConnectionHandler.getConnection(parentNode, connectionName);
    if (connection && deletedIds) {
        deletedIds.forEach(deletedId => {
            ConnectionHandler.deleteNode(connection, deletedId);
        });
    }
};
/**
 * Helper function to get all RecordProxys in a connection and run a callback for each.
 */
export const mutateAllEdgesInConnection = ({ parentNode, connectionName, callback }) => {
    const connection = ConnectionHandler.getConnection(parentNode, connectionName);
    if (!connection)
        return;
    const connectionEdges = connection.getLinkedRecords('edges');
    if (!connectionEdges)
        return;
    connectionEdges.forEach(connectionEdge => {
        const connectionNode = connectionEdge.getLinkedRecord('node');
        if (connectionNode) {
            callback(connectionNode);
        }
    });
};
