import React from 'react';
import PropTypes from 'prop-types';
import {EVENT_ID, subscribe, unsubscribe} from '../containers/event_manager';
import {withRouter} from 'react-router-dom';
import Util from '../forecast-app/shared/util/util';
import * as Sentry from '@sentry/browser';

export function withSocketHandling(WrappedComponent) {
	// ...and returns another component...
	class WithSocketHandling extends React.Component {
		constructor(props) {
			super(props);
			this.handleSocketNotify = this.handleSocketNotify.bind(this);

			this.setSocketConfig = this.setSocketConfig.bind(this);
			this.setSocketVariables = this.setSocketVariables.bind(this);

			this.updatePending = false;

			document.addEventListener('visibilitychange', event => {
				if (!document.hidden) {
					// Is visible
					if (this.updatePending) {
						//Have pending update. Update now!
						this.updateComponent();
					}
					this.updatePending = false;
				}
			});
		}

		componentDidMount() {
			// ... that takes care of the subscription...
			subscribe(EVENT_ID.SOCKET_NOTIFY, this.handleSocketNotify);
		}

		componentWillUnmount() {
			unsubscribe(EVENT_ID.SOCKET_NOTIFY, this.handleSocketNotify);
		}

		setSocketConfig(config, refetchFunc) {
			this.config = config.map(c => {
				if (c.personIds && !Array.isArray(c.personIds)) {
					c.personIds = [c.personIds];
				}
				if (c.projectIds && !Array.isArray(c.projectIds)) {
					c.projectIds = [c.projectIds];
				}
				if (c.taskIds && !Array.isArray(c.taskIds)) {
					c.taskIds = [c.taskIds];
				}
				if (c.statusColumnIds && !Array.isArray(c.statusColumnIds)) {
					c.statusColumnIds = [c.statusColumnIds];
				}
				return c;
			});

			if (refetchFunc) {
				this.refetchFunc = refetchFunc;
			}
		}

		setSocketVariables(variables) {
			this.refetchVariables = variables;
		}

		handleSocketNotify(dataList) {
			if (!this.config) return;
			let shouldUpdate = false;
			let notFoundRedirect = false;
			// Go through list of events to see if this component should update
			dataList.forEach(data => {
				this.config.forEach(event => {
					// Check if component is listening for this particular event
					if (event.type === data.eventType && event.action === data.eventAction) {
						let personsValid = true;
						if (!!event.personIds) {
							personsValid =
								!data.personIds ||
								event.personIds.some(p => data.personIds.some(dataId => dataId === p) || p === 'ALL');
						}
						let projectsValid = true;
						if (!!event.projectIds) {
							projectsValid = !!(
								event.projectIds &&
								data.projectIds &&
								event.projectIds.some(p => data.projectIds.some(dataId => dataId === p))
							);
						}

						let tasksValid = true;
						if (!!event.taskIds) {
							tasksValid =
								event.taskIds &&
								data.taskIds &&
								event.taskIds.some(p => data.taskIds.some(dataId => dataId === p) || p === 'ALL');
						}

						let statusColumnValid = true;
						if (!!event.statusColumnIds && !!data.statusColumnIds) {
							statusColumnValid =
								event.statusColumnIds &&
								data.statusColumnIds &&
								event.statusColumnIds.some(
									p => data.statusColumnIds.some(dataId => dataId === p) || p === 'ALL'
								);
						}

						if (personsValid && projectsValid && tasksValid && statusColumnValid) {
							shouldUpdate = true;
							Sentry.addBreadcrumb({
								data: {
									eventType: data.eventType,
									action: data.eventAction,
									projectIds: data.projectIds,
									personIds: data.personIds,
									taskIds: data.taskIds,
									statusColumnIds: data.statusColumnIds,
								},
								category: 'socketEventData',
								level: 'debug',
							});
						}

						// Redirect to not-found if event is PROJECT DELETE and user is in that project
						if (
							data.projectIds &&
							data.projectIds.length === 1 &&
							event.projectIds &&
							event.projectIds[0] === data.projectIds[0] &&
							data.eventType === 'PROJECT' &&
							data.eventAction === 'DELETE'
						) {
							notFoundRedirect = true;
						}
					}
				});
			});
			if (notFoundRedirect) {
				Util.validateProjectUrl(this.props.history);
			}
			if (shouldUpdate) {
				if (document.hidden) {
					// Page not visible. Set update pending flag
					this.updatePending = true;
				} else {
					// Page visible. Updating as normal
					this.updateComponent();
				}
			}
		}

		updateComponent() {
			if (this.refetchFunc && this.refetchVariables) {
				this.refetchFunc(this.refetchVariables);
			} else if (this.props.retry) {
				this.props.retry();
			} else {
				Sentry.captureMessage(
					'Missing relay.forceFetch on query: ' +
						this.props.viewer.component +
						' - ' +
						getDisplayName(WrappedComponent)
				);
			}
		}

		render() {
			// ... and renders the wrapped component with the fresh data!
			// Notice that we pass through any additional props
			return (
				<WrappedComponent
					{...this.props}
					setSocketConfig={this.setSocketConfig}
					setSocketVariables={this.setSocketVariables}
				/>
			);
		}
	}

	WithSocketHandling.propTypes = {
		retry: PropTypes.func,
	};

	WithSocketHandling.displayName = `WithSocketHandling(${getDisplayName(WrappedComponent)})`;
	return withRouter(WithSocketHandling);
}

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
