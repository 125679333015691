/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssignMultiplePersonsToMultipleSkillsInput = {|
  skillIds?: ?$ReadOnlyArray<?string>,
  personIds?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type assignMultiplePersonsToMultipleSkillsMutationVariables = {|
  input: AssignMultiplePersonsToMultipleSkillsInput
|};
export type assignMultiplePersonsToMultipleSkillsMutationResponse = {|
  +assignMultiplePersonsToMultipleSkills: ?{|
    +skills: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +persons: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +fullName: ?string,
          |}
        |}>
      |},
    |}>
  |}
|};
export type assignMultiplePersonsToMultipleSkillsMutation = {|
  variables: assignMultiplePersonsToMultipleSkillsMutationVariables,
  response: assignMultiplePersonsToMultipleSkillsMutationResponse,
|};
*/


/*
mutation assignMultiplePersonsToMultipleSkillsMutation(
  $input: AssignMultiplePersonsToMultipleSkillsInput!
) {
  assignMultiplePersonsToMultipleSkills(input: $input) {
    skills {
      id
      name
      persons {
        edges {
          node {
            id
            fullName
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AssignMultiplePersonsToMultipleSkillsInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AssignMultiplePersonsToMultipleSkillsPayload",
    "kind": "LinkedField",
    "name": "assignMultiplePersonsToMultipleSkills",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "skills",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonTypeConnection",
            "kind": "LinkedField",
            "name": "persons",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "assignMultiplePersonsToMultipleSkillsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "assignMultiplePersonsToMultipleSkillsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "assignMultiplePersonsToMultipleSkillsMutation",
    "operationKind": "mutation",
    "text": "mutation assignMultiplePersonsToMultipleSkillsMutation(\n  $input: AssignMultiplePersonsToMultipleSkillsInput!\n) {\n  assignMultiplePersonsToMultipleSkills(input: $input) {\n    skills {\n      id\n      name\n      persons {\n        edges {\n          node {\n            id\n            fullName\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b500904b0e339f3b9563bded285838cf';

module.exports = node;
