import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation DeleteCompanyContactMutation($input: DeleteCompanyContactInput!) {
		deleteCompanyContact(input: $input) {
			deletedContactId
		}
	}
`;

function getConfigs(companyId) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: companyId,
			connectionKeys: [
				{
					key: 'Company_contactPersons',
				},
			],
			pathToConnection: ['company', 'contactPersons'],
			deletedIDFieldName: 'deletedContactId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Company Contact Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				contactType: input.contactType,
			},
		},
		configs: getConfigs(input.companyId),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
