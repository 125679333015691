/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsIdleTimes_idleTime$ref = any;
export type CreateIdleTimeInput = {|
  name?: ?string,
  isInternalTime?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createIdleTimeMutationVariables = {|
  input: CreateIdleTimeInput
|};
export type createIdleTimeMutationResponse = {|
  +createIdleTime: ?{|
    +idleTime: ?{|
      +node: ?{|
        +$fragmentRefs: settingsIdleTimes_idleTime$ref
      |}
    |}
  |}
|};
export type createIdleTimeMutation = {|
  variables: createIdleTimeMutationVariables,
  response: createIdleTimeMutationResponse,
|};
*/


/*
mutation createIdleTimeMutation(
  $input: CreateIdleTimeInput!
) {
  createIdleTime(input: $input) {
    idleTime {
      node {
        ...settingsIdleTimes_idleTime
        id
      }
    }
  }
}

fragment settingsIdleTimes_idleTime on IdleTime {
  id
  name
  isInternalTime
  disabled
  timeRegistrations(first: 1) {
    edges {
      node {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateIdleTimeInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createIdleTimeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateIdleTimePayload",
        "kind": "LinkedField",
        "name": "createIdleTime",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IdleTimeTypeEdge",
            "kind": "LinkedField",
            "name": "idleTime",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IdleTime",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "settingsIdleTimes_idleTime"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createIdleTimeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateIdleTimePayload",
        "kind": "LinkedField",
        "name": "createIdleTime",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IdleTimeTypeEdge",
            "kind": "LinkedField",
            "name": "idleTime",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IdleTime",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isInternalTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "disabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                      }
                    ],
                    "concreteType": "TimeRegTypeConnection",
                    "kind": "LinkedField",
                    "name": "timeRegistrations",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TimeRegTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TimeRegType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "timeRegistrations(first:1)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createIdleTimeMutation",
    "operationKind": "mutation",
    "text": "mutation createIdleTimeMutation(\n  $input: CreateIdleTimeInput!\n) {\n  createIdleTime(input: $input) {\n    idleTime {\n      node {\n        ...settingsIdleTimes_idleTime\n        id\n      }\n    }\n  }\n}\n\nfragment settingsIdleTimes_idleTime on IdleTime {\n  id\n  name\n  isInternalTime\n  disabled\n  timeRegistrations(first: 1) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b59c1a824aef81f3c75285d6e2342baa';

module.exports = node;
