import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteGithubAttachedObjectMutation($input: DeleteGithubAttachedObjectInput!) {
		deleteGithubAttachedObject(input: $input) {
			deletedGithubAttachedObjectId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.taskId,
			connectionKeys: [
				{
					key: 'Task_githubAttachedObjects',
				},
			],
			pathToConnection: ['task', 'githubAttachedObjects'],
			deletedIDFieldName: 'deletedGithubAttachedObjectId',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		deleteGithubAttachedObject: {
			deletedGithubAttachedObjectId: input.id,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Github Object Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), id: input.id, taskId: input.taskId},
		},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
