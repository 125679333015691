import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation UpdateApprovalStatusMutation($input: UpdateApprovalStatusInput!) {
		updateApprovalStatus(input: $input) {
			timeRegs {
				id
				approvalStatus
				noApprovalStatusChange
				lockedInPeriod
			}
			persons {
				id
				submitLockedDateYear
				submitLockedDateMonth
				submitLockedDateDay
			}
			errors
		}
	}
`;

function getOptimisticResponse(input) {
	// TODO: Maybe use optimisticTimeRegIds?
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['personIds'].includes(key) || input[key] === undefined) {
			continue;
		}
		changes.push(key);
	}
	tracking.trackEvent('Approval Status updated', {_Changed: changes});

	const variables = {
		input: {
			csrfToken: Util.getCsrfValue(),
			socketClientId: Util.getClientId(),
			...input,
		},
	};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
