/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatePersonNotificationInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  publisherPersonId?: ?number,
  subscriberPersonId?: ?number,
  entityType?: ?string,
  entityId?: ?number,
  publisherAction?: ?string,
  params?: ?string,
  createdAt?: ?string,
  all?: ?boolean,
  read?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updatePersonNotificationMutationVariables = {|
  input: UpdatePersonNotificationInput
|};
export type updatePersonNotificationMutationResponse = {|
  +updatePersonNotification: ?{|
    +viewer: ?{|
      +recentNotifications: ?$ReadOnlyArray<?{|
        +id: string,
        +countId: ?number,
        +publisherPersonId: string,
        +subscriberPersonId: string,
        +publisherAction: ?string,
        +entityType: ?string,
        +entityId: ?number,
        +params: ?string,
        +createdAt: ?string,
        +read: ?boolean,
      |}>,
      +unseenNotifications: ?number,
    |},
    +PersonNotifications: ?$ReadOnlyArray<?{|
      +id: string,
      +read: ?boolean,
    |}>,
  |}
|};
export type updatePersonNotificationMutation = {|
  variables: updatePersonNotificationMutationVariables,
  response: updatePersonNotificationMutationResponse,
|};
*/


/*
mutation updatePersonNotificationMutation(
  $input: UpdatePersonNotificationInput!
) {
  updatePersonNotification(input: $input) {
    viewer {
      recentNotifications {
        id
        countId
        publisherPersonId
        subscriberPersonId
        publisherAction
        entityType
        entityId
        params
        createdAt
        read
      }
      unseenNotifications
      id
    }
    PersonNotifications {
      id
      read
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdatePersonNotificationInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "read",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PersonNotification",
  "kind": "LinkedField",
  "name": "recentNotifications",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publisherPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriberPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publisherAction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "params",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unseenNotifications",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PersonNotification",
  "kind": "LinkedField",
  "name": "PersonNotifications",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePersonNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePersonNotificationPayload",
        "kind": "LinkedField",
        "name": "updatePersonNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePersonNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePersonNotificationPayload",
        "kind": "LinkedField",
        "name": "updatePersonNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updatePersonNotificationMutation",
    "operationKind": "mutation",
    "text": "mutation updatePersonNotificationMutation(\n  $input: UpdatePersonNotificationInput!\n) {\n  updatePersonNotification(input: $input) {\n    viewer {\n      recentNotifications {\n        id\n        countId\n        publisherPersonId\n        subscriberPersonId\n        publisherAction\n        entityType\n        entityId\n        params\n        createdAt\n        read\n      }\n      unseenNotifications\n      id\n    }\n    PersonNotifications {\n      id\n      read\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '06eb7d55a2632c6f93e61cdfc91cf0b3';

module.exports = node;
