/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSkillCategoryInput = {|
  id: string,
  name?: ?string,
  assignedSkills?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateSkillCategoryMutationVariables = {|
  input: UpdateSkillCategoryInput
|};
export type updateSkillCategoryMutationResponse = {|
  +updateSkillCategory: ?{|
    +skillCategory: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +skills: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +category: ?{|
                +id: string
              |},
              +persons: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +role: ?{|
                      +id: string
                    |},
                    +department: ?{|
                      +id: string
                    |},
                    +active: ?boolean,
                    +permissions: ?$ReadOnlyArray<?string>,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |},
    +skills: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +category: ?{|
        +id: string
      |},
    |}>,
  |}
|};
export type updateSkillCategoryMutation = {|
  variables: updateSkillCategoryMutationVariables,
  response: updateSkillCategoryMutationResponse,
|};
*/


/*
mutation updateSkillCategoryMutation(
  $input: UpdateSkillCategoryInput!
) {
  updateSkillCategory(input: $input) {
    skillCategory {
      node {
        id
        name
        skills {
          edges {
            node {
              id
              name
              category {
                id
              }
              persons {
                edges {
                  node {
                    id
                    role {
                      id
                    }
                    department {
                      id
                    }
                    active
                    permissions
                  }
                }
              }
            }
          }
        }
      }
    }
    skills {
      id
      name
      category {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSkillCategoryInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSkillCategoryPayload",
    "kind": "LinkedField",
    "name": "updateSkillCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillCategoryTypeEdge",
        "kind": "LinkedField",
        "name": "skillCategory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SkillCategory",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SkillTypeConnection",
                "kind": "LinkedField",
                "name": "skills",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PersonTypeConnection",
                            "kind": "LinkedField",
                            "name": "persons",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PersonTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Person",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Role",
                                        "kind": "LinkedField",
                                        "name": "role",
                                        "plural": false,
                                        "selections": (v3/*: any*/),
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "DepartmentType",
                                        "kind": "LinkedField",
                                        "name": "department",
                                        "plural": false,
                                        "selections": (v3/*: any*/),
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "active",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "permissions",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Skill",
        "kind": "LinkedField",
        "name": "skills",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateSkillCategoryMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateSkillCategoryMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateSkillCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation updateSkillCategoryMutation(\n  $input: UpdateSkillCategoryInput!\n) {\n  updateSkillCategory(input: $input) {\n    skillCategory {\n      node {\n        id\n        name\n        skills {\n          edges {\n            node {\n              id\n              name\n              category {\n                id\n              }\n              persons {\n                edges {\n                  node {\n                    id\n                    role {\n                      id\n                    }\n                    department {\n                      id\n                    }\n                    active\n                    permissions\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    skills {\n      id\n      name\n      category {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac11017c9bb6b4595982add44011749e';

module.exports = node;
