import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation ValidateProjectIdMutation($input: ValidateProjectIdInput!) {
		validateProjectId(input: $input) {
			valid
		}
	}
`;

function getConfigs() {
	return [];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		uploadables,
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
