/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation DeleteDeliverableMutation(
  $input: DeleteDeliverableInput!
) {
  deleteDeliverable(input: $input) {
    deletedDeliverableId
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "DeleteDeliverableInput!"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "DeleteDeliverablePayload",
            "kind": "LinkedField",
            "name": "deleteDeliverable",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deletedDeliverableId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteDeliverableMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteDeliverableMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "DeleteDeliverableMutation",
            "operationKind": "mutation",
            "text": "mutation DeleteDeliverableMutation(\n  $input: DeleteDeliverableInput!\n) {\n  deleteDeliverable(input: $input) {\n    deletedDeliverableId\n  }\n}\n"
        }
    };
})();
node.hash = '3fdf521d0f1fae6936d42c4da1e83825';
export default node;
