/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DisableAsanaInput = {|
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type disableAsanaMutationVariables = {|
  input: DisableAsanaInput
|};
export type disableAsanaMutationResponse = {|
  +disableAsana: ?{|
    +viewer: ?{|
      +asanaEnabled: ?boolean
    |}
  |}
|};
export type disableAsanaMutation = {|
  variables: disableAsanaMutationVariables,
  response: disableAsanaMutationResponse,
|};
*/


/*
mutation disableAsanaMutation(
  $input: DisableAsanaInput!
) {
  disableAsana(input: $input) {
    viewer {
      asanaEnabled
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DisableAsanaInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "asanaEnabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "disableAsanaMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DisableAsanaPayload",
        "kind": "LinkedField",
        "name": "disableAsana",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "disableAsanaMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DisableAsanaPayload",
        "kind": "LinkedField",
        "name": "disableAsana",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "disableAsanaMutation",
    "operationKind": "mutation",
    "text": "mutation disableAsanaMutation(\n  $input: DisableAsanaInput!\n) {\n  disableAsana(input: $input) {\n    viewer {\n      asanaEnabled\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a097989b51664419395a439e503bc3b5';

module.exports = node;
