/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LANGUAGE = "DANISH" | "ENGLISH_EU" | "ENGLISH_UK" | "ENGLISH_US" | "FRENCH" | "POLISH" | "SPANISH" | "%future added value";
export type SEAT_TYPE = "COLLABORATOR" | "CORE" | "FULL" | "VIRTUAL" | "%future added value";
export type CreatePersonInput = {|
  email?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  language?: ?LANGUAGE,
  profileIds?: ?$ReadOnlyArray<?string>,
  roleId?: ?string,
  invited?: ?boolean,
  clientId?: ?string,
  phone?: ?string,
  adminPassword?: ?string,
  clientUserAccessToProjectId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createPersonMutationModernMutationVariables = {|
  input: CreatePersonInput
|};
export type createPersonMutationModernMutationResponse = {|
  +createPerson: ?{|
    +person: ?{|
      +node: ?{|
        +id: string,
        +harvestUser: ?boolean,
        +economicUser: ?boolean,
        +initials: ?string,
        +seat: ?SEAT_TYPE,
        +gitlabUser: ?{|
          +integrationUserId: ?number
        |},
        +gitlabServerUser: ?{|
          +integrationUserId: ?number
        |},
        +githubUser: ?{|
          +integrationUserId: ?number
        |},
        +jiraServerUser: ?boolean,
        +jiraCloudUser: ?boolean,
        +asanaUser: ?boolean,
        +adoUserId: ?string,
        +firstName: ?string,
        +lastName: ?string,
        +fullName: ?string,
        +email: ?string,
        +phone: ?string,
        +profilePictureId: ?string,
        +profilePictureDefaultId: ?number,
        +role: ?{|
          +id: string,
          +name: ?string,
        |},
        +skills: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string
            |}
          |}>
        |},
        +skillPersons: ?$ReadOnlyArray<?{|
          +skill: ?{|
            +id: string
          |},
          +level: ?{|
            +id: string
          |},
        |}>,
        +profiles: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +seat: ?SEAT_TYPE,
            |}
          |}>
        |},
        +permissions: ?$ReadOnlyArray<?string>,
        +active: ?boolean,
        +isViewer: ?boolean,
        +costPeriods: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +startDay: ?number,
              +startMonth: ?number,
              +startYear: ?number,
              +cost: ?number,
            |}
          |}>
        |},
        +invited: ?boolean,
      |}
    |},
    +company: ?{|
      +userSeats: ?number,
      +virtualSeats: ?number,
    |},
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type createPersonMutationModernMutation = {|
  variables: createPersonMutationModernMutationVariables,
  response: createPersonMutationModernMutationResponse,
|};
*/


/*
mutation createPersonMutationModernMutation(
  $input: CreatePersonInput!
) {
  createPerson(input: $input) {
    person {
      node {
        id
        harvestUser
        economicUser
        initials
        seat
        gitlabUser {
          integrationUserId
        }
        gitlabServerUser {
          integrationUserId
        }
        githubUser {
          integrationUserId
        }
        jiraServerUser
        jiraCloudUser
        asanaUser
        adoUserId
        firstName
        lastName
        fullName
        email
        phone
        profilePictureId
        profilePictureDefaultId
        role {
          id
          name
        }
        skills {
          edges {
            node {
              id
            }
          }
        }
        skillPersons {
          skill {
            id
          }
          level {
            id
          }
          id
        }
        profiles(first: 10000) {
          edges {
            node {
              id
              name
              seat
            }
          }
        }
        permissions
        active
        isViewer
        costPeriods(first: 10000) {
          edges {
            node {
              id
              startDay
              startMonth
              startYear
              cost
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        invited
      }
    }
    company {
      userSeats
      virtualSeats
      id
    }
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreatePersonInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "harvestUser",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "economicUser",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initials",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seat",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integrationUserId",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "gitlabUser",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "gitlabServerUser",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "githubUser",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraServerUser",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraCloudUser",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "asanaUser",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adoUserId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v22/*: any*/)
  ],
  "storageKey": null
},
v24 = [
  (v2/*: any*/)
],
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillTypeConnection",
  "kind": "LinkedField",
  "name": "skills",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SkillTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v24/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skill",
  "plural": false,
  "selections": (v24/*: any*/),
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillLevel",
  "kind": "LinkedField",
  "name": "level",
  "plural": false,
  "selections": (v24/*: any*/),
  "storageKey": null
},
v28 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v29 = {
  "alias": null,
  "args": (v28/*: any*/),
  "concreteType": "ProfileTypeConnection",
  "kind": "LinkedField",
  "name": "profiles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Profile",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v22/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "profiles(first:10000)"
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isViewer",
  "storageKey": null
},
v33 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CostPeriodTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CostPeriod",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cost",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invited",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSeats",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "virtualSeats",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPersonMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePersonPayload",
        "kind": "LinkedField",
        "name": "createPerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonTypeEdge",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v23/*: any*/),
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillPerson",
                    "kind": "LinkedField",
                    "name": "skillPersons",
                    "plural": true,
                    "selections": [
                      (v26/*: any*/),
                      (v27/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/),
                  {
                    "alias": "costPeriods",
                    "args": null,
                    "concreteType": "CostPeriodTypeConnection",
                    "kind": "LinkedField",
                    "name": "__Person_costPeriods_connection",
                    "plural": false,
                    "selections": (v33/*: any*/),
                    "storageKey": null
                  },
                  (v34/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v35/*: any*/),
              (v36/*: any*/)
            ],
            "storageKey": null
          },
          (v37/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPersonMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePersonPayload",
        "kind": "LinkedField",
        "name": "createPerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonTypeEdge",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v23/*: any*/),
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SkillPerson",
                    "kind": "LinkedField",
                    "name": "skillPersons",
                    "plural": true,
                    "selections": [
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/),
                  {
                    "alias": null,
                    "args": (v28/*: any*/),
                    "concreteType": "CostPeriodTypeConnection",
                    "kind": "LinkedField",
                    "name": "costPeriods",
                    "plural": false,
                    "selections": (v33/*: any*/),
                    "storageKey": "costPeriods(first:10000)"
                  },
                  {
                    "alias": null,
                    "args": (v28/*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "Person_costPeriods",
                    "kind": "LinkedHandle",
                    "name": "costPeriods"
                  },
                  (v34/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v35/*: any*/),
              (v36/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v37/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "createPerson",
            "person",
            "node",
            "costPeriods"
          ]
        }
      ]
    },
    "name": "createPersonMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation createPersonMutationModernMutation(\n  $input: CreatePersonInput!\n) {\n  createPerson(input: $input) {\n    person {\n      node {\n        id\n        harvestUser\n        economicUser\n        initials\n        seat\n        gitlabUser {\n          integrationUserId\n        }\n        gitlabServerUser {\n          integrationUserId\n        }\n        githubUser {\n          integrationUserId\n        }\n        jiraServerUser\n        jiraCloudUser\n        asanaUser\n        adoUserId\n        firstName\n        lastName\n        fullName\n        email\n        phone\n        profilePictureId\n        profilePictureDefaultId\n        role {\n          id\n          name\n        }\n        skills {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n        skillPersons {\n          skill {\n            id\n          }\n          level {\n            id\n          }\n          id\n        }\n        profiles(first: 10000) {\n          edges {\n            node {\n              id\n              name\n              seat\n            }\n          }\n        }\n        permissions\n        active\n        isViewer\n        costPeriods(first: 10000) {\n          edges {\n            node {\n              id\n              startDay\n              startMonth\n              startYear\n              cost\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        invited\n      }\n    }\n    company {\n      userSeats\n      virtualSeats\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12fcc078fa003500d0947580620ce2db';

module.exports = node;
