import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation moveProjectOnTimelineMutation($input: MoveProjectOnTimelineInput!) {
		moveProjectOnTimeline(input: $input) {
			project {
				id
				projectStartYear
				projectStartMonth
				projectStartDay
				projectEndYear
				projectEndMonth
				projectEndDay
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const obj = {id: input.id};
	if (input.projectStartYear !== undefined) {
		obj.projectStartYear = input.projectStartYear;
		obj.projectStartMonth = input.projectStartMonth;
		obj.projectStartDay = input.projectStartDay;
	}
	if (input.projectEndYear !== undefined) {
		obj.projectEndYear = input.projectEndYear;
		obj.projectEndMonth = input.projectEndMonth;
		obj.projectEndDay = input.projectEndDay;
	}
	return {
		moveProjectOnTimeline: {
			project: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Project Moved On Timeline');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
