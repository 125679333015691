/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation StoreCustomMetricMutation(
  $input: StoreCustomMetricInput!
) {
  storeCustomMetric(input: $input) {
    clientMutationId
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "StoreCustomMetricInput!"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "StoreCustomMetricPayload",
            "kind": "LinkedField",
            "name": "storeCustomMetric",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "StoreCustomMetricMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "StoreCustomMetricMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "StoreCustomMetricMutation",
            "operationKind": "mutation",
            "text": "mutation StoreCustomMetricMutation(\n  $input: StoreCustomMetricInput!\n) {\n  storeCustomMetric(input: $input) {\n    clientMutationId\n  }\n}\n"
        }
    };
})();
node.hash = 'ab756db76024b3a54570b52c88220899';
export default node;
