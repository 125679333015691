import {commitMutation, graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import RelayEnvironment from '../../RelayEnvironment';

const mutation = graphql`
	mutation deleteBambooDuplicatesMutation($input: DeleteBambooDuplicatesInput!) {
		DeleteBambooDuplicates(input: $input) {
			companyId
			allocations {
				id
				startYear
				startMonth
				startDay
				endYear
				endMonth
				endDay
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(RelayEnvironment.getInstanceMC(), {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
