import { commitMutation, graphql } from 'react-relay';
import Util from '../../../../forecast-app/shared/util/util';
const mutation = graphql `
	mutation DeleteOutlookCalendarTokensMutation($input: DeleteOutlookCalendarTokensInput!) {
		deleteOutlookCalendarTokens(input: $input) {
			outlookCalendar {
				id
				calendarUser {
					name
					email
					picture
				}
				calendarTokenError
				enabledCalendars
				calendarFetchEnabled
				calendars {
					edges {
						node {
							id
							name
							description
							backgroundColor
							foregroundColor
						}
					}
				}
			}
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    const optimisticResponse = {
        deleteOutlookCalendarTokens: {
            outlookCalendar: {
                id: input.id,
                calendarUser: null,
                calendarTokenError: null,
                enabledCalendars: null,
                calendarFetchEnabled: false,
            },
        },
    };
    return commitMutation(environment, {
        mutation,
        variables,
        optimisticResponse,
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
