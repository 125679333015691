import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateLabelCategoryMutation($input: UpdateLabelCategoryInput!) {
		updateLabelCategory(input: $input) {
			labelCategory {
				node {
					id
					name
					allowOnTasks
					allowOnProjects
					allowOnPeople
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function getOptimisticResponse(input) {
	return {
		updateLabelCategory: {
			labelCategory: {
				node: {
					id: input.id,
					name: input.name,
					allowOnTasks: input.allowOnTasks,
					allowOnProjects: input.allowOnProjects,
					allowOnPeople: input.allowOnPeople,
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Label Category Updated');

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(cleanedInput),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
