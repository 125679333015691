/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MarkProjectAsFavoriteInput = {|
  projectId: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type markProjectAsFavoriteMutationVariables = {|
  input: MarkProjectAsFavoriteInput
|};
export type markProjectAsFavoriteMutationResponse = {|
  +markProjectAsFavorite: ?{|
    +project: ?{|
      +node: ?{|
        +id: string,
        +userRelationTypes: ?$ReadOnlyArray<?string>,
      |}
    |}
  |}
|};
export type markProjectAsFavoriteMutation = {|
  variables: markProjectAsFavoriteMutationVariables,
  response: markProjectAsFavoriteMutationResponse,
|};
*/


/*
mutation markProjectAsFavoriteMutation(
  $input: MarkProjectAsFavoriteInput!
) {
  markProjectAsFavorite(input: $input) {
    project {
      node {
        id
        userRelationTypes
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "MarkProjectAsFavoriteInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkProjectAsFavoritePayload",
    "kind": "LinkedField",
    "name": "markProjectAsFavorite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectServiceProjectTypeEdge",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectServiceProjectType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userRelationTypes",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "markProjectAsFavoriteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "markProjectAsFavoriteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "markProjectAsFavoriteMutation",
    "operationKind": "mutation",
    "text": "mutation markProjectAsFavoriteMutation(\n  $input: MarkProjectAsFavoriteInput!\n) {\n  markProjectAsFavorite(input: $input) {\n    project {\n      node {\n        id\n        userRelationTypes\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '50857c471ee017925e1adb06fefff7ab';

module.exports = node;
