/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteInsightInput = {|
  id: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteInsightMutationVariables = {|
  input: DeleteInsightInput
|};
export type deleteInsightMutationResponse = {|
  +deleteInsight: ?{|
    +deletedInsightId: ?string
  |}
|};
export type deleteInsightMutation = {|
  variables: deleteInsightMutationVariables,
  response: deleteInsightMutationResponse,
|};
*/


/*
mutation deleteInsightMutation(
  $input: DeleteInsightInput!
) {
  deleteInsight(input: $input) {
    deletedInsightId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteInsightInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteInsightPayload",
    "kind": "LinkedField",
    "name": "deleteInsight",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedInsightId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteInsightMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteInsightMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteInsightMutation",
    "operationKind": "mutation",
    "text": "mutation deleteInsightMutation(\n  $input: DeleteInsightInput!\n) {\n  deleteInsight(input: $input) {\n    deletedInsightId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f3ecee4ca46c7365ed8ea7557fe89526';

module.exports = node;
