/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteBambooDuplicatesInput = {|
  companyId: number,
  isDryRun?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteBambooDuplicatesMutationVariables = {|
  input: DeleteBambooDuplicatesInput
|};
export type deleteBambooDuplicatesMutationResponse = {|
  +DeleteBambooDuplicates: ?{|
    +companyId: number,
    +allocations: ?$ReadOnlyArray<?{|
      +id: string,
      +startYear: ?number,
      +startMonth: ?number,
      +startDay: ?number,
      +endYear: ?number,
      +endMonth: ?number,
      +endDay: ?number,
      +monday: ?number,
      +tuesday: ?number,
      +wednesday: ?number,
      +thursday: ?number,
      +friday: ?number,
      +saturday: ?number,
      +sunday: ?number,
    |}>,
  |}
|};
export type deleteBambooDuplicatesMutation = {|
  variables: deleteBambooDuplicatesMutationVariables,
  response: deleteBambooDuplicatesMutationResponse,
|};
*/


/*
mutation deleteBambooDuplicatesMutation(
  $input: DeleteBambooDuplicatesInput!
) {
  DeleteBambooDuplicates(input: $input) {
    companyId
    allocations {
      id
      startYear
      startMonth
      startDay
      endYear
      endMonth
      endDay
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteBambooDuplicatesInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteBambooDuplicatesPayload",
    "kind": "LinkedField",
    "name": "DeleteBambooDuplicates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Allocation",
        "kind": "LinkedField",
        "name": "allocations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "monday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tuesday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "wednesday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thursday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "friday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "saturday",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sunday",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteBambooDuplicatesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteBambooDuplicatesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteBambooDuplicatesMutation",
    "operationKind": "mutation",
    "text": "mutation deleteBambooDuplicatesMutation(\n  $input: DeleteBambooDuplicatesInput!\n) {\n  DeleteBambooDuplicates(input: $input) {\n    companyId\n    allocations {\n      id\n      startYear\n      startMonth\n      startDay\n      endYear\n      endMonth\n      endDay\n      monday\n      tuesday\n      wednesday\n      thursday\n      friday\n      saturday\n      sunday\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a13239719c8cbe551f4e4754cc9ceb14';

module.exports = node;
