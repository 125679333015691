import {commitMutation, graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';

const mutation = graphql`
	mutation updatePasswordMutation($input: UpdateViewerInput!) {
		updateViewer(input: $input) {
			errors
		}
	}
`;

function getConfigs() {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
