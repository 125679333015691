import styled from 'styled-components';
import loaderAnim from '../../../images/loaders/loader-animation.svg';

export const OverlayLoaderStyle = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(240, 240, 240, 0.75);
	z-index: 5;
	& div {
		position: relative;
		width: 100%;
		height: 100%;
		background-image: url(${loaderAnim});
		background-repeat: no-repeat;
		background-position: center;
		background-size: 80px 80px;
	}
`;
