import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation googleLoginMutation($input: GoogleLoginInput!) {
		googleLogin(input: $input) {
			errors
			viewer {
				actualPersonId
				csrfToken
				permissions
				company {
					modules {
						moduleType
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Google Login');

	const variables = {input};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
