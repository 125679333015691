import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
import {omit} from 'lodash';
import {createRangeBulkAddUpdater} from '../relay-helpers/RelayCacheHelpers';

const mutation = graphql`
	mutation createMultipleTimeRegistrationMutation($input: CreateMultipleTimeRegsInput!) {
		createMultipleTimeRegs(input: $input) {
			timeRegs {
				node {
					id
					invoiced
					xeroInvoiceId
					lockedInPeriod
					year
					month
					day
					canvasTimelineDate
					minutesRegistered
					allocationId
					notes
					price
					unit4ActivityId
					unit4ActivityName
					harvestTaskIdInt
					harvestTask {
						name
					}
					harvestTimeId
					approvalStatus
					person {
						id
						fullName
						isViewer
						firstName
						lastName
						profilePictureId
						profilePictureDefaultId
						role {
							id
							name
						}
					}
					task {
						id
						name
						progress
						companyTaskId
						highPriority
						approved
						timeLeft
						favoured
						fullAccessToProject
						latestUiUpdateAt
						timeLeft
						currentPrice
						allTotalTimeAndExpensesAtCompletion
						project {
							id
							name
							status
							companyProjectId
							projectColor
							estimationUnit
							fullAccessToProject
							client {
								id
								name
							}
							harvestProject {
								id
								name
							}
						}
						phase {
							id
							name
						}
					}
					project {
						id
						projectPersons(first: 100, contactsOnly: true) {
							edges {
								node {
									person {
										id
										fullName
										profilePictureId
									}
								}
							}
						}
					}
					idleTime {
						id
					}
				}
			}
			errors
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	console.log({debug2: true, input});

	const cleanedVariables = omit(input, ['source', 'viewer', 'newFormat', 'subTask']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}},
		updater: createRangeBulkAddUpdater(input.viewer.id, 'createMultipleTimeRegs', 'timeRegs', 'Viewer_timeRegistrations'),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
