import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateIdleTimeMutation($input: UpdateIdleTimeInput!) {
		updateIdleTime(input: $input) {
			idleTime {
				...settingsIdleTimes_idleTime
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const idleTime = {id: input.id};
	if (input.name) {
		idleTime.name = input.name;
	}
	if (input.isInternalTime != null) {
		idleTime.isInternalTime = input.isInternalTime;
	}
	if (input.disabled != null) {
		idleTime.disabled = input.disabled;
	}
	return {
		updateIdleTime: {
			idleTime: idleTime,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Non Project Time Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
