import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createProjectStatusMutation($input: CreateProjectStatusInput!) {
		createProjectStatus(input: $input) {
			projectStatus {
				node {
					id
					person {
						id
						firstName
						lastName
						profilePictureId
						profilePictureDefaultId
					}
					description
					color
					day
					month
					year
					hour
					minute
					second
				}
			}
			project {
				currentProjectStatus {
					id
					color
					description
					person {
						id
						firstName
						lastName
						profilePictureId
					}
				}
			}
			psProject {
				currentProjectStatus {
					id
					color
					description
					person {
						id
						firstName
						lastName
						profilePictureId
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_projectStatuses',
					rangeBehavior: 'prepend',
				},
			],
			edgeName: 'projectStatus',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Project Status Added', {_Color: Util.getProjectStatusColorName(input.color)});
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
