import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation CreateTaskDismissedNotificationMutation($input: CreateTaskDismissedNotificationInput!) {
		createTaskDismissedNotification(input: $input) {
			tasks {
				id
				taskDismissedNotifications {
					taskOverrunPredictionDismissed
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const tasks = [];
	input.taskIds.forEach(taskId => {
		const task = {
			id: taskId,
		};
		task.taskDismissedNotifications = {
			taskOverrunPredictionDismissed: true,
			id: undefined,
		};
		tasks.push(task);
	});

	return {
		createTaskDismissedNotification: {
			tasks,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Task notification dismissed ' + input.eventType);
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: [],
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
