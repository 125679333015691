/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreatePipedriveVerificationKeyInput = {|
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type CreatePipedriveVerificationKeyMutationVariables = {|
  input: CreatePipedriveVerificationKeyInput
|};
export type CreatePipedriveVerificationKeyMutationResponse = {|
  +createPipedriveVerificationKey: ?{|
    +key: ?string
  |}
|};
export type CreatePipedriveVerificationKeyMutation = {|
  variables: CreatePipedriveVerificationKeyMutationVariables,
  response: CreatePipedriveVerificationKeyMutationResponse,
|};
*/


/*
mutation CreatePipedriveVerificationKeyMutation(
  $input: CreatePipedriveVerificationKeyInput!
) {
  createPipedriveVerificationKey(input: $input) {
    key
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreatePipedriveVerificationKeyInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePipedriveVerificationKeyPayload",
    "kind": "LinkedField",
    "name": "createPipedriveVerificationKey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePipedriveVerificationKeyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePipedriveVerificationKeyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreatePipedriveVerificationKeyMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePipedriveVerificationKeyMutation(\n  $input: CreatePipedriveVerificationKeyInput!\n) {\n  createPipedriveVerificationKey(input: $input) {\n    key\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '16ccc8a67cb0d96979ba4d6ad0affe32';

module.exports = node;
