import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteLabelMutation($input: DeleteLabelInput!) {
		deleteLabel(input: $input) {
			deletedLabelId
		}
	}
`;

function getConfigs(companyId) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: companyId,
			connectionKeys: [
				{
					key: 'Company_labels',
				},
			],
			pathToConnection: ['company', 'label'],
			deletedIDFieldName: 'deletedLabelId',
		},
	];
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Label Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				id: input.id,
			},
		},
		configs: getConfigs(input.companyId),
		onCompleted,
		onError,
	});
}

export default {commit};
