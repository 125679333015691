import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {commitMutation, ConnectionHandler} from 'relay-runtime';
import {omit} from 'lodash';
import {trackCustomNameMutation} from '../tracking/amplitude/TrackMutation';

// TODO: Split viewer { tasks { favouredSortOrder }} into separate mutation?

const mutation = graphql`
	mutation ReorderProjectBoardTaskMutation($input: UpdateTaskInput!) {
		updateTask(input: $input) {
			errors
			taskEdge {
				cursor
				node {
					id
					sortOrder
					...DraggableCard_task
				}
			}
			taskEdges {
				node {
					id
					sortOrder
					...DraggableCard_task @relay(mask: false)
				}
			}
			sortOrderTasks {
				id
				sortOrder
			}
			updatedTasksIds
		}
	}
`;

function getOptimisticResponse(input) {
	const taskEdge = {id: input.ids[0]};
	if (input.optimisticTaskOrder) {
		taskEdge.sortOrder = input.optimisticTaskOrder;
	}
	const taskEdges = [];
	input.ids.forEach(id => {
		const task = {
			node: {
				id,
				statusColumnV2: {
					projectGroupStatusColumnId: input.projectGroupStatusColumnId,
					id: input.statusColumnId,
				},
			},
		};
		if (input.optimisticTaskOrder) {
			task.node.sortOrder = input.optimisticTaskOrder;
		}
		taskEdges.push(task);
	});

	return {
		updateTask: {
			taskEdges,
			taskEdge: {node: taskEdge},
		},
	};
}

function updater(store, input, id, prevStatusColumnId, prevProjectGroupStatusColumnId, updatedEdge) {
	const companyProxy = store.get(input.companyId);
	if (input.statusColumnId && prevStatusColumnId) {
		const prevConn = ConnectionHandler.getConnection(companyProxy, 'Company_allTasks', {
			filterColumnId: prevStatusColumnId,
		});
		const newConn = ConnectionHandler.getConnection(companyProxy, 'Company_allTasks', {
			filterColumnId: input.statusColumnId,
		});
		if (prevConn) {
			ConnectionHandler.deleteNode(prevConn, id);
		}
		if (newConn) {
			// newConnection does not exist when board column is collapsed
			ConnectionHandler.insertEdgeBefore(newConn, updatedEdge);
		}
	}
	if (input.projectGroupStatusColumnId && prevProjectGroupStatusColumnId) {
		const prevConn = ConnectionHandler.getConnection(companyProxy, 'Company_allTasks', {
			filterColumnId: prevProjectGroupStatusColumnId.toString(),
		});
		const newConn = ConnectionHandler.getConnection(companyProxy, 'Company_allTasks', {
			filterColumnId: input.projectGroupStatusColumnId.toString(),
		});
		if (prevConn) {
			ConnectionHandler.deleteNode(prevConn, id);
		}
		if (newConn) {
			// newConnection does not exist when board column is collapsed
			ConnectionHandler.insertEdgeBefore(newConn, updatedEdge);
		}
	}
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Card Updated', {_Changed: 'sortOrder'});

	const additionalTracking = {mutationAffectedCount: input.ids ? input.ids.length : 1};
	if (input.statusColumnCategory) {
		additionalTracking.statusColumnCategory = input.statusColumnCategory;
	}
	trackCustomNameMutation('Update Task', input, undefined, undefined, additionalTracking);

	const cleanedInput = omit(input, [
		'companyId',
		'prevStatusColumnIdMap',
		'timeLeftOptimistic',
		'optimisticTaskOrder',
		'optimisticColumnId',
		'aboveFavouredTaskOrder',
		'viewerId',
		'existingAssignedPersons',
		'optimisticSprintId',
		'statusColumnCategory',
	]);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};
	return commitMutation(environment, {
		mutation,
		variables,
		updater: store => {
			// Get payload
			const payload = store.getRootField('updateTask');
			const errors = payload.getValue('errors') || [];
			if (errors.some(error => error === 'jira_transition_failed')) {
				return;
			}
			const updatedEdges = payload.getLinkedRecords('taskEdges');
			input.ids.forEach(id => {
				const updatedEdge = updatedEdges.find(edge => edge.getLinkedRecord('node').getValue('id') === id);
				const prevStatusColumnObject = input.prevStatusColumnIdMap.get(id);
				// Call updater function
				updater(
					store,
					input,
					id,
					prevStatusColumnObject.statusColumnId,
					prevStatusColumnObject.projectGroupStatusColumnId,
					updatedEdge
				);
			});
		},
		optimisticUpdater: store => {
			input.ids.forEach((id, index, array) => {
				const node = store.get(id);
				if (input.optimisticTaskOrder) {
					node.setValue('sortOrder', input.optimisticTaskOrder + (0.8 / array.length) * index);
				}
				const newEdge = store.create(`client:newEdge:${id}`, 'TaskEdge');
				newEdge.setLinkedRecord(node, 'node');
				const prevStatusColumnObject = input.prevStatusColumnIdMap.get(id);
				updater(
					store,
					input,
					id,
					prevStatusColumnObject.statusColumnId,
					prevStatusColumnObject.projectGroupStatusColumnId,
					newEdge
				);
			});
		},
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
