import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

// This mutation does not currently include the bulk update needed for retainers, should either be created in a new mutation or added to this one.

const mutation = graphql`
	mutation updateTimeRegistrationRetainerConflictMutation($input: UpdateTimeRegInput!) {
		updateTimeReg(input: $input) {
			timeRegs {
				id
				retainerConflictHandled
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Retainer Conflicts Handled');

	const variables = {
		input: {
			csrfToken: Util.getCsrfValue(),
			socketClientId: Util.getClientId(),
			...input,
		},
	};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
