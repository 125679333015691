import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation createSageIntacctInvoiceMutation($input: CreateSageIntacctInvoiceInput!) {
		createSageIntacctInvoice(input: $input) {
			invoice {
				id
				invoiceReference
				companyInvoiceId
				name
				invoiceType
				status
				externalStatus
				dueDay
				dueMonth
				dueYear
				createdDay
				createdMonth
				createdYear
				notes
				quickbooksId
				xeroId
				sageIntacctId
				entries(first: 100000000) {
					edges {
						node {
							id
							name
							quantity
							unitPrice
							discount
							tax
							description
						}
					}
				}
				payments(first: 100000000) {
					edges {
						node {
							id
							notes
							amount
							day
							month
							year
							createdAt
							createdBy {
								fullName
							}
						}
					}
				}
			}
			success
			errorMessage
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
