const messageCache = new Map();

export const getCachedMessage = (intl, id) => {
	if (!id) return '';

	let cachedMessage = messageCache.get(id);
	if (!cachedMessage) {
		cachedMessage = intl.formatMessage({id});
		messageCache.set(id, cachedMessage);
	}

	return cachedMessage || '';
};
