import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../css_variables';

export default styled.div`
	background: white;
	min-width: 400px;
	width: auto;
	height: auto;
	z-index: ${props => (props.isTaskModal ? 'auto' : 100)};

	.popup-wrapper {
		padding: 16px;

		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.title {
			font-size: 13px;
			color: ${CSS_CONSTANTS.v2_text_gray};
			font-weight: 500;
		}
    
    .icon-title-wrapper {
      height: 14px;
    }

		.input-title {
			color: ${CSS_CONSTANTS.v2_text_light_gray};
			font-size: 9px;
      height: 9px;
      font-weight: 500;
			text-transform: uppercase;
			margin-bottom: 8px;
			letter-spacing: 1px;
		}
		.entry-wrapper {
			margin-bottom: 16px;

			.date-picker-button {
				height: 30px;
				border-radius: 4px !important;
			}
		    
		    .roles {
		      width: 240px;
		    }
		}

		.notes {
			.rich-text-field-container .rich-text-field-input-container {
				height: 60px;
			}

			textarea {
				width: 100%;
				resize: none;
				border: none;
				outline: 0;
				border: 1px solid ${CSS_CONSTANTS.app_border_color};
				border-radius: 4px;
				font-family: ${CSS_CONSTANTS.text_font_family};
				font-size: 12px;
				color: ${CSS_CONSTANTS.v2_text_gray};
				padding: 10px 16px 0 16px;
				height: 70px;
				&:hover {
					border-color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
				}
				&:focus {
					border-color: ${CSS_CONSTANTS.v2_branding_purple};
				}
			}
			::-webkit-input-placeholder {
				/* Chrome/Opera/Safari */
				font-size: 12px;
			}
			::-moz-placeholder {
				/* Firefox 19+ */
				font-size: 12px;
			}
			:-ms-input-placeholder {
				/* IE 10+ */
				font-size: 12px;
			}
			:-moz-placeholder {
				/* Firefox 18- */
				font-size: 12px;
			}
		}

		.buttons {
			display: flex;
			justify-content: flex-end;
			height: 30px;

			.custom-button-container .input-container .text {
				padding: 0 8.5px 0 8.5px;
			}
		}
	}
`;
