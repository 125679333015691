/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateUnit4ResourcesInput = {|
  unit4PersonIds?: ?$ReadOnlyArray<?string>,
  profileId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type CreateUnit4ResourcesMutationVariables = {|
  input: CreateUnit4ResourcesInput
|};
export type CreateUnit4ResourcesMutationResponse = {|
  +createUnit4Resources: ?{|
    +errors: ?$ReadOnlyArray<?string>,
    +company: ?{|
      +allPersons: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
            +initials: ?string,
            +fullName: ?string,
            +active: ?boolean,
            +email: ?string,
            +unit4User: ?boolean,
            +unit4UserObject: ?string,
            +permissions: ?$ReadOnlyArray<?string>,
            +costPeriods: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +startDay: ?number,
                  +startMonth: ?number,
                  +startYear: ?number,
                  +cost: ?number,
                |}
              |}>
            |},
            +profiles: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +name: ?string,
                |}
              |}>
            |},
          |}
        |}>
      |}
    |},
  |}
|};
export type CreateUnit4ResourcesMutation = {|
  variables: CreateUnit4ResourcesMutationVariables,
  response: CreateUnit4ResourcesMutationResponse,
|};
*/


/*
mutation CreateUnit4ResourcesMutation(
  $input: CreateUnit4ResourcesInput!
) {
  createUnit4Resources(input: $input) {
    errors
    company {
      allPersons(first: 10000, excludeClientUsers: true) {
        edges {
          node {
            id
            firstName
            lastName
            initials
            fullName
            active
            email
            unit4User
            unit4UserObject
            permissions
            costPeriods(first: 10000) {
              edges {
                node {
                  id
                  startDay
                  startMonth
                  startYear
                  cost
                  __typename
                }
                cursor
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
            profiles(first: 10000) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateUnit4ResourcesInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initials",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit4User",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit4UserObject",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CostPeriodTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CostPeriod",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cost",
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      },
      (v14/*: any*/)
    ],
    "storageKey": null
  },
  (v15/*: any*/)
],
v17 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
},
v18 = [
  (v17/*: any*/)
],
v19 = {
  "alias": null,
  "args": (v18/*: any*/),
  "concreteType": "ProfileTypeConnection",
  "kind": "LinkedField",
  "name": "profiles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Profile",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "profiles(first:10000)"
},
v20 = [
  {
    "kind": "Literal",
    "name": "excludeClientUsers",
    "value": true
  },
  (v17/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateUnit4ResourcesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUnit4ResourcesPayload",
        "kind": "LinkedField",
        "name": "createUnit4Resources",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": "allPersons",
                "args": null,
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "__Company_allPersons_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": "costPeriods",
                            "args": null,
                            "concreteType": "CostPeriodTypeConnection",
                            "kind": "LinkedField",
                            "name": "__Person_costPeriods_connection",
                            "plural": false,
                            "selections": (v16/*: any*/),
                            "storageKey": null
                          },
                          (v19/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateUnit4ResourcesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUnit4ResourcesPayload",
        "kind": "LinkedField",
        "name": "createUnit4Resources",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v20/*: any*/),
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "allPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": (v18/*: any*/),
                            "concreteType": "CostPeriodTypeConnection",
                            "kind": "LinkedField",
                            "name": "costPeriods",
                            "plural": false,
                            "selections": (v16/*: any*/),
                            "storageKey": "costPeriods(first:10000)"
                          },
                          {
                            "alias": null,
                            "args": (v18/*: any*/),
                            "filters": [],
                            "handle": "connection",
                            "key": "Person_costPeriods",
                            "kind": "LinkedHandle",
                            "name": "costPeriods"
                          },
                          (v19/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
              },
              {
                "alias": null,
                "args": (v20/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Company_allPersons",
                "kind": "LinkedHandle",
                "name": "allPersons"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": null
        },
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "createUnit4Resources",
            "company",
            "allPersons"
          ]
        }
      ]
    },
    "name": "CreateUnit4ResourcesMutation",
    "operationKind": "mutation",
    "text": "mutation CreateUnit4ResourcesMutation(\n  $input: CreateUnit4ResourcesInput!\n) {\n  createUnit4Resources(input: $input) {\n    errors\n    company {\n      allPersons(first: 10000, excludeClientUsers: true) {\n        edges {\n          node {\n            id\n            firstName\n            lastName\n            initials\n            fullName\n            active\n            email\n            unit4User\n            unit4UserObject\n            permissions\n            costPeriods(first: 10000) {\n              edges {\n                node {\n                  id\n                  startDay\n                  startMonth\n                  startYear\n                  cost\n                  __typename\n                }\n                cursor\n              }\n              pageInfo {\n                endCursor\n                hasNextPage\n              }\n            }\n            profiles(first: 10000) {\n              edges {\n                node {\n                  id\n                  name\n                }\n              }\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7e945fefe7de6069c9d7aa9aca0b62e2';

module.exports = node;
