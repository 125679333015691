import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
import {trackEvent} from '../tracking/amplitude/TrackingV2';

const mutation = graphql`
	mutation deleteSavedReportMutation($input: DeleteSavedReportInput!) {
		deleteSavedReport(input: $input) {
			deletedSavedReportId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_allSavedReports',
				},
			],
			pathToConnection: ['company', 'allSavedReports'],
			deletedIDFieldName: 'deletedSavedReportId',
		},
		{
			type: 'NODE_DELETE',
			deletedIDFieldName: 'deletedSavedReportId',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		deleteSavedReport: {
			deletedSavedReportId: input.id,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Report Deleted');
	trackEvent('Standard Report', 'Deleted');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				id: input.id,
				reportService: input.reportService,
			},
		},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
