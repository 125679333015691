import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation createOktaVerificationKeyMutation($input: CreateOktaVerificationKeyInput!) {
		createOktaVerificationKey(input: $input) {
			key
			oktaClientId
			oktaUrl
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input};
	return commitMutation(environment, {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
