import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateHolidayCalendarMutation($input: UpdateHolidayCalendarInput!) {
		updateHolidayCalendar(input: $input) {
			holidayCalendar {
				name
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const obj = {id: input.id};
	if (input.name) {
		obj.name = input.name;
	}
	return {
		updateHolidayCalendar: {
			holidayCalendar: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
