import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation verifyResetKeyMutation($input: VerifyResetKeyInput!) {
		verifyResetKey(input: $input) {
			isKeyValid
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input};
	return commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
