import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateInvoiceMutation($input: UpdateInvoiceInput!) {
		updateInvoice(input: $input) {
			invoice {
				invoiceReference
				name
				currency
				invoiceType
				status
				dueDay
				dueMonth
				dueYear
				createdDay
				createdMonth
				createdYear
				notes
				paymentStatus
				entries(first: 100000000) {
					edges {
						node {
							id
							name
							quantity
							quantityText
							unitPrice
							discount
							tax
							description
							quickbooksItemOrService
						}
					}
				}
				payments(first: 100000000) {
					edges {
						node {
							id
							notes
							amount
							day
							month
							year
							createdAt
							createdBy {
								fullName
							}
						}
					}
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const {id, status} = input;

	const invoice = {id};
	if (status) {
		invoice.status = status;
	}
	return {
		updateInvoice: {
			invoice,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Invoice Updated');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		optimisticResponse: getOptimisticResponse(input),
		onError: onError,
	});
}

export default {commit};
