import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createHolidayCalendarEntryMutation($input: CreateHolidayCalendarEntryInput!) {
		createHolidayCalendarEntry(input: $input) {
			holidayCalendarEntry {
				node {
					...settingsHolidayCalendars_holidayCalendarEntry
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.holidayCalendarId,
			connectionInfo: [
				{
					key: 'HolidayCalendar_holidayCalendarEntries',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'holidayCalendarEntry',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		createHolidayCalendarEntry: {
			holidayCalendarEntry: {
				node: {
					name: input.name,
					year: input.year,
					month: input.month,
					day: input.day,
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Holiday calendar entry created');

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
