import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createSprintAndMoveTasksMutation($input: CreateSprintAndMoveTasksInput!) {
		createSprintAndMoveTasks(input: $input) {
			sprint {
				node {
					id
					name
					projectGroupSprintId
					isProjectGroupSprint
					startDay
					startMonth
					startYear
					endDay
					endMonth
					endYear
					available
					sprintPersons(first: 1000000) {
						edges {
							node {
								id
								availableMinutes
								scheduledMinutes
								person {
									id
									firstName
									lastName
									profilePictureId
									profilePictureDefaultId
									role {
										id
										name
									}
								}
							}
						}
					}
				}
			}
			projectGroup {
				id
				projects(first: 1000000) {
					edges {
						node {
							tasks(first: 100000) @connection(key: "Project_tasks", filters: []) {
								edges {
									node {
										id
										sprint {
											id
											projectGroupSprintId
										}
									}
								}
							}
						}
					}
				}
				projectGroupSprints(first: 1000000) @connection(key: "ProjectGroup_projectGroupSprints", filters: []) {
					edges {
						node {
							id
							sprintPersons(first: 1000000) {
								edges {
									node {
										id
										availableMinutes
										scheduledMinutes
										taskAvailableMinutes
										person {
											id
											firstName
											lastName
											profilePictureId
											profilePictureDefaultId
											role {
												id
												name
											}
										}
									}
								}
							}
						}
					}
				}
			}
			project {
				tasks(first: 100000) @connection(key: "Project_tasks", filters: []) {
					edges {
						node {
							id
							sprint {
								id
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	const config = [];
	if (input.projectId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_sprints',
					rangeBehavior: 'prepend',
				},
			],
			edgeName: 'sprint',
		});
	}
	if (input.projectGroupId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.projectGroupId,
			connectionInfo: [
				{
					key: 'ProjectGroup_projectGroupSprints',
					rangeBehavior: 'prepend',
				},
			],
			edgeName: 'sprint',
		});
	}

	return config;
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Cards Moved To New Sprint');
	//
	const cleanedInput = input.projectGroupId ? omit(input, ['projectId']) : input;

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
