/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateBulkFixedPriceLockInput = {|
  projectId?: ?string,
  locks?: ?$ReadOnlyArray<?FixedPriceLockInputType>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type FixedPriceLockInputType = {|
  startDate?: ?string,
  endDate?: ?string,
  amount?: ?number,
  timeAmount?: ?number,
  locked?: ?boolean,
|};
export type createBulkFixedPriceLockMutationVariables = {|
  input: CreateBulkFixedPriceLockInput
|};
export type createBulkFixedPriceLockMutationResponse = {|
  +createBulkFixedPriceLock: ?{|
    +fixedPriceLocks: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +startDate: ?string,
        +endDate: ?string,
        +locked: ?boolean,
      |}
    |}>
  |}
|};
export type createBulkFixedPriceLockMutation = {|
  variables: createBulkFixedPriceLockMutationVariables,
  response: createBulkFixedPriceLockMutationResponse,
|};
*/


/*
mutation createBulkFixedPriceLockMutation(
  $input: CreateBulkFixedPriceLockInput!
) {
  createBulkFixedPriceLock(input: $input) {
    fixedPriceLocks {
      node {
        id
        startDate
        endDate
        locked
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateBulkFixedPriceLockInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateBulkFixedPriceLockPayload",
    "kind": "LinkedField",
    "name": "createBulkFixedPriceLock",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectFixedPriceLockTypeEdge",
        "kind": "LinkedField",
        "name": "fixedPriceLocks",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectFixedPriceLockType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locked",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createBulkFixedPriceLockMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createBulkFixedPriceLockMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createBulkFixedPriceLockMutation",
    "operationKind": "mutation",
    "text": "mutation createBulkFixedPriceLockMutation(\n  $input: CreateBulkFixedPriceLockInput!\n) {\n  createBulkFixedPriceLock(input: $input) {\n    fixedPriceLocks {\n      node {\n        id\n        startDate\n        endDate\n        locked\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78deccd41156af84559ef5f2e90ca88b';

module.exports = node;
