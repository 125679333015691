/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImportBambooHRTimeOffTypesInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type ImportBambooHRTimeOffTypesMutationVariables = {|
  input: ImportBambooHRTimeOffTypesInput
|};
export type ImportBambooHRTimeOffTypesMutationResponse = {|
  +importBambooHRTimeOffTypes: ?{|
    +company: ?{|
      +idleTimes: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +isInternalTime: ?boolean,
            +disabled: ?boolean,
            +timeRegistrations: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string
                |}
              |}>
            |},
          |}
        |}>
      |}
    |}
  |}
|};
export type ImportBambooHRTimeOffTypesMutation = {|
  variables: ImportBambooHRTimeOffTypesMutationVariables,
  response: ImportBambooHRTimeOffTypesMutationResponse,
|};
*/


/*
mutation ImportBambooHRTimeOffTypesMutation(
  $input: ImportBambooHRTimeOffTypesInput!
) {
  importBambooHRTimeOffTypes(input: $input) {
    company {
      idleTimes(first: 1000) {
        edges {
          node {
            id
            name
            isInternalTime
            disabled
            timeRegistrations(first: 1) {
              edges {
                node {
                  id
                }
              }
            }
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImportBambooHRTimeOffTypesInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "IdleTimeTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "IdleTime",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isInternalTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              }
            ],
            "concreteType": "TimeRegTypeConnection",
            "kind": "LinkedField",
            "name": "timeRegistrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TimeRegTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TimeRegType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "timeRegistrations(first:1)"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportBambooHRTimeOffTypesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ImportBambooHRTimeOffTypesPayload",
        "kind": "LinkedField",
        "name": "importBambooHRTimeOffTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": "idleTimes",
                "args": null,
                "concreteType": "IdleTimeTypeConnection",
                "kind": "LinkedField",
                "name": "__Company_idleTimes_connection",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportBambooHRTimeOffTypesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ImportBambooHRTimeOffTypesPayload",
        "kind": "LinkedField",
        "name": "importBambooHRTimeOffTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "IdleTimeTypeConnection",
                "kind": "LinkedField",
                "name": "idleTimes",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": "idleTimes(first:1000)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Company_idleTimes",
                "kind": "LinkedHandle",
                "name": "idleTimes"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "importBambooHRTimeOffTypes",
            "company",
            "idleTimes"
          ]
        }
      ]
    },
    "name": "ImportBambooHRTimeOffTypesMutation",
    "operationKind": "mutation",
    "text": "mutation ImportBambooHRTimeOffTypesMutation(\n  $input: ImportBambooHRTimeOffTypesInput!\n) {\n  importBambooHRTimeOffTypes(input: $input) {\n    company {\n      idleTimes(first: 1000) {\n        edges {\n          node {\n            id\n            name\n            isInternalTime\n            disabled\n            timeRegistrations(first: 1) {\n              edges {\n                node {\n                  id\n                }\n              }\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b7c7a1b2d6849eaadd057e443d4f9a8';

module.exports = node;
