import {commitMutation, graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';

const mutation = graphql`
	mutation updateSuggestedTaskLabelsMutation($input: UpdateSuggestedTaskLabelsInput!) {
		updateSuggestedTaskLabels(input: $input) {
			labelSuggestion
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	return {
		updateSuggestedTaskLabels: {},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['taskId'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
