/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateTaskProgressInput = {|
  taskId: string,
  progress: number,
  phaseId?: ?string,
  projectId: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createTaskProgressMutationVariables = {|
  input: CreateTaskProgressInput
|};
export type createTaskProgressMutationResponse = {|
  +createTaskProgress: ?{|
    +project: ?{|
      +id: string,
      +progress: ?number,
    |},
    +phase: ?{|
      +id: string,
      +progress: ?number,
    |},
    +task: ?{|
      +id: string,
      +progressDetails: ?{|
        +id: string,
        +progress: ?number,
        +createdAt: ?string,
        +createdBy: ?{|
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
        |},
      |},
      +timeLeft: ?number,
    |},
  |}
|};
export type createTaskProgressMutation = {|
  variables: createTaskProgressMutationVariables,
  response: createTaskProgressMutationResponse,
|};
*/


/*
mutation createTaskProgressMutation(
  $input: CreateTaskProgressInput!
) {
  createTaskProgress(input: $input) {
    project {
      id
      progress
    }
    phase {
      id
      progress
    }
    task {
      id
      progressDetails {
        id
        progress
        createdAt
        createdBy {
          firstName
          lastName
          profilePictureId
          id
        }
      }
      timeLeft
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTaskProgressInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PhaseType",
  "kind": "LinkedField",
  "name": "phase",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLeft",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createTaskProgressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateTaskProgressPayload",
        "kind": "LinkedField",
        "name": "createTaskProgress",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskProgress",
                "kind": "LinkedField",
                "name": "progressDetails",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createTaskProgressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateTaskProgressPayload",
        "kind": "LinkedField",
        "name": "createTaskProgress",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskProgress",
                "kind": "LinkedField",
                "name": "progressDetails",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createTaskProgressMutation",
    "operationKind": "mutation",
    "text": "mutation createTaskProgressMutation(\n  $input: CreateTaskProgressInput!\n) {\n  createTaskProgress(input: $input) {\n    project {\n      id\n      progress\n    }\n    phase {\n      id\n      progress\n    }\n    task {\n      id\n      progressDetails {\n        id\n        progress\n        createdAt\n        createdBy {\n          firstName\n          lastName\n          profilePictureId\n          id\n        }\n      }\n      timeLeft\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '62c4b195c33293b7fe13749c40726e49';

module.exports = node;
