/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LANGUAGE = "DANISH" | "ENGLISH_EU" | "ENGLISH_UK" | "ENGLISH_US" | "FRENCH" | "POLISH" | "SPANISH" | "%future added value";
export type SEAT_TYPE = "COLLABORATOR" | "CORE" | "FULL" | "VIRTUAL" | "%future added value";
export type UpdatePersonInput = {|
  id: string,
  email?: ?string,
  jobTitle?: ?string,
  departmentId?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  language?: ?LANGUAGE,
  profileIds?: ?$ReadOnlyArray<?string>,
  roleId?: ?string,
  active?: ?boolean,
  invited?: ?boolean,
  labels?: ?$ReadOnlyArray<?string>,
  profilePictureId?: ?string,
  monday?: ?number,
  tuesday?: ?number,
  wednesday?: ?number,
  thursday?: ?number,
  friday?: ?number,
  saturday?: ?number,
  sunday?: ?number,
  startDate?: ?string,
  endDate?: ?string,
  cost?: ?number,
  timeLogPersonId?: ?number,
  githubPersonId?: ?string,
  gitlabPersonId?: ?number,
  gitlabServerPersonId?: ?number,
  jiraCloudId?: ?string,
  jiraServerId?: ?string,
  unit4PersonId?: ?string,
  sageIntacctId?: ?string,
  sageIntacctName?: ?string,
  sageIntacctEmail?: ?string,
  sageIntacctLocationId?: ?string,
  adoUserId?: ?string,
  holidayCalendarId?: ?string,
  phone?: ?string,
  useNewUIWorkflow?: ?boolean,
  adminPassword?: ?string,
  hasSSOExemption?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updatePersonMutationModernMutationVariables = {|
  input: UpdatePersonInput
|};
export type updatePersonMutationModernMutationResponse = {|
  +updatePerson: ?{|
    +errors: ?$ReadOnlyArray<?string>,
    +person: ?{|
      +email: ?string,
      +jobTitle: ?string,
      +department: ?{|
        +id: string
      |},
      +firstName: ?string,
      +lastName: ?string,
      +initials: ?string,
      +seat: ?SEAT_TYPE,
      +profiles: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +seat: ?SEAT_TYPE,
          |}
        |}>
      |},
      +permissions: ?$ReadOnlyArray<?string>,
      +cost: ?number,
      +phone: ?string,
      +language: ?LANGUAGE,
      +active: ?boolean,
      +invited: ?boolean,
      +profilePictureId: ?string,
      +excludeFromCompanyLockedPeriod: ?boolean,
      +startDate: ?string,
      +endDate: ?string,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
      +holidayCalendar: ?{|
        +id: string
      |},
      +personLabels: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +label: ?{|
              +id: string
            |}
          |}
        |}>
      |},
      +gitlabUser: ?{|
        +integrationUserId: ?number
      |},
      +gitlabServerUser: ?{|
        +integrationUserId: ?number
      |},
      +githubUser: ?{|
        +integrationUserId: ?number
      |},
      +unit4UserObject: ?string,
      +jiraCloudId: ?string,
      +jiraServerId: ?string,
      +hasSSOExemption: ?boolean,
      +sageIntacctId: ?string,
      +sageIntacctName: ?string,
      +sageIntacctEmail: ?string,
      +sageIntacctLocationId: ?string,
      +adoUserId: ?string,
    |},
    +viewer: ?{|
      +permissions: ?$ReadOnlyArray<?string>,
      +profiles: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +permissions: ?$ReadOnlyArray<?string>,
          |}
        |}>
      |},
      +company: ?{|
        +userSeats: ?number,
        +virtualSeats: ?number,
      |},
    |},
  |}
|};
export type updatePersonMutationModernMutation = {|
  variables: updatePersonMutationModernMutationVariables,
  response: updatePersonMutationModernMutationResponse,
|};
*/


/*
mutation updatePersonMutationModernMutation(
  $input: UpdatePersonInput!
) {
  updatePerson(input: $input) {
    errors
    person {
      email
      jobTitle
      department {
        id
      }
      firstName
      lastName
      initials
      seat
      profiles(first: 10000) {
        edges {
          node {
            id
            name
            seat
          }
        }
      }
      permissions
      cost
      phone
      language
      active
      invited
      profilePictureId
      excludeFromCompanyLockedPeriod
      startDate
      endDate
      role {
        id
        name
      }
      holidayCalendar {
        id
      }
      personLabels(first: 10000) {
        edges {
          node {
            label {
              id
            }
            id
          }
        }
      }
      gitlabUser {
        integrationUserId
      }
      gitlabServerUser {
        integrationUserId
      }
      githubUser {
        integrationUserId
      }
      unit4UserObject
      jiraCloudId
      jiraServerId
      hasSSOExemption
      sageIntacctId
      sageIntacctName
      sageIntacctEmail
      sageIntacctLocationId
      adoUserId
      id
    }
    viewer {
      permissions
      profiles(first: 10000) {
        edges {
          node {
            id
            name
            permissions
          }
        }
      }
      company {
        userSeats
        virtualSeats
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdatePersonInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "DepartmentType",
  "kind": "LinkedField",
  "name": "department",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initials",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seat",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": (v12/*: any*/),
  "concreteType": "ProfileTypeConnection",
  "kind": "LinkedField",
  "name": "profiles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Profile",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v13/*: any*/),
            (v11/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "profiles(first:10000)"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invited",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "excludeFromCompanyLockedPeriod",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "HolidayCalendar",
  "kind": "LinkedField",
  "name": "holidayCalendar",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "Label",
  "kind": "LinkedField",
  "name": "label",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v28 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "integrationUserId",
    "storageKey": null
  }
],
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "gitlabUser",
  "plural": false,
  "selections": (v28/*: any*/),
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "gitlabServerUser",
  "plural": false,
  "selections": (v28/*: any*/),
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationUserType",
  "kind": "LinkedField",
  "name": "githubUser",
  "plural": false,
  "selections": (v28/*: any*/),
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit4UserObject",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraCloudId",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jiraServerId",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSSOExemption",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sageIntacctId",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sageIntacctName",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sageIntacctEmail",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sageIntacctLocationId",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adoUserId",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": (v12/*: any*/),
  "concreteType": "ProfileTypeConnection",
  "kind": "LinkedField",
  "name": "profiles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Profile",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v13/*: any*/),
            (v15/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "profiles(first:10000)"
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSeats",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "virtualSeats",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePersonMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "PersonLabelTypeConnection",
                "kind": "LinkedField",
                "name": "personLabels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonLabelTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonLabel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v27/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "personLabels(first:10000)"
              },
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              (v37/*: any*/),
              (v38/*: any*/),
              (v39/*: any*/),
              (v40/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v41/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v42/*: any*/),
                  (v43/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePersonMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "PersonLabelTypeConnection",
                "kind": "LinkedField",
                "name": "personLabels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonLabelTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonLabel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v27/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "personLabels(first:10000)"
              },
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              (v37/*: any*/),
              (v38/*: any*/),
              (v39/*: any*/),
              (v40/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v41/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v42/*: any*/),
                  (v43/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updatePersonMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation updatePersonMutationModernMutation(\n  $input: UpdatePersonInput!\n) {\n  updatePerson(input: $input) {\n    errors\n    person {\n      email\n      jobTitle\n      department {\n        id\n      }\n      firstName\n      lastName\n      initials\n      seat\n      profiles(first: 10000) {\n        edges {\n          node {\n            id\n            name\n            seat\n          }\n        }\n      }\n      permissions\n      cost\n      phone\n      language\n      active\n      invited\n      profilePictureId\n      excludeFromCompanyLockedPeriod\n      startDate\n      endDate\n      role {\n        id\n        name\n      }\n      holidayCalendar {\n        id\n      }\n      personLabels(first: 10000) {\n        edges {\n          node {\n            label {\n              id\n            }\n            id\n          }\n        }\n      }\n      gitlabUser {\n        integrationUserId\n      }\n      gitlabServerUser {\n        integrationUserId\n      }\n      githubUser {\n        integrationUserId\n      }\n      unit4UserObject\n      jiraCloudId\n      jiraServerId\n      hasSSOExemption\n      sageIntacctId\n      sageIntacctName\n      sageIntacctEmail\n      sageIntacctLocationId\n      adoUserId\n      id\n    }\n    viewer {\n      permissions\n      profiles(first: 10000) {\n        edges {\n          node {\n            id\n            name\n            permissions\n          }\n        }\n      }\n      company {\n        userSeats\n        virtualSeats\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12f6d2a9370228c0cd3cd62bc8c911fe';

module.exports = node;
