import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateCostPeriodMutationModernMutation($input: UpdateCostPeriodInput!) {
		updateCostPeriod(input: $input) {
			costPeriod {
				id
				startDay
				startMonth
				startYear
				cost
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const obj = {
		id: input.id,
	};

	if (input.name) {
		obj.name = input.name;
	}
	if (input.startDay) {
		obj.startDay = input.startDay;
	}
	if (input.startMonth) {
		obj.startMonth = input.startMonth;
	}
	if (input.startYear) {
		obj.startYear = input.startYear;
	}
	if (input.cost) {
		obj.cost = input.cost;
	}

	return {
		updateCostPeriod: {
			costPeriod: obj,
		},
	};
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Cost Period Updated');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				...input,
			},
		},
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted,
		onError,
	});
}

export default {commit};
