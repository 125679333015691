import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';
import {createRangeBulkAddUpdater} from '../relay-helpers/RelayCacheHelpers';

const mutation = graphql`
	mutation ImportSageIntacctDepartmentsMutation($input: ImportSageIntacctDepartmentsInput!) {
		importSageIntacctDepartments(input: $input) {
			departments {
				node {
					id
					name
					sageIntacctId
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(environment, {
		mutation,
		variables,
		updater: createRangeBulkAddUpdater(
			input.companyId,
			'importSageIntacctDepartments',
			'departments',
			'Company_departments'
		),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
