/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TransferReportOwnershipInput = {|
  reportId?: ?string,
  newOwnerId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type TransferReportOwnershipMutationVariables = {|
  input: TransferReportOwnershipInput
|};
export type TransferReportOwnershipMutationResponse = {|
  +transferReportOwnership: ?{|
    +savedReport: ?{|
      +person: ?{|
        +id: string,
        +active: ?boolean,
        +email: ?string,
        +fullName: ?string,
        +profilePictureId: ?string,
      |}
    |}
  |}
|};
export type TransferReportOwnershipMutation = {|
  variables: TransferReportOwnershipMutationVariables,
  response: TransferReportOwnershipMutationResponse,
|};
*/


/*
mutation TransferReportOwnershipMutation(
  $input: TransferReportOwnershipInput!
) {
  transferReportOwnership(input: $input) {
    savedReport {
      person {
        id
        active
        email
        fullName
        profilePictureId
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "TransferReportOwnershipInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransferReportOwnershipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TransferReportOwnershipPayload",
        "kind": "LinkedField",
        "name": "transferReportOwnership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedReportType",
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransferReportOwnershipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TransferReportOwnershipPayload",
        "kind": "LinkedField",
        "name": "transferReportOwnership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedReportType",
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "TransferReportOwnershipMutation",
    "operationKind": "mutation",
    "text": "mutation TransferReportOwnershipMutation(\n  $input: TransferReportOwnershipInput!\n) {\n  transferReportOwnership(input: $input) {\n    savedReport {\n      person {\n        id\n        active\n        email\n        fullName\n        profilePictureId\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '86f05c55f90fbda732f2a07c5d558d1c';

module.exports = node;
