import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateProjectGroupMutation($input: UpdateProjectGroupInput!) {
		updateProjectGroup(input: $input) {
			errors
			projectGroup {
				id
				name
				color
				projects(first: 100000) {
					edges {
						node {
							id
							estimationUnit
							sprintTimeBox
							sprintLengthInDays
							minutesPerEstimationPoint
							useManualAllocations
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const projectGroup = {id: input.id};
	if (input.name) {
		projectGroup.name = input.name;
	}
	if (input.statusColumns) {
		projectGroup.statusColumns = input.statusColumns;
	}
	return {
		updateProjectGroup: {
			projectGroup: projectGroup,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Connected Project Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
