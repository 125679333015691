import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation DeleteTempoIntegrationMutation($input: DeleteTempoIntegrationInput!) {
		deleteTempoIntegration(input: $input) {
			company {
				id
				tempoOauthTokenApplied
				tempoOauthClientId
				tempoOauthClientSecret
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId()}},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
