import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createTaskProgressMutation($input: CreateTaskProgressInput!) {
		createTaskProgress(input: $input) {
			project {
				id
				progress
			}
			phase {
				id
				progress
			}
			task {
				id
				progressDetails {
					id
					progress
					createdAt
					createdBy {
						firstName
						lastName
						profilePictureId
					}
				}
				timeLeft
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_taskProgress',
					rangeBehavior: 'prepend',
				},
			],
			edgeName: 'taskProgress',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Task Progress Record Added');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
