/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VerifyUnit4IntegrationInput = {|
  unit4Domain: string,
  unit4DomainSoap: string,
  unit4Client: string,
  unit4Username: string,
  unit4Password: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type verifyUnit4IntegrationMutationVariables = {|
  input: VerifyUnit4IntegrationInput
|};
export type verifyUnit4IntegrationMutationResponse = {|
  +verifyUnit4Integration: ?{|
    +success: ?boolean,
    +message: ?string,
    +company: ?{|
      +unit4Domain: ?string,
      +unit4DomainSoap: ?string,
      +unit4Client: ?string,
      +unit4Username: ?string,
    |},
  |}
|};
export type verifyUnit4IntegrationMutation = {|
  variables: verifyUnit4IntegrationMutationVariables,
  response: verifyUnit4IntegrationMutationResponse,
|};
*/


/*
mutation verifyUnit4IntegrationMutation(
  $input: VerifyUnit4IntegrationInput!
) {
  verifyUnit4Integration(input: $input) {
    success
    message
    company {
      unit4Domain
      unit4DomainSoap
      unit4Client
      unit4Username
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "VerifyUnit4IntegrationInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit4Domain",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit4DomainSoap",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit4Client",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit4Username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "verifyUnit4IntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyUnit4IntegrationPayload",
        "kind": "LinkedField",
        "name": "verifyUnit4Integration",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "verifyUnit4IntegrationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyUnit4IntegrationPayload",
        "kind": "LinkedField",
        "name": "verifyUnit4Integration",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "verifyUnit4IntegrationMutation",
    "operationKind": "mutation",
    "text": "mutation verifyUnit4IntegrationMutation(\n  $input: VerifyUnit4IntegrationInput!\n) {\n  verifyUnit4Integration(input: $input) {\n    success\n    message\n    company {\n      unit4Domain\n      unit4DomainSoap\n      unit4Client\n      unit4Username\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f4c60cd662b65d929a5e3a7b3a77da70';

module.exports = node;
