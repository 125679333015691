import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateRetainerPeriodBulkMutation($input: UpdateRetainerPeriodBulkInput!) {
		updateRetainerPeriodBulk(input: $input) {
			errors
			project {
				retainerPeriods(first: 100000) {
					edges {
						node {
							id
							name
							startYear
							startMonth
							startDay
							endYear
							endMonth
							endDay
							available
							periodLength
							periodPeriodicity
							periodPriceAmount
							periodHoursAmount
							periodBudgetType
							periodSettingIgnoreForBilling
							periodSettingSubtractValue
							periodSettingRollValue
							periodSettingAddExpenses
							periodDifferencePriceAmount
							periodDifferenceHoursAmount
							sharedPeriodDifferenceHoursAmount
							sharedPeriodDifferencePriceAmount
							ignoredRolloverHours
							ignoredRolloverPrice
							periodLocked
							periodLockedTime
							periodDifferenceSplit
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
