import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation UpdateTaskHierarchyMutation($input: UpdateTaskInput!) {
		updateTask(input: $input) {
			errors
			updatedTasksIds
			taskEdge {
				node {
					id
					estimateForecast
					parentTask {
						id
						rollupEstimate
						rollupTimeEntries
						rollupRemaining
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	const configs = [];
	return configs;
}

function getOptimisticResponse(input) {
	const task = {id: input.id};
	if (input.startDay) {
		task.startDay = input.startDay;
	}
	if (input.startMonth) {
		task.startMonth = input.startMonth;
	}
	if (input.startYear) {
		task.startYear = input.startYear;
	}
	if (input.deadlineDay) {
		task.deadlineDay = input.deadlineDay;
	}
	if (input.deadlineMonth) {
		task.deadlineMonth = input.deadlineMonth;
	}
	if (input.deadlineYear) {
		task.deadlineYear = input.deadlineYear;
	}
	if (input.description) {
		task.description = input.description;
	}
	if (input.name) {
		task.name = input.name;
	}
	if (input.forecast) {
		task.estimateForecast = input.forecast;
	}
	if (input.timeLeftOptimistic) {
		task.timeLeft = input.timeLeftOptimistic;
	}

	if (input.sprintId === null) {
		task.sprint = null;
		task.startFrom = null;
		task.deadlineFrom = null;
	}
	if (input.sprintId) {
		task.sprint = {id: input.sprintId};
		task.startFrom = null;
		task.deadlineFrom = null;
	}
	if (input.roleId) {
		task.role = {id: input.roleId};
	}
	if (input.phaseId !== undefined) {
		task.phase = input.phaseId ? {id: input.phaseId} : null;
		task.startFrom = null;
		task.deadlineFrom = null;
	}
	if (input.coverFileId) {
		task.coverFile = {id: input.coverFileId};
	}
	if (input.aboveTaskOrder) {
		task.sortOrder = input.aboveTaskOrder + 1;
	}
	if (input.optimisticTaskOrder) {
		task.sortOrder = input.optimisticTaskOrder;
	}
	if (input.aboveFavouredTaskOrder) {
		//task.favouredSortOrder = input.aboveFavouredTaskOrder + BigInt(1); // 1n doesn't compile :(
		/*
		 *	Just to add 1 to bigInt made of a string.
		 *	This code should be removed (and replace by the line bellow)
		 *	as soon as Safari, IE and Edge accept BigInt
		 */
		let aboveFavouredTaskOrderFormatted = input.aboveFavouredTaskOrder;
		let i = aboveFavouredTaskOrderFormatted.length - 1;
		while (aboveFavouredTaskOrderFormatted[i] === '9' && i >= 0) {
			aboveFavouredTaskOrderFormatted = Util.replaceAt(aboveFavouredTaskOrderFormatted, i, '0');
			i--;
		}
		if (i < 0) {
			aboveFavouredTaskOrderFormatted = '1' + aboveFavouredTaskOrderFormatted;
		} else {
			aboveFavouredTaskOrderFormatted = Util.replaceAt(
				aboveFavouredTaskOrderFormatted,
				i,
				parseInt(aboveFavouredTaskOrderFormatted[i]) + 1
			);
		}
		task.favouredSortOrder = aboveFavouredTaskOrderFormatted;
	}
	if (input.favouredTaskOrder) {
		task.favouredSortOrder = input.favouredTaskOrder;
	}
	if (input.blocked !== undefined) {
		task.blocked = input.blocked;
	}
	if (input.approved !== undefined) {
		task.approved = input.approved;
	}
	if (input.bug !== undefined) {
		task.bug = input.bug;
	}
	if (input.assignedPersons) {
		task.assignedPersons = input.assignedPersons.map(p => ({id: p}));
	}
	if (input.repeatingTaskId) {
		task.repeatingTaskId = {id: input.repeatingTaskId};
	}
	if (input.statusColumnId && !input.statusColumnV2Id) {
		task.statusColumnV2 = {id: input.statusColumnId};
	}
	if (input.statusColumnV2Id) {
		task.statusColumnV2 = {id: input.statusColumnV2Id};
	}
	// if (input.actualStatusColumnId) {
	// 	task.statusColumn = {id: input.actualStatusColumnId};
	// }
	if (input.projectGroupStatusIdToStatusIdMap && input.taskIdToProjectIdMap && input.projectGroupStatusColumnId) {
		const projectId = input.taskIdToProjectIdMap.get(task.id);
		const groupStatusIdToStatusIdMap = input.projectGroupStatusIdToStatusIdMap.get(projectId);
		task.statusColumn = {id: groupStatusIdToStatusIdMap.get(input.projectGroupStatusColumnId)};
	}
	if (input.projectGroupStatusColumnId && input.optimisticColumnId) {
		task.statusColumnV2 = {id: input.optimisticColumnId};
	}
	if (input.previousProjectId && input.projectId) {
		task.projectId = input.projectId;
	}
	if (input.remaining) {
		task.timeLeft = input.remaining;
	}
	if (input.highPriority !== undefined) {
		task.highPriority = input.highPriority;
	}
	if (input.ownerId === null) {
		task.owner = null;
	}
	if (input.ownerId) {
		task.owner = {id: input.ownerId};
	}
	if (input.followers) {
		task.followers = input.followers.map(f => ({id: f}));
	}
	if (input.favoured !== undefined) {
		task.favoured = input.favoured;
	}
	if (input.parentTaskId !== undefined) {
		task.parentTaskId = input.parentTaskId;
	}
	if (input.previousParentTaskId) {
		task.previousParentTaskId = input.previousParentTaskId;
	}

	return {
		updateTask: {
			taskEdge: {
				node: task,
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	Util.localStorageDeleteFincancialMap();
	const changes = [];

	for (const key of Object.keys(input)) {
		if (
			[
				'ids',
				'id',
				'aboveTaskOrder',
				'viewerId',
				'viewerActualId',
				'optimisticTaskOrder',
				'previousAssignedPersons',
			].includes(key) ||
			input[key] === undefined
		) {
			continue;
		}

		if (key === 'aboveTaskId') {
			changes.push('sortOrder');
		} else {
			changes.push(key);
		}
	}

	tracking.trackEvent('Card Updated', {_Changed: changes, bulkUpdate: input.ids && input.ids.length > 1});

	const cleanedInput = omit(input, [
		'companyId',
		'timeLeftOptimistic',
		'optimisticTaskOrder',
		'optimisticColumnId',
		'deleteNode',
		'prevStatusColumnId',
		'prevProjectGroupStatusColumnId',
	]);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
