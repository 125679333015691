import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createRateCardMutation($input: CreateRateCardInput!) {
		createRateCard(input: $input) {
			rateCard {
				node {
					id
					name
					defaultRate
					parentRateCardId
					currency
					disabledRoles {
						id
					}
					rates(first: 10000) {
						edges {
							node {
								id
								rate
								defaultRate
								startDate
								role {
									id
								}
							}
						}
					}
				}
			}
			company {
				exchangeRates(first: 100000) {
					edges {
						node {
							id
							isUsed
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_rateCards',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'rateCard',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Rate Card Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				name: input.name,
				currency: input.currency,
				rates: input.rates,
				disabledRoles: input.disabledRoles,
			},
		},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
