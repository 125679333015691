/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSprintGoalInput = {|
  sprintId?: ?string,
  projectGroupSprintId?: ?string,
  description?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type CreateSprintGoalMutationVariables = {|
  input: CreateSprintGoalInput
|};
export type CreateSprintGoalMutationResponse = {|
  +createSprintGoal: ?{|
    +sprintGoal: ?{|
      +node: ?{|
        +id: string,
        +description: ?string,
        +statusColor: ?string,
        +stage: ?string,
      |}
    |}
  |}
|};
export type CreateSprintGoalMutation = {|
  variables: CreateSprintGoalMutationVariables,
  response: CreateSprintGoalMutationResponse,
|};
*/


/*
mutation CreateSprintGoalMutation(
  $input: CreateSprintGoalInput!
) {
  createSprintGoal(input: $input) {
    sprintGoal {
      node {
        id
        description
        statusColor
        stage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSprintGoalInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateSprintGoalPayload",
    "kind": "LinkedField",
    "name": "createSprintGoal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SprintGoalTypeEdge",
        "kind": "LinkedField",
        "name": "sprintGoal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SprintGoal",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "statusColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSprintGoalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSprintGoalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateSprintGoalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSprintGoalMutation(\n  $input: CreateSprintGoalInput!\n) {\n  createSprintGoal(input: $input) {\n    sprintGoal {\n      node {\n        id\n        description\n        statusColor\n        stage\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '828794e9db73c9ed28c2cf9a89bbf63e';

module.exports = node;
