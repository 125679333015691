import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateLabelMutation($input: UpdateLabelInput!) {
		updateLabel(input: $input) {
			label {
				id
				name
				taskCount
				projectCount
				peopleCount
				category {
					id
					allowOnTasks
					allowOnProjects
					allowOnPeople
				}
				...labelCategoryRow_labels
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const {id, name, color, categoryId} = input;

	return {
		updateLabel: {
			label: {
				id,
				name,
				color,
				occurrenceCount: undefined,
				taskCount: undefined,
				projectCount: undefined,
				peopleCount: undefined,
				category: categoryId
					? {
							id: categoryId,
							allowOnTasks: undefined,
							allowOnProjects: undefined,
							allowOnPeople: undefined,
					  }
					: undefined,
			},
		},
	};
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Label Updated');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				id: input.id,
				name: input.name,
				color: input.color,
				categoryId: input.categoryId,
			},
		},
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted,
		onError,
	});
}

export default {commit};
