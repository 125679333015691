/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type MigrateAsanaProjectsInput = {|
  projectIds?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type migrateAsanaProjectsMutationVariables = {|
  input: MigrateAsanaProjectsInput
|};
export type migrateAsanaProjectsMutationResponse = {|
  +migrateAsanaProjects: ?{|
    +success: ?boolean,
    +projects: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +companyProjectId: ?number,
        +status: ?PROJECT_STATUS,
        +name: ?string,
        +projectColor: ?string,
        +isInProjectGroup: ?boolean,
        +useBaseline: ?boolean,
        +billable: ?boolean,
        +budgetType: ?BUDGET_TYPE,
        +projectGroupId: ?string,
        +sprintTimeBox: ?boolean,
        +estimationUnit: ?string,
      |}
    |}>,
  |}
|};
export type migrateAsanaProjectsMutation = {|
  variables: migrateAsanaProjectsMutationVariables,
  response: migrateAsanaProjectsMutationResponse,
|};
*/


/*
mutation migrateAsanaProjectsMutation(
  $input: MigrateAsanaProjectsInput!
) {
  migrateAsanaProjects(input: $input) {
    success
    projects {
      node {
        id
        companyProjectId
        status
        name
        projectColor
        isInProjectGroup
        useBaseline
        billable
        budgetType
        projectGroupId
        sprintTimeBox
        estimationUnit
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "MigrateAsanaProjectsInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MigrateAsanaProjectsPayload",
    "kind": "LinkedField",
    "name": "migrateAsanaProjects",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectTypeEdge",
        "kind": "LinkedField",
        "name": "projects",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyProjectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInProjectGroup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useBaseline",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "billable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budgetType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectGroupId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sprintTimeBox",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "estimationUnit",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "migrateAsanaProjectsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "migrateAsanaProjectsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "migrateAsanaProjectsMutation",
    "operationKind": "mutation",
    "text": "mutation migrateAsanaProjectsMutation(\n  $input: MigrateAsanaProjectsInput!\n) {\n  migrateAsanaProjects(input: $input) {\n    success\n    projects {\n      node {\n        id\n        companyProjectId\n        status\n        name\n        projectColor\n        isInProjectGroup\n        useBaseline\n        billable\n        budgetType\n        projectGroupId\n        sprintTimeBox\n        estimationUnit\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ff382a11b90e733af1560695d228ae19';

module.exports = node;
