import {commitMutation, graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';

const mutation = graphql`
	mutation deleteHolidayCalendarMutation($input: DeleteHolidayCalendarInput!) {
		deleteHolidayCalendar(input: $input) {
			deletedHolidayCalendarId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_holidayCalendars',
				},
			],
			pathToConnection: ['company', 'holidayCalendars'],
			deletedIDFieldName: 'deletedHolidayCalendarId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), id: input.id}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
