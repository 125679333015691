import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation LinkTeamToTeamsMutation($input: LinkTeamToTeamsInput!) {
		linkTeamToTeams(input: $input) {
			project {
				teamsId
				projectPersons(first: 10000, excludeClientUsers: true) {
					edges {
						node {
							id
							isContactPerson
							role {
								id
								name
							}
							person {
								id
								firstName
								lastName
								fullName
								initials
								permissions
								email
								profilePictureId
								profilePictureDefaultId
								active
								isViewer
								harvestUser
								role {
									id
									name
								}
								personLabels(first: 1000) {
									edges {
										node {
											id
											label {
												id
												name
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
