/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateInsightInput = {|
  name?: ?string,
  category?: ?string,
  components?: ?$ReadOnlyArray<?InsightComponentInputType>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type InsightComponentInputType = {|
  id?: ?string,
  componentName?: ?string,
  x?: ?number,
  y?: ?number,
  w?: ?number,
  h?: ?number,
  config?: ?string,
|};
export type createInsightMutationVariables = {|
  input: CreateInsightInput
|};
export type createInsightMutationResponse = {|
  +createInsight: ?{|
    +insight: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +category: ?string,
        +components: ?$ReadOnlyArray<?{|
          +id: string,
          +componentName: ?string,
          +config: ?string,
          +w: ?number,
          +h: ?number,
          +x: ?number,
          +y: ?number,
        |}>,
      |}
    |}
  |}
|};
export type createInsightMutation = {|
  variables: createInsightMutationVariables,
  response: createInsightMutationResponse,
|};
*/


/*
mutation createInsightMutation(
  $input: CreateInsightInput!
) {
  createInsight(input: $input) {
    insight {
      node {
        id
        name
        category
        components {
          id
          componentName
          config
          w
          h
          x
          y
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateInsightInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateInsightPayload",
    "kind": "LinkedField",
    "name": "createInsight",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InsightTypeEdge",
        "kind": "LinkedField",
        "name": "insight",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Insight",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InsightComponent",
                "kind": "LinkedField",
                "name": "components",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "componentName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "config",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "w",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "h",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "x",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "y",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createInsightMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createInsightMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createInsightMutation",
    "operationKind": "mutation",
    "text": "mutation createInsightMutation(\n  $input: CreateInsightInput!\n) {\n  createInsight(input: $input) {\n    insight {\n      node {\n        id\n        name\n        category\n        components {\n          id\n          componentName\n          config\n          w\n          h\n          x\n          y\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '356ce469eba59566dfffdcf87ad0fc41';

module.exports = node;
