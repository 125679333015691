import {graphql} from 'react-relay';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation requestResetPasswordMutation($input: RequestResetPasswordInput!) {
		requestResetPassword(input: $input) {
			errors
			success
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('New Password Requested');
	const variables = {input};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
