import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateDependencyMutation($input: UpdateDependencyInput!) {
		updateDependency(input: $input) {
			errors
			dependency {
				id
				type
				completed
				taskDependsOnThis {
					id
					name
					companyTaskId
					startYear
					startMonth
					startDay
					deadlineDay
					deadlineMonth
					deadlineYear
					statusColumnV2 {
						id
						category
					}
				}
				thisDependsOnTask {
					id
					name
					companyTaskId
					startYear
					startMonth
					startDay
					deadlineDay
					deadlineMonth
					deadlineYear
					statusColumnV2 {
						id
						category
					}
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const obj = {
		id: input.id,
	};
	if (input.type) {
		obj.type = input.type;
	}
	return {
		updateDependency: {
			dependency: obj,
		},
	};
}

function commit(environment, input, onCompleted, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Dependency Updated', {_Changed: changes});

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				...input,
			},
		},
		optimisticResponse: getOptimisticResponse(input),
		onCompleted,
		onError,
	});
}

export default {commit};
