/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation CreateBulkBillToMutation(
  $input: CreateBulkBillToInput!
) {
  createBulkBillTo(input: $input) {
    billTos {
      node {
        id
        name
        address
        externalId
        taxId
        billFromId
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "CreateBulkBillToInput!"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "CreateBulkBillToPayload",
            "kind": "LinkedField",
            "name": "createBulkBillTo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillToTypeEdge",
                    "kind": "LinkedField",
                    "name": "billTos",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "BillTo",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "address",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "externalId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "taxId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "billFromId",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateBulkBillToMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateBulkBillToMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "CreateBulkBillToMutation",
            "operationKind": "mutation",
            "text": "mutation CreateBulkBillToMutation(\n  $input: CreateBulkBillToInput!\n) {\n  createBulkBillTo(input: $input) {\n    billTos {\n      node {\n        id\n        name\n        address\n        externalId\n        taxId\n        billFromId\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '2a623a1a15a1c25e21ff13bf7716640d';
export default node;
