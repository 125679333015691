import { commitMutation, graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
import { createRangeBulkAddUpdater } from '../../relay-helpers/RelayCacheHelpers';
const mutation = graphql `
	mutation CreateBulkBillToMutation($input: CreateBulkBillToInput!) {
		createBulkBillTo(input: $input) {
			billTos {
				node {
					id
					name
					address
					externalId
					taxId
					billFromId
				}
			}
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    var _a, _b;
    trackEvent('Bill To', 'Bulk Created', input);
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return commitMutation(environment, {
        mutation,
        variables,
        updater: createRangeBulkAddUpdater(((_b = (_a = input.billTos) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.billFromId) || '', 'createBulkBillTo', 'billTos', 'BillFrom_billTos'),
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
