import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation UpdateSprintGoalMutation($input: UpdateSprintGoalInput!) {
		updateSprintGoal(input: $input) {
			sprintGoal {
				id
				description
				stage
				statusColor
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const obj = {
		id: input.id,
		description: input.description,
		stage: input.stage,
		statusColor: input.statusColor,
	};
	return {
		updateSprintGoal: {
			sprintGoal: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
