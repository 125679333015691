/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeletePersonDismissedProjectNotificationMutationInput = {|
  projectId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type DeletePersonDismissedProjectNotificationMutationVariables = {|
  input: DeletePersonDismissedProjectNotificationMutationInput
|};
export type DeletePersonDismissedProjectNotificationMutationResponse = {|
  +deletePersonDismissedProjectNotificationMutation: ?{|
    +viewer: ?{|
      +personDismissedProjectNotificationIds: ?$ReadOnlyArray<?string>
    |}
  |}
|};
export type DeletePersonDismissedProjectNotificationMutation = {|
  variables: DeletePersonDismissedProjectNotificationMutationVariables,
  response: DeletePersonDismissedProjectNotificationMutationResponse,
|};
*/


/*
mutation DeletePersonDismissedProjectNotificationMutation(
  $input: DeletePersonDismissedProjectNotificationMutationInput!
) {
  deletePersonDismissedProjectNotificationMutation(input: $input) {
    viewer {
      personDismissedProjectNotificationIds
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeletePersonDismissedProjectNotificationMutationInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "personDismissedProjectNotificationIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeletePersonDismissedProjectNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePersonDismissedProjectNotificationMutationPayload",
        "kind": "LinkedField",
        "name": "deletePersonDismissedProjectNotificationMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeletePersonDismissedProjectNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePersonDismissedProjectNotificationMutationPayload",
        "kind": "LinkedField",
        "name": "deletePersonDismissedProjectNotificationMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "DeletePersonDismissedProjectNotificationMutation",
    "operationKind": "mutation",
    "text": "mutation DeletePersonDismissedProjectNotificationMutation(\n  $input: DeletePersonDismissedProjectNotificationMutationInput!\n) {\n  deletePersonDismissedProjectNotificationMutation(input: $input) {\n    viewer {\n      personDismissedProjectNotificationIds\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f113bd85271a54dcd54f463bd6b280b5';

module.exports = node;
