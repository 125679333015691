import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateTimeRegistrationMutationModernMutation($input: UpdateTimeRegInput!) {
		updateTimeReg(input: $input) {
			timeReg {
				id
				day
				month
				year
				canvasTimelineDate
				minutesRegistered
				billableMinutesRegistered
				role {
					id
					name
				}
				notes
				invoiced
				role {
					id
					name
				}
				xeroInvoiceId
				lockedInPeriod
				harvestTimeId
				allocationId
				harvestTask {
					id
					name
				}
				harvestTaskIdInt
				harvestError
				unit4ActivityId
				unit4ActivityName
				project {
					id
					name
					status
					projectColor
					companyProjectId
					estimationUnit
					fullAccessToProject
					harvestProject {
						id
						name
					}
					unit4Project {
						id
						name
					}
					client {
						id
						name
					}
				}
				task {
					id
					name
					progress
					companyTaskId
					highPriority
					approved
					favoured
					fullAccessToProject
					latestUiUpdateAt
					timeLeft
					timeLeftMinutesWithoutFutureTimeRegs
					totalMinutesRegistered
					project {
						id
						name
						status
						companyProjectId
						projectColor
						estimationUnit
						fullAccessToProject
						client {
							id
							name
						}
						harvestProject {
							id
							name
						}
						unit4Project {
							id
							name
						}
					}
					phase {
						id
						name
					}
				}
				idleTime {
					id
					name
					isInternalTime
					favoured
				}
				person {
					id
					firstName
					lastName
					isViewer
				}
			}
			deletedTimeRegIds
			task {
				timeLeft
				totalMinutesRegistered
				timeLeftPrice
				currentPrice
				allTotalTimeAndExpensesAtCompletion
				progress
			}
			errors
		}
	}
`;

function getConfigs(input) {
	const config = [];
	if (input.removeOthersForTheDay) {
		config.push({
			type: 'RANGE_DELETE',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionKeys: [
				{
					key: 'Viewer_timeRegistrations',
				},
			],
			pathToConnection: ['viewer', 'timeRegistrations'],
			deletedIDFieldName: 'deletedTimeRegIds',
		});
	}
	return config;
}

function getOptimisticResponse(input) {
	const obj = {
		id: input.id,
	};

	if (input.retryHarvest) {
		obj.harvestError = null;
	}

	obj.minutesRegistered = input.minutesRegistered;
	obj.billableMinutesRegistered =
		input.billableMinutesRegistered === null ? input.minutesRegistered : input.billableMinutesRegistered;
	obj.notes = input.notes;

	return {
		updateTimeReg: {
			timeReg: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (
			(!input.updateTimeRegLocation && ['id', 'projectId', 'taskId', 'idleTimeId'].includes(key)) ||
			input[key] === undefined
		) {
			continue;
		}
		changes.push(key);
	}
	tracking.trackEvent('Time Registration Updated', {_Source: input.source ? input.source : 'NA', _Changed: changes});

	const cleanedInput = omit(input, ['newFormat', 'source', 'harvestTaskName', 'optimisticTaskId']);
	const variables = {
		input: {
			csrfToken: Util.getCsrfValue(),
			socketClientId: Util.getClientId(),
			...cleanedInput,
			minutesRegistered: !isNaN(input.minutesRegistered) ? Math.round(input.minutesRegistered) : undefined,
			billableMinutesRegistered:
				input.billableMinutesRegistered === null
					? null
					: !isNaN(input.billableMinutesRegistered)
					? Math.round(input.billableMinutesRegistered)
					: undefined,
		},
	};

	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables,
			configs: getConfigs(input),
			optimisticResponse: getOptimisticResponse(input),
			onCompleted: onSuccess,
			onError: onError,
		},
		true
	);
}

export default {commit};
