import {commitMutation, graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';

const mutation = graphql`
	mutation SetSlackNotificationSettingMutation($input: SetSlackNotificationSettingInput!) {
		setSlackNotificationSetting(input: $input) {
			slackNotificationSettings {
				disableNotifications
				notifyOnProjectStatusChange
				notifyOnAssignedProject
				notifyOnPersonJoin
				notifyOnProjectDeadline
				notifyOnAssignedTask
				notifyOnAssignedTaskUpdate
				notifyOnTaskDeadline
				notifyOnMention
				notifyOnTaskDescriptionChange
				notifyOnTaskTitleChange
				notifyOnTaskEstimateChange
				notifyOnTaskStartDateChange
				notifyOnTaskEndDateChange
				notifyOnTaskSprintChange
				notifyOnTaskPhaseChange
				notifyOnTaskStatusColumnChange
				notifyOnTaskProjectChange
				notifyOnTaskSubtaskChange
				notifyOnTaskSubtaskEstimateChange
				notifyOnTaskCommentChange
				notifyOnTaskFileChange
				notifyOnTaskBugChange
				notifyOnTaskBlockedChange
				notifyOnTaskRepeatingChange
				notifyOnInvoiceDueDate
				notifyOnInvoiceOverdue
				notifyOnInvoiceDaysOverdue
				notifyOnInvoiceCreatedOrDeleted
				notifyOnInvoiceStatusChange
				notifyOnInvoiceDateReached
				notifyOnInvoicePayment
				notifyOnInvoiceDateChanged
				notifyOnInvoiceDueDateChanged
				notifyOnTimeOffManager
				notifyOnTimeOffOwner
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
