import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteFolderMutation($input: DeleteFolderInput!) {
		deleteFolder(input: $input) {
			deletedFolderId
			project {
				id
				folders(first: 1000) {
					edges {
						node {
							id
							folder {
								id
							}
						}
					}
				}
				tasks(first: 1000) {
					edges {
						node {
							files(first: 100) {
								edges {
									node {
										id
										folder {
											id
										}
									}
								}
							}
						}
					}
				}
				files(first: 10000) {
					edges {
						node {
							id
							folder {
								id
							}
						}
					}
				}
				googleDriveFiles(first: 10000) {
					edges {
						node {
							id
							folder {
								id
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_folders',
				},
			],
			pathToConnection: ['project', 'folders'],
			deletedIDFieldName: 'deletedFolderId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Folder Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				id: input.id,
				projectId: input.projectId,
			},
		},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
