/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProgramDatesInput = {|
  id?: ?string,
  startDate?: ?string,
  endDate?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateProgramDatesMutationVariables = {|
  input: UpdateProgramDatesInput
|};
export type updateProgramDatesMutationResponse = {|
  +updateProgramDates: ?{|
    +program: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +description: ?string,
        +prefix: ?string,
        +color: ?string,
        +startDate: ?string,
        +endDate: ?string,
      |}
    |}
  |}
|};
export type updateProgramDatesMutation = {|
  variables: updateProgramDatesMutationVariables,
  response: updateProgramDatesMutationResponse,
|};
*/


/*
mutation updateProgramDatesMutation(
  $input: UpdateProgramDatesInput!
) {
  updateProgramDates(input: $input) {
    program {
      node {
        id
        name
        description
        prefix
        color
        startDate
        endDate
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProgramDatesInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateProgramDatesPayload",
    "kind": "LinkedField",
    "name": "updateProgramDates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectServiceProgramTypeEdge",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateProgramDatesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateProgramDatesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateProgramDatesMutation",
    "operationKind": "mutation",
    "text": "mutation updateProgramDatesMutation(\n  $input: UpdateProgramDatesInput!\n) {\n  updateProgramDates(input: $input) {\n    program {\n      node {\n        id\n        name\n        description\n        prefix\n        color\n        startDate\n        endDate\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '65af81637b5386158187062018765ece';

module.exports = node;
