/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type APPROVAL_STATUS = "APPROVED" | "NOT_SUBMITTED" | "SUBMITTED" | "%future added value";
export type APPROVAL_TYPE = "APPROVE" | "FORCE_APPROVE" | "SUBMIT" | "SUBMIT_TIME_OFF" | "UNAPPROVE" | "UNSUBMIT" | "%future added value";
export type UpdateApprovalStatusInput = {|
  personIds?: ?$ReadOnlyArray<?string>,
  projectIds?: ?$ReadOnlyArray<?string>,
  idleTimeIds?: ?$ReadOnlyArray<?string>,
  approvalType?: ?APPROVAL_TYPE,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  endYear?: ?number,
  endMonth?: ?number,
  endDay?: ?number,
  sendNotification?: ?boolean,
  rejectionMessage?: ?string,
  includeTimeOff?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateApprovalStatusMutationVariables = {|
  input: UpdateApprovalStatusInput
|};
export type UpdateApprovalStatusMutationResponse = {|
  +updateApprovalStatus: ?{|
    +timeRegs: ?$ReadOnlyArray<?{|
      +id: string,
      +approvalStatus: ?APPROVAL_STATUS,
      +noApprovalStatusChange: ?string,
      +lockedInPeriod: ?boolean,
    |}>,
    +persons: ?$ReadOnlyArray<?{|
      +id: string,
      +submitLockedDateYear: ?number,
      +submitLockedDateMonth: ?number,
      +submitLockedDateDay: ?number,
    |}>,
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type UpdateApprovalStatusMutation = {|
  variables: UpdateApprovalStatusMutationVariables,
  response: UpdateApprovalStatusMutationResponse,
|};
*/


/*
mutation UpdateApprovalStatusMutation(
  $input: UpdateApprovalStatusInput!
) {
  updateApprovalStatus(input: $input) {
    timeRegs {
      id
      approvalStatus
      noApprovalStatusChange
      lockedInPeriod
    }
    persons {
      id
      submitLockedDateYear
      submitLockedDateMonth
      submitLockedDateDay
    }
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateApprovalStatusInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateApprovalStatusPayload",
    "kind": "LinkedField",
    "name": "updateApprovalStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TimeRegType",
        "kind": "LinkedField",
        "name": "timeRegs",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "approvalStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "noApprovalStatusChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockedInPeriod",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "persons",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submitLockedDateYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submitLockedDateMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submitLockedDateDay",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateApprovalStatusMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateApprovalStatusMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateApprovalStatusMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateApprovalStatusMutation(\n  $input: UpdateApprovalStatusInput!\n) {\n  updateApprovalStatus(input: $input) {\n    timeRegs {\n      id\n      approvalStatus\n      noApprovalStatusChange\n      lockedInPeriod\n    }\n    persons {\n      id\n      submitLockedDateYear\n      submitLockedDateMonth\n      submitLockedDateDay\n    }\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3827fa0e520efa40dd33536702a09326';

module.exports = node;
