import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';

const mutation = graphql`
	mutation CreateTemporaryReportMutation($input: CreateTemporaryReportInput!) {
		createTemporaryReport(input: $input) {
			temporaryReport {
				id
				type
				name
				person {
					id
					fullName
				}
				groupingOne
				groupingTwo
				groupingThree
				eyeApplied
				filterApplied
				startDate
				endDate
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError, uploadables) {
	trackEvent('Temporary Standard Report', 'Created');
	const cleanedInput = omit(input, ['companyId']);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
