/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QUANTITY_LOCKED_REASON = "FIXED_PRICE" | "FIXED_PRICE_PERIOD" | "%future added value";
export type CreateInvoiceInput = {|
  clientId?: ?string,
  projectId?: ?string,
  name: string,
  currency?: ?string,
  invoiceReference?: ?string,
  invoiceType?: ?string,
  status?: ?string,
  dueDay?: ?number,
  dueMonth?: ?number,
  dueYear?: ?number,
  createdDay?: ?number,
  createdMonth?: ?number,
  createdYear?: ?number,
  notes?: ?string,
  entries: $ReadOnlyArray<?InvoiceEntryInput>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type InvoiceEntryInput = {|
  id?: ?string,
  name?: ?string,
  projectId?: ?string,
  quantity?: ?number,
  quantityText?: ?string,
  unitPrice?: ?number,
  discount?: ?number,
  tax?: ?number,
  description?: ?string,
  timeRegIds?: ?$ReadOnlyArray<?string>,
  expenseItemIds?: ?$ReadOnlyArray<?string>,
  periodIds?: ?$ReadOnlyArray<?string>,
  externalRefs?: ?$ReadOnlyArray<?ExternalRef>,
  quantityLockedReason?: ?QUANTITY_LOCKED_REASON,
|};
export type ExternalRef = {|
  key?: ?string,
  value?: ?string,
|};
export type createInvoiceMutationVariables = {|
  input: CreateInvoiceInput
|};
export type createInvoiceMutationResponse = {|
  +createInvoice: ?{|
    +invoice: ?{|
      +node: ?{|
        +id: string,
        +invoiceReference: ?string,
        +companyInvoiceId: ?number,
        +name: ?string,
        +currency: ?string,
        +invoiceType: ?string,
        +status: ?string,
        +dueDay: ?number,
        +dueMonth: ?number,
        +dueYear: ?number,
        +createdDay: ?number,
        +createdMonth: ?number,
        +createdYear: ?number,
        +notes: ?string,
        +quickbooksId: ?number,
        +xeroId: ?string,
        +economicId: ?number,
        +project: ?{|
          +retainerPeriods: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +invoiced: ?boolean
              |}
            |}>
          |}
        |},
        +entries: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +quantity: ?number,
              +unitPrice: ?number,
              +discount: ?number,
              +tax: ?number,
              +description: ?string,
            |}
          |}>
        |},
        +payments: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +notes: ?string,
              +amount: ?number,
              +day: ?number,
              +month: ?number,
              +year: ?number,
              +createdAt: ?string,
              +createdBy: ?{|
                +fullName: ?string
              |},
            |}
          |}>
        |},
      |}
    |}
  |}
|};
export type createInvoiceMutation = {|
  variables: createInvoiceMutationVariables,
  response: createInvoiceMutationResponse,
|};
*/


/*
mutation createInvoiceMutation(
  $input: CreateInvoiceInput!
) {
  createInvoice(input: $input) {
    invoice {
      node {
        id
        invoiceReference
        companyInvoiceId
        name
        currency
        invoiceType
        status
        dueDay
        dueMonth
        dueYear
        createdDay
        createdMonth
        createdYear
        notes
        quickbooksId
        xeroId
        economicId
        project {
          retainerPeriods {
            edges {
              node {
                invoiced
                id
              }
            }
          }
          id
        }
        entries(first: 100000000) {
          edges {
            node {
              id
              name
              quantity
              unitPrice
              discount
              tax
              description
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        payments(first: 100000000) {
          edges {
            node {
              id
              notes
              amount
              day
              month
              year
              createdAt
              createdBy {
                fullName
                id
              }
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateInvoiceInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceReference",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyInvoiceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDay",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueMonth",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueYear",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDay",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdMonth",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdYear",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quickbooksId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xeroId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "economicId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiced",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InvoiceEntryTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceEntryType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unitPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v20/*: any*/)
        ],
        "storageKey": null
      },
      (v21/*: any*/)
    ],
    "storageKey": null
  },
  (v22/*: any*/)
],
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v30 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateInvoicePayload",
        "kind": "LinkedField",
        "name": "createInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceTypeEdge",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InvoiceType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RetainerPeriodTypeConnection",
                        "kind": "LinkedField",
                        "name": "retainerPeriods",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RetainerPeriodTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RetainerPeriodType",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v19/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "entries",
                    "args": null,
                    "concreteType": "InvoiceEntryTypeConnection",
                    "kind": "LinkedField",
                    "name": "__Invoice_entries_connection",
                    "plural": false,
                    "selections": (v23/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": "payments",
                    "args": null,
                    "concreteType": "InvoicePaymentTypeConnection",
                    "kind": "LinkedField",
                    "name": "__Invoice_payments_connection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoicePaymentTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InvoicePaymentType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v15/*: any*/),
                              (v24/*: any*/),
                              (v25/*: any*/),
                              (v26/*: any*/),
                              (v27/*: any*/),
                              (v28/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Person",
                                "kind": "LinkedField",
                                "name": "createdBy",
                                "plural": false,
                                "selections": [
                                  (v29/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v20/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v21/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createInvoiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateInvoicePayload",
        "kind": "LinkedField",
        "name": "createInvoice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceTypeEdge",
            "kind": "LinkedField",
            "name": "invoice",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InvoiceType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RetainerPeriodTypeConnection",
                        "kind": "LinkedField",
                        "name": "retainerPeriods",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RetainerPeriodTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RetainerPeriodType",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v19/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v30/*: any*/),
                    "concreteType": "InvoiceEntryTypeConnection",
                    "kind": "LinkedField",
                    "name": "entries",
                    "plural": false,
                    "selections": (v23/*: any*/),
                    "storageKey": "entries(first:100000000)"
                  },
                  {
                    "alias": null,
                    "args": (v30/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "Invoice_entries",
                    "kind": "LinkedHandle",
                    "name": "entries"
                  },
                  {
                    "alias": null,
                    "args": (v30/*: any*/),
                    "concreteType": "InvoicePaymentTypeConnection",
                    "kind": "LinkedField",
                    "name": "payments",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoicePaymentTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InvoicePaymentType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v15/*: any*/),
                              (v24/*: any*/),
                              (v25/*: any*/),
                              (v26/*: any*/),
                              (v27/*: any*/),
                              (v28/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Person",
                                "kind": "LinkedField",
                                "name": "createdBy",
                                "plural": false,
                                "selections": [
                                  (v29/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v20/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v21/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v22/*: any*/)
                    ],
                    "storageKey": "payments(first:100000000)"
                  },
                  {
                    "alias": null,
                    "args": (v30/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "Invoice_payments",
                    "kind": "LinkedHandle",
                    "name": "payments"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "createInvoice",
            "invoice",
            "node",
            "entries"
          ]
        },
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "createInvoice",
            "invoice",
            "node",
            "payments"
          ]
        }
      ]
    },
    "name": "createInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation createInvoiceMutation(\n  $input: CreateInvoiceInput!\n) {\n  createInvoice(input: $input) {\n    invoice {\n      node {\n        id\n        invoiceReference\n        companyInvoiceId\n        name\n        currency\n        invoiceType\n        status\n        dueDay\n        dueMonth\n        dueYear\n        createdDay\n        createdMonth\n        createdYear\n        notes\n        quickbooksId\n        xeroId\n        economicId\n        project {\n          retainerPeriods {\n            edges {\n              node {\n                invoiced\n                id\n              }\n            }\n          }\n          id\n        }\n        entries(first: 100000000) {\n          edges {\n            node {\n              id\n              name\n              quantity\n              unitPrice\n              discount\n              tax\n              description\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        payments(first: 100000000) {\n          edges {\n            node {\n              id\n              notes\n              amount\n              day\n              month\n              year\n              createdAt\n              createdBy {\n                fullName\n                id\n              }\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2dbc1c8102e82d214c8e2c7833ac0994';

module.exports = node;
