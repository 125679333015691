/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteSprintInput = {|
  id?: ?string,
  projectId?: ?string,
  projectGroupId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteSprintMutationVariables = {|
  input: DeleteSprintInput
|};
export type deleteSprintMutationResponse = {|
  +deleteSprint: ?{|
    +deletedSprintId: ?string,
    +project: ?{|
      +id: string,
      +tasks: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +sprint: ?{|
              +id: string
            |},
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
            +startFrom: ?string,
            +deadlineFrom: ?string,
            +deadlineDay: ?number,
            +deadlineMonth: ?number,
            +deadlineYear: ?number,
          |}
        |}>
      |},
    |},
    +projectGroup: ?{|
      +id: string,
      +projects: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +tasks: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +sprint: ?{|
                    +id: string
                  |},
                  +startDay: ?number,
                  +startMonth: ?number,
                  +startYear: ?number,
                  +startFrom: ?string,
                  +deadlineFrom: ?string,
                  +deadlineDay: ?number,
                  +deadlineMonth: ?number,
                  +deadlineYear: ?number,
                |}
              |}>
            |}
          |}
        |}>
      |},
    |},
  |}
|};
export type deleteSprintMutation = {|
  variables: deleteSprintMutationVariables,
  response: deleteSprintMutationResponse,
|};
*/


/*
mutation deleteSprintMutation(
  $input: DeleteSprintInput!
) {
  deleteSprint(input: $input) {
    deletedSprintId
    project {
      id
      tasks(first: 10000) {
        edges {
          node {
            sprint {
              id
            }
            startDay
            startMonth
            startYear
            startFrom
            deadlineFrom
            deadlineDay
            deadlineMonth
            deadlineYear
            id
          }
        }
      }
    }
    projectGroup {
      id
      projects(first: 10000) {
        edges {
          node {
            tasks(first: 10000) {
              edges {
                node {
                  sprint {
                    id
                  }
                  startDay
                  startMonth
                  startYear
                  startFrom
                  deadlineFrom
                  deadlineDay
                  deadlineMonth
                  deadlineYear
                  id
                }
              }
            }
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteSprintInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedSprintId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Sprint",
  "kind": "LinkedField",
  "name": "sprint",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startFrom",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineFrom",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": (v4/*: any*/),
  "concreteType": "TaskTypeConnection",
  "kind": "LinkedField",
  "name": "tasks",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "tasks(first:10000)"
},
v15 = {
  "alias": null,
  "args": (v4/*: any*/),
  "concreteType": "TaskTypeConnection",
  "kind": "LinkedField",
  "name": "tasks",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "tasks(first:10000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteSprintMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteSprintPayload",
        "kind": "LinkedField",
        "name": "deleteSprint",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectGroupType",
            "kind": "LinkedField",
            "name": "projectGroup",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "ProjectTypeConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "projects(first:10000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteSprintMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteSprintPayload",
        "kind": "LinkedField",
        "name": "deleteSprint",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectGroupType",
            "kind": "LinkedField",
            "name": "projectGroup",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "ProjectTypeConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "projects(first:10000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteSprintMutation",
    "operationKind": "mutation",
    "text": "mutation deleteSprintMutation(\n  $input: DeleteSprintInput!\n) {\n  deleteSprint(input: $input) {\n    deletedSprintId\n    project {\n      id\n      tasks(first: 10000) {\n        edges {\n          node {\n            sprint {\n              id\n            }\n            startDay\n            startMonth\n            startYear\n            startFrom\n            deadlineFrom\n            deadlineDay\n            deadlineMonth\n            deadlineYear\n            id\n          }\n        }\n      }\n    }\n    projectGroup {\n      id\n      projects(first: 10000) {\n        edges {\n          node {\n            tasks(first: 10000) {\n              edges {\n                node {\n                  sprint {\n                    id\n                  }\n                  startDay\n                  startMonth\n                  startYear\n                  startFrom\n                  deadlineFrom\n                  deadlineDay\n                  deadlineMonth\n                  deadlineYear\n                  id\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '32b5d92314bdbe22174231b4e6b07f10';

module.exports = node;
