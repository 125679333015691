import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation verifyUnit4IntegrationMutation($input: VerifyUnit4IntegrationInput!) {
		verifyUnit4Integration(input: $input) {
			success
			message
			company {
				unit4Domain
				unit4DomainSoap
				unit4Client
				unit4Username
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Unit4 verification attempt');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(environment, {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
