/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type github_type = "branch" | "issue" | "pullrequest" | "%future added value";
export type github_type_mutation = "branch" | "issue" | "pullrequest" | "%future added value";
export type CreateGithubAttachedObjectInput = {|
  taskId?: ?string,
  githubId?: ?string,
  githubRepo?: ?string,
  githubType?: ?github_type_mutation,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createGithubAttachedObjectMutationVariables = {|
  input: CreateGithubAttachedObjectInput
|};
export type createGithubAttachedObjectMutationResponse = {|
  +createGithubAttachedObject: ?{|
    +githubAttachedObject: ?{|
      +node: ?{|
        +id: ?string,
        +number: ?string,
        +title: ?string,
        +repo: ?string,
        +url: ?string,
        +createdBy: ?string,
        +type: ?github_type,
        +status: ?string,
        +state: ?string,
        +base: ?string,
        +head: ?string,
      |}
    |}
  |}
|};
export type createGithubAttachedObjectMutation = {|
  variables: createGithubAttachedObjectMutationVariables,
  response: createGithubAttachedObjectMutationResponse,
|};
*/


/*
mutation createGithubAttachedObjectMutation(
  $input: CreateGithubAttachedObjectInput!
) {
  createGithubAttachedObject(input: $input) {
    githubAttachedObject {
      node {
        id
        number
        title
        repo
        url
        createdBy
        type
        status
        state
        base
        head
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateGithubAttachedObjectInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateGithubAttachedObjectPayload",
    "kind": "LinkedField",
    "name": "createGithubAttachedObject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GithubAttachedObjectTypeEdge",
        "kind": "LinkedField",
        "name": "githubAttachedObject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GithubAttachedObject",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "repo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdBy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "base",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "head",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createGithubAttachedObjectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createGithubAttachedObjectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createGithubAttachedObjectMutation",
    "operationKind": "mutation",
    "text": "mutation createGithubAttachedObjectMutation(\n  $input: CreateGithubAttachedObjectInput!\n) {\n  createGithubAttachedObject(input: $input) {\n    githubAttachedObject {\n      node {\n        id\n        number\n        title\n        repo\n        url\n        createdBy\n        type\n        status\n        state\n        base\n        head\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '458a5335fc1a291454a5e81485f2dcc3';

module.exports = node;
