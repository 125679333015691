import styled from 'styled-components';
export const ComponentContainer = styled.div`
	.project-budget-type-controls-container {
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
	}
`;
export const ButtonsStyled = styled.div`
	.budget-type-buttons {
		display: flex;
		flex-direction: row;
		margin-bottom: 18px;
		height: 34px;
		.tooltip-container,
		.custom-button-container {
			flex: 1;
		}
	}
`;
export const SpecificValuesStyled = styled.div`
	.budget-type-specific-values {
		.price-section {
			display: flex;
			flex-direction: row;
			min-height: 64px;
			max-height: 194px;
			transition: all 0.5s ease-in-out;
			&.fixed_price,
			&.time_and_materials {
				&:not(.edit-mode) {
					opacity: 1;
					min-height: 64px;
					max-height: 64px;
				}
			}
			&.time_and_materials_lite,
			&.time_and_materials.edit-mode {
				opacity: 1;
				min-height: 0px;
				max-height: 0px;
			}
			&.non_billable {
				opacity: 0;
				min-height: 0px;
				max-height: 0px;
			}
			&.retainer {
				opacity: 1;
			}
			.fixed-price {
				width: 108px;
				margin-right: 66px;
			}
			.rate-card {
				width: 224px;
				margin-bottom: 19px;
				margin-right: 8px;
			}
		}
		.retainer-section {
			.existing_periods_warning {
				font-size: 13px;
				margin: 0 0 16px 0;
				color: #535353;
			}
			.retainer-length-and-value {
				display: flex;
				flex-direction: row;
				//margin-bottom: 19px;
				.retainer-container {
					display: flex;
					flex-direction: row;
					margin-right: 8px;
					&:last-child {
						margin-right: 0px;
					}
					.drop-down-v2 {
						width: 166px;
						margin-right: 8px;
						margin-bottom: 10px;
					}
					.input-container-v2 {
						width: 108px;
					}
				}
			}
			.budget-type-period {
				.dropdown-custom {
					display: flex;
					flex-direction: column;
					width: 166px;
				}
			}
		}
	}
`;
