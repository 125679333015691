/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSprintInput = {|
  id?: ?string,
  projectId?: ?string,
  projectGroupId?: ?string,
  name?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  endYear?: ?number,
  endMonth?: ?number,
  endDay?: ?number,
  savedPerformance?: ?number,
  description?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateSprintMutationVariables = {|
  input: UpdateSprintInput
|};
export type updateSprintMutationResponse = {|
  +updateSprint: ?{|
    +sprint: ?{|
      +name: ?string,
      +startYear: ?number,
      +startMonth: ?number,
      +startDay: ?number,
      +endDay: ?number,
      +endMonth: ?number,
      +endYear: ?number,
      +timeLeft: ?number,
      +completion: ?number,
      +forecast: ?number,
      +available: ?number,
      +description: ?string,
      +savedPerformance: ?number,
    |},
    +projectGroupSprint: ?{|
      +name: ?string,
      +timeLeft: ?number,
      +completion: ?number,
      +forecast: ?number,
      +available: ?number,
    |},
  |}
|};
export type updateSprintMutation = {|
  variables: updateSprintMutationVariables,
  response: updateSprintMutationResponse,
|};
*/


/*
mutation updateSprintMutation(
  $input: UpdateSprintInput!
) {
  updateSprint(input: $input) {
    sprint {
      name
      startYear
      startMonth
      startDay
      endDay
      endMonth
      endYear
      timeLeft
      completion
      forecast
      available
      description
      savedPerformance
      id
    }
    projectGroupSprint {
      name
      timeLeft
      completion
      forecast
      available
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSprintInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDay",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endMonth",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endYear",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeLeft",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completion",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "forecast",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "available",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "savedPerformance",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateSprintMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSprintPayload",
        "kind": "LinkedField",
        "name": "updateSprint",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Sprint",
            "kind": "LinkedField",
            "name": "sprint",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectGroupSprintType",
            "kind": "LinkedField",
            "name": "projectGroupSprint",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateSprintMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSprintPayload",
        "kind": "LinkedField",
        "name": "updateSprint",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Sprint",
            "kind": "LinkedField",
            "name": "sprint",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectGroupSprintType",
            "kind": "LinkedField",
            "name": "projectGroupSprint",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateSprintMutation",
    "operationKind": "mutation",
    "text": "mutation updateSprintMutation(\n  $input: UpdateSprintInput!\n) {\n  updateSprint(input: $input) {\n    sprint {\n      name\n      startYear\n      startMonth\n      startDay\n      endDay\n      endMonth\n      endYear\n      timeLeft\n      completion\n      forecast\n      available\n      description\n      savedPerformance\n      id\n    }\n    projectGroupSprint {\n      name\n      timeLeft\n      completion\n      forecast\n      available\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c6e0b2a80f331acf73b53a17d8a526d';

module.exports = node;
