/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LinkTeamToTeamsInput = {|
  projectId?: ?string,
  teamId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type LinkTeamToTeamsMutationVariables = {|
  input: LinkTeamToTeamsInput
|};
export type LinkTeamToTeamsMutationResponse = {|
  +linkTeamToTeams: ?{|
    +project: ?{|
      +teamsId: ?string,
      +projectPersons: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +isContactPerson: ?boolean,
            +role: ?{|
              +id: string,
              +name: ?string,
            |},
            +person: ?{|
              +id: string,
              +firstName: ?string,
              +lastName: ?string,
              +fullName: ?string,
              +initials: ?string,
              +permissions: ?$ReadOnlyArray<?string>,
              +email: ?string,
              +profilePictureId: ?string,
              +profilePictureDefaultId: ?number,
              +active: ?boolean,
              +isViewer: ?boolean,
              +harvestUser: ?boolean,
              +role: ?{|
                +id: string,
                +name: ?string,
              |},
              +personLabels: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +label: ?{|
                      +id: string,
                      +name: ?string,
                    |},
                  |}
                |}>
              |},
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type LinkTeamToTeamsMutation = {|
  variables: LinkTeamToTeamsMutationVariables,
  response: LinkTeamToTeamsMutationResponse,
|};
*/


/*
mutation LinkTeamToTeamsMutation(
  $input: LinkTeamToTeamsInput!
) {
  linkTeamToTeams(input: $input) {
    project {
      teamsId
      projectPersons(first: 10000, excludeClientUsers: true) {
        edges {
          node {
            id
            isContactPerson
            role {
              id
              name
            }
            person {
              id
              firstName
              lastName
              fullName
              initials
              permissions
              email
              profilePictureId
              profilePictureDefaultId
              active
              isViewer
              harvestUser
              role {
                id
                name
              }
              personLabels(first: 1000) {
                edges {
                  node {
                    id
                    label {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "LinkTeamToTeamsInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamsId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "excludeClientUsers",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000
    }
  ],
  "concreteType": "ProjectPersonTypeConnection",
  "kind": "LinkedField",
  "name": "projectPersons",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectPersonTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectPerson",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isContactPerson",
              "storageKey": null
            },
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "initials",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "permissions",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureDefaultId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "active",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isViewer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "harvestUser",
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 1000
                    }
                  ],
                  "concreteType": "PersonLabelTypeConnection",
                  "kind": "LinkedField",
                  "name": "personLabels",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PersonLabelTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PersonLabel",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Label",
                              "kind": "LinkedField",
                              "name": "label",
                              "plural": false,
                              "selections": (v4/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "personLabels(first:1000)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "projectPersons(excludeClientUsers:true,first:10000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LinkTeamToTeamsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LinkTeamToTeamsPayload",
        "kind": "LinkedField",
        "name": "linkTeamToTeams",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LinkTeamToTeamsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LinkTeamToTeamsPayload",
        "kind": "LinkedField",
        "name": "linkTeamToTeams",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "LinkTeamToTeamsMutation",
    "operationKind": "mutation",
    "text": "mutation LinkTeamToTeamsMutation(\n  $input: LinkTeamToTeamsInput!\n) {\n  linkTeamToTeams(input: $input) {\n    project {\n      teamsId\n      projectPersons(first: 10000, excludeClientUsers: true) {\n        edges {\n          node {\n            id\n            isContactPerson\n            role {\n              id\n              name\n            }\n            person {\n              id\n              firstName\n              lastName\n              fullName\n              initials\n              permissions\n              email\n              profilePictureId\n              profilePictureDefaultId\n              active\n              isViewer\n              harvestUser\n              role {\n                id\n                name\n              }\n              personLabels(first: 1000) {\n                edges {\n                  node {\n                    id\n                    label {\n                      id\n                      name\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e343f851feb1dc3a3d44cee83820d99c';

module.exports = node;
