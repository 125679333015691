import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deletePersonMutation($input: DeletePersonInput!) {
		deletePerson(input: $input) {
			deletedPersonId
			errors
		}
	}
`;

function getConfigs(input) {
	const configs = [
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_allPersons',
				},
			],
			pathToConnection: ['company', 'person'],
			deletedIDFieldName: 'deletedPersonId',
		},
	];
	if (input.parentId) {
		configs.push({
			type: 'RANGE_DELETE',
			parentID: input.parentId,
			connectionKeys: [
				{
					key: 'Client_clientUsers',
				},
			],
			pathToConnection: ['client', 'clientUsers'],
			deletedIDFieldName: 'deletedPersonId',
		});
	}
	return configs;
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Person Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), id: input.id}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
