/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTimeLockInput = {|
  companyId?: ?string,
  lockedPeriodYear?: ?number,
  lockedPeriodMonth?: ?number,
  lockedPeriodDay?: ?number,
  personId?: ?string,
  useTimeLocking?: ?boolean,
  excludeFromCompanyLockedPeriod?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateTimeLockMutationVariables = {|
  input: UpdateTimeLockInput
|};
export type updateTimeLockMutationResponse = {|
  +updateTimeLock: ?{|
    +company: ?{|
      +id: string,
      +lockedPeriodYear: ?number,
      +lockedPeriodMonth: ?number,
      +lockedPeriodDay: ?number,
      +useTimeLocking: ?boolean,
    |},
    +person: ?{|
      +id: string,
      +excludeFromCompanyLockedPeriod: ?boolean,
    |},
  |}
|};
export type updateTimeLockMutation = {|
  variables: updateTimeLockMutationVariables,
  response: updateTimeLockMutationResponse,
|};
*/


/*
mutation updateTimeLockMutation(
  $input: UpdateTimeLockInput!
) {
  updateTimeLock(input: $input) {
    company {
      id
      lockedPeriodYear
      lockedPeriodMonth
      lockedPeriodDay
      useTimeLocking
    }
    person {
      id
      excludeFromCompanyLockedPeriod
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTimeLockInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTimeLockPayload",
    "kind": "LinkedField",
    "name": "updateTimeLock",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockedPeriodYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockedPeriodMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockedPeriodDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "useTimeLocking",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "excludeFromCompanyLockedPeriod",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateTimeLockMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateTimeLockMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateTimeLockMutation",
    "operationKind": "mutation",
    "text": "mutation updateTimeLockMutation(\n  $input: UpdateTimeLockInput!\n) {\n  updateTimeLock(input: $input) {\n    company {\n      id\n      lockedPeriodYear\n      lockedPeriodMonth\n      lockedPeriodDay\n      useTimeLocking\n    }\n    person {\n      id\n      excludeFromCompanyLockedPeriod\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd9320036570eddb1f39d60d57cb240a7';

module.exports = node;
