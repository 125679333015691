/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateExchangeRateInput = {|
  id?: ?string,
  rate?: ?number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateExchangeRateMutationVariables = {|
  input: UpdateExchangeRateInput
|};
export type updateExchangeRateMutationResponse = {|
  +updateExchangeRate: ?{|
    +exchangeRate: ?{|
      +rate: ?number
    |}
  |}
|};
export type updateExchangeRateMutation = {|
  variables: updateExchangeRateMutationVariables,
  response: updateExchangeRateMutationResponse,
|};
*/


/*
mutation updateExchangeRateMutation(
  $input: UpdateExchangeRateInput!
) {
  updateExchangeRate(input: $input) {
    exchangeRate {
      rate
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateExchangeRateInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateExchangeRateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExchangeRatePayload",
        "kind": "LinkedField",
        "name": "updateExchangeRate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExchangeRate",
            "kind": "LinkedField",
            "name": "exchangeRate",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateExchangeRateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExchangeRatePayload",
        "kind": "LinkedField",
        "name": "updateExchangeRate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExchangeRate",
            "kind": "LinkedField",
            "name": "exchangeRate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateExchangeRateMutation",
    "operationKind": "mutation",
    "text": "mutation updateExchangeRateMutation(\n  $input: UpdateExchangeRateInput!\n) {\n  updateExchangeRate(input: $input) {\n    exchangeRate {\n      rate\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'db8e592a5c59871a06c0068479b150c2';

module.exports = node;
