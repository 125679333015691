/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ExportTeamToTeamsInput = {|
  projectId?: ?string,
  teamName?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type ExportTeamToTeamsMutationVariables = {|
  input: ExportTeamToTeamsInput
|};
export type ExportTeamToTeamsMutationResponse = {|
  +exportTeamToTeams: ?{|
    +project: ?{|
      +teamsId: ?string
    |}
  |}
|};
export type ExportTeamToTeamsMutation = {|
  variables: ExportTeamToTeamsMutationVariables,
  response: ExportTeamToTeamsMutationResponse,
|};
*/


/*
mutation ExportTeamToTeamsMutation(
  $input: ExportTeamToTeamsInput!
) {
  exportTeamToTeams(input: $input) {
    project {
      teamsId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ExportTeamToTeamsInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamsId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportTeamToTeamsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExportTeamToTeamsPayload",
        "kind": "LinkedField",
        "name": "exportTeamToTeams",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportTeamToTeamsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExportTeamToTeamsPayload",
        "kind": "LinkedField",
        "name": "exportTeamToTeams",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ExportTeamToTeamsMutation",
    "operationKind": "mutation",
    "text": "mutation ExportTeamToTeamsMutation(\n  $input: ExportTeamToTeamsInput!\n) {\n  exportTeamToTeams(input: $input) {\n    project {\n      teamsId\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e01e927c168e51d0a82e915196ff6431';

module.exports = node;
