/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkDeleteSavedReportInput = {|
  reportServiceIds?: ?$ReadOnlyArray<?string>,
  savedReportIds?: ?$ReadOnlyArray<?string>,
  companyId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type bulkDeleteSavedReportMutationVariables = {|
  input: BulkDeleteSavedReportInput
|};
export type bulkDeleteSavedReportMutationResponse = {|
  +bulkDeleteSavedReport: ?{|
    +deletedReportIds: ?$ReadOnlyArray<?string>,
    +company: ?{|
      +id: string
    |},
  |}
|};
export type bulkDeleteSavedReportMutation = {|
  variables: bulkDeleteSavedReportMutationVariables,
  response: bulkDeleteSavedReportMutationResponse,
|};
*/


/*
mutation bulkDeleteSavedReportMutation(
  $input: BulkDeleteSavedReportInput!
) {
  bulkDeleteSavedReport(input: $input) {
    deletedReportIds
    company {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "BulkDeleteSavedReportInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BulkDeleteSavedReportPayload",
    "kind": "LinkedField",
    "name": "bulkDeleteSavedReport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedReportIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bulkDeleteSavedReportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bulkDeleteSavedReportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "bulkDeleteSavedReportMutation",
    "operationKind": "mutation",
    "text": "mutation bulkDeleteSavedReportMutation(\n  $input: BulkDeleteSavedReportInput!\n) {\n  bulkDeleteSavedReport(input: $input) {\n    deletedReportIds\n    company {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d06e8325a70b4f10c251c6b798ec8ee';

module.exports = node;
