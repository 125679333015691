import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation CreateSisenseJwtMutation($input: CreateSisenseJwtInput!) {
		createSisenseJwt(input: $input) {
			jwt
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {
			input: {
				socketClientId: Util.getClientId(),
				...input,
			},
		},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
