import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createDependencyMutation($input: CreateDependencyInput!) {
		createDependency(input: $input) {
			errors
			dependency {
				node {
					id
					type
					completed
					taskDependsOnThis {
						id
						name
						companyTaskId
						startYear
						startMonth
						startDay
						deadlineDay
						deadlineMonth
						deadlineYear
						statusColumnV2 {
							id
							category
						}
					}
					thisDependsOnTask {
						id
						name
						companyTaskId
						startYear
						startMonth
						startDay
						deadlineDay
						deadlineMonth
						deadlineYear
						statusColumnV2 {
							id
							category
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.thisDependsOnTaskId,
			edgeName: 'dependency',
			connectionInfo: [
				{
					key: 'Task_dependsOnThisTask',
					rangeBehavior: 'append',
				},
			],
		},
		{
			type: 'RANGE_ADD',
			parentID: input.taskIdDependsOnThis,
			edgeName: 'dependency',
			connectionInfo: [
				{
					key: 'Task_thisTaskDependsOn',
					rangeBehavior: 'append',
				},
			],
		},
	];
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Dependency Created', {_Type: input.type});
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				...input,
			},
		},
		configs: getConfigs(input),
		onCompleted,
		onError,
	});
}

export default {commit};
