/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteExpenseCategoryInput = {|
  id: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteExpenseCategoryMutationVariables = {|
  input: DeleteExpenseCategoryInput
|};
export type deleteExpenseCategoryMutationResponse = {|
  +deleteExpenseCategory: ?{|
    +deletedExpenseCategoryId: ?string
  |}
|};
export type deleteExpenseCategoryMutation = {|
  variables: deleteExpenseCategoryMutationVariables,
  response: deleteExpenseCategoryMutationResponse,
|};
*/


/*
mutation deleteExpenseCategoryMutation(
  $input: DeleteExpenseCategoryInput!
) {
  deleteExpenseCategory(input: $input) {
    deletedExpenseCategoryId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteExpenseCategoryInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteExpenseCategoryPayload",
    "kind": "LinkedField",
    "name": "deleteExpenseCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedExpenseCategoryId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteExpenseCategoryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteExpenseCategoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteExpenseCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation deleteExpenseCategoryMutation(\n  $input: DeleteExpenseCategoryInput!\n) {\n  deleteExpenseCategory(input: $input) {\n    deletedExpenseCategoryId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6712eb441bac6a1d59fb65c7a48fc4a7';

module.exports = node;
