/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChangeJiraStartDateFieldInput = {|
  fieldId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type ChangeJiraStartDateFieldMutationVariables = {|
  input: ChangeJiraStartDateFieldInput
|};
export type ChangeJiraStartDateFieldMutationResponse = {|
  +changeJiraStartDateField: ?{|
    +company: ?{|
      +integrations: ?{|
        +jiraCloud: ?{|
          +startDateField: ?{|
            +id: ?string,
            +name: ?string,
          |}
        |}
      |}
    |}
  |}
|};
export type ChangeJiraStartDateFieldMutation = {|
  variables: ChangeJiraStartDateFieldMutationVariables,
  response: ChangeJiraStartDateFieldMutationResponse,
|};
*/


/*
mutation ChangeJiraStartDateFieldMutation(
  $input: ChangeJiraStartDateFieldInput!
) {
  changeJiraStartDateField(input: $input) {
    company {
      integrations {
        jiraCloud {
          startDateField {
            id
            name
          }
          id
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ChangeJiraStartDateFieldInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "JiraStartDateFieldType",
  "kind": "LinkedField",
  "name": "startDateField",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeJiraStartDateFieldMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangeJiraStartDateFieldPayload",
        "kind": "LinkedField",
        "name": "changeJiraStartDateField",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyIntegrationsType",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyJiraSettingsType",
                    "kind": "LinkedField",
                    "name": "jiraCloud",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeJiraStartDateFieldMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangeJiraStartDateFieldPayload",
        "kind": "LinkedField",
        "name": "changeJiraStartDateField",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyIntegrationsType",
                "kind": "LinkedField",
                "name": "integrations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyJiraSettingsType",
                    "kind": "LinkedField",
                    "name": "jiraCloud",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ChangeJiraStartDateFieldMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeJiraStartDateFieldMutation(\n  $input: ChangeJiraStartDateFieldInput!\n) {\n  changeJiraStartDateField(input: $input) {\n    company {\n      integrations {\n        jiraCloud {\n          startDateField {\n            id\n            name\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '36e2ce5148a915a831ef35bd1597b964';

module.exports = node;
