import {graphql} from 'react-relay';
import {omit} from 'lodash';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateTimeLockMutation($input: UpdateTimeLockInput!) {
		updateTimeLock(input: $input) {
			company {
				id
				lockedPeriodYear
				lockedPeriodMonth
				lockedPeriodDay
				useTimeLocking
			}
			person {
				id
				excludeFromCompanyLockedPeriod
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	let person, company;
	company = {id: input.companyId};
	if (input.lockedPeriodYear !== undefined && input.lockedPeriodMonth !== undefined && input.lockedPeriodDay !== undefined) {
		company.lockedPeriodYear = input.lockedPeriodYear;
		company.lockedPeriodMonth = input.lockedPeriodMonth;
		company.lockedPeriodDay = input.lockedPeriodDay;
	}
	if (input.personId && input.excludeFromCompanyLockedPeriod !== undefined) {
		person = {id: input.personId, excludeFromCompanyLockedPeriod: input.excludeFromCompanyLockedPeriod};
	}

	return {updateTimeLock: {company, person}};
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}
		changes.push(key);
	}

	tracking.trackEvent('Company Updated', {_Changed: changes});

	const variables = {...input};
	// ID is needed for updateCompanyMutation's optimistic response, but should not be included in the variables
	const variablesNoID = omit(variables, ['id']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...variablesNoID}},
		uploadables,
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
