import { commitMutation, graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
const mutation = graphql `
	mutation UpdateDeliverableMutation($input: UpdateDeliverableInput!) {
		updateDeliverable(input: $input) {
			deliverable {
				node {
					id
					name
					description
				}
			}
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    trackEvent('Deliverable', 'Updated', input);
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return commitMutation(environment, {
        mutation,
        variables,
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
