/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsHolidayCalendars_holidayCalendarEntry$ref = any;
export type UpdateHolidayCalendarEntryInput = {|
  id: string,
  name?: ?string,
  year?: ?number,
  month?: ?number,
  day?: ?number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateHolidayCalendarEntryMutationVariables = {|
  input: UpdateHolidayCalendarEntryInput
|};
export type updateHolidayCalendarEntryMutationResponse = {|
  +updateHolidayCalendarEntry: ?{|
    +holidayCalendarEntry: ?{|
      +$fragmentRefs: settingsHolidayCalendars_holidayCalendarEntry$ref
    |}
  |}
|};
export type updateHolidayCalendarEntryMutation = {|
  variables: updateHolidayCalendarEntryMutationVariables,
  response: updateHolidayCalendarEntryMutationResponse,
|};
*/


/*
mutation updateHolidayCalendarEntryMutation(
  $input: UpdateHolidayCalendarEntryInput!
) {
  updateHolidayCalendarEntry(input: $input) {
    holidayCalendarEntry {
      ...settingsHolidayCalendars_holidayCalendarEntry
      id
    }
  }
}

fragment settingsHolidayCalendars_holidayCalendarEntry on HolidayCalendarEntry {
  id
  name
  year
  month
  day
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateHolidayCalendarEntryInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateHolidayCalendarEntryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateHolidayCalendarEntryPayload",
        "kind": "LinkedField",
        "name": "updateHolidayCalendarEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HolidayCalendarEntry",
            "kind": "LinkedField",
            "name": "holidayCalendarEntry",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "settingsHolidayCalendars_holidayCalendarEntry"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateHolidayCalendarEntryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateHolidayCalendarEntryPayload",
        "kind": "LinkedField",
        "name": "updateHolidayCalendarEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HolidayCalendarEntry",
            "kind": "LinkedField",
            "name": "holidayCalendarEntry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "year",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "month",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "day",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateHolidayCalendarEntryMutation",
    "operationKind": "mutation",
    "text": "mutation updateHolidayCalendarEntryMutation(\n  $input: UpdateHolidayCalendarEntryInput!\n) {\n  updateHolidayCalendarEntry(input: $input) {\n    holidayCalendarEntry {\n      ...settingsHolidayCalendars_holidayCalendarEntry\n      id\n    }\n  }\n}\n\nfragment settingsHolidayCalendars_holidayCalendarEntry on HolidayCalendarEntry {\n  id\n  name\n  year\n  month\n  day\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '22d8184b21bb3b9d6980a5fdbd8d80d4';

module.exports = node;
