import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createCommentMutation($input: CreateCommentInput!) {
		createComment(input: $input) {
			task {
				commentCount
				latestUiUpdateAt
				latestUiUpdateBy {
					id
					firstName
					lastName
				}
			}
			comment {
				node {
					id
					comment
					year
					month
					day
					hours
					minutes
					seconds
					person {
						id
						firstName
						lastName
						fullName
						profilePictureId
						profilePictureDefaultId
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.taskId,
			connectionInfo: [
				{
					key: 'Task_comments',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'comment',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		createComment: {
			task: {
				id: input.taskId,
			},
			comment: {
				node: {
					comment: input.comment,
					person: input.person,
					year: input.currentDate.get('year'),
					month: input.currentDate.get('month') + 1,
					day: input.currentDate.get('date'),
					hours: input.currentDate.get('hour'),
					minutes: input.currentDate.get('minute') + 1,
					seconds: input.currentDate.get('second'),
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Comment Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				comment: input.comment,
				taskId: input.taskId,
			},
		},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
