import Util from '../../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';
import RelayEnvironment from '../../RelayEnvironment';

const mutation = graphql`
	mutation enableModuleMutation($input: EnableModuleInput!) {
		enableModule(input: $input) {
			module {
				node {
					id
					companyId
					moduleType
					enabledByTier
					startYear
					startMonth
					startDay
					endYear
					endMonth
					endDay
				}
			}
		}
	}
`;

function getConfigs(module) {
	if (module.id) {
		return [];
	}
	return [
		{
			type: 'RANGE_ADD',
			parentID: module.companyId,
			connectionInfo: [
				{
					key: 'companiesWithModules_modules',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'module',
		},
	];
}

function commit(environment, module, onSuccess, onError) {
	const variables = {
		input: {
			csrfToken: Util.getCsrfValue(),
			socketClientId: Util.getClientId(),
			datacenter: module.datacenter,
			moduleType: module.moduleType,
			companyId: module.companyId,
			startYear: module.startYear,
			startMonth: module.startMonth,
			startDay: module.startDay,
			endYear: module.endYear,
			endMonth: module.endMonth,
			endDay: module.endDay,
		},
	};
	return commitMutation(RelayEnvironment.getInstanceMC(), {
		mutation,
		variables,
		configs: getConfigs(module),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
