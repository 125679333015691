import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation favoriteIdleTimeMutation($input: UpdateIdleTimeInput!) {
		updateIdleTime(input: $input) {
			idleTime {
				id
				favoured
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const idleTime = {id: input.id, favoured: input.favoured};
	return {
		updateIdleTime: {
			idleTime: idleTime,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Idle time Favorited');

	const variables = {
		input: {
			csrfToken: Util.getCsrfValue(),
			socketClientId: Util.getClientId(),
			...input,
		},
	};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
