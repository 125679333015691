/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
export type UpdateStatusColumnInput = {|
  id?: ?string,
  name?: ?string,
  category?: ?STATUS_CATEGORY,
  order?: ?number,
  autoShowOnTimePage?: ?boolean,
  encourageTimeRegistration?: ?boolean,
  fixOrdersUpdate?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateStatusColumnMutationVariables = {|
  input: UpdateStatusColumnInput
|};
export type updateStatusColumnMutationResponse = {|
  +updateStatusColumn: ?{|
    +errors: ?$ReadOnlyArray<?string>,
    +statusColumnV2: ?{|
      +id: string,
      +name: ?string,
      +category: ?STATUS_CATEGORY,
      +autoShowOnTimePage: ?boolean,
      +encourageTimeRegistration: ?boolean,
      +order: ?number,
    |},
    +statusColumnsV2: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +order: ?number,
        |}
      |}>
    |},
    +projects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +statusColumnsV2: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +order: ?number,
              |}
            |}>
          |}
        |}
      |}>
    |},
  |}
|};
export type updateStatusColumnMutation = {|
  variables: updateStatusColumnMutationVariables,
  response: updateStatusColumnMutationResponse,
|};
*/


/*
mutation updateStatusColumnMutation(
  $input: UpdateStatusColumnInput!
) {
  updateStatusColumn(input: $input) {
    errors
    statusColumnV2 {
      id
      name
      category
      autoShowOnTimePage
      encourageTimeRegistration
      order
    }
    statusColumnsV2(first: 1000000) {
      edges {
        node {
          id
          order
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    projects(first: 1000000) {
      edges {
        node {
          statusColumnsV2(first: 1000000) {
            edges {
              node {
                id
                order
                __typename
              }
              cursor
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
          id
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateStatusColumnInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "StatusColumnV2",
  "kind": "LinkedField",
  "name": "statusColumnV2",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoShowOnTimePage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "encourageTimeRegistration",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "StatusColumnV2TypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StatusColumnV2",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "storageKey": null
  },
  (v8/*: any*/)
],
v10 = {
  "alias": "statusColumnsV2",
  "args": null,
  "concreteType": "StatusColumnV2TypeConnection",
  "kind": "LinkedField",
  "name": "__Project_statusColumnsV2_connection",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
],
v12 = {
  "alias": null,
  "args": (v11/*: any*/),
  "concreteType": "StatusColumnV2TypeConnection",
  "kind": "LinkedField",
  "name": "statusColumnsV2",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": "statusColumnsV2(first:1000000)"
},
v13 = {
  "alias": null,
  "args": (v11/*: any*/),
  "filters": ([]/*: any*/),
  "handle": "connection",
  "key": "Project_statusColumnsV2",
  "kind": "LinkedHandle",
  "name": "statusColumnsV2"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateStatusColumnMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStatusColumnPayload",
        "kind": "LinkedField",
        "name": "updateStatusColumn",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          (v10/*: any*/),
          {
            "alias": "projects",
            "args": null,
            "concreteType": "ProjectTypeConnection",
            "kind": "LinkedField",
            "name": "__ProjectGroup_projects_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateStatusColumnMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateStatusColumnPayload",
        "kind": "LinkedField",
        "name": "updateStatusColumn",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "ProjectTypeConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": "projects(first:1000000)"
          },
          {
            "alias": null,
            "args": (v11/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "ProjectGroup_projects",
            "kind": "LinkedHandle",
            "name": "projects"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "updateStatusColumn",
            "statusColumnsV2"
          ]
        },
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": null
        },
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "updateStatusColumn",
            "projects"
          ]
        }
      ]
    },
    "name": "updateStatusColumnMutation",
    "operationKind": "mutation",
    "text": "mutation updateStatusColumnMutation(\n  $input: UpdateStatusColumnInput!\n) {\n  updateStatusColumn(input: $input) {\n    errors\n    statusColumnV2 {\n      id\n      name\n      category\n      autoShowOnTimePage\n      encourageTimeRegistration\n      order\n    }\n    statusColumnsV2(first: 1000000) {\n      edges {\n        node {\n          id\n          order\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    projects(first: 1000000) {\n      edges {\n        node {\n          statusColumnsV2(first: 1000000) {\n            edges {\n              node {\n                id\n                order\n                __typename\n              }\n              cursor\n            }\n            pageInfo {\n              endCursor\n              hasNextPage\n            }\n          }\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8de5bc647dab61c0e561a3beeebe782c';

module.exports = node;
