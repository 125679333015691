/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type labelCategoryRow_labels$ref = any;
export type UpdateLabelInput = {|
  id: string,
  name?: ?string,
  color?: ?string,
  categoryId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateLabelMutationVariables = {|
  input: UpdateLabelInput
|};
export type updateLabelMutationResponse = {|
  +updateLabel: ?{|
    +label: ?{|
      +id: string,
      +name: ?string,
      +taskCount: ?number,
      +projectCount: ?number,
      +peopleCount: ?number,
      +category: ?{|
        +id: string,
        +allowOnTasks: ?boolean,
        +allowOnProjects: ?boolean,
        +allowOnPeople: ?boolean,
      |},
      +$fragmentRefs: labelCategoryRow_labels$ref,
    |}
  |}
|};
export type updateLabelMutation = {|
  variables: updateLabelMutationVariables,
  response: updateLabelMutationResponse,
|};
*/


/*
mutation updateLabelMutation(
  $input: UpdateLabelInput!
) {
  updateLabel(input: $input) {
    label {
      id
      name
      taskCount
      projectCount
      peopleCount
      category {
        id
        allowOnTasks
        allowOnProjects
        allowOnPeople
      }
      ...labelCategoryRow_labels
    }
  }
}

fragment labelCategoryRow_labels on Label {
  id
  name
  taskCount
  projectCount
  peopleCount
  ...labelRow_label
}

fragment labelRow_label on Label {
  id
  name
  color
  taskCount
  projectCount
  peopleCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateLabelInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taskCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "peopleCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "LabelCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowOnTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowOnProjects",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowOnPeople",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateLabelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateLabelPayload",
        "kind": "LinkedField",
        "name": "updateLabel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "label",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "labelCategoryRow_labels"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateLabelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateLabelPayload",
        "kind": "LinkedField",
        "name": "updateLabel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "label",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateLabelMutation",
    "operationKind": "mutation",
    "text": "mutation updateLabelMutation(\n  $input: UpdateLabelInput!\n) {\n  updateLabel(input: $input) {\n    label {\n      id\n      name\n      taskCount\n      projectCount\n      peopleCount\n      category {\n        id\n        allowOnTasks\n        allowOnProjects\n        allowOnPeople\n      }\n      ...labelCategoryRow_labels\n    }\n  }\n}\n\nfragment labelCategoryRow_labels on Label {\n  id\n  name\n  taskCount\n  projectCount\n  peopleCount\n  ...labelRow_label\n}\n\nfragment labelRow_label on Label {\n  id\n  name\n  color\n  taskCount\n  projectCount\n  peopleCount\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd37cc7ff240757e4fca6e95d2e66d316';

module.exports = node;
