/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateGoogleDriveProjectFileAttachmentInput = {|
  projectId: string,
  personId: string,
  googleDriveId?: ?string,
  name?: ?string,
  link?: ?string,
  thumb?: ?string,
  folderId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createGoogleDriveProjectFileAttachmentMutationVariables = {|
  input: CreateGoogleDriveProjectFileAttachmentInput
|};
export type createGoogleDriveProjectFileAttachmentMutationResponse = {|
  +createGoogleDriveProjectFileAttachment: ?{|
    +googleDriveFile: ?{|
      +node: ?{|
        +id: ?string,
        +name: ?string,
        +link: ?string,
        +thumb: ?string,
        +createdAt: ?string,
        +person: ?{|
          +id: string,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
          +firstName: ?string,
          +lastName: ?string,
          +role: ?{|
            +name: ?string
          |},
        |},
        +folder: ?{|
          +id: string,
          +name: ?string,
        |},
      |}
    |}
  |}
|};
export type createGoogleDriveProjectFileAttachmentMutation = {|
  variables: createGoogleDriveProjectFileAttachmentMutationVariables,
  response: createGoogleDriveProjectFileAttachmentMutationResponse,
|};
*/


/*
mutation createGoogleDriveProjectFileAttachmentMutation(
  $input: CreateGoogleDriveProjectFileAttachmentInput!
) {
  createGoogleDriveProjectFileAttachment(input: $input) {
    googleDriveFile {
      node {
        id
        name
        link
        thumb
        createdAt
        person {
          id
          profilePictureId
          profilePictureDefaultId
          firstName
          lastName
          role {
            name
            id
          }
        }
        folder {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateGoogleDriveProjectFileAttachmentInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumb",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Folder",
  "kind": "LinkedField",
  "name": "folder",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createGoogleDriveProjectFileAttachmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateGoogleDriveProjectFileAttachmentPayload",
        "kind": "LinkedField",
        "name": "createGoogleDriveProjectFileAttachment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GoogleDriveFileTypeEdge",
            "kind": "LinkedField",
            "name": "googleDriveFile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GoogleDriveFile",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "role",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createGoogleDriveProjectFileAttachmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateGoogleDriveProjectFileAttachmentPayload",
        "kind": "LinkedField",
        "name": "createGoogleDriveProjectFileAttachment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GoogleDriveFileTypeEdge",
            "kind": "LinkedField",
            "name": "googleDriveFile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GoogleDriveFile",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "role",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createGoogleDriveProjectFileAttachmentMutation",
    "operationKind": "mutation",
    "text": "mutation createGoogleDriveProjectFileAttachmentMutation(\n  $input: CreateGoogleDriveProjectFileAttachmentInput!\n) {\n  createGoogleDriveProjectFileAttachment(input: $input) {\n    googleDriveFile {\n      node {\n        id\n        name\n        link\n        thumb\n        createdAt\n        person {\n          id\n          profilePictureId\n          profilePictureDefaultId\n          firstName\n          lastName\n          role {\n            name\n            id\n          }\n        }\n        folder {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '64d407617668d49f7389bf573d151053';

module.exports = node;
