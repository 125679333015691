/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateFolderInput = {|
  projectId?: ?string,
  name?: ?string,
  folderId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createFolderMutationVariables = {|
  input: CreateFolderInput
|};
export type createFolderMutationResponse = {|
  +createFolder: ?{|
    +folder: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +yearCreated: ?number,
        +monthCreated: ?number,
        +dayCreated: ?number,
        +hourCreated: ?number,
        +minuteCreated: ?number,
        +secondCreated: ?number,
        +person: ?{|
          +id: string,
          +profilePictureId: ?string,
          +firstName: ?string,
          +lastName: ?string,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
        |},
        +project: ?{|
          +id: string
        |},
        +folder: ?{|
          +id: string,
          +name: ?string,
        |},
      |}
    |}
  |}
|};
export type createFolderMutation = {|
  variables: createFolderMutationVariables,
  response: createFolderMutationResponse,
|};
*/


/*
mutation createFolderMutation(
  $input: CreateFolderInput!
) {
  createFolder(input: $input) {
    folder {
      node {
        id
        name
        yearCreated
        monthCreated
        dayCreated
        hourCreated
        minuteCreated
        secondCreated
        person {
          id
          profilePictureId
          firstName
          lastName
          role {
            id
            name
          }
        }
        project {
          id
        }
        folder {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateFolderInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateFolderPayload",
    "kind": "LinkedField",
    "name": "createFolder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FolderTypeEdge",
        "kind": "LinkedField",
        "name": "folder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Folder",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "yearCreated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "monthCreated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dayCreated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hourCreated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minuteCreated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondCreated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilePictureId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Folder",
                "kind": "LinkedField",
                "name": "folder",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createFolderMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createFolderMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createFolderMutation",
    "operationKind": "mutation",
    "text": "mutation createFolderMutation(\n  $input: CreateFolderInput!\n) {\n  createFolder(input: $input) {\n    folder {\n      node {\n        id\n        name\n        yearCreated\n        monthCreated\n        dayCreated\n        hourCreated\n        minuteCreated\n        secondCreated\n        person {\n          id\n          profilePictureId\n          firstName\n          lastName\n          role {\n            id\n            name\n          }\n        }\n        project {\n          id\n        }\n        folder {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dc15d323ed86f2128032f6fc4d71d250';

module.exports = node;
