import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createInsightShareMutation($input: CreateInsightShareInput!) {
		createInsightShare(input: $input) {
			insightShare {
				node {
					id
					uuid
					password
					project {
						id
						sprints(first: 1000) {
							edges {
								node {
									id
									name
								}
							}
						}
					}
					person {
						id
					}
					client {
						id
					}
					projectGroup {
						id
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.insightId,
			connectionInfo: [
				{
					key: 'Insight_shares',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'insightShare',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	let type = 'Portfolio';
	if (input.personId) {
		type = 'People';
	} else if (input.clientId) {
		type = 'Business';
	} else if (input.projectId || input.projectGroupId) {
		type = 'Projects';
	}
	tracking.trackEvent('Insight Shared', {_Type: type});

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
