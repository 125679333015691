import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation CreatePhaseBaselineMutation($input: CreatePhaseInput!) {
		createPhase(input: $input) {
			phase {
				node {
					id
				}
			}
			project {
				id
				phases(first: 100000) @connection(key: "Project_phases", filters: []) {
					edges {
						...PhasesSection_phases
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Phase Created');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
