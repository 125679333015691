/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation UpdateTimeRegistrationGranularityMutation(
  $input: UpdateTimeRegistrationGranularityInput!
) {
  updateTimeRegistrationGranularity(input: $input) {
    timeRegistrationSettings {
      granularity
      allowDoneTasks
      allowProjects
      allowInternalTime
      allowBillableSplit
      allowRoles
      requireTaskAssignment
      requireNote
      id
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "UpdateTimeRegistrationGranularityInput!"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "granularity",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "allowDoneTasks",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "allowProjects",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "allowInternalTime",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "allowBillableSplit",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "allowRoles",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requireTaskAssignment",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requireNote",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateTimeRegistrationGranularityMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateTimeRegistrationGranularityPayload",
                    "kind": "LinkedField",
                    "name": "updateTimeRegistrationGranularity",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TimeRegistrationSettingsType",
                            "kind": "LinkedField",
                            "name": "timeRegistrationSettings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateTimeRegistrationGranularityMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateTimeRegistrationGranularityPayload",
                    "kind": "LinkedField",
                    "name": "updateTimeRegistrationGranularity",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TimeRegistrationSettingsType",
                            "kind": "LinkedField",
                            "name": "timeRegistrationSettings",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "UpdateTimeRegistrationGranularityMutation",
            "operationKind": "mutation",
            "text": "mutation UpdateTimeRegistrationGranularityMutation(\n  $input: UpdateTimeRegistrationGranularityInput!\n) {\n  updateTimeRegistrationGranularity(input: $input) {\n    timeRegistrationSettings {\n      granularity\n      allowDoneTasks\n      allowProjects\n      allowInternalTime\n      allowBillableSplit\n      allowRoles\n      requireTaskAssignment\n      requireNote\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = 'd9513c77a7d26287c6b8674773cf2766';
export default node;
