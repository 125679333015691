import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
/*
	DO NOT CALL THIS WITHOUT CHECKING THE BLACKLIST.
	THERE IS A UTIL METHOD WHICH CAN DO IT FOR YOU.
*/

const mutation = graphql`
	mutation createFileMutation($input: CreateFileInput!) {
		createFile(input: $input) {
			task {
				fileCount
			}
			file {
				node {
					id
					name
					mimeType
					key
					size
					yearCreated
					monthCreated
					dayCreated
					hourCreated
					minuteCreated
					secondCreated
					person {
						id
						fullName
					}
					folder {
						id
						name
					}
				}
			}
			error
			filename
		}
	}
`;

function getConfigs(input) {
	const config = [];
	if (input.taskId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.taskId,
			connectionInfo: [
				{
					key: 'Task_files',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'file',
		});
	}
	if (input.projectId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_files',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'file',
		});
	}
	if (input.expenseItemId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.expenseItemId,
			connectionInfo: [
				{
					key: 'ExpenseItem_files',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'file',
		});
	}
	return config;
}

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('File Uploaded', {
		_MimeType: input.mimeType,
		_OnExpenseItem: !!input.expenseItemId,
		_InFolder: !!input.folderId,
		_OnTask: !!input.taskId,
	});
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		uploadables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
