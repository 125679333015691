import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateSkillMutation($input: UpdateSkillInput!) {
		updateSkill(input: $input) {
			skill {
				node {
					id
					name
					category {
						id
					}
					skillPersons {
						person {
							id
							role {
								id
							}
							department {
								id
							}
							active
							permissions
						}
						level {
							id
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function getOptimisticResponse(input) {
	return {
		updateSkill: {
			skill: {
				node: {
					id: input.id,
					name: input.name,
					category: {
						id: input.categoryId,
					},
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Skill Updated');

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(cleanedInput),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
