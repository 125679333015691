import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updatePlaceholderAllocationMutation($input: UpdatePlaceholderAllocationInput!) {
		updatePlaceholderAllocation(input: $input) {
			placeholderAllocation {
				node {
					id
					startDate
					endDate
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					description
					placeholder {
						id
					}
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	return {
		updatePlaceholderAllocation: {
			placeholderAllocation: {
				node: {
					id: input.id,
					startDate: input.startDate,
					endDate: input.endDate,
					monday: input.monday,
					tuesday: input.tuesday,
					wednesday: input.wednesday,
					thursday: input.thursday,
					friday: input.friday,
					saturday: input.saturday,
					sunday: input.sunday,
					description: input.description,
					placeholder: {
						id: input.placeholderId,
					},
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Placeholder allocation Updated');

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		optimisticResponse: getOptimisticResponse(cleanedInput),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit, getOptimisticResponse};
