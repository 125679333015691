import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createFolderMutation($input: CreateFolderInput!) {
		createFolder(input: $input) {
			folder {
				node {
					id
					name
					yearCreated
					monthCreated
					dayCreated
					hourCreated
					minuteCreated
					secondCreated
					person {
						id
						profilePictureId
						firstName
						lastName
						role {
							id
							name
						}
					}
					project {
						id
					}
					folder {
						id
						name
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_folders',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'folder',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Folder Created', {_SubFolder: !!input.folderId});
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
