import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateCommentMutation($input: UpdateCommentInput!) {
		updateComment(input: $input) {
			comment {
				id
				comment
				year
				month
				day
				hours
				minutes
				seconds
			}
		}
	}
`;

function getOptimisticResponse(input) {
	return {
		updateComment: {
			comment: {
				id: input.id,
				comment: input.comment,
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Comment Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
