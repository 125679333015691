/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateHubspotVerificationKeyInput = {|
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type CreateHubspotVerificationKeyMutationVariables = {|
  input: CreateHubspotVerificationKeyInput
|};
export type CreateHubspotVerificationKeyMutationResponse = {|
  +createHubspotVerificationKey: ?{|
    +key: ?string
  |}
|};
export type CreateHubspotVerificationKeyMutation = {|
  variables: CreateHubspotVerificationKeyMutationVariables,
  response: CreateHubspotVerificationKeyMutationResponse,
|};
*/


/*
mutation CreateHubspotVerificationKeyMutation(
  $input: CreateHubspotVerificationKeyInput!
) {
  createHubspotVerificationKey(input: $input) {
    key
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateHubspotVerificationKeyInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateHubspotVerificationKeyPayload",
    "kind": "LinkedField",
    "name": "createHubspotVerificationKey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateHubspotVerificationKeyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateHubspotVerificationKeyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateHubspotVerificationKeyMutation",
    "operationKind": "mutation",
    "text": "mutation CreateHubspotVerificationKeyMutation(\n  $input: CreateHubspotVerificationKeyInput!\n) {\n  createHubspotVerificationKey(input: $input) {\n    key\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4de9c428f561e0f17f8035157b3cd5ff';

module.exports = node;
