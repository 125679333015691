import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';
import {createRangeBulkAddUpdater} from '../relay-helpers/RelayCacheHelpers';

const mutation = graphql`
	mutation ImportSageIntacctCustomersMutation($input: ImportSageIntacctCustomersInput!) {
		importSageIntacctCustomers(input: $input) {
			clients {
				node {
					id
					name
					notes
					logoId
					logoDefaultId
					projectCount
					economicCustomerId
					quickbooksCustomerId
					xeroContactId
					salesforceAccountId
					hubspotCompanyId
					pipedriveOrganizationId
					sageIntacctCustomerId
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(environment, {
		mutation,
		variables,
		updater: createRangeBulkAddUpdater(input.companyId, 'importSageIntacctCustomers', 'clients', 'Company_clients'),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
