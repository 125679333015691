import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateProjectGroupProjectsMutation($input: UpdateProjectInput!) {
		updateProject(input: $input) {
			errors
			project {
				...ProjectSettingsPage_project
			}
			projectGroup {
				projects(first: 100000) @connection(key: "ProjectGroup_projects", filters: []) {
					edges {
						node {
							id
						}
					}
				}
			}
			previousProjectGroup {
				projects(first: 100000) @connection(key: "ProjectGroup_projects", filters: []) {
					edges {
						node {
							id
						}
					}
				}
			}
			viewer {
				projectGroups(first: 100000) @connection(key: "Viewer_projectGroups") {
					edges {
						node {
							id
						}
					}
				}
				headerProjectGroups: projectGroups(first: 100000) @connection(key: "Viewer_headerProjectGroups") {
					edges {
						node {
							id
						}
					}
				}
			}
		}
	}
`;

/*function getConfigs(input) {
	if (input.projectGroupId) {
		return [
			{
				type: 'RANGE_ADD',
				parentID: input.projectGroupId,
				connectionInfo: [
					{
						key: 'ProjectGroup_projects',
						rangeBehavior: 'append'
					}
				],
				edgeName: 'project'
			}
		];
	}
}*/

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	let status;

	for (const key of Object.keys(input)) {
		if (['id', 'project'].includes(key) || input[key] === undefined) {
			continue;
		}

		if (key === 'status') {
			status = input.status;
		}

		changes.push(key);
	}

	const options = {_Changed: changes};
	if (status) {
		options.Status = status;
	}
	tracking.trackEvent('Project Updated', options);

	const cleanedVariables = omit(input, ['project']);
	cleanedVariables.id = input.project.id;

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		//configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
