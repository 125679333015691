import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../css_variables';

export const ProjectPhaseModalStyled = styled.div`
	min-width: 500px;
	color: ${CSS_CONSTANTS.schedule_text_dark_grey};
`;

export const TopSection = styled.div`
	padding: 15px 0;
	margin-bottom: 10px;
	border-bottom: 1px solid ${CSS_CONSTANTS.schedule_border_color_primary};
	display: flex;
	flex-direction: column;
`;

export const InformationAndBadge = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
`;

export const ProjectIdAndName = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

export const Information = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
`;

export const DatesAndMetaData = styled.div`
	display: flex;
	align-items: center;
	margin-top: 12px;
	font-size: 12px;
	font-weight: 400;
`;

export const ProjectNameAndId = styled.div`
	display: flex;
`;

export const ProjectId = styled.div`
	font-weight: 600;
	font-size: 15px;
	margin-right: 4px;
`;

export const ProjectName = styled.div`
	font-weight: 400;
	font-size: 15px;
`;

export const ProjectInfo = styled.div`
	margin-top: 5px;
	margin-left: 56px; /* align with the name */
	font-size: 12px;
	display: flex;
	align-items: center;
`;

export const ProjectInfoSeparator = styled.div`
	height: 12px;
	width: 2px;
	background-color: ${CSS_CONSTANTS.v2_button_very_light_grey};
	margin: 0 10px;
`;

export const Table = styled.table`
	margin-top: 20px;
	width: 100%;
`;

export const HeaderTableRow = styled.tr``;

export const TdHead = styled.td`
	font-size: 11px;
	font-weight: 400;
	color: ${CSS_CONSTANTS.schedule_text_grey};
	padding: 10px;
	border-bottom: 1px solid ${CSS_CONSTANTS.schedule_border_color_primary};
`;

export const TdHeadContainer = styled.span`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${props => (props.isRole ? 'flex-start' : 'flex-end')};
`;

export const TrBody = styled.tr`
	&:first-child {
		padding-top: 20px;
	}
`;

export const TdBody = styled.td`
	display: ${props => (props.isNameColumn ? 'flex' : 'table-cell')};
	font-size: 12px;
	text-align: ${props => (props.isNameColumn ? 'left' : 'right')};
	font-weight: ${props => (props.isNameColumn ? '400' : '100')};
	align-items: center;
	padding: 5px 10px;
`;

export const UtilizationIndicator = styled.div`
	height: 12px;
	width: 12px;
	background-color: ${props => (props.isNegative ? CSS_CONSTANTS.schedule_v2_red : CSS_CONSTANTS.schedule_v2_green)};
	margin-right: 15px;
	border-radius: 4px;
`;

export const EmptyState = styled.div`
	margin-top: 20px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Warning = styled.div`
	margin-top: 20px;
	font-size: 14px;
	font-weight: 500;
`;

export const Instruction = styled.div`
	margin-top: 5px;
	font-size: 12px;
`;
