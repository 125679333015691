import {commitMutation, graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';

const mutation = graphql`
	mutation TransferReportOwnershipMutation($input: TransferReportOwnershipInput!) {
		transferReportOwnership(input: $input) {
			savedReport {
				person {
					id
					active
					email
					fullName
					profilePictureId
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	return {
		transferReportOwnership: {
			savedReport: {
				id: input.reportId,
				person: {
					id: input.newOwnerId,
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	trackEvent('Standard Report', 'Ownership Transferred');

	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
