import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation ChangeJiraStartDateFieldMutation($input: ChangeJiraStartDateFieldInput!) {
		changeJiraStartDateField(input: $input) {
			company {
				integrations {
					jiraCloud {
						startDateField {
							id
							name
						}
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
