import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deletePlaceholderAllocationMutation($input: DeletePlaceholderAllocationInput!) {
		deletePlaceholderAllocation(input: $input) {
			deletedAllocationId
		}
	}
`;

function getOptimisticResponse(input) {
	return {
		deletePlaceholderAllocation: {
			deletedAllocationId: input.id,
		},
	};
}

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.placeholderId,
			connectionKeys: [
				{
					key: 'Project_placeholderAllocations',
				},
			],
			pathToConnection: ['project', 'placeholders', 'placeholderAllocations'],
			deletedIDFieldName: 'deletedAllocationId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Placeholder Allocation Deleted');

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit, getOptimisticResponse};
