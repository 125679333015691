/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsDepartments_department$ref = any;
export type CreateDepartmentInput = {|
  companyId?: ?string,
  name?: ?string,
  persons?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createDepartmentMutationVariables = {|
  input: CreateDepartmentInput
|};
export type createDepartmentMutationResponse = {|
  +createDepartment: ?{|
    +department: ?{|
      +node: ?{|
        +$fragmentRefs: settingsDepartments_department$ref
      |}
    |},
    +company: ?{|
      +allPersons: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +department: ?{|
              +id: string
            |},
          |}
        |}>
      |}
    |},
  |}
|};
export type createDepartmentMutation = {|
  variables: createDepartmentMutationVariables,
  response: createDepartmentMutationResponse,
|};
*/


/*
mutation createDepartmentMutation(
  $input: CreateDepartmentInput!
) {
  createDepartment(input: $input) {
    department {
      node {
        ...settingsDepartments_department
        id
      }
    }
    company {
      allPersons(first: 10000, excludeClientUsers: true) {
        edges {
          node {
            id
            department {
              id
            }
          }
        }
      }
      id
    }
  }
}

fragment settingsDepartments_department on DepartmentType {
  id
  name
  sageIntacctId
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateDepartmentInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "excludeClientUsers",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000
    }
  ],
  "concreteType": "PersonTypeConnection",
  "kind": "LinkedField",
  "name": "allPersons",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "DepartmentType",
              "kind": "LinkedField",
              "name": "department",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createDepartmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDepartmentPayload",
        "kind": "LinkedField",
        "name": "createDepartment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DepartmentTypeEdge",
            "kind": "LinkedField",
            "name": "department",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DepartmentType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "settingsDepartments_department"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createDepartmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDepartmentPayload",
        "kind": "LinkedField",
        "name": "createDepartment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DepartmentTypeEdge",
            "kind": "LinkedField",
            "name": "department",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DepartmentType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sageIntacctId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createDepartmentMutation",
    "operationKind": "mutation",
    "text": "mutation createDepartmentMutation(\n  $input: CreateDepartmentInput!\n) {\n  createDepartment(input: $input) {\n    department {\n      node {\n        ...settingsDepartments_department\n        id\n      }\n    }\n    company {\n      allPersons(first: 10000, excludeClientUsers: true) {\n        edges {\n          node {\n            id\n            department {\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment settingsDepartments_department on DepartmentType {\n  id\n  name\n  sageIntacctId\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '80b8591efc8a84c53613c345438e3e9b';

module.exports = node;
