/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTaskInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  projectId?: ?string,
  projectGroupId?: ?string,
  parentId?: ?string,
  phaseId?: ?string,
  aboveTaskId?: ?string,
  aboveFavouredTaskId?: ?string,
  name?: ?string,
  description?: ?string,
  estimateLow?: ?number,
  estimateHigh?: ?number,
  forecast?: ?number,
  timeLeft?: ?number,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  deadlineYear?: ?number,
  deadlineMonth?: ?number,
  deadlineDay?: ?number,
  roleId?: ?string,
  assignedPersons?: ?$ReadOnlyArray<?string>,
  unassignAllPersons?: ?boolean,
  assignedPersonToAdd?: ?string,
  followers?: ?$ReadOnlyArray<?string>,
  removeAllFollowers?: ?boolean,
  labels?: ?$ReadOnlyArray<?string>,
  clearAllLabels?: ?boolean,
  sprintId?: ?string,
  projectGroupSprintId?: ?string,
  statusColumnId?: ?string,
  statusColumnV2Id?: ?string,
  projectGroupStatusColumnId?: ?number,
  coverFileId?: ?string,
  blocked?: ?boolean,
  bug?: ?boolean,
  approved?: ?boolean,
  billable?: ?boolean,
  repeatingTaskId?: ?string,
  unassignedTaskId?: ?string,
  previousProjectId?: ?string,
  unassignPersonFromTask?: ?$ReadOnlyArray<?UnassignPersonFromTask>,
  remaining?: ?number,
  highPriority?: ?boolean,
  ownerId?: ?string,
  favoured?: ?boolean,
  favouredSortOrder?: ?string,
  parentTaskId?: ?string,
  previousParentTaskId?: ?string,
  isBulkContext?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type UnassignPersonFromTask = {|
  taskId: string,
  personIds?: ?$ReadOnlyArray<?string>,
|};
export type UpdateTaskHierarchyMutationVariables = {|
  input: UpdateTaskInput
|};
export type UpdateTaskHierarchyMutationResponse = {|
  +updateTask: ?{|
    +errors: ?$ReadOnlyArray<?string>,
    +updatedTasksIds: ?$ReadOnlyArray<?string>,
    +taskEdge: ?{|
      +node: ?{|
        +id: string,
        +estimateForecast: ?number,
        +parentTask: ?{|
          +id: string,
          +rollupEstimate: ?number,
          +rollupTimeEntries: ?number,
          +rollupRemaining: ?number,
        |},
      |}
    |},
  |}
|};
export type UpdateTaskHierarchyMutation = {|
  variables: UpdateTaskHierarchyMutationVariables,
  response: UpdateTaskHierarchyMutationResponse,
|};
*/


/*
mutation UpdateTaskHierarchyMutation(
  $input: UpdateTaskInput!
) {
  updateTask(input: $input) {
    errors
    updatedTasksIds
    taskEdge {
      node {
        id
        estimateForecast
        parentTask {
          id
          rollupEstimate
          rollupTimeEntries
          rollupRemaining
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTaskInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTaskPayload",
    "kind": "LinkedField",
    "name": "updateTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedTasksIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskTypeEdge",
        "kind": "LinkedField",
        "name": "taskEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "estimateForecast",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Task",
                "kind": "LinkedField",
                "name": "parentTask",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rollupEstimate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rollupTimeEntries",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rollupRemaining",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTaskHierarchyMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateTaskHierarchyMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateTaskHierarchyMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateTaskHierarchyMutation(\n  $input: UpdateTaskInput!\n) {\n  updateTask(input: $input) {\n    errors\n    updatedTasksIds\n    taskEdge {\n      node {\n        id\n        estimateForecast\n        parentTask {\n          id\n          rollupEstimate\n          rollupTimeEntries\n          rollupRemaining\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f3b38832bb8283a9851c91434b80e0b8';

module.exports = node;
