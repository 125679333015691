/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateRepeatingTaskInput = {|
  id: string,
  taskId: string,
  repeatType?: ?string,
  monday?: ?boolean,
  tuesday?: ?boolean,
  wednesday?: ?boolean,
  thursday?: ?boolean,
  friday?: ?boolean,
  saturday?: ?boolean,
  sunday?: ?boolean,
  monthlyDay?: ?number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateRepeatingTaskMutationVariables = {|
  input: UpdateRepeatingTaskInput
|};
export type updateRepeatingTaskMutationResponse = {|
  +updateRepeatingTask: ?{|
    +task: ?{|
      +repeatingTask: ?{|
        +id: string,
        +repeatType: ?string,
        +monday: ?boolean,
        +tuesday: ?boolean,
        +wednesday: ?boolean,
        +thursday: ?boolean,
        +friday: ?boolean,
        +saturday: ?boolean,
        +sunday: ?boolean,
        +monthlyDay: ?number,
      |}
    |}
  |}
|};
export type updateRepeatingTaskMutation = {|
  variables: updateRepeatingTaskMutationVariables,
  response: updateRepeatingTaskMutationResponse,
|};
*/


/*
mutation updateRepeatingTaskMutation(
  $input: UpdateRepeatingTaskInput!
) {
  updateRepeatingTask(input: $input) {
    task {
      repeatingTask {
        id
        repeatType
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        monthlyDay
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateRepeatingTaskInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "RepeatingTask",
  "kind": "LinkedField",
  "name": "repeatingTask",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repeatType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tuesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wednesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thursday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "saturday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sunday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyDay",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateRepeatingTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRepeatingTaskPayload",
        "kind": "LinkedField",
        "name": "updateRepeatingTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateRepeatingTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRepeatingTaskPayload",
        "kind": "LinkedField",
        "name": "updateRepeatingTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateRepeatingTaskMutation",
    "operationKind": "mutation",
    "text": "mutation updateRepeatingTaskMutation(\n  $input: UpdateRepeatingTaskInput!\n) {\n  updateRepeatingTask(input: $input) {\n    task {\n      repeatingTask {\n        id\n        repeatType\n        monday\n        tuesday\n        wednesday\n        thursday\n        friday\n        saturday\n        sunday\n        monthlyDay\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '87c0db238c2383ba4287d240e686cdb5';

module.exports = node;
