import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteCostPeriodMutation($input: DeleteCostPeriodInput!) {
		deleteCostPeriod(input: $input) {
			deletedCostPeriodId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.personId,
			connectionKeys: [
				{
					key: 'Person_costPeriods',
				},
			],
			pathToConnection: ['person', 'costPeriods'],
			deletedIDFieldName: 'deletedCostPeriodId',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		deleteCostPeriod: {
			person: {
				id: input.personId,
			},
			deletedCostPeriodId: input.id,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Cost Period Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), id: input.id, personId: input.personId},
		},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
