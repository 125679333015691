import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateInsightMutation($input: UpdateInsightInput!) {
		updateInsight(input: $input) {
			insight {
				id
				name
				components {
					id
					componentName
					x
					y
					w
					h
					config
				}
				labels {
					id
					name
				}
				statusColumns {
					id
					category
					name
				}
				clients {
					id
					name
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Insight Updated', {
		_Category: input.category,
		_Components: input.components.map(c => c.componentName),
	});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
