/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsIdleTimes_idleTime$ref = any;
export type SetBambooHRIdleTimeInput = {|
  bambooHRTimeOffTypeId?: ?string,
  forecastIdleTimeId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type SetBambooHRIdleTimeMutationVariables = {|
  input: SetBambooHRIdleTimeInput
|};
export type SetBambooHRIdleTimeMutationResponse = {|
  +setBambooHRIdleTime: ?{|
    +idleTime: ?{|
      +bambooHRId: ?string,
      +$fragmentRefs: settingsIdleTimes_idleTime$ref,
    |}
  |}
|};
export type SetBambooHRIdleTimeMutation = {|
  variables: SetBambooHRIdleTimeMutationVariables,
  response: SetBambooHRIdleTimeMutationResponse,
|};
*/


/*
mutation SetBambooHRIdleTimeMutation(
  $input: SetBambooHRIdleTimeInput!
) {
  setBambooHRIdleTime(input: $input) {
    idleTime {
      ...settingsIdleTimes_idleTime
      bambooHRId
      id
    }
  }
}

fragment settingsIdleTimes_idleTime on IdleTime {
  id
  name
  isInternalTime
  disabled
  timeRegistrations(first: 1) {
    edges {
      node {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetBambooHRIdleTimeInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bambooHRId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetBambooHRIdleTimeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetBambooHRIdleTimePayload",
        "kind": "LinkedField",
        "name": "setBambooHRIdleTime",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IdleTime",
            "kind": "LinkedField",
            "name": "idleTime",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "settingsIdleTimes_idleTime"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetBambooHRIdleTimeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetBambooHRIdleTimePayload",
        "kind": "LinkedField",
        "name": "setBambooHRIdleTime",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IdleTime",
            "kind": "LinkedField",
            "name": "idleTime",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInternalTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "disabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  }
                ],
                "concreteType": "TimeRegTypeConnection",
                "kind": "LinkedField",
                "name": "timeRegistrations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TimeRegTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TimeRegType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "timeRegistrations(first:1)"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SetBambooHRIdleTimeMutation",
    "operationKind": "mutation",
    "text": "mutation SetBambooHRIdleTimeMutation(\n  $input: SetBambooHRIdleTimeInput!\n) {\n  setBambooHRIdleTime(input: $input) {\n    idleTime {\n      ...settingsIdleTimes_idleTime\n      bambooHRId\n      id\n    }\n  }\n}\n\nfragment settingsIdleTimes_idleTime on IdleTime {\n  id\n  name\n  isInternalTime\n  disabled\n  timeRegistrations(first: 1) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eae83147acdd67c3bff420ea74852050';

module.exports = node;
