import {commitMutation, graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';

const mutation = graphql`
	mutation createOrUpdatePriorityLevelsBulkMutationModernMutation($input: CreateOrUpdatePriorityLevelsBulkInput!) {
		createOrUpdatePriorityLevelsBulk(input: $input) {
			company {
				priorityLevels(first: 1000) @connection(key: "Company_priorityLevels", filters: []) {
					edges {
						node {
							id
							name
							weight
							disabled
						}
					}
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const edges = input.priorityLevels.map((priorityLevel, index) => {
		return {
			node: {
				__typename: 'PriorityLevel',
				id: priorityLevel.id,
				name: priorityLevel.name,
				weight: input.priorityLevels.length - index,
				disabled: priorityLevel.disabled,
			},
			cursor: {},
		};
	});
	return {
		createOrUpdatePriorityLevelsBulk: {
			company: {
				priorityLevels: {
					edges,
					pageInfo: {
						endCursor: {},
						hasNextPage: {},
					},
				},
				id: 0,
			},
		},
	};
}

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	const variables = {...input};
	const cleanedVariables = omit(variables, ['companyId']);

	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
