import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateProgramMemberRoleMutation($input: UpdateProgramMemberRoleInput!) {
		updateProgramMemberRole(input: $input) {
			program {
				id
				name
				description
				prefix
				color
				startDate
				endDate
				members(first: 10000) @connection(key: "Program_members", filters: []) {
					edges {
						node {
							id
							role
							person {
								id
								profilePictureId
								fullName
								email
								initials
							}
						}
					}
				}
			}
			member {
				node {
					id
					role
					person {
						id
						profilePictureId
						fullName
						email
						initials
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('Update program member role');
	const {companyId, ...cleanedInput} = input;

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs({companyId, ...cleanedInput}),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
