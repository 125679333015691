/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DetachProjectFromProgramInput = {|
  programId: string,
  projectIds?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type detachProjectFromProgramMutationVariables = {|
  input: DetachProjectFromProgramInput
|};
export type detachProjectFromProgramMutationResponse = {|
  +detachProjectFromProgram: ?{|
    +detachedProjectIds: ?$ReadOnlyArray<?string>,
    +program: ?{|
      +id: string,
      +name: ?string,
      +description: ?string,
      +prefix: ?string,
      +color: ?string,
      +startDate: ?string,
      +endDate: ?string,
      +projects: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +companyProjectId: ?number,
            +name: ?string,
          |}
        |}>
      |},
      +projectsFinancialsTotals: ?{|
        +recognitionForecastRevenue: ?number,
        +allActualTimeAndExpenses: ?number,
        +allTotalTimeAndExpensesAtCompletion: ?number,
        +recognitionProfitPercentage: ?number,
        +recognitionActualProfitPercentage: ?number,
      |},
      +projectsFinancialsToDate: ?{|
        +totalActualRevenueRecognition: ?number,
        +recognitionAmount: ?number,
        +recognitionActualProfitPercentage: ?number,
        +allActualTimeAndExpenses: ?number,
      |},
    |},
  |}
|};
export type detachProjectFromProgramMutation = {|
  variables: detachProjectFromProgramMutationVariables,
  response: detachProjectFromProgramMutationResponse,
|};
*/


/*
mutation detachProjectFromProgramMutation(
  $input: DetachProjectFromProgramInput!
) {
  detachProjectFromProgram(input: $input) {
    detachedProjectIds
    program {
      id
      name
      description
      prefix
      color
      startDate
      endDate
      projects {
        edges {
          node {
            companyProjectId
            name
            id
          }
        }
      }
      projectsFinancialsTotals {
        recognitionForecastRevenue
        allActualTimeAndExpenses
        allTotalTimeAndExpensesAtCompletion
        recognitionProfitPercentage
        recognitionActualProfitPercentage
      }
      projectsFinancialsToDate {
        totalActualRevenueRecognition
        recognitionAmount
        recognitionActualProfitPercentage
        allActualTimeAndExpenses
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DetachProjectFromProgramInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detachedProjectIds",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefix",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allActualTimeAndExpenses",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recognitionActualProfitPercentage",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "FinancialNumbers",
  "kind": "LinkedField",
  "name": "projectsFinancialsTotals",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recognitionForecastRevenue",
      "storageKey": null
    },
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allTotalTimeAndExpensesAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recognitionProfitPercentage",
      "storageKey": null
    },
    (v12/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "FinancialNumbers",
  "kind": "LinkedField",
  "name": "projectsFinancialsToDate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalActualRevenueRecognition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recognitionAmount",
      "storageKey": null
    },
    (v12/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "detachProjectFromProgramMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DetachProjectFromProgramPayload",
        "kind": "LinkedField",
        "name": "detachProjectFromProgram",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProjectTypeConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectServiceProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectServiceProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "detachProjectFromProgramMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DetachProjectFromProgramPayload",
        "kind": "LinkedField",
        "name": "detachProjectFromProgram",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectServiceProjectTypeConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectServiceProjectTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectServiceProjectType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "detachProjectFromProgramMutation",
    "operationKind": "mutation",
    "text": "mutation detachProjectFromProgramMutation(\n  $input: DetachProjectFromProgramInput!\n) {\n  detachProjectFromProgram(input: $input) {\n    detachedProjectIds\n    program {\n      id\n      name\n      description\n      prefix\n      color\n      startDate\n      endDate\n      projects {\n        edges {\n          node {\n            companyProjectId\n            name\n            id\n          }\n        }\n      }\n      projectsFinancialsTotals {\n        recognitionForecastRevenue\n        allActualTimeAndExpenses\n        allTotalTimeAndExpensesAtCompletion\n        recognitionProfitPercentage\n        recognitionActualProfitPercentage\n      }\n      projectsFinancialsToDate {\n        totalActualRevenueRecognition\n        recognitionAmount\n        recognitionActualProfitPercentage\n        allActualTimeAndExpenses\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e54f4867fb5e6fe1329db9f4434a81ff';

module.exports = node;
