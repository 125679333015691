import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation sendTasksToJiraMutation($input: SendTasksToJiraInput!) {
		sendTasksToJira(input: $input) {
			tasks {
				id
				jiraId
				jiraKey
				name
				statusColumnV2 {
					id
					name
					category
				}
				phase {
					id
				}
			}
			updatedTasksIds
			errors
		}
	}
`;

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Send tasks to Jira');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				...input,
			},
		},
		onCompleted,
		onError,
	});
}

export default {commit};
