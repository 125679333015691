import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation favoriteTaskMutation($input: UpdateTaskInput!) {
		updateTask(input: $input) {
			taskEdge {
				node {
					id
					favoured
					favouredSortOrder
					...DraggableCard_task
				}
			}
			updatedTasksIds
			viewer {
				favouredTasksCount
			}
		}
	}
`;

function getConfigs(input) {
	const config = [];

	if (input.favoured) {
		config.push({
			type: 'RANGE_ADD',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionInfo: [
				{
					key: 'Viewer_favouredTasks',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'taskEdge',
		});
	} else {
		config.push({
			type: 'RANGE_DELETE',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionKeys: [
				{
					key: 'Viewer_favouredTasks',
				},
			],
			pathToConnection: ['viewer', 'tasks'],
			deletedIDFieldName: 'updatedTasksIds',
		});
	}

	if (input.favoriteTaskList) {
		if (input.favoured) {
			config.push({
				type: 'RANGE_ADD',
				parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
				connectionInfo: [
					{
						key: 'Viewer_tasks',
						rangeBehavior: 'append',
					},
				],
				edgeName: 'taskEdge',
			});
		} else {
			config.push({
				type: 'RANGE_DELETE',
				parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
				connectionKeys: [
					{
						key: 'Viewer_tasks',
					},
				],
				pathToConnection: ['viewer', 'tasks'],
				deletedIDFieldName: 'updatedTasksIds',
			});
		}
	}

	return config;
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Card Favorited');

	const cleanedInput = omit(input, ['viewerId', 'companyId', 'favoriteTaskList']);

	const variables = {
		input: {
			csrfToken: Util.getCsrfValue(),
			socketClientId: Util.getClientId(),
			...cleanedInput,
		},
	};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
