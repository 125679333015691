/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateExpenseCategoryInput = {|
  id: string,
  name?: ?string,
  disabled?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateExpenseCategoryMutationVariables = {|
  input: UpdateExpenseCategoryInput
|};
export type updateExpenseCategoryMutationResponse = {|
  +updateExpenseCategory: ?{|
    +expenseCategory: ?{|
      +id: string,
      +name: ?string,
      +disabled: ?boolean,
      +canDelete: ?boolean,
    |}
  |}
|};
export type updateExpenseCategoryMutation = {|
  variables: updateExpenseCategoryMutationVariables,
  response: updateExpenseCategoryMutationResponse,
|};
*/


/*
mutation updateExpenseCategoryMutation(
  $input: UpdateExpenseCategoryInput!
) {
  updateExpenseCategory(input: $input) {
    expenseCategory {
      id
      name
      disabled
      canDelete
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateExpenseCategoryInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateExpenseCategoryPayload",
    "kind": "LinkedField",
    "name": "updateExpenseCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExpenseCategory",
        "kind": "LinkedField",
        "name": "expenseCategory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canDelete",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateExpenseCategoryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateExpenseCategoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateExpenseCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation updateExpenseCategoryMutation(\n  $input: UpdateExpenseCategoryInput!\n) {\n  updateExpenseCategory(input: $input) {\n    expenseCategory {\n      id\n      name\n      disabled\n      canDelete\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9dcfba91c5dd846b0f9ffe79fe0fb9be';

module.exports = node;
