import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../css_variables';

export const PageWrapper = styled.div`
	/* required to make scroll work */
	display: flex;
	flex-grow: 1;
`;

export const PageContent = styled.div`
	padding: 0 66px 66px;
`;

export const PageTitleWrapper = styled.div`
	display: flex;
	margin: 24px 66px 0;
	position: sticky;
	left: 66px;
`;

export const PageTitle = styled.div`
	font-size: 16px;
	text-transform: capitalize;
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-weight: 500;
	flex-grow: 1;
`;

export const BannerWrapper = styled.div`
	max-width: 650px;
`;

export const TableWrapper = styled.div`
	table {
		min-width: calc(100% + 66px);
		border-collapse: separate;
		padding-right: 66px;
		.person-initials-hexagon-shape {
			z-index: -1;
			&:before,
			&:after {
				z-index: -1;
			}
			.initials {
				z-index: -1;
			}
		}
	}
	/* border: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey}; */
`;

export const HeaderContainer = styled.div`
	background: white;
	width: 100%;
	position: sticky;
	top: 0;
	left: 0;
`;
