import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation assignMultiplePersonsToMultipleSkillsMutation($input: AssignMultiplePersonsToMultipleSkillsInput!) {
		assignMultiplePersonsToMultipleSkills(input: $input) {
			skills {
				id
				name
				persons {
					edges {
						node {
							id
							fullName
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Skill Persons Created');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
