import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteGoogleDriveProjectFileLinkMutation($input: DeleteGoogleDriveProjectFileLinkInput!) {
		deleteGoogleDriveProjectFileLink(input: $input) {
			deletedGoogleDriveFileId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_googleDriveFiles',
				},
			],
			pathToConnection: ['project', 'googleDriveFiles'],
			deletedIDFieldName: 'deletedGoogleDriveFileId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Google Drive File Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
