/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteSavedReportInput = {|
  id: string,
  reportService?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteSavedReportMutationVariables = {|
  input: DeleteSavedReportInput
|};
export type deleteSavedReportMutationResponse = {|
  +deleteSavedReport: ?{|
    +deletedSavedReportId: ?string
  |}
|};
export type deleteSavedReportMutation = {|
  variables: deleteSavedReportMutationVariables,
  response: deleteSavedReportMutationResponse,
|};
*/


/*
mutation deleteSavedReportMutation(
  $input: DeleteSavedReportInput!
) {
  deleteSavedReport(input: $input) {
    deletedSavedReportId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteSavedReportInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteSavedReportPayload",
    "kind": "LinkedField",
    "name": "deleteSavedReport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedSavedReportId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteSavedReportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteSavedReportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteSavedReportMutation",
    "operationKind": "mutation",
    "text": "mutation deleteSavedReportMutation(\n  $input: DeleteSavedReportInput!\n) {\n  deleteSavedReport(input: $input) {\n    deletedSavedReportId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54d79ae77d476868532b21124ff07801';

module.exports = node;
