import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteExpenseItemMutation($input: DeleteExpenseItemInput!) {
		deleteExpenseItem(input: $input) {
			deletedExpenseItemId
		}
	}
`;

function getConfigs(input) {
	const config = [
		{
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_expenseItems',
				},
			],
			pathToConnection: ['project', 'expenseItems'],
			deletedIDFieldName: 'deletedExpenseItemId',
		},
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_expenseItems',
				},
			],
			pathToConnection: ['company', 'expenseItems'],
			deletedIDFieldName: 'deletedExpenseItemId',
		},
	];

	if (input.actualPersonId === input.personId) {
		config.push({
			type: 'RANGE_DELETE',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionKeys: [
				{
					key: 'Viewer_expenseItems',
				},
			],
			pathToConnection: ['viewer', 'expenseItems'],
			deletedIDFieldName: 'deletedExpenseItemId',
		});
	}
	return config;
}

function getOptimisticResponse(input) {
	return {
		deleteExpenseItem: {
			deletedExpenseItemId: input.id,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	Util.localStorageDeleteFincancialMap();
	tracking.trackEvent('Expense Item Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
