/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnmarkProjectAsFavoriteInput = {|
  projectId: string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type unmarkProjectAsFavoriteMutationVariables = {|
  input: UnmarkProjectAsFavoriteInput
|};
export type unmarkProjectAsFavoriteMutationResponse = {|
  +unmarkProjectAsFavorite: ?{|
    +unmarkedProjectId: ?string,
    +project: ?{|
      +node: ?{|
        +id: string,
        +userRelationTypes: ?$ReadOnlyArray<?string>,
      |}
    |},
  |}
|};
export type unmarkProjectAsFavoriteMutation = {|
  variables: unmarkProjectAsFavoriteMutationVariables,
  response: unmarkProjectAsFavoriteMutationResponse,
|};
*/


/*
mutation unmarkProjectAsFavoriteMutation(
  $input: UnmarkProjectAsFavoriteInput!
) {
  unmarkProjectAsFavorite(input: $input) {
    unmarkedProjectId
    project {
      node {
        id
        userRelationTypes
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UnmarkProjectAsFavoriteInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UnmarkProjectAsFavoritePayload",
    "kind": "LinkedField",
    "name": "unmarkProjectAsFavorite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unmarkedProjectId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectServiceProjectTypeEdge",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectServiceProjectType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userRelationTypes",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "unmarkProjectAsFavoriteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "unmarkProjectAsFavoriteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "unmarkProjectAsFavoriteMutation",
    "operationKind": "mutation",
    "text": "mutation unmarkProjectAsFavoriteMutation(\n  $input: UnmarkProjectAsFavoriteInput!\n) {\n  unmarkProjectAsFavorite(input: $input) {\n    unmarkedProjectId\n    project {\n      node {\n        id\n        userRelationTypes\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76f22380b01b80233c98e85c40f30a7c';

module.exports = node;
