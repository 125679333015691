import {graphql} from 'react-relay';
import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createCostPeriodMutationModernMutation($input: CreateCostPeriodInput!) {
		createCostPeriod(input: $input) {
			costPeriod {
				node {
					id
					startDay
					startMonth
					startYear
					cost
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.personId,
			connectionInfo: [
				{
					key: 'Person_costPeriods',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'costPeriod',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Role Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
