import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateGoogleDriveFileMutation($input: UpdateGoogleDriveFileInput!) {
		updateGoogleDriveFile(input: $input) {
			googleDriveFile {
				name
				folder {
					id
					name
				}
			}
			project {
				tasks(first: 10000) {
					edges {
						node {
							id
							companyTaskId
							name
							googleDriveFiles(first: 1000) {
								edges {
									node {
										id
										name
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Google Drive File Updated');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
