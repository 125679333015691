import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteFixedPriceLockMutation($input: DeleteFixedPriceLockInput!) {
		deleteFixedPriceLock(input: $input) {
			deleteFixedPriceLockId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_fixedPriceLocks',
				},
			],
			pathToConnection: ['project', 'fixedPriceLocks'],
			deletedIDFieldName: 'deleteFixedPriceLockId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Fixed Price Lock Deleted');
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
