/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DuplicateExpenseItemInput = {|
  expenseItemId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type duplicateExpenseItemMutationVariables = {|
  input: DuplicateExpenseItemInput
|};
export type duplicateExpenseItemMutationResponse = {|
  +duplicateExpenseItem: ?{|
    +expenseItem: ?{|
      +node: ?{|
        +id: string,
        +category: ?{|
          +id: string,
          +name: ?string,
        |},
        +name: ?string,
        +approved: ?boolean,
        +expenseYear: ?number,
        +expenseMonth: ?number,
        +expenseDay: ?number,
        +billable: ?boolean,
        +partOfFixedPrice: ?boolean,
        +invoiced: ?boolean,
        +price: ?number,
        +cost: ?number,
        +costBaseCurrency: ?number,
        +priceBaseCurrency: ?number,
        +quantity: ?number,
        +planned: ?boolean,
        +createdAt: ?string,
        +files: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
        +person: ?{|
          +id: string,
          +fullName: ?string,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
        |},
        +project: ?{|
          +id: string,
          +client: ?{|
            +id: string,
            +name: ?string,
          |},
        |},
        +phase: ?{|
          +id: string
        |},
      |}
    |}
  |}
|};
export type duplicateExpenseItemMutation = {|
  variables: duplicateExpenseItemMutationVariables,
  response: duplicateExpenseItemMutationResponse,
|};
*/


/*
mutation duplicateExpenseItemMutation(
  $input: DuplicateExpenseItemInput!
) {
  duplicateExpenseItem(input: $input) {
    expenseItem {
      node {
        id
        category {
          id
          name
        }
        name
        approved
        expenseYear
        expenseMonth
        expenseDay
        billable
        partOfFixedPrice
        invoiced
        price
        cost
        costBaseCurrency
        priceBaseCurrency
        quantity
        planned
        createdAt
        files(first: 1000) {
          edges {
            node {
              id
              name
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        person {
          id
          fullName
          profilePictureId
          profilePictureDefaultId
        }
        project {
          id
          client {
            id
            name
          }
        }
        phase {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DuplicateExpenseItemInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ExpenseCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approved",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expenseYear",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expenseMonth",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expenseDay",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partOfFixedPrice",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiced",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costBaseCurrency",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceBaseCurrency",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planned",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v20 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FileTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureDefaultId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectType",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "PhaseType",
  "kind": "LinkedField",
  "name": "phase",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v24 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "duplicateExpenseItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateExpenseItemPayload",
        "kind": "LinkedField",
        "name": "duplicateExpenseItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExpenseItemTypeEdge",
            "kind": "LinkedField",
            "name": "expenseItem",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExpenseItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": "files",
                    "args": null,
                    "concreteType": "FileTypeConnection",
                    "kind": "LinkedField",
                    "name": "__ExpenseItem_files_connection",
                    "plural": false,
                    "selections": (v20/*: any*/),
                    "storageKey": null
                  },
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "duplicateExpenseItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateExpenseItemPayload",
        "kind": "LinkedField",
        "name": "duplicateExpenseItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExpenseItemTypeEdge",
            "kind": "LinkedField",
            "name": "expenseItem",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExpenseItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": (v24/*: any*/),
                    "concreteType": "FileTypeConnection",
                    "kind": "LinkedField",
                    "name": "files",
                    "plural": false,
                    "selections": (v20/*: any*/),
                    "storageKey": "files(first:1000)"
                  },
                  {
                    "alias": null,
                    "args": (v24/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "ExpenseItem_files",
                    "kind": "LinkedHandle",
                    "name": "files"
                  },
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "duplicateExpenseItem",
            "expenseItem",
            "node",
            "files"
          ]
        }
      ]
    },
    "name": "duplicateExpenseItemMutation",
    "operationKind": "mutation",
    "text": "mutation duplicateExpenseItemMutation(\n  $input: DuplicateExpenseItemInput!\n) {\n  duplicateExpenseItem(input: $input) {\n    expenseItem {\n      node {\n        id\n        category {\n          id\n          name\n        }\n        name\n        approved\n        expenseYear\n        expenseMonth\n        expenseDay\n        billable\n        partOfFixedPrice\n        invoiced\n        price\n        cost\n        costBaseCurrency\n        priceBaseCurrency\n        quantity\n        planned\n        createdAt\n        files(first: 1000) {\n          edges {\n            node {\n              id\n              name\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        person {\n          id\n          fullName\n          profilePictureId\n          profilePictureDefaultId\n        }\n        project {\n          id\n          client {\n            id\n            name\n          }\n        }\n        phase {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f1e4c4df1ef45cd3126351e83e9d07fb';

module.exports = node;
