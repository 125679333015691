import Util from '../../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';
import RelayEnvironment from '../../RelayEnvironment';

const mutation = graphql`
	mutation updateErrorMutation($input: UpdateErrorInput!) {
		UpdateError(input: $input) {
			MissionControlErrorList {
				id
				actualId
				timestamp
				personId
				personName
				companyId
				companyName
				correlationId
				fixed
				notes
				excludeFromStats
				lastUpdatedByName
				bugDuty
				team
				url
				knownType
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(RelayEnvironment.getInstanceMC(), {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
