import Util from '../../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';
import RelayEnvironment from '../../RelayEnvironment';

const mutation = graphql`
	mutation updateCompanyToBeDeletedMutation($input: UpdateCompanyToBeDeletedInput!) {
		UpdateCompanyToBeDeleted(input: $input) {
			MissionControlCompanyToBeDeleted {
				id
				toBeDeleted
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(RelayEnvironment.getInstanceMC(), {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
