import { commitMutation, graphql } from 'react-relay';
import Util from '../../../forecast-app/shared/util/util';
import RelayEnvironment from '../../../RelayEnvironment';
const mutation = graphql `
	mutation CreateSageCompanyMutation($input: CreateSageCompanyInput!) {
		CreateSageCompany(input: $input) {
			resultCode
			companyId
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return commitMutation(RelayEnvironment.getInstanceMC(), {
        mutation,
        variables,
        configs: [],
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
