/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteGithubAttachedObjectInput = {|
  id?: ?string,
  taskId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteGithubAttachedObjectMutationVariables = {|
  input: DeleteGithubAttachedObjectInput
|};
export type deleteGithubAttachedObjectMutationResponse = {|
  +deleteGithubAttachedObject: ?{|
    +deletedGithubAttachedObjectId: ?string
  |}
|};
export type deleteGithubAttachedObjectMutation = {|
  variables: deleteGithubAttachedObjectMutationVariables,
  response: deleteGithubAttachedObjectMutationResponse,
|};
*/


/*
mutation deleteGithubAttachedObjectMutation(
  $input: DeleteGithubAttachedObjectInput!
) {
  deleteGithubAttachedObject(input: $input) {
    deletedGithubAttachedObjectId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteGithubAttachedObjectInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteGithubAttachedObjectPayload",
    "kind": "LinkedField",
    "name": "deleteGithubAttachedObject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedGithubAttachedObjectId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteGithubAttachedObjectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteGithubAttachedObjectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteGithubAttachedObjectMutation",
    "operationKind": "mutation",
    "text": "mutation deleteGithubAttachedObjectMutation(\n  $input: DeleteGithubAttachedObjectInput!\n) {\n  deleteGithubAttachedObject(input: $input) {\n    deletedGithubAttachedObjectId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'da16d7aff68157b2b99e52ac46da8dae';

module.exports = node;
