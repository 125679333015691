import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteInsightMutation($input: DeleteInsightInput!) {
		deleteInsight(input: $input) {
			deletedInsightId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_insights',
				},
			],
			pathToConnection: ['company', 'insights'],
			deletedIDFieldName: 'deletedInsightId',
		},
		{
			type: 'NODE_DELETE',
			deletedIDFieldName: 'deletedInsightId',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		deleteInsight: {
			deletedInsightId: input.id,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Insight Deleted');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), id: input.id}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
