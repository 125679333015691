import { commitMutation, graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
import { createRangeBulkAddUpdater } from '../../relay-helpers/RelayCacheHelpers';
const mutation = graphql `
	mutation CreateBulkClientBillToMutation($input: CreateBulkClientBillToInput!) {
		createBulkClientBillTo(input: $input) {
			billTos {
				node {
					id
					clientId
					billFrom {
						id
					}
					billTo {
						id
					}
				}
			}
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    var _a, _b;
    trackEvent('Client Bill To', 'Bulk Created', input);
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return commitMutation(environment, {
        mutation,
        variables,
        updater: createRangeBulkAddUpdater(((_b = (_a = input === null || input === void 0 ? void 0 : input.billTos) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.clientId) || '', 'createBulkClientBillTo', 'billTos', 'Client_clientBillTos'),
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
