import {graphql} from 'react-relay';
import {omit} from 'lodash';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation ValidateCanUserDeleteTaskMutation($input: ValidateCanUserDeleteTaskInput!) {
		validateCanUserDeleteTask(input: $input) {
			userCanDeleteTask
			userCantDeleteTaskReason
		}
	}
`;

function getConfigs() {
	return [];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const variables = {...input};

	// ID is needed for updateCompanyMutation's optimistic response, but should not be included in the variables
	const variablesNoID = omit(variables, ['id']);

	return trackAndCommitMutation(
		environment,
		{
			mutation,
			variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...variablesNoID}},
			uploadables,
			configs: getConfigs(),
			onCompleted: onSuccess,
			onError: onError,
		},
		true
	);
}

export default {commit};
