/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSlackVerificationKeyInput = {|
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createSlackVerificationKeyMutationVariables = {|
  input: CreateSlackVerificationKeyInput
|};
export type createSlackVerificationKeyMutationResponse = {|
  +createSlackVerificationKey: ?{|
    +key: ?string
  |}
|};
export type createSlackVerificationKeyMutation = {|
  variables: createSlackVerificationKeyMutationVariables,
  response: createSlackVerificationKeyMutationResponse,
|};
*/


/*
mutation createSlackVerificationKeyMutation(
  $input: CreateSlackVerificationKeyInput!
) {
  createSlackVerificationKey(input: $input) {
    key
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSlackVerificationKeyInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateSlackVerificationKeyPayload",
    "kind": "LinkedField",
    "name": "createSlackVerificationKey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createSlackVerificationKeyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createSlackVerificationKeyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createSlackVerificationKeyMutation",
    "operationKind": "mutation",
    "text": "mutation createSlackVerificationKeyMutation(\n  $input: CreateSlackVerificationKeyInput!\n) {\n  createSlackVerificationKey(input: $input) {\n    key\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39633f78f990bf6f4e5589827b8d27cd';

module.exports = node;
