/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateLabelCategoryInput = {|
  name?: ?string,
  allowOnPeople?: ?boolean,
  allowOnProjects?: ?boolean,
  allowOnTasks?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createLabelCategoryMutationVariables = {|
  input: CreateLabelCategoryInput
|};
export type createLabelCategoryMutationResponse = {|
  +createLabelCategory: ?{|
    +labelCategory: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +allowOnTasks: ?boolean,
        +allowOnProjects: ?boolean,
        +allowOnPeople: ?boolean,
      |}
    |}
  |}
|};
export type createLabelCategoryMutation = {|
  variables: createLabelCategoryMutationVariables,
  response: createLabelCategoryMutationResponse,
|};
*/


/*
mutation createLabelCategoryMutation(
  $input: CreateLabelCategoryInput!
) {
  createLabelCategory(input: $input) {
    labelCategory {
      node {
        id
        name
        allowOnTasks
        allowOnProjects
        allowOnPeople
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateLabelCategoryInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateLabelCategoryPayload",
    "kind": "LinkedField",
    "name": "createLabelCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LabelCategoryTypeEdge",
        "kind": "LinkedField",
        "name": "labelCategory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LabelCategory",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowOnTasks",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowOnProjects",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowOnPeople",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createLabelCategoryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createLabelCategoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createLabelCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation createLabelCategoryMutation(\n  $input: CreateLabelCategoryInput!\n) {\n  createLabelCategory(input: $input) {\n    labelCategory {\n      node {\n        id\n        name\n        allowOnTasks\n        allowOnProjects\n        allowOnPeople\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7566b2771c833fea77ab6ff016f6cb13';

module.exports = node;
