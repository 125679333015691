/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImportHubspotCompaniesInput = {|
  clientIds?: ?$ReadOnlyArray<?string>,
  selectAll?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type ImportHubspotCompaniesMutationVariables = {|
  input: ImportHubspotCompaniesInput
|};
export type ImportHubspotCompaniesMutationResponse = {|
  +importHubspotCompanies: ?{|
    +clients: ?$ReadOnlyArray<?{|
      +cursor: string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +street: ?string,
        +zip: ?string,
        +city: ?string,
        +vat: ?string,
        +country: ?string,
        +notes: ?string,
        +logoId: ?string,
        +logoDefaultId: ?number,
      |},
    |}>
  |}
|};
export type ImportHubspotCompaniesMutation = {|
  variables: ImportHubspotCompaniesMutationVariables,
  response: ImportHubspotCompaniesMutationResponse,
|};
*/


/*
mutation ImportHubspotCompaniesMutation(
  $input: ImportHubspotCompaniesInput!
) {
  importHubspotCompanies(input: $input) {
    clients {
      cursor
      node {
        id
        name
        street
        zip
        city
        vat
        country
        notes
        logoId
        logoDefaultId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ImportHubspotCompaniesInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ImportHubspotCompaniesPayload",
    "kind": "LinkedField",
    "name": "importHubspotCompanies",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientTypeEdge",
        "kind": "LinkedField",
        "name": "clients",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Client",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "street",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zip",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logoId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logoDefaultId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportHubspotCompaniesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportHubspotCompaniesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ImportHubspotCompaniesMutation",
    "operationKind": "mutation",
    "text": "mutation ImportHubspotCompaniesMutation(\n  $input: ImportHubspotCompaniesInput!\n) {\n  importHubspotCompanies(input: $input) {\n    clients {\n      cursor\n      node {\n        id\n        name\n        street\n        zip\n        city\n        vat\n        country\n        notes\n        logoId\n        logoDefaultId\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5240457d3d95a610803051e95cbe955c';

module.exports = node;
