import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateProgramClientMutation($input: UpdateProgramClientInput!) {
		updateProgramClient(input: $input) {
			deletedClientId
			program {
				node {
					id
					clients(first: 10000) {
						edges {
							node {
								id
								name
								logoId
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('Updating program client');
	const {companyId, ...cleanedInput} = input;

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs({companyId, ...cleanedInput}),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
