import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation CreateBulkInvoicesMutation($input: CreateBulkInvoicesInput!) {
		createBulkInvoices(input: $input) {
			invoices {
				id
				invoiceReference
				companyInvoiceId
				name
				currency
				invoiceType
				status
				dueDay
				dueMonth
				dueYear
				createdDay
				createdMonth
				createdYear
				notes
				entries(first: 100000000) @connection(key: "Invoice_entries") {
					edges {
						node {
							id
							name
							quantity
							unitPrice
							discount
							tax
							description
						}
					}
				}
				payments(first: 100000000) @connection(key: "Invoice_payments") {
					edges {
						node {
							id
							notes
							amount
							day
							month
							year
							createdAt
							createdBy {
								fullName
							}
						}
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Bulk Invoices Created');

	if (input.invoiceReference === null) {
		input = omit(input, ['invoiceReference']);
	}

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
