/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PERIODICITY = "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type CreateRetainerPeriodInput = {|
  projectId?: ?string,
  name?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  endYear?: ?number,
  endMonth?: ?number,
  endDay?: ?number,
  preCreateAmount?: ?number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createRetainerPeriodMutationVariables = {|
  input: CreateRetainerPeriodInput
|};
export type createRetainerPeriodMutationResponse = {|
  +createRetainerPeriod: ?{|
    +retainerPeriod: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +startYear: ?number,
        +startMonth: ?number,
        +startDay: ?number,
        +endYear: ?number,
        +endMonth: ?number,
        +endDay: ?number,
        +available: ?number,
        +periodLength: ?number,
        +periodPeriodicity: ?PERIODICITY,
        +periodPriceAmount: ?number,
        +periodHoursAmount: ?number,
        +periodBudgetType: ?PERIOD_BUDGET_TYPE,
        +periodSettingIgnoreForBilling: ?boolean,
        +periodSettingSubtractValue: ?boolean,
        +periodSettingRollValue: ?boolean,
        +periodSettingAddExpenses: ?boolean,
        +periodDifferencePriceAmount: ?number,
        +periodDifferenceHoursAmount: ?number,
        +sharedPeriodDifferenceHoursAmount: ?number,
        +sharedPeriodDifferencePriceAmount: ?number,
        +ignoredRolloverHours: ?number,
        +ignoredRolloverPrice: ?number,
        +periodLocked: ?boolean,
        +periodLockedTime: ?string,
        +periodDifferenceSplit: ?boolean,
      |}
    |}
  |}
|};
export type createRetainerPeriodMutation = {|
  variables: createRetainerPeriodMutationVariables,
  response: createRetainerPeriodMutationResponse,
|};
*/


/*
mutation createRetainerPeriodMutation(
  $input: CreateRetainerPeriodInput!
) {
  createRetainerPeriod(input: $input) {
    retainerPeriod {
      node {
        id
        name
        startYear
        startMonth
        startDay
        endYear
        endMonth
        endDay
        available
        periodLength
        periodPeriodicity
        periodPriceAmount
        periodHoursAmount
        periodBudgetType
        periodSettingIgnoreForBilling
        periodSettingSubtractValue
        periodSettingRollValue
        periodSettingAddExpenses
        periodDifferencePriceAmount
        periodDifferenceHoursAmount
        sharedPeriodDifferenceHoursAmount
        sharedPeriodDifferencePriceAmount
        ignoredRolloverHours
        ignoredRolloverPrice
        periodLocked
        periodLockedTime
        periodDifferenceSplit
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateRetainerPeriodInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRetainerPeriodPayload",
    "kind": "LinkedField",
    "name": "createRetainerPeriod",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RetainerPeriodTypeEdge",
        "kind": "LinkedField",
        "name": "retainerPeriod",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RetainerPeriodType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "available",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodLength",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodPeriodicity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodPriceAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodHoursAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodBudgetType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodSettingIgnoreForBilling",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodSettingSubtractValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodSettingRollValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodSettingAddExpenses",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodDifferencePriceAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodDifferenceHoursAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sharedPeriodDifferenceHoursAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sharedPeriodDifferencePriceAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ignoredRolloverHours",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ignoredRolloverPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodLocked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodLockedTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "periodDifferenceSplit",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createRetainerPeriodMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createRetainerPeriodMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createRetainerPeriodMutation",
    "operationKind": "mutation",
    "text": "mutation createRetainerPeriodMutation(\n  $input: CreateRetainerPeriodInput!\n) {\n  createRetainerPeriod(input: $input) {\n    retainerPeriod {\n      node {\n        id\n        name\n        startYear\n        startMonth\n        startDay\n        endYear\n        endMonth\n        endDay\n        available\n        periodLength\n        periodPeriodicity\n        periodPriceAmount\n        periodHoursAmount\n        periodBudgetType\n        periodSettingIgnoreForBilling\n        periodSettingSubtractValue\n        periodSettingRollValue\n        periodSettingAddExpenses\n        periodDifferencePriceAmount\n        periodDifferenceHoursAmount\n        sharedPeriodDifferenceHoursAmount\n        sharedPeriodDifferencePriceAmount\n        ignoredRolloverHours\n        ignoredRolloverPrice\n        periodLocked\n        periodLockedTime\n        periodDifferenceSplit\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6812794c61df99405fb86a6ed41725fa';

module.exports = node;
