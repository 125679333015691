import { commitMutation, graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
const mutation = graphql `
	mutation UpdateGeneralTimeRegistrationSettingsMutation($input: UpdateGeneralTimeRegistrationSettingsInput!) {
		updateGeneralTimeRegistrationSettings(input: $input) {
			timeRegistrationSettings {
				granularity
				allowDoneTasks
				allowProjects
				allowInternalTime
				allowBillableSplit
				allowRoles
				allowExceedingEstimate
				allowOutsideProjectDates
				allowOutsideTaskDates
				requireTaskAssignment
				requireNote
			}
		}
	}
`;
function getOptimisticResponse(input) {
    return {
        updateGeneralTimeRegistrationSettings: {
            timeRegistrationSettings: {
                id: input.id,
                granularity: undefined,
                allowDoneTasks: input.allowDoneTasks,
                allowProjects: input.allowProjects,
                allowInternalTime: input.allowInternalTime,
                allowBillableSplit: input.allowBillableSplit,
                allowRoles: input.allowRoles,
                allowExceedingEstimate: input.allowExceedingEstimate,
                allowOutsideProjectDates: input.allowOutsideProjectDates,
                allowOutsideTaskDates: input.allowOutsideTaskDates,
                requireTaskAssignment: input.requireTaskAssignment,
                requireNote: input.requireNote,
            },
        },
    };
}
function commit(environment, input, onSuccess, onError) {
    trackEvent('Time Registration Settings', 'General Settings Updated', input);
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return commitMutation(environment, {
        mutation,
        variables,
        optimisticResponse: getOptimisticResponse(input),
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
