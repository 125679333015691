import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation CreateSageIntacctProjectMutation($input: CreateSageIntacctProjectInput!) {
		createSageIntacctProject(input: $input) {
			project {
				id
				name
				sageProject {
					sageProjectId
					sageProjectName
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
