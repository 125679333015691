/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateImpersonatePersonInput = {|
  id: string,
  impersonatePerson?: ?string,
  category?: ?string,
  reason?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateImpersonatePersonMutationVariables = {|
  input: UpdateImpersonatePersonInput
|};
export type updateImpersonatePersonMutationResponse = {|
  +UpdateImpersonatePerson: ?{|
    +missionControlData: ?{|
      +forecastPersons: ?$ReadOnlyArray<?{|
        +id: string,
        +firstName: ?string,
        +lastName: ?string,
        +impersonatedPersonId: ?string,
      |}>
    |}
  |}
|};
export type updateImpersonatePersonMutation = {|
  variables: updateImpersonatePersonMutationVariables,
  response: updateImpersonatePersonMutationResponse,
|};
*/


/*
mutation updateImpersonatePersonMutation(
  $input: UpdateImpersonatePersonInput!
) {
  UpdateImpersonatePerson(input: $input) {
    missionControlData {
      forecastPersons {
        id
        firstName
        lastName
        impersonatedPersonId
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateImpersonatePersonInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "MissionControlImpersonatePersonType",
  "kind": "LinkedField",
  "name": "forecastPersons",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "impersonatedPersonId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateImpersonatePersonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateImpersonatePersonPayload",
        "kind": "LinkedField",
        "name": "UpdateImpersonatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateImpersonatePersonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateImpersonatePersonPayload",
        "kind": "LinkedField",
        "name": "UpdateImpersonatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MissionControlDataType",
            "kind": "LinkedField",
            "name": "missionControlData",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateImpersonatePersonMutation",
    "operationKind": "mutation",
    "text": "mutation updateImpersonatePersonMutation(\n  $input: UpdateImpersonatePersonInput!\n) {\n  UpdateImpersonatePerson(input: $input) {\n    missionControlData {\n      forecastPersons {\n        id\n        firstName\n        lastName\n        impersonatedPersonId\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '113e36804b2177c12d3a8253e69330b2';

module.exports = node;
