/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
export type SendTasksToJiraInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  jiraIssueTypeId?: ?string,
  jiraSubtaskIssueTypeId?: ?string,
  jiraType?: ?string,
  jiraEpicId?: ?string,
  customFields?: ?$ReadOnlyArray<?JiraCustomFieldInputType>,
  syncHierarchy?: ?boolean,
  firstLevelAsEpic?: ?boolean,
  epicIssueTypeId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type JiraCustomFieldInputType = {|
  key?: ?string,
  value?: ?string,
|};
export type sendTasksToJiraMutationVariables = {|
  input: SendTasksToJiraInput
|};
export type sendTasksToJiraMutationResponse = {|
  +sendTasksToJira: ?{|
    +tasks: ?$ReadOnlyArray<?{|
      +id: string,
      +jiraId: ?string,
      +jiraKey: ?string,
      +name: ?string,
      +statusColumnV2: ?{|
        +id: string,
        +name: ?string,
        +category: ?STATUS_CATEGORY,
      |},
      +phase: ?{|
        +id: string
      |},
    |}>,
    +updatedTasksIds: ?$ReadOnlyArray<?string>,
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type sendTasksToJiraMutation = {|
  variables: sendTasksToJiraMutationVariables,
  response: sendTasksToJiraMutationResponse,
|};
*/


/*
mutation sendTasksToJiraMutation(
  $input: SendTasksToJiraInput!
) {
  sendTasksToJira(input: $input) {
    tasks {
      id
      jiraId
      jiraKey
      name
      statusColumnV2 {
        id
        name
        category
      }
      phase {
        id
      }
    }
    updatedTasksIds
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SendTasksToJiraInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendTasksToJiraPayload",
    "kind": "LinkedField",
    "name": "sendTasksToJira",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jiraId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jiraKey",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StatusColumnV2",
            "kind": "LinkedField",
            "name": "statusColumnV2",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhaseType",
            "kind": "LinkedField",
            "name": "phase",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedTasksIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sendTasksToJiraMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sendTasksToJiraMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "sendTasksToJiraMutation",
    "operationKind": "mutation",
    "text": "mutation sendTasksToJiraMutation(\n  $input: SendTasksToJiraInput!\n) {\n  sendTasksToJira(input: $input) {\n    tasks {\n      id\n      jiraId\n      jiraKey\n      name\n      statusColumnV2 {\n        id\n        name\n        category\n      }\n      phase {\n        id\n      }\n    }\n    updatedTasksIds\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6261c2d2bf40f3868f980e91f0522964';

module.exports = node;
