/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetFirstTimePasswordInput = {|
  password: string,
  email: string,
  firstName?: ?string,
  lastName?: ?string,
  phoneNumber?: ?string,
  title?: ?string,
  toolUsed?: ?string,
  website?: ?string,
  companyName?: ?string,
  timezone?: ?string,
  key: string,
  language?: ?string,
  interest?: ?string,
  companySize?: ?string,
  businessType?: ?string,
  teamType?: ?string,
  roleType?: ?string,
  roleId?: ?string,
  helpFromRealPerson?: ?string,
  economicToken?: ?string,
  termsVersionAccepted?: ?string,
  autoJoinAccepted?: ?boolean,
  noSampleProject?: ?boolean,
  clientMutationId?: ?string,
|};
export type setFirstTimePasswordMutationVariables = {|
  input: SetFirstTimePasswordInput
|};
export type setFirstTimePasswordMutationResponse = {|
  +setFirstTimePassword: ?{|
    +personId: ?string,
    +companyId: ?string,
    +companyTier: ?string,
    +csrfToken: ?string,
    +profiles: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
    |}>,
    +success: ?boolean,
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type setFirstTimePasswordMutation = {|
  variables: setFirstTimePasswordMutationVariables,
  response: setFirstTimePasswordMutationResponse,
|};
*/


/*
mutation setFirstTimePasswordMutation(
  $input: SetFirstTimePasswordInput!
) {
  setFirstTimePassword(input: $input) {
    personId
    companyId
    companyTier
    csrfToken
    profiles {
      id
      name
    }
    success
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetFirstTimePasswordInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetFirstTimePasswordPayload",
    "kind": "LinkedField",
    "name": "setFirstTimePassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "personId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyTier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "csrfToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "profiles",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "setFirstTimePasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "setFirstTimePasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "setFirstTimePasswordMutation",
    "operationKind": "mutation",
    "text": "mutation setFirstTimePasswordMutation(\n  $input: SetFirstTimePasswordInput!\n) {\n  setFirstTimePassword(input: $input) {\n    personId\n    companyId\n    companyTier\n    csrfToken\n    profiles {\n      id\n      name\n    }\n    success\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ea82a3ac4d2335fb702b2c1c5e390c1';

module.exports = node;
