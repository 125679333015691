/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type settingsRoles_role$ref = any;
export type UpdateRoleInput = {|
  id: string,
  name?: ?string,
  notifyTimeReg?: ?boolean,
  notifyProjectOverview?: ?boolean,
  disabled?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateRoleMutationVariables = {|
  input: UpdateRoleInput
|};
export type updateRoleMutationResponse = {|
  +updateRole: ?{|
    +role: ?{|
      +$fragmentRefs: settingsRoles_role$ref
    |}
  |}
|};
export type updateRoleMutation = {|
  variables: updateRoleMutationVariables,
  response: updateRoleMutationResponse,
|};
*/


/*
mutation updateRoleMutation(
  $input: UpdateRoleInput!
) {
  updateRole(input: $input) {
    role {
      ...settingsRoles_role
      id
    }
  }
}

fragment settingsRoles_role on Role {
  id
  name
  notifyProjectOverview
  timeRegCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateRoleInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRolePayload",
        "kind": "LinkedField",
        "name": "updateRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "settingsRoles_role"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRolePayload",
        "kind": "LinkedField",
        "name": "updateRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notifyProjectOverview",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeRegCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateRoleMutation",
    "operationKind": "mutation",
    "text": "mutation updateRoleMutation(\n  $input: UpdateRoleInput!\n) {\n  updateRole(input: $input) {\n    role {\n      ...settingsRoles_role\n      id\n    }\n  }\n}\n\nfragment settingsRoles_role on Role {\n  id\n  name\n  notifyProjectOverview\n  timeRegCount\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '66360606d8bed6a553cff260875492aa';

module.exports = node;
