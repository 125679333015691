import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateSkillCategoryMutation($input: UpdateSkillCategoryInput!) {
		updateSkillCategory(input: $input) {
			skillCategory {
				node {
					id
					name
					skills {
						edges {
							node {
								id
								name
								category {
									id
								}
								persons {
									edges {
										node {
											id
											role {
												id
											}
											department {
												id
											}
											active
											permissions
										}
									}
								}
							}
						}
					}
				}
			}
			skills {
				id
				name
				category {
					id
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function getOptimisticResponse(input) {
	return {
		updateSkillCategory: {
			skillCategory: {
				node: {
					id: input.id,
					name: input.name,
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Skill Category Updated');

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(cleanedInput),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
