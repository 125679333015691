import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation addMemberToProgramMutation($input: AddMemberToProgramInput!) {
		addMemberToProgram(input: $input) {
			program {
				node {
					id
					name
					description
					prefix
					color
					startDate
					endDate
					members(first: 10000) @connection(key: "Program_members", filters: []) {
						edges {
							node {
								id
								role
								person {
									id
									profilePictureId
									fullName
									email
									initials
								}
							}
						}
					}
					projects(first: 10000) @connection(key: "Program_projects", filters: []) {
						edges {
							node {
								id
								serviceCompanyId
								companyProjectId
								name
								prefix
								color
								startDate
								endDate
								stage
								clients {
									edges {
										node {
											id
											name
											logoId
										}
									}
								}
								status {
									edges {
										node {
											id
											description
											color
											addedAt
										}
									}
								}
								currentProjectStatus {
									id
									color
									description
									person {
										id
										firstName
										lastName
										profilePictureId
									}
								}
								financialNumbers {
									allActualTimeAndExpenses
									recognitionProfitPercentage
									allTotalTimeAndExpensesAtCompletion
								}
								financialNumbersToDate {
									recognitionProfitPercentage
								}
								totalRevenueRecognition
								totalRevenueRecognitionToDate
								totalTimeRevenueRecognitionToDate
								totalBillableExpensesAtCompletion
								settings {
									edges {
										node {
											id
											name
											value
										}
									}
								}
								userRelationTypes
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.programId,
			connectionInfo: [
				{
					key: 'Program_members',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'members',
		},
	];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	tracking.trackEvent('Add member to program');
	const {companyId, ...cleanedInput} = input;

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs({companyId, ...cleanedInput}),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
