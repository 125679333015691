/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MoveProjectOnTimelineInput = {|
  id?: ?string,
  projectStartYear?: ?number,
  projectStartMonth?: ?number,
  projectStartDay?: ?number,
  projectEndYear?: ?number,
  projectEndMonth?: ?number,
  projectEndDay?: ?number,
  isWeekendHidden?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type moveProjectOnTimelineMutationVariables = {|
  input: MoveProjectOnTimelineInput
|};
export type moveProjectOnTimelineMutationResponse = {|
  +moveProjectOnTimeline: ?{|
    +project: ?{|
      +id: string,
      +projectStartYear: ?number,
      +projectStartMonth: ?number,
      +projectStartDay: ?number,
      +projectEndYear: ?number,
      +projectEndMonth: ?number,
      +projectEndDay: ?number,
    |}
  |}
|};
export type moveProjectOnTimelineMutation = {|
  variables: moveProjectOnTimelineMutationVariables,
  response: moveProjectOnTimelineMutationResponse,
|};
*/


/*
mutation moveProjectOnTimelineMutation(
  $input: MoveProjectOnTimelineInput!
) {
  moveProjectOnTimeline(input: $input) {
    project {
      id
      projectStartYear
      projectStartMonth
      projectStartDay
      projectEndYear
      projectEndMonth
      projectEndDay
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "MoveProjectOnTimelineInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MoveProjectOnTimelinePayload",
    "kind": "LinkedField",
    "name": "moveProjectOnTimeline",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectType",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectStartYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectStartMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectStartDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectEndYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectEndMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectEndDay",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "moveProjectOnTimelineMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "moveProjectOnTimelineMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "moveProjectOnTimelineMutation",
    "operationKind": "mutation",
    "text": "mutation moveProjectOnTimelineMutation(\n  $input: MoveProjectOnTimelineInput!\n) {\n  moveProjectOnTimeline(input: $input) {\n    project {\n      id\n      projectStartYear\n      projectStartMonth\n      projectStartDay\n      projectEndYear\n      projectEndMonth\n      projectEndDay\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '109afb9720c922397ad4e2a1ea681ff6';

module.exports = node;
