/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateLabelCategoryInput = {|
  id: string,
  name?: ?string,
  allowOnPeople?: ?boolean,
  allowOnProjects?: ?boolean,
  allowOnTasks?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateLabelCategoryMutationVariables = {|
  input: UpdateLabelCategoryInput
|};
export type updateLabelCategoryMutationResponse = {|
  +updateLabelCategory: ?{|
    +labelCategory: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +allowOnTasks: ?boolean,
        +allowOnProjects: ?boolean,
        +allowOnPeople: ?boolean,
      |}
    |}
  |}
|};
export type updateLabelCategoryMutation = {|
  variables: updateLabelCategoryMutationVariables,
  response: updateLabelCategoryMutationResponse,
|};
*/


/*
mutation updateLabelCategoryMutation(
  $input: UpdateLabelCategoryInput!
) {
  updateLabelCategory(input: $input) {
    labelCategory {
      node {
        id
        name
        allowOnTasks
        allowOnProjects
        allowOnPeople
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateLabelCategoryInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLabelCategoryPayload",
    "kind": "LinkedField",
    "name": "updateLabelCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LabelCategoryTypeEdge",
        "kind": "LinkedField",
        "name": "labelCategory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LabelCategory",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowOnTasks",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowOnProjects",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowOnPeople",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateLabelCategoryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateLabelCategoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateLabelCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation updateLabelCategoryMutation(\n  $input: UpdateLabelCategoryInput!\n) {\n  updateLabelCategory(input: $input) {\n    labelCategory {\n      node {\n        id\n        name\n        allowOnTasks\n        allowOnProjects\n        allowOnPeople\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '85486aab425e6e975e6591f75bf85ac8';

module.exports = node;
