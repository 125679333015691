/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation DeleteClientBillToMutation(
  $input: DeleteClientBillToInput!
) {
  deleteClientBillTo(input: $input) {
    deletedClientBillToId
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "DeleteClientBillToInput!"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "DeleteClientBillToPayload",
            "kind": "LinkedField",
            "name": "deleteClientBillTo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deletedClientBillToId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DeleteClientBillToMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DeleteClientBillToMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "DeleteClientBillToMutation",
            "operationKind": "mutation",
            "text": "mutation DeleteClientBillToMutation(\n  $input: DeleteClientBillToInput!\n) {\n  deleteClientBillTo(input: $input) {\n    deletedClientBillToId\n  }\n}\n"
        }
    };
})();
node.hash = '71450eda6154dbd804026593606ab845';
export default node;
