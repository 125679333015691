/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSprintGoalInput = {|
  id?: ?string,
  description?: ?string,
  stage?: ?string,
  done?: ?boolean,
  statusColor?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateSprintGoalMutationVariables = {|
  input: UpdateSprintGoalInput
|};
export type UpdateSprintGoalMutationResponse = {|
  +updateSprintGoal: ?{|
    +sprintGoal: ?{|
      +id: string,
      +description: ?string,
      +stage: ?string,
      +statusColor: ?string,
    |}
  |}
|};
export type UpdateSprintGoalMutation = {|
  variables: UpdateSprintGoalMutationVariables,
  response: UpdateSprintGoalMutationResponse,
|};
*/


/*
mutation UpdateSprintGoalMutation(
  $input: UpdateSprintGoalInput!
) {
  updateSprintGoal(input: $input) {
    sprintGoal {
      id
      description
      stage
      statusColor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSprintGoalInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSprintGoalPayload",
    "kind": "LinkedField",
    "name": "updateSprintGoal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SprintGoal",
        "kind": "LinkedField",
        "name": "sprintGoal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "statusColor",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSprintGoalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSprintGoalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateSprintGoalMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSprintGoalMutation(\n  $input: UpdateSprintGoalInput!\n) {\n  updateSprintGoal(input: $input) {\n    sprintGoal {\n      id\n      description\n      stage\n      statusColor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '70a8db7ea3f16828032e84f838911262';

module.exports = node;
