import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteSprintMutation($input: DeleteSprintInput!) {
		deleteSprint(input: $input) {
			deletedSprintId
			project {
				id
				tasks(first: 10000) {
					edges {
						node {
							sprint {
								id
							}
							startDay
							startMonth
							startYear
							startFrom
							deadlineFrom
							deadlineDay
							deadlineMonth
							deadlineYear
						}
					}
				}
			}
			projectGroup {
				id
				projects(first: 10000) {
					edges {
						node {
							tasks(first: 10000) {
								edges {
									node {
										sprint {
											id
										}
										startDay
										startMonth
										startYear
										startFrom
										deadlineFrom
										deadlineDay
										deadlineMonth
										deadlineYear
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_sprints',
				},
			],
			pathToConnection: ['project', 'sprints'],
			deletedIDFieldName: 'deletedSprintId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Sprint Deleted');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
