import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';
import {createRangeBulkAddUpdater} from '../relay-helpers/RelayCacheHelpers';

const mutation = graphql`
	mutation CreateSageIntacctTasksMutation($input: CreateSageIntacctTasksInput!) {
		createSageIntacctTasks(input: $input) {
			tasks {
				node {
					id
					name
					sageIntacctId
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(environment, {
		mutation,
		variables,
		updater: createRangeBulkAddUpdater(input.forecastProjectId, 'createSageIntacctTasks', 'tasks', 'Project_tasks'),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
