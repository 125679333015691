import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation ChangeJiraRemainingSyncMutation($input: changeJiraRemainingSyncInput!) {
		changeJiraRemainingSync(input: $input) {
			project {
				remainingAutoCalculated
				jiraSyncRemaining
				jiraCloudProjectSettings {
					isSyncDone
				}
			}
		}
	}
`;
function getOptimisticResponse(input) {
	return {
		changeJiraRemainingSync: {
			project: {
				id: input.forecastProjectId,
				jiraSyncRemaining: input.syncRemaining,
				jiraCloudProjectSettings: {isSyncDone: false},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(environment, {
		mutation,
		variables,
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
