/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EnableAndConvertSubtasksForProjectInput = {|
  projectId?: ?string,
  conversionMethod?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type EnableAndConvertSubtasksForProjectMutationVariables = {|
  input: EnableAndConvertSubtasksForProjectInput
|};
export type EnableAndConvertSubtasksForProjectMutationResponse = {|
  +enableAndConvertSubtasksForProject: ?{|
    +project: ?{|
      +id: string,
      +taskLevels: ?number,
      +remainingAutoCalculated: ?boolean,
      +useTaskHierarchy: ?boolean,
    |},
    +projectEdge: ?{|
      +node: ?{|
        +id: string,
        +taskLevels: ?number,
        +remainingAutoCalculated: ?boolean,
        +useTaskHierarchy: ?boolean,
      |}
    |},
  |}
|};
export type EnableAndConvertSubtasksForProjectMutation = {|
  variables: EnableAndConvertSubtasksForProjectMutationVariables,
  response: EnableAndConvertSubtasksForProjectMutationResponse,
|};
*/


/*
mutation EnableAndConvertSubtasksForProjectMutation(
  $input: EnableAndConvertSubtasksForProjectInput!
) {
  enableAndConvertSubtasksForProject(input: $input) {
    project {
      id
      taskLevels
      remainingAutoCalculated
      useTaskHierarchy
    }
    projectEdge {
      node {
        id
        taskLevels
        remainingAutoCalculated
        useTaskHierarchy
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "EnableAndConvertSubtasksForProjectInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "taskLevels",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "remainingAutoCalculated",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "useTaskHierarchy",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EnableAndConvertSubtasksForProjectPayload",
    "kind": "LinkedField",
    "name": "enableAndConvertSubtasksForProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectType",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectTypeEdge",
        "kind": "LinkedField",
        "name": "projectEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnableAndConvertSubtasksForProjectMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnableAndConvertSubtasksForProjectMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "EnableAndConvertSubtasksForProjectMutation",
    "operationKind": "mutation",
    "text": "mutation EnableAndConvertSubtasksForProjectMutation(\n  $input: EnableAndConvertSubtasksForProjectInput!\n) {\n  enableAndConvertSubtasksForProject(input: $input) {\n    project {\n      id\n      taskLevels\n      remainingAutoCalculated\n      useTaskHierarchy\n    }\n    projectEdge {\n      node {\n        id\n        taskLevels\n        remainingAutoCalculated\n        useTaskHierarchy\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '04084c838e3619b9c90aff1e914302de';

module.exports = node;
