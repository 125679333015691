import {omit} from 'lodash';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateStatusColumnMutation($input: UpdateStatusColumnInput!) {
		updateStatusColumn(input: $input) {
			errors
			statusColumnV2 {
				id
				name
				category
				autoShowOnTimePage
				encourageTimeRegistration
				order
			}
			statusColumnsV2(first: 1000000) @connection(key: "Project_statusColumnsV2", filters: []) {
				edges {
					node {
						id
						order
					}
				}
			}
			projects(first: 1000000) @connection(key: "ProjectGroup_projects", filters: []) {
				edges {
					node {
						statusColumnsV2(first: 1000000) @connection(key: "Project_statusColumnsV2", filters: []) {
							edges {
								node {
									id
									order
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}
function getOptimisticResponse(input) {
	const obj = {id: input.id};

	if (input.name) {
		obj.name = input.name;
	}
	if (input.category) {
		obj.category = input.category;
	}
	if (input.order !== undefined && input.order !== null) {
		//check if not undefined and not null so it also sets new order if 0
		obj.order = input.isIncrementingOrder ? input.order + 0.5 : input.order - 0.5;
	}
	if (input.autoShowOnTimePage !== undefined && input.autoShowOnTimePage !== null) {
		obj.autoShowOnTimePage = input.autoShowOnTimePage;
	}
	if (input.encourageTimeRegistration !== undefined && input.encourageTimeRegistration !== null) {
		obj.encourageTimeRegistration = input.encourageTimeRegistration;
	}
	return {
		updateStatusColumn: {
			statusColumnV2: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];
	for (const key of Object.keys(input)) {
		//ADD projectId, projectGroupId also here if used by the mutation
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}
		changes.push(key);
	}

	tracking.trackEvent('StatusColumn Updated', {_Changed: changes});

	const cleanedVariables = omit(input, ['isIncrementingOrder']);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
