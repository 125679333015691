/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetADOProjectSettingsInput = {|
  projectId?: ?string,
  vstsProject?: ?string,
  vstsArea?: ?string,
  vstsAccount?: ?string,
  vstsSyncBugs?: ?boolean,
  vstsUseEpics?: ?boolean,
  vstsTwoWaySync?: ?boolean,
  vstsUseNewSubtasks?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type SetADOProjectSettingsMutationVariables = {|
  input: SetADOProjectSettingsInput
|};
export type SetADOProjectSettingsMutationResponse = {|
  +setADOProjectSettings: ?{|
    +project: ?{|
      +vstsProject: ?string,
      +vstsAccount: ?string,
      +vstsArea: ?string,
      +vstsSyncBugs: ?boolean,
      +vstsUseEpics: ?boolean,
      +vstsTwoWaySync: ?boolean,
      +vstsUseNewSubtasks: ?boolean,
    |}
  |}
|};
export type SetADOProjectSettingsMutation = {|
  variables: SetADOProjectSettingsMutationVariables,
  response: SetADOProjectSettingsMutationResponse,
|};
*/


/*
mutation SetADOProjectSettingsMutation(
  $input: SetADOProjectSettingsInput!
) {
  setADOProjectSettings(input: $input) {
    project {
      vstsProject
      vstsAccount
      vstsArea
      vstsSyncBugs
      vstsUseEpics
      vstsTwoWaySync
      vstsUseNewSubtasks
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetADOProjectSettingsInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vstsProject",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vstsAccount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vstsArea",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vstsSyncBugs",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vstsUseEpics",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vstsTwoWaySync",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vstsUseNewSubtasks",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetADOProjectSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetADOProjectSettingsPayload",
        "kind": "LinkedField",
        "name": "setADOProjectSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetADOProjectSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetADOProjectSettingsPayload",
        "kind": "LinkedField",
        "name": "setADOProjectSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SetADOProjectSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation SetADOProjectSettingsMutation(\n  $input: SetADOProjectSettingsInput!\n) {\n  setADOProjectSettings(input: $input) {\n    project {\n      vstsProject\n      vstsAccount\n      vstsArea\n      vstsSyncBugs\n      vstsUseEpics\n      vstsTwoWaySync\n      vstsUseNewSubtasks\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '23bd3eaaca5d5d20f1c957619bf9dff4';

module.exports = node;
