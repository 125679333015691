import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
import {addEdgeToConnection, getStoreNodeById, mutateAllEdgesInConnection} from '../relay-helpers/RelayCacheHelpers';

const mutation = graphql`
	mutation createProjectPersonMutation($input: CreateProjectPersonInput!) {
		createProjectPerson(input: $input) {
			errors
			projectPersons {
				id
				person {
					id
				}
				project {
					id
				}
			}
			projectPersonsConnection {
				edges {
					node {
						id
						isContactPerson
						relationType
						role {
							id
							name
						}
						person {
							id
							firstName
							lastName
							fullName
							initials
							profiles(first: 10000) {
								edges {
									node {
										id
										name
									}
								}
							}
							permissions
							email
							profilePictureId
							profilePictureDefaultId
							active
							isViewer
							harvestUser
							role {
								id
								name
							}
							personLabels(first: 1000) {
								edges {
									node {
										id
										label {
											id
											name
										}
									}
								}
							}
						}
						project {
							id
						}
					}
				}
			}
			projectPerson {
				node {
					id
					isContactPerson
					relationType
					role {
						id
						name
					}
					person {
						id
						firstName
						lastName
						fullName
						initials
						profiles(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
						permissions
						email
						profilePictureId
						profilePictureDefaultId
						active
						isViewer
						harvestUser
						role {
							id
							name
						}
						personLabels(first: 1000) {
							edges {
								node {
									id
									label {
										id
										name
									}
								}
							}
						}
					}
				}
			}
			project {
				id
				projectPersons(first: 1000000) {
					edges {
						node {
							id
							isContactPerson
							relationType
							role {
								id
								name
							}
							person {
								id
								firstName
								lastName
								fullName
								initials
								profiles(first: 10000) {
									edges {
										node {
											id
											name
										}
									}
								}
								permissions
								email
								profilePictureId
								profilePictureDefaultId
								active
								isViewer
								harvestUser
								role {
									id
									name
								}
								personLabels(first: 1000) {
									edges {
										node {
											id
											label {
												id
												name
											}
										}
									}
								}
							}
						}
					}
				}
			}
			projectGroup {
				id
				projects(first: 100000) {
					edges {
						node {
							projectPersons(first: 10000, excludeClientUsers: true) {
								edges {
									node {
										id
										isContactPerson
										relationType
										role {
											id
											name
										}
										person {
											id
											firstName
											lastName
											fullName
											initials
											profiles(first: 10000) {
												edges {
													node {
														id
														name
													}
												}
											}
											permissions
											email
											profilePictureId
											profilePictureDefaultId
											active
											isViewer
											harvestUser
											role {
												id
												name
											}
											personLabels(first: 1000) {
												edges {
													node {
														id
														label {
															id
															name
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	const configs = [];

	if (input.projectId) {
		configs.push({
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_projectPersons',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'projectPerson',
		});
	}

	if (input.projectGroupId) {
		configs.push({
			type: 'RANGE_ADD',
			parentID: input.projectGroupId,
			connectionInfo: [
				{
					key: 'ProjectGroup_projectGroupPersons',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'projectPerson',
		});
	}

	return configs;
}

function updater(store, input, payload) {
	// If the mutation is called to add a projectPerson to a projectGroup, add the projectPerson edge to all projects in that projectGroup
	if (input.projectGroupId) {
		const createdProjectPersonEdges = payload.getLinkedRecord('projectPersonsConnection')?.getLinkedRecords('edges');

		// The projectPersons created by the mutation have to be individually assigned to the project they were created for
		const addProjectPersonToAppropriateProject = projectNode => {
			const projectId = projectNode.getValue('id');
			const createdProjectPersonEdge = createdProjectPersonEdges.find(edge => {
				const projectPersonProjectId = edge.getLinkedRecord('node')?.getLinkedRecord('project')?.getValue('id');
				return projectId === projectPersonProjectId;
			});
			if (createdProjectPersonEdge) {
				addEdgeToConnection({
					parentNode: projectNode,
					connectionName: 'Project_projectPersons',
					newEdge: createdProjectPersonEdge,
				});
			}
		};

		const projectGroupNode = getStoreNodeById({store, id: input.projectGroupId});
		if (createdProjectPersonEdges && projectGroupNode) {
			mutateAllEdgesInConnection({
				parentNode: projectGroupNode,
				connectionName: 'ProjectGroup_projects',
				callback: addProjectPersonToAppropriateProject,
			});
		}
	}
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Project Person Added');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		updater: store => {
			const payload = store.getRootField('createProjectPerson');
			updater(store, input, payload);
		},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
