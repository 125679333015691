/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation SaveReportAsMutation(
  $input: SaveReportAsInput!
) {
  saveReportAs(input: $input) {
    savedReport {
      id
      updatedAt
      updatedBy {
        id
        fullName
      }
      type
      name
      person {
        id
        active
        email
        fullName
        profilePictureId
      }
      groupingOne
      groupingTwo
      groupingThree
      groupingFour
      eyeApplied
      filterApplied
      filters {
        field
        value
        operator
        id
      }
      startDate
      endDate
      periodType
      periodDate
      reportService
      privateAccess
      state
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "SaveReportAsInput!"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "updatedBy",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            (v4 /*: any*/)
        ],
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
            },
            (v4 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePictureId",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "groupingOne",
        "storageKey": null
    }, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "groupingTwo",
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "groupingThree",
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "groupingFour",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "eyeApplied",
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filterApplied",
        "storageKey": null
    }, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "field",
        "storageKey": null
    }, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v17 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "operator",
        "storageKey": null
    }, v18 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
    }, v19 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endDate",
        "storageKey": null
    }, v20 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "periodType",
        "storageKey": null
    }, v21 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "periodDate",
        "storageKey": null
    }, v22 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reportService",
        "storageKey": null
    }, v23 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "privateAccess",
        "storageKey": null
    }, v24 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SaveReportAsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SaveReportAsPayload",
                    "kind": "LinkedField",
                    "name": "saveReportAs",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SavedReportType",
                            "kind": "LinkedField",
                            "name": "savedReport",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReportFilterType",
                                    "kind": "LinkedField",
                                    "name": "filters",
                                    "plural": true,
                                    "selections": [
                                        (v15 /*: any*/),
                                        (v16 /*: any*/),
                                        (v17 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v18 /*: any*/),
                                (v19 /*: any*/),
                                (v20 /*: any*/),
                                (v21 /*: any*/),
                                (v22 /*: any*/),
                                (v23 /*: any*/),
                                (v24 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SaveReportAsMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SaveReportAsPayload",
                    "kind": "LinkedField",
                    "name": "saveReportAs",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SavedReportType",
                            "kind": "LinkedField",
                            "name": "savedReport",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                (v9 /*: any*/),
                                (v10 /*: any*/),
                                (v11 /*: any*/),
                                (v12 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ReportFilterType",
                                    "kind": "LinkedField",
                                    "name": "filters",
                                    "plural": true,
                                    "selections": [
                                        (v15 /*: any*/),
                                        (v16 /*: any*/),
                                        (v17 /*: any*/),
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v18 /*: any*/),
                                (v19 /*: any*/),
                                (v20 /*: any*/),
                                (v21 /*: any*/),
                                (v22 /*: any*/),
                                (v23 /*: any*/),
                                (v24 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "SaveReportAsMutation",
            "operationKind": "mutation",
            "text": "mutation SaveReportAsMutation(\n  $input: SaveReportAsInput!\n) {\n  saveReportAs(input: $input) {\n    savedReport {\n      id\n      updatedAt\n      updatedBy {\n        id\n        fullName\n      }\n      type\n      name\n      person {\n        id\n        active\n        email\n        fullName\n        profilePictureId\n      }\n      groupingOne\n      groupingTwo\n      groupingThree\n      groupingFour\n      eyeApplied\n      filterApplied\n      filters {\n        field\n        value\n        operator\n        id\n      }\n      startDate\n      endDate\n      periodType\n      periodDate\n      reportService\n      privateAccess\n      state\n    }\n  }\n}\n"
        }
    };
})();
node.hash = 'e5966d7be64207f1fd3d543557d5b9ed';
export default node;
