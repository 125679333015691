import {graphql} from 'react-relay';
import {omit} from 'lodash';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteFilterMutation($input: DeleteFilterInput!) {
		deleteFilter(input: $input) {
			deletedFilterId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.viewerId,
			connectionKeys: [
				{
					key: 'Viewer_filters',
				},
			],
			pathToConnection: ['viewer', 'filters'],
			deletedIDFieldName: 'deletedFilterId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Saved Filter Deleted');
	const cleanedVariables = omit(input, ['viewerId']);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
