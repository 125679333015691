import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateSkillPersonMutation($input: UpdateSkillPersonInput!) {
		updateSkillPerson(input: $input) {
			viewer {
				...settingsProfileViewer_viewer
			}
			person {
				id
				createdAt
				createdBy
				harvestUser
				economicUser
				initials
				gitlabUser {
					integrationUserId
				}
				gitlabServerUser {
					integrationUserId
				}
				githubUser {
					integrationUserId
				}
				jiraServerUser
				jiraCloudUser
				asanaUser
				adoUserId
				unit4User
				msTeamsId
				bambooHRId
				firstName
				lastName
				fullName
				email
				startDate
				endDate
				profilePictureId
				profilePictureDefaultId
				role {
					id
					name
				}
				skillPersons {
					skill {
						id
					}
					level {
						id
					}
				}
				permissions
				active
				invited
				isViewer
				costPeriods(first: 10000) @connection(key: "Person_costPeriods", filters: []) {
					edges {
						node {
							id
							startDay
							startMonth
							startYear
							cost
						}
					}
				}
				department {
					id
					name
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Skill Person Updated');

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
