import { commitMutation, graphql } from 'react-relay';
import Util from '../../../../forecast-app/shared/util/util';
const mutation = graphql `
	mutation CreateAdoUsersMutation($input: CreateAdoUsersInput!) {
		createAdoUsers(input: $input) {
			company {
				allPersons(first: 10000, excludeClientUsers: true) @connection(key: "Company_allPersons", filters: []) {
					edges {
						node {
							id
							firstName
							lastName
							initials
							fullName
							active
							email
							adoUserId
							adoUserId
							permissions
							cost
							costPeriods(first: 10000) @connection(key: "Person_costPeriods", filters: []) {
								edges {
									node {
										id
										startDay
										startMonth
										startYear
										cost
									}
								}
							}
							profiles(first: 10000) {
								edges {
									node {
										id
										name
									}
								}
							}
						}
					}
				}
			}
			errors
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return commitMutation(environment, {
        mutation,
        variables,
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
