/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProgramMemberRoleInput = {|
  memberId: string,
  role?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateProgramMemberRoleMutationVariables = {|
  input: UpdateProgramMemberRoleInput
|};
export type updateProgramMemberRoleMutationResponse = {|
  +updateProgramMemberRole: ?{|
    +program: ?{|
      +id: string,
      +name: ?string,
      +description: ?string,
      +prefix: ?string,
      +color: ?string,
      +startDate: ?string,
      +endDate: ?string,
      +members: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +role: ?string,
            +person: ?{|
              +id: string,
              +profilePictureId: ?string,
              +fullName: ?string,
              +email: ?string,
              +initials: ?string,
            |},
          |}
        |}>
      |},
    |},
    +member: ?{|
      +node: ?{|
        +id: string,
        +role: ?string,
        +person: ?{|
          +id: string,
          +profilePictureId: ?string,
          +fullName: ?string,
          +email: ?string,
          +initials: ?string,
        |},
      |}
    |},
  |}
|};
export type updateProgramMemberRoleMutation = {|
  variables: updateProgramMemberRoleMutationVariables,
  response: updateProgramMemberRoleMutationResponse,
|};
*/


/*
mutation updateProgramMemberRoleMutation(
  $input: UpdateProgramMemberRoleInput!
) {
  updateProgramMemberRole(input: $input) {
    program {
      id
      name
      description
      prefix
      color
      startDate
      endDate
      members(first: 10000) {
        edges {
          node {
            id
            role
            person {
              id
              profilePictureId
              fullName
              email
              initials
            }
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    member {
      node {
        id
        role
        person {
          id
          profilePictureId
          fullName
          email
          initials
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProgramMemberRoleInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefix",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initials",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectServiceProgramMemberTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectServiceProgramMemberType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectServiceProgramMemberTypeEdge",
  "kind": "LinkedField",
  "name": "member",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectServiceProgramMemberType",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateProgramMemberRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProgramMemberRolePayload",
        "kind": "LinkedField",
        "name": "updateProgramMemberRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": "members",
                "args": null,
                "concreteType": "ProjectServiceProgramMemberTypeConnection",
                "kind": "LinkedField",
                "name": "__Program_members_connection",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateProgramMemberRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProgramMemberRolePayload",
        "kind": "LinkedField",
        "name": "updateProgramMemberRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "ProjectServiceProgramMemberTypeConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": "members(first:10000)"
              },
              {
                "alias": null,
                "args": (v13/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Program_members",
                "kind": "LinkedHandle",
                "name": "members"
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "updateProgramMemberRole",
            "program",
            "members"
          ]
        }
      ]
    },
    "name": "updateProgramMemberRoleMutation",
    "operationKind": "mutation",
    "text": "mutation updateProgramMemberRoleMutation(\n  $input: UpdateProgramMemberRoleInput!\n) {\n  updateProgramMemberRole(input: $input) {\n    program {\n      id\n      name\n      description\n      prefix\n      color\n      startDate\n      endDate\n      members(first: 10000) {\n        edges {\n          node {\n            id\n            role\n            person {\n              id\n              profilePictureId\n              fullName\n              email\n              initials\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    member {\n      node {\n        id\n        role\n        person {\n          id\n          profilePictureId\n          fullName\n          email\n          initials\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5f3fef9d467ed37de1532a6102b982d2';

module.exports = node;
