import Util from '../../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';
import RelayEnvironment from '../../RelayEnvironment';

const mutation = graphql`
	mutation disableModuleMutation($input: DisableModuleInput!) {
		disableModule(input: $input) {
			deletedModuleId
		}
	}
`;

function getConfigs(module) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: module.companyId,
			connectionKeys: [
				{
					key: 'companiesWithModules_modules',
				},
			],
			pathToConnection: ['companiesWithModules', 'modules'],
			deletedIDFieldName: 'deletedModuleId',
		},
		{
			type: 'NODE_DELETE',
			deletedIDFieldName: 'deletedModuleId',
		},
	];
}

function commit(environment, module, onSuccess, onError) {
	const variables = {
		input: {
			csrfToken: Util.getCsrfValue(),
			socketClientId: Util.getClientId(),
			moduleId: module.id,
			datacenter: module.datacenter,
		},
	};
	return commitMutation(RelayEnvironment.getInstanceMC(), {
		mutation,
		variables,
		configs: getConfigs(module),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
