import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteCommentMutation($input: DeleteCommentInput!) {
		deleteComment(input: $input) {
			deletedCommentId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.taskId,
			connectionKeys: [
				{
					key: 'Task_comments',
				},
			],
			pathToConnection: ['task', 'comments'],
			deletedIDFieldName: 'deletedCommentId',
		},
		{
			type: 'NODE_DELETE',
			deletedIDFieldName: 'deletedCommentId',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		deleteComment: {
			deletedCommentId: input.id,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Comment Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
