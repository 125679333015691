/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DuplicateRateCardInput = {|
  rateCardId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type duplicateRateCardMutationModernMutationVariables = {|
  input: DuplicateRateCardInput
|};
export type duplicateRateCardMutationModernMutationResponse = {|
  +duplicateRateCard: ?{|
    +rateCard: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +defaultRate: ?number,
        +parentRateCardId: ?string,
        +currency: ?string,
        +disabledRoles: ?$ReadOnlyArray<?{|
          +id: string
        |}>,
        +rates: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +rate: ?number,
              +defaultRate: ?boolean,
              +startDate: ?string,
              +role: ?{|
                +id: string
              |},
            |}
          |}>
        |},
      |}
    |}
  |}
|};
export type duplicateRateCardMutationModernMutation = {|
  variables: duplicateRateCardMutationModernMutationVariables,
  response: duplicateRateCardMutationModernMutationResponse,
|};
*/


/*
mutation duplicateRateCardMutationModernMutation(
  $input: DuplicateRateCardInput!
) {
  duplicateRateCard(input: $input) {
    rateCard {
      node {
        id
        name
        defaultRate
        parentRateCardId
        currency
        disabledRoles {
          id
        }
        rates(first: 10000) {
          edges {
            node {
              id
              rate
              defaultRate
              startDate
              role {
                id
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DuplicateRateCardInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultRate",
  "storageKey": null
},
v3 = [
  (v1/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DuplicateRateCardPayload",
    "kind": "LinkedField",
    "name": "duplicateRateCard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RateCardTypeEdge",
        "kind": "LinkedField",
        "name": "rateCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RateCard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentRateCardId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "disabledRoles",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10000
                  }
                ],
                "concreteType": "RateTypeConnection",
                "kind": "LinkedField",
                "name": "rates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RateTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Rate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rate",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "role",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "rates(first:10000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "duplicateRateCardMutationModernMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "duplicateRateCardMutationModernMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "duplicateRateCardMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation duplicateRateCardMutationModernMutation(\n  $input: DuplicateRateCardInput!\n) {\n  duplicateRateCard(input: $input) {\n    rateCard {\n      node {\n        id\n        name\n        defaultRate\n        parentRateCardId\n        currency\n        disabledRoles {\n          id\n        }\n        rates(first: 10000) {\n          edges {\n            node {\n              id\n              rate\n              defaultRate\n              startDate\n              role {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ce94334e0b676243feb17884ef2e1099';

module.exports = node;
