/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateXeroVerificationKeyInput = {|
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createXeroVerificationKeyMutationVariables = {|
  input: CreateXeroVerificationKeyInput
|};
export type createXeroVerificationKeyMutationResponse = {|
  +createXeroVerificationKey: ?{|
    +key: ?string
  |}
|};
export type createXeroVerificationKeyMutation = {|
  variables: createXeroVerificationKeyMutationVariables,
  response: createXeroVerificationKeyMutationResponse,
|};
*/


/*
mutation createXeroVerificationKeyMutation(
  $input: CreateXeroVerificationKeyInput!
) {
  createXeroVerificationKey(input: $input) {
    key
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateXeroVerificationKeyInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateXeroVerificationKeyPayload",
    "kind": "LinkedField",
    "name": "createXeroVerificationKey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createXeroVerificationKeyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createXeroVerificationKeyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createXeroVerificationKeyMutation",
    "operationKind": "mutation",
    "text": "mutation createXeroVerificationKeyMutation(\n  $input: CreateXeroVerificationKeyInput!\n) {\n  createXeroVerificationKey(input: $input) {\n    key\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b956d39804506461eb90c404cfda02cd';

module.exports = node;
