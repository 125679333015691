/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkChangeAccessForSavedReportInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  privateAccess?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type bulkChangeAccessForSavedReportMutationVariables = {|
  input: BulkChangeAccessForSavedReportInput
|};
export type bulkChangeAccessForSavedReportMutationResponse = {|
  +bulkChangeAccessForSavedReport: ?{|
    +savedReports: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +privateAccess: ?boolean,
        |}
      |}>
    |}
  |}
|};
export type bulkChangeAccessForSavedReportMutation = {|
  variables: bulkChangeAccessForSavedReportMutationVariables,
  response: bulkChangeAccessForSavedReportMutationResponse,
|};
*/


/*
mutation bulkChangeAccessForSavedReportMutation(
  $input: BulkChangeAccessForSavedReportInput!
) {
  bulkChangeAccessForSavedReport(input: $input) {
    savedReports {
      edges {
        node {
          id
          privateAccess
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "BulkChangeAccessForSavedReportInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BulkChangeAccessForSavedReportPayload",
    "kind": "LinkedField",
    "name": "bulkChangeAccessForSavedReport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SavedReportTypeConnection",
        "kind": "LinkedField",
        "name": "savedReports",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedReportTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SavedReportType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privateAccess",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bulkChangeAccessForSavedReportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bulkChangeAccessForSavedReportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "bulkChangeAccessForSavedReportMutation",
    "operationKind": "mutation",
    "text": "mutation bulkChangeAccessForSavedReportMutation(\n  $input: BulkChangeAccessForSavedReportInput!\n) {\n  bulkChangeAccessForSavedReport(input: $input) {\n    savedReports {\n      edges {\n        node {\n          id\n          privateAccess\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8a9ef9a24046815c093d76d192f85a45';

module.exports = node;
