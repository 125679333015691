import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateCustomFieldDefinitionMutation($input: UpdateCustomFieldDefinitionInput!) {
		updateCustomFieldDefinition(input: $input) {
			customFieldDefinition {
				node {
					id
					key
					displayName
					readOnly
					entityType
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [];
}

function getOptimisticResponse(input) {
	return {
		updateCustomFieldDefinition: {
			customFieldDefinition: {
				node: {
					id: input.id,
					key: undefined,
					displayName: input.displayName,
					readOnly: input.readOnly,
					entityType: undefined,
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('CustomFieldDefinition - Updated');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
