import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createStatusColumnMutation($input: CreateStatusColumnInput!) {
		createStatusColumn(input: $input) {
			statusColumnV2 {
				node {
					id
					projectGroupStatusColumnId
					order
					userActions {
						canRename
						canCreateTask
						canDelete
						canDeleteWithTasks
					}
				}
			}
			projectGroup {
				id
				projects(first: 1000000) @connection(key: "ProjectGroup_projects", filters: []) {
					edges {
						node {
							statusColumnsV2(first: 1000000) @connection(key: "Project_statusColumnsV2", filters: []) {
								edges {
									node {
										id
										name
										order
										category
										projectGroupStatusColumnId
										encourageTimeRegistration
										jiraStatusId
										adoState
										userActions {
											canRename
											canCreateTask
											canDelete
											canDeleteWithTasks
										}
									}
								}
							}
						}
					}
				}
			}
			project {
				id
				statusColumnsV2(first: 1000000) @connection(key: "Project_statusColumnsV2", filters: []) {
					edges {
						node {
							id
							name
							order
							category
							encourageTimeRegistration
							jiraStatusId
							adoState
							userActions {
								canRename
								canCreateTask
								canDelete
								canDeleteWithTasks
							}
						}
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('StatusColumn Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
