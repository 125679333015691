import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation deleteVstsAccountMutation($input: DeleteVSTSAccountInput!) {
		deleteVSTSAccount(input: $input) {
			viewer {
				company {
					vstsEnabled
					vstsAccount
					vstsEstimateField
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
