import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createPlaceholderMutation($input: CreatePlaceholderInput!) {
		createPlaceholder(input: $input) {
			placeholder {
				node {
					id
					name
					role {
						id
						name
					}
					project {
						id
					}
					projectGroupId
					skills {
						edges {
							node {
								id
							}
						}
					}
					skillPlaceholders {
						skill {
							id
						}
						level {
							id
						}
					}
					departmentId
					teamPlaceholders {
						teamId
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_placeholders',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'placeholder',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		createPlaceholder: {
			placeholder: {
				node: {
					id: 'new',
					name: input.name,
					role: {
						id: input.roleId,
						name: 'new',
					},
					project: {
						id: input.projectId,
					},
					projectGroupId: input.projectGroupId,
					skills: {
						edges: input.skillPlaceholders.map(skillPlaceholder => ({
							node: {
								id: skillPlaceholder.skillId,
							},
						})),
					},
					skillPlaceholders: input.skillPlaceholders.map(skillPlaceholder => ({
						id: 'new',
						skill: {
							id: skillPlaceholder.skillId,
						},
						level: {
							id: skillPlaceholder.skillLevelId,
						},
					})),
					departmentId: input.departmentId,
					teamPlaceholders: input.teamIds.map(teamId => ({
						teamId,
					})),
				},
			},
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const createdWithSkills = input.skillPlaceholders?.length > 0;

	const trackingOptions = {
		createdWithSkills,
	};

	tracking.trackEvent('Placeholder Created', trackingOptions);

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(cleanedInput),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
