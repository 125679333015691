/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateProjectProgressInput = {|
  projectId: string,
  progress: number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createProjectProgressMutationVariables = {|
  input: CreateProjectProgressInput
|};
export type createProjectProgressMutationResponse = {|
  +createProjectProgress: ?{|
    +project: ?{|
      +manualProgressOnProjectEnabled: ?boolean,
      +progress: ?number,
      +progressDetails: ?{|
        +id: string,
        +progress: ?number,
        +createdAt: ?string,
        +createdBy: ?{|
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
        |},
      |},
      +progressHistory: ?$ReadOnlyArray<?{|
        +id: string,
        +progress: ?number,
        +createdAt: ?string,
        +createdBy: ?{|
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
        |},
      |}>,
    |}
  |}
|};
export type createProjectProgressMutation = {|
  variables: createProjectProgressMutationVariables,
  response: createProjectProgressMutationResponse,
|};
*/


/*
mutation createProjectProgressMutation(
  $input: CreateProjectProgressInput!
) {
  createProjectProgress(input: $input) {
    project {
      manualProgressOnProjectEnabled
      progress
      progressDetails {
        id
        progress
        createdAt
        createdBy {
          firstName
          lastName
          profilePictureId
          id
        }
      }
      progressHistory {
        id
        progress
        createdAt
        createdBy {
          firstName
          lastName
          profilePictureId
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProjectProgressInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualProgressOnProjectEnabled",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Person",
    "kind": "LinkedField",
    "name": "createdBy",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "storageKey": null
  }
],
v10 = [
  (v4/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Person",
    "kind": "LinkedField",
    "name": "createdBy",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createProjectProgressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateProjectProgressPayload",
        "kind": "LinkedField",
        "name": "createProjectProgress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectProgress",
                "kind": "LinkedField",
                "name": "progressDetails",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectProgress",
                "kind": "LinkedField",
                "name": "progressHistory",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createProjectProgressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateProjectProgressPayload",
        "kind": "LinkedField",
        "name": "createProjectProgress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectProgress",
                "kind": "LinkedField",
                "name": "progressDetails",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectProgress",
                "kind": "LinkedField",
                "name": "progressHistory",
                "plural": true,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createProjectProgressMutation",
    "operationKind": "mutation",
    "text": "mutation createProjectProgressMutation(\n  $input: CreateProjectProgressInput!\n) {\n  createProjectProgress(input: $input) {\n    project {\n      manualProgressOnProjectEnabled\n      progress\n      progressDetails {\n        id\n        progress\n        createdAt\n        createdBy {\n          firstName\n          lastName\n          profilePictureId\n          id\n        }\n      }\n      progressHistory {\n        id\n        progress\n        createdAt\n        createdBy {\n          firstName\n          lastName\n          profilePictureId\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'abd5a6f4fda50f2131976e2963821854';

module.exports = node;
