/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateTaskDismissedNotificationInput = {|
  taskIds?: ?$ReadOnlyArray<?string>,
  eventType?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type CreateTaskDismissedNotificationMutationVariables = {|
  input: CreateTaskDismissedNotificationInput
|};
export type CreateTaskDismissedNotificationMutationResponse = {|
  +createTaskDismissedNotification: ?{|
    +tasks: ?$ReadOnlyArray<?{|
      +id: string,
      +taskDismissedNotifications: ?{|
        +taskOverrunPredictionDismissed: ?boolean
      |},
    |}>
  |}
|};
export type CreateTaskDismissedNotificationMutation = {|
  variables: CreateTaskDismissedNotificationMutationVariables,
  response: CreateTaskDismissedNotificationMutationResponse,
|};
*/


/*
mutation CreateTaskDismissedNotificationMutation(
  $input: CreateTaskDismissedNotificationInput!
) {
  createTaskDismissedNotification(input: $input) {
    tasks {
      id
      taskDismissedNotifications {
        taskOverrunPredictionDismissed
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTaskDismissedNotificationInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taskOverrunPredictionDismissed",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTaskDismissedNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateTaskDismissedNotificationPayload",
        "kind": "LinkedField",
        "name": "createTaskDismissedNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "tasks",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskDismissedNotifications",
                "kind": "LinkedField",
                "name": "taskDismissedNotifications",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTaskDismissedNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateTaskDismissedNotificationPayload",
        "kind": "LinkedField",
        "name": "createTaskDismissedNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "tasks",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskDismissedNotifications",
                "kind": "LinkedField",
                "name": "taskDismissedNotifications",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateTaskDismissedNotificationMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTaskDismissedNotificationMutation(\n  $input: CreateTaskDismissedNotificationInput!\n) {\n  createTaskDismissedNotification(input: $input) {\n    tasks {\n      id\n      taskDismissedNotifications {\n        taskOverrunPredictionDismissed\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '909a85709ed011a46c0f6d9afcff6293';

module.exports = node;
