/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateDemoInput = {|
  name?: ?string,
  sharedInCompany?: ?boolean,
  viewDay?: ?number,
  viewMonth?: ?number,
  viewYear?: ?number,
  anonymize?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createDemoMutationVariables = {|
  input: CreateDemoInput
|};
export type createDemoMutationResponse = {|
  +createDemo: ?{|
    +demo: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +trialDemo: ?boolean,
        +sharedInCompany: ?boolean,
        +createdByEmail: ?string,
        +numberOfProjects: ?number,
      |}
    |}
  |}
|};
export type createDemoMutation = {|
  variables: createDemoMutationVariables,
  response: createDemoMutationResponse,
|};
*/


/*
mutation createDemoMutation(
  $input: CreateDemoInput!
) {
  createDemo(input: $input) {
    demo {
      node {
        id
        name
        trialDemo
        sharedInCompany
        createdByEmail
        numberOfProjects
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateDemoInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDemoPayload",
    "kind": "LinkedField",
    "name": "createDemo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DemoTypeEdge",
        "kind": "LinkedField",
        "name": "demo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Demo",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trialDemo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sharedInCompany",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdByEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfProjects",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createDemoMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createDemoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createDemoMutation",
    "operationKind": "mutation",
    "text": "mutation createDemoMutation(\n  $input: CreateDemoInput!\n) {\n  createDemo(input: $input) {\n    demo {\n      node {\n        id\n        name\n        trialDemo\n        sharedInCompany\n        createdByEmail\n        numberOfProjects\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '90b829304fe42a57446746d863fb3495';

module.exports = node;
