import {graphql} from 'react-relay';
import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createRoleMutation($input: CreateRoleInput!) {
		createRole(input: $input) {
			role {
				node {
					...settingsRoles_role
				}
			}
		}
	}
`;

function getConfigs(companyId) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: companyId,
			connectionInfo: [
				{
					key: 'Company_roles',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'role',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Role Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), name: input.name}},
		configs: getConfigs(input.companyId),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
