import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';
const mutation = graphql`
	mutation deleteFileMutationModernMutation($input: DeleteFileInput!) {
		deleteFile(input: $input) {
			deletedFileIds
		}
	}
`;

function getConfigs(input) {
	const config = [];
	if (input.taskId) {
		config.push({
			type: 'RANGE_DELETE',
			parentID: input.taskId,
			connectionKeys: [
				{
					key: 'Task_files',
				},
			],
			pathToConnection: ['task', 'files'],
			deletedIDFieldName: 'deletedFileIds',
		});
	}
	if (input.projectId) {
		config.push({
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_files',
				},
			],
			pathToConnection: ['project', 'files'],
			deletedIDFieldName: 'deletedFileIds',
		});
	}
	if (!input.taskId && !input.projectId && input.expenseItemId) {
		config.push({
			type: 'RANGE_DELETE',
			parentID: input.expenseItemId,
			connectionKeys: [
				{
					key: 'ExpenseItem_files',
				},
			],
			pathToConnection: ['expenseItem', 'files'],
			deletedIDFieldName: 'deletedFileIds',
		});
	}
	config.push({
		type: 'NODE_DELETE',
		deletedIDFieldName: 'deletedFileIds',
	});
	return config;
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('File Deleted');
	const cleanedVariables = omit(input, ['expenseItemId']);
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedVariables}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
