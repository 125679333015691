/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LANGUAGE = "DANISH" | "ENGLISH_EU" | "ENGLISH_UK" | "ENGLISH_US" | "FRENCH" | "POLISH" | "SPANISH" | "%future added value";
export type UpdateViewerInput = {|
  email?: ?string,
  jobTitle?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  phone?: ?string,
  initials?: ?string,
  userType?: ?string,
  roleId?: ?string,
  profileId?: ?string,
  departmentId?: ?string,
  holidayCalendarId?: ?string,
  language?: ?LANGUAGE,
  oldPassword?: ?string,
  newPassword?: ?string,
  labels?: ?$ReadOnlyArray<?string>,
  profilePictureId?: ?string,
  notificationFrequency?: ?number,
  notifyOnProjectStatusChange?: ?boolean,
  notifyOnPersonJoin?: ?boolean,
  notifyOnAssignedProject?: ?boolean,
  notifyOnProjectDeadline?: ?boolean,
  notifyOnAssignedTask?: ?boolean,
  notifyOnAssignedTaskUpdate?: ?boolean,
  notifyOnTaskDeadline?: ?boolean,
  notifyOnMention?: ?boolean,
  notifyOnInvoiceDueDate?: ?boolean,
  notifyOnInvoiceOverdue?: ?boolean,
  notifyOnInvoiceDaysOverdue?: ?boolean,
  notifyOnInvoiceCreatedOrDeleted?: ?boolean,
  notifyOnInvoiceStatusChange?: ?boolean,
  notifyOnInvoiceDateReached?: ?boolean,
  notifyOnInvoicePayment?: ?boolean,
  notifyOnInvoiceDateChanged?: ?boolean,
  notifyOnInvoiceDueDateChanged?: ?boolean,
  notifyOnTaskDescriptionChange?: ?boolean,
  notifyOnTaskTitleChange?: ?boolean,
  notifyOnTaskEstimateChange?: ?boolean,
  notifyOnTaskStartDateChange?: ?boolean,
  notifyOnTaskEndDateChange?: ?boolean,
  notifyOnTaskSprintChange?: ?boolean,
  notifyOnTaskPhaseChange?: ?boolean,
  notifyOnTaskStatusColumnChange?: ?boolean,
  notifyOnTaskProjectChange?: ?boolean,
  notifyOnTaskSubtaskChange?: ?boolean,
  notifyOnTaskSubtaskEstimateChange?: ?boolean,
  notifyOnTaskCommentChange?: ?boolean,
  notifyOnTaskFileChange?: ?boolean,
  notifyOnTaskBugChange?: ?boolean,
  notifyOnTaskBlockedChange?: ?boolean,
  notifyOnTaskRepeatingChange?: ?boolean,
  notifyOnTimeOffManager?: ?boolean,
  notifyOnTimeOffOwner?: ?boolean,
  projectDeadlineNotificationPeriod?: ?number,
  taskDeadlineNotificationPeriod?: ?number,
  invoiceNotificationDaysOverdue?: ?number,
  disableNotifications?: ?boolean,
  notifyInAppOnPersonJoin?: ?boolean,
  notifyInAppOnProjectStatusChange?: ?boolean,
  notifyInAppOnAssignedProject?: ?boolean,
  notifyInAppOnProjectDeadline?: ?boolean,
  notifyInAppOnAssignedTask?: ?boolean,
  notifyInAppOnAssignedTaskUpdate?: ?boolean,
  notifyInAppOnTaskDeadline?: ?boolean,
  notifyInAppOnMention?: ?boolean,
  notifyInAppOnInvoiceDueDate?: ?boolean,
  notifyInAppOnInvoiceOverdue?: ?boolean,
  notifyInAppOnInvoiceDaysOverdue?: ?boolean,
  notifyInAppOnInvoiceCreatedOrDeleted?: ?boolean,
  notifyInAppOnInvoiceStatusChange?: ?boolean,
  notifyInAppOnInvoiceDateReached?: ?boolean,
  notifyInAppOnInvoicePayment?: ?boolean,
  notifyInAppOnInvoiceDateChanged?: ?boolean,
  notifyInAppOnInvoiceDueDateChanged?: ?boolean,
  notifyInAppOnTaskDescriptionChange?: ?boolean,
  notifyInAppOnTaskTitleChange?: ?boolean,
  notifyInAppOnTaskEstimateChange?: ?boolean,
  notifyInAppOnTaskStartDateChange?: ?boolean,
  notifyInAppOnTaskEndDateChange?: ?boolean,
  notifyInAppOnTaskSprintChange?: ?boolean,
  notifyInAppOnTaskPhaseChange?: ?boolean,
  notifyInAppOnTaskStatusColumnChange?: ?boolean,
  notifyInAppOnTaskProjectChange?: ?boolean,
  notifyInAppOnTaskSubtaskChange?: ?boolean,
  notifyInAppOnTaskSubtaskEstimateChange?: ?boolean,
  notifyInAppOnTaskCommentChange?: ?boolean,
  notifyInAppOnTaskFileChange?: ?boolean,
  notifyInAppOnTaskBugChange?: ?boolean,
  notifyInAppOnTaskBlockedChange?: ?boolean,
  notifyInAppOnTaskRepeatingChange?: ?boolean,
  notifyInAppOnTimeOffManager?: ?boolean,
  notifyInAppOnTimeOffOwner?: ?boolean,
  lastSeenInAppNotification?: ?number,
  disableInAppNotifications?: ?boolean,
  disableCelebrationAnimations?: ?boolean,
  projectDeadlineInAppNotificationPeriod?: ?number,
  taskDeadlineInAppNotificationPeriod?: ?number,
  sendNewsletter?: ?boolean,
  monday?: ?number,
  tuesday?: ?number,
  wednesday?: ?number,
  thursday?: ?number,
  friday?: ?number,
  saturday?: ?number,
  sunday?: ?number,
  startDate?: ?string,
  endDate?: ?string,
  timerStartDate?: ?string,
  timerEndDate?: ?string,
  timerTaskId?: ?string,
  cost?: ?number,
  flowSampleStep?: ?string,
  timeLogPersonId?: ?string,
  githubPersonId?: ?string,
  gitlabPersonId?: ?string,
  gitlabServerPersonId?: ?string,
  jiraCloudId?: ?string,
  jiraServerId?: ?string,
  unit4PersonId?: ?string,
  sageIntacctId?: ?string,
  sageIntacctName?: ?string,
  sageIntacctEmail?: ?string,
  sageIntacctLocationId?: ?string,
  adoUserId?: ?string,
  onboardingInitialStep?: ?string,
  onboardingProjectPhasesStep?: ?string,
  onboardingProjectBudgetStep?: ?string,
  onboardingProjectBoardStep?: ?string,
  onboardingProjectOverviewStep?: ?string,
  onboardingTaskModalStep?: ?string,
  onboardingOverviewTimeStep?: ?string,
  setToRunningNotificationLastDismissalDate?: ?string,
  useNewUIWorkflow?: ?boolean,
  savedFiltersJson?: ?string,
  adminPassword?: ?string,
  calendarViewPrivateEvents?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updatePasswordMutationVariables = {|
  input: UpdateViewerInput
|};
export type updatePasswordMutationResponse = {|
  +updateViewer: ?{|
    +errors: ?$ReadOnlyArray<?string>
  |}
|};
export type updatePasswordMutation = {|
  variables: updatePasswordMutationVariables,
  response: updatePasswordMutationResponse,
|};
*/


/*
mutation updatePasswordMutation(
  $input: UpdateViewerInput!
) {
  updateViewer(input: $input) {
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateViewerInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateViewerPayload",
    "kind": "LinkedField",
    "name": "updateViewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updatePasswordMutation",
    "operationKind": "mutation",
    "text": "mutation updatePasswordMutation(\n  $input: UpdateViewerInput!\n) {\n  updateViewer(input: $input) {\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bac4a7651074e6e41a60059cdf1c884c';

module.exports = node;
