/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation CreateBulkClientBillToMutation(
  $input: CreateBulkClientBillToInput!
) {
  createBulkClientBillTo(input: $input) {
    billTos {
      node {
        id
        clientId
        billFrom {
          id
        }
        billTo {
          id
        }
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "CreateBulkClientBillToInput!"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        (v1 /*: any*/)
    ], v3 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "CreateBulkClientBillToPayload",
            "kind": "LinkedField",
            "name": "createBulkClientBillTo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientBillToTypeEdge",
                    "kind": "LinkedField",
                    "name": "billTos",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClientBillTo",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "clientId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BillFrom",
                                    "kind": "LinkedField",
                                    "name": "billFrom",
                                    "plural": false,
                                    "selections": (v2 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BillTo",
                                    "kind": "LinkedField",
                                    "name": "billTo",
                                    "plural": false,
                                    "selections": (v2 /*: any*/),
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CreateBulkClientBillToMutation",
            "selections": (v3 /*: any*/),
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CreateBulkClientBillToMutation",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "CreateBulkClientBillToMutation",
            "operationKind": "mutation",
            "text": "mutation CreateBulkClientBillToMutation(\n  $input: CreateBulkClientBillToInput!\n) {\n  createBulkClientBillTo(input: $input) {\n    billTos {\n      node {\n        id\n        clientId\n        billFrom {\n          id\n        }\n        billTo {\n          id\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = 'b245e38cce4aaab192d3ae619a8deb25';
export default node;
