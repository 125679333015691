import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
// eslint-disable-next-line no-unused-vars
import {has, omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation moveTaskToAnotherProjectMutation($input: UpdateTaskInput!) {
		updateTask(input: $input) {
			errors
			updatedTasksIds
			tasks {
				...DraggableCard_task
				...taskModalV3_task @relay(mask: false)
				project {
					id
					completion
					remaining
					forecast
				}
				assignedPersons {
					id
				}
				billable
				blocked
				bug
				companyTaskId
				deadlineDay
				deadlineMonth
				deadlineYear
				deadlineFrom
				startFrom
				done
				estimateForecast
				estimateForecastMinutes
				highPriority
				id
				role {
					id
				}
				phase {
					id
				}
				sprint {
					id
				}
				statusColumnV2 {
					id
				}
				progress
				startDay
				startMonth
				startYear
				timeLeft
				name
				timeLeft
				progress
				estimateForecast
				estimateForecastMinutes
			}
			taskEdge {
				node {
					...DraggableCard_task
					...taskModalV3_task @relay(mask: false)
					project {
						id
						completion
						remaining
						forecast
					}
					assignedPersons {
						id
					}
					billable
					blocked
					bug
					companyTaskId
					deadlineDay
					deadlineMonth
					deadlineYear
					deadlineFrom
					startFrom
					done
					estimateForecast
					estimateForecastMinutes
					highPriority
					id
					role {
						id
					}
					phase {
						id
					}
					sprint {
						id
					}
					statusColumnV2 {
						id
					}
					progress
					startDay
					startMonth
					startYear
					timeLeft
					name
					timeLeft
					progress
					estimateForecast
					estimateForecastMinutes
				}
			}
		}
	}
`;

function getConfigs(input) {
	const configs = [];

	if (input.previousProjectId) {
		configs.push({
			type: 'RANGE_ADD',
			parentID: input.projectId,
			edgeName: 'taskEdge',
			connectionInfo: [
				{
					key: 'Project_tasks',
					rangeBehavior: 'append',
				},
			],
		});
		configs.push({
			type: 'RANGE_DELETE',
			parentID: input.previousProjectId,
			connectionKeys: [
				{
					key: 'Project_tasks',
				},
			],
			pathToConnection: ['project', 'tasks'],
			deletedIDFieldName: 'updatedTasksIds',
		});

		// This is to handle the new Sprint page V4.
		// It uses two different connections for the sprint task and the backlog.
		if (!input.sprintId) {
			configs.push({
				type: 'RANGE_ADD',
				parentID: input.projectId,
				edgeName: 'taskEdge',
				connectionInfo: [
					{
						key: 'Project_tasks',
						filters: {backlog: true},
						rangeBehavior: 'append',
					},
				],
			});
		}
		configs.push({
			type: 'RANGE_DELETE',
			parentID: input.previousProjectId,
			connectionKeys: [
				{
					key: 'Project_tasks',
					filters: {backlog: true},
				},
			],
			pathToConnection: ['project', 'tasks'],
			deletedIDFieldName: 'updatedTasksIds',
		});
	}
	if (input.filters && has(input, 'viewer.company.id')) {
		let filterConfig = {
			type: 'RANGE_DELETE',
			parentID: input.viewer.company.id,
			connectionKeys: [],
			pathToConnection: ['company', 'allTasks'],
			deletedIDFieldName: 'updatedTasksIds',
		};
		if (input.filters.length > 0) {
			input.filters.forEach(filter => {
				// Dirty hack: Only removes from filter on projectGroupStatusColumnId if projects are not from the same status column, since filter won't be an integer for normal columns. Should probably find a cleaner way to do this.
				if (!isNaN(Number.parseInt(filter)) && input.sameProjectGroup) {
					return;
				}
				filterConfig.connectionKeys.push({
					key: 'Company_allTasks',
					filters: {filterColumnId: filter.toString()},
				});
			});
		}
		configs.push(filterConfig);
	}
	return configs;
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (
			[
				'ids',
				'id',
				'aboveTaskOrder',
				'viewerId',
				'viewerActualId',
				'optimisticTaskOrder',
				'previousAssignedPersons',
			].includes(key) ||
			input[key] === undefined
		) {
			continue;
		}

		if (key === 'aboveTaskId') {
			changes.push('sortOrder');
		} else {
			changes.push(key);
		}
	}
	tracking.trackEvent('Card Updated', {_Changed: changes});
	const configs = getConfigs(input);
	const cleanedInput = omit(input, ['filters', 'viewer', 'sameProjectGroup']);
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
