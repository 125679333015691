/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateInsightShareInput = {|
  insightId?: ?string,
  projectId?: ?string,
  personId?: ?string,
  clientId?: ?string,
  projectGroupId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createInsightShareMutationVariables = {|
  input: CreateInsightShareInput
|};
export type createInsightShareMutationResponse = {|
  +createInsightShare: ?{|
    +insightShare: ?{|
      +node: ?{|
        +id: string,
        +uuid: ?string,
        +password: ?string,
        +project: ?{|
          +id: string,
          +sprints: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
              |}
            |}>
          |},
        |},
        +person: ?{|
          +id: string
        |},
        +client: ?{|
          +id: string
        |},
        +projectGroup: ?{|
          +id: string
        |},
      |}
    |}
  |}
|};
export type createInsightShareMutation = {|
  variables: createInsightShareMutationVariables,
  response: createInsightShareMutationResponse,
|};
*/


/*
mutation createInsightShareMutation(
  $input: CreateInsightShareInput!
) {
  createInsightShare(input: $input) {
    insightShare {
      node {
        id
        uuid
        password
        project {
          id
          sprints(first: 1000) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
        person {
          id
        }
        client {
          id
        }
        projectGroup {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateInsightShareInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateInsightSharePayload",
    "kind": "LinkedField",
    "name": "createInsightShare",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InsightShareTypeEdge",
        "kind": "LinkedField",
        "name": "insightShare",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InsightShare",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "password",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1000
                      }
                    ],
                    "concreteType": "SprintTypeConnection",
                    "kind": "LinkedField",
                    "name": "sprints",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SprintTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Sprint",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "sprints(first:1000)"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Person",
                "kind": "LinkedField",
                "name": "person",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectGroupType",
                "kind": "LinkedField",
                "name": "projectGroup",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createInsightShareMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createInsightShareMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createInsightShareMutation",
    "operationKind": "mutation",
    "text": "mutation createInsightShareMutation(\n  $input: CreateInsightShareInput!\n) {\n  createInsightShare(input: $input) {\n    insightShare {\n      node {\n        id\n        uuid\n        password\n        project {\n          id\n          sprints(first: 1000) {\n            edges {\n              node {\n                id\n                name\n              }\n            }\n          }\n        }\n        person {\n          id\n        }\n        client {\n          id\n        }\n        projectGroup {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e805bd81f3b7763f564cf290d247d0b9';

module.exports = node;
