/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GetSuggestedClientLogoInput = {|
  name?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type getSuggestedClientLogoMutationVariables = {|
  input: GetSuggestedClientLogoInput
|};
export type getSuggestedClientLogoMutationResponse = {|
  +getSuggestedClientLogo: ?{|
    +suggestedLogoSrc: ?string
  |}
|};
export type getSuggestedClientLogoMutation = {|
  variables: getSuggestedClientLogoMutationVariables,
  response: getSuggestedClientLogoMutationResponse,
|};
*/


/*
mutation getSuggestedClientLogoMutation(
  $input: GetSuggestedClientLogoInput!
) {
  getSuggestedClientLogo(input: $input) {
    suggestedLogoSrc
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "GetSuggestedClientLogoInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GetSuggestedClientLogoPayload",
    "kind": "LinkedField",
    "name": "getSuggestedClientLogo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "suggestedLogoSrc",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getSuggestedClientLogoMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getSuggestedClientLogoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "getSuggestedClientLogoMutation",
    "operationKind": "mutation",
    "text": "mutation getSuggestedClientLogoMutation(\n  $input: GetSuggestedClientLogoInput!\n) {\n  getSuggestedClientLogo(input: $input) {\n    suggestedLogoSrc\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b15bcbfe20fe3bad0fa49f10ac29f234';

module.exports = node;
