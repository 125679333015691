import {graphql} from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation CreateProgramStatusMutation($input: CreateProgramStatusInput!) {
		createProgramStatus(input: $input) {
			programStatus {
				node {
					id
					createdAt
					createdBy {
						person {
							fullName
						}
					}
					description
					ragStatus
				}
			}
			program {
				currentProgramStatus {
					id
					createdAt
					createdBy {
						person {
							fullName
						}
					}
					description
					ragStatus
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.programId,
			connectionInfo: [
				{
					key: 'Program_programStatuses',
					rangeBehavior: 'prepend',
				},
			],
			edgeName: 'programStatus',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
