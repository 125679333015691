import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation DeleteSprintGoalMutation($input: DeleteSprintGoalInput!) {
		deleteSprintGoal(input: $input) {
			deletedSprintGoalId
		}
	}
`;

function getConfigs(input) {
	const config = [
		{
			type: 'RANGE_DELETE',
			parentID: input.sprintId,
			connectionKeys: [
				{
					key: 'ProjectGroupSprint_sprintGoals',
				},
			],
			pathToConnection: ['projectGroupSprint', 'sprintGoals'],
			deletedIDFieldName: 'deletedSprintGoalId',
		},
		{
			type: 'RANGE_DELETE',
			parentID: input.sprintId,
			connectionKeys: [
				{
					key: 'Sprint_sprintGoals',
				},
			],
			pathToConnection: ['sprint', 'sprintGoals'],
			deletedIDFieldName: 'deletedSprintGoalId',
		},
	];

	return config;
}

function commit(environment, input, onSuccess, onError) {
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), id: input.id}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
