import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation deactivateJiraCloudMutation($input: DeactivateJiraCloudInput!) {
		deactivateJiraCloud(input: $input) {
			viewer {
				company {
					jiraVerificationKey
					jiraInstallationLinked
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId()}};
	return commitMutation(environment, {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
