/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation OptOutOfFeatureFlagMutation(
  $input: OptOutOfFeatureFlagInput!
) {
  optOutOfFeatureFlag(input: $input) {
    viewer {
      availableFeatureFlags {
        id
        key
      }
      id
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "OptOutOfFeatureFlagInput!"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "FeatureFlag",
        "kind": "LinkedField",
        "name": "availableFeatureFlags",
        "plural": true,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "OptOutOfFeatureFlagMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "OptOutOfFeatureFlagPayload",
                    "kind": "LinkedField",
                    "name": "optOutOfFeatureFlag",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Viewer",
                            "kind": "LinkedField",
                            "name": "viewer",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "OptOutOfFeatureFlagMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "OptOutOfFeatureFlagPayload",
                    "kind": "LinkedField",
                    "name": "optOutOfFeatureFlag",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Viewer",
                            "kind": "LinkedField",
                            "name": "viewer",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "OptOutOfFeatureFlagMutation",
            "operationKind": "mutation",
            "text": "mutation OptOutOfFeatureFlagMutation(\n  $input: OptOutOfFeatureFlagInput!\n) {\n  optOutOfFeatureFlag(input: $input) {\n    viewer {\n      availableFeatureFlags {\n        id\n        key\n      }\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = 'fcbad45137bbafc1923a22c3e7e2c37d';
export default node;
