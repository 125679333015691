import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deletePhaseMutation($input: DeletePhaseInput!) {
		deletePhase(input: $input) {
			deletedPhaseId
			project {
				tasks(first: 10000) {
					edges {
						node {
							phase {
								id
							}
							startDay
							startMonth
							startYear
							startFrom
							deadlineFrom
							deadlineDay
							deadlineMonth
							deadlineYear
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.projectId,
			connectionKeys: [
				{
					key: 'Project_phases',
				},
			],
			pathToConnection: ['project', 'phases'],
			deletedIDFieldName: 'deletedPhaseId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Phase Deleted');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
