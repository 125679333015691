/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCostPeriodInput = {|
  id: string,
  startDay?: ?number,
  startMonth?: ?number,
  startYear?: ?number,
  cost?: ?number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateCostPeriodMutationModernMutationVariables = {|
  input: UpdateCostPeriodInput
|};
export type updateCostPeriodMutationModernMutationResponse = {|
  +updateCostPeriod: ?{|
    +costPeriod: ?{|
      +id: string,
      +startDay: ?number,
      +startMonth: ?number,
      +startYear: ?number,
      +cost: ?number,
    |}
  |}
|};
export type updateCostPeriodMutationModernMutation = {|
  variables: updateCostPeriodMutationModernMutationVariables,
  response: updateCostPeriodMutationModernMutationResponse,
|};
*/


/*
mutation updateCostPeriodMutationModernMutation(
  $input: UpdateCostPeriodInput!
) {
  updateCostPeriod(input: $input) {
    costPeriod {
      id
      startDay
      startMonth
      startYear
      cost
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCostPeriodInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCostPeriodPayload",
    "kind": "LinkedField",
    "name": "updateCostPeriod",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CostPeriod",
        "kind": "LinkedField",
        "name": "costPeriod",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startMonth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cost",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateCostPeriodMutationModernMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateCostPeriodMutationModernMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateCostPeriodMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation updateCostPeriodMutationModernMutation(\n  $input: UpdateCostPeriodInput!\n) {\n  updateCostPeriod(input: $input) {\n    costPeriod {\n      id\n      startDay\n      startMonth\n      startYear\n      cost\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9fb9648cf62acc827cebf3f58d20dc1d';

module.exports = node;
