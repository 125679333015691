import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation DismissSimilarTaskMutation($input: DismissSimilarTaskInput!) {
		dismissSimilarTask(input: $input) {
			task {
				id
				similarTasks(overrunOnly: true) {
					edges {
						node {
							id
							companyTaskId
							name
							estimateForecast
							fullAccessToProject
							project {
								id
								name
								projectColor
								companyProjectId
							}
							timeRegistrations(first: 1000000) {
								edges {
									node {
										minutesRegistered
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getOptimisticResponse(input) {
	const task = {id: input.taskId};

	return {
		dismissSimilarTask: {
			task,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: [],
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
