import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {ConnectionHandler} from 'relay-runtime';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createReportShareMutation($input: CreateReportShareInput!) {
		createReportShare(input: $input) {
			shareEdges {
				node {
					id
					user {
						id
						email
					}
				}
			}
			warnings
		}
	}
`;

function updater(store, input, updatedEdge) {
	const proxy = store.get(input.reportId);
	const connection = ConnectionHandler.getConnection(proxy, 'Report_shares');
	if (connection) {
		ConnectionHandler.insertEdgeAfter(connection, updatedEdge);
	}
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Report Share Created');
	const cleanedInput = omit(input, []);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		updater: store => {
			const payload = store.getRootField('createReportShare');
			const updatedEdges = payload.getLinkedRecords('shareEdges');
			updatedEdges.forEach(edge => updater(store, input, edge));
		},
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
