import Util from '../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation ImportEconomicCustomersMutation($input: ImportEconomicCustomersInput!) {
		importEconomicCustomers(input: $input) {
			company {
				clients(first: 10000) {
					edges {
						node {
							id
							name
							notes
							logoId
							logoDefaultId
							projectCount
							xeroContactId
						}
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(environment, {
		mutation,
		variables,
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
