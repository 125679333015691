import { commitMutation, graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
import { omit } from 'lodash';
const mutation = graphql `
	mutation DeleteClientBillToMutation($input: DeleteClientBillToInput!) {
		deleteClientBillTo(input: $input) {
			deletedClientBillToId
		}
	}
`;
function getConfigs(input) {
    return [
        {
            type: 'RANGE_DELETE',
            parentID: input.clientId,
            connectionKeys: [
                {
                    key: 'Client_clientBillTos',
                },
            ],
            pathToConnection: ['client', 'clientBillTos'],
            deletedIDFieldName: 'deletedClientBillToId',
        },
    ];
}
function getOptimisticResponse(input) {
    return {
        deleteClientBillTo: {
            deletedClientBillToId: input.clientBillToId,
        },
    };
}
function commit(environment, input, onSuccess, onError) {
    trackEvent('Client Bill To', 'Deleted', input);
    const cleanedInput = omit(input, ['clientId']);
    const variables = {
        input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, cleanedInput),
    };
    return commitMutation(environment, {
        mutation,
        variables,
        configs: getConfigs(input),
        optimisticResponse: getOptimisticResponse(input),
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
