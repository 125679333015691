/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCustomFieldValueInput = {|
  id?: ?string,
  entityId?: ?string,
  entityType?: ?string,
  key?: ?string,
  value?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateCustomFieldValueMutationVariables = {|
  input: UpdateCustomFieldValueInput
|};
export type updateCustomFieldValueMutationResponse = {|
  +updateCustomFieldValue: ?{|
    +customFieldValue: ?{|
      +node: ?{|
        +id: string,
        +key: ?string,
        +displayName: ?string,
        +value: ?string,
      |}
    |}
  |}
|};
export type updateCustomFieldValueMutation = {|
  variables: updateCustomFieldValueMutationVariables,
  response: updateCustomFieldValueMutationResponse,
|};
*/


/*
mutation updateCustomFieldValueMutation(
  $input: UpdateCustomFieldValueInput!
) {
  updateCustomFieldValue(input: $input) {
    customFieldValue {
      node {
        id
        key
        displayName
        value
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCustomFieldValueInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCustomFieldValuePayload",
    "kind": "LinkedField",
    "name": "updateCustomFieldValue",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomFieldValueTypeEdge",
        "kind": "LinkedField",
        "name": "customFieldValue",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomFieldValue",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateCustomFieldValueMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateCustomFieldValueMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateCustomFieldValueMutation",
    "operationKind": "mutation",
    "text": "mutation updateCustomFieldValueMutation(\n  $input: UpdateCustomFieldValueInput!\n) {\n  updateCustomFieldValue(input: $input) {\n    customFieldValue {\n      node {\n        id\n        key\n        displayName\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '471d7a9cbd1115cc4ca4cc648127ac61';

module.exports = node;
