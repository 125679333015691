import { commitMutation, graphql } from 'react-relay';
import Util from '../../forecast-app/shared/util/util';
const mutation = graphql `
	mutation StoreCustomMetricMutation($input: StoreCustomMetricInput!) {
		storeCustomMetric(input: $input) {
			clientMutationId
		}
	}
`;
function commit(environment, input, onSuccess, onError) {
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return commitMutation(environment, {
        mutation,
        variables,
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
