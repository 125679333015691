import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createGoogleDriveFileAttachmentMutation($input: CreateGoogleDriveFileAttachmentInput!) {
		createGoogleDriveFileAttachment(input: $input) {
			task {
				latestUiUpdateAt
				latestUiUpdateBy {
					firstName
					lastName
				}
			}
			googleDriveFile {
				node {
					id
					name
					link
					thumb
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.taskId,
			connectionInfo: [
				{
					key: 'Task_googleDriveFiles',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'googleDriveFile',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Google Drive File Attached');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
