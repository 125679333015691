// @ts-check

import {omit, startCase} from 'lodash';
import {commitMutation} from 'relay-runtime';
import {logTrackingError, trackMutation} from './TrackingV2';

/**
 * @typedef {import('./TrackingV2').EventProperties} EventProperties
 * @typedef {import('./TrackingV2').TrackingOutput<EventProperties>} TrackingOutput
 * @typedef {import('react-relay').Environment} Environment
 * @typedef {import('relay-runtime').MutationConfig} MutationConfig
 * @typedef {import('relay-runtime').ConcreteRequest} ConcreteRequest
 * @typedef {import('relay-runtime').Disposable} Disposable
 */

/**
 * Fields that should always be excluded entirely from tracking
 */
const excludedFields = [
	'aboveTaskOrder',
	'aboveFavouredTaskOrder',
	'companyId',
	'companyProjectId',
	'csrfToken',
	'deleteNode',
	'existingAssignedPersons',
	'id',
	'idleTimeId',
	'ids',
	'optimisticColumnId',
	'optimisticSprintId',
	'optimisticTaskOrder',
	'personIds',
	'previousAssignedPersons',
	'prevStatusColumnId',
	'prevProjectGroupStatusColumnId',
	'prevStatusColumnIdMap',
	'projectId',
	'taskId',
	'socketClientId',
	'timeLeftOptimistic',
	'viewerId',
	'viewerActualId',
	'companyId',
	'adminPassword',
	'newPassword',
	'oldPassword',
	'password',
	'publicCreateTaskPassword',
];

/**
 *
 * @param {Object} input
 * @param {Array.<string>} [ignoredChanges=[]]
 * @returns {Object}
 */
const cleanInput = (input, ignoredChanges = []) => {
	const ignoredFields = excludedFields.concat(ignoredChanges);

	return omit(input, ignoredFields);
};

/**
 * Fields that should be excluded from list of changed fields
 */
const excludedChangedFields = ['mutationAffectedCount'];

/**
 * Convert input key to more easy to understand string
 * @param {string} key
 * @returns {string}
 */
const mapKeyToChangedField = key => {
	switch (key) {
		case 'aboveTaskId':
			return 'sortOrder';
		default:
			return key;
	}
};

/**
 * @param {Object} cleanInput
 * @returns {Array.<string>} the formatted list of changes
 */
const getChangesFromMutationInput = cleanInput => {
	return Object.entries(cleanInput)
		.filter(([key, value]) => !excludedChangedFields.includes(key) && value !== undefined)
		.map(([key]) => mapKeyToChangedField(key));
};

/**
 *
 * @param {import('relay-runtime').GraphQLTaggedNode} mutation
 * @returns {mutation is ConcreteRequest}
 */
const typeIsConcreteRequest = mutation => {
	if (mutation) {
		return true;
	}

	return false;
};

/**
 * Trims the " Mutation" ending from mutation names and prepends instead, and converts to Formal Case
 * @param {string} mutationName
 * @returns {string}
 */
const formatMutationName = mutationName => {
	return startCase(mutationName.replace('Mutation', '').replace('ModernMutation', ''));
};

/**
 * @param {string} mutationName
 * @param {Object} input
 * @param {boolean} changesInTrackName
 * @param {Array.<string>} ignoredChanges
 * @param {Object} additionalTracking Key-value pairs to include in mutation tracking without needing it to be included in mutation input
 * @returns {TrackingOutput | Error | undefined}
 */
const mutationTrack = (mutationName, input, changesInTrackName, ignoredChanges, additionalTracking) => {
	if (!mutationName) throw new Error('TrackingError: mutationTrack must be called with mutationName');
	if (!input) throw new Error('TrackingError: mutationTrack must be called with input');

	const extendedInput = {...input, ...additionalTracking};
	const cleanedInput = cleanInput(extendedInput, ignoredChanges);
	const changedFields = getChangesFromMutationInput(cleanedInput);
	const formattedMutationName = formatMutationName(mutationName);

	const changeString = changedFields.map(change => startCase(change)).join(', ');
	const mutationString = `Mutation: ${formattedMutationName} ${changesInTrackName ? `- Changed: ${changeString}` : ''}`;

	const mutationProperties = {
		changedFields,
		input: cleanedInput,
		trackingType: 'Mutation',
		mutationName: formattedMutationName,
	};

	return trackMutation(mutationString, mutationProperties);
};

/**
 * @param {MutationConfig} config
 * @param {Boolean} changesInTrackName
 * @param {Array.<string>} ignoredChanges
 * @param {Object} additionalTracking Key-value pairs to include in mutation tracking without needing it to be included in mutation input
 * @returns {TrackingOutput | Error | undefined} an object containing properties that were tracked to facilitate testing.
 * Optionally contains error if function threw during execution. If input or mutationName is missing from config, returns nothing and doesn't track.
 */
const trackCommit = (config, changesInTrackName, ignoredChanges, additionalTracking) => {
	try {
		const input = config.variables?.input;
		const mutation = config.mutation;
		if (typeIsConcreteRequest(mutation)) {
			// @ts-ignore Mutation name can sometimes be nested under default, which does not exist on the exported MutationConfig type - not sure why
			const mutationName = mutation.default ? mutation.default.fragment?.name : mutation.fragment?.name;
			return mutationTrack(mutationName, input, changesInTrackName, ignoredChanges, additionalTracking);
		}
	} catch (e) {
		logTrackingError(e);
		return e;
	}
};

/**
 * Higher-level helper function to execute a mutation against a specific environment while also sending tracking info about the mutation to Amplitude.
 * Use this function instead of the standard Relay commitMutation if your mutation needs tracking.
 * Allowed params are the same as commitMutation, and additionally a set of properties to be included in tracking.
 * @param {Environment} environment
 * @param {MutationConfig} config
 * @param {boolean} [changesInTrackName=false] set true to include list of changes in tracking name, should primarily be used for mutations that do a bunch of different things
 * @param {Array.<string>} [ignoredChanges=[]] list of keys that will be ignored from change list
 * @param {Object} [additionalTracking={}] Key-value pairs to include in mutation tracking without needing it to be included in mutation input
 * @returns {Disposable}
 */
export const trackAndCommitMutation = (
	environment,
	config,
	changesInTrackName = false,
	ignoredChanges = [],
	additionalTracking = {}
) => {
	trackCommit(config, changesInTrackName, ignoredChanges, additionalTracking);

	return commitMutation(environment, config);
};

/**
 * Function allowing granular control over the mutation name rather than inferring from input to react-relay commitMutation function.
 * This function should generally only be used for mutations sent outside of react-relay or to override the given name of your mutation.
 * @param {string} mutationName object being mutated.
 * @param {Object} [input] the variables supplied to the mutation. If an input is not supplied, a standard tracking event is sent with the mutationObject and mutationAction. If the mutation cleans its variables, supply the cleanedVariable object.
 * @param {boolean} [changesInTrackName=false] set true to include list of changes in tracking name, should primarily be used for mutations that do a bunch of different things
 * @param {Array.<string>} [ignoredChanges=[]] list of keys that will be ignored from change list
 * @param {Object} [additionalTracking={}] Key-value pairs to include in mutation tracking without needing it to be included in mutation input
 * @returns {TrackingOutput | Error | undefined} an object containing properties that were tracked to facilitate testing.
 * Optionally contains error if function threw during execution. If input or mutationName is missing from config, returns nothing and doesn't track.
 */
export const trackCustomNameMutation = (
	mutationName,
	input,
	changesInTrackName = false,
	ignoredChanges = [],
	additionalTracking = {}
) => {
	try {
		return mutationTrack(mutationName, input, changesInTrackName, ignoredChanges, additionalTracking);
	} catch (e) {
		logTrackingError(e);
		return e;
	}
};
