import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation duplicateExpenseItemMutation($input: DuplicateExpenseItemInput!) {
		duplicateExpenseItem(input: $input) {
			expenseItem {
				node {
					id
					category {
						id
						name
					}
					name
					approved
					expenseYear
					expenseMonth
					expenseDay
					billable
					partOfFixedPrice
					invoiced
					price
					cost
					costBaseCurrency
					priceBaseCurrency
					quantity
					name
					planned
					createdAt
					files(first: 1000) @connection(key: "ExpenseItem_files") {
						edges {
							node {
								id
								name
							}
						}
					}
					person {
						id
						fullName
						profilePictureId
						profilePictureDefaultId
					}
					project {
						id
						client {
							id
							name
						}
					}
					category {
						id
					}
					phase {
						id
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	const config = [];
	if (input.projectId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.projectId,
			connectionInfo: [
				{
					key: 'Project_expenseItems',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'expenseItem',
		});
	}
	if (input.companyId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_expenseItems',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'expenseItem',
		});
	}

	if (input.actualPersonId === input.personId || input.viewerId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.viewerId,
			connectionInfo: [
				{
					key: 'Viewer_expenseItems',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'expenseItem',
		});
	}
	return config;
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Expense Item Duplicated');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				expenseItemId: input.expenseItemId,
			},
		},
		configs: getConfigs(input),
		onCompleted,
		onError,
	});
}

export default {commit};
