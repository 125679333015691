import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updatePhaseMutation($input: UpdatePhaseInput!) {
		updatePhase(input: $input) {
			phase {
				name
				startYear
				startMonth
				startDay
				startFrom
				deadlineDay
				deadlineMonth
				deadlineYear
				deadlineFrom
				inheritDatesFrom
			}
			project {
				tasks(first: 10000) {
					edges {
						node {
							startDay
							startMonth
							startYear
							startFrom
							deadlineDay
							deadlineMonth
							deadlineYear
							deadlineFrom
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const obj = {id: input.id};
	if (input.name) {
		obj.name = input.name;
	}
	if (input.deadlineYear) {
		obj.deadlineYear = input.deadlineYear;
		obj.deadlineMonth = input.deadlineMonth;
		obj.deadlineDay = input.deadlineDay;
	}
	if (input.startYear) {
		obj.startYear = input.startYear;
		obj.startMonth = input.startMonth;
		obj.startDay = input.startDay;
	}
	return {
		updatePhase: {
			phase: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];
	for (const key of Object.keys(input)) {
		//ADD projectId, projectGroupId also here if used by the mutation
		if (['id', 'projectId'].includes(key) || input[key] === undefined) {
			continue;
		}
		changes.push(key);
	}

	tracking.trackEvent('Phase Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
