import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {commitMutation, graphql} from 'react-relay';

const mutation = graphql`
	mutation deleteAllTimeRegistrationsForWeekMutation($input: DeleteMultipleTimeRegsInput!) {
		deleteMultipleTimeRegs(input: $input) {
			deletedTimeRegIds
			viewer {
				id
			}
			errors
		}
	}
`;

function getConfigs(input) {
	const config = [
		{
			type: 'RANGE_DELETE',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionKeys: [
				{
					key: 'Viewer_timeRegistrations',
				},
			],
			pathToConnection: ['viewer', 'timeRegistrations'],
			deletedIDFieldName: 'deletedTimeRegIds',
		},
	];
	return config;
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Time for Week Deleted');
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
