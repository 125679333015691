import io from 'socket.io-client';
import uuid from 'uuid';
import {dispatch, EVENT_ID} from '../containers/event_manager';
import Tracking from '../tracking';
import DirectApi from '../directApi';
import {SOCKET_EVENT_TYPE} from '../constants';

// Used for tracking last socket update to not update too much
let lastSocketChange = {time: new Date(2010, 1, 1)};
let lastSocketChange2 = {time: new Date(2010, 1, 1)};
let timer = {timeout: null};
let timer2 = {timeout: null};
let dataList = [];
let dataList2 = [];

class socket_handling {
	// Requires a client id to ignore updates made by current client
	constructor(clientId) {
		this.clientId = clientId;
	}

	connect(socketFullUpdate, actualPersonId) {
		// If already connected, disconnect.
		if (this.socket) {
			this.socket.disconnect();
			return;
		}

		this.socket = io(DirectApi.socketServerEndpoint(), {transports: ['websocket'], upgrade: false});
		//this.socket = io('http://localhost:4000');
		this.socket.on('connect', () => {
			// Try authenticate
			this.socket.emit('authenticate');
		});

		// Update frontend on reconnect
		this.socket.on('reconnect', () => {
			dispatch(EVENT_ID.SOCKET_NOTIFY, []);
		});

		this.socket.on('notify', data => {
			// Skip updates made by current client
			if (data && data.clientId !== this.clientId) {
				// This is to handle impersonations
				if (data.fullUpdate && data.fullUpdate === actualPersonId && socketFullUpdate) {
					Tracking.logout();
					window.location.href = '/';
				}
				// Only update once every 4 / 10 seconds depending on event type.
				// Events from financial service are prioritized in their own queue with a 4 second interval
				if (
					[
						SOCKET_EVENT_TYPE.PROGRAM_FINANCIALS,
						SOCKET_EVENT_TYPE.PROJECT_FINANCIALS,
						SOCKET_EVENT_TYPE.TASK_FINANCIALS,
					].includes(data.eventType)
				) {
					this.doDispatchEvent(data, lastSocketChange2, timer2, dataList2, 4);
				} else {
					this.doDispatchEvent(data, lastSocketChange, timer, dataList, 10);
				}
			}
		});
	}

	doDispatchEvent(data, theLastSocketChange, theTimer, theDataList, intervalInSeconds) {
		// If timer is already running, skip this event
		if (theTimer.timeout !== null) {
			theDataList.push(data);
			return;
		}

		const t = new Date();
		t.setSeconds(t.getSeconds() - intervalInSeconds);
		if (theLastSocketChange.time > t) {
			const timeout = Math.round(theLastSocketChange.time.getTime() - t.getTime());
			// If already updated during the last 10 seconds. Wait.
			theDataList = [data];
			theTimer.timeout = setTimeout(() => {
				theTimer.timeout = null;
				dispatch(EVENT_ID.SOCKET_NOTIFY, theDataList);
				theLastSocketChange.time = new Date();
			}, timeout);
		} else {
			dispatch(EVENT_ID.SOCKET_NOTIFY, [data]);
			theLastSocketChange.time = new Date();
		}
	}

	disconnect() {
		if (this.socket) {
			this.socket.disconnect();
			this.socket = null;
		}
	}

	getClientId() {
		return this.clientId;
	}
}

export default new socket_handling(uuid.v4());
