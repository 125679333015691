import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation CreateSprintGoalMutation($input: CreateSprintGoalInput!) {
		createSprintGoal(input: $input) {
			sprintGoal {
				node {
					id
					description
					statusColor
					stage
				}
			}
		}
	}
`;

function getConfigs(input) {
	const config = [];
	if (input.projectGroupSprintId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.projectGroupSprintId,
			connectionInfo: [
				{
					key: 'ProjectGroupSprint_sprintGoals',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'sprintGoal',
		});
	} else if (input.sprintId) {
		config.push({
			type: 'RANGE_ADD',
			parentID: input.sprintId,
			connectionInfo: [
				{
					key: 'Sprint_sprintGoals',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'sprintGoal',
		});
	}

	return config;
}

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
