/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSisenseJwtInput = {|
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type CreateSisenseJwtMutationVariables = {|
  input: CreateSisenseJwtInput
|};
export type CreateSisenseJwtMutationResponse = {|
  +createSisenseJwt: ?{|
    +jwt: ?string
  |}
|};
export type CreateSisenseJwtMutation = {|
  variables: CreateSisenseJwtMutationVariables,
  response: CreateSisenseJwtMutationResponse,
|};
*/


/*
mutation CreateSisenseJwtMutation(
  $input: CreateSisenseJwtInput!
) {
  createSisenseJwt(input: $input) {
    jwt
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSisenseJwtInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateSisenseJwtPayload",
    "kind": "LinkedField",
    "name": "createSisenseJwt",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jwt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSisenseJwtMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSisenseJwtMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateSisenseJwtMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSisenseJwtMutation(\n  $input: CreateSisenseJwtInput!\n) {\n  createSisenseJwt(input: $input) {\n    jwt\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b35937806e3ee8225d2fa1af12aefe98';

module.exports = node;
