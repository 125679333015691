/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeletePriorityLevelInput = {|
  priorityLevelIdToDelete?: ?string,
  replacementPriorityLevelId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deletePriorityLevelMutationModernMutationVariables = {|
  input: DeletePriorityLevelInput
|};
export type deletePriorityLevelMutationModernMutationResponse = {|
  +deletePriorityLevel: ?{|
    +company: ?{|
      +priorityLevels: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +weight: ?number,
            +useCount: ?number,
          |}
        |}>
      |}
    |}
  |}
|};
export type deletePriorityLevelMutationModernMutation = {|
  variables: deletePriorityLevelMutationModernMutationVariables,
  response: deletePriorityLevelMutationModernMutationResponse,
|};
*/


/*
mutation deletePriorityLevelMutationModernMutation(
  $input: DeletePriorityLevelInput!
) {
  deletePriorityLevel(input: $input) {
    company {
      priorityLevels(first: 1000) {
        edges {
          node {
            id
            name
            weight
            useCount
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeletePriorityLevelInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PriorityLevelTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PriorityLevel",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weight",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "useCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deletePriorityLevelMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePriorityLevelPayload",
        "kind": "LinkedField",
        "name": "deletePriorityLevel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": "priorityLevels",
                "args": null,
                "concreteType": "PriorityLevelTypeConnection",
                "kind": "LinkedField",
                "name": "__Company_priorityLevels_connection",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deletePriorityLevelMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeletePriorityLevelPayload",
        "kind": "LinkedField",
        "name": "deletePriorityLevel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "PriorityLevelTypeConnection",
                "kind": "LinkedField",
                "name": "priorityLevels",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": "priorityLevels(first:1000)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Company_priorityLevels",
                "kind": "LinkedHandle",
                "name": "priorityLevels"
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "deletePriorityLevel",
            "company",
            "priorityLevels"
          ]
        }
      ]
    },
    "name": "deletePriorityLevelMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation deletePriorityLevelMutationModernMutation(\n  $input: DeletePriorityLevelInput!\n) {\n  deletePriorityLevel(input: $input) {\n    company {\n      priorityLevels(first: 1000) {\n        edges {\n          node {\n            id\n            name\n            weight\n            useCount\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a1ed19f86292a550c0e12d3cb613e082';

module.exports = node;
