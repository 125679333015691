/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTaskInput = {|
  ids?: ?$ReadOnlyArray<?string>,
  projectId?: ?string,
  projectGroupId?: ?string,
  parentId?: ?string,
  phaseId?: ?string,
  aboveTaskId?: ?string,
  aboveFavouredTaskId?: ?string,
  name?: ?string,
  description?: ?string,
  estimateLow?: ?number,
  estimateHigh?: ?number,
  forecast?: ?number,
  timeLeft?: ?number,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  deadlineYear?: ?number,
  deadlineMonth?: ?number,
  deadlineDay?: ?number,
  roleId?: ?string,
  assignedPersons?: ?$ReadOnlyArray<?string>,
  unassignAllPersons?: ?boolean,
  assignedPersonToAdd?: ?string,
  followers?: ?$ReadOnlyArray<?string>,
  removeAllFollowers?: ?boolean,
  labels?: ?$ReadOnlyArray<?string>,
  clearAllLabels?: ?boolean,
  sprintId?: ?string,
  projectGroupSprintId?: ?string,
  statusColumnId?: ?string,
  statusColumnV2Id?: ?string,
  projectGroupStatusColumnId?: ?number,
  coverFileId?: ?string,
  blocked?: ?boolean,
  bug?: ?boolean,
  approved?: ?boolean,
  billable?: ?boolean,
  repeatingTaskId?: ?string,
  unassignedTaskId?: ?string,
  previousProjectId?: ?string,
  unassignPersonFromTask?: ?$ReadOnlyArray<?UnassignPersonFromTask>,
  remaining?: ?number,
  highPriority?: ?boolean,
  ownerId?: ?string,
  favoured?: ?boolean,
  favouredSortOrder?: ?string,
  parentTaskId?: ?string,
  previousParentTaskId?: ?string,
  isBulkContext?: ?boolean,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type UnassignPersonFromTask = {|
  taskId: string,
  personIds?: ?$ReadOnlyArray<?string>,
|};
export type reorderTaskMutationModernMutationVariables = {|
  input: UpdateTaskInput
|};
export type reorderTaskMutationModernMutationResponse = {|
  +updateTask: ?{|
    +errors: ?$ReadOnlyArray<?string>,
    +childrenTasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +sprint: ?{|
            +id: string
          |},
          +phase: ?{|
            +id: string
          |},
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +deadlineFrom: ?string,
          +startDay: ?number,
          +startMonth: ?number,
          +startYear: ?number,
          +startFrom: ?string,
        |}
      |}>
    |},
    +tasks: ?$ReadOnlyArray<?{|
      +id: string,
      +progress: ?number,
      +sortOrder: ?number,
      +favouredSortOrder: ?string,
      +phase: ?{|
        +id: string,
        +progress: ?number,
        +progressDetails: ?{|
          +progress: ?number
        |},
      |},
      +parentTaskId: ?string,
      +sprint: ?{|
        +id: string
      |},
      +statusColumnV2: ?{|
        +id: string
      |},
      +deadlineDay: ?number,
      +deadlineMonth: ?number,
      +deadlineYear: ?number,
      +startDay: ?number,
      +startMonth: ?number,
      +startYear: ?number,
      +done: ?boolean,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
      +assignedPersons: ?$ReadOnlyArray<?{|
        +id: string,
        +firstName: ?string,
        +lastName: ?string,
        +active: ?boolean,
        +email: ?string,
        +profilePictureId: ?string,
        +profilePictureDefaultId: ?number,
        +permissions: ?$ReadOnlyArray<?string>,
      |}>,
      +dependsOnThisTask: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +taskDependsOnThis: ?{|
              +id: string,
              +canStart: ?boolean,
              +canBeSetToDone: ?boolean,
            |},
          |}
        |}>
      |},
    |}>,
    +sortOrderTasks: ?$ReadOnlyArray<?{|
      +id: string,
      +sortOrder: ?number,
    |}>,
  |}
|};
export type reorderTaskMutationModernMutation = {|
  variables: reorderTaskMutationModernMutationVariables,
  response: reorderTaskMutationModernMutationResponse,
|};
*/


/*
mutation reorderTaskMutationModernMutation(
  $input: UpdateTaskInput!
) {
  updateTask(input: $input) {
    errors
    childrenTasks {
      edges {
        node {
          id
          sprint {
            id
          }
          phase {
            id
          }
          deadlineDay
          deadlineMonth
          deadlineYear
          deadlineFrom
          startDay
          startMonth
          startYear
          startFrom
        }
      }
    }
    tasks {
      id
      progress
      sortOrder
      favouredSortOrder
      phase {
        id
        progress
        progressDetails {
          progress
          id
        }
      }
      parentTaskId
      sprint {
        id
      }
      statusColumnV2 {
        id
      }
      deadlineDay
      deadlineMonth
      deadlineYear
      startDay
      startMonth
      startYear
      done
      role {
        id
        name
      }
      assignedPersons {
        id
        firstName
        lastName
        active
        email
        profilePictureId
        profilePictureDefaultId
        permissions
      }
      dependsOnThisTask(first: 1000) {
        edges {
          node {
            id
            taskDependsOnThis {
              id
              canStart
              canBeSetToDone
            }
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    sortOrderTasks {
      id
      sortOrder
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTaskInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Sprint",
  "kind": "LinkedField",
  "name": "sprint",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "TaskTypeConnection",
  "kind": "LinkedField",
  "name": "childrenTasks",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PhaseType",
              "kind": "LinkedField",
              "name": "phase",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deadlineFrom",
              "storageKey": null
            },
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startFrom",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortOrder",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "favouredSortOrder",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentTaskId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "StatusColumnV2",
  "kind": "LinkedField",
  "name": "statusColumnV2",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "done",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "assignedPersons",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePictureDefaultId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DependencyTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DependencyType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "taskDependsOnThis",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canBeSetToDone",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Task",
  "kind": "LinkedField",
  "name": "sortOrderTasks",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v23 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reorderTaskMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTaskPayload",
        "kind": "LinkedField",
        "name": "updateTask",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "tasks",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PhaseType",
                "kind": "LinkedField",
                "name": "phase",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PhaseProgress",
                    "kind": "LinkedField",
                    "name": "progressDetails",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v16/*: any*/),
              (v5/*: any*/),
              (v17/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": "dependsOnThisTask",
                "args": null,
                "concreteType": "DependencyTypeConnection",
                "kind": "LinkedField",
                "name": "__Task_dependsOnThisTask_connection",
                "plural": false,
                "selections": (v21/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reorderTaskMutationModernMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTaskPayload",
        "kind": "LinkedField",
        "name": "updateTask",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "tasks",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PhaseType",
                "kind": "LinkedField",
                "name": "phase",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PhaseProgress",
                    "kind": "LinkedField",
                    "name": "progressDetails",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v16/*: any*/),
              (v5/*: any*/),
              (v17/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": (v23/*: any*/),
                "concreteType": "DependencyTypeConnection",
                "kind": "LinkedField",
                "name": "dependsOnThisTask",
                "plural": false,
                "selections": (v21/*: any*/),
                "storageKey": "dependsOnThisTask(first:1000)"
              },
              {
                "alias": null,
                "args": (v23/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "Task_dependsOnThisTask",
                "kind": "LinkedHandle",
                "name": "dependsOnThisTask"
              }
            ],
            "storageKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": null
        }
      ]
    },
    "name": "reorderTaskMutationModernMutation",
    "operationKind": "mutation",
    "text": "mutation reorderTaskMutationModernMutation(\n  $input: UpdateTaskInput!\n) {\n  updateTask(input: $input) {\n    errors\n    childrenTasks {\n      edges {\n        node {\n          id\n          sprint {\n            id\n          }\n          phase {\n            id\n          }\n          deadlineDay\n          deadlineMonth\n          deadlineYear\n          deadlineFrom\n          startDay\n          startMonth\n          startYear\n          startFrom\n        }\n      }\n    }\n    tasks {\n      id\n      progress\n      sortOrder\n      favouredSortOrder\n      phase {\n        id\n        progress\n        progressDetails {\n          progress\n          id\n        }\n      }\n      parentTaskId\n      sprint {\n        id\n      }\n      statusColumnV2 {\n        id\n      }\n      deadlineDay\n      deadlineMonth\n      deadlineYear\n      startDay\n      startMonth\n      startYear\n      done\n      role {\n        id\n        name\n      }\n      assignedPersons {\n        id\n        firstName\n        lastName\n        active\n        email\n        profilePictureId\n        profilePictureDefaultId\n        permissions\n      }\n      dependsOnThisTask(first: 1000) {\n        edges {\n          node {\n            id\n            taskDependsOnThis {\n              id\n              canStart\n              canBeSetToDone\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    sortOrderTasks {\n      id\n      sortOrder\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bc4e3c10c52dea1b3b407c1970c8a8f7';

module.exports = node;
