import * as tracking from '../tracking';
import Util from '../forecast-app/shared/util/util';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createProjectMutation($input: CreateProjectInput!) {
		createProject(input: $input) {
			errors
			project {
				node {
					projectPersons(first: 1000000, contactsOnly: true) {
						edges {
							node {
								id
								isContactPerson
								person {
									id
									firstName
									lastName
									profilePictureId
								}
							}
						}
					}
					id
					companyProjectId
					customProjectId
					status
					name
					isJiraProject
					projectColor
					fullAccessToProject
					readOnlyAccess
					isInProjectGroup
					billable
					budgetType
					isInProjectGroup
					projectGroupId
					sprintTimeBox
					useBaseline
					harvestProject {
						id
					}
					statusColumnsV2(first: 1000000) {
						edges {
							node {
								id
								projectGroupStatusColumnId
							}
						}
					}
					priorityLevel {
						id
						name
						weight
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [
		{
			type: 'RANGE_ADD',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionInfo: [
				{
					key: 'Viewer_projects',
					rangeBehavior: 'append',
				},
				{
					key: 'Header_Viewer_projects',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'project',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	const settings = [];

	for (const key of Object.keys(input)) {
		const val = input[key];
		if (['id', 'projectId', 'name', 'projectColor', 'rateCardId', 'labels', 'budget'].includes(key) || val == null) {
			continue;
		}
		settings.push({key, val});
	}
	tracking.trackEvent('Project Created', {
		_UseSprints: input.sprintTimeBox,
		_SprintLength: input.sprintLengthInDays,
		_StartDateSet: !!input.projectStartYear,
		_EndDateSet: !!input.projectEndYear,
		_ClientSet: !!input.clientId,
		_UseBaseline: input.useBaseline,
	});
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
