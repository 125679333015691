import {commitMutation, graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';

const mutation = graphql`
	mutation deleteHolidayCalendarEntryMutation($input: DeleteHolidayCalendarEntryInput!) {
		deleteHolidayCalendarEntry(input: $input) {
			deletedHolidayCalendarEntryId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.holidayCalendarId,
			connectionKeys: [
				{
					key: 'HolidayCalendar_holidayCalendarEntries',
				},
			],
			pathToConnection: ['holidayCalendar', 'holidayCalendarEntry'],
			deletedIDFieldName: 'deletedHolidayCalendarEntryId',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	return commitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
