/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateErrorInput = {|
  id: string,
  fixed?: ?boolean,
  excludeFromStats?: ?boolean,
  notes?: ?string,
  team?: ?string,
  knownType?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateErrorMutationVariables = {|
  input: UpdateErrorInput
|};
export type updateErrorMutationResponse = {|
  +UpdateError: ?{|
    +MissionControlErrorList: ?{|
      +id: string,
      +actualId: ?number,
      +timestamp: ?string,
      +personId: ?number,
      +personName: ?string,
      +companyId: ?number,
      +companyName: ?string,
      +correlationId: ?string,
      +fixed: ?boolean,
      +notes: ?string,
      +excludeFromStats: ?boolean,
      +lastUpdatedByName: ?string,
      +bugDuty: ?string,
      +team: ?string,
      +url: ?string,
      +knownType: ?string,
    |}
  |}
|};
export type updateErrorMutation = {|
  variables: updateErrorMutationVariables,
  response: updateErrorMutationResponse,
|};
*/


/*
mutation updateErrorMutation(
  $input: UpdateErrorInput!
) {
  UpdateError(input: $input) {
    MissionControlErrorList {
      id
      actualId
      timestamp
      personId
      personName
      companyId
      companyName
      correlationId
      fixed
      notes
      excludeFromStats
      lastUpdatedByName
      bugDuty
      team
      url
      knownType
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateErrorInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateErrorPayload",
    "kind": "LinkedField",
    "name": "UpdateError",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MissionControlErrorListType",
        "kind": "LinkedField",
        "name": "MissionControlErrorList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actualId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timestamp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "personId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "personName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "correlationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fixed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "excludeFromStats",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdatedByName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bugDuty",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "team",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "knownType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateErrorMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateErrorMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateErrorMutation",
    "operationKind": "mutation",
    "text": "mutation updateErrorMutation(\n  $input: UpdateErrorInput!\n) {\n  UpdateError(input: $input) {\n    MissionControlErrorList {\n      id\n      actualId\n      timestamp\n      personId\n      personName\n      companyId\n      companyName\n      correlationId\n      fixed\n      notes\n      excludeFromStats\n      lastUpdatedByName\n      bugDuty\n      team\n      url\n      knownType\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c0939106fe3bf662139a14b9784b3646';

module.exports = node;
