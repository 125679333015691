import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteCustomFieldDefinitionMutation($input: DeleteCustomFieldDefinitionInput!) {
		deleteCustomFieldDefinition(input: $input) {
			deletedCustomFieldDefinitionId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.companyId,
			connectionKeys: [
				{
					key: 'Company_customFieldDefinitions',
				},
			],
			pathToConnection: ['company', 'customFieldDefinitions'],
			deletedIDFieldName: 'deletedCustomFieldDefinitionId',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		deleteCustomFieldDefinition: {
			deletedCustomFieldDefinitionId: input.id,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('CustomFieldDefinition - Deleted');

	const cleanedInput = omit(input, ['companyId']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
