/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetBambooHRUserInput = {|
  bambooHRUserId?: ?string,
  forecastPersonId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type SetBambooHRUserMutationVariables = {|
  input: SetBambooHRUserInput
|};
export type SetBambooHRUserMutationResponse = {|
  +setBambooHRUser: ?{|
    +viewer: ?{|
      +bambooHRId: ?string
    |},
    +person: ?{|
      +bambooHRId: ?string
    |},
  |}
|};
export type SetBambooHRUserMutation = {|
  variables: SetBambooHRUserMutationVariables,
  response: SetBambooHRUserMutationResponse,
|};
*/


/*
mutation SetBambooHRUserMutation(
  $input: SetBambooHRUserInput!
) {
  setBambooHRUser(input: $input) {
    viewer {
      bambooHRId
      id
    }
    person {
      bambooHRId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetBambooHRUserInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bambooHRId",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetBambooHRUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetBambooHRUserPayload",
        "kind": "LinkedField",
        "name": "setBambooHRUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetBambooHRUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetBambooHRUserPayload",
        "kind": "LinkedField",
        "name": "setBambooHRUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SetBambooHRUserMutation",
    "operationKind": "mutation",
    "text": "mutation SetBambooHRUserMutation(\n  $input: SetBambooHRUserInput!\n) {\n  setBambooHRUser(input: $input) {\n    viewer {\n      bambooHRId\n      id\n    }\n    person {\n      bambooHRId\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1481fd7971167b52b1717ba89b309663';

module.exports = node;
