/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteCustomFieldDefinitionInput = {|
  id?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteCustomFieldDefinitionMutationVariables = {|
  input: DeleteCustomFieldDefinitionInput
|};
export type deleteCustomFieldDefinitionMutationResponse = {|
  +deleteCustomFieldDefinition: ?{|
    +deletedCustomFieldDefinitionId: ?string
  |}
|};
export type deleteCustomFieldDefinitionMutation = {|
  variables: deleteCustomFieldDefinitionMutationVariables,
  response: deleteCustomFieldDefinitionMutationResponse,
|};
*/


/*
mutation deleteCustomFieldDefinitionMutation(
  $input: DeleteCustomFieldDefinitionInput!
) {
  deleteCustomFieldDefinition(input: $input) {
    deletedCustomFieldDefinitionId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteCustomFieldDefinitionInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteCustomFieldDefinitionPayload",
    "kind": "LinkedField",
    "name": "deleteCustomFieldDefinition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedCustomFieldDefinitionId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteCustomFieldDefinitionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteCustomFieldDefinitionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteCustomFieldDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation deleteCustomFieldDefinitionMutation(\n  $input: DeleteCustomFieldDefinitionInput!\n) {\n  deleteCustomFieldDefinition(input: $input) {\n    deletedCustomFieldDefinitionId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8965de18e7dfd48b49d333d333621eb';

module.exports = node;
