import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createSubTaskMutation($input: CreateSubTaskInput!) {
		createSubTask(input: $input) {
			task {
				timeLeft
				latestUiUpdateAt
				latestUiUpdateBy {
					firstName
					lastName
				}
			}
			subTask {
				node {
					id
					name
					estimate
					sortOrder
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.taskId,
			edgeName: 'subTask',
			connectionInfo: [
				{
					key: 'Task_subTasks',
					rangeBehavior: 'append',
				},
			],
		},
	];
}

/*function getOptimisticResponse(input) {
	const subTask = {sortOrder: 99999};
	if (input.name) {
		subTask.name = input.name;
	}

	if (input.estimate) {
		subTask.estimate = input.estimate;
	}
	return {
		createSubTask: {
			subTask: {node: {...subTask}},
			task: {id: input.taskId}
		}
	};
}*/

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Sub Task Created');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				...input,
			},
		},
		configs: getConfigs(input),
		//optimisticResponse: getOptimisticResponse(input),
		onCompleted,
		onError,
	});
}

export default {commit};
