import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation bulkUpdatePhasesMutation($input: BulkUpdatePhasesInput!) {
		bulkUpdatePhases(input: $input) {
			project {
				id
				phases(first: 1000000) @connection(key: "Project_phases", filters: []) {
					edges {
						node {
							id
							projectId
							name
							startYear
							startMonth
							startDay
							deadlineYear
							deadlineMonth
							deadlineDay
							sortOrder
						}
					}
				}
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];
	for (const key of Object.keys(input)) {
		if (['projectId'].includes(key) || input[key] === undefined) {
			continue;
		}
		changes.push(key);
	}
	tracking.trackEvent('Phases Bulk Updated', {_Changed: changes});

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
