import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation UpdateViewerNotificationSettingsMutation($input: UpdateViewerNotificationSettingsInput!) {
		updateViewerNotificationSettings(input: $input) {
			personNotificationSettings {
				id
				projectStatusEmailEnabled
				projectStatusEmailDay
				projectStatusEmailHour
				projectDeadlineOnlyAssignedProjects
				stateChangedOnlyAssignedProjects
				invoiceDueDateOnlyOwned
				invoiceOverdueOnlyOwned
				invoiceDaysOverdueOnlyOwned
				invoiceCreatedOrDeletedOnlyOwned
				invoiceStatusChangeOnlyOwned
				invoiceDateReachedOnlyOwned
				invoicePaymentOnlyOwned
				invoiceDateChangedOnlyOwned
				invoiceDueDateChangedOnlyOwned
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const obj = {id: input.id};
	obj.projectStatusEmailEnabled = input.projectStatusEmailEnabled;
	obj.projectStatusEmailDay = input.projectStatusEmailDay;
	obj.projectStatusEmailHour = input.projectStatusEmailHour;
	obj.projectDeadlineOnlyAssignedProjects = input.projectDeadlineOnlyAssignedProjects;
	obj.stateChangedOnlyAssignedProjects = input.stateChangedOnlyAssignedProjects;
	obj.invoiceDueDateOnlyOwned = input.invoiceDueDateOnlyOwned;
	obj.invoiceOverdueOnlyOwned = input.invoiceOverdueOnlyOwned;
	obj.invoiceDaysOverdueOnlyOwned = input.invoiceDaysOverdueOnlyOwned;
	obj.invoiceCreatedOrDeletedOnlyOwned = input.invoiceCreatedOrDeletedOnlyOwned;
	obj.invoiceStatusChangeOnlyOwned = input.invoiceStatusChangeOnlyOwned;
	obj.invoiceDateReachedOnlyOwned = input.invoiceDateReachedOnlyOwned;
	obj.invoicePaymentOnlyOwned = input.invoicePaymentOnlyOwned;
	obj.invoiceDateChangedOnlyOwned = input.invoiceDateChangedOnlyOwned;
	obj.invoiceDueDateChangedOnlyOwned = input.invoiceDueDateChangedOnlyOwned;
	return {
		updateViewerNotificationSettings: {
			personNotificationSettings: obj,
		},
	};
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Person Notification Settings Updated', {_Changed: changes});

	const variables = {...input};
	// ID is needed for mutations's optimistic response, but should not be included in the variables
	const variablesNoID = omit(variables, ['id', 'viewer']);

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...variablesNoID}},
		uploadables,
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
