import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation duplicateRateCardMutationModernMutation($input: DuplicateRateCardInput!) {
		duplicateRateCard(input: $input) {
			rateCard {
				node {
					id
					name
					defaultRate
					parentRateCardId
					currency
					disabledRoles {
						id
					}
					rates(first: 10000) {
						edges {
							node {
								id
								rate
								defaultRate
								startDate
								role {
									id
								}
							}
						}
					}
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_rateCards',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'rateCard',
		},
	];
}

function commit(environment, input, onCompleted, onError) {
	tracking.trackEvent('Rate Card Duplicated');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {
			input: {
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				rateCardId: input.rateCardId,
			},
		},
		configs: getConfigs(input),
		onCompleted,
		onError,
	});
}

export default {commit};
