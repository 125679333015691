/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EnableModuleInput = {|
  companyId: string,
  datacenter?: ?string,
  moduleType?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  endYear?: ?number,
  endMonth?: ?number,
  endDay?: ?number,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type enableModuleMutationVariables = {|
  input: EnableModuleInput
|};
export type enableModuleMutationResponse = {|
  +enableModule: ?{|
    +module: ?{|
      +node: ?{|
        +id: string,
        +companyId: string,
        +moduleType: ?string,
        +enabledByTier: ?boolean,
        +startYear: ?number,
        +startMonth: ?number,
        +startDay: ?number,
        +endYear: ?number,
        +endMonth: ?number,
        +endDay: ?number,
      |}
    |}
  |}
|};
export type enableModuleMutation = {|
  variables: enableModuleMutationVariables,
  response: enableModuleMutationResponse,
|};
*/


/*
mutation enableModuleMutation(
  $input: EnableModuleInput!
) {
  enableModule(input: $input) {
    module {
      node {
        id
        companyId
        moduleType
        enabledByTier
        startYear
        startMonth
        startDay
        endYear
        endMonth
        endDay
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "EnableModuleInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EnableModulePayload",
    "kind": "LinkedField",
    "name": "enableModule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ModuleEdge",
        "kind": "LinkedField",
        "name": "module",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "moduleType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabledByTier",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDay",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "enableModuleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "enableModuleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "enableModuleMutation",
    "operationKind": "mutation",
    "text": "mutation enableModuleMutation(\n  $input: EnableModuleInput!\n) {\n  enableModule(input: $input) {\n    module {\n      node {\n        id\n        companyId\n        moduleType\n        enabledByTier\n        startYear\n        startMonth\n        startDay\n        endYear\n        endMonth\n        endDay\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '76b0970712dc53dc920a3ba106dde0a6';

module.exports = node;
