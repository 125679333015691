import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation updateFixedPriceLockMutation($input: UpdateFixedPriceLockInput!) {
		updateFixedPriceLock(input: $input) {
			fixedPriceLock {
				id
				locked
				actualRevenueProjectCurrency
				progress
			}
		}
	}
`;

function getConfigs() {
	return [];
}

function getOptimisticResponse(input) {
	const fixedPriceLock = {id: input.id};
	if (input.locked) {
		fixedPriceLock.locked = input.locked;
	}
	if (input.actualRevenueProjectCurrency) {
		fixedPriceLock.actualRevenueProjectCurrency = input.actualRevenueProjectCurrency;
	}
	if (input.progress) {
		fixedPriceLock.progress = input.progress;
	}
	return {
		updateFixedPriceLock: {
			fixedPriceLock: fixedPriceLock,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Fixed Price Lock Updated', {_Changed: changes});

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
