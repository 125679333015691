import Util from '../../forecast-app/shared/util/util';
import {commitMutation, graphql} from 'react-relay';
import RelayEnvironment from '../../RelayEnvironment';

const mutation = graphql`
	mutation featureFlagMutation($input: UpdateFeatureFlagInput!) {
		UpdateFeatureFlag(input: $input) {
			availableFeatureFlags {
				id
				key
			}
			missionControlData {
				availableFeatures {
					id
					name
					key
					color
					companies {
						id
						companyId
						name
						timestamp
					}
					persons {
						id
						personId
						companyId
						companyName
						name
						email
						timestamp
					}
					personsOptedOut {
						id
						companyId
						companyName
						name
						email
					}
				}
			}
		}
	}
`;

function commit(environment, input, onSuccess, onError) {
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};
	return commitMutation(RelayEnvironment.getInstanceMC(), {
		mutation,
		variables,
		configs: [],
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
