/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteLabelCategoryInput = {|
  id?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type deleteLabelCategoryMutationVariables = {|
  input: DeleteLabelCategoryInput
|};
export type deleteLabelCategoryMutationResponse = {|
  +deleteLabelCategory: ?{|
    +deletedLabelCategoryId: ?string
  |}
|};
export type deleteLabelCategoryMutation = {|
  variables: deleteLabelCategoryMutationVariables,
  response: deleteLabelCategoryMutationResponse,
|};
*/


/*
mutation deleteLabelCategoryMutation(
  $input: DeleteLabelCategoryInput!
) {
  deleteLabelCategory(input: $input) {
    deletedLabelCategoryId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteLabelCategoryInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteLabelCategoryPayload",
    "kind": "LinkedField",
    "name": "deleteLabelCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedLabelCategoryId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteLabelCategoryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteLabelCategoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "deleteLabelCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation deleteLabelCategoryMutation(\n  $input: DeleteLabelCategoryInput!\n) {\n  deleteLabelCategory(input: $input) {\n    deletedLabelCategoryId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '08c4714834d4ab996a7311d33659bc03';

module.exports = node;
