import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteInsightShareMutation($input: DeleteInsightShareInput!) {
		deleteInsightShare(input: $input) {
			deletedInsightShareId
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: input.insightId,
			connectionKeys: [
				{
					key: 'Insight_shares',
				},
			],
			pathToConnection: ['insight', 'shares'],
			deletedIDFieldName: 'deletedInsightShareId',
		},
		{
			type: 'NODE_DELETE',
			deletedIDFieldName: 'deletedInsightShareId',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		deleteInsightShare: {
			deletedInsightShareId: input.id,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Insight Share Deleted');

	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}},
		configs: getConfigs(input),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
