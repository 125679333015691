import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {graphql} from 'react-relay';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation deleteInvoiceMutation($input: DeleteInvoiceInput!) {
		deleteInvoice(input: $input) {
			deletedInvoiceId
		}
	}
`;

function getConfigs() {
	return [
		{
			type: 'RANGE_DELETE',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionKeys: [
				{
					key: 'Viewer_invoicesV2',
				},
			],
			pathToConnection: ['viewer', 'invoicesV2'],
			deletedIDFieldName: 'deletedInvoiceId',
		},
	];
}

function getOptimisticResponse(input) {
	return {
		deleteInvoice: {
			deletedInvoiceId: input.id,
		},
	};
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Invoice Deleted');
	return trackAndCommitMutation(environment, {
		mutation,
		variables: {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), id: input.id}},
		configs: getConfigs(),
		optimisticResponse: getOptimisticResponse(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
