/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProgramClientInput = {|
  programId: string,
  prevClientId?: ?string,
  nextClientId?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateProgramClientMutationVariables = {|
  input: UpdateProgramClientInput
|};
export type updateProgramClientMutationResponse = {|
  +updateProgramClient: ?{|
    +deletedClientId: ?number,
    +program: ?{|
      +node: ?{|
        +id: string,
        +clients: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +logoId: ?string,
            |}
          |}>
        |},
      |}
    |},
  |}
|};
export type updateProgramClientMutation = {|
  variables: updateProgramClientMutationVariables,
  response: updateProgramClientMutationResponse,
|};
*/


/*
mutation updateProgramClientMutation(
  $input: UpdateProgramClientInput!
) {
  updateProgramClient(input: $input) {
    deletedClientId
    program {
      node {
        id
        clients(first: 10000) {
          edges {
            node {
              id
              name
              logoId
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProgramClientInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateProgramClientPayload",
    "kind": "LinkedField",
    "name": "updateProgramClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedClientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectServiceProgramTypeEdge",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10000
                  }
                ],
                "concreteType": "ClientTypeConnection",
                "kind": "LinkedField",
                "name": "clients",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Client",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "logoId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "clients(first:10000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateProgramClientMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateProgramClientMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateProgramClientMutation",
    "operationKind": "mutation",
    "text": "mutation updateProgramClientMutation(\n  $input: UpdateProgramClientInput!\n) {\n  updateProgramClient(input: $input) {\n    deletedClientId\n    program {\n      node {\n        id\n        clients(first: 10000) {\n          edges {\n            node {\n              id\n              name\n              logoId\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '676210bbb0d1a410660aa185508cf562';

module.exports = node;
