/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateGoogleDriveFileInput = {|
  id: string,
  taskId?: ?string,
  personId: string,
  googleDriveId?: ?string,
  name?: ?string,
  link?: ?string,
  previousTaskId?: ?string,
  thumb?: ?string,
  folderId?: ?string,
  projectId?: ?string,
  createdAt?: ?string,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type updateGoogleDriveFileMutationVariables = {|
  input: UpdateGoogleDriveFileInput
|};
export type updateGoogleDriveFileMutationResponse = {|
  +updateGoogleDriveFile: ?{|
    +googleDriveFile: ?{|
      +name: ?string,
      +folder: ?{|
        +id: string,
        +name: ?string,
      |},
    |},
    +project: ?{|
      +tasks: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +companyTaskId: ?number,
            +name: ?string,
            +googleDriveFiles: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: ?string,
                  +name: ?string,
                |}
              |}>
            |},
          |}
        |}>
      |}
    |},
  |}
|};
export type updateGoogleDriveFileMutation = {|
  variables: updateGoogleDriveFileMutationVariables,
  response: updateGoogleDriveFileMutationResponse,
|};
*/


/*
mutation updateGoogleDriveFileMutation(
  $input: UpdateGoogleDriveFileInput!
) {
  updateGoogleDriveFile(input: $input) {
    googleDriveFile {
      name
      folder {
        id
        name
      }
      id
    }
    project {
      tasks(first: 10000) {
        edges {
          node {
            id
            companyTaskId
            name
            googleDriveFiles(first: 1000) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateGoogleDriveFileInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Folder",
  "kind": "LinkedField",
  "name": "folder",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10000
    }
  ],
  "concreteType": "TaskTypeConnection",
  "kind": "LinkedField",
  "name": "tasks",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "companyTaskId",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 1000
                }
              ],
              "concreteType": "GoogleDriveFileTypeConnection",
              "kind": "LinkedField",
              "name": "googleDriveFiles",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GoogleDriveFileTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GoogleDriveFile",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "googleDriveFiles(first:1000)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "tasks(first:10000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateGoogleDriveFileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGoogleDriveFilePayload",
        "kind": "LinkedField",
        "name": "updateGoogleDriveFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GoogleDriveFile",
            "kind": "LinkedField",
            "name": "googleDriveFile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateGoogleDriveFileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGoogleDriveFilePayload",
        "kind": "LinkedField",
        "name": "updateGoogleDriveFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GoogleDriveFile",
            "kind": "LinkedField",
            "name": "googleDriveFile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateGoogleDriveFileMutation",
    "operationKind": "mutation",
    "text": "mutation updateGoogleDriveFileMutation(\n  $input: UpdateGoogleDriveFileInput!\n) {\n  updateGoogleDriveFile(input: $input) {\n    googleDriveFile {\n      name\n      folder {\n        id\n        name\n      }\n      id\n    }\n    project {\n      tasks(first: 10000) {\n        edges {\n          node {\n            id\n            companyTaskId\n            name\n            googleDriveFiles(first: 1000) {\n              edges {\n                node {\n                  id\n                  name\n                }\n              }\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e4b39ed25016b6aa720002580efea50e';

module.exports = node;
