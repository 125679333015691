/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
mutation UpdateOutlookCalendarMutation(
  $input: UpdateOutlookCalendarInput!
) {
  updateOutlookCalendar(input: $input) {
    outlookCalendar {
      calendarViewPrivateEvents
      enabledCalendars
      id
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input",
            "type": "UpdateOutlookCalendarInput!"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "calendarViewPrivateEvents",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabledCalendars",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateOutlookCalendarMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateOutlookCalendarPayload",
                    "kind": "LinkedField",
                    "name": "updateOutlookCalendar",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CalendarIntegration",
                            "kind": "LinkedField",
                            "name": "outlookCalendar",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateOutlookCalendarMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateOutlookCalendarPayload",
                    "kind": "LinkedField",
                    "name": "updateOutlookCalendar",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "CalendarIntegration",
                            "kind": "LinkedField",
                            "name": "outlookCalendar",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "UpdateOutlookCalendarMutation",
            "operationKind": "mutation",
            "text": "mutation UpdateOutlookCalendarMutation(\n  $input: UpdateOutlookCalendarInput!\n) {\n  updateOutlookCalendar(input: $input) {\n    outlookCalendar {\n      calendarViewPrivateEvents\n      enabledCalendars\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = '0f2fb286e5ed1bc90e5e27a4170bee7e';
export default node;
